<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>商品管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">

            <div class="padding15">
                <el-button type="primary" @click="subscribe.isAddSubscribe = true">设置周期订阅</el-button>
                <el-button type="primary" @click="isSetSpike = true">设置秒杀</el-button>
            </div>

            <el-form style="width: 100%" label-width="120px" :rules="pushRules" :model="pushData" ref="pushData">
                <el-form-item label="商品推广标题">
                    <el-input v-model="pushData.name"></el-input>
                </el-form-item>

                <div class="display-flex" style="margin: 0 0 20px 60px">
                    <div>
                        <span>商品主图：</span>
                        <upload-image title="商品显示主图【必填】" v-model="pushData.primary_pic_url" style="margin-right: 50px">
                            <div style="text-align: center;">4：3</div>
                        </upload-image>
                    </div>

                    <div>
                        <span>商品列表图【详情轮播图】：</span>
                        <el-upload
                            class="upload-demo"
                            :action="$api + UPLOAD_HEADER_IMG"
                            :headers="{ Auth_token }"
                            :on-success="handleImageSuccess"
                            list-type="picture-card"
                            >
                            <i class="el-icon-plus"></i>
                            <!--<el-button size="small" type="primary">点击上传</el-button>-->
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                        </el-upload>
                        <div style="position:relative;display: inline-block;float: left;box-shadow: 0 0 5px #eee;margin: 1em;" v-for="item in fileList_arr" :key="item.img_url"
                             v-dragging="{ item: item, list: fileList_arr, group: 'item' }">
                            <img @click="removeGoodsGallery(item)" style="position: absolute;right: 0;top: 0;width: 15px;height: 15px;cursor: pointer;" src="https://oss.mxclass.com/cathouse_pro/icon/%E5%85%83%E7%B4%A0%E6%9C%AA%E8%AF%BB%E6%95%B02%402x.png" alt="">
                            <img :src="item.img_url" alt="" style="width: 80px;height: 80px;padding: 5px;">
                        </div>
                    </div>

                </div>

                <!--<el-form-item label="所属品牌" prop="brand_id">-->
                    <!--<el-select v-model="pushData.brand_id" placeholder="请选择品牌" @change="changeBrand">-->
                        <!--<el-option v-for="item in brandOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
                    <!--</el-select>-->
                    <!--<span style="color: #ff0000;font-size: 13px;" v-if="!isThisBrandNotDeleted">该品牌已被下架，请更换品牌！</span>-->
                <!--</el-form-item>-->

                <el-form-item label="商品简介">
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入商品简介"
                        v-model="pushData.goods_brief">
                    </el-input>
                </el-form-item>

                <!--<el-form-item label="商品属性" name="attribute" prop="attribute">-->
                    <!--<div v-for="(item, curIndex) in pushData.attribute" :key="item.hashCode">-->
                        <!--<el-input style="width:200px;margin-right:5px;margin-bottom:5px;" placeholder="请输入属性名称，如：颜色" v-model="item.name" ></el-input>-->
                        <!--<el-input style="width:200px" placeholder="请输入属性值，如：白色" v-model="item.value"></el-input>-->
                        <!--<el-button size="small" type="danger" @click="handleRemoveAttribute(curIndex)">删除</el-button>-->
                    <!--</div>-->

                    <!--<el-button size="small" type="primary" @click="handleAddAttribute()">新增</el-button>-->
                <!--</el-form-item>-->


                <el-form-item label="商品分类">
                    <el-select v-model="currentCategory">
                        <el-option v-for="item in category" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>

                    <el-select v-model="pushData.category_id" v-if="currentCategoryArr" @change="chooseThisCategory">
                        <el-option v-for="item in currentCategoryArr" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="商品SKU">
                    <div v-for="(goods, $index) in goodsList" :key="$index" class="width40" style="float: left; margin-right: 15px;">
                        <el-card   :body-style="{ padding: '15px' }">
                            <div class="disflex_around_center">
                                <div class="width40">
                                    <sku-img-upload  title="sku主图" list-type="picture-card" v-model="goods.header_img" style="margin-right: 50px" >
                                        <div style="text-align: center;">4：3</div>
                                    </sku-img-upload>
                                   <div class="disflex_flex_start align-items padding15">
                                       <p>首页展示：</p>
                                       <el-switch
                                           v-model="goods.IS_PAGE_SHOW"
                                           active-color="#13ce66"
                                           inactive-color="#ff4949"
                                           @change="changePageShow($event, goods)">
                                       </el-switch>
                                   </div>
                                </div>
                                <div class="width60">
                                    <el-input v-model="goods.goods_number"  placeholder="请输入商品库存" class="mgb20">
                                        <template slot="prepend">库存</template>
                                    </el-input>
                                    <el-input v-model="goods.selling_price"  placeholder="请设置销售价格" class="mgb20">
                                        <template slot="prepend">售价￥</template>
                                    </el-input>
                                    <el-input v-model="goods.discount"  placeholder="请设置当前sku折扣价" class="mgb20">
                                        <template slot="prepend">折扣</template>
                                    </el-input>
                                    <div v-for="(attribute, attrIndex) in goods.attributeList" :key="attrIndex" class="disflex_flex_start mgb20">
                                        <el-cascader
                                            :options="attributeData"
                                            v-model="attribute.attribute_value"
                                            @change="attributeDataChange($event, goods)" @visible-change="attrVisibleChange"></el-cascader>
                                        <i class="el-icon-circle-plus" style="font-size: 30px;color: #09bbe7;cursor: pointer;" @click="up_attrIndexLength(goods, attribute)" v-if="attrIndex === goods.attributeList.length - 1"></i>
                                        <i class="el-icon-remove" style="font-size: 30px;color: #eb3021;cursor: pointer" @click="cut_attrIndexLength(goods, attribute)" v-if="attrIndex !== goods.attributeList.length - 1"></i>
                                    </div>
                                    <el-input v-model="goods.remarks"  placeholder="请设置销售价格" >
                                        <template slot="prepend">备注</template>
                                    </el-input>
                                </div>
                            </div>
                        </el-card>
                        <p class="color_eb3021 font-size-13 cursor_pointer" @click="deleteGoodsSku(goods)">删除sku <i class="el-icon-remove"></i></p>
                    </div>
                    <p class="color_09bbe7 font-size-13 cursor_pointer" @click="addGoodsSku">新增sku <i class="el-icon-circle-plus"></i></p>
                </el-form-item>


                <el-form-item label="零售价格">
                    <el-input v-model="pushData.retail_price"></el-input>
                    <p class="color_eb3021_important font-size-10 padding15 margin-left-1em">当前价格可自行设置或者选择根据上面的sku自动填充【首页展示】的价格</p>
                </el-form-item>

                <el-form-item label="库存数">
                    <el-input v-model="pushData.goods_number"></el-input>
                    <p class="color_eb3021_important font-size-10 padding15 margin-left-1em">当前库存可自行设置或者选择根据上面的sku自动填充【首页展示】的库存</p>
                </el-form-item>

                <el-form-item label="折扣（两位小数）">
                    <el-input-number v-model="pushData.discount" :precision="2" :step="0.1" :max="1"></el-input-number>
                    <p class="color_eb3021_important font-size-10 padding15 margin-left-1em">当前折扣可自行设置或者选择根据上面的sku自动填充【首页展示】的折扣，两位小数，例如：0.50 标识5折，0.85表示85折</p>
                </el-form-item>

                <el-form-item label="推荐类型">
                    <el-radio-group v-model="recommendChecked">
                        <el-radio-button label="0" :key="1">无</el-radio-button>
                        <el-radio-button label="1" :key="2">新品</el-radio-button>
                        <el-radio-button label="2" :key="3">热卖</el-radio-button>
                    </el-radio-group>

                </el-form-item>

                <el-form-item label="直接上架">
                    <p class="font-size-13 color_eb3021 padding15"> 默认上线，不能在此处修改</p>
                    <!--<el-switch on-text="" off-text="" v-model="pushData.is_on_sale"></el-switch>-->
                </el-form-item>

                <el-form-item label="是否为试用装？">
                    <el-switch on-text="" off-text="" v-model="pushData.goods_type"></el-switch>
                    <p class="font-size-13 color_eb3021 padding15"> 试用装目前转换为普通商品身份流通，但是必须标记以区分</p>
                </el-form-item>


                <el-form-item label="排序">
                    <el-input-number v-model="pushData.sort_order" :min="1" :max="1000"></el-input-number>
                </el-form-item>

                <el-form-item label="搜索关键字">
                    <el-input v-model="pushData.keywords"></el-input>
                </el-form-item>

                <el-form-item label="活动备注（价格备注）">
                    <el-input v-model="pushData.price_desc" placeholder="如该商品存在买 * 送 * 活动，请备注买送详情以及发货方式, 长度为32字符以内！"></el-input>
                </el-form-item>
                <el-form-item label="商品单位">
                    <el-input v-model="pushData.goods_unit"></el-input>
                </el-form-item>
                <el-form-item label="推广标签">
                    <el-input v-model="pushData.promotion_tag"></el-input>
                </el-form-item>
                <el-form-item label="推广详情">
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入推广详情"
                        v-model="pushData.promotion_desc">
                    </el-input>
                </el-form-item>
            </el-form>

            <el-dialog title="添加标签" :visible.sync="addTagsForm" width="30%">
                <el-checkbox-group v-model="addTagsList" size="small">
                    <el-checkbox border style="margin-left: 10px" v-for="tags in tagsList" :label="tags" :key="tags.id">
                        {{tags.name}}
                    </el-checkbox>
                </el-checkbox-group>
            </el-dialog>

            <!--<div style="margin-bottom: 10px">-->
            <!--<el-radio v-model="uploadData.contentType" label="1" border>文章</el-radio>-->
            <!--<el-radio v-model="uploadData.contentType" label="2" border>公众号</el-radio>-->
            <!--</div>-->

            <h1 style="padding: 1em;text-align: left;">商品详情 (
            <span @click="showPreviewPhone = !showPreviewPhone" style="color: #429493;font-size: 16px;cursor:pointer;">点此预览详情</span>
            )</h1>
            <div class="editBox_preview" @click="showPreviewPhone = !showPreviewPhone">
                <ueditor class="editBox" v-model="pushData.goods_desc"></ueditor>
                <phone-preview :html="pushData.goods_desc" v-model="showPreviewPhone"></phone-preview>
            </div>

        </div>
        <div class="operationBtn">
            <el-button class="editor-btn" type="primary" @click="submitPaper">提交</el-button>
            <!--<el-button class="editor-btn" type="default" @click="saveAsDraft">存为草稿</el-button>-->
        </div>
        <el-dialog title="设置周期订阅" :visible.sync="subscribe.isAddSubscribe" width="80%" @close="subscribe.isAddSubscribe = false">
            <p class="font-size-13 color_eb3021 padding15">周期订阅商品填写后保存即可生效，无需提交商品编辑。</p>
            <el-form style="width: 100%" label-width="120px"  :model="subscribe" ref="subscribe">
                <el-form-item label="订阅期数">
                    <el-select
                        class="width100"
                        v-model="subscribe.selectedSubscribeCycle"
                        @change="setSubscribeCycle"
                        @remove-tag="removeSubscribeCycle"
                        multiple
                        filterable
                        default-first-option
                        placeholder="请选择订阅的可选周期">
                        <el-option
                            v-for="item in subscribe.canAddSubscribeDaysList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="订阅设置">
                    <div style="width: 250px;display: inline-block;margin: 10px;border-bottom: 1px solid #f4f4f4;padding-bottom: 10px;" v-for="item in subscribe.subscribeCycleData">
                        <p class="labelText">{{item.subscribe_cycle_times}}期订阅计划</p>
                        <el-input placeholder="单期价格" v-model="item.subscribe_price" @input="changePrice(item)">
                            <template slot="prepend">单期价格￥</template>
                        </el-input>
                        <el-input placeholder="总价" v-model="item.subscribe_amount" @input="changeAmount(item)">
                            <template slot="prepend">计划总价￥</template>
                        </el-input>
                        <el-input placeholder="周期备注" v-model="item.desc">
                            <template slot="prepend">订阅备注：</template>
                        </el-input>
                        <p class="font-size-10 color_eb3021_important">订阅备注会显示在订阅周期下方</p>

                        <div class="disflex_flex_start align-items">
                            <p class="labelText" style="margin-right: 1em;">设置为推荐</p>
                            <el-switch
                                v-model="item.is_recommend"
                                active-color="#13ce66"
                                inactive-color="#ff4949">
                            </el-switch>
                        </div>
                    </div>
                </el-form-item>

                <el-form-item label="配送时间选择">
                    <el-select
                        class="width100"
                        v-model="subscribe.subscribeExpressDays"
                        @change="setExpressDays"
                        @remove-tag="removeSubscribeExpress"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请选择配送周期">
                        <el-option
                            v-for="item in subscribe.canSubscribeExpressDaysList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="配送时间设置">
                    <div style="display: inline-block;margin: 10px" v-for="item in subscribe.subscribeExpressDaysData">
                        <span>{{item.label}}</span>
                        <el-switch
                            v-model="item.is_default"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            @change="changeExpressTimesDefault($event, item)">
                        </el-switch>
                    </div>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="subscribe.isAddSubscribe = false">取消</el-button>
                <el-button type="primary" @click="saveSubscribe">保存订阅</el-button>
            </div>
        </el-dialog>
        <spike-goods :goods="goodsDetailData"
                     :is-open="isSetSpike"
                     v-if="isSetSpike"
                     @close="isSetSpike = false"
                     @refresh="loadGoodsDetail"
        ></spike-goods>
    </div>
</template>

<script>
    import ueditor from '../../common/editor'
    import bus from '../../common/bus'
    import uploadImage from '../../common/uploadImage'
    import spikeGoods from './spikeGoods'
    import phonePreview from '../../common/phonePreview'

    export default {
        data: function () {
            return {
                isSetSpike: false, // 是否打开设置秒杀窗口
                currentCategory: '', //选择胡当前商品一级分类
                currentCategoryArr: [], //选择胡当前商品子分类数组
                column: '',
                columnList: [],
                tagsList: [],
                addTagsList: [],
                addTagsForm: false,
                templateDialogVisible: false,
                title: '新增文章',
                templateSearchName: '',
                fileList: '',
                category: [],
                templateData: [],
                recommendChecked: 0, // 推荐类型
                uploadData: {
                    title: '',
                    author: '',
                    content: '',
                    tag: '',
                    sort: '',
                    picture: '',
                    sharePicture: '',
                    categoryId: 0,
                    contentType: '',
                    selected: '',
                },
                pushRules: {
                    name: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
                    goods_number: [{ required: true, message: "请输入商品名称", trigger: "blur" }],
                    goods_brief: [{ required: true, message: "请输入商品简介", trigger: "blur" }],
                    goods_desc: [{ required: true, message: "请完善商品详情", trigger: "blur" }],
                    retail_price: [{ required: true, message: "请明确零售价格", trigger: "blur" }],
                    brand_id: [{ required: true, message: "请选择商品品牌", trigger: "blur" }],
                    list_pic_url: [{ required: true, message: "请上传商品图片", trigger: "blur" }],
                    primary_pic_url: [{ required: true, message: "请上传商品主图", trigger: "blur" }],
                    attribute: [{ type:'array', required: true, message: "请添加商品属性", trigger: "blur"}],
                },
                brandOptions: [], // 品牌集合
                subscribe: {
                    isAddSubscribe: false, // 是否开启周期订阅窗口
                    canAddSubscribeDaysList: [...new Array(13)].map((item, index) => {
                        if (index >= 3) {
                            return {
                                label: (index) + '期',
                                value: index
                            }
                        } else {
                            return null
                        }
                    }).filter(item => {return !this.$_.isEmpty(item)}),
                    canSubscribeExpressDaysList: [5,7,10,20,30,35,40,45,50,60,70,80,90,100,120].map((item, index) => {
                        return {
                            label: '每隔' + (item) + '天',
                            value: item
                        }
                    }),
                    subscribeExpressDays: [], // 配送周期
                    subscribeExpressDaysData: [], // 生成的配送周期数据
                    selectedSubscribeCycle: [], // 使用的订阅周期
                    subscribeCycleData: [], // 设置的周期和金额
                },
                pushData: {
                    id: '', // 商品ID
                    category_id: '', // 商品分类
                    name: '', // 商品名
                    brand_id: '', // 品牌名称
                    goods_number: '', // 商品库存数
                    keywords: '', // 关键字
                    goods_brief: '', // 商品简介
                    goods_sn: '', // 商品的商品编码
                    goods_type: 1, // 是否为试用装
                    goods_desc: '', // 商品详情
                    is_on_sale: 1, // 默认在售
                    add_time: '', // 添加时间
                    sort_order: 0, // 排序
                    attribute: [], // 0
                    counter_price: 0, // 专柜价格
                    extra_price: 0, // 附加价格
                    goods_unit: '件', // 商品单位
                    primary_pic_url: '', // 商品主图
                    list_pic_url: '', //商品列表图
                    retail_price: '', // 零售价格
                    sell_volume: '', // 销量
                    primary_product_id: '', // 主sku　product_id
                    unit_price: 0, // 单位价格，单价
                    promotion_desc: '', // 推广详情
                    promotion_tag: '', // 推广tag
                    app_exclusive_price: '', // 专柜价
                    is_app_exclusive: '', // 是否app 专属
                    is_hot: 0, // 是否热卖
                    is_new: 0, // 是否新品首发
                    price_desc: '',
                    discount: 1, // 商品的折扣
                },
                fileList_arr: [], // 提交的商品图数组
                goodsDetailData: {}, // 商品详情
                isThisBrandNotDeleted: false, // 判断该商品是否被删除
                showPreviewPhone: false, // 显示预览

                attributeData: [], // 商品的属性sku
                attrIndexLength: 1, // 商品的sku属性个数
                selectSKUData: {}, // 选择的sku数据参数
                goodsList: [
                    {
                        header_img: '',
                        goods_number: '', // 商品单sku库存
                        selling_price: '', // 商品单sku售价
                        discount: 1, // 商品的折扣价
                        IS_PAGE_SHOW: false, // 是否首页显示
                        remarks: '', // sku备注
                        onlyId: Math.random().toString(36).substr(3,length) + Date.now().toString(36), // 生成随机数的id
                        attributeList: [
                            {
                                random_id: Math.random().toString(36).substr(3,length) + Date.now().toString(36),
                                attribute_value: []
                            }
                        ]
                    }
                ], // 商品的数组
            }
        },
        components: {
            spikeGoods,
            ueditor,
            uploadImage,
            'phone-preview': phonePreview,
            'upload-image': uploadImage,
            'sku-img-upload': uploadImage
        },
        computed: {
            windowWidth() {
                return document.body.clientWidth
            }
        },
        watch: {
            $route: {
                handler (newv) {
                    if (newv.query.id) {
                        this.loadGoodsDetail(newv.query.id).then(res => {
                            this.getParentId()
                        })
                    } else {
                        this.$message.error('null id 商品不存在！')
                    }
                },
                immediate: true
            },
            currentCategory (newv) {
                this.getCurrentCategory(newv)
            },
            recommendChecked:{
                handler (newv){
                    newv = Number(newv)
                    if (newv === 0) {
                        this.pushData.is_hot = 0
                        this.pushData.is_new = 0
                    } else if (newv === 1) {
                        this.pushData.is_hot = 0
                        this.pushData.is_new = 1
                    } else if (newv === 2) {
                        this.pushData.is_hot = 1
                        this.pushData.is_new = 0
                    }
                },
                immediate: true
            }
        },
        methods: {
            // 删除订阅周期
            async removeSubscribeCycle(value) {
                await this.$http.post(this.$api + '/admin/goods/deleteCycle', {
                    goods_id: this.goodsDetailData.id,
                    cycle_value: value
                })
                    .then(res => {
                        this.$message.success('删除周期成功!');
                        this.loadGoodsDetail(this.$route.query.id).then(res => {
                            this.getParentId()
                        })
                    })
            },
            // 删除配送周期
            async removeSubscribeExpress(value) {
                await this.$http.post(this.$api + '/admin/goods/deleteSubscribeExpress', {
                    goods_id: this.goodsDetailData.id,
                    express_value: value
                })
                    .then(res => {
                        this.$message.success('删除配送时间成功!');
                        this.loadGoodsDetail(this.$route.query.id).then(res => {
                            this.getParentId()
                        })
                    })
            },
            // 设置订阅周期
            setSubscribeCycle() {
                this.subscribe.selectedSubscribeCycle = Array.from(new Set(this.subscribe.selectedSubscribeCycle));
                const data = this.subscribe.subscribeCycleData;
                const cycle = [];
                this.subscribe.selectedSubscribeCycle.map(item => {
                    const sub = data.find(su => item == su.subscribe_cycle_times);
                    cycle.push({
                        subscribe_cycle_times: item,
                        subscribe_amount: sub? sub.subscribe_amount : 0,
                        subscribe_price: sub? sub.subscribe_price : 0,
                        desc: sub? sub.desc : '',
                        is_recommend: sub? sub.is_recommend : 0,
                        goods_id: this.goodsDetailData.id,
                        goods_name: this.goodsDetailData.name,
                        list_pic_url: this.goodsDetailData.list_pic_url,
                        id: sub? sub.id:''
                    })
                });
                this.subscribe.subscribeCycleData = cycle
            },
            // 设置配送周期
            setExpressDays () {
                this.subscribe.subscribeExpressDays = Array.from(new Set(this.subscribe.subscribeExpressDays));
                const subscribeExpressDaysData = this.subscribe.subscribeExpressDaysData;
                const data = this.subscribe.subscribeExpressDays.map((item, index) => {
                    const express = subscribeExpressDaysData.find(su => item == su.value);
                    return {
                        label: express? express.label : `${item}天`,
                        value: express? express.value : item,
                        goods_id: this.goodsDetailData.id,
                        is_default: express? express.is_default === 1? true : (index === 0 ? true : false) : false,
                        id: express? express.id:''
                    }
                })
                this.subscribe.subscribeExpressDaysData = data
            },
            // 设置默认的配送周期
            changeExpressTimesDefault(value, express) {
                this.subscribe.subscribeExpressDaysData = this.subscribe.subscribeExpressDaysData.map(item => {
                    if (express.value === item.value) {
                        item.is_default = true
                    } else {
                        item.is_default = false
                    }
                    return item
                })
            },
            // 保存订阅周期的数据
            async saveSubscribe() {
              // 保存数据
                const subscribe = this.$_.cloneDeep(this.subscribe);
                subscribe.subscribeExpressDaysData = subscribe.subscribeExpressDaysData.map(item => {
                    item.is_default = item.is_default? 1: 0;
                    return item
                });
                subscribe.subscribeCycleData = subscribe.subscribeCycleData.map(item => {
                    item.subscribe_price = +item.subscribe_price;
                    item.is_recommend = item.is_recommend? 1: 0;
                    return item
                });
                subscribe.isUpdate = false; // 不是执行更新，而是添加数据
                await this.$http.post(this.$api + '/admin/goods/saveSubscribeData', {
                    data: subscribe
                })
                    .then(res => {
                        this.$message.success('操作成功!');
                        this.subscribe.isAddSubscribe = false

                        this.loadGoodsDetail(this.$route.query.id).then(res => {
                            this.getParentId()
                        })
                    })
            },
            // 设置价格
            changePrice(cycle) {
                this.subscribe.subscribeCycleData.map(item => {
                    if (item.subscribe_cycle_times === cycle.subscribe_cycle_times) {
                        item.subscribe_amount = +((item.subscribe_price * item.subscribe_cycle_times).toFixed(2))
                    }
                    return item
                })
            },
            // 设置总价
            changeAmount(cycle) {
                this.subscribe.subscribeCycleData.map(item => {
                    if (item.subscribe_cycle_times === cycle.subscribe_cycle_times) {
                        item.subscribe_price = +((item.subscribe_amount / item.subscribe_cycle_times).toFixed(2))
                    }
                    return item
                })
            },
            // 商品选择分类触发
            chooseThisCategory (category_id) {
                if (!category_id) {
                    this.$message.error('请重新选择商品分类！');
                    return
                }
                this.$http.post(this.$api + '/admin/goods/goodsAttributes', {
                    category_id: category_id
                }).then(res => {
                    if (res.data.errno === 0) {
                        this.attributeData = res.data.data
                    }
                })
            },
            // 变更首页展示
            changePageShow (bool, item) {
              if (bool) {
                  this.goodsList = this.goodsList.map(function (param) {
                      if (item.onlyId === param.onlyId) {
                          param.IS_PAGE_SHOW = true
                      } else {
                          param.IS_PAGE_SHOW = false
                      }
                      return param;
                  });
                  this.pushData.retail_price = item.selling_price;
                  this.pushData.goods_number = item.goods_number;
                  this.pushData.discount = item.discount;
              }
            },
            // 删除单品
            deleteGoodsSku (sku) {
                if (sku.id) {
                    this.$confirm('该SKU已上线，您正在进行【删除sku】操作，编辑数据时，删除sku会直接生效，无需保存商品编辑数据，确认？', '警示', {
                        confirmButtonText: '删除',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$http({
                            url: this.$api + '/admin/goods/deleteSKUonUpdate',
                            method: 'post',
                            data: this.$qs.stringify({sku_id: sku.id})
                        }).then(response => {
                            if (response.status === 200) {
                                if (response.data.errno == 0) {
                                    this.$message.success('sku已删除！');
                                    this.goodsList.splice(this.goodsList.indexOf(sku), 1)
                                }
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '操作取消'
                        });
                    });
                } else {
                    this.goodsList.splice(this.goodsList.indexOf(sku), 1)
                }

            },
            // 新增单品
            addGoodsSku () {
                this.goodsList.push(
                    {
                        header_img: '',
                        goods_number: '', // 商品单sku库存
                        selling_price: '', // 商品单sku售价
                        discount: 1, // 商品折扣价
                        IS_PAGE_SHOW: false, // 是否首页显示
                        remarks: '', // sku备注
                        onlyId: Math.random().toString(36).substr(3,length) + Date.now().toString(36), // 生成随机数的id
                        attributeList: [
                            {
                                random_id: Math.random().toString(36).substr(3,length) + Date.now().toString(36),
                                attribute_value: []
                            }
                        ]
                    }
                )
            },
            // 新增属性
            up_attrIndexLength (goods) {
                for (let index = 0; index < this.goodsList.length; index++) {
                    if (this.goodsList[index].onlyId === goods.onlyId) {
                        this.goodsList[index].attributeList.push({
                            random_id: Math.random().toString(36).substr(3,length) + Date.now().toString(36),
                            attribute_value: []
                        })
                    }
                }
            },
            // 减少属性
            cut_attrIndexLength (goods, attribute) {
                for (let index = 0; index < this.goodsList.length; index++) {
                    if (this.goodsList[index].onlyId === goods.onlyId) {
                        for (let item = 0; item < this.goodsList[index].attributeList.length ; item ++) {
                            if (attribute.random_id === this.goodsList[index].attributeList[item].random_id) {
                                this.goodsList[index].attributeList.splice(item, 1)
                            }
                        }
                    }
                }
            },
            // 检查是否先选择了商品属性
            attrVisibleChange (visable) {
                if (visable) {
                    if (this.attributeData.length === 0) {
                        this.$message.error('未选择商品分类 或 该分类属性下不存在商品sku属性值！')
                    }
                }
            },
            // 为商品选择SKU
            attributeDataChange (data) {
                console.log(data)
            },
            // 改变品牌
            changeBrand () {
              this.isThisBrandNotDeleted = true
            },
            // 获取商品详情
            async loadGoodsDetail (id) {
                await this.$http({
                    url: this.$api + "/admin/goods/get",
                    data: this.$qs.stringify({ goodsId: id || this.$route.query.id }),
                    method: 'post'
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.goodsDetailData = response.data.data;
                        // sku数据
                        this.goodsList = this.goodsDetailData.SKU;

                        // 设置周期订阅数据
                        response.data.data.subscribeExpressDaysData = response.data.data.subscribeExpressDaysData.map(item => {
                            item.is_default = item.is_default === 1 ? true : false
                            return item
                        });

                        response.data.data.subscribeData = response.data.data.subscribeData.map(item => {
                            item.is_recommend = item.is_recommend === 1 ? true : false
                            return item
                        });

                        const subscribeExpressDaysData = response.data.data.subscribeExpressDaysData;
                        const subscribe = response.data.data.subscribeData;

                        this.subscribe.subscribeCycleData = subscribe;
                        this.subscribe.subscribeExpressDaysData = subscribeExpressDaysData;

                        this.subscribe.selectedSubscribeCycle = subscribe.map(item => {
                            return item.subscribe_cycle_times
                        });
                        this.subscribe.subscribeExpressDays = subscribeExpressDaysData.map(item => {
                            return Number(item.value)
                        });

                        // 获取商品sku选项参数
                        this.$http.post(this.$api + '/admin/goods/goodsAttributes', {
                            category_id: response.data.data.category_id
                        }).then(attribute => {
                            if (attribute.data.errno === 0) {
                                this.attributeData = attribute.data.data
                            }
                        });
                        for (let item in response.data.data) {
                            for (let attr in this.pushData) {
                                if (item == attr) {
                                    this.pushData[attr] = response.data.data[item]
                                }
                            }
                        }
                        for (let item in this.brandOptions) {
                            if (this.brandOptions[item].id == this.pushData.brand_id) {
                                this.isThisBrandNotDeleted = true;
                                break
                            }
                        }

                        // 判断是否直接上线
                        if (this.pushData.is_on_sale == 0) {
                            this.pushData.is_on_sale = true
                        } else {
                            this.pushData.is_on_sale = false
                        }

                        // 判断是否为试用装
                        if (this.pushData.goods_type == 2) {
                            this.pushData.goods_type = true
                        } else {
                            this.pushData.goods_type = false
                        }

                        this.fileList_arr =  response.data.data.gallery

                    }
                });
            },
            // 获取分类父id
            getParentId () {
                this.$http({
                    url: this.$api + "/admin/category/getParent",
                    data: this.$qs.stringify({ categoryId: this.pushData.category_id }),
                    method: 'post'
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.pushData.parent_id = this.currentCategory =  response.data.data.parent_id
                    }
                });
            },
            // 上传轮播图
            handleImageSuccess (res) {
                if (res.errno == 0) {
                    this.fileList_arr.push({
                        id: 0,
                        img_url: res.data
                    })
                }
            },
            // 删除商品属性
            handleRemoveAttribute (index) {
                if(index>=0&&index<this.pushData.attribute.length)
                {
                    this.pushData.attribute.splice(index, 1);
                }
            },
            // 新增一条商品属性
            handleAddAttribute () {
                let obj = {"name":"", "value":""};
                this.pushData.attribute.push(obj);
            },
            // 获取品牌
            getBrand() {
                this.$http({
                    url: this.$api + "/admin/brand/index",
                    data: this.$qs.stringify({ params: { size: 500 } }),
                    method: 'post'
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.brandOptions = this.brandOptions.concat(response.data.data.data);
                    }
                });
            },
            // 删除商品图片, 商品id 为0 时， 说明为新增的图片
            removeGoodsGallery (item) {
                if (item.id === 0) {
                    for (let param in this.fileList_arr) {
                        if (this.fileList_arr[param].img_url === item.img_url) {
                            this.fileList_arr.splice(param, 1)
                        }
                    }
                } else {
                    this.$http({
                        url: this.$api + "/admin/upload/deleteGallery",
                        data: this.$qs.stringify({ id: item.id }),
                        method: 'post'
                    }).then(response => {
                        if (response.data.errno == 0) {
                            for (let param in this.fileList_arr) {
                                if (this.fileList_arr[param].id === item.id) {
                                    this.fileList_arr.splice(param, 1)
                                }
                            }

                            this.$http({
                                url: this.$api + 'admin/file/deleteSingleFile',
                                method: 'post',
                                data: this.$qs.stringify({ filename: item.img_url}),
                            }).then(response => {
                                if (response.data.errno === 0) {
                                    this.$message.success('删除成功');
                                } else {
                                    this.$message(response.data.msg)
                                }
                            })
                        }
                    });
                }
            },
            handleClose(tag) {
                this.addTagsList.splice(this.addTagsList.indexOf(tag), 1);
            },
            async submitPaper() {
                let that =this;
                let is_have_zero_storage = false;
                let is_have_zero_storage_index = 0;

                if (!this.pushData.name || !this.pushData.category_id) {
                    this.$message.error('请完善商品参数后提交！！');
                    return
                }
                // 判断sku是否完善
                let is_not_finish = false;
                for (let item in this.goodsList) {
                    if (!this.goodsList[item].selling_price) {
                        this.$message.error(`请完善第${Number(item) + 1}个商品的价格！！`);
                        is_not_finish = true
                        break
                    }

                    if (!this.goodsList[item].goods_number) {
                        is_have_zero_storage_index = Number(item) + 1
                        is_have_zero_storage = true;
                    }

                    for (let index in this.goodsList[item].attributeList) {
                        if (this.goodsList[item].attributeList[index].attribute_value.length <= 0) {
                            is_not_finish = true
                            this.$message.error(`请完善第${Number(item) + 1}个商品的第${Number(index) + 1}个sku参数！！`);
                            break
                        }


                        if (this.goodsList[item].attributeList[index].attribute_value.length < 2) {
                            is_not_finish = true
                            this.$message.error(`请完善第${Number(item) + 1}个商品的第${Number(index) + 1}个sku参数的第${attribute_value_index}个数据！！`);
                            break
                        }
                    }
                }

                if (is_not_finish) {
                    return
                }

                if (is_have_zero_storage) {
                    // 提示存在库存为0的商品
                    this.$confirm( `第${is_have_zero_storage_index}个商品sku库存为0,请确认，库存为零的商品在前台将不可购买。`, "警示", {
                        confirmButtonText: "确认提交",
                        cancelButtonText: "继续编辑",
                        type: "warning"
                    })
                        .then(async () => {
                            await this.submitData()
                        })
                        .catch(async () => {
                        });
                } else {
                    await this.submitData()
                }
            },
            async submitData () {
                let that = this;
                const loading = this.$loading({
                    lock: true,
                    text: '正在更新商品信息...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });

                // 判断是否需要直接设置成新品或者是是否上线销售，goods_type
                // this.pushData.is_on_sale? this.pushData.is_on_sale = 1:this.pushData.is_on_sale = 0;
                this.pushData.goods_type? this.pushData.goods_type = 2:this.pushData.goods_type = 1;
                this.pushData.is_on_sale = 1; // 默认上线，不允许下线;
                this.pushData.list_pic_url = this.pushData.primary_pic_url;
                // 默认普通商品
                this.pushData.special_type = 1;
                // 商品图册，即商品的轮播图。
                this.pushData.goodsGallery = this.fileList_arr;

                // 添加sku数据
                let SKU = this.goodsList;
                let goods_attribute_key_list = [];
                let Goods_Attribute_List = [];
                for (let item in SKU) {
                    for (let param in SKU[item].attributeList) {
                        SKU[item].attributeList[param].attribute_key = [];
                        that.attributeData.map(function (attr) {
                            if (attr.id === SKU[item].attributeList[param].attribute_value[0]) {
                                SKU[item].attributeList[param].attribute_key.push(attr.label);
                                // 保存在 商品表 数据的key
                                if (goods_attribute_key_list.indexOf(attr.id) === -1) {
                                    goods_attribute_key_list.push(attr.id);
                                    // 生成第一条attr列表数据
                                    Goods_Attribute_List.push({
                                        id: attr.id,
                                        name: attr.label,
                                        value_list: []
                                    })
                                }
                            }

                            attr.children.map(function (attr_value) {
                                if (attr_value.value === SKU[item].attributeList[param].attribute_value[1]) {
                                    SKU[item].attributeList[param].attribute_key.push(attr_value.label)
                                }
                            })
                        })
                    }
                }

                // 把所有相同属性的值组成一个list, 保存在商品数据中
                for (let item in SKU) {
                    for (let param in SKU[item].attributeList) {
                        Goods_Attribute_List = Goods_Attribute_List.map(function (goods_attr_item) {
                            if (goods_attr_item.id === SKU[item].attributeList[param].attribute_value[0]) {
                                that.attributeData.map(function (attr) {
                                    attr.children.map(function (attr_value) {
                                        if (attr_value.value === SKU[item].attributeList[param].attribute_value[1]) {
                                            if (JSON.stringify(goods_attr_item.value_list).indexOf(
                                                JSON.stringify({
                                                    name: attr_value.label,
                                                    id: SKU[item].attributeList[param].attribute_value[1]
                                                })) === -1) {
                                                goods_attr_item.value_list.push(
                                                    {
                                                        name: attr_value.label,
                                                        id: SKU[item].attributeList[param].attribute_value[1]
                                                    })
                                            }
                                        }
                                    })
                                })
                            }
                            return goods_attr_item
                        })
                    }
                }


                this.pushData.SKU = SKU;
                this.pushData.Goods_Attribute_List = Goods_Attribute_List;

                // 提交所有参数
                this.$http({
                    url: this.$api + '/admin/goods/update',
                    method: 'post',
                    data: this.pushData
                }).then(response => {
                    loading.close()
                    if (response.data.errno === 0) {
                        this.$notify({
                            title: '成功',
                            message: '商品编辑成功!',
                            type: 'success'
                        });
                        this.$router.back();
                        let reload = true;
                        bus.$emit('reloadPaper', reload)
                    }
                })
                loading.close()
            },
            listCategory() {
                this.$http({
                    url: this.$api + '/admin/category/parent',
                    method: 'post'
                }).then(response => {
                    if (response.data != null) {
                        this.category = response.data.data
                    }
                })
            },
            getCurrentCategory(id) {
                this.$http({
                    url: this.$api + '/admin/category/current',
                    method: 'post',
                    data: this.$qs.stringify({categoryId: id})
                }).then(response => {
                    if (response.data != null) {
                        this.currentCategoryArr = response.data.data
                    }
                })
            },
        },
        created() {
            this.Auth_token = localStorage.getItem('auth_token')
            this.listCategory()
            this.getBrand()
        },
        mounted() {
            let ct = this.$route.query.ct
            this.uploadData.contentType = ct
        }
    }
</script>
<style>

    .el-upload-list{
        display: none !important;
    }
</style>
<style scoped>
    .operationBtn{
        text-align: right;
        padding: 20px;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .audio-div {
        margin: 20px 0 20px 0;
    }

    .article-edit span {
        width: 100px;
        margin-left: 20px;
    }

    .display-flex {
        display: flex;
    }
</style>
