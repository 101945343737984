<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 系统</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-table :data="ditDetailList" style="width: 100%" >
            <el-table-column label="key" >
              <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium" >{{ scope.row.configKey }}</span>
                  </div>
              </template>
            </el-table-column>
            <el-table-column label="值" >
              <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium" >{{ scope.row.configValue }}</span>
                  </div>
              </template>
            </el-table-column>
            <el-table-column label="设置描述" >
              <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium" >{{ scope.row.describe }}</span>
                  </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <div style="width: 100%;display: flex;justify-content: space-between;">
                    <span @click="editDitValue(scope.row)" class="el-icon-edit-outline ft25"></span>
                    <span @click="deleteDit(scope.row.id)" class="el-icon-delete ft25 red"></span>
                </div>
              </template>
            </el-table-column>
        </el-table>
          <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageContent.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="tableData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageContent.total">
            </el-pagination>
          </div>
        <br />
         <el-button type="primary" @click="addNewBlockWord">新增系统设置</el-button>
         <!-- 新增屏蔽词 -->
           <el-dialog
              title="新增系统设置"
              :visible.sync="showaddDialog"
              width="30%">
              <el-form>
                <el-form-item label="key">
                  <el-input  v-model="addNewWordData.configKey"></el-input>
              </el-form-item>
                <el-form-item label="值">
                  <el-input v-model="addNewWordData.configValue" placeholder="请输入系统配置值"></el-input>
              </el-form-item>
                <el-form-item label="默认值">
                  <el-input v-model="addNewWordData.configDefault" placeholder="请输入系统配置默认值"></el-input>
              </el-form-item>
                <el-form-item label="设置描述">
                  <el-input v-model="addNewWordData.describe" placeholder="请输入系统设置描述"></el-input>
              </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="showaddDialog = false">取 消</el-button>
                <el-button type="primary" @click="saveAddDitValue()">新  增</el-button>
              </span>
            </el-dialog>
            <!-- 修改屏蔽词 -->
          <el-dialog
              title="修改系统设置"
              :visible.sync="showEditDialog"
              width="30%">
              <el-form>
                <el-form-item label="key">
                  <el-input disabled v-model="editData.configKey"></el-input>
              </el-form-item>
                <el-form-item label="值">
                  <el-input v-model="editData.configValue" placeholder="请输入系统配置值"></el-input>
              </el-form-item>
                <el-form-item label="设置描述">
                  <el-input v-model="editData.describe" placeholder="请输入系统设置描述"></el-input>
              </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="showEditDialog = false">取 消</el-button>
                <el-button type="primary" @click="saveEditDitValue(editData.id)">修  改</el-button>
              </span>
            </el-dialog>
    </div>
</template>

<script>
    export default {
        data: function(){
            return {
                ditDetailList: [],
                showEditDialog: false,
                showaddDialog: false,
                pageContent: {
                  current: 1,
                  pages: 1,
                  size: 10,
                  total: 0,
                },
                editData: {
                  configKey: '',
                  configValue: '',
                  describe:'',
                  id: ''
                },
                addNewWordData: {
                  configKey: '',
                  configValue: '',
                  describe:'',
                  id: '',
                  configDefault:''
                }
            }
        },
        created () {
            this.loadBlockWord()
        },
        methods: {
            handleCurrentChange (val) {
              this.loadBlockWord(val,this.pageContent.size)
            },
            handleSizeChange (val) {
              this.loadBlockWord(this.pageContent.pages,val)
            },
            saveAddDitValue () {
                if (this.addNewWordData.configValue == '' | this.addNewWordData.configKey == '') {
                    this.$alert('请完善信息')
                    return
                }
                this.$http({
                    url: this.$api + '/chigua/sys/config/add',
                    method: 'post',
                    data: this.$qs.stringify(this.addNewWordData)
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.loadBlockWord(this.pageContent.pages,this.pageContent.size)
                        // 隐藏窗口
                        this.showaddDialog = false
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            addNewBlockWord () {
              this.showaddDialog = true

            },
            saveEditDitValue (row) {
                this.$http({
                    url: this.$api + '/chigua/sys/config/update',
                    method: 'post',
                    data: this.$qs.stringify(this.editData)
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.loadBlockWord(this.pageContent.pages,this.pageContent.size)
                        // 隐藏窗口
                        this.showEditDialog = false
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            deleteDit (id) {
                this.$http({
                    url: this.$api + '/chigua/sys/config/delete',
                    method: 'post',
                    data: this.$qs.stringify({
                      id: id
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.loadBlockWord(this.pageContent.pages,this.pageContent.size)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            editDitValue (row) {
                this.editData = row
                this.showEditDialog = true
            },
            loadBlockWord (page,limit) {
                this.$http({
                    url: this.$api + '/chigua/sys/config/page',
                    method: 'post',
                    data: this.$qs.stringify({
                      page: page? page:1,
                      limit: limit? limit:10
                    })
                }).then(res => {

                    if (res.data.errno == 0) {
                        this.ditDetailList = res.data.data.records
                        for (var item in res.data.data) {
                          this.pageContent[item] = Number(res.data.data[item])
                        }
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            }
        }
    }
</script>
