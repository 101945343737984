<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>商品管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="submitPaper('all')">所有库存统计表</el-button>
                <el-button type="danger" @click="submitPaper('zero')">0库存统计表</el-button>
                <el-button type="info" @click="submitPaper('almost')">即将断货统计表</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
            }
        },
        components: {

        },
        computed: {
        },
        methods: {
            submitPaper (type) {
                let url = this.$api + '/admin/datacenter/getGoodsStorage?downloadType=allGoods';
                let token = localStorage.getItem('auth_token');
                let filename = '';
                switch (type) {
                    case 'all':
                        filename = '所有商品库存表.xlsx';
                        break;
                    case 'zero':
                        filename = '0库存商品库存表【断货表】.xlsx';
                        break;
                    case 'almost':
                        filename = '临界库存表（即将断货表）.xlsx';
                        break;
                    default:
                        filename = '所有商品库存表.xlsx'
                }
                if (!token) {
                    this.$message.error('请重新登陆后操作！');
                    this.$router.push('/login');
                }
                const loading = this.$loading({
                    lock: true,
                    text: '正在下载~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                try {
                    this.$http.post(url, {downloadType: type}, {
                        responseType: 'blob'
                    }).then(res => {
                        const BLOB = res.data; // Blob 对象表示一个不可变、原始数据的类文件对象（File 接口都是基于Blob）
                        const fileReader = new FileReader(); // FileReader 对象允许Web应用程序异步读取存储在用户计算机上的文件的内容
                        fileReader.readAsDataURL(BLOB); // 开始读取指定的Blob中的内容。一旦完成，result属性中将包含一个data: URL格式的Base64字符串以表示所读取文件的内容
                        fileReader.onload = (event) => { // 处理load事件。该事件在读取操作完成时触发
                            // 新建个下载的a标签，完成后移除。
                            let a = document.createElement('a');
                            a.download = filename;
                            a.href = event.target.result;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                            loading.close();
                        }
                    }).catch(err => {
                        console.log(err.message)
                        this.$message.error(err.message)
                        loading.close();
                    });
                } catch (e) {
                    this.$message.error(e)
                    console.log(e)
                    loading.close();
                }
            }
        }
    }
</script>
<style>
</style>
