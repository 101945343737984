<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（只显示{{ tableData.length }}个商品）</label>
        </div>
        <div class="button-tool">
            <div style="display: flex;justify-content: flex-start;">
                <el-input placeholder="请输入商品名查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 200px;margin-right: 1em;">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
                <el-button type="default" @click="handleSearchByTitle">搜索</el-button>

                <el-button type="primary" @click="getPaperList">更新新品列表</el-button>
            </div>
        </div>
        <el-table
            :data="tableData"
            style="width: 100%" >
            <el-table-column type="index"  width="50" ></el-table-column>
            <el-table-column label="商品id" width="80" prop="id"></el-table-column>
            <el-table-column label="商品名称" >
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <a :href="scope.row.primary_pic_url" target="_blank"><img :src="scope.row.primary_pic_url" style="width: 80px;vertical-align: middle;"></a>
                        <span style="margin-left: 10px">{{ scope.row.name }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="排序" width="100" >
                <template slot-scope="scope">
                    <p style="text-align: center">{{ scope.row.sort_order }}</p>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300px">
                <template slot-scope="scope">
                    <el-input placeholder="请输入变更后的数量" v-model="scope.row.new_sort_order" @keyup.enter.native="changeRankingIndex(scope.row)">
                        <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="changeRankingIndex(scope.row)"></i>
                        <el-button type="success" slot="append" @click="changeRankingIndex(scope.row)">更新</el-button>
                    </el-input>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    export default {
        data: function () {
            return {
                tableData: [],
                searchState: '', // 搜索框绑定的值
            }
        },
        watch: {},
        created() {
            this.getPaperList(0, 1, 10);

        },
        mounted() {

        },
        methods: {
            changeRankingIndex(row) {
                const loading = this.$loading({
                    lock: true,
                    text: '正在更新~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/operationManagement/updateHotGoodsRanking',
                    method: 'post',
                    data: {
                        id: row.id,
                        sort_order: row.new_sort_order
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('更新成功！请核对销量数据');
                        this.getPaperList();
                    }
                    loading.close()
                }).catch(err => {
                    loading.close()
                })
            },
            //按标题搜索列表
            handleContentType() {
                this.getPaperList()
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList()
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size)
            },
            getPaperList() {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/operationManagement/hotGoodsRanking',
                    method: 'post',
                    data: {
                        name: this.searchState
                    }
                }).then(response => {
                    // this.loading = false
                    if (response.data.data) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                }).catch(err => {
                    loading.close()
                })
            }
        }
    }
</script>
<style>
</style>
