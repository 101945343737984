<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 发布管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="container">
            <div class="">
                <h3 class="h3Tag">标题</h3>
                <el-input v-model="uploadData.title" placeholder="请输入标题（最多40个字符）" required></el-input>
                <h3 class="h3Tag">内容</h3>
                <el-input type="textarea" v-model="uploadData.content" placeholder="请输入分享内容" required></el-input>
                <div class="disflex_flex_start">
                    <div>
                        <h3 class="h3Tag">请上传视频</h3>
                        <!--<video :src="uploadData.video" controls v-if="uploadData.video"></video>-->
                        <upload-video  title="视频" v-model="uploadData.video">
                        </upload-video>
                    </div>
                </div>
                <div>
                    <h3>请选择一个用户</h3>
                    <span v-for="item in RobotList.data" :key="item.id" @click="setThisUser(item)">
                            <el-badge style="margin: 5px;" >
                                <el-button class="share-button" >
                                    <img class="width25px" :src="item.avatar" alt="">
                                    {{item.nickname}}
                                </el-button>
                            </el-badge>
                        </span>

                    <el-pagination
                        @size-change="handleSizeChangeRobotList"
                        @current-change="handleCurrentChangeRobotList"
                        :current-page="RobotList.currentPage"
                        :page-size="RobotList.pageSize"
                        layout="total,prev, pager, next"
                        :total="RobotList.count">
                    </el-pagination>
                </div>

            </div>
            <h3  v-if="uploadData.author">使用该用户发布：</h3>
            <el-card v-if="uploadData.author" >
                <img :src="uploadData.avatar" alt="" class="width45px">
                <span style="font-weight: bold;font-size: 15px;margin-left: 1em;">{{uploadData.author}}</span>
            </el-card>
            <div class="disflex_flex_end">
                <el-button class="editor-btn" type="primary" @click="submitAddQuestion">发布</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import bus from '../../common/bus'
    import uploadVideo from '../../common/uploadVideo'
    export default {
        data: function () {
            return {
                tableData: [],
                isShowUpload: true,
                uploadData: {
                    title: '',
                    content: '',
                    video: '',
                    gallery: [],
                    create_by: 0,
                    author: '',
                    avatar: ''
                },
                RobotList: {}

            }
        },
        components: {
            uploadVideo: uploadVideo
        },
        watch: {},
        created() {
            this.getRobotList(1, 20)
        },
        mounted() {
            let that = this;
            // 上传组件emit的是上传事件
            bus.$on('fileTraceBack', function (item) {
                that.uploadData.gallery.push(item);
            })
        },
        methods: {
            setThisUser (item) {
                this.uploadData.create_by = item.id;
                this.uploadData.author = item.nickname;
                this.uploadData.avatar = item.avatar;
            },
            handleSizeChangeRobotList (val) {
                this.getRobotList(this.RobotList.currentPage, val, this.sort)
            },
            handleCurrentChangeRobotList (val) {
                this.getRobotList( val, this.RobotList.pageSize, this.sort)
            },
            getRobotList(page, length) {
                this.$http({
                    url: this.$api + '/admin/user/getAllRobot',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 20,
                    })
                }).then(response => {
                    if (response.data.data) {
                        this.RobotList = response.data.data;
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            submitAddQuestion () {
                this.$http({
                    url: this.$api + '/admin/community/saveRobotPublishVideo',
                    method: 'post',
                    data: this.uploadData
                }).then(response => {
                    if (response.data.data) {
                        this.isShowUpload = false
                        this.$confirm('发布成功！', '提示', {
                            confirmButtonText: '继续添加',
                            cancelButtonText: '返回上一页',
                            type: 'success'
                        }).then(() => {
                            this.uploadData =  {
                                    title: '',
                                    content: '',
                                    video: '',
                                    create_by: 0,
                                    author: '',
                                    avatar: '',
                                    type: 4 // 视频类型为4
                            }
                            this.isShowUpload = true
                        }).catch(() => {
                            this.$router.back()
                        });
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            }
        }
    }
</script>
<style scoped>

    .el-upload-list .el-upload-list--text .el-upload .el-upload--text {
        width: 200px !important;
    }

    .el-upload .el-upload--text {
        width: 240px !important;
    }

    .editor-btn {
        margin-top: 20px;
    }

    #title-input:-ms-input-placeholder, #title-input:-moz-placeholder {
        font-size: 30px;
        text-align: left;
    }

    .button-invite {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        padding: 5px 45px 5px 35px;
        background-color: #f0f0f0;
        border-radius: 10px;
        font-size: 18px;
    }

</style>
