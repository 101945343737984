<template>
    <div>
        <div class="crumbs" style="width: 100%;">
            <label>
                <span>{{ $route.meta.title }}</span>（用户发布）
            </label>
            <div>
                <el-input style="width:260px; display: block;
  float: right;" placeholder="请输入专栏名或作者查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="suffix" @click="handleSearchByTitle"></i>
                </el-input>
                <el-button type='primary' style=" display: block;margin-right:50px;
  float: right;" size="mini" @click="$router.push('/newsColumnList')">新建专栏
                </el-button>
            </div>

        </div>
        <el-table :data="tableData"  style="width: 100%">
            <el-table-column label="专栏名称">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper" @click="goTo(scope.row)" style="cursor:pointer">
                        <img :src="scope.row.picture" :onerror="errorUserPhoto"
                             style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.name }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="作者" width="120">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper" @click="goTo(scope.row)" style="cursor:pointer">
                        <span style="margin-left: 10px">{{scope.row.authorName}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="发布内容" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.publishNum === null? 0:scope.row.publishNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="阅读总数" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.browseNum === null? 0:scope.row.browseNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="评论总数" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.commentNum === null? 0:scope.row.commentNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="点赞总数" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.praiseNum=== null? 0:scope.row.praiseNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收藏总数" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.collectNum=== null? 0:scope.row.collectNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="分享总数" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.forwardNum=== null? 0:scope.row.forwardNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="创建日期" width="180">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ formatStrTime(scope.row.createTime,16) }}</span>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="120px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-around;height:35px;
     align-items: flex-end;">
                        <!-- 上下线 -->
                        <i title="下线" class="el-icon-remove-outline ft25 pointer"
                           @click="downLineColumn(scope.row)"></i>
                        <!-- 删除 -->
                        <i title="删除" class="el-icon-delete ft25 red pointer" @click="deleteVideo(scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="下线视频" :visible.sync="uppaper" width="50%">
            <el-form ref="form" :model="offLineData.form">
                <span>请选择理由(可多选)</span>
                <el-form-item prop="type">
                    <el-checkbox-group v-model="offLineData.form.type">
                        <el-checkbox style="width:45%; margin-left: 30px;" label="标题、图片或内容低俗,具有性暗示"
                                     name="type"></el-checkbox>
                        <el-checkbox style="width:45%" label="色情低俗" name="type"></el-checkbox>
                        <el-checkbox style="width:45%" label="垃圾广告" name="type"></el-checkbox>
                        <el-checkbox style="width:45%" label="内容或图片暴力、恐怖、血腥" name="type"></el-checkbox>
                        <el-checkbox style="width:45%" label="内容不相关" name="type"></el-checkbox>
                        <el-checkbox style="width:45%" label="人身攻击" name="type"></el-checkbox>
                        <el-checkbox style="width:45%" label="含政治敏感信息" name="type"></el-checkbox>
                        <el-checkbox style="width:45%" label="赌博诈骗" name="type"></el-checkbox>
                        <el-checkbox style="width:45%" label="发布违法违禁信息" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="其他" prop="desc">
                    <el-input type="textarea" size="medium" v-model="offLineData.form.desc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="float:right" @click="onSubmit">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>
    </div>
</template>

<script>
    import bus from "../../common/bus";
    import commonlist from "../../common/userCommon";
    import {Loading} from "element-ui";
    import userPhoto from '@/assets/nofind.png'

    export default {
        data: function () {
            return {
                activeName: "全部",
                addPaperType_bool: false,
                tableData: [],
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 0,
                errorUserPhoto: 'this.src="' + userPhoto + '"',
                currentTagId: "",
                paperStatus: "未上线",
                paperId: "",
                searchState: "",
                showCommon: false,
                commonData: "",
                addPaperData: {
                    name: ""
                },
                editPaperData: {
                    name: ""
                },
                paperTypeData: [],
                editPaperStr: "新  增",
                oprationType: "add",
                tableHeight: 600,
                is_edit: false,
                uppaper: false,
                offLineData: {
                    ids: "",
                    // status: "",
                    form: {
                        desc: "",
                        type: []
                    }
                }
            };
        },
        components: {
            commonlist
        },
        watch: {},
        created() {
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653;
            this.getPaperList(0, 1, 10);
        },
        mounted() {
            // 更新列表数据
            bus.$on("reloadVideo", reload => {
                if (reload) {
                    this.getPaperList(this.currentTagId, this.currentPage, this.size);
                }
            });
        },
        methods: {
            // 下线操作\
            downLineColumn(row) {
                // //console.log(row);

                this.uppaper = true;
                this.offLineData.ids = row.id;
                // this.offLineData.status = row.status;
            },
            onSubmit() {
                let url = "",
                    tips = "操作成功",
                    alertText = "";
                url = this.$api + "/api/column/info/offline";
                tips = "下线成功！";
                alertText = "下线";
                this.$http({
                    url: url,
                    method: "post",
                    data: this.$qs.stringify({
                        ids: this.offLineData.ids,
                        offlineReason: this.offLineData.form.type + this.offLineData.form.desc
                    })
                }).then(response => {
                    // //console.log(response);

                    if (response.status === 200) {
                        if (response.data.errno === 0) {
                            this.$message.success(tips);
                            this.getPaperList(this.currentTagId, this.currentPage, this.size);
                            this.uppaper = false;
                        }
                    }
                });
            },
            // 删除视频类型
            deletePaperType(item) {
                this.$http({
                    url: this.$api + "/chigua/admin/video/category/delete",
                    method: "post",
                    data: this.$qs.stringify({
                        id: item.id
                    })
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.$message.success(res.data.errmsg);
                            this.loadPaperType();
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 新增视频类型
            submit_add_paper_type() {
                let url = "/chigua/admin/video/category/add";
                this.$http({
                    url: this.$api + url,
                    method: "post",
                    data: this.$qs.stringify({
                        id: this.addPaperData.id,
                        name: this.addPaperData.name,
                        sort: this.addPaperData.sort ? this.addPaperData.sort : 99
                    })
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.$message.success(res.data.errmsg);
                            this.addPaperData.name = "";
                            this.loadPaperType();
                            this.oprationType = "add";
                            this.editPaperStr = "新  增";
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 新增视频类型
            submit_edit_paper_type() {
                let url = "/chigua/admin/video/category/update";
                this.$http({
                    url: this.$api + url,
                    method: "post",
                    data: this.$qs.stringify({
                        id: this.editPaperData.id,
                        name: this.editPaperData.name,
                        sort: this.editPaperData.sort ? this.editPaperData.sort : 99
                    })
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.$message.success(res.data.errmsg);
                            this.editPaperData.name = "";
                            this.loadPaperType();
                            this.oprationType = "add";
                            this.editPaperStr = "新  增";
                            this.is_edit = false;
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 编辑视频类型
            editPaperType_btn() {
                this.addPaperType_bool = true;
            },
            editPaperType_btn_inside(item) {
                for (var i in item) {
                    this.editPaperData[i] = item[i];
                }
                this.is_edit = true;
                this.editPaperStr = "保存编辑";
                this.oprationType = "edit";
            },
            // 加载视频类型
            loadPaperType() {
                this.$http({
                    url: this.$api + "/chigua/admin/video/category/list",
                    method: "post"
                }).then(response => {
                    if (response.data.data != null) {
                        this.tagSwitchData = response.data.data;
                        // 添加“全部”分类按钮
                        let addAll = {};
                        addAll.id = 0;
                        addAll.name = "全部";
                        addAll.isDeleted = false;
                        this.tagSwitchData.unshift(addAll);
                    }
                });
            },
            // 跳转到专栏详情页
            goTo(row) {
                this.paperId = row.id;
                this.paperTitle = row.name
                bus.$emit("userColumnChange", row.id);
                this.$router.push({
                    path: '/userColumnDetails',
                    query: {
                        id: row.id,
                    }
                });
            },
            // 删除视频
            deleteVideo(row) {
                this.$confirm("您正在进行【删除专栏】操作，确认？", "警示", {
                    confirmButtonText: "删除",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.$http({
                            url: this.$api + "/api/column/info/delete",
                            method: "post",
                            data: this.$qs.stringify({ids: row.id})
                        }).then(response => {
                            if (response.status === 200) {
                                if (response.data.errno === 0) {
                                    this.$message.success("专栏已删除！");
                                    this.getPaperList(
                                        this.currentTagId,
                                        this.currentPage,
                                        this.size
                                    );
                                }
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作取消"
                        });
                    });
            },
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size);
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.currentPage, val);
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size);
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: "已经很努力加载啦~",
                    spinner: "el-icon-loading",
                    background: "rgba(183, 183, 183, 0.57)"
                });
                if ((page === undefined) | (page === null)) {
                    page = 1;
                } else if ((length === undefined) | (length === null)) {
                    length = this.size;
                }
                this.$http({
                    url: this.$api + "/api/column/info/list",
                    method: "post",
                    data: this.$qs.stringify({
                        queryType: type,
                        queryName: this.searchState,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        for (var item in response.data.data.records) {
                            if (response.data.data.records[item].status === 1) {
                                response.data.data.records[item].paperStatus = "可上线";
                                // response.data.data.records[item].color = '#e3e3e3'
                            } else if (response.data.data.records[item].status === 2) {
                                response.data.data.records[item].paperStatus = "已上线";
                                // response.data.data.records[item].color = '#49293'
                            } else if (response.data.data.records[item].status === 3) {
                                response.data.data.records[item].paperStatus = "已下线";
                                // response.data.data.records[item].color = '#ff0000'
                            } else {
                                response.data.data.records[item].paperStatus = "未知状态";
                            }
                        }
                        this.tableData = response.data.data.records;
                        this.currentPage = response.data.data.current;
                        this.total = response.data.data.total;
                        this.size = response.data.data.size;
                        loading.close();
                    }
                });
            },
            switchTag(tab) {
                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {
                        this.searchState = "";
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10);
                        this.currentTagId = this.tagSwitchData[i].id;
                    }
                }
            }
        }
    };
</script>
<style>
    .crumbs {
        display: flex;
        justify-content: space-between;

    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped><
.tagSwitch {
    width: 100%;
    height: auto;
}

/
style >
