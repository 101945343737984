<template>
    <div >
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}人）</label>
        </div>

        <el-table :data="tableData.data"
                  v-loading="!tableData.data" style="width: 100%"
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column label="头像" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.group_avatar" style="width: 40px;vertical-align: middle;">
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="公号用户id" prop="belong_user_id" width="80"></el-table-column>
            <el-table-column label="真实姓名" prop="real_name" width="150"></el-table-column>
            <el-table-column label="微信号" prop="last_login_ip" width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.wechat}}</span>
                </template>
            </el-table-column>
            <el-table-column label="手机号" prop="mobile" width="120"></el-table-column>

            <el-table-column label="审核状态" width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.permit_status_str}}</span>
                </template>
            </el-table-column>


            <el-table-column label="审核结果" width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.permit_msg}}</span>
                </template>
            </el-table-column>

            <el-table-column label="提交申请时间" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.add_time }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div class="disflex_between_center">
                        <!-- 编辑 -->
                        <el-button type="primary" @click="$router.push('/serviceUserDetail?service_id=' + scope.row.belong_user_id)">
                            用户综合信息
                        </el-button>
                        <el-button type="danger" @click="showJudgeDialogFun(scope.row)">
                            审核
                        </el-button>
                    </div>
                </template>
            </el-table-column>


        </el-table>

        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>

        <el-dialog title="猫趴合伙人审核" :visible.sync="showJudgeDialog">

            <el-form :model="judgeData"  ref="judgeData">
                <el-form-item label="" prop="ShipperName">
                    <el-card :body-style="{ padding: '0px', display: 'flex' }">
                        <img :src="judgeData.group_avatar" style="width: 80px;height: 80px;">
                        <div class="bottom clearfix">
                            <p>组长名称： {{judgeData.real_name}}</p>
                            <p>微信号： {{judgeData.wechat}}</p>
                            <p>手机号：{{judgeData.apply_mobile}}</p>
                            <p>申请：{{judgeData.add_time}}</p>
                            <p>小组状态：{{judgeData.permit_status_str}}</p>
                        </div>
                    </el-card>
                </el-form-item>


                <!--快递单号-->
                <el-form-item label="审核意见：" prop="LogisticCode">
                    <el-input v-model="judgeData.permit_msg" autocomplete="off"></el-input>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button type="warn" @click="permitAccess(3)">因资料不全驳回</el-button>
                <el-button type="danger" @click="permitAccess(2)">申请失败驳回</el-button>
                <el-button type="primary" @click="permitAccess(1)">审核通过</el-button>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                tableData: {},
                tableHeight: 0,
                showJudgeDialog: false,
                judgeData: {}
            }
        },
        components: {},
        watch: {},
        created() {
            this.getPaperList(1, 10)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
        },
        mounted() {

        },
        methods: {
            permitAccess (permit_status) {
                if (!permit_status) {
                    this.$message.error('请选择审核状态')
                    return
                }
                this.$http({
                    url: this.$api + '/admin/serviceAccount/authorization',
                    method: 'post',
                    data: {
                        apply_id: this.judgeData.id,
                        permit_status: permit_status,
                        permit_msg: this.judgeData.permit_msg
                    }
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.$message.success(response.data.msg || response.data.data);
                        this.getPaperList(this.tableData.currentPage, this.tableData.pageSize)
                    } else {
                        this.$message.error(response.data.msg)
                    }
                })
            },
            showJudgeDialogFun (data) {
                this.judgeData = data;
                this.showJudgeDialog = true;
            },
            switchTag (e) {
                this.activeName = e.label;
                if (e.label === '黑名单' || e.index === 1) {
                    this.getPaperList(1, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            handleSelectionChange (val) {
                this.selectUserList = val
            },
            // 批量加入黑名单
            addMultiUserToBlackList () {
                let allName = '';
                let userList = this.selectUserList.map(function (item) {
                    allName += (item.nickname + '--');
                    return {
                        id: item.id,
                        is_limit: 1
                    }
                })
                this.$confirm('您正在批量把【' + userList.length + '】人加入黑名单！名单：' + allName + ', 正在批量加入黑名单，请谨慎操作。', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/user/addMultiUserToBlackList',
                        method: 'post',
                        data: {
                            user_list: userList
                        }
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success(response.data.data)
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            handleSearchByTitle() {
                if (this.activeName === '黑名单') {
                    this.getPaperList(1, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            handleSizeChange(val) {

                if (this.activeName === '黑名单') {
                    this.getPaperList(1, this.tableData.currentPage || 1, val, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, val, this.searchTitle)
                }
            },
            handleCurrentChange(val) {
                if (this.activeName === '黑名单') {
                    this.getPaperList(val, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(val, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            getPaperList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/serviceAccount/getTeamApply',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page || 1,
                        size: length || 10
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style scoped>
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .pointer {
        cursor: pointer;
    }
</style>

