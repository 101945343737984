<template>
    <div class="mike_con padding15">

        <el-form label-width="150px" class="form_con padding15">

            <!--基本信息-->
            <div class="mike_part left ">
                <el-alert title="短信发送工具" type="info" :closable="false" class="coupon_title"></el-alert>
                <br/>
                <!--短信名称名称-->
                <el-form-item label="请选择短信模板" prop="name">
                    <el-select v-model="TemplateCode" placeholder="请选择短信模板">
                        <el-option
                            v-for="item in ValidSmsTemplate"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>

                <!--优惠券描述-->
                <el-form-item label="短信内容" prop="name_desc">
                    <el-input type="textarea" v-model="TemplateExample"></el-input>
                </el-form-item>


            </div>

            <!--按钮-->
            <el-form-item>
                <el-button type="primary" @click="sendSms">发送</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "addCoupon",
        data() {
            return {
                TemplateCode: '',
                TemplateParam: {},
                TemplateExample: '',
                PhoneNumbers: '',
                ValidSmsTemplate: []
            }
        },
        mounted () {
            this.getValidSmsTemplate();
        },
        watch: {
        },
        methods: {
            sendSms () {
                this.$http({
                    url: this.$api + '/admin/adminManagement/sendSms',
                    method: 'post',
                    data: {
                        TemplateCode: '',
                        TemplateParam: {},
                        PhoneNumbers: ''
                    }
                }).then(response => {
                    if (response.data.errno === 0) {

                    } else {
                        this.$message.error('发送失败！请刷新重试！')
                    }
                })
            },
            getValidSmsTemplate () {
                this.$http({
                    url: this.$api + '/admin/adminManagement/getSmsTemplate'
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.ValidSmsTemplate = response.data.data
                    } else {
                        this.$message.error('发送失败！请刷新重试！')
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
</style>
