<template>
    <!--添加疾病分类-->
    <div>
        <el-dialog title="修改疾病分类" :visible.sync="editTypeDialogData.isShow">

            <el-form :model="foodTypeForm" :rules="rules" ref="foodTypeForm">
                <!--添加分类的名称-->
                <el-form-item label="分类名称：" prop="name">
                    <el-input v-model="foodTypeForm.name" autocomplete="off"></el-input>
                </el-form-item>

                <!--添加分类的序号-->
                <el-form-item label="分类序号：">
                    <el-input v-model="foodTypeForm.sort_order" autocomplete="off"></el-input>
                </el-form-item>

                <!--添加的分类是否立即上线-->
                <el-form-item label="是否立即上线：">
                    <el-switch
                        v-model="foodTypeForm.is_show" active-color="#13ce66" inactive-color="#ccc">
                    </el-switch>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="editTypeDialogData.isShow = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </div>

        </el-dialog>
    </div>

</template>

<script>
    export default {
        name: "editFormDialog",
        props: {
            editTypeDialogData: {
                type: Object,
                default: {
                    isShow: false,
                    type_id: null
                }
            },
            isDraft: {
                type: Boolean,
                default: false,
            }
        },
        watch:{
            'editTypeDialogData.type_id': {
                handler(newId, oldId) {
                    console.log(newId,oldId);
                    this.getTypeDetail();
                },
            }
        },
        data(){
            return {
                foodTypeForm: {
                    name: '',
                    sort_order: 0,
                    is_show: true,
                },
                rules: {
                    name: [{required: true, message: "请输入疾病分类名称", trigger: 'blur'}]
                }
            }
        },
        methods: {
            //获取疾病分类信息
            getTypeDetail(){
                this.$http.post(this.$api + 'admin/disease/get', {
                    foods_category_id: this.editTypeDialogData.type_id,
                }).then(res => {
                    if(res.data.errno===0){
                        let info = res.data.data;
                        this.foodTypeForm.sort_order = info.sort_order;
                        this.foodTypeForm.name = info.name;
                        this.foodTypeForm.is_show = info.is_show==1?true:false;
                    }
                })
            },
            //修改疾病分类弹窗确定
            submitForm() {
                this.$refs['foodTypeForm'].validate((valid) => {
                    if (valid) {
                        this.editTypeDialogData.isShow = false;
                        this.editTypeSubmit();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //编辑疾病分类提交
            editTypeSubmit(){
                this.$http.post(this.$api + 'admin/disease/update', {
                    name : this.foodTypeForm.name,
                    id : this.editTypeDialogData.type_id,
                    sort_order: this.foodTypeForm.sort_order,
                    is_show: this.foodTypeForm.is_show ? 1 : 0
                }).then(res => {
                    // console.log('退款res',res);
                    if (res.data.errno === 0) {
                        this.foodTypeForm.sort_order = 0;
                        this.foodTypeForm.name = '';
                        this.$message.success('修改疾病分类成功！');
                        if(this.isDraft){
                            this.$emit("editDraftSuccess");
                        }else{
                            this.$store.dispatch('getDiseaseList');
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
