<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 统计分析</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName">
                <el-tab-pane label="单篇文章" name="single">
                    <div class="article-analyse">
                        <el-card class="article-analyse-table">
                            <div slot="header" style="display: flex;justify-content: space-between">
                                <my-date-picker @dateChange="singletableDateChange"></my-date-picker>
                            </div>
                            <el-table @sort-change="handleSingleSortTable" :data="singleTableData" style="width: 100%">
                                <el-table-column prop="title" label="标题" align="left"/>
                                <el-table-column prop="author" label="作者" width="180"/>
                                <el-table-column prop="dateTime" label="时间" sortable="'custom'" width="180"/>
                                <!--</el-table-column>-->

                                <!--阅读-->
                                <el-table-column label="阅读">
                                    <el-table-column prop="readPerson" label="人数" sortable="'custom'"
                                                     width="100"></el-table-column>
                                    <el-table-column prop="browseNum" sortable="'custom'" label="次数" width="100"></el-table-column>
                                </el-table-column>
                                <!--评论-->
                                <el-table-column label="评论">
                                    <el-table-column prop="commentPerson" label="人数" sortable="'custom'"
                                                     width="100"></el-table-column>
                                    <el-table-column prop="commentNum" sortable="'custom'" label="次数" width="100"></el-table-column>
                                </el-table-column>
                                <!--点赞-->
                                <el-table-column label="点赞">
                                    <el-table-column prop="praiseNum" sortable="'custom'" label="次数"  width="150"></el-table-column>
                                </el-table-column>
                                <!--分享-->
                                <el-table-column label="分享">
                                    <el-table-column prop="forwardNum" sortable="'custom'" label="次数"  width="150"></el-table-column>
                                </el-table-column>
                            </el-table>
                            <div class="paginaion" style="text-align: center;margin-top: 1em">
                                <el-pagination
                                    @size-change="handleSingleSizeChange"
                                    @current-change="handleSingleCurrentChange"
                                    :current-page="singlecurrentPage"
                                    :page-sizes="[10, 20, 30, 40]"
                                    :page-size="tableData.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="singletotal">
                                </el-pagination>
                            </div>
                        </el-card>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="全部文章" name="all">
                    <div class="article-analyse">
                        <el-card class="box-card article-analyse-keyword">
                            <div slot="header" class="clearfix">
                                <span>昨日关键数据</span>
                            </div>
                            <div class="my-box-card">
                                <table v-for="o in keyData" :key="o.id">
                                    <tr>
                                        <td colspan="3" class="ft20">{{o.name}}</td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" class="ft30">{{o.number}}</td>
                                    </tr>
                                    <tr>
                                        <td>日</td>
                                        <td>{{Math.abs(o.dayPercent)}}%</td>
                                        <td><i
                                            :class="o.dayPercent<0?'el-icon-czd-jiantou1-copy-copy red':(o.dayPercent===0?'el-icon-minus':'el-icon-czd-jiantou1 green')"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>周</td>
                                        <td>{{Math.abs(o.weekPercent)}}%</td>
                                        <td><i
                                            :class="o.weekPercent<0?'el-icon-czd-jiantou1-copy-copy red':(o.weekPercent===0?'el-icon-minus':'el-icon-czd-jiantou1 green')"></i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>月</td>
                                        <td>{{Math.abs(o.monthPercent)}}%</td>
                                        <td><i
                                            :class="o.monthPercent<0?'el-icon-czd-jiantou1-copy-copy red':(o.monthPercent===0?'el-icon-minus':'el-icon-czd-jiantou1 green')"></i>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </el-card>

                        <div class="article-analyse-button">
                            <el-radio-group v-model="buttonType" @change="loadArticleCharts(buttonType)">
                                <el-radio-button v-for="t in buttonTypes" :key="t.value" :label="t.value">{{t.name}}
                                </el-radio-button>
                            </el-radio-group>
                        </div>
                        <el-card class="article-analyse-charts">
                            <div slot="header">
                                <my-date-picker @dateChange="chartsDateChange"></my-date-picker>
                            </div>
                            <multi-charts :charts-data="chartsData"></multi-charts>
                        </el-card>

                        <el-card class="article-analyse-table">
                            <div slot="header" style="display: flex;justify-content: space-between">
                                <my-date-picker @dateChange="tableDateChange"></my-date-picker>
                                <el-button style="margin-right: 30px" type="text" @click="exportArticleAnalyse">导出列表
                                </el-button>
                            </div>
                            <el-table @sort-change="handleSortTable" :data="tableData" style="width: 100%">
                                <el-table-column prop="dateTime" label="时间" sortable="'custom'"
                                                 width="180"></el-table-column>
                                <!--阅读-->
                                <el-table-column label="阅读">
                                    <el-table-column prop="readPerson" label="人数" sortable="'custom'"
                                                     width="180"></el-table-column>
                                    <el-table-column prop="browseNum" sortable="'custom'" label="次数" width="180"></el-table-column>
                                </el-table-column>
                                <!--评论-->
                                <el-table-column label="评论">
                                    <el-table-column prop="commentPerson" label="人数" sortable="'custom'"
                                                     width="180"></el-table-column>
                                    <el-table-column prop="commentNum" sortable="'custom'" label="次数" width="180"></el-table-column>
                                </el-table-column>
                                <!--点赞-->
                                <el-table-column label="点赞">
                                    <el-table-column prop="praiseNum" sortable="'custom'" label="次数"  width="180"></el-table-column>
                                </el-table-column>
                                <!--分享-->
                                <el-table-column label="分享">
                                    <el-table-column prop="forwardNum" sortable="'custom'" label="次数"  width="180"></el-table-column>
                                </el-table-column>
                            </el-table>
                            <div class="paginaion" style="text-align: center;margin-top: 1em">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="currentPage"
                                    :page-sizes="[10, 20, 30, 40]"
                                    :page-size="tableData.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="tableData.count">
                                </el-pagination>
                            </div>
                        </el-card>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import multiCharts from '../../common/multiCharts'
    import myDatePicker from '../../common/myDatePicker'

    export default {
        components: {
            multiCharts,
            myDatePicker
        },
        data: () => ({
            currentPage: 1,
            total: 0,
            size: 10,
            sortName: '',
            sortType: '',
            singlecurrentPage: 1,
            singletotal: 0,
            singlesize: 10,
            singlesortName: '',
            singlesortType: '',

            chartsData: [],
            chartsTitle: '新增用户数',
            tableData: [],
            singleTableData: [],
            keyData: [],
            activeName: 'all',

            buttonType: '',
            buttonTypes: [],
            selectTime: '',

            tableStartTime: '',
            tableEndTime: '',

            singletableStartTime: '',
            singletableEndTime: '',
            queryData: {
                startTime: '',
                endTime: '',
                type: '1'
            },
        }),

        created() {

        },
        mounted() {
            this.buttonTypes = this.$getDicByName('ARTICLE_ANALYZE_GRAPH_TYPE')
            this.buttonType = this.buttonTypes[0].value
            this.chartsTitle = this.buttonTypes[0].name
            this.loadKeyData()
        },
        methods: {
            //导出用户分析列表
            exportArticleAnalyse() {
                this.$export({
                    url: this.$api + '/chigua/admin/analyze/article/export',
                    data: {
                        startTime: this.tableStartTime,
                        endTime: this.tableEndTime,
                        colName: this.sortName,
                        order: this.sortType
                    }
                })
            },
            tableDateChange(data) {
                this.tableStartTime = data[0]
                this.tableEndTime = data[1]
                this.loadTableData()
            },
            singletableDateChange(data) {
                this.singletableStartTime = data[0]
                this.singletableEndTime = data[1]
                this.loadSingleTableData()
            },
            //组件自动触发事件
            chartsDateChange(data) {
                this.queryData.startTime = data[0]
                this.queryData.endTime = data[1]
                this.queryData.type = this.buttonType
                this.loadArticleCharts()
            },

            handleSortTable(data) {
                //console.log(data.column, data.prop, data.order)
                if ('descending' === data.order) {
                    this.sortType = 'DESC'
                } else if ('ascending' === data.order) {
                    this.sortType = 'ASC'
                } else {
                    this.sortType = ''
                }
                this.sortName = data.prop
                this.loadTableData()
            },
            handleSizeChange(val) {
                this.size = val
                this.loadTableData()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.loadTableData()
            },
            loadTableData() {
                this.getTableData(this.currentPage, this.size, this.tableStartTime, this.tableEndTime, this.sortName, this.sortType)
            },
            getTableData(page, size, startTime, endTime, colName, order) {
                this.$http({
                    url: this.$api + '/chigua/admin/analyze/article/all',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page,
                        limit: size,
                        startTime: startTime,
                        endTime: endTime,
                        colName: colName,
                        order: order
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.tableData = res.data.data.records
                        this.currentPage = res.data.data.current
                        this.total = res.data.data.total
                        this.size = res.data.data.size
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },

            handleSingleSortTable(data) {
                // //console.log(data.column, data.prop, data.order)
                if ('descending' === data.order) {
                    this.singlesortType = 'DESC'
                } else if ('ascending' === data.order) {
                    this.singlesortType = 'ASC'
                } else {
                    this.singlesortType = ''
                }
                this.singlesortName = data.prop
                this.loadSingleTableData()
            },
            handleSingleSizeChange(val) {
                this.singlesize = val
                this.loadSingleTableData()
            },
            handleSingleCurrentChange(val) {
                this.singlecurrentPage = val
                this.loadSingleTableData()
            },
            loadSingleTableData() {
                this.getSingleTableData(this.singlecurrentPage, this.singlesize, this.singletableStartTime, this.singletableEndTime, this.singlesortName, this.singlesortType)
            },
            getSingleTableData(page, size, startTime, endTime, colName, order) {
                this.$http({
                    url: this.$api + '/chigua/admin/analyze/article/single',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page,
                        limit: size,
                        startTime: startTime,
                        endTime: endTime,
                        colName: colName,
                        order: order
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.singleTableData = res.data.data.records
                        this.singlecurrentPage = res.data.data.current
                        this.singletotal = res.data.data.total
                        this.singlesize = res.data.data.size
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },

            loadKeyData() {
                this.$http({
                    url: this.$api + '/chigua/admin/analyze/article/yesterday',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.keyData = res.data.data
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            // 加载近三个月数据
            loadArticleCharts(type) {
                if (type) this.queryData.type = type
                for (let t in this.buttonTypes) {
                    if (this.buttonTypes[t].value === type) {
                        this.chartsTitle = this.buttonTypes[t].name
                    }
                }
                this.$http({
                    url: this.$api + '/chigua/admin/analyze/article/graph',
                    method: 'post',
                    data: this.$qs.stringify(this.queryData)
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.chartsData = res.data.data
                        if (this.chartsTitle == '阅读') {
                            this.chartsData.unshift(['product', '总阅读人数', '总阅读次数'])
                        } else if (this.chartsTitle == '评论') {
                            this.chartsData.unshift(['data', '总评论人数', '总评论次数'])
                        } else if (this.chartsTitle == '点赞') {
                            this.chartsData.unshift(['sd', '点赞次数'])
                        } else if (this.chartsTitle == '分享') {
                            this.chartsData.unshift(['dss', '分享次数'])
                        }
                        this.chartsData.unshift(this.chartsTitle)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
        }
    }
</script>

<style>
    .article-analyse .el-card__header {
        padding: 1px;
        background-color: #f0f0f0;
    }
    .article-analyse-table .el-card__body {
        padding: 0px;
    }

    .article-analyse-table th{
        text-align: center;
        background-color: #f0f0f0;
    }

</style>
<style scoped>
    .clearfix {
        margin: 5px;
    }

    .ft30 {
        font-size: 30px;
    }

    .ft20 {
        font-size: 20px;
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    .article-analyse-keyword {
        margin-bottom: 30px;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*;*/
    }

    .my-box-card table {
        width: 150px;
    }

    .my-box-card {
        text-align: center;
        display: flex;
        /*width: 500px;*/
        justify-content: space-around;
    }

    .article-analyse-button {
        margin-bottom: 30px;
    }

    .article-analyse-charts {
        margin-bottom: 30px;
    }

    .article-analyse-table {
        margin-bottom: 30px;
        text-align: center;
    }

</style>
