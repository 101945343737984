<template>
    <div>

        <el-dialog title="修改疾病" :close-on-click-modal="false" :visible.sync="editFoodDialogData.isShow">

                <!--填写疾病内容-->
                <el-form style="width: 80%" label-width="120px" :rules="pushRules" :model="pushData" ref="pushData">

                    <el-form-item label="疾病名称" prop="name">
                        <el-input v-model="pushData.name"></el-input>
                    </el-form-item>

                    <el-form-item label="疾病展示图" prop="wap_banner_url">
                        <upload-image v-if="editFoodDialogData.isShow" title="疾病展示图【必填】" v-model="pushData.wap_banner_url" style="margin-right: 50px">
                            <div style="text-align: center;">疾病的展示图</div>
                        </upload-image>
                    </el-form-item>

                    <el-form-item label="疾病类型" prop="parent_id">
                        <el-select v-model="pushData.parent_id" placeholder="请选择">
                            <el-option
                                v-for="item in $store.state.getDiseaseList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="疾病序号：" prop="sort_order">
                        <el-input v-model="pushData.sort_order" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item label="疾病紧急程度">
                        <el-radio-group v-model="pushData.eat_type">
                            <el-radio border :label="1"><span style="color: red;">危险</span></el-radio>
                            <el-radio border :label="2"><span style="color: #ff6602;">紧急</span></el-radio>
                            <el-radio border :label="3"><span style="color: #1AAD19;">常见病</span></el-radio>
                        </el-radio-group>
                    </el-form-item>


                    <el-form-item label="疾病大标题（简介）：" prop="title">
                        <el-input v-model="pushData.title" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item label="疾病解释/介绍" prop="front_desc">
                        <!--<el-input type="textarea" v-model="pushData.front_desc" placeholder="为疾病的介绍"></el-input>-->

                        <u-editor-edit class="editBox" v-model="pushData.front_desc"></u-editor-edit>

                    </el-form-item>

                    <el-form-item label="是否立即上线：">
                        <el-switch
                            v-model="pushData.is_show" active-color="#13ce66" inactive-color="#ccc">
                        </el-switch>
                    </el-form-item>

                    <el-button type="primary" @click="submitForm">保存疾病</el-button>
                </el-form>

        </el-dialog>


    </div>
</template>

<script>
    /*添加疾病*/
    import bus from '../../../common/bus'
    import uploadImage from '../../../common/uploadImage'
    import uEditorEdit from '../../../common/editor'

    export default {
        name: "addFood",
        components: {
            uploadImage,uEditorEdit
        },
        props: {
            editFoodDialogData: {
                type: Object,
                default: {
                    isShow: false,
                    food_id: null,
                }
            },
            isDraft: {
                type: Boolean,
                default: false,
            }
        },
        watch:{
            'editFoodDialogData.food_id': {
                handler(newId, oldId) {
                    // console.log(newId,oldId);
                    this.getFoodDetail();
                },
            }
        },
        mounted() {
            this.getFoodDetail();
        },
        data(){
            return {
                pushData: {
                    title: '', // 疾病大标题
                    wap_banner_url: null,  //疾病展示图
                    name: null,  //疾病名称
                    front_desc: null,  //疾病描述
                    eat_type: 1,  //疾病是否可以吃，1标识能吃、2标识慎吃、3标识禁吃
                    sort_order: 0,  //疾病序号
                    parent_id: null,  //疾病分类
                    is_show: false  //是否立即上线
                },
                pushRules: {
                    name: [{required: true, message: "请输入疾病名称", trigger: "blur"}],
                    wap_banner_url: [{required: true, message: "请上传视频展示图", trigger: "blur"}],
                    sort_order: [{required: true, message: "请输入疾病序号", trigger: "blur"}],
                    front_desc: [{required: true, message: "请输入疾病描述", trigger: "blur"}],
                    parent_id: [{required: true, message: "请选择疾病分类", trigger: "blur"}],
                },
            }
        },
        methods: {
            //获取疾病信息
            getFoodDetail(){
                this.$http.post(this.$api + 'admin/disease/get', {
                    foods_category_id: this.editFoodDialogData.food_id,
                }).then(res => {
                    if(res.data.errno===0){
                        let info = res.data.data;
                        this.pushData.sort_order = info.sort_order;
                        this.pushData.name = info.name;
                        this.pushData.title = info.title;
                        this.pushData.parent_id = info.parent_id;
                        this.pushData.eat_type = info.eat_type;
                        this.pushData.wap_banner_url = info.wap_banner_url;
                        this.pushData.front_desc = info.front_desc;
                        this.pushData.is_show = info.is_show==1?true:false;
                    }
                })
            },
            //添加疾病弹窗确定
            submitForm() {
                this.$refs['pushData'].validate((valid) => {
                    if (valid) {
                        // this.addTypeSubmit();
                        this.editFoodSubmit();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //编辑食提交
            editFoodSubmit(){
                console.log('isDraft',this.isDraft);
                this.editFoodDialogData.isShow = false
                this.$http.post(this.$api + 'admin/disease/update', {
                    name : this.pushData.name,
                    title: this.pushData.title,
                    id: this.editFoodDialogData.food_id,
                    parent_id : this.pushData.parent_id,
                    sort_order: this.pushData.sort_order,
                    eat_type: this.pushData.eat_type,
                    wap_banner_url: this.pushData.wap_banner_url,
                    front_desc: this.pushData.front_desc,
                    is_show: this.pushData.is_show ? 1 : 0
                }).then(res => {
                    // console.log('退款res',res);
                    if (res.data.errno === 0) {
                        this.$message.success('修改疾病成功！');
                        if(this.isDraft){
                            this.$emit("editDraftSuccess");
                        }else{
                            this.$store.dispatch('getDiseaseList');
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .mike_container{
        background-color: #fff;
    }
</style>
