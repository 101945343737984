<template>
    <div class="container">
        <div style="margin-bottom: 30px">
            <label> 目录：</label>
            <el-select id="select" v-model="path" placeholder="请选择" @change="reloadList">
                <el-option v-for="(item,index) in options"
                           :key="item.label"
                           :label="item.label"
                           :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div>
            <upload-image :action="$api +'/chigua/admin/upload/picture/special'" v-model="imageUrl"></upload-image>
            <!--<el-upload-->
                <!--:action="$api +'/chigua/admin/upload/picture/special'"-->
                <!--list-type="picture-card"-->
                <!--drag-->
                <!--:headers="{ Auth_token }"-->
                <!--:on-preview="handlePictureCardPreview"-->
                <!--:on-success="uploadAudioSuccess"-->
                <!--:on-remove="handleRemove">-->

                <!--<i class="el-icon-plus"></i>-->
            <!--</el-upload>-->
            <span>{{imageUrl}}</span>
            <el-button data-clipboard-action="copy" data-clipboard-text="el-button" type="text"></el-button>
            <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
        </div>
        <div style="width: 11%;display: inline-block;height: 200px;position: relative;overflow: hidden;margin: 10px;border: 1px solid red"
            v-for="(item,index) in imageListData" :key="index">
            <img width="100%" :src="item" alt=""
                 style="position: absolute;top: 50%;left: 50%;transform: translateX(-50%) translateY(-50%);width: 100%;">
            <!--<el-card :body-style="{ padding: '0px' }">-->
                <!--<img :src="item.url" class="image">-->
                <!--<div style="padding: 14px;">-->
                    <!--<span>好吃的汉堡</span>-->
                    <!--<div class="bottom clearfix">-->
                        <!--<time class="time">{{ currentDate }}</time>-->
                        <!--<el-button type="text" class="button">操作按钮</el-button>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</el-card>-->
        </div>


    </div>

</template>

<script>
    import uploadImage from '../common/uploadImage'

    export default {
        data() {
            return {
                Auth_token: '',
                dialogImageUrl: '',
                imageUrl: '',
                imageListData: '',
                dialogVisible: false,
                path: '',
                nextUrl: '',
                preUrl: '',
                options: [{
                    value: '',
                    label: '/'
                }, {
                    value: 'image',
                    label: 'image'
                }, {
                    value: 'static',
                    label: 'static'
                }, {
                    value: 'static/icon/',
                    label: 'static/icon'
                }, {
                    value: 'static/images/',
                    label: 'static/images'
                }],
            };
        },
        created() {
        },
        components:{
            uploadImage
        },
        mounted() {
            this.Auth_token = localStorage.getItem('auth_token')
            this.reloadList();
        },
        methods: {
            reloadList() {
                this.getImgList(this.path, 50)
            },
            getImgList(path, limit) {
                this.$http({
                    url: this.$api + '/chigua/admin/upload/page/special',
                    method: 'post',
                    data: this.$qs.stringify({
                        url: '',
                        maxKeys: limit,
                        path: path,
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.imageListData = res.data.data
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            handleRemove(file, fileList) {
                //console.log(file.url);
                //console.log(file.response.data);
                // //console.log(file, fileList);
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            uploadAudioSuccess(res) {
                if (res.ret === 0) {
                    this.imageUrl = res.data
                } else {
                    this.fileList.pop()
                }
            }
        }
    }
</script>

<style scoped>

</style>
