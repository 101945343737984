<template>
    <div class="mike_con">

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="form_con">

            <!--基本信息-->
            <div class="mike_part left">
                <el-alert title="基本信息" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--优惠券名称-->
                <el-form-item label="优惠券名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>

                <!--优惠券描述-->
                <el-form-item label="优惠券描述" prop="name_desc">
                    <el-input v-model="ruleForm.name_desc"></el-input>
                </el-form-item>

                <!--满减门槛-->
                <el-form-item label="满减门槛" prop="limit_amount">
                    <el-input v-model="ruleForm.limit_amount" placeholder="需要达到多少金额满减"></el-input>
                </el-form-item>

                <!--优惠金额-->
                <el-form-item label="优惠金额" prop="preferential_cash">
                    <el-input v-model="ruleForm.preferential_cash" placeholder="优惠券金额"></el-input>
                </el-form-item>

                <!--用户支付订单时限制使用的优惠券数量-->
                <el-form-item label="支付时可用券数限制" prop="limit_pay_num">
                    <el-input v-model="ruleForm.limit_pay_num" placeholder="用户支付订单时限制使用的优惠券数量"></el-input>
                </el-form-item>

            </div>

            <!--时间选项-->
            <div class="mike_part">
                <el-alert title="时间选项" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--生效日期-过期日期-->
                <el-form-item label="生效日期-过期日期" prop="publish_date">
                    <el-date-picker
                        v-model="ruleForm.publish_date" type="datetimerange" :picker-options="pickerOptions"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                </el-form-item>


                <!--可使用日期-停止使用日期-->
                <el-form-item label="激活日期-停止日期" prop="active_date">
                    <el-date-picker
                        v-model="ruleForm.active_date" type="datetimerange" :picker-options="pickerOptions"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                </el-form-item>
            </div>


            <!--限制条件-->
            <div class="mike_part left">
                <el-alert title="限制条件" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--总共发放多少张优惠券  默认1000张-->
                <!--<el-form-item label="是否会员专属" prop="limit_publish_num">-->
                    <!--<el-badge :value="ruleForm.is_belong_vip? '会员专用券&#45;&#45;只有会员用户才能领取':'会员与非会员均可领券'" class="item">-->
                        <!--<el-switch v-model="ruleForm.is_belong_vip" active-color="#13ce66" inactive-color="#ccc">-->
                        <!--</el-switch>-->
                    <!--</el-badge>-->
                    <!--<p class="color_eb3021 font-size-13">请注意：设置为会员专用券后，遵循以下规则：</p>-->
                    <!--<p class="color_eb3021 font-size-13">1、只有会员用户才能领券</p>-->
                    <!--<p class="color_eb3021 font-size-13">2、会员用户的产生机制：</p>-->
                    <!--<p class="color_eb3021 font-size-13"> ① 用户通过购买会员卡成为猫趴会员可以领取专用的会员券。</p>-->
                    <!--<p class="color_eb3021 font-size-13"> ② 后台运营通过赠送会员等方式，改变用户会员身份，让用户获取领券资格。</p>-->
                <!--</el-form-item>                &lt;!&ndash;总共发放多少张优惠券  默认1000张&ndash;&gt;-->
                <!--<el-form-item label="是否为免费券" prop="limit_publish_num">-->
                    <!--<el-badge :value="ruleForm.is_free? '所有用户都可以领取该券':'只有完成任务的用户才能领取的特权券'" class="item">-->
                        <!--<el-switch v-model="ruleForm.is_free" active-text="免费券" inactive-text="特权券" active-color="#13ce66" inactive-color="#ccc">-->
                        <!--</el-switch>-->
                    <!--</el-badge>-->
                    <!--<p class="color_eb3021 font-size-13">请注意：设置为非免费券时,该全只能通过以下几种方式获取：</p>-->
                    <!--<p class="color_eb3021 font-size-13">1、运营使用特权从后台给指定用户发券</p>-->
                    <!--<p class="color_eb3021 font-size-13">2、用户完成对应的任务后，可以领特权券，例如完成积分等级任务，完成邀请猫友任务等</p>-->
                <!--</el-form-item>-->

                <!--用户可领取优惠券数量-->
                <el-form-item label="用户可领数量(0为不限次数)" prop="limit_get_num">
                    <el-input v-model="ruleForm.limit_get_num" placeholder="用户可领该优惠券的次数"></el-input>
                </el-form-item>

                <!--总共发放多少张优惠券  默认1000张-->
                <el-form-item label="发放总数" prop="limit_publish_num">
                    <el-input v-model="ruleForm.limit_publish_num" placeholder="总共发放多少张优惠券  默认1000张"></el-input>
                </el-form-item>

                <!--满减的商品数，例如买三件享受满减优惠券-->
                <el-form-item label="订单商品数量达到多少件可用" prop="limit_number">
                    <el-input v-model="ruleForm.limit_number" placeholder="满减的商品数，例如买三件享受满减优惠券"></el-input>
                </el-form-item>

                <!--总共发放多少张优惠券  默认1000张-->
                <el-form-item label="是否全场通用券" >
                    <el-switch v-model="ruleForm.is_full_fit" active-color="#13ce66" inactive-color="#ccc">
                    </el-switch>
                </el-form-item>

                <!--指定分类可以使用的券，例如猫粮专用券，id字符串，多个分类使用逗号分隔-->
                <el-form-item label="指定商品类型专用" prop="fit_category_ids" v-if="!ruleForm.is_full_fit">
                    <el-transfer v-model="CouponCategory" :data="CouponCategoryData"
                                 :button-texts="['取消', '添加']"
                                 :titles="['备选分类', '已选分类']"
                                 filterable></el-transfer>
                </el-form-item>

                <!--指定商品可以使用的券，例如go专用券，id字符串，多个分类使用逗号分隔-->
                <el-form-item label="指定商品专用" prop="fit_goods_ids" v-if="!ruleForm.is_full_fit">
                    <el-transfer class="couponGoodsTransfer" v-model="CouponGoods" :data="CouponGoodsData"
                                 :button-texts="['取消', '添加']"
                                 :titles="['备选商品（请搜索）', '已选商品']"
                                 filterable></el-transfer>

                    <div class="baseBorder padding15" v-if="ruleForm.fit_goods_names_data && ruleForm.fit_goods_names_data.length > 0">
                        <p class="fontWeight">请设置商品别名</p>
                        <ul class="padding15">
                            <li class="padding15" v-for="(item, index) in ruleForm.fit_goods_names_data" :key="index">
                                <p>{{index + 1}}、{{item.name}}</p>
                                <el-input  v-model="ruleForm.fit_goods_names_data[index].shortName"  placeholder="请设置商品别名">
                                    <template slot="prepend">商品别名：</template>
                                </el-input>
                            </li>
                        </ul>
                    </div>
                    <!--<el-input v-model="ruleForm.fit_goods_ids" :disabled="true" placeholder="例如go九种肉8磅专用券，id字符串，多个分类使用逗号分隔"></el-input>-->
                </el-form-item>

                <!--指定分类不可以使用的券，例如猫粮专用券，id字符串，多个分类使用逗号分隔-->
                <!--<el-form-item label="指定商品类型不可用" prop="beside_category_ids">-->
                    <!--<el-input v-model="ruleForm.beside_category_ids" placeholder="例如猫粮不可用，id字符串，多个分类使用逗号分隔"></el-input>-->
                <!--</el-form-item>-->

                <!--指定商品可以使用的券，例如go专用券，id字符串，多个分类使用逗号分隔-->
                <!--<el-form-item label="指定商品不可用" prop="beside_goods_ids ">-->
                    <!--<el-input v-model="ruleForm.beside_goods_ids "-->
                              <!--placeholder="例如go九种肉8磅不可用，id字符串，多个分类使用逗号分隔"></el-input>-->
                <!--</el-form-item>-->

            </div>

            <!--按钮-->
            <el-form-item>
                <el-button type="primary" @click="couponSubmit">立即创建</el-button>
                <el-button type="primary" @click="couponSubmit_AppUse">创建为APP专用优惠券</el-button>
            </el-form-item>
        </el-form>



    </div>
</template>

<script>
    export default {
        name: "addCoupon",
        data() {
            return {
                ruleForm: {
                    is_full_fit: false, // 是否全场通用
                    name: null,  //优惠券名称
                    name_desc: null,  //优惠券描述
                    limit_amount: null, //需要达到多少金额满减
                    preferential_cash: null,  //优惠金额
                    // is_free: true,  //是否优惠，先默认免费
                    publish_date: [],
                    active_date: [],
                    limit_get_num: 1,  //用户可领数量
                    limit_pay_num: 1,  //用户支付订单时限制使用的优惠券数量
                    // is_belong_vip: false,  //是否属于会员才能领取的券
                    limit_number: 1,  //满减的商品数，例如买三件享受满减优惠券
                    limit_publish_num: 1000,  //优惠券发放总数
                    fit_category_ids: [],
                    fit_category_names: [],
                    fit_goods_names_data: [], // 带有长短名称的商品数组
                    fit_goods_ids: [],
                    fit_goods_names: [],
                    // beside_category_ids: null,
                    // beside_goods_ids: null,
                },
                rules: {
                    name: [
                        {required: true, message: "请输入优惠券名称", trigger: 'blur'},
                    ],
                    name_desc: [
                        {required: true, message: "请输入优惠券描述", trigger: 'blur'},
                    ],
                    limit_amount: [
                        {required: true, message: "请输入优惠券满减门槛", trigger: 'blur'},
                    ],
                    limit_pay_num: [
                        {required: true, message: "请输入用户支付订单时限制使用的优惠券数量", trigger: 'blur'},
                    ],
                    preferential_cash: [
                        {required: true, message: "请输入优惠券金额", trigger: 'blur'},
                    ],
                    publish_date: [
                        {required: true, message: '请选择日期', trigger: 'change'}
                    ],
                    active_date: [
                        {required: true, message: '请选择日期', trigger: 'change'}
                    ],
                },
                //时间选择器快捷选项
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                category: [],
                CouponCategory: [], // 已选择的分类，在watch中监听，组装数据
                CouponCategoryData: [], // 分类数组
                CouponGoods: [], // 已选择的商品，在watch中监听，组装数据
                CouponGoodsData: [], // 商品的所有
            }
        },
        mounted () {
          this.getCategory();
          this.getGoods();
        },
        watch: {
            CouponCategory (newv) {
                let that = this;
                let fit_category_names_data = [];
                if (newv.constructor === Array) {
                    newv.map(function (param) {
                        that.CouponCategoryData.map(function (item) {
                            if (param === item.key) {
                                fit_category_names_data.push({
                                    name: item.label,
                                    id: item.key
                                })
                            }
                        })
                    })
                }
                this.ruleForm.fit_category_ids = newv;
                this.ruleForm.fit_category_names_data = fit_category_names_data
            },
            CouponGoods (newv) {
                let that = this;
                let fit_goods_names_data = [];
                if (newv.constructor === Array) {
                    newv.map(function (param) {
                        that.CouponGoodsData.map(function (item) {
                            if (param === item.key) {
                                fit_goods_names_data.push({
                                    id: item.key,
                                    name: item.label,
                                    shortName: ''
                                })
                            }
                        })
                    })
                }
                this.ruleForm.fit_goods_ids = newv;
                this.ruleForm.fit_goods_names_data = fit_goods_names_data
            }
        },
        methods: {
            getGoods (name) {
                this.$http({
                    url: this.$api + '/admin/goods/getTransferGoods',
                    method: 'post',
                    data: {
                        name: name,
                        page: 1,
                        size: 20
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.CouponGoodsData = response.data.data.data.map(function (item) {
                            return {
                                key: item.id,
                                label: item.name,
                                disabled: false
                            }
                        })
                    } else {
                        this.$message.error('商品数据加载失败！请刷新重试！')
                    }
                })
            },
            getCategory() {
                this.$http({
                    url: this.$api + '/admin/category/allMix',
                    method: 'post'
                }).then(response => {
                    if (response.data != null) {
                        this.CouponCategoryData = response.data.data.map(function (item) {
                            return {
                                key: item.id,
                                label: item.name,
                                disabled: false
                            }
                        })
                    }
                })
            },
            couponSubmit() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        /**
                         * 先格式化数据
                         * **/
                        let ajaxParams = {
                            is_full_fit: this.ruleForm.is_full_fit? 1:0,
                            name: this.ruleForm.name,
                            name_desc: this.ruleForm.name_desc,
                            limit_amount: this.ruleForm.limit_amount,
                            limit_number: this.ruleForm.limit_number,
                            limit_publish_num: this.ruleForm.limit_publish_num,
                            limit_get_num: this.ruleForm.limit_get_num,
                            preferential_cash: this.ruleForm.preferential_cash,
                            limit_pay_num: this.ruleForm.limit_pay_num,
                            // is_belong_vip: this.ruleForm.is_belong_vip ? 1 : 0,
                            is_free: this.ruleForm.is_free ? 1 :0,
                            fit_goods_data: !this.ruleForm.is_full_fit? this.ruleForm.fit_goods_names_data:[],
                            fit_category_data: !this.ruleForm.is_full_fit? this.ruleForm.fit_category_names_data:[],
                            fit_category_ids: !this.ruleForm.is_full_fit? this.ruleForm.fit_category_ids.join(','):'',
                            fit_goods_ids: !this.ruleForm.is_full_fit? this.ruleForm.fit_goods_ids.join(','):'',
                            fit_category_names: !this.ruleForm.is_full_fit && this.ruleForm.fit_category_names_data? this.ruleForm.fit_category_names_data.map(function (item) {
                                return item.name
                            }).join(','):'',
                            // 映射新的商品简称数组
                            fit_goods_names: !this.ruleForm.is_full_fit && this.ruleForm.fit_goods_names_data? this.ruleForm.fit_goods_names_data.map(function (item) {
                                return item.shortName
                            }).join(','):'',
                            // beside_category_ids: this.ruleForm.beside_category_ids,
                            // beside_goods_ids: this.ruleForm.beside_goods_ids,

                            publish_start_date: this.ruleForm.publish_date[0].getTime(),
                            publish_end_date: this.ruleForm.publish_date[1].getTime(),
                            active_start_date : this.ruleForm.active_date [0].getTime(),
                            active_end_date : this.ruleForm.active_date[1].getTime(),
                        };

                        this.$http({
                            url: this.$api + '/admin/coupon/add',
                            method: 'post',
                            data: ajaxParams
                        }).then(response => {
                            if (response.data.errno === 0) {
                                this.$message.success('添加优惠券成功！');

                                this.$router.push({path: '/couponList'});

                                // this.getMenuList()
                            }
                        })

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            couponSubmit_AppUse() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        /**
                         * 先格式化数据
                         * **/
                        let ajaxParams = {
                            is_full_fit: this.ruleForm.is_full_fit? 1:0,
                            name: this.ruleForm.name,
                            name_desc: this.ruleForm.name_desc,
                            limit_amount: this.ruleForm.limit_amount,
                            limit_number: this.ruleForm.limit_number,
                            limit_publish_num: this.ruleForm.limit_publish_num,
                            limit_get_num: this.ruleForm.limit_get_num,
                            preferential_cash: this.ruleForm.preferential_cash,
                            limit_pay_num: this.ruleForm.limit_pay_num,
                            // is_belong_vip: this.ruleForm.is_belong_vip ? 1 : 0,
                            is_free: this.ruleForm.is_free ? 1 :0,
                            fit_goods_data: !this.ruleForm.is_full_fit? this.ruleForm.fit_goods_names_data:[],
                            fit_category_data: !this.ruleForm.is_full_fit? this.ruleForm.fit_category_names_data:[],
                            fit_category_ids: !this.ruleForm.is_full_fit? this.ruleForm.fit_category_ids.join(','):'',
                            fit_goods_ids: !this.ruleForm.is_full_fit? this.ruleForm.fit_goods_ids.join(','):'',
                            fit_category_names: !this.ruleForm.is_full_fit && this.ruleForm.fit_category_names_data? this.ruleForm.fit_category_names_data.map(function (item) {
                                return item.name
                            }).join(','):'',
                            // 映射新的商品简称数组
                            fit_goods_names: !this.ruleForm.is_full_fit && this.ruleForm.fit_goods_names_data? this.ruleForm.fit_goods_names_data.map(function (item) {
                                return item.shortName
                            }).join(','):'',
                            // beside_category_ids: this.ruleForm.beside_category_ids,
                            // beside_goods_ids: this.ruleForm.beside_goods_ids,

                            publish_start_date: this.ruleForm.publish_date[0].getTime(),
                            publish_end_date: this.ruleForm.publish_date[1].getTime(),
                            active_start_date : this.ruleForm.active_date [0].getTime(),
                            active_end_date : this.ruleForm.active_date[1].getTime(),
                            type: 4 // APP专用优惠券
                        };

                        this.$http({
                            url: this.$api + '/admin/coupon/add',
                            method: 'post',
                            data: ajaxParams
                        }).then(response => {
                            if (response.data.errno === 0) {
                                this.$message.success('添加优惠券成功！');

                                this.$router.push({path: '/couponList'});

                                // this.getMenuList()
                            }
                        })

                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    .couponGoodsTransfer .el-transfer-panel {
        width: 40%;
    }
    .coupon_title {
        margin-bottom: 20px;
    }

    .form_con {
        display: flex;
        flex-wrap: wrap;
    }

    .mike_part {
        width: 100%;
        &.left {
            margin-right: 100px;
        }
    }
</style>
