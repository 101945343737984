import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);


//mode: 'history', // 去掉连接中的#号
const routes =  [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/',
        component: resolve => require(['../components/common/Home.vue'], resolve),
        // meta: { title: '页面容器' , permission: true },
        children:[
            {
                path: '/iframe',
                component: resolve => require(['../components/page/iframe.vue'], resolve),
                meta: { title: 'baidu' , permission: [] , keepAlive: false}
            },
            {
                path: '/answerlist',
                component: resolve => require(['../components/page/question/answerList.vue'], resolve),
                meta: { title: '回答列表' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/userEvaluate',
                component: resolve => require(['../components/page/operationManagement/userEvaluate.vue'], resolve),
                meta: { title: '用户评价' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/sendSms',
                component: resolve => require(['../components/page/operationManagement/sendSms.vue'], resolve),
                meta: { title: '发送短信' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/generateQrCode',
                component: resolve => require(['../components/page/operationManagement/generateQrCode.vue'], resolve),
                meta: { title: '小程序码生成器' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/salesRankingManagement',
                component: resolve => require(['../components/page/operationManagement/salesRankingManagement.vue'], resolve),
                meta: { title: '销量排行管理' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/newGoodsRanking',
                component: resolve => require(['../components/page/operationManagement/newGoodsRanking.vue'], resolve),
                meta: { title: '新品发布管理' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/hotGoodsManagement',
                component: resolve => require(['../components/page/operationManagement/hotGoodsManagement.vue'], resolve),
                meta: { title: '人气推荐管理' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/statisticalManagement',
                component: resolve => require(['../components/page/operationManagement/statisticalManagement.vue'], resolve),
                meta: { title: '统计数据' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/evaluateRobot',
                component: resolve => require(['../components/page/operationManagement/evaluateRobot.vue'], resolve),
                meta: { title: '评价机器人' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/robotManagement',
                component: resolve => require(['../components/page/operationManagement/robotManagement.vue'], resolve),
                meta: { title: '机器人管理' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/cacheManagement',
                component: resolve => require(['../components/page/operationManagement/cacheManagement.vue'], resolve),
                meta: { title: '缓存设置' , permission: ['add', 'edit'] , keepAlive: false}
            },
            {
                path: '/addanswer',
                component: resolve => require(['../components/page/question/addAnswer.vue'], resolve),
                meta: { title: '回答发布' , permission: ['add', 'edit'] , keepAlive: false }
            },
            {
                path: '/addquestion',
                component: resolve => require(['../components/page/question/addQuestion.vue'], resolve),
                meta: { title: '新建发布' , permission: true , keepAlive: false }
            },
            {
                path: '/addVideoQuestion',
                component: resolve => require(['../components/page/question/addVideoQuestion.vue'], resolve),
                meta: { title: '发布视频' , permission: true , keepAlive: false }
            },
            {
                path: '/feedbackList',
                component: resolve => require(['../components/page/feedback/feedbackList.vue'], resolve),
                meta: { title: '用户反馈' , permission: true , keepAlive: false }
            },
            {
                path: '/questionIndex',
                component: resolve => require(['../components/page/question/index.vue'], resolve),
                meta: { title: '发布首页' , permission: true , keepAlive: false }
            },
            {
                path: '/questionList',
                component: resolve => require(['../components/page/question/list.vue'], resolve),
                meta: { title: '发布列表' , permission: ['add', 'edit'] , keepAlive: false }
            },
            {
                path: '/aiTestCats',
                component: resolve => require(['../components/page/question/aiTestCats.vue'], resolve),
                meta: { title: 'AI测猫器内容' , permission: ['add', 'edit'] , keepAlive: false }
            },
            {
                path: '/useranalysis',
                component: resolve => require(['../components/page/analyse/user.vue'], resolve),
                meta: { title: '用户统计分析' , permission: true , keepAlive: false }
            },
            {
                path: '/searchKeyword',
                component: resolve => require(['../components/page/analyse/searchKeyword.vue'], resolve),
                meta: { title: '搜索关键字分析' , permission: true , keepAlive: false }
            },
            {
                path: '/keywordTimeAnalyse',
                component: resolve => require(['../components/page/analyse/keywordTimeAnalyse.vue'], resolve),
                meta: { title: '关键字分析' , permission: true , keepAlive: false }
            },
            {
                path: '/hotKeywordSetup',
                component: resolve => require(['../components/page/adminManagement/hotKeywordSetup.vue'], resolve),
                meta: { title: '前台搜搜索词管理' , permission: true , keepAlive: false }
            },
            {
                path: '/cardanalysis',
                component: resolve => require(['../components/page/analyse/card.vue'], resolve),
                meta: { title: '名片统计分析' , permission: true , keepAlive: false }
            },
            {
                path: '/articleanalysis',
                component: resolve => require(['../components/page/analyse/article.vue'], resolve),
                meta: { title: '文章统计分析' , permission: true , keepAlive: false }
            },
            {
                path: '/videoanalysis',
                component: resolve => require(['../components/page/analyse/video.vue'], resolve),
                meta: { title: '视频统计分析' , permission: true , keepAlive: false }
            },
            {
                path: '/operationalIndicators',
                component: resolve => require(['../components/page/analyse/operationalIndicators.vue'], resolve),
                meta: { title: '运营指标设定' , permission: true , keepAlive: false }
            },
            {
                path: '/iconmanage',
                component: resolve => require(['../components/page/iconmanage.vue'], resolve),
                meta: { title: '图标管理' , permission: true , keepAlive: false }
            },
            {
                path: '/cardmanage',
                component: resolve => require(['../components/page/cardmanage.vue'], resolve),
                meta: { title: '名片管理' , permission: true , keepAlive: false }
            },
            {
                path: '/recyclebox',
                component: resolve => require(['../components/page/recyclebox.vue'], resolve),
                meta: { title: '回收站' , permission: true  , keepAlive: false}
            },
            // {
            //     path: '/newwxpaper',
            //     component: resolve => require(['../components/page/article/add.vue'], resolve),
            //     meta: { title: '新增公众号文章' , permission: true  , keepAlive: false}
            // },
            // {
            //     path: '/courselist',
            //     component: resolve => require(['../components/page/course/courseList.vue'], resolve),
            //     meta: { title: '课程列表' , permission: true  , keepAlive: false}
            // },
            {
                path: '/ditmanage',
                component: resolve => require(['../components/page/ditmanage.vue'], resolve),
                meta: { title: '字典管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/editcourse',
                component: resolve => require(['../components/page/course/editCourse.vue'], resolve),
                meta: { title: '编辑课程' , permission: true  , keepAlive: false}
            },
            {
                path: '/coursebox',
                component: resolve => require(['../components/page/course/coursebox.vue'], resolve),
                meta: { title: '课程草稿箱' , permission: true  , keepAlive: false}
            },
            {
                path: '/addcourse',
                component: resolve => require(['../components/page/course/addCourse.vue'], resolve),
                meta: { title: '新增课程' , permission: true  , keepAlive: false}
            },
            {
                path: '/activityedit',
                component: resolve => require(['../components/page/activity/save.vue'], resolve),
                meta: { title: '编辑活动' , permission: true  , keepAlive: false}
            },
            {
                path: '/activity_apply_detail',
                component: resolve => require(['../components/page/activity/apply.vue'], resolve),
                meta: { title: '活动报名详情' , permission: true  , keepAlive: false}
            },
            {
                path: '/invoicelist',
                component: resolve => require(['../components/page/activity/invoice.vue'], resolve),
                meta: { title: '发票信息' , permission: true  , keepAlive: false}
            },

            {
                path: '/newsactivity',
                component: resolve => require(['../components/page/activity/save.vue'], resolve),
                meta: { title: '新增活动' , permission: true , keepAlive: false }
            },
            {
                path: '/activitylist',
                component: resolve => require(['../components/page/activity/list.vue'], resolve),
                meta: { title: '活动列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/storageAlertList',
                component: resolve => require(['../components/page/messageHub/storageAlertList.vue'], resolve),
                meta: { title: '库存告警' , permission: true  , keepAlive: false}
            },
            {
                path: '/onlineactivities',
                component: resolve => require(['../components/page/activity/onlineactivities.vue'], resolve),
                meta: { title: '线上活动' , permission: true  , keepAlive: false}
            },
            {
                path: '/adsdraft',
                component: resolve => require(['../components/page/advertise/adsdraft.vue'], resolve),
                meta: { title: '广告草稿箱' , permission: true  , keepAlive: false}
            },

            {
                path: '/newActivityDraft',
                component: resolve => require(['../components/page/nianhuo/nianhuodraft.vue'], resolve),
                meta: { title: '年货草稿箱' , permission: true  , keepAlive: false}
            },
            {
                path: '/activityDraftManage',
                component: resolve => require(['../components/page/nianhuo/nianhuomanage.vue'], resolve),
                meta: { title: '年货管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/addNewActivity',
                component: resolve => require(['../components/page/nianhuo/addnianhuo.vue'], resolve),
                meta: { title: '新增年货' , permission: true  , keepAlive: false}
            },
            {
                path: '/editNewActivity',
                component: resolve => require(['../components/page/nianhuo/editnianhuo.vue'], resolve),
                meta: { title: '编辑年货' , permission: true  , keepAlive: false}
            },
            {
                path: '/heartChoiceManage',
                component: resolve => require(['../components/page/heartChoice/heartChoiceManage.vue'], resolve),
                meta: { title: '猫趴心选管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/petsList',
                component: resolve => require(['../components/page/pets/petsList.vue'], resolve),
                meta: { title: '宠物管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/heartChoiceDraft',
                component: resolve => require(['../components/page/heartChoice/heartChoiceDraft.vue'], resolve),
                meta: { title: '猫趴心选草稿箱' , permission: true  , keepAlive: false}
            },
            {
                path: '/updatePrinter',
                component: resolve => require(['../components/page/express/updatePrinter.vue'], resolve),
                meta: { title: '更新打印员' , permission: true  , keepAlive: false}
            },
            {
              path: '/wx-user-list',
              component: resolve => require(['../components/page/wxuser/userlist.vue'], resolve),
              meta: { title: '用户列表' , permission: true  , keepAlive: false}
            },
            {
              path: '/kefu',
              component: resolve => require([
                '../components/page/kefu/index.vue'
              ], resolve),
              meta: { title: '在线客服后台' , permission: true  , keepAlive: false}
            },
            {
                path: '/serviceAccountUser',
                component: resolve => require(['../components/page/wxuser/serviceAccountUser.vue'], resolve),
                meta: { title: '公众号用户管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/groupApply',
                component: resolve => require(['../components/page/team/groupApply.vue'], resolve),
                meta: { title: '合伙人申请' , permission: true  , keepAlive: false}
            },
            {
                path: '/teamList',
                component: resolve => require(['../components/page/team/teamList.vue'], resolve),
                meta: { title: '合伙人列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/serviceUserDetail',
                component: resolve => require(['../components/page/team/serviceUserDetail.vue'], resolve),
                meta: { title: '合伙人详情' , permission: true  , keepAlive: false}
            },
            {
                path: '/managerTeamDetail',
                component: resolve => require(['../components/page/team/managerTeamDetail.vue'], resolve),
                meta: { title: '合伙人收益情况' , permission: true  , keepAlive: false}
            },
            {
                path: '/usermanage',
                component: resolve => require(['../components/page/usermanage.vue'], resolve),
                meta: { title: '管理员列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/companyWechat',
                component: resolve => require(['../components/page/adminManagement/companyWechat.vue'], resolve),
                meta: { title: '企业消息推送' , permission: true  , keepAlive: false}
            },
            {
                path: '/adsmanage',
                component: resolve => require(['../components/page/advertise/adsmanage.vue'], resolve),
                meta: { title: '广告管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/orderrecycle',
                component: resolve => require(['../components/page/order/orderrecycle.vue'], resolve),
                meta: { title: '订单回收站' , permission: true  , keepAlive: false}
            },
            {
                path: '/goodsadd',
                component: resolve => require(['../components/page/goods/goodsAdd.vue'], resolve),
                meta: { title: '新增商品' , permission: true  , keepAlive: false}
            },
            {
                path: '/addPromotion',
                component: resolve => require(['../components/page/goodsPromotion/addPromotion.vue'], resolve),
                meta: { title: '新增促销活动' , permission: true  , keepAlive: false}
            },
            {
                path: '/editPromotion',
                component: resolve => require(['../components/page/goodsPromotion/editPromotion.vue'], resolve),
                meta: { title: '编辑促销活动' , permission: true  , keepAlive: false}
            },
            {
                path: '/promotionList',
                component: resolve => require(['../components/page/goodsPromotion/promotionList.vue'], resolve),
                meta: { title: '正在参加促销的活动' , permission: true  , keepAlive: false}
            },
            {
                path: '/spikeGoodsList',
                component: resolve => require(['../components/page/goodsPromotion/spikeGoodsList.vue'], resolve),
                meta: { title: '秒杀活动' , permission: true  , keepAlive: false}
            },
            {
                path: '/subscribeList',
                component: resolve => require(['../components/page/goods/subscribeList.vue'], resolve),
                meta: { title: '周期订阅' , permission: true  , keepAlive: false}
            },
            {
                path: '/goodsStorage',
                component: resolve => require(['../components/page/goods/goodsStorage.vue'], resolve),
                meta: { title: '库存管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/skuManage',
                component: resolve => require(['../components/page/goods/skuManage.vue'], resolve),
                meta: { title: 'sku管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/tryGoodsAdd',
                component: resolve => require(['../components/page/freeTryGoods/tryGoodsAdd.vue'], resolve),
                meta: { title: '新增商品' , permission: true  , keepAlive: false}
            },
            {
                path: '/goodsedit',
                component: resolve => require(['../components/page/goods/goodsEdit.vue'], resolve),
                meta: { title: '更新商品信息' , permission: true  , keepAlive: false}
            },
            {
                path: '/goodsDetail',
                component: resolve => require(['../components/page/goods/goodsDetail.vue'], resolve),
                meta: { title: '商品详情' , permission: true  , keepAlive: false}
            },
            {
                path: '/systemsetup',
                component: resolve => require(['../components/page/systemsetup.vue'], resolve),
                meta: { title: '系统设置' , permission: true  , keepAlive: false}
            },
            {
                path: '/allorder',
                component: resolve => require(['../components/page/order/allorder.vue'], resolve),
                meta: { title: '全部订单' , permission: true  , keepAlive: false}
            },
            {
                path: '/unExpressOrder',
                component: resolve => require(['../components/page/order/unExpressOrder.vue'], resolve),
                meta: { title: '未发货订单' , permission: true  , keepAlive: false}
            },
            {
                path: '/videoedit',
                component: resolve => require(['../components/page/order/videoedit.vue'], resolve),
                meta: { title: '视频编辑' , permission: true  , keepAlive: true}
            },
            {
                path: '/unpayorder',
                component: resolve => require(['../components/page/order/unpayOrder.vue'], resolve),
                meta: { title: '未付款订单', permission: true   , keepAlive: false}
            },
            {
                path: '/payorder',
                component: resolve => require(['../components/page/order/payOrder.vue'], resolve),
                meta: { title: '已付款订单', permission: true   , keepAlive: false}
            },
            {
                path: '/refundorder',
                component: resolve => require(['../components/page/order/refundOrder.vue'], resolve),
                meta: { title: '退款订单' , permission: true  , keepAlive: false}
            },
            {
                path: '/orderDetail',
                component: resolve => require(['../components/page/order/orderDetail.vue'], resolve),
                meta: { title: '订单详情', permission: true   , keepAlive: false}
            },
            {
                path: '/blockword',
                component: resolve => require(['../components/page/blockword.vue'], resolve),
                meta: { title: '屏蔽词设定', permission: true   , keepAlive: false}
            },
            {
                path: '/goodslist',
                component: resolve => require(['../components/page/goods/goodsList.vue'], resolve),
                meta: { title: '商品列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/specialGoodsList',
                component: resolve => require(['../components/page/freeTryGoods/specialGoodsList.vue'], resolve),
                meta: { title: '试用装列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/freeGoodsBox',
                component: resolve => require(['../components/page/freeTryGoods/freeGoodsBox.vue'], resolve),
                meta: { title: '试用装草稿' , permission: true  , keepAlive: false}
            },
            {
                path: '/freeGoodsEdit',
                component: resolve => require(['../components/page/freeTryGoods/freeGoodsEdit.vue'], resolve),
                meta: { title: '编辑试用装' , permission: true  , keepAlive: true}
            },
            {
                path: '/goodsedit',
                component: resolve => require(['../components/page/goods/goodsEdit.vue'], resolve),
                meta: { title: '文章编辑', permission: true   , keepAlive: true}
            },
            {
                path: '/goodsbox',
                component: resolve => require(['../components/page/goods/goodsBox.vue'], resolve),
                meta: { title: '商品草稿箱', permission: true   , keepAlive: false}
            },
            {
                path: '/menumanage',
                component: resolve => require(['../components/page/menu/menumanage.vue'], resolve),
                meta: { title: '菜单管理' , permission: true , keepAlive: false }
            },
            {
                path: '/userpaperlist',
                component: resolve => require(['../components/page/userGenerate/userpaperlist.vue'], resolve),
                meta: { title: '文章列表' , permission: true , keepAlive: false }
            },
            {
                path: '/userVideolist',
                component: resolve => require(['../components/page/userGenerate/userVideolist.vue'], resolve),
                meta: { title: '视频列表' , permission: true , keepAlive: false }
            },
            {
                path: '/userdrafts',
                component: resolve => require(['../components/page/userGenerate/userdrafts.vue'], resolve),
                meta: { title: '草稿箱' , permission: true , keepAlive: false }
            },
            {
                path: '/userColumnList',
                component: resolve => require(['../components/page/userGenerate/userColumnList.vue'], resolve),
                meta: { title: '专栏列表' , permission: true , keepAlive: false }
            },
            {
                path: '/newsColumnList',
                component: resolve => require(['../components/page/userGenerate/newsColumnList.vue'], resolve),
                meta: { title: '新增专栏' , permission: true , keepAlive: false }
            },
            {
                path: '/userColumnDetails',
                component: resolve => require(['../components/page/userGenerate/userColumnDetails.vue'], resolve),
                meta: { title: '专栏详情' , permission: true , keepAlive: false }
            },
            {
                path: '/applyColumn',
                component: resolve => require(['../components/page/qualificationInspecting/applyColumn.vue'], resolve),
                meta: { title: '专栏申请' , permission: true , keepAlive: false }
            },
            {
                path: '/dashboard',
                component: resolve => require(['../components/page/Dashboard.vue'], resolve),
                meta: { title: '首页' , permission: true  , keepAlive: false}
            },
            {
                path: '/categorylist',
                component: resolve => require(['../components/page/category/categoryList.vue'], resolve),
                meta: { title: '分类列表' , permission: true  , keepAlive: false}

            },
            {
                path: '/categorydraft',
                component: resolve => require(['../components/page/category/categoryDraft.vue'], resolve),
                meta: { title: '分类草稿箱' , permission: true  , keepAlive: false}
            },
            {
                path: '/categorybox',
                component: resolve => require(['../components/page/category/categoryBox.vue'], resolve),
                meta: { title: '分类回收站' , permission: true  , keepAlive: false}
            },
            {
                path: '/channellist',
                component: resolve => require(['../components/page/channel/channelList.vue'], resolve),
                meta: { title: '频道' , permission: true  , keepAlive: false}

            },
            {
                path: '/channeldraft',
                component: resolve => require(['../components/page/channel/channelDraft.vue'], resolve),
                meta: { title: '频道草稿箱' , permission: true  , keepAlive: false}
            },
            {
                path: '/channelbox',
                component: resolve => require(['../components/page/channel/channelBox.vue'], resolve),
                meta: { title: '频道回收站' , permission: true  , keepAlive: false}
            },
            {
                path: '/topiclist',
                component: resolve => require(['../components/page/topic/topicList.vue'], resolve),
                meta: { title: '专题列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/topicdraft',
                component: resolve => require(['../components/page/topic/topicDraft.vue'], resolve),
                meta: { title: '专题草稿箱' , permission: true  , keepAlive: false}
            },
            {
                path: '/topicrecycle',
                component: resolve => require(['../components/page/topic/topicRecycle.vue'], resolve),
                meta: { title: '专题回收站' , permission: true  , keepAlive: false}
            },
            {
                path: '/topicadd',
                component: resolve => require(['../components/page/topic/topicAdd.vue'], resolve),
                meta: { title: '新增专题' , permission: true  , keepAlive: false}
            },
            {
                path: '/topicedit',
                component: resolve => require(['../components/page/topic/topicEdit.vue'], resolve),
                meta: { title: '编辑专题' , permission: true  , keepAlive: true}
            },
            {
                path: '/customService',
                component: resolve => require(['../components/page/customService/index.vue'], resolve),
                meta: { title: '客服消息' , permission: true  , keepAlive: false}
            },
            {
                path: '/discount',
                component: resolve => require(['../components/page/discount/discount.vue'], resolve),
                meta: { title: '折扣设置' , permission: true  , keepAlive: false}
            },
            /**
             * 购物车分析
             * **/
            {
                path: '/baseAnalysis',
                component: resolve => require(['../components/page/buyCartAnalysis/baseAnalysis.vue'], resolve),
                meta: { title: '购物车分析' , permission: true  , keepAlive: false}
            },
            /**
             * 砍价
             * **/
            {
                path: '/bargainList',
                component: resolve => require(['../components/page/bargain/bargainList.vue'], resolve),
                meta: { title: '砍价列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/bargainDraft',
                component: resolve => require(['../components/page/bargain/bargainDraft.vue'], resolve),
                meta: { title: '砍价草稿箱' , permission: true  , keepAlive: false}
            },
            {
                path: '/addBargain',
                component: resolve => require(['../components/page/bargain/addBargain.vue'], resolve),
                meta: { title: '新增砍价' , permission: true  , keepAlive: false}
            },
            {
                path: '/editBargain',
                component: resolve => require(['../components/page/bargain/editBargain.vue'], resolve),
                meta: { title: '编辑砍价' , permission: true  , keepAlive: false}
            },
            /**
             * 优惠券工具
             * */
            {
                path: '/addCoupon',
                component: resolve => require(['../components/page/coupon/addCoupon.vue'],resolve),
                meta: {title: '添加普通优惠券', permission: true  , keepAlive: false}
            },
            {
                path: '/addNewUserCoupon',
                component: resolve => require(['../components/page/coupon/addNewUserCoupon.vue'],resolve),
                meta: {title: '新用户优惠券', permission: true  , keepAlive: false}
            },
            {
                path: '/addVipCoupon',
                component: resolve => require(['../components/page/coupon/addVipCoupon.vue'],resolve),
                meta: {title: '会员专用优惠券', permission: true  , keepAlive: false}
            },
            {
                path: '/couponList',
                component: resolve => require(['../components/page/coupon/couponList.vue'],resolve),
                meta: {title: '优惠券列表', permission: true  , keepAlive: false}
            },
            {
                path: '/newUserCouponList',
                component: resolve => require(['../components/page/coupon/newUserCouponList.vue'],resolve),
                meta: {title: '新用户优惠券', permission: true  , keepAlive: false}
            },
            {
                path: '/vipCouponList',
                component: resolve => require(['../components/page/coupon/vipCouponList.vue'],resolve),
                meta: {title: '会员优惠券', permission: true  , keepAlive: false}
            },
            {
                path: '/appCouponList',
                component: resolve => require(['../components/page/coupon/appCouponList.vue'],resolve),
                meta: {title: 'APP专用券', permission: true  , keepAlive: false}
            },
            {
                path: '/editCoupon',
                component: resolve => require(['../components/page/coupon/editCoupon.vue'],resolve),
                meta: {title: '编辑优惠券', permission: true  , keepAlive: false}
            },
            {
                path: '/couponDraftList',
                component: resolve => require(['../components/page/coupon/couponDraftList.vue'],resolve),
                meta: {title: '优惠券草稿箱', permission: true  , keepAlive: false}
            },
            /**
             * 通用分类活动、专栏活动等
             * **/
            {
                path: '/newClassActivity',
                component: resolve => require(['../components/page/classActivity/newClassActivity.vue'],resolve),
                meta: {title: '新增通用栏目活动', permission: true  , keepAlive: false}
            },
            {
                path: '/classActivityManage',
                component: resolve => require(['../components/page/classActivity/classActivityManage.vue'],resolve),
                meta: {title: '通用栏目活动列表', permission: true  , keepAlive: false}
            },
            /**
             * 猫咪能不能吃工具
             * */
            {
                path: '/foodTypeList',
                component: resolve => require(['../components/page/foodsDiction/foodTypeList.vue'], resolve),
                meta: { title: '食物列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/foodDraft',
                component: resolve => require(['../components/page/foodsDiction/foodDraft.vue'], resolve),
                meta: { title: '食物草稿箱' , permission: true  , keepAlive: false}
            },
            /**
             *
             * 疾病自查
             *
             * **/

            {
                path: '/diseaseList',
                component: resolve => require(['../components/page/disease/diseaseList.vue'], resolve),
                meta: { title: '疾病列表' , permission: true  , keepAlive: false}
            },
            {
                path: 'diseaseDraft',
                component: resolve => require(['../components/page/disease/diseaseDraft.vue'], resolve),
                meta: { title: '疾病草稿箱' , permission: true  , keepAlive: false}
            },
            {
                path: '/adminList',
                component: resolve => require(['../components/page/adminManagement/adminList.vue'], resolve),
                meta: { title: '管理员列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/sendSmsTemplate',
                component: resolve => require(['../components/page/adminManagement/sendSmsTemplate.vue'], resolve),
                meta: { title: '发送短信' , permission: true  , keepAlive: false}
            },
            {
                path: '/rightManagement',
                component: resolve => require(['../components/page/adminManagement/rightManagement.vue'], resolve),
                meta: { title: '权限管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/selfPrintExpress',
                component: resolve => require(['../components/page/express/selfPrintExpress.vue'], resolve),
                meta: { title: '手打单' , permission: true  , keepAlive: false}
            },
            {
                path: '/addCommissionTemplate',
                component: resolve => require(['../components/page/commission/addCommissionTemplate.vue'], resolve),
                meta: { title: '新增返佣商品模板' , permission: true  , keepAlive: false}
            },
            {
                path: '/commissionTemplateList',
                component: resolve => require(['../components/page/commission/commissionTemplateList.vue'], resolve),
                meta: { title: '返佣商品模板' , permission: true  , keepAlive: false}
            },
            {
                path: '/addCustomerService',
                component: resolve => require(['../components/page/team/addCustomerService.vue'], resolve),
                meta: { title: '新增服务号客服内容' , permission: true  , keepAlive: false}
            },
            {
                path: '/addHelp',
                component: resolve => require(['../components/page/team/addHelp.vue'], resolve),
                meta: { title: '服务号帮助信息管理' , permission: true  , keepAlive: false}
            },
            {
                path: '/setTeamUserCommission',
                component: resolve => require(['../components/page/commission/setTeamUserCommission.vue'], resolve),
                meta: { title: '为用户配置返佣模板' , permission: true  , keepAlive: false}
            },
            {
                path: '/teamUserWithdrawal',
                component: resolve => require(['../components/page/team/teamUserWithdrawal.vue'], resolve),
                meta: { title: '合伙人提现' , permission: true  , keepAlive: false}
            },
            {
                path: '/teamCommissionList',
                component: resolve => require(['../components/page/commission/teamCommissionList.vue'], resolve),
                meta: { title: '小组长返佣列表' , permission: true  , keepAlive: false}
            },
            {
                path: '/editCommissionTemplate',
                component: resolve => require(['../components/page/commission/editCommissionTemplate.vue'], resolve),
                meta: { title: '编辑返佣模板' , permission: true  , keepAlive: false}
            },
            {
                path: '/commissionTemplateDetail',
                component: resolve => require(['../components/page/commission/commissionTemplateDetail.vue'], resolve),
                meta: { title: '返佣模板详情' , permission: true  , keepAlive: false}
            },
            {
              path: '/userMobileNumber',
              component: resolve => require(['../components/page/adminManagement/userMobileNumber.vue'], resolve),
              meta: { title: '录入客户电话'}
            },
            {
                path: '/404',
                component: resolve => require(['../components/page/404.vue'], resolve),
                meta: { title: '页面找不到了'}

            }
        ]
    },
    {
        path: '/login',
        component: resolve => require(['../components/page/Login-new.vue'], resolve),
        // permission必须设置为true,否则无法进入系统
        meta: { title: '登陆', permission: false , keepAlive: false}
    },

    {
        path: '/minipubpaper',
        component: resolve => require(['../components/page/miniPubPaper.vue'], resolve),
        // permission必须设置为true,否则无法进入系统
        meta: { title: '发布文章', permission: false , keepAlive: false}
    },

    {
        path: '/403',
        component: resolve => require(['../components/page/403.vue'], resolve)
    },
    {
        path: '*',
        redirect: '/404'
    }
]

export default routes;
