<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>回收站</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>文章列表</el-breadcrumb-item> -->
            </el-breadcrumb>
            <!-- <el-button type='success' @click="$router.push('/newspaper')">添加文章</el-button> -->
        </div>
        <div class="tagSwitch">
            <el-tabs v-model="activeName" @tab-click="switchTag">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <!-- <div style="text-align: center;position: relative;top: 50%;">功能正在开发，敬请期待</div> -->
        <div class="paper_video" v-if="switchStatusArr.paper_video">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column label="标题">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                            <span style="margin-left: 10px">{{ scope.row.title }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="作者" width="100">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <span size="medium">{{ scope.row.author }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="发布时间" width="150">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ formatStrTime(scope.row.releaseTime,16) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="点赞" width="80">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.praiseNum === null? 0:scope.row.praiseNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="分享" width="80">
                    <template slot-scope="scope">
                        <span
                            style="margin-left: 10px">{{ scope.row.forwardNum === null? 0:scope.row.forwardNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <div style="display: flex;justify-content: space-between;height:35px; ">
                            <!-- <i title="编辑" class="el-icon-edit-outline ft25 pointer" @click="editPaper(scope.$index, scope.row)"></i> -->
                            <div type="success" size='mini' class="ft12 operateEvent pointer" title="还原操作"
                                 @click="operatePaper(scope.$index, scope.row)" :name="scope.row.status">
                                <svg t="1528682042513" class="icon" style="" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="1437"
                                     xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25">
                                    <defs></defs>
                                    <path
                                        d="M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112c-4.1 3.2-4.1 9.4 0 12.6l142 112c5.2 4.1 12.9 0.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z"
                                        p-id="1438" fill="#bfbfbf"></path>
                                </svg>
                            </div>

                            <i title="删除" class="el-icon-delete ft25 red pointer"
                               @click="deletePaper(scope.$index, scope.row)"></i>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="ads" v-if="switchStatusArr.ads">
            <el-table :data="adstableData" style="width: 100%">
                <el-table-column label="标题">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                            <span style="margin-left: 10px">{{ scope.row.title }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="时间"
                    width="200">
                    <template slot-scope="scope">
                        <div style="margin-left: 10px"><i class="el-icon-time" v-if="scope.row.startTime"></i>{{
                            scope.row.startTime }}
                        </div>
                        <div style="margin-left: 10px"><i class="el-icon-time" v-if="scope.row.endTime"></i>{{
                            scope.row.endTime }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100px">
                    <template slot-scope="scope">
                        <div style="display: flex;justify-content: space-around;height:35px;align-items: flex-end;">
                            <div class="ft25 operateEvent pointer" title="还原"
                                 @click="operatePaper(scope.$index, scope.row)" :name="scope.row.status">
                                <svg t="1528682042513" class="icon" style="" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="1437"
                                     xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25">
                                    <defs></defs>
                                    <path
                                        d="M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112c-4.1 3.2-4.1 9.4 0 12.6l142 112c5.2 4.1 12.9 0.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z"
                                        p-id="1438" fill="#bfbfbf"></path>
                                </svg>
                            </div>
                            <i title="删除" class="el-icon-delete ft25 red pointer"
                               @click="deletePaper(scope.$index, scope.row)"></i>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="card" v-if="switchStatusArr.card">
            <el-table :data="cardList" style="width: 100%">
                <el-table-column label="真实姓名">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <span style="margin-left: 10px">{{ scope.row.realName }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="公司" width="200">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <span size="medium">{{ scope.row.company }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="所在地区" width="150">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ scope.row.city }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="职位" width="80">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.jobPosition }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建者" width="80">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.createName }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" width="150">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100">
                    <template slot-scope="scope">
                        <div style="display: flex;justify-content: space-between;height:35px; ">
                            <!-- <i title="编辑" class="el-icon-edit-outline ft25 pointer" @click="editPaper(scope.$index, scope.row)"></i> -->
                            <div type="success" size='mini' class="ft12 operateEvent pointer" title="还原操作"
                                 @click="operatePaper(scope.$index, scope.row)" :name="scope.row.status">
                                <svg t="1528682042513" class="icon" style="" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="1437"
                                     xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25">
                                    <defs></defs>
                                    <path
                                        d="M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112c-4.1 3.2-4.1 9.4 0 12.6l142 112c5.2 4.1 12.9 0.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z"
                                        p-id="1438" fill="#bfbfbf"></path>
                                </svg>
                            </div>

                            <i title="删除" class="el-icon-delete ft25 red pointer"
                               @click="deletePaper(scope.$index, scope.row)"></i>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="activity" v-if="switchStatusArr.activity">
            <el-table :data="tableData" style="width: 100%">
                <el-table-column label="标题">
                    <template slot-scope="scope">
                        <!-- <el-popover trigger="hover" placement="top"> -->
                        <!-- <p>姓名: {{ scope.row.name }}</p> -->
                        <!-- <p>住址: {{ scope.row.address }}</p> -->
                        <div slot="reference" class="name-wrapper">
                            <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                            <span style="margin-left: 10px">{{ scope.row.title }}</span>
                        </div>
                        <!-- </el-popover> -->
                    </template>
                </el-table-column>
                <el-table-column label="作者" width="80">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <el-tag size="medium">{{ scope.row.author }}</el-tag>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column label="发布时间" width="150">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ formatStrTime(scope.row.releaseTime,16) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="点赞" width="80">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.praiseNum === null? 0:scope.row.praiseNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="分享" width="80">
                    <template slot-scope="scope">
                        <span
                            style="margin-left: 10px">{{ scope.row.forwardNum === null? 0:scope.row.forwardNum }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="100px">
                    <template slot-scope="scope">
                        <div style="display: flex;justify-content: space-between;height:35px; ">
                            <!-- 编辑 -->
                            <!-- <i title="编辑" class="el-icon-edit-outline ft25 pointer" @click="editPaper(scope.$index, scope.row)"></i> -->
                            <!-- <span class="ft12 operateEvent pointer" title="操作" @click="operatePaper(scope.$index, scope.row)" :name="scope.row.status">{{ scope.row.paperStatus }}</span> -->
                            <div class="ft12 operateEvent pointer" title="操作"
                                 @click="operatePaper(scope.$index, scope.row)" :name="scope.row.status">
                                <svg t="1528682042513" class="icon" style="" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="1437"
                                     xmlns:xlink="http://www.w3.org/1999/xlink" width="25" height="25">
                                    <defs></defs>
                                    <path
                                        d="M793 242H366v-74c0-6.7-7.7-10.4-12.9-6.3l-142 112c-4.1 3.2-4.1 9.4 0 12.6l142 112c5.2 4.1 12.9 0.4 12.9-6.3v-74h415v470H175c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h618c35.3 0 64-28.7 64-64V306c0-35.3-28.7-64-64-64z"
                                        p-id="1438" fill="#bfbfbf"></path>
                                </svg>
                            </div>
                            <!-- 删除 -->
                            <i title="删除" class="el-icon-delete ft25 red pointer"
                               @click="deletePaper(scope.$index, scope.row)"></i>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <!-- <commonlist :commons="commonData"></commonlist> -->
    </div>
</template>

<script>
    import bus from '../common/bus';
    // import commonlist from '../common/userCommon'
    export default {
        data: function () {
            return {
                switchStatusArr: {
                    paper_video: true,
                    ads: false,
                    card: false,
                    activity: false,
                },
                activeName: '文章',
                // loading: true,
                tableData: [],
                tagSwitchData: [],
                currentPage: 1,
                adstableData: [], // 广告数据
                cardList: [], // 名片展示数组
                total: 0,
                size: 0,
                currentTagId: '1',
                paperStatus: '未上线',
                paperId: '',
                showCommon: false,
                commonData: '',
                // 选择数组
                selectList: [],
                searchStr: ''
            }
        },
        components: {
            // commonlist
        },
        watch: {},
        created() {
            this.getPaperList(this.searchStr, this.currentTagId, 1, 10)
            this.tagSwitchData = this.$getDicByName('RECYCLE_TYPE')
        },
        mounted() {
            bus.$on('reloadPaper', res => {
                if (res) {
                    this.getPaperList(this.searchStr, this.currentTagId, this.currentPage, this.size)
                }
            })
        },
        methods: {
            handleSelectionChange(val) {
                this.selectList = val
            },
            // 菜单改变时调用
            switchTag(tab) {
                // //console.log(tab)
                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {
                        // this.getPaperList (this.tagSwitchData[i].value,1,10)
                        this.currentTagId = this.tagSwitchData[i].value
                        switch (this.currentTagId) {
                            case '1':
                            case '2':
                                this.getPaperList(this.searchStr, this.currentTagId, 1, 10)
                                this.setShowType('paper_video')
                                break;
                            case '3':
                                this.getCardList(this.searchStr, this.currentTagId, 1, 10)
                                this.setShowType('card')
                                break;
                            case '4':
                                this.loadads(this.searchStr, this.currentTagId, 1, 10)
                                this.setShowType('ads')
                                break;
                            case '5':
                                this.A_getPaperList(this.searchStr, this.currentTagId, 1, 10)
                                this.setShowType('activity')
                                break;
                            default:
                                this.$message.error('未匹配到对应的回收站类型！')
                                this.setShowType('false')
                                break;
                        }
                        break;
                    }
                }
            },
            // 设置显示页面
            setShowType(type) {
                for (var item in this.switchStatusArr) {
                    if (type == item) {
                        this.switchStatusArr[item] = true
                        // //console.log(item)
                        // //console.log(this.switchStatusArr[item] === true)
                    } else {
                        this.switchStatusArr[item] = false
                    }
                }
            },
            // 获取活动列表
            A_getPaperList(query = '', type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/chigua/admin/recycle/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        type: type,
                        query: query,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        for (var item in response.data.data.records) {
                            if (response.data.data.records[item].status === 1) {
                                response.data.data.records[item].paperStatus = '可上线'
                                // response.data.data.records[item].color = '#e3e3e3'
                            } else if (response.data.data.records[item].status === 2) {
                                response.data.data.records[item].paperStatus = '已上线'
                                // response.data.data.records[item].color = '#49293'
                            } else if (response.data.data.records[item].status === 3) {
                                response.data.data.records[item].paperStatus = '已下线'
                                // response.data.data.records[item].color = '#ff0000'

                            } else {
                                response.data.data.records[item].paperStatus = '未知状态'
                            }
                        }
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size
                    } else {
                        this.$message.error('菜单加载失败！')
                    }
                    loading.close()
                })
            },
            // 加载广告
            loadads(query = '', type, page, length) {
                this.$http({
                    url: this.$api + '/chigua/admin/recycle/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        query: query,
                        type: type,
                        page: page,
                        limit: length
                    })
                }).then(res => {
                    if (res.data !== null) {
                        this.adstableData = res.data.data.records
                        this.currentPage = res.data.data.current
                        this.size = res.data.data.size
                        this.total = res.data.data.total
                    } else {
                        this.$message.error('列表加载失败')
                    }
                }).catch(res => {

                })
            },
            // 加载名片
            getCardList(query = '', type, page, length) {
                this.$http({
                    url: this.$api + '/chigua/admin/recycle/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        query: query,
                        type: type,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    //
                    if (response.status === 200) {
                        if (response.data.errno === 0) {
                            this.cardList = response.data.data.records
                            this.currrent = response.data.data.current
                            this.size = response.data.data.size
                            this.total = response.data.data.total
                            this.searchStatus = true
                        }
                    }
                }).catch(error => {
                    this.$message.error('加载名片失败！')
                })
            },
            // 删除文章
            deletePaper(index, row) {
                this.$confirm('您正在进行【删除】操作，确认？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/recycle/delete',
                        method: 'post',
                        data: this.$qs.stringify({
                            ids: row.id,
                            type: this.currentTagId
                        })
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('已删除！')
                                switch (this.currentTagId) {
                                    case '1':
                                    case '2':
                                        this.getPaperList(this.searchStr, this.currentTagId, this.currentPage, this.size)
                                        this.setShowType('paper_video')
                                        break
                                    case '3':
                                        this.getCardList(this.searchStr, this.currentTagId, 1, 10)
                                        this.setShowType('card')
                                        break
                                    case '4':
                                        this.loadads(this.searchStr, this.currentTagId, 1, 10)
                                        this.setShowType('ads')
                                        break
                                    case '5':
                                        // 获取活动列表
                                        this.A_getPaperList(this.searchStr, this.currentTagId, 1, 10)
                                        this.setShowType('activity')
                                        break
                                    default:
                                        this.$message.error('未匹配到对应的回收站类型！')
                                        this.setShowType('false')
                                        break
                                }
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            operatePaper(index, row) {
                let url = '/chigua/admin/recycle/update', tips = "操作成功", alertText = '还原'
                this.$confirm('您正在进行【' + alertText + '】操作', '警示', {
                    confirmButtonText: alertText,
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + url,
                        method: 'post',
                        data: this.$qs.stringify({
                            ids: row.id,
                            type: this.currentTagId
                        })
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success(tips)
                                switch (this.currentTagId) {
                                    case '1':
                                    case '2':
                                        this.getPaperList(this.searchStr, this.currentTagId, this.currentPage, this.size)
                                        this.setShowType('paper_video')
                                        break
                                    case '3':
                                        this.getCardList(this.searchStr, this.currentTagId, 1, 10)
                                        this.setShowType('card')
                                        break
                                    case '4':
                                        this.loadads(this.searchStr, this.currentTagId, 1, 10)
                                        this.setShowType('ads')
                                        break
                                    case '5':
                                        // 获取活动列表
                                        this.A_getPaperList(this.searchStr, this.currentTagId, 1, 10)
                                        this.setShowType('activity')
                                        break
                                    default:
                                        this.$message.error('未匹配到对应的回收站类型！')
                                        this.setShowType('false')
                                        break
                                }
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });

            },
            handleSizeChange(val) {
                switch (this.currentTagId) {
                    case '1':
                    case '2':
                        this.getPaperList(this.searchStr, this.currentTagId, this.currentPage, val)
                        this.setShowType('paper_video')
                        break
                    case '3':
                        this.getCardList(this.searchStr, this.currentTagId, this.currentPage, val)
                        this.setShowType('card')
                        break
                    case '4':
                        this.loadads(this.searchStr, this.currentTagId, this.currentPage, val)
                        this.setShowType('ads')
                        break
                    case '5':
                        // 获取活动列表
                        this.A_getPaperList(this.searchStr, this.currentTagId, this.currentPage, val)
                        this.setShowType('activity')
                        break
                    default:
                        this.$message.error('未匹配到对应的回收站类型！')
                        this.setShowType('false')
                        break
                }
            },
            handleCurrentChange(val) {
                switch (this.currentTagId) {
                    case '1':
                    case '2':
                        this.getPaperList(this.searchStr, this.currentTagId, val, this.size)
                        this.setShowType('paper_video')
                        break
                    case '3':
                        this.getCardList(this.searchStr, this.currentTagId, val, this.size)
                        this.setShowType('card')
                        break
                    case '4':
                        this.loadads(this.searchStr, this.currentTagId, val, this.size)
                        break
                    case '5':
                        // 获取活动列表
                        this.A_getPaperList(this.searchStr, this.currentTagId, val, this.size)
                        this.setShowType('activity')
                        break
                    default:
                        this.$message.error('未匹配到对应的回收站类型！')
                        this.setShowType('false')
                        break
                }
            },
            getPaperList(query = '', type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/chigua/admin/recycle/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        type: type,
                        page: page,
                        limit: length,
                        // query: query
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        for (var item in response.data.data.records) {
                            if (response.data.data.records[item].status === 1) {
                                response.data.data.records[item].paperStatus = '可上线'
                                // response.data.data.records[item].color = '#e3e3e3'
                            } else if (response.data.data.records[item].status === 2) {
                                response.data.data.records[item].paperStatus = '已上线'
                                // response.data.data.records[item].color = '#49293'
                            } else if (response.data.data.records[item].status === 3) {
                                response.data.data.records[item].paperStatus = '已下线'
                                // response.data.data.records[item].color = '#ff0000'

                            } else {
                                response.data.data.records[item].paperStatus = '未知状态'
                            }
                        }
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size
                    } else {
                        this.$message.error('菜单加载失败！')
                    }
                    loading.close()
                })
            },
        }
    }
</script>
<style>
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .tagSwitch {
        width: 100%;
        height: auto;
    }
</style>
