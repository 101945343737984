<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <h2 class="padding15">客服内容设置（客服微信以及小tips）</h2>
            <div class="editBox_preview" @click="showPreviewPhone = !showPreviewPhone">
                <ueditor class="editBox" v-model="pushData.content"></ueditor>
                <phone-preview :html="pushData.goods_desc" v-model="showPreviewPhone"></phone-preview>
            </div>
        </div>
        <div class="operationBtn">
            <el-button class="editor-btn" type="primary" @click="submitPaper">提交</el-button>
        </div>
    </div>
</template>

<script>
    import ueditor from '../../common/editor'
    export default {
        data: function () {
            return {
                showPreviewPhone: false,
                pushData: {
                    content: '',
                }
            }
        },
        components: {
            ueditor
        },
        methods: {
            submitPaper() {
                const loading = this.$loading({
                    lock: true,
                    text: '正在提交...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // 判断是否需要直接设置成新品或者是是否上线销售，
                this.$http({
                    url: this.$api + '/admin/serviceAccount/setCustomerServiceContent',
                    method: 'post',
                    data: this.pushData
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$notify({
                            title: '成功',
                            message: '新增客服内容成功!',
                            type: 'success'
                        });
                    }
                })
                loading.close()
            },
            getCustomerService () {
                // 判断是否需要直接设置成新品或者是是否上线销售，
                this.$http({
                    url: this.$api + '/admin/serviceAccount/customerServiceContent',
                    method: 'post'
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.pushData.content = response.data.data.content
                    }
                })
            }
        },
        created() {
        },
        mounted() {
            this.getCustomerService();
        }
    }
</script>
<style>

</style>
