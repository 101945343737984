<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>能不能吃</el-breadcrumb-item>
                <el-breadcrumb-item>食物列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="padding: 10px">
            <el-button type="primary" @click="addTypeDialogData.isShow=true" style="margin-top: 1em;">添加食物分类</el-button>
            <el-table
                :data="$store.state.getFoodsList"
                style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <!--该分类存在食品-->
                        <div v-if="props.row.childrens.length>0">
                            <div class="single_food thead">
                                <div class="single_data">食品名称</div>
                                <div class="single_data">食品图片</div>
                                <div class="single_data">食品序号</div>
                                <div class="single_data">能吃类型</div>
                                <div class="single_data front_desc">食品描述</div>
                                <div class="single_data">操作</div>
                            </div>
                            <div v-for="item in props.row.childrens" class="single_food">
                                <div class="single_data">{{item.name}}</div>
                                <div class="single_data">
                                    <img class="food_picture" :src="item.wap_banner_url" alt="">
                                </div>
                                <div class="single_data">{{item.sort_order}}</div>
                                <!--能吃类型-->
                                <div class="single_data">
                                    <span v-if="item.eat_type==1" style="color: red;">禁吃</span>
                                    <span v-else-if="item.eat_type==2" style="color: #ff6602;">慎吃</span>
                                    <span v-else style="color: #1AAD19;">可吃</span>
                                </div>
                                <div class="single_data front_desc">{{item.front_desc}}</div>
                                <div class="single_data">
                                    <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                                       @click="editFood(item.id)"></i>

                                    <!-- 上下线 -->
                                    <i title="下线" class="el-icon-remove-outline ft25 pointer" style="margin-left: 10px"
                                       @click="downLineFood(item)"></i>

                                </div>
                            </div>
                        </div>
                        <!--该分类不存在食品-->
                        <div v-else style="color: #ccc;">该分类不存在食品</div>

                        <!--<el-form label-position="left" inline class="demo-table-expand">-->
                           <!---->
                            <!--<el-form-item label="商品名称">-->
                                <!--<span>{{ props.row.name }}</span>-->
                            <!--</el-form-item>-->
                        <!--</el-form>-->
                    </template>
                </el-table-column>
                <el-table-column width="200" label="食品分类名称" prop="name"></el-table-column>
                <el-table-column width="200"  label="食品序号" prop="sort_order"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <!--<el-button-->
                            <!--size="mini"-->
                            <!--@click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
                        <el-button size="mini" type="default" @click="editType(scope.row.id)">修改食品分类</el-button>
                        <el-button size="mini" type="primary" @click="addFoodClick(scope.row.id)">添加食品</el-button>
                        <el-button size="mini" type="danger" @click="downLineFood(scope.row)">下线</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--添加食品分类弹窗-->
        <add-type-dialog :addTypeDialogData="addTypeDialogData"></add-type-dialog>

        <!--修改食品分类弹窗-->
        <edit-type-dialog :editTypeDialogData="editTypeDialogData"></edit-type-dialog>

        <!--添加食品弹窗-->
        <add-food-dialog :addFoodDialogData="addFoodDialogData"></add-food-dialog>

        <!--修改食品弹窗-->
        <edit-food-dialog :editFoodDialogData="editFoodDialogData"></edit-food-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import addTypeDialog from './children/addTypeDialog'
    import addFoodDialog from './children/addFoodDialog'
    import editFoodDialog from './children/editFoodDialog'
    import editTypeDialog from './children/editTypeDialog'

    export default {
        data: function () {
            return {
                addTypeDialogData:{
                    isShow: false
                },
                editTypeDialogData:{
                    isShow: false,
                    type_id: null,
                },
                addFoodDialogData:{
                    isShow: false,
                    parent_id: null
                },
                editFoodDialogData:{
                    isShow: false,
                    food_id: null,
                }
            }
        },
        components: {
            addTypeDialog,
            addFoodDialog,
            editFoodDialog,
            editTypeDialog
        },
        methods: {
            //点击编辑食品分类
            editType(type_id){
                this.editTypeDialogData.isShow = true;
                this.editTypeDialogData.type_id = type_id;
            },
            //点击编辑食品
            editFood(food_id){
                this.editFoodDialogData.isShow = true;
                this.editFoodDialogData.food_id = food_id;
            },
            //点击添加食品
            addFoodClick(id){
                this.addFoodDialogData.isShow=true;
                this.addFoodDialogData.parent_id = id;
            },
            //下线食品
            downLineFood(food) {
                this.$confirm('此操作下线食品分类或食品《'+food.name+'》, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$api + 'admin/disease/switch', {
                        foods_category_id: food.id,
                    }).then(res => {
                        if(res.data.errno===0){
                            this.$message.success("食品/分类下线成功");
                            this.$store.dispatch('getFoodsList');
                        }
                    })
                }).catch(() => {
                });
            }
        },
        created(){
            if ( this.$store.state.getFoodsList.length <= 0 ) {
                this.$store.dispatch('getFoodsList')
            }
        },
        mounted() {
        },
    }
</script>

<style lang="scss" scoped>
    .single_food{
        display: flex;
        align-items: center;
        margin: 2px 0;
        padding: 10px;
        border-radius: 4px;
        &.thead{
            font-weight: bold;
            font-size: 15px;
            color: #000;
            &:hover{
                background-color: #f6faff;
            }
        }
        &:hover{
            background-color: #fff;
        }
        .single_data{
            /*flex: 1;*/
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.front_desc{
                /*flex: 1;*/
                width: 300px;
                justify-content: left;
            }
            .food_picture{
                width: 80px;
                height: 80px;
                border-radius: 4px;
            }
        }
    }

    .pointer{
        cursor: pointer;
    }

</style>
