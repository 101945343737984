<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span></label>
        </div>
        <keyword_search_charts :charts-data="userSearchKeyword" id-attr="userAnalysis" name="搜索次数"></keyword_search_charts>
    </div>
</template>

<script>
    import multiCharts from '../../common/multiCharts'
    export default {
        data: function () {
            return {
                searchState: '',
                userSearchKeyword: []
            }
        },
        components: {
            keyword_search_charts: multiCharts,
        },
        watch: {

        },
        created() {

        },
        mounted() {
          this.loadKeywordTimesEachDay();
        },
        methods: {
            //按标题搜索列表
            handleSearchByTitle() {
                this.loadKeywordTimesEachDay()
            },
            // 加载近三个月数据
            loadKeywordTimesEachDay () {
                this.$http({
                    url: this.$api + '/admin/analysis/keywordTimesEachDay',
                    method: 'post',
                    data: {
                        keyword: this.$route.query.keyword
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        let arr = [];
                        arr.push('关键字分析');
                        arr.push(['totalNumber','搜索总数']);
                        this.userSearchKeyword = arr.concat(res.data.data)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
