<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}人）</label>
        </div>
        <el-table :data="tableData.data"
                  v-loading="!tableData.data" style="width: 100%"
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column label="头像" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.avatar" style="width: 40px;vertical-align: middle;">
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="用户ID" prop="id" width="80"></el-table-column>
            <el-table-column label="用户名" prop="username" width="80"></el-table-column>
            <el-table-column label="最后登录时间" prop="last_login_time" width="200"></el-table-column>
            <el-table-column label="最后登录时间" prop="last_login_ip" width="200"></el-table-column>
            <el-table-column label="新增时间" prop="add_time" width="200"></el-table-column>
            <el-table-column label="最近修改时间" prop="update_time" width="200"></el-table-column>
            <el-table-column label="管理角色" prop="role_name">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        {{scope.row.role_name}}
                        <el-button type="primary" @click="showChangeAdminRole = !showChangeAdminRole">变更</el-button>
                    </div>
                </template>
            </el-table-column>


            <el-table-column type="expand">
                <right-management :is-not-components="false"></right-management>
            </el-table-column>

        </el-table>

        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>

        <el-dialog title="变更用户角色"
                   :visible.sync="showChangeAdminRole"
                   width="50%">
            <el-select v-model="update_admin_role" placeholder="请选择角色">
                <el-option
                    v-for="item in admin_role_data"
                    :key="item.id"
                    :label="item.role_name"
                    :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" @click="updateAdminUser">提交</el-button>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import rightManagement from './rightManagement';
    export default {
        data: function () {
            return {
                activeName: '全部用户',
                tableData: {},
                paperId: '',
                searchTitle: '',
                tableHeight: 600,
                selectUserList: [],
                showChangeAdminRole: false,
                admin_role_data: [],
                update_admin_role: '', // 更新的管理员角色的值
            }
        },
        components: {rightManagement},
        watch: {
            showChangeAdminRole (newv, oldv) {
                if (this.admin_role_data.length <= 0) {
                    this.loadAdminRoleList()
                }
            }
        },
        created() {
            this.getPaperList(0, 1, 10)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
        },
        mounted() {

        },
        methods: {
            // 更新管理员角色
            updateAdminUser () {
                this.$alert('您正在变更用户角色，请确认你知道你在做什么！请确认！', {
                    confirmButtonText: '确认变更',
                    showCancelButton: true,
                    cancelButtonText: '取消',
                    callback: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.$http({
                                url: this.$api + '/admin/adminManagement/updateAdminRole',
                                method: 'post',
                                data: {
                                    role_id: this.update_admin_role
                                }
                            }).then(response => {
                                if (response.data.errno === 0) {
                                    this.$message.success('用户角色更新成功！！');
                                    this.showChangeAdminRole = !this.showChangeAdminRole;
                                    this.getPaperList()
                                } else {
                                    this.$message.fail('用户角色更新失败！');
                                }
                            })
                        } else {
                            this.$message.success('操作取消！');
                        }
                    }
                })
            },
            // 加载角色信息
            loadAdminRoleList () {
                this.$http({
                    url: this.$api + '/admin/adminManagement/adminRoleList',
                    method: 'post'
                }).then(response => {
                    if (response.data.data != null) {
                        this.admin_role_data = response.data.data
                    } else {
                        this.$message.error('加载用户角色操作失败！')
                    }
                })
            },
            handleSelectionChange (val) {
                this.selectUserList = val
            },
            handleSearchByTitle() {
                if (this.activeName === '黑名单') {
                    this.getPaperList(1, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            handleSizeChange(val) {

                if (this.activeName === '黑名单') {
                    this.getPaperList(1, this.tableData.currentPage || 1, val, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, val, this.searchTitle)
                }
            },
            handleCurrentChange(val) {
                if (this.activeName === '黑名单') {
                    this.getPaperList(1, val, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, val, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            getPaperList(is_limit, page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/adminManagement/getAllAdminRole',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page || 1,
                        size: length || 10,
                        name: title || '',
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style scoped>
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .pointer {
        cursor: pointer;
    }
</style>

