<template>
    <div>

        <el-dialog :visible.sync="showaddForm" width="75%" :title="editMenuData.name">
            <!-- :title="'文章评论(共'+commons.total+'条)'" -->
            <el-form :model="editMenuData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <!--<el-form-item label="频道类型" prop="menuType">-->
                <!--<el-radio :disabled="editMenuData.menuType!==item.value" v-model="editMenuData.menuType"-->
                <!--v-for="item in menuType" :label="item.value" :key="item.value" border>{{item.name}}-->
                <!--</el-radio>-->
                <!--</el-form-item>-->

                <el-form-item label="频道名称" prop="name">
                    <el-input v-model="editMenuData.name"></el-input>
                </el-form-item>

                <el-form-item  label="一级频道图标">
                    <upload-image title="一级频道必填" v-model="editMenuData.icon_url" style="margin-right: 50px">
                        <div style="text-align: center;">一级频道logo必填，二级频道可不填</div>
                    </upload-image>
                </el-form-item>




                <el-form-item label="排序">
                    <el-input-number v-model="editMenuData.sort_order" :min="1" :max="1000"></el-input-number>
                </el-form-item>


                <el-form-item label="频道地址">
                    <el-input v-model="editMenuData.url"></el-input>
                </el-form-item>

                <el-form-item label="频道图标样式">
                    <el-input v-model="editMenuData.img_style" placeholder="可不填，不填则使用默认样式"></el-input>
                </el-form-item>

                <el-form-item label="频道字体样式">
                    <el-input v-model="editMenuData.text_style" placeholder="可不填，不填则使用默认样式"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="addMenu('ruleForm')">下线频道并更新（更新后需要重新上线）</el-button>
                    <el-button @click="resetForm('ruleForm')">重置频道</el-button>
                </el-form-item>
            </el-form>
            <h2 style="padding: 15px;border-top: 1px solid #eee;">快捷设置为频道</h2>
            <el-tree
                class="menu-tree-table"
                :data="categoryList"
                :props="defaultProps"
                :indent="0"
                :expand-on-click-node="false"
                accordion
                @node-click=""
            >
                <div class="menu-flex" style="width: 100%" slot-scope="{ node, data }" @click="setAsChannel(data)">
                    <!--频道名-->
                    <div style="width: 8em">
                        <img v-if="data.level === 'L1'" style="width: 25px;height: 35px; margin-right: 10px;vertical-align: middle;" :src="data.icon_url" alt="频道图标">
                        <span style="padding-left: 1px">{{ data.name }}({{data.id}})</span>
                    </div>
                    <!--图标-->
                    <div >
                        <img style="height: 35px; margin-right: 10px;vertical-align: middle;" :src="data.wap_banner_url" alt="频道展示背景图">
                    </div>
                    <div style="font-size: 12px;color: #999;">
                        序号： {{data.sort_order}}
                    </div>
                    <div style="">
                        <i title="设为频道" class="el-icon-location ft25 pointer" style="margin-left: 10px"></i>

                    </div>
                </div>
            </el-tree>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import uploadImage from '../../common/uploadImage'

    export default {
        data() {
            return {
                showaddForm: false,
                editMenuData: {
                    name: '',
                    url: '',
                    icon_url: '', // 一级频道logo
                    sort_order: 99,
                    is_show: 0, // 设置为下线状态，需要手动更新上线
                    img_style: '',  //图标样式
                    text_style: '',  //频道字体样式
                },
                isShows: [
                    {name: '显示', value: 1},
                    {name: '隐藏', value: 0}
                ],
                rules: {
                    name: [
                        {required: true, message: '请输入频道名称', trigger: 'blur'},
                        {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
                    ],
                    isShow: [
                        {required: true, message: '请选择频道状态', trigger: 'blur'}
                    ],
                    parent_id: [
                        {required: true, message: '请填写频道id', trigger: 'blur'}
                    ]
                },
                // 默认
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
            }
        },
        components: {
            uploadImage
        },
        computed: {
            categoryList: {
                get () {
                    return this.$store.state.categoryList
                },
                set (v) {
                    return v
                }
            }
        },
        mounted() {
            bus.$on('editChannel', data => {
                this.showaddForm = true
                this.editMenuData= data
            })
        },
        created () {
            if ( this.$store.state.categoryList.length <= 0 ) {
                this.$store.dispatch('getCategoryList')
            }
        },
        methods: {
            // 设为频道
            setAsChannel (data) {
                //console.log(data)
                this.editMenuData.icon_url = data.icon_url;
                this.editMenuData.name = data.name;
                this.editMenuData.url = '/pages/category/category?id=' + data.id;
            },
            addMenu() {
                this.$http({
                    url: this.$api + '/admin/channel/update',
                    method: 'post',
                    data: this.$qs.stringify(this.editMenuData)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.showaddForm = false;
                        this.$message.success('修改频道成功!');
                        let update = true;
                        bus.$emit('updateChannel', update)
                    } else if (response.data.errno === 500) {
                        this.$message.error(response.data.msg);
                    }
                }).catch(error => {
                    //console.log(error)
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // dialogClose(done) {
            //   this.showEditForm = false
            // }
        }
    }
</script>
<style>
</style>>
