<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 字典</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-input placeholder="请输入内容" v-model="searchStr" class="input-with-select">
            <!--<el-select v-model="select" slot="prepend" placeholder="快捷搜索">-->
                <!--<el-option v-for="it in selectDitData" :label="it[0].name" :value="it[0].value"-->
                           <!--:key="it[0].value + it[0].name + Math.random(1,999)"></el-option>-->
            <!--</el-select>-->
            <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
        </el-input>
        <div class="container">
            <el-collapse accordion>
                <el-collapse-item v-for="item in ditData" :key="item.typeKey"
                                  :title="item.typeValue +'【'+ item.typeKey + '】'" :name="item.id"
                                  @click.native="loadDitDetail(item.id)">
                    <div style="text-align: right;">
                        <el-tag style="cursor: pointer;color: #429493;" @click.native="deleteDitType(item.id)">删除本字典类</el-tag>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <el-tag style="cursor: pointer;color: #429493;" @click.native="showDitTypeEdit(item)">修改字典类型</el-tag>
                    </div>
                    <el-table :data="ditDetailList" style="width: 100%">
                        <el-table-column label="字典名">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <span size="medium">{{ scope.row.dicValue }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="字典值">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <el-tag size="medium">{{ scope.row.dicKey }}</el-tag>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="150">
                            <template slot-scope="scope">
                                <div style="width: 100%;display: flex;justify-content: space-between;">
                                    <span @click="editDitValue(scope.row)" class="el-icon-edit-outline ft25"></span>
                                    <span @click="deleteDit(scope.row.id,scope.row.typeId)"
                                          class="el-icon-delete ft25 red"></span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-collapse-item>
            </el-collapse>
            <div class="paginaion" style="text-align: center;margin-top: 1em">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageContent.current"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="tableData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageContent.total">
                </el-pagination>
            </div>
            <el-dialog
                title="修改字典"
                :visible.sync="editDitValueBoo"
                width="30%">
                <el-form>
                    <el-form-item label="字典名">
                        <el-input v-model="dicEditData.dicValue" placeholder="请输入字典名"></el-input>
                    </el-form-item>
                    <el-form-item label="字典值">
                        <el-input v-model="dicEditData.dicKey" placeholder="请输入字典值"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="editDitValueBoo = false">取 消</el-button>
                    <el-button type="primary" @click="saveEditDitValue(dicEditData.typeId)">修  改</el-button>
                  </span>
            </el-dialog>
        </div>
        <br/>
        <div style="text-align: left;">
            <el-button type="success" @click="addnewDit">新增字典</el-button>
            <el-button type="default" @click="addnewDitType">新增字典类型</el-button>
        </div>
        <el-dialog
            title="新增字典"
            :visible.sync="addDit"
            width="30%">
            <el-form>
                <el-form-item label="字典类型">
                    <el-select v-model="adddicEditData.typeId">
                        <el-option v-for="it in selectDitDataTypeForAdd" :label="it[0]" :value="it[1]"
                                   :key="it[0]"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="字典名">
                    <el-input v-model="adddicEditData.dicValue" placeholder="请输入字典名"></el-input>
                </el-form-item>
                <el-form-item label="字典值">
                    <el-input v-model="adddicEditData.dicKey" placeholder="请输入字典值"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="adddicEditData.remark" placeholder="请输入类型备注" require></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input type="number" v-model="adddicEditData.sort" placeholder="请输入排序(数字)" require></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="addDit = false">取 消</el-button>
            <el-button type="primary" @click="saveAddNewDit()">保  存</el-button>
          </span>
        </el-dialog>

        <el-dialog
            title="新增字典类型"
            :visible.sync="addDitType"
            width="30%">
            <el-form>
                <el-form-item label="字典类型">
                    <el-input v-model="addDitTypeData.typeValue" placeholder="请输入字典类型" require></el-input>
                </el-form-item>
                <el-form-item label="字典值">
                    <el-input v-model="addDitTypeData.typeKey" placeholder="请输入字典值" require></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="addDitTypeData.remark" placeholder="请输入类型备注" require></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input type="number" v-model="addDitTypeData.sort" placeholder="请输入排序(数字)" require></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="addDitType = false">取 消</el-button>
            <el-button type="primary" @click="saveAddNewDitType()">保  存</el-button>
          </span>
        </el-dialog>

        <el-dialog
            title="修改字典类型"
            :visible.sync="editDitTypeStatus"
            width="30%">
            <el-form>
                <el-form-item label="字典类型">
                    <el-input v-model="editDitTypeData.typeValue" placeholder="请输入字典类型" require></el-input>
                </el-form-item>
                <el-form-item label="字典值">
                    <el-input v-model="editDitTypeData.typeKey" placeholder="请输入字典值" require></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="editDitTypeData.remark" placeholder="请输入类型备注" require></el-input>
                </el-form-item>
                <el-form-item label="排序">
                    <el-input type="number" v-model="editDitTypeData.sort" placeholder="请输入排序(数字)" require></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="editDitTypeStatus = false">取 消</el-button>
            <el-button type="primary" @click="saveEditNewDitType()">保  存</el-button>
          </span>
        </el-dialog>


    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                searchStr: '',
                addDit: false,
                addDitType: false,
                editDitTypeStatus: false,
                editDitTypeData: {},
                dicEditData: {
                    typeId: '',
                    dicKey: '',
                    dicValue: '',
                    remark: '',
                    sort: '',
                    id: ''
                },
                adddicEditData: {
                    typeId: '',
                    dicKey: '',
                    dicValue: '',
                    remark: '',
                    sort: '',
                    id: ''
                },
                addDitTypeData: {
                    remark: '',
                    sort: '',
                    typeValue: '',
                    typeKey: ''
                },
                editDitValueBoo: false,
                ditData: [],
                selectDitDataTypeForAdd: [],
                selectDitData: {
                    ACTIVITY_TYPE: {}
                },
                select: [],
                ditDetailList: [],
                size: '',
                page: '',
                current: '',
                currentDitId: '',
                pageContent: {
                    pages: 1,
                    current: 1,
                    size: 10,
                    total: 0
                }
            }
        },
        created() {
            this.loadDictionary(this.searchStr, this.pageContent.pages, this.pageContent.size)
            this.selectDitData = this.$qs.parse(localStorage.getItem('dictionary'))
        },
        computed: {
            activeName: {
                get: () => {
                },
                set: () => {
                }
            }
        },
        methods: {
            // 保存类型编辑
            saveEditNewDitType() {
                this.$http({
                    url: this.$api + '/api/sys/dic/type/update',
                    method: 'post',
                    data: this.$qs.stringify(this.editDitTypeData)
                }).then(res => {
                    debugger
                    if (res.data.errno === 0) {
                        this.$message.success(res.data.errmsg)
                        // 新增玩字典，从重新加载列表
                        this.loadDictionary(this.searchStr, this.pageContent.pages, this.pageContent.size)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            // 显示类型编辑
            showDitTypeEdit(item) {
                this.editDitTypeStatus = true
                this.editDitTypeData = item
            },
            handleCurrentChange(val) {
                this.pageContent.pages = val
                this.loadDictionary(this.searchStr, this.pageContent.pages, this.pageContent.size)
            },
            handleSizeChange(val) {
                this.pageContent.size = val
                this.loadDictionary(this.searchStr, 1, this.pageContent.size)
            },
            // 删除字典类型
            deleteDitType(id) {
                this.$http({
                    url: this.$api + '/api/sys/dic/type/delete',
                    method: 'post',
                    data: this.$qs.stringify({ids: id})
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        // 新增玩字典，从重新加载列表
                        this.loadDictionary(this.searchStr, this.pageContent.pages, this.pageContent.size)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            // 新增字典类型
            saveAddNewDitType() {
                this.$http({
                    url: this.$api + '/api/sys/dic/type/add',
                    method: 'post',
                    data: this.$qs.stringify(this.addDitTypeData)
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        // 新增玩字典，从重新加载列表
                        this.loadDictionary(this.searchStr, this.pageContent.pages, this.pageContent.size)
                        this.addDitType = false
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            // 新增字典
            saveAddNewDit() {
                this.$http({
                    url: this.$api + '/api/sys/dic/save',
                    method: 'post',
                    data: this.$qs.stringify(this.adddicEditData)
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.addDit = false
                        this.loadDictionary(this.searchStr, this.pageContent.pages, this.pageContent.size)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            // 显示添加字典类型
            addnewDitType() {
                this.addDitType = true
            },
            // 显示添加字典
            addnewDit() {
                this.addDit = true
            },
            // 保存字典更新
            saveEditDitValue(typeId) {
                this.$http({
                    url: this.$api + '/api/sys/dic/update',
                    method: 'post',
                    data: this.$qs.stringify(this.dicEditData)
                }).then(res => {

                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        // 带上布尔值，表示这个不需要拦截
                        this.loadDitDetail(this.currentDitId, true)
                        this.editDitValueBoo = false
                    }else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            // 删除字典
            deleteDit(id, typeId) {
                this.log(typeId)
                this.$http({
                    url: this.$api + '/api/sys/dic/delete',
                    method: 'post',
                    data: this.$qs.stringify({ids: id})
                }).then(res => {

                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        // 删除字典后重载详情
                        this.loadDitDetail(this.currentDitId, true)
                    }
                }).catch(response => {
                    //console.log(response)

                })
            },
            // 编辑字典，显示编辑段落
            editDitValue(row) {
                for (var item in row) {
                    for (var item2 in this.dicEditData) {
                        this.dicEditData[item] = row[item]
                    }
                }
                this.log(row)
                this.editDitValueBoo = true
            },
            search() {
                this.loadDictionary(this.searchStr, 1, this.size)
            },
            loadDitDetail(id, boo) {
                // 防止多次触发
                if (this.currentDitId == id && !boo) return
                this.currentDitId = id
                this.$http({
                    url: this.$api + '/api/sys/dic/page',
                    method: 'post',
                    data: this.$qs.stringify({typeId: id})
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.ditDetailList = res.data.data.records
                    }
                }).catch(response => {
                    //console.log(response)
                })
            },
            onSubmit() {
                this.$message.success('提交成功！');
            },
            loadDictionary(t, p, l) {
                this.$http({
                    url: this.$api + '/api/sys/dic/type/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        typeValue: t ? t : '',
                        page: p ? p : 1,
                        limit: l ? l : 10
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.ditData = res.data.data.records

                        for (var item in res.data.data) {
                            this.pageContent[item] = res.data.data[item]
                        }
                        for (var item in res.data.data.records) {
                            let jsond = {}
                            jsond[0] = res.data.data.records[item].typeValue
                            jsond[1] = res.data.data.records[item].id
                            this.selectDitDataTypeForAdd.push(jsond)
                        }
                    }
                }).catch(response => {
                    //console.log(response)
                })
            }

        }
    }
</script>
<style>
    .el-select .el-input {
        width: 130px;
    }

    .input-with-select .el-input-group__prepend {
        background-color: #fff;
    }

    .input-with-select {
        margin-bottom: 15px;
    }
</style>
