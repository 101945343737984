<template>
    <div>
        <el-dialog :visible.sync="isShow" width="60%" :title="TemplateCode">
            <el-form :model="sendForm"  ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="模板名称" prop="name">
                    <el-select v-model="TemplateCode">
                        <el-option  v-for="item in smsTemplate" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="收件人电话，多个电话请用英文逗号【,】分隔" prop="sort">
                    <el-input v-model="sendForm.PhoneNumbers"></el-input>
                </el-form-item>

                <el-form-item :label="item" prop="sort" v-for="(item, index) in selectTemplateOrigin.requireParam" :key="index">
                    <el-input type="textarea" v-model="selectTemplate[item]"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button @click="sendSms">发送短信</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                TemplateCode: '',
                sendForm: {
                    PhoneNumbers: '',
                    TemplateParam: {

                    }
                },
                smsTemplate: [],
                selectTemplateOrigin: {
                    requireParam: []
                },
                selectTemplate: {
                }
            }
        },
        props: {
            isShow: {
                type: Boolean,
                default: true
            }
        },
        components: {
        },
        watch: {
            TemplateCode (newValue, oldValue) {
                let that = this;
                this.smsTemplate.map(function (item) {
                    if (item.value === newValue) {
                        let obj = {};
                        item.requireParam.map(function (param) {
                           obj[item] = '';
                        });
                        that.selectTemplateOrigin = item;
                        that.selectTemplate = obj
                    }
                })
            }
        },
        mounted() {
            this.loadSmsConfig();
        },
        methods: {
            sendSms() {
                this.sendForm.TemplateParam = JSON.stringify(this.selectTemplate);
                this.sendForm.TemplateCode = this.TemplateCode;
                this.$http({
                    url: this.$api + '/admin/sms/sendSmsNotice',
                    method: 'post',
                    data: this.sendForm
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('发送成功！')
                    } else if (response.data.errno === 500) {
                        this.$message.error(response.data.msg)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            loadSmsConfig() {
                this.$http({
                    url: this.$api + '/admin/adminManagement/getSmsTemplate',
                    method: 'get',
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.smsTemplate = response.data.data;
                    } else if (response.data.errno === 500) {
                        this.$message.error(response.data.msg)
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        }
    }
</script>
<style>
</style>>
