<template>
    <el-row>
        <el-col :span="12">
            <h2 class="padding15">加购商品排行榜</h2>
            <el-table
                :data="buyAnalysisData"
                stripe
                style="width: 100%">
                <el-table-column label="商品头图" width="80">
                    <template slot-scope="scope">
                        <img class="width60px" :src="scope.row.primary_pic_url" alt="">
                    </template>
                </el-table-column>
                <el-table-column
                    label="商品名">
                    <template slot-scope="scope">
                        <a @click="$router.push('/goodsDetail?id=' + scope.row.id)">{{scope.row.name}}</a>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="count"
                    label="总加购数">
                </el-table-column>
            </el-table>
        </el-col>
        <el-col :span="12" style="border-left: 3px solid #666;">
            <h2 class="padding15">用户加购排行榜</h2>
            <el-table
                :data="userCartCountAnalysisData"
                stripe
                style="width: 100%">
                <el-table-column label="用户头像" width="80">
                    <template slot-scope="scope">
                        <img class="width60px" :src="scope.row.avatar" alt="">
                    </template>
                </el-table-column>
                <el-table-column
                    prop="nickname"
                    label="昵称"
                    width="180">
                </el-table-column>
                <el-table-column
                    prop="count"
                    label="加购数"
                    width="180">
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: "baseAnalysis",
        data () {
            return {
                buyAnalysisData: [],
                userCartCountAnalysisData: []
            }
        },
        mounted () {
            this.getBuyAnalysisData();
            this.userCartCountAnalysis()
        },
        methods: {
            async getBuyAnalysisData () {
                await this.$http({
                    url: this.$api + '/admin/buyCartAnalysis/index',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.buyAnalysisData = res.data.data
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {
                    this.$message.error(response)
                })
            },
            async userCartCountAnalysis () {
                await this.$http({
                    url: this.$api + '/admin/buyCartAnalysis/userCartCountAnalysis',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.userCartCountAnalysisData = res.data.data
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {
                    this.$message.error(response)
                })
            },
        }
    }
</script>

<style scoped>

</style>
