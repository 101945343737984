<template>
    <div>
        <el-dialog
            title="填写发货信息"
            :visible.sync="isShowDialog"
            @close="closeBox"
            width="90%">
            <div class="maskLayer" @click="closeBox"></div>
            <div class="dialogBox">
                <h3 class="padding15">填写发货信息</h3>
                <div class="disflex_around_start">
                    <!--物流信息-->
                    <div class="expressBox">
                        <el-form ref="express" :model="express">
                            <fieldset class="padding15 border_eee">
                                <legend class="color_cdcdcd">物流信息</legend>
                                <el-form-item :rules="[
                              { required: true, message: '运单号码不能为空'}
                            ]" prop="LogisticCode">
                                    <span>运单号码: </span>
                                    <el-input v-model="recogniseLogisticCode" placeholder="请填写运单号码(快递单号)"></el-input>
                                </el-form-item>
                                <el-form-item :rules="[
                              { required: false, message: '物流公司名称不能为空'}
                            ]" prop="ShipperName">
                                    <span>物流公司：</span>
                                    <el-select v-model="express.ShipperCode" placeholder="请选择快递公司">
                                        <el-option
                                            v-for="item in recogniseExpressList"
                                            :key="item.ShipperCode"
                                            :label="item.ShipperName"
                                            :value="item.ShipperCode">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item :rules="[
                              { required: true, message: '物流公司名称不能为空'}
                            ]" prop="ShipperCode">
                                    <span>物流公司代号：</span>
                                    <el-input v-model="express.ShipperCode" placeholder="请填写物流公司代号" ></el-input>
                                </el-form-item>

                                <el-form-item>
                                    <span>付款方式：</span>
                                    <el-select v-model="express.PayType" placeholder="请选择付款方式">
                                        <el-option
                                            v-for="item in PayTypeOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>

                                <el-form-item>
                                    <span>快递类型：</span>
                                    <el-select v-model="express.ExpType" placeholder="请选择快递类型">
                                        <el-option
                                            v-for="item in ExpTypeOptions"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <span>物品数量：</span>
                                    <el-input v-model="express.Quantity" placeholder="物品数量" require ></el-input>
                                </el-form-item>
                            </fieldset>
                        </el-form>
                    </div>
                    <!--发件人信息-->
                    <div class="expressBox">
                        <el-form ref="senderMsg" :model="express">
                            <fieldset class="padding15 border_eee">
                                <legend class="color_cdcdcd">发件信息</legend>
                                <el-form-item :rules="[
                              { required: true, message: '发件人不能为空'}
                            ]" prop="Sender.Name">
                                    <span>发件人：</span>
                                    <el-input v-model="express.Sender.Name" placeholder="请选择物流公司" ></el-input>
                                </el-form-item>
                                <el-form-item :rules="[
                              { required: true, message: '发件人手机号不能为空！'}
                            ]" prop="Sender.Mobile">
                                    <span>发件人联系方式：</span>
                                    <el-input v-model="express.Sender.Mobile" placeholder="手机号码" ></el-input>
                                    <el-input v-model="express.Sender.Tel" placeholder="固话" require ></el-input>
                                </el-form-item>
                                <el-form-item :rules="[
                              { required: true, message: '发件省市地址不能为空，省市区间用空格分开'}
                            ]" prop="Sender.FullRegion">
                                    <span>发件地址：</span>
                                    <el-input v-model="express.Sender.FullRegion" placeholder="请选择发件地址" ></el-input>
                                </el-form-item>
                                <el-form-item :rules="[
                              { required: true, message: '发件人详细地址不能为空'}
                            ]" prop="Sender.Address">
                                    <span>发件地址：</span>
                                    <el-input v-model="express.Sender.Address" placeholder="详细地址" ></el-input>
                                </el-form-item>
                            </fieldset>
                        </el-form>
                    </div>
                    <!--收件人信息-->
                    <div class="expressBox">
                        <el-form ref="receiverMsg"  :model="express">
                            <fieldset class="padding15 border_eee">
                                <legend class="color_cdcdcd">收件信息</legend>
                                <el-form-item prop="Receiver.Name" :rules="[
                              { required: true, message: '收件人姓名不能为空！'}
                            ]">
                                    <span>收件人：</span>
                                    <el-input v-model="express.Receiver.Name" placeholder="请填写收件人姓名"></el-input>
                                </el-form-item>
                                <el-form-item prop="Receiver.Mobile"  :rules="[
                              { required: true, message: '收件人手机号不能为空'}
                            ]">
                                    <span>收件人联系方式：</span>
                                    <el-input v-model="express.Receiver.Mobile" placeholder="收件人手机号码" ></el-input>
                                    <el-input v-model="express.Receiver.Tel" placeholder="收件人固话" require ></el-input>
                                </el-form-item>
                                <el-form-item  prop="Receiver.FullRegion" :rules="[
                              { required: true, message: '收件人省市区不能为空，省市区间用空格分开'}
                            ]">
                                    <span>收件地址：</span>
                                    <el-input v-model="express.Receiver.FullRegion" placeholder="请选择发件地址"></el-input>
                                </el-form-item>
                                <el-form-item  prop="Receiver.Address" :rules="[
                              { required: true, message: '收件人详细地址不能为空！'}
                            ]">
                                    <span>收件地址：</span>
                                    <el-input v-model="express.Receiver.Address" placeholder="详细地址" ></el-input>
                                </el-form-item>
                            </fieldset>
                        </el-form>
                    </div>
                    <!--其他物流信息-->
                    <div class="expressBox">
                        <el-form ref="otherExpress" :model="express">
                            <fieldset class="padding15 border_eee">
                                <legend class="color_cdcdcd">其他物流信息</legend>
                                <el-form-item prop="Commodity.GoodsName" :rules="[
                              { required: true, message: '快递物件名不能为空！快递要求必须填写物件名！'}
                            ]">
                                    <span>快递物件名：</span>
                                    <el-input v-model="express.Commodity.GoodsName" placeholder="请填写快递物件名"  ></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <span>发送信息：</span>
                                    <el-select v-model="express.IsSendMessage" placeholder="是否发送信息">
                                        <el-option label="是" :value="1">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <span>接受通知：</span>
                                    <el-select v-model="express.IsNotice" placeholder="是否接受通知">
                                        <el-option label="是" :value="1">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item>
                                    <span>备注：</span>
                                    <textarea class="remarks" name="备注" id="" cols="30" rows="10" :value="express.Remark" @input="RemarkChange"></textarea>
                                </el-form-item>
                            </fieldset>
                        </el-form>
                    </div>
                </div>
                <div slot="footer" class="dialog-footer padding15" style="text-align: right;">
                    <el-checkbox style="margin-left: 20px;" v-model="is_auto_send_sms_notice">自动发送短信通知</el-checkbox>
                    <el-button type="primary" @click="sendExpress">发  货</el-button>
                    <el-button type="default" @click="closeBox">关  闭</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../common/bus';
    import expressCompanyCode from '../../utils/expressCompanyCode'
    export default {
        name: "addExpress",
        data: function () {
            return {
                is_auto_send_sms_notice: true, // 是否自动发送发货短信
                recogniseLogisticCode: '', // 被识别的单号
                recogniseExpressList: [], // 识别结果
                expressData_com: {},
                isShowDialog: false,
                PayTypeOptions: [ // 付款方式
                    {label: '现付', value: 1},
                    {label: '到付', value: 2},
                    {label: '月结', value: 3},
                    {label: '第三方', value: 4}
                ],
                ExpTypeOptions: [
                    // 快递类型
                    {label: '标准快件', value: 1}
                ],
                express: {
                    LogisticCode: '', // 运单号
                    ShipperCode: '', //物流公司代码
                    ShipperName: '', // 物流公司名称
                    OrderId: '',
                    IsNotice: 1, //默认不通知
                    IsSendMessage: 1, // 默认发送通知
                    PayType: 1, // 默认发件类型为现付
                    ExpType: 1, // 默认快递类型为标准快递
                    Quantity: '', // 数量
                    Remark: '', // 备注
                    Sender: {
                        Name: '李美丽',
                        Tel: '075532867416',
                        Mobile: '075532867416',
                        Address: '三合华侨新村三区6栋',
                        ProvinceName: '广东省',
                        CityName: '深圳市',
                        ExpAreaName: '龙华区',
                        FullRegion: '广东省 深圳市 龙华区'
                    },
                    Receiver: {
                        Name: '',
                        Tel: '',
                        Mobile: '',
                        Address: '',
                        ProvinceName: '',
                        CityName: '',
                        ExpAreaName: '',
                        FullRegion: ''
                    },
                    Commodity: {
                        GoodsName: ''
                    }
                    // 快递信息
                }
            }
        },
        props: {
            expressData: {
              type: Object,
              default: {
                  Commodity: '',
                  Receiver: {},
                  Sender: {}
              }
            },
            value: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            recogniseLogisticCode (newv) {
                this.express.LogisticCode = newv;
                this.$http.post(this.$api + '/admin/express/recogniseExpressNo', {
                    LogisticCode: newv
                })
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.recogniseExpressList = res.data.data;
                            this.express.ShipperCode = this.recogniseExpressList[0].ShipperCode
                            if (this.recogniseExpressList[0].NOT_MATCH) {
                                this.$message.error('单号匹配失败！请手动选择单号！')
                            }
                        } else {
                            this.$message.error(res.data.errmsg)
                        }
                    })
            },
            expressData (newv) {
                let that = this;
                console.log(newv)
                that.showAddExpress = true;
                that.expressData_com = newv;
                that.express.OrderId = that.expressData_com.id;
                that.express.Receiver.Name = that.expressData_com.consignee;
                that.express.Receiver.Tel = that.expressData_com.tel;
                that.express.Receiver.Mobile = that.expressData_com.mobile;
                that.express.Quantity = that.expressData_com.buy_number;
                that.express.Remark = that.expressData_com.remarks;
                that.express.Commodity.GoodsName = that.expressData_com.goodList&&that.expressData_com.goodList.length > 0 && that.expressData_com.goodList.constructor === Array? `${that.expressData_com.goodList[0].goods_name}\n等${that.expressData_com.goodList[0].length}件不同的商品】`:'';
                that.express.Receiver.ProvinceName = that.expressData_com.ProvinceName;
                that.express.Receiver.CityName = that.expressData_com.CityName;
                that.express.Receiver.ExpAreaName = that.expressData_com.DistrictName;
                that.express.Receiver.Address = that.expressData_com.address;
                that.express.Receiver.FullRegion = that.expressData_com.ProvinceName + ' ' + that.expressData_com.CityName + ' ' + that.expressData_com.DistrictName;
            },
            value (newv) {
                this.isShowDialog = newv;
                // this.$emit('input', !newv);
            },
            express: {
                handler (newv, oldv) {
                    let that = this;
                    that.recogniseExpressList.map(function (item) {
                        if (item.ShipperCode === newv.ShipperCode) {
                            that.express.ShipperName = item.ShipperName
                        }
                    })
                },
                deep: true
            }
        },
        mounted () {
            this.recogniseExpressList = expressCompanyCode.default
        },
        methods: {
            closeBox () {
                this.$emit('input', false);
            },
            // 发货操作
            sendExpress () {
                let that = this;
                this.$refs['express'].validate((valid) => {
                    if (!valid) {
                        that.$message.error('请完善物流必填信息！！');
                        return false;
                    } else {
                        that.$refs['senderMsg'].validate((valid) => {
                            if (!valid) {
                                that.$message.error('请完善发件人必填信息！！');
                                return false;
                            } else {
                                that.$refs['receiverMsg'].validate((valid) => {
                                    if (!valid) {
                                        that.$message.error('请完善收件人必填信息！！');
                                        return false;
                                    } else {
                                        that.$refs['otherExpress'].validate((valid) => {
                                            if (!valid) {
                                                that.$message.error('请完善其他物流必填信息！！');
                                                return false;
                                            } else {
                                                // 提交数据
                                                that.express.Receiver_str = JSON.stringify(that.express.Receiver);
                                                that.express.Sender_str = JSON.stringify(that.express.Sender);
                                                that.express.Commodity_str = JSON.stringify(that.express.Commodity);
                                                that.$http.post(that.$api + '/admin/order/saveExpress', that.express)
                                                    .then( res => {
                                                        if (res.data.errno === 0) {
                                                            that.$message.success('发货成功！');
                                                            that.isShowDialog = !that.isShowDialog;
                                                            if (that.is_auto_send_sms_notice) {
                                                                that.sendOutExpressNotice({
                                                                    mobile: that.express.Receiver.Mobile,
                                                                    ShipperName: that.express.ShipperName? `(${that.express.ShipperName})`:'',
                                                                    consignee: that.express.Receiver.Name ,
                                                                    order_sn: '***（前往APP查看）',
                                                                    LogisticCode: that.express.LogisticCode || '***（前往APP查看）'
                                                                });
                                                            }
                                                            that.$refs['receiverMsg'].resetFields();
                                                            that.$refs['otherExpress'].resetFields();
                                                            that.$refs['senderMsg'].resetFields();
                                                            that.$refs['express'].resetFields();
                                                            bus.$emit('closeAddExpressDialog')
                                                        }
                                                    })
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                })
            },
            // 备注

            // 发送发货通知
            sendOutExpressNotice (order) {
                if (this.is_auto_send_sms_notice) {
                    this.$notify({
                        title: '提示',
                        message: '启用自动发送短信通知。。。',
                        duration: 1500
                    });
                }

                if (!order.LogisticCode) {
                    this.$message.error('该订单不存在运单号码！请发货后重试！');
                    return
                }

                if (!order.mobile) {
                    this.$message.error('收件人号码有误！');
                    return
                }

                this.$http({
                    url: this.$api + '/admin/sms/sendOutExpressNotice',
                    method: 'post',
                    data: {
                        PhoneNumbers: order.mobile,
                        TemplateParam: {
                            nickname: (order.consignee || order.NICKNAME) + order.ShipperName? `(${order.ShipperName})`:'',
                            orderSn: '***' + order.order_sn.slice(order.order_sn.length -4,order.order_sn),
                            logisticCode: order.LogisticCode
                        }
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.$notify({
                            title: '提示',
                            message: '自动发送短信通知成功。。。',
                            duration: 1500
                        });
                        if (response.data.errno == 0) {
                            this.$message.success('发送短信成功！');
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    }
                })
            },
            RemarkChange (e) {
                this.express.Remark = e.target.value
            },
        }
    }
</script>

<style scoped>
    /*.dialogBox {*/
        /*z-index: 9999;*/
        /*width: 90%;*/
        /*padding: 15px;*/
        /*box-sizing: border-box;*/
        /*-webkit-border-radius: 8px;*/
        /*-moz-border-radius: 8px;*/
        /*border-radius: 8px;*/
        /*position: absolute;*/
        /*top: 50%;*/
        /*left: 50%;*/
        /*transform: translateX(-50%) translateY(-50%);*/
        /*background-color: #ffffff;*/
        /*transition: all .8s ease 0s;*/
    /*}*/
    /*.maskLayer {*/
        /*z-index: 9998;*/
        /*background-color: #000000;*/
        /*opacity: 0.4;*/
        /*position: absolute;*/
        /*top: 50%;*/
        /*left: 50%;*/
        /*width: 100%;*/
        /*height: 2000px;*/
        /*transform: translateX(-50%) translateY(-50%);*/
    /*}*/
</style>
