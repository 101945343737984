<template>
    <div>
        <div class="crumbs">
            <label>
                <span>专栏: {{ this.columnName}}</span>（共{{ total }}篇）
                <i title="修改" class="el-icon-edit" style="color:#999;cursor:pointer;margin-right:10px"
                   @click="changeColumn">修改</i>
                <span
                    style="font-size:16px;line-height: 45px; height:45px;margin-right:10px">作者: {{this.authorName}}</span>
                <span style="font-size:16px; line-height: 45px;">专栏封面:</span>
                <img :src="this.columnPicture" style="height:45px;">
            </label>
            <el-dialog title="修改专栏信息" :visible.sync="dialogFormVisible">
                <div>
                    <span>请输入修改的专栏名称:</span>
                    <el-input style="width:45%" v-model="columnName"></el-input>
                </div>
                <div style="margin-top:30px;height:80px;width:400px;">
                    <span style="display:block;margin-top:27px;float:left;width:123px">请上传修改的封面:</span>
                    <el-upload class="avatar-uploader" :action="$api + UPLOAD_HEADER_IMG" :headers="{ Auth_token }"
                               :show-file-list="false" :on-success="handleAvatarSuccess"
                               :before-upload="beforeAvatarUpload">
                        <img v-if="this.columnPicture" :src="this.columnPicture" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </div>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="changeName">确 定</el-button>
                </div>
            </el-dialog>
            <el-button type="primary" style="margin-right:-10%;height:34px;" @click="editTagType_btn">添加更多</el-button>
            <el-input style="width:260px;" placeholder="请输入标题查询" v-model="searchState"
                      @keyup.enter.native="handleSearchByTitle">
                <i class="el-icon-search el-input__icon pointer " style="height:34px;" slot="suffix"
                   @click="handleSearchByTitle"></i>
            </el-input>
            <el-dialog title="继续添加内容" :visible.sync="addPaperType_bool" width="70%">
                <el-input style="width:260px;margin-left:60%;" placeholder="请输入标题或标签查询" v-model="searchStates"
                          @keyup.enter.native="handleSearchByTitles">
                    <i class="el-icon-search el-input__icon pointer " slot="suffix" @click="handleSearchByTitles"></i>
                </el-input>
                <div class="tagSwitch disflexct">
                    <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 100%">
                        <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                                     :name="(item.id).toString()"></el-tab-pane>
                    </el-tabs>
                </div>
                <el-table :data="tableDatas" ref="multipleTable" @selection-change="handleSelection"
                           style="width: 100%">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="标题">
                        <template slot-scope="scope">
                            <div slot="reference" class="name-wrapper">
                                <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                                <span style="margin-left: 10px">{{ scope.row.title }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="标签" width="120">
                        <template slot-scope="scope">
                            <div slot="reference" class="name-wrapper">
                                <el-tag v-if="scope.row.tag!=null" size="medium">{{ scope.row.tag }}</el-tag>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="评论数" width="80" prop="commentNum"></el-table-column>
                    <el-table-column label="点赞数" width="80" prop="praiseNum"></el-table-column>
                    <el-table-column label="收藏数" width="80" prop="praiseNum"></el-table-column>
                    <el-table-column label="分享" width="80" prop="forwardNum"></el-table-column>
                    <el-table-column label="发布时间" width="150">
                        <template slot-scope="scope">
                            <i class="el-icon-time"></i>
                            <span style="margin-left: 10px">{{ formatStrTime(scope.row.releaseTime, 16) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="paginaion" style="display: inline-block; margin-left:20%;margin-top: 2em">
                    <el-pagination @size-change="handleSizeChanges" @current-change="handleCurrentChanges"
                                   :current-page="currentPages" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize"
                                   layout="total, sizes, prev, pager, next, jumper" :total="totals">
                    </el-pagination>
                </div>
                <el-button style="margin-left:30%;margin-top: 2em" @click="clearTable">取消</el-button>
                <el-button type="primary" style="margin-top: 2em" @click="addContent">添加</el-button>
            </el-dialog>
        </div>
        <div class="tagSwitch disflexct">
            <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 100%">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="(item.id).toString()"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table :data="tableData" @selection-change="handleSelectionChange"  style="width: 100%">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="标题">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.title }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="标签" width="150">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <el-tag v-if="scope.row.tag!=null" size="medium">{{ scope.row.tag }}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="评论数" width="80" prop="commentNum"></el-table-column>
            <el-table-column label="点赞数" width="80" prop="praiseNum"></el-table-column>
            <el-table-column label="收藏数" width="80" prop="praiseNum"></el-table-column>
            <el-table-column label="分享" width="80" prop="forwardNum"></el-table-column>
            <el-table-column label="发布时间" width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ formatStrTime(scope.row.releaseTime, 16) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px;
     align-items: flex-end;">
                        <!-- 添加标签 -->
                        <i title="添加标签" class="el-icon-czd-biaoqian1 ft25 pointer" @click="addtagFun(scope.row)"></i>
                        <!-- 编辑 -->
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                           @click="editPaper(scope.$index, scope.row)"></i>
                        <!-- 评论 -->
                        <!-- 无评论 -->
                        <div class="pointer" v-if="scope.row.commentNum <= 0" @click="$message.info('暂无评论')"
                             style="height: 24px">
                            <img src="https://file.chezidao.com/root/static/icon/comments.png" style="width: 20px">
                        </div>
                        <!-- 有评论 -->
                        <div v-if="scope.row.commentNum > 0" @click="loadCommonList(scope.row)" style="height: 24px">
                            <el-badge :value="scope.row.commentNum" :max="99" class="item pointer">
                                <img src="https://file.chezidao.com/root/static/icon/comments.png" style="width: 20px">
                            </el-badge>
                        </div>

                        <!--<el-dropdown @command="handleCommand">>-->
                            <!--<i class="el-icon-more ft25"></i>-->
                            <!--<el-dropdown-menu slot="dropdown">-->
                                <!--<el-dropdown-item @command="downLinePaper(scope.row)">下线</el-dropdown-item>-->
                                <!--<el-dropdown-item @command="removeColumn(scope.row)">移出专栏</el-dropdown-item>-->
                            <!--</el-dropdown-menu>-->
                        <!--</el-dropdown>-->
                        <i title="下线" class="el-icon-remove-outline ft25 pointer" @click="downLinePaper(scope.row)"></i>
                        <!-- 移除专栏 -->
                        <i title="移出专栏" class="el-icon-minus ft25 pointer" @click="removeColumn(scope.row)"></i>
                        <!-- 删除 -->
                        <!-- <i title="删除" class="el-icon-delete ft25 red pointer" @click="deletePaper(scope.$index, scope.row)"></i> -->
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 批量标签弹出框 -->
        <el-dialog title="批量添加标签" :visible.sync="addtags" width="30%">
            <el-checkbox-group v-model="checkboxGroup">
                <el-checkbox-button v-for="(items, index) in allTaglist"
                                    style="border-radius: 5px; border: 1px solid #dcdfe6; margin-right:25px;margin-top:10px;"
                                    :key="items.id" :label="items">{{items.name}}
                </el-checkbox-button>
            </el-checkbox-group>
            <el-button style="color: red;border-color:red; margin-left:8px;margin-top:20px;" @click="clearButton">清空
            </el-button>
            <el-button style="margin-left:180px;margin-top:20px;" type="success" @click="batchAdd">保存</el-button>
        </el-dialog>
        <!-- 单击标签弹出框 -->
        <el-dialog title="添加标签" :visible.sync="addtag" width="30%">
            <div prop="type" style=" margin-bottom: 30px;">
                <el-button name="type" plain size="medium"
                           :style="item.hasTag==0 ? '':' background-color:#409eff;color:#fff;'" @click="changeTag(item)"
                           style="margin:5px; width:120px; text-align: center;" v-for="(item, index) in tagData"
                           :key="item.id">{{ item.name }}
                </el-button>
            </div>
            <el-button style="color: red;border-color:red; margin-left:8px;" @click="resetForm">清空</el-button>
            <el-button style="margin-left:180px" type="success" @click="submitTag">保存</el-button>
        </el-dialog>
        <el-dialog title="批量下线内容" :visible.sync="uppapers" width="50%">
            <el-form ref="form" :model="uppapersList">
                <span>请选择理由(可多选)</span>
                <el-form-item prop="type">
                    <el-checkbox-group v-model="uppapersList.type" v-for="(item, index) in reasonData" :key="item.id">
                        <el-checkbox style="width:45%; margin-left: 30px;" :label="item.name" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="其他" prop="desc">
                    <el-input type="textarea" size="medium" v-model="uppapersList.desc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="float:right" @click="batchOffline">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="下线内容" :visible.sync="uppaper" width="50%">
            <el-form ref="form" :model="offLineData.form">
                <span>请选择理由(可多选)</span>
                <el-checkbox-group v-model="offLineData.form.type" v-for="(item, index) in reasonData" :key="item.id">
                    <el-checkbox style="width:45%; margin-left: 30px;" :label="item.name" name="type"></el-checkbox>
                </el-checkbox-group>
                <el-form-item label="其他">
                    <el-input type="textarea" size="medium" v-model="offLineData.form.desc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="float:right" @click="onSubmit">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-button type="primary" style="margin-left:5%;margin-top: 2em" @click="outtags">批量添加标签</el-button>
        <el-button type="info" @click="outReason">批量下线</el-button>
        <el-button type="info" @click="removeColumns">批量移除</el-button>
        <!-- <el-button type="danger" @click="batchRemove">批量删除</el-button> -->
        <div class="paginaion" style="display: inline-block; margin-left:20%;margin-top: 2em">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>
    </div>
</template>

<script>
    import bus from "../../common/bus";
    import commonlist from "../../common/userCommon";
    import ElButton from "../../../../node_modules/element-ui/packages/button/src/button.vue";

    export default {
        data: function () {
            return {
                authorName: "",
                columnName: "",
                columnPicture: "",
                checkboxGroup: [],
                multipleSelection: [],
                multiplesSelection: [],
                activeName: "全部",
                addPaperType_bool: false,
                // loading: true,
                tableData: [],
                tableDatas: [],
                tagSwitchData: [],
                tagData: [],
                contentType: "",
                contentTypes: [],
                currentPage: 1,
                currentPages: 1,
                total: 0,
                totals: 0,
                sizes: 0,
                size: 0,
                activeName: "1",
                currentTagId: "",
                searchState: "",
                searchStates: "",
                paperStatus: "未上线",
                paperId: "",
                tagId: " ",
                showCommon: false,
                commonData: "",
                addPaperData: {
                    name: ""
                },
                allTaglist: [],
                addtagname: {
                    name: ""
                },
                dialogFormVisible: false,
                addtag: false,
                addtags: false,
                editPaperData: {
                    name: ""
                },
                reasonData: [],
                paperTypeData: [],
                editPaperStr: "新  增",
                oprationType: "add",
                tableHeight: 600,
                is_edit: false,
                uppaper: false,
                uppapers: false,
                uppapersList: {
                    desc: "",
                    type: []
                },
                offTagList: {
                    ids: ""
                },
                offLineData: {
                    id: "",
                    status: "",
                    form: {
                        desc: "",
                        type: []
                    }
                }
            };
        },
        components: {
            ElButton,
            commonlist
        },
        watch: {},
        beforeCreate() {
            this.$http({
                url: this.$api + "/api/column/info/info",
                method: "post",
                data: this.$qs.stringify({
                    columnId: this.$route.query.id
                })
            })
                .then(res => {
                    // //console.log(res);
                    if (res.data.errno == 0) {
                        this.columnPicture = res.data.data.picture;
                        this.columnName = res.data.data.name;
                        this.authorName = res.data.data.authorName;
                    }
                })
                .catch(res => {
                    //console.warn(res);
                });
        },
        created() {
            // this.reasonData = [
            //   { id: 1, name: "标题、图片或内容低俗,具有性暗示" },
            //   { id: 2, name: "色情低俗" },
            //   { id: 3, name: "垃圾广告" },
            //   { id: 4, name: "内容或图片暴力、恐怖、血腥" },
            //   { id: 5, name: "内容不相关" },
            //   { id: 6, name: "人身攻击" },
            //   { id: 7, name: "含政治敏感信息" },
            //   { id: 8, name: "赌博诈骗" },
            //   { id: 9, name: "发布违法违禁信息" }
            // ];
            this.tagSwitchData = [{id: 1, name: "文章"}, {id: 2, name: "视频"}];
            // 从兄弟组件传过来的巴士消息
            bus.$on("userColumnChange", msg => {
                this.paperId = msg;
            });
            this.Auth_token = localStorage.getItem("Auth_token");
            this.getPaperList(0, 1, 10);

            // this.loadPaperType();
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653;
            this.contentTypes = this.$getDicByName("CONTENT_TYPE");
            this.reasonData = this.$getDicByName("OFFLINE_REASON_TYPE");
        },
        mounted() {
            bus.$on("reloadColumn", res => {
                if (res) {
                    this.getPaperList(this.currentTagId, this.currentPage, this.size);
                    this.getAddList(this.currentTagId, this.currentPages, this.sizes);
                }
            });
        },
        methods: {
            //   上传封面
            handleAvatarSuccess(res) {
                if (res.ret === 0) {
                    this.columnPicture = res.data;
                }
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isLt2M;
            },
            // 弹出修改框
            changeColumn() {
                this.dialogFormVisible = true;
            },
            // 添加文章视频
            addContent() {
                let videoId = [];
                for (let index in this.multiplesSelection) {
                    videoId.push(this.multiplesSelection[index].id);
                }
                let videoIds = videoId.join(",");
                let url = "";
                let data = "";
                this.activeName == "1"
                    ? (url = this.$api + "/api/column/article/add")
                    : (url = this.$api + "/api/column/video/add");
                this.activeName == "1"
                    ? (data = this.$qs.stringify({articleIds: videoIds, columnId: this.$route.query.id}))
                    : (data = this.$qs.stringify({videoIds: videoIds, columnId: this.$route.query.id}));
                this.$http({
                    url: url,
                    method: "post",
                    data: data,
                })
                    .then(res => {
                        // //console.log(res);
                        if (res.data.errno == 0) {
                            this.addPaperType_bool = false;
                            this.getPaperList();
                            this.clearTable();
                            this.$message.success(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            changeName() {
                this.$http({
                    url: this.$api + "/api/column/info/update",
                    method: "post",
                    data: this.$qs.stringify({
                        id: this.$route.query.id,
                        name: this.columnName,
                        picture: this.columnPicture
                    })
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.dialogFormVisible = false;
                            this.getPaperList();
                            this.$message.success(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 清空表格
            clearTable() {
                this.$refs.multipleTable.clearSelection();
            },
            // 弹出原因框
            outReason() {
                this.uppapers = true;
            },
            // 批量下线
            batchOffline() {
                let pagerId = [];
                for (let index in this.multipleSelection) {
                    pagerId.push(this.multipleSelection[index].id);
                }
                let pagerIds = pagerId.join(",");
                let url = "";
                this.activeName == "1"
                    ? (url = this.$api + "/chigua/admin/article/downLine/batch")
                    : (url = this.$api + "/chigua/admin/video/downLine/batch");
                if (this.uppapersList.type.length == 0) {
                    this.$message.error("请选择要下线的原因");
                } else {
                    this.$http({
                        url: url,
                        method: "post",
                        data: this.$qs.stringify({
                            ids: pagerIds,
                            offlineReason: this.uppapersList.desc + "," + this.uppapersList.type
                        })
                    }).then(response => {
                        if (response.data.msg == "ids-不能为空") {
                            this.$message.error("请选择要下线的文章");
                        } else {
                            this.$message.success(response.data.msg);
                        }
                        this.getPaperList(this.currentTagId, this.currentPage, this.size);
                        // //console.log(this.$refs["form"]);
                        this.uppapers = false;
                        this.$refs["form"].resetFields();
                    });
                }
            },
            // 弹出全部标签
            outtags() {
                this.allTag();
                this.addtags = true;
            },
            clearButton() {
                this.checkboxGroup = [];
            },
            // 批量添加标签
            batchAdd() {
                let pagerId = [];
                for (let index in this.multipleSelection) {
                    pagerId.push(this.multipleSelection[index].id);
                }
                let pagerIds = pagerId.join(",");
                let tag = [];
                for (let index in this.checkboxGroup) {
                    tag.push(this.checkboxGroup[index].id);
                }
                let tags = tag.join(",");
                let url = "";
                let data = "";
                this.activeName == "1"
                    ? (url = this.$api + "/api/tags/article/add/batch")
                    : (url = this.$api + "/api/tags/video/add/batch");
                this.activeName == "1"
                    ? (data = this.$qs.stringify({articleIds: pagerIds, tags: tags}))
                    : (data = this.$qs.stringify({videoIds: pagerIds, tags: tags}));
                this.$http({
                    url: url,
                    method: "post",
                    data: data
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.addtags = false;
                            this.getPaperList();
                            this.checkboxGroup = [];
                            this.$message.success(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 监听批量操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            // 监听批量操作
            handleSelection(val) {
                this.multiplesSelection = val;
            },
            // 查询所有的标签
            allTag() {
                this.$http({
                    url: this.$api + "/api/tags/info/list/tip",
                    method: "post"
                })
                    .then(res => {
                        this.allTaglist = res.data.data;
                        // //console.log(this.allTaglist);
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 保存按钮
            submitTag() {
                let tagList = [];
                for (let param in this.tagData) {
                    if (this.tagData[param].hasTag == 1) {
                        tagList.push(this.tagData[param].id);
                    }
                }
                let tags = tagList.join(",");
                let url = "";
                let data = "";
                this.activeName == "1"
                    ? (url = this.$api + "/api/tags/article/add")
                    : (url = this.$api + "/api/tags/video/add");
                this.activeName == "1"
                    ? (data = this.$qs.stringify({
                        articleId: this.offTagList.ids,
                        tags: tags
                    }))
                    : (data = this.$qs.stringify({
                        videoId: this.offTagList.ids,
                        tags: tags
                    }));
                this.$http({
                    url: url,
                    method: "post",
                    data: data
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.addtag = false;
                            this.getPaperList();
                            this.$message.success(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 切换tag状态
            // @item 对象数据
            changeTag(item) {
                for (let param in this.tagData) {
                    if (item.id == this.tagData[param].id) {
                        this.tagData[param].hasTag == 0
                            ? (this.tagData[param].hasTag = 1)
                            : (this.tagData[param].hasTag = 0);
                        break;
                    }
                }
            },
            // 批量切换标签弹窗状态
            changeTags(items) {
                for (let param in this.allTaglist) {
                    if (items.id == this.allTaglist[param].id) {
                        this.allTaglist[param].checked = !this.allTaglist[param].checked;
                        break;
                    }
                }
            },
            // 重置标签
            resetForm() {
                for (let param in this.tagData) {
                    if (this.tagData[param].hasTag == 1) {
                        this.tagData[param].hasTag = 0;
                    }
                }
            },
            // 编辑标签
            // @item: 单条数据
            addtagFun(row) {
                let url = "";
                this.addtag = true;
                this.offTagList.ids = row.id;
                this.activeName == "1"
                    ? (url = this.$api + "/api/tags/article/list/tags")
                    : (url = this.$api + "/api/tags/video/list/tags");
                this.$http({
                    url: url,
                    method: "post",
                    data: this.$qs.stringify({
                        id: row.id
                    })
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.tagData = res.data.data;
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 下线操作\
            downLinePaper(row) {
                this.uppaper = true;
                this.offLineData.id = row.id;
//                this.offLineData.status = row.status;
            },
            onSubmit() {
                let tips = "下线成功";
                let url = "";
                this.activeName == "1"
                    ? (url = this.$api + "/chigua/admin/article/downLine")
                    : (url = this.$api + "/chigua/admin/video/downLine");
                if (this.offLineData.form.type.length == 0) {
                    this.$message.error("请选择下线理由");
                } else {
                    this.$http({
                        url: url,
                        method: "post",
                        data: this.$qs.stringify({
                            id: this.offLineData.id,
                            offlineReason: this.offLineData.form.type + "," + this.offLineData.form.desc
                        })
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success(tips);
                            this.getPaperList(this.currentTagId, this.currentPage, this.size);
                            this.uppaper = false;
                            this.$refs["form"].resetFields();
                        }
                    });
                }
            },
            // 添加更多
            editTagType_btn() {
                this.getAddList(0, 1, 10);
                this.addPaperType_bool = true;
            },
            editTagType_btn_inside(item) {
                for (var i in item) {
                    this.editPaperData[i] = item[i];
                }
                this.is_edit = true;
                this.editPaperStr = "保存编辑";
                this.oprationType = "edit";
            },
            // 加载评论列表
            loadCommonList(row) {
                let url = "";
                this.activeName == "1"
                    ? (url = this.$api + "/chigua/admin/article/comment/page")
                    : (url = this.$api + "/chigua/admin/video/comment/page");
                this.$http({
                    url: url,
                    method: "post",
                    data: this.$qs.stringify({
                        articleId: row.id,
                        page: 1,
                        limit: 10,
                        sort: 0
                    })
                })
                    .then(response => {
                        this.commonData = response.data.data;
                        this.commonData.showCommon = true;
                        this.commonData.rows = row;
                        this.showCommon = true;
                        this.commonData.showCommonType = "paper";
                    })
                    .catch(response => {
                        // //console.log(response);
                    });
            },
            // 跳转到编辑页面，巴士负责传递id
            editPaper(index, row) {
                var loading = this.$loading({fullscreen: true});
                this.paperId = row.id;
                // //console.log(row.id);
                if (this.activeName == 1) {
                    bus.$emit("paperChange", this.paperId);
                    //  把文章类型带上
                    this.$router.push(
                        "/paperedit?paperid=" + row.id + "&ct=" + row.contentType
                    );
                } else {
                    bus.$emit("videoChange", row.id);
                    this.$router.push("/videoedit?videoid=" + row.id);
                }
                loading.close();
            },
            // 移出内容
            // 修改
            removeColumn(row) {
                let url = "";
                let data = "";
                this.activeName == "1"
                    ? (url = this.$api + "/api/column/article/delete")
                    : (url = this.$api + "/api/column/video/delete");
                this.activeName == "1"
                    ? (data = this.$qs.stringify({
                        columnId: this.$route.query.id,
                        articleIds: row.id
                    }))
                    : (data = this.$qs.stringify({
                        columnId: this.$route.query.id,
                        videoIds: row.id
                    }));
                this.$http({
                    url: url,
                    method: "post",
                    data: data
                }).then(response => {
                    // //console.log(response);
                    if (response.data.errno === 0) {
                        this.$message.success(response.data.msg);
                        // 重新调用方法
                        this.getPaperList();
                    }
                });
            },
            // 批量移出
            removeColumns() {
                let url = "";
                let data = "";
                let pagerId = [];
                for (let index in this.multipleSelection) {
                    pagerId.push(this.multipleSelection[index].id);
                }
                let pagerIds = pagerId.join(",");
                this.activeName == "1"
                    ? (url = this.$api + "/api/column/article/delete")
                    : (url = this.$api + "/api/column/video/delete");
                this.activeName == "1"
                    ? (data = this.$qs.stringify({
                        columnId: this.$route.query.id,
                        articleIds: pagerIds
                    }))
                    : (data = this.$qs.stringify({
                        columnId: this.$route.query.id,
                        videoIds: pagerIds
                    }));
                this.$http({
                    url: url,
                    method: "post",
                    data: data
                }).then(res => {
                    if (res.data.errno === 0) {
                        this.$message.success(res.data.errmsg);
                        // 重新调用方法
                        this.getPaperList();
                    }
                });
            },
            //按标题搜索列表
            handleContentType() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size);
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size);
            },
            handleSearchByTitles() {
                this.getAddList(this.currentTagId, this.currentPage, this.size);
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.currentPage, val);
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size);
            },
            handleSizeChanges(val) {
                this.getAddList(this.currentTagId, this.currentPage, val);
            },
            handleCurrentChanges(val) {
                this.getAddList(this.currentTagId, val, this.sizes);
            },
            // 添加专栏的数据
            getAddList(type, pages, lengths) {
                const loading = this.$loading({
                    lock: true,
                    text: "已经很努力加载啦~",
                    spinner: "el-icon-loading",
                    background: "rgba(183, 183, 183, 0.57)"
                });
                if ((pages === undefined) | (pages === null)) {
                    pages = 1;
                } else if ((lengths === undefined) | (lengths === null)) {
                    lengths = this.size;
                }
                // //console.log(this.$route.query);

                this.$http({
                    url: this.$api + "/api/column/info/list/content",
                    method: "post",
                    data: this.$qs.stringify({
                        contentType: this.activeName,
                        query: this.searchStates,
                        page: pages,
                        limit: lengths,
                        columnId: this.$route.query.id
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        for (var item in response.data.data.records) {
                            if (response.data.data.records[item].status === 1) {
                                response.data.data.records[item].paperStatus = "可上线";
                                // response.data.data.records[item].color = '#e3e3e3'
                            } else if (response.data.data.records[item].status === 2) {
                                response.data.data.records[item].paperStatus = "已上线";
                                // response.data.data.records[item].color = '#49293'
                            } else if (response.data.data.records[item].status === 3) {
                                response.data.data.records[item].paperStatus = "已下线";
                                // response.data.data.records[item].color = '#ff0000'
                            } else {
                                response.data.data.records[item].paperStatus = "未知状态";
                            }
                        }
                        // 页面详细信息
                        this.tableDatas = response.data.data.records;
                        this.currentPages = response.data.data.current;
                        this.totals = response.data.data.total;
                        this.sizes = response.data.data.size;
                        // //console.log(response);
                    } else {
                        this.$message.error(response.data.msg);
                    }
                    loading.close();
                });
            },
            // 专栏详情数据
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: "已经很努力加载啦~",
                    spinner: "el-icon-loading",
                    background: "rgba(183, 183, 183, 0.57)"
                });
                if ((page === undefined) | (page === null)) {
                    page = 1;
                } else if ((length === undefined) | (length === null)) {
                    length = this.size;
                }
                // //console.log(this.$route.query);

                this.$http({
                    url: this.$api + "/api/column/info/list/details",
                    method: "post",
                    data: this.$qs.stringify({
                        contentType: this.activeName,
                        query: this.searchState,
                        page: page,
                        limit: length,
                        columnId: this.$route.query.id
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        for (var item in response.data.data.records) {
                            if (response.data.data.records[item].status === 1) {
                                response.data.data.records[item].paperStatus = "可上线";
                                // response.data.data.records[item].color = '#e3e3e3'
                            } else if (response.data.data.records[item].status === 2) {
                                response.data.data.records[item].paperStatus = "已上线";
                                // response.data.data.records[item].color = '#49293'
                            } else if (response.data.data.records[item].status === 3) {
                                response.data.data.records[item].paperStatus = "已下线";
                                // response.data.data.records[item].color = '#ff0000'
                            } else {
                                response.data.data.records[item].paperStatus = "未知状态";
                            }
                        }
                        // 页面详细信息
                        this.tableData = response.data.data.records;
                        this.currentPage = response.data.data.current;
                        this.total = response.data.data.total;
                        this.size = response.data.data.size;
                        // //console.log(this.activeName);
                    } else {
                        this.$message.error(response.data.msg);
                    }
                    loading.close();
                });
            },
            switchTag(tab) {
                // //console.log(tab);
                for (var i in this.tagSwitchData) {
                    if (tab.name == this.tagSwitchData[i].id) {
                        this.searchState = "";
                        this.getAddList(this.tagSwitchData[i].id, 1, 10);
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10);
                        this.currentTagId = this.tagSwitchData[i].id;
                    }
                }
            }
        }
    };
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .avatar-uploader {
        display: inline-block;
    }

    .avatar-uploader .el-upload {
        display: inline-block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 78px;
        height: 78px;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 78px;
        text-align: center;
    }

    .avatar {
        width: 78px;
        height: 78px;
        display: block;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }
</style>
