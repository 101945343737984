<template>
    <section class="main">
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span></label>
            <!--<el-breadcrumb separator="/">-->
            <!--<el-breadcrumb-item><i class="el-icon-rank"></i>广告管理</el-breadcrumb-item>-->
            <!--</el-breadcrumb>-->
        </div>

        <div class="container">
            <div class="drag-box">
                <div class="drag-box-item">
                    <div class="dragitem tabletd">
                        <div style="width: 15%">轮播图</div>
                        <div style="width: 10%">广告类型</div>
                        <div style="width: 35%">广告标题</div>
                        <div style="width: 20%">持续时间</div>
                        <div style="width: 20%">操作</div>
                    </div>
                    <draggable v-model="dragData.data" @remove="removeHandle" :options="dragOptions">
                        <transition-group tag="div" id="todo" class="item-ul">
                            <div v-for="(item,index2) in dragData.data" class="drag-list" :key="item.jump_id">
                                <!-- <img :src="item.picture">/ -->
                                <el-tooltip class="item" effect="dark" :content="'跳转 id：' + item.jump_id" placement="top">
                                    <div class="dragitem tabletd">
                                        <div class="dragimg tabletd" style="width: 15%">
                                            <img :src="item.activity_img" style="width:80px;height: 60px">
                                            <i style="color: #e1e4e8">{{item.sort}}</i>
                                            <!--<a :href="item.picture"> </a>-->
                                        </div>
                                        <div class="dragitemname tabletd" title="分类" style="width: 10%">
                                            <span >【{{item.media_type == 1? '商品':'其他'}}】</span>
                                        </div>
                                        <!--<div class="dragitemurl" title="链接地址"  style="width: 45%" @click="router.push(item.category === 1? '/paperedit?paperid='+item.jumpValue:'/videoedit?videoid='+item.jumpValue)">-->
                                        <!-- </div> -->
                                        <div style="display: block;max-width: 35%;min-width: 35%" title="广告标题">
                                            <!--<router-link :to="item.category === 1? '/paperedit?paperid='+item.jumpValue:'/videoedit?videoid='+item.jumpValue"></router-link>-->
                                            <el-button @click="jumpToDetail(item)" type="text">{{item.goods_name}}</el-button>
                                        </div>
                                        <div title="到期时间 " class="tabletd" style="width: 20%">
                                            <div>{{item.end_time}}</div>
                                            <!--<br/>-->
                                            <!--<div>{{formatStrTime(item.endTime, 16)}}</div>-->
                                        </div>
                                        <div title="操作" class='tabletd' style="width: 20%;font-size: 20px;">
                                            <!--<i class="el-icon-edit-outline ft25 pointer" title="编辑"-->
                                               <!--@click="editPaper(item,item.id)"></i>-->
                                            <!--<i @click="offlineREPO(item.id)" class="el-icon-remove-outline pointer"-->
                                               <!--title="下线广告"></i>-->
                                            <!--<i class="el-icon-delete ft25 red pointer" title="删除"-->
                                               <!--@click="deleteBana(item.id)"></i>-->
                                        </div>
                                    </div>
                                </el-tooltip>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            </div>
        </div>

        <el-button type="success" @click="changeBanaSort" style="margin-top: 20px;" round>更改轮播顺序</el-button>
        <el-button type="primary" round @click="addNewAds">新增活动商品</el-button>
        <!-- 新增广告组件 -->

        <addnianhuo :adsadd="adsaddStatus"></addnianhuo>
        <editnianhuo :adsEditData="adsEditData" @reloadAdsmanage="loadads" @reloadAdsDraft="reloadAdsDraft"></editnianhuo>
    </section>
</template>

<script>
    import draggable from 'vuedraggable'
    import addnianhuo from './addnianhuo'
    import editnianhuo from './editnianhuo'
    import bus from '../../common/bus'

    export default {
        data() {
            return {
                dragOptions: {
                    animation: 120,
                    scroll: true,
                    group: 'sortlist',
                    ghostClass: 'white'
                },
                dragData: [],
                advertType: [],
                adsaddStatus: false,
                adsEditData: {}
            }
        },
        watch: {
            // dragData: function () {
            //     debugger
            //     for (var item in this.dragData) {
            //         this.dragData[item].sort = item
            //     }
            // }
        },
        components: {
            draggable, adsadd, editAds
        },
        created() {
            this.loadads()
            // 尝试调用本地字典
            this.advertType = this.$getDicByName('ADVERT_TYPE')
        },
        mounted() {
            bus.$on('reloadAdsList', msg => {
                this.loadads()
            })
        },
        methods: {
            // 跳转到详情页
            jumpToDetail (item) {
                this.$router.push('goodsedit?id=' + item.jump_id);
            },
            reloadAdsDraft() {
                let reload = true
                bus.$emit('reloadAdsDraft', reload)
            },
            // 跳转到编辑页面，巴士负责传递id
            editPaper(row, id) {
                this.adsEditData = row;
                this.adsEditData.showForm = true;
                bus.$emit('showEditDialog')
            },
            offlineREPO(id) {
                this.$confirm(
                    '您正在执行下线轮播广告操作，下线后首页将不会显示该条轮播广告', '提示', {
                        confirmButtonText: '下线',
                        cancelButtonText: '取消'
                    }
                ).then(() => {
                    this.$http({
                        url: this.$api + '/admin/ads/switch',
                        method: 'post',
                        data: this.$qs.stringify({
                            adsId: id,
                            enabled: 0
                        })
                    }).then(res => {
                        if (res.data.errno === 0) {
                            // //console.log(res.data)
                            this.$message.success('下线成功！')
                            this.loadads()
                            let reload = true
                            bus.$emit('reloadAdsDraft', reload)
                        } else {
                            this.$message.error(res.data.errmsg)
                        }
                    }).catch(res => {
                        this.$message.error('下线失败')
                    })
                })
            },
            addNewAds() {
                this.adsEditData.showForm = true;
                bus.$emit('showAddDialog', this.adsEditData)
            },
            loadads() {
                this.$http({
                    url: this.$api + '/admin/goods/getActivityList',
                    method: 'post',
                }).then(res => {
                    if (res.data !== null) {
                        this.dragData = res.data.data
                    } else {
                        this.$message.error('列表加载失败')
                    }
                }).catch(res => {

                })
            },
            deleteBana(val) {
                this.$confirm(
                    '您正在执行删除轮播广告操作，删除后首页将不会显示该条轮播广告', '提示', {
                        confirmButtonText: '删除',
                        cancelButtonText: '取消'
                    }
                ).then(() => {
                    this.$http({
                        url: this.$api + '/admin/ads/delete',
                        method: 'post',
                        data: this.$qs.stringify({adsId: val})
                    }).then(res => {
                        this.$message.success('删除广告成功');
                        this.loadads()
                        //
                    }).catch(res => {
                        this.$message.info('操作取消！');
                    })
                })
            },
            changeBanaSort() {
                // uploadSort = uploadSort.join(',')
                this.$http({
                    url: this.$api + '/admin/ads/changeSort',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: this.dragData.data
                }).then(res => {
                    //
                    if (res.data.errno === 0) {
                        this.$message.success('修改轮播顺序成功！')
                        this.loadads()
                    }
                }).catch(res => {
                    this.$message.error('更新失败')
                })

            },
            removeHandle(event) {
                //
                // this.$message.success(`从 ${event.from.id} 移动到 ${event.to.id} `);
            }
        }
    }

</script>

<style scoped>

    .ghost-style {
        display: block;
        color: transparent;
        border-style: dashed
    }

    .drag-box-item {
        width: 100%;
        max-width: 100%;
    }

    .item-ul {
        height: 100%;
    }

    .dragitem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        padding: 5px;
        border-bottom: 0.5px solid #e1e4e8;
    }

    .tabletd {
        text-align: center;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
