<template>
    <!--v-for="question in newDataList"-->
    <div class="" >
        <h2 >{{question.title}}</h2>
        <div class="tag-box-tool" style="color: #8c939d;padding: 15px;box-sizing: border-box">
            <div style="display: flex">
                <div class="user-avator">
                    <img :src="question.avatar" />
                </div>
                <h3>{{question.nickname}}</h3>

            </div>
            <div style="text-align: right">
                <span>发布于{{formatStrTime(question.add_time,16)}}</span>

                <span v-if="question.address">
                    <i class="el-icon-location-outline"></i>
                    {{question.address}}
                </span>
            </div>
        </div>
        <div v-html="question.content"></div>
        <div class="imgHub">
            <img class="width25" :src="item" alt="" v-for="item in question.picture" @click="showImagePath = item">
            <video v-if="question.video" class="width50" :src="question.video" alt=""  controls></video>
        </div>
        <div class="">
            <div class="text-align-right">
                <span>{{question.praise_num}} 点赞</span>
                <el-tooltip class="item" effect="dark" content="回答列表" placement="top">
                    <span class="pointer color_09bbe7 text-decoration" @click="getAnswerList(question.id)">{{question.comment_num}}  评论</span>
                </el-tooltip>
                <el-dropdown  @command="handleCommand" v-if="!question.hideMore">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="delete">删除问题</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div class="text-align-center" v-if="question.judge_type === 0">
                <el-button type="primary" @click="judgeAccess">审核通过并通知用户</el-button>
                <el-button type="danger"@click="judgeBack">驳回并通知用户</el-button>
            </div>
            <div class="text-align-center" v-else>
                <el-button type="default"disabled="disabled">已审核（{{question_computed.judge_type_cn}}）</el-button>
            </div>
        </div>
        <preview-image :img-path="showImagePath"></preview-image>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import previewImage from '../../common/previewImage';

    export default {
        data() {
            return {
                showImagePath: '', // 预览图片的地址
                commentList: {}, // 评论列表
                selectList: [],
                idlist: '',
                userIdList: [],
                id: '',
                page: 1,
                row: {},
                dialogImageUrl: '',
                showImgDialog: false,
                collapse: false,
                showCommon: false,
                currentPage: 1,
                total: 0,
                size: 50,
                sort: 0,
                showCommonType: '',
                commonData: [],
            }
        },
        props: ['question'],
        computed: {
            question_computed () {
                let item = this.question;
                if (item.judge_type === 1) {
                    item.judge_type_cn = '审核通过'
                } else if (item.judge_type === 2) {
                    item.judge_type_cn = '驳回审核'
                } else {
                    item.judge_type_cn = '未审核'
                }
                return item
            }
        },
        components: {
            previewImage: previewImage
        },
        mounted() {
            let that = this;
            bus.$on('closeImageHub', function () {
                that.showImagePath = ''
            })
        },
        watch: {

        },
        methods: {
            // 通过审核
            judgeAccess () {
                this.$confirm('审核通过后，用户发布内容将直接在向公众开放？', '警示', {
                    confirmButtonText: '通过审核',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/community/judgeCommunity',
                        method: 'post',
                        data: {
                            question_id: this.question.id,
                            judgeType: 1
                        }
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('审核成功！');
                                bus.$emit('closeQuestionBox', 'closeQuestionBox');
                            } else {
                                this.$message.warning(response.data.msg);
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            // 驳回审核
            judgeBack () {
                this.$confirm('驳回后，将通知用户驳回原因，该条内容不会发布', '警示', {
                    confirmButtonText: '确认驳回',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/community/judgeCommunity',
                        method: 'post',
                        data: {
                            question_id: this.question.id,
                            judgeType: 2
                        }
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('驳回成功！');
                                bus.$emit('closeQuestionBox', 'closeQuestionBox');
                            } else {
                                this.$message.warning(response.data.msg);
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            showImageDeatil(pic){
              this.dialogImageUrl = pic;
              this.showImgDialog = true
            },
            handleCommand(command) {
                if (command ==='delete') {
                    this.deleteQuestion(this.question.id)
                }
            },
            /**
             * 跳转问题
             * @param id
             */
            getAnswerList(id){
                // bus.$emit('/answerList', id)
                this.$router.push('/answerlist?questionId='+id)
            },
            deleteQuestion(id){
                this.$confirm('您正在删除用户发布的内容，确认删除？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/community/deleteShare',
                        method: 'post',
                        data: {id: id}
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('删除成功！');
                                this.getQuestionList(this.tableData.currentPage, this.tableData.pageSize)
                            } else {
                                this.$message.warning(response.data.msg);
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            }
        }
    }
</script>
<style>
    .question-picture-dialog .el-dialog__body{
        padding: 2px;
        background-color: #00a0e9;
    }
    .question-picture-dialog .el-dialog__header{
        height: 0;
        padding: 0;
    }
</style>
<style scoped>
    .pointer{
        cursor: pointer;
    }
    .disflex {
        display: flex;
    }

    .tag-box > div {
        margin-top: 10px;
    }

    .core-data-box > div {
        padding: 0 10px 0 10px;
        margin-top: 10px;
    }

    .tag-box-tool {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .user-avator img {
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        vertical-align: middle;
    }

    .question-image-list{
        margin-top: 10px;
        margin-bottom: 10px
    }
    .question-image-list >img{
        margin-right:1em;
        height: 10em;
        vertical-align: middle;
        cursor: pointer;
    }
</style>
