<template>
    <div style="background-color: #ebe5a2 !important;padding: 15px">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>优惠券</el-breadcrumb-item>
                <el-breadcrumb-item>优惠券列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-button type="primary" @click="$router.push({path:'/addVipCoupon'})">新增会员优惠券</el-button>

        <div style="padding: 10px">
            <el-table
                :data="couponList"
                style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form label-position="left" inline class="demo-table-expand" style="padding: 10px 20px;background-color: #f5f5f5;">
                            <el-form-item label="优惠券ID：" class="width100">
                                <div>{{ props.row.id }}</div>
                            </el-form-item>
                            <!--<el-form-item label="是否vip专属：" class="width100">-->
                                <!--<div>{{ props.row.is_belong_vip ? '是' : '否' }}</div>-->
                            <!--</el-form-item>-->
                            <!--<el-form-item label="是否免费领取：" class="width100">-->
                                <!--<div>{{ props.row.is_free ? '是' : '否' }}</div>-->
                            <!--</el-form-item>-->
                            <el-form-item label="同一个订单可以使用的优惠券数量：" class="width100">
                                <div>{{ props.row.limit_pay_num}}</div>
                            </el-form-item>
                            <el-form-item label="满减的商品数：" class="width100">
                                <div>{{ props.row.limit_number}} （例如买三件享受满减优惠）</div>
                            </el-form-item>

                            <el-form-item label="券类型：" class="width100">
                                <div class="color_eb3021">{{ Number(props.row.is_full_fit) === 1 ? '全场通用券':'特殊适用券' }}</div>
                            </el-form-item>

                            <el-form-item label="指定分类可以使用的券：" class="width100">
                                <div class="color_eb3021">{{ props.row.fit_category_names }}</div>
                            </el-form-item>
                            <el-form-item label="指定商品可以使用的券：" class="width100">
                                <div class="color_eb3021">{{ props.row.fit_goods_names }}</div>
                            </el-form-item>
                            <!--<el-form-item label=" 指定分类不能使用优惠券：" class="width100">-->
                                <!--<div>{{ props.row.beside_category_ids }} （例如猫粮不可用）</div>-->
                            <!--</el-form-item>-->
                            <!--<el-form-item label="指定商品不可以使用的券：" class="width100">-->
                                <!--<div>{{ props.row.beside_goods_ids }} （例如go九种肉8磅不可用）</div>-->
                            <!--</el-form-item>-->
                            <el-form-item label="优惠券描述：" class="width100">
                                <div>{{ props.row.name_desc }}</div>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="200" label="优惠券名称" prop="name"></el-table-column>
                <el-table-column align="center" width="150"  label="优惠券使用" prop="couponUse"></el-table-column>
                <el-table-column align="center" width="260"  label="发放日期" prop="publish_date"></el-table-column>
                <el-table-column align="center" width="260"  label="生效时间" prop="publish_date"></el-table-column>
                <el-table-column align="center" width="100"  label="发放总数" prop="limit_publish_num"></el-table-column>
                <el-table-column align="center" width="100"  label="用户可领次数" prop="limit_get_num"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <!--<el-button-->
                            <!--size="mini"-->
                            <!--@click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
                        <el-button size="mini" type="default" @click="toEditCoupon(scope.row.coupon_only_id)">修改</el-button>
                        <el-button size="mini" type="danger" @click="downLineCoupon(scope.row)">下线</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import util from '../../../utils/util'

    export default {
        data: function () {
            return {
                couponList: [],
            }
        },
        components: {
        },
        methods: {
            //获取优惠券列表
            getCouponList(){
                let ajaxParams = {
                    is_online: 1,
                    is_delete: 0,
                    is_active: 1,
                    type: 3, // 会员优惠券类型
                    page: 1,
                    size: 100,
                }
                this.$http({
                    url: this.$api + '/admin/coupon/index',
                    method: 'post',
                    data: this.$qs.stringify(ajaxParams)
                }).then(response => {
                    if (response.data.errno === 0) {
                        let couponList =  response.data.data.data.map((item) =>{
                            item.couponUse = `满 ${item.limit_amount} 减 ${item.preferential_cash}`
                            item.publish_date = `${util.getDateTimeStr(item.publish_start_date)} ~ ${util.getDateTimeStr(item.publish_end_date)}`
                            item.active_date = `${util.getDateTimeStr(item.active_start_date)} ~ ${util.getDateTimeStr(item.active_end_date)}`
                            return item;
                        });
                        this.couponList = couponList;
                    }
                })
            },
            //跳转到编辑优惠券
            toEditCoupon(coupon_only_id){
                this.$router.push({path: '/editCoupon',query:{coupon_only_id:coupon_only_id}})
            },

            //下线食品
            downLineCoupon(coupon) {
                this.$confirm('此操作下线优惠券《'+coupon.name+'》, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$api + 'admin/coupon/switch', {
                        coupon_only_id: coupon.coupon_only_id,
                        is_online: 0
                    }).then(res => {
                        if(res.data.errno===0){
                            this.$message.success("优惠券下线成功");
                        }
                    })
                }).catch(() => {

                });
            }
        },
        created(){

        },
        mounted() {
            this.getCouponList();
        },
    }
</script>

<style lang="scss" scoped>
    .single_food{
        display: flex;
        align-items: center;
        margin: 2px 0;
        padding: 10px;
        border-radius: 4px;
        &.thead{
            font-weight: bold;
            font-size: 15px;
            color: #000;
            &:hover{
                background-color: #f6faff;
            }
        }
        &:hover{
            background-color: #fff;
        }
        .single_data{
            /*flex: 1;*/
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.front_desc{
                /*flex: 1;*/
                width: 300px;
                justify-content: left;
            }
            .food_picture{
                width: 80px;
                height: 80px;
                border-radius: 4px;
            }
        }
    }

    .pointer{
        cursor: pointer;
    }

</style>
