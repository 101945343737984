<template>
    <div class="mike_con">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form :model="ruleForm"  ref="ruleForm" label-width="150px" class="form_con">

            <!--基本信息-->
            <div class="mike_part left">
                <el-alert title="基本信息" type="info" :closable="false" class="coupon_title"></el-alert>
                <!--优惠券名称-->
                <el-form-item label="模板名称" prop="name">
                    <el-input v-model="ruleForm.template_name"></el-input>
                </el-form-item>
                <!--优惠券描述-->
                <el-form-item label="模板说明" prop="name_desc">
                    <el-input v-model="ruleForm.template_desc"></el-input>
                </el-form-item>
            </div>

            <!--限制条件-->
            <div class="mike_part left">
                <el-alert title="限制条件" type="info" :closable="false" class="coupon_title"></el-alert>
                <!--指定商品可以使用的券，例如go专用券，id字符串，多个分类使用逗号分隔-->
                <el-form-item >
                    <el-transfer class="couponGoodsTransfer" v-model="CouponGoods" :data="commissionGoodsData"
                                 :button-texts="['取消', '添加']"
                                 :titles="['备选商品（请搜索）', '已选商品']"
                                 filterable></el-transfer>

                    <div class="baseBorder padding15" >
                        <p class="fontWeight">设置返佣信息</p>
                        <ul class="padding15 ">
                            <li class="padding15" style="border-bottom: 1px solid #eee;" v-for="(item, index) in ruleForm.submitGoods" :key="index">
                                <div class="width100 disflex_flex_start align-items">
                                    <img :src="item.primary_pic_url" alt="" class="width60px">
                                    <div class="padding15">
                                        <p>{{index + 1}}、{{item.name}}</p>
                                        <div class="disflex_between_center">
                                            <p >售价：￥<span class="color_eb3021 font-size-15">{{item.retail_price}}</span> </p>
                                            <p>折扣：<span class="color_09bb07">{{item.discount}}</span></p>
                                            <p>折后价：<span class="color_eb3021 font-size-15">{{(item.retail_price * item.discount).toFixed(2)}}</span></p>
                                        </div>
                                    </div>
                                </div>
                                <el-input  v-model="ruleForm.submitGoods[index].return_cash"  placeholder="返佣金额">
                                    <template slot="prepend">返佣金额：￥</template>
                                </el-input>
                                <span class="font-size-10 color_666">默认建议返佣：  {{((item.retail_price * item.discount)*0.05).toFixed(2)}}  (5%)</span>

                            </li>
                        </ul>
                    </div>
                </el-form-item>
            </div>

            <!--按钮-->
            <div class="text-align-right width100">
                <el-button type="primary" @click="saveEdit">保存模板</el-button>
            </div>

        </el-form>
    </div>
</template>

<script>
    export default {
        name: "addCommissionTemplate",
        data() {
            return {
                ruleForm: {
                    template_name: '',
                    template_desc: '',
                    template_id: null,
                    submitGoods: []
                },
                GoodsData: [],
                CouponGoods: [],
                commissionGoodsData: [],
                template_data: {}
            }
        },
        mounted () {
            this.getGoods().then(res => {
                this.getTemplateDetail();
            });
        },
        watch: {
            CouponGoods (newv) {
                let that = this;
                let submitGoods = [];
                if (newv.constructor === Array) {
                    newv.map(function (param) {
                        console.log(param);
                        that.GoodsData.map(function (item) {
                            console.log(item);
                            if (param === item.id) {
                                submitGoods.push({
                                    id: item.id,
                                    primary_pic_url: item.primary_pic_url,
                                    name: item.name,
                                    return_cash: 0,
                                    retail_price: item.retail_price,
                                    discount: item.discount
                                })
                            }
                        })
                    })
                }

                submitGoods = submitGoods.map(function (item) {
                    that.template_data.template_goods.map(function (templateGoods) {
                        if (Number(item.id) === Number(templateGoods.goods_id)) {
                            item.return_cash = templateGoods.return_cash
                        }
                    });
                    return item
                });

                this.ruleForm.submitGoods = submitGoods;
            }
        },
        methods: {
            saveEdit () {

                if (!this.ruleForm.template_name) {
                    this.$message.error(`请填写模板名称`);
                    return;
                }

                for (let item in this.ruleForm.submitGoods) {
                    if (this.ruleForm.submitGoods[item].return_cash <= 0 || !this.ruleForm.submitGoods[item].return_cash) {
                        this.$message.error(`请完善第${item + 1}条返佣商品的返佣金额`)
                        return;
                    }
                }

                this.$http({
                    url: this.$api + '/admin/serviceTemplate/updateTemplateGoods',
                    method: 'post',
                    data: {
                        template_desc: this.ruleForm.template_name,
                        template_name: this.ruleForm.template_desc,
                        template_id: this.ruleForm.template_id,
                        goods_list: this.ruleForm.submitGoods.map(function (item) {
                            return {
                                head_img_url: item.primary_pic_url,
                                goods_name: item.name,
                                return_cash: item.return_cash,
                                goods_id: item.id
                            }
                        })
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('更新模板成功！');
                        this.$router.back();
                    } else {
                        this.$message.error('更新模板失败！')
                    }
                })
            },
            async getGoods (name) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                await this.$http({
                    url: this.$api + '/admin/goods/getTransferGoods',
                    method: 'post',
                    data: {
                        name: name,
                        page: 1,
                        size: 20
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.GoodsData = response.data.data.data;
                        this.commissionGoodsData = response.data.data.data.map(function (item) {
                            return {
                                key: item.id,
                                label: item.name,
                                disabled: false
                            }
                        })
                    } else {
                        this.$message.error('商品数据加载失败！请刷新重试！')
                    }
                    loading.close();
                })
            },
            getTemplateDetail () {
                this.$http({
                    url: this.$api + '/admin/serviceTemplate/getTemplateDetail',
                    method: 'post',
                    data: {
                        template_id: this.$route.query.template_id
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.template_data = response.data.data;
                        this.CouponGoods = this.template_data.template_goods.map(function (item) {
                            return item.goods_id
                        });
                        this.ruleForm.template_name = this.template_data.template_name;
                        this.ruleForm.template_desc = this.template_data.template_desc;
                        this.ruleForm.template_id = this.template_data.template_id;

                    } else {
                        this.$message.error('加载模板详情失败！')
                    }
                })
            },
        }
    }
</script>

<style lang="scss">
    .couponGoodsTransfer .el-transfer-panel {
        width: 40%;
    }
    .coupon_title {
        margin-bottom: 20px;
    }

    .form_con {
        display: flex;
        flex-wrap: wrap;
    }

    .mike_part {
        width: 100%;
        &.left {
            /*margin-right: 100px;*/
        }
    }
</style>
