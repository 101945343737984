<template>
    <div>
        <el-dialog title="商品砍价详情" :visible.sync="dialogTableVisible" @close="closeDiaglog">
            <el-table :data="buyerList">

                <!--好友砍价列表-->
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <div class="child_list_wrap">
                            <h3>好友帮砍列表</h3>
                            <div class="single_friend" v-for="item in scope.row.childList">
                                <div class="name">{{item.FRIENDS_NICKNAME}}</div>
                                <img class="avatar" :src="item.FRIENDS_AVATAR" alt="">
                                <div class="cut_price">{{item.friends_bargain}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <!--发起者名字-->
                <el-table-column property="BUY_USER_NICKNAME" label="昵称" width="150"></el-table-column>

                <!--发起者头像-->
                <el-table-column property="BUY_USER_AVATAR" label="头像" width="100">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <img :src="scope.row.BUY_USER_AVATAR" style="width: 40px;vertical-align: middle;">
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="最低可砍到">
                    <template slot-scope="scope">
                        <div>{{bargainDetail.retail_price-bargainDetail.cut_price}}元</div>
                    </template>
                </el-table-column>

                <el-table-column property="bargain_id" label="bargainId"></el-table-column>

                <el-table-column label="还可砍金额(元)">
                    <template slot-scope="scope">
                        <div>{{bargainDetail.cut_price-scope.row.FRIENDS_BARGAIN_TOTAL}}</div>
                    </template>
                </el-table-column>

                <!--发起者商品已砍金额-->
                <el-table-column property="FRIENDS_BARGAIN_TOTAL" label="已砍金额(元)"></el-table-column>

                <el-table-column label="砍到底还需">
                    <template slot-scope="scope">
                        <div>{{
                            bargainDetail.cut_price-scope.row.FRIENDS_BARGAIN_TOTAL == 0 ? 0 :
                            bargainDetail.cut_user_num-scope.row.childList.length
                            }}人</div>
                    </template>
                </el-table-column>

                <el-table-column label="已砍人数">
                    <template slot-scope="scope">
                        <div>{{scope.row.childList.length}}人</div>
                    </template>
                </el-table-column>

                <el-table-column label="是否已购买">
                    <template slot-scope="scope">
                        <div>{{scope.row.is_success? '已购买' : ''}}</div>
                    </template>
                </el-table-column>

            </el-table>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "singleUserBargainDetail",
        props: {
            bargainId: null,
            bargainDetail: {}
        },
        data(){
            return {
                dialogTableVisible: false,
                buyerList: []
            }
        },
        watch:{
            bargainId(){
                if(this. bargainId){
                    this.dialogTableVisible = true;
                    this.getUserBargainDetail();
                }
            },
        },
        methods: {
            getUserBargainDetail(){

                console.log('bargainDetail',this.bargainDetail);

                this.$http({
                    url: this.$api + '/admin/bargain/getBargainUserList',
                    method: 'post',
                    data: this.$qs.stringify({bargain_id: this.bargainId, page:1, size: 100})
                }).then(response => {
                    if (response.data.errno == 0) {
                        // console.log('response.data.data',response.data.data.data);
                        this.buyerList = response.data.data.data;
                    }
                })
            },
            closeDiaglog(){
                this.$emit("closeSingleBargainDetail");
            }
        },
        mounted(){
        }
    }
</script>

<style lang="scss" scoped>
    .child_list_wrap{
        padding: 15px 10px;
        border-radius: 4px;
        background-color: #f3f3f3;
    }
    .single_friend{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        .name{
            width: 140px;
            margin-right: 10px;
        }
        .avatar{
            width: 40px;
            height: 40px;
            border-radius: 50%;
            margin-right: 100px;
        }
    }
</style>
