<template>
  <div>
    <div>
      <el-button type="primary" @click="isShowAdd = true">添加新的客户</el-button>
      <el-button type="primary" @click="isCopy = true">复制电话</el-button>
    </div>
    <el-table :data="dbList.data"
              v-loading="!dbList"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              border highlight-current-row>
      <el-table-column
        type="selection"
        width="55">
      </el-table-column>
      <el-table-column label="序号" width="100">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{scope.row.id}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="电话号码" width="100">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{scope.row.mobile}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="姓名" width="100">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{scope.row.name}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="地址" width="100">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{scope.row.address}}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="客源" width="100">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{scope.row.from}}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="paginaion" style="text-align: center;margin-top: 1em">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="dbList.currentPage"
        :page-sizes="[10, 20, 50, 100, 200, 500, 1000, 2000, 5000, 10000]"
        :page-size="dbList.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="dbList.count">
      </el-pagination>
    </div>

    <el-dialog :visible="isShowAdd" @close="isShowAdd = false">
      <div style="width: 100%" class="disflex_flex_start">
        <div style="padding: 30px;width: 70%">
          <el-input style="width: 100%;" type="textarea"
                    :rows="26"
                    placeholder="请输入需要录入的电话号码。每行一个 或者使用英文逗号 “,” 分割"
                    v-model="mobileList"></el-input>
          <div style="padding: 15px;text-align: right">
            <el-button type="primary" @click="submitMobile">提交</el-button>
          </div>
        </div>
        <div class="width40 padding15 edui-shadow">
          <p class="padding15 labelText">不合法的电话已被忽略</p>
          <span v-for="item in invalid" :key="item">{{item}}</span>
        </div>
      </div>
    </el-dialog>
    <el-dialog :visible="isCopy" @close="isCopy = false">
      <p>直接复制输入框文本即可（电话以英文标点：’,‘分割）</p>
        <el-input type="textarea" :rows="25" v-model="copyList"></el-input>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      isCopy: false,
      mobileList: '',
      invalid: [],
      valid: [],
      isShowAdd: false,
      dbList: [],
      selectedList: []
    }
  },
  computed: {
    copyList() {
      return this.selectedList.map(item => {
        return item.mobile
      }).join(',')
    }
  },
  watch: {

  },
  created() {
  },
  async mounted() {
    await this.loadMobile(1, 10)
  },
  methods: {
    handleSelectionChange (val) {
      this.selectedList = val
    },
    handleSizeChange(val) {
      this.loadMobile(this.dbList.currentPage || 1, val)
    },
    handleCurrentChange(val) {
      this.loadMobile(val, this.dbList.pageSize || 10)
    },
    async loadMobile(page, size) {
      await this.$http({
        url: this.$api + '/admin/adminManagement/userMobile',
        method: 'post',
        data: {
          page: page || 1,
          size: size || 20
        }
      }).then(response => {
        if (response.data.errno === 0) {
          this.dbList = response.data.data
        } else {
          this.$message.error('加载电话列表失败！')
        }
      })
    },
    async submitMobile() {
      console.log(this.mobileList)
      let list = this.mobileList.replace(/\n/g, ',')
      // 判断哪些电话号码是不符合手机号规则
      list = Array.from(new Set(list.split(',')))
      this.valid = list.filter(item => {
        if (item.length !== 11) {
          this.invalid.push(item);
          return false
        }
        return true
      })

      // 提交电话号码
      if (!this.valid || this.valid.length === 0) {
        this.$message.error('请输入合法的电话号码')
        return false
      }
      await this.$http({
        url: this.$api + '/admin/adminManagement/saveCustomerMobile',
        method: 'post',
        data: {
          phoneList: this.valid.join(',')
        }
      }).then(response => {
        if (response.data.errno === 0) {
          this.$message.success('保存成功')
        } else {
          this.$message.error('保存失败！')
        }
        })
    }
  }
}
</script>
<style scoped>

</style>

