<template>
    <div>
        <meta name="referrer" content="never">
        <div class="crumbs " style="width: 100%;">
            <div class="disflex_flex_start">
                <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }} 条告警信息）
                </label>
                <el-button type="success" class="margin-left-1em" @click="readAllMessage" >全部已读</el-button>
            </div>
        </div>
        <el-table
            :data="tableData.data"
            v-loading="!tableData.data"
            style="width: 100%;padding: 15px;"
            :stripe="true"
            @expand-change="selectionChange" highlight-current-row >
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-form label-position="center" inline class="demo-table-expand">
                        <el-form-item>
                            <h2>
                                商品id：{{scope.row.json_msg.call_goods_info.id}}
                            </h2>
                            <a :href="scope.row.json_msg.call_goods_info.primary_pic_url" target="_blank">
                                <img class="cursor_pointer"  :src="scope.row.json_msg.call_goods_info.primary_pic_url" style="width: 40px;vertical-align: middle;">
                            </a>
                            <span style="margin-left: 10px">{{ scope.row.json_msg.call_goods_info.name }}</span>
                            <el-button type="success" @click="$router.push('/goodsedit?id=' + scope.row.json_msg.call_goods_info.id)">更新库存</el-button>
                            <el-button type="primary" @click="updateGoodsNum(scope.row.json_msg.call_goods_info.id)">快速更新至99</el-button>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>

            <el-table-column
                label="状态" width="50">
                <template slot-scope="scope">
                        <el-link type="info" v-if="scope.row.is_read === 1">已读</el-link>
                        <el-link type="danger" v-else>未读</el-link>
                </template>
            </el-table-column>
            <el-table-column
                label="告警内容">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <p>
                            <i class="el-icon-warning color_eb3021" color="#ev3021" size="25"></i>
                            {{scope.row.msg}}
                        </p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="add_time"
                label="告警时间">
            </el-table-column>

        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[5,10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: {}
            }
        },
        mounted () {
            this.getStorageAlert()
        },
        methods: {
            readAllMessage () {
                this.$http.post(this.$api + '/admin/messageHub/updateAllReadStatus', {
                    is_read: 1
                })
                    .then(res => {
                        this.$message.success('全部消息已设置为已读！！')
                        this.getStorageAlert(this.tableData.currentPage, this.tableData.pageSize)
                    })
            },
            selectionChange (param) {
                for (let item in this.tableData.data) {
                    if (this.tableData.data[item].id === param.id) {
                        this.tableData.data[item].is_read = 1
                    }
                }
                this.$http.post(this.$api + '/admin/messageHub/updateReadStatus', {
                    is_read: 1,
                    id: param.id
                })
            },
            updateGoodsNum (id) {
                this.$http.post(this.$api + '/admin/goods/updateGoodsStorageNumber', {
                    number: 99,
                    id: id
                })
                    .then(res => {
                        if (res.data.errno === 0) {
                            this.$message.success('更新成功！')
                        }
                    })
            },
            handleSizeChange(val) {
                this.getStorageAlert(this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getStorageAlert(val, this.tableData.pageSize)
            },
            getStorageAlert (page, size) {
                this.$http.post(this.$api + '/admin/messageHub/storageAlert', {
                    page: page || this.tableData.currentPage || 1,
                    size: size || this.tableData.pageSize || 10
                })
                    .then(res => {
                        if (res.data.errno === 0) {
                            this.tableData = res.data.data
                        }
                    })
            }
        },
    }
</script>
<style>
    .item_image{
        width: 50px;
        height: 50px;
    }
    .add_time_right{
        text-align: right;
    }
    .el-table__row {
        cursor: pointer;
    }
    .el-table .warning-row {
        background: oldlace;
    }
    .el-table .success-row {
        background: #f0f9eb;
    }
</style>
