<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>商品管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <goods-detail-preview :data="goodsDetailData" :is-center="true"></goods-detail-preview>
    </div>
</template>

<script>
    import bus from '../../common/bus'
    import goodsDetailPreview from '../../common/goodsDetailPreview'

    export default {
        data: function () {
            return {
                goodsDetailData: {}, // 商品详情
            }
        },
        components: {
            goodsDetailPreview: goodsDetailPreview
        },
        watch: {
            $route: {
                handler (newv) {
                    if (newv.query.id) {
                        this.loadGoodsDetail(newv.query.id).then(res => {
                            // this.getParentId()
                        })
                    } else {
                        this.$message.error('null id 商品不存在！')
                    }
                },
                immediate: true
            }
        },
        methods: {
            // 获取商品详情
            async loadGoodsDetail (id) {
                await this.$http({
                    url: this.$api + "/admin/goods/get",
                    data: this.$qs.stringify({ goodsId: id }),
                    method: 'post'
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.goodsDetailData = response.data.data
                    }
                });
            },
        },
        created() {
            this.Auth_token = localStorage.getItem('auth_token')
        },
        mounted() {
        }
    }
</script>
<style>

    .el-upload-list{
        display: none !important;
    }
</style>
<style scoped>
    .operationBtn{
        text-align: right;
        padding: 20px;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .audio-div {
        margin: 20px 0 20px 0;
    }

    .article-edit span {
        width: 100px;
        margin-left: 20px;
    }

    .display-flex {
        display: flex;
    }
</style>
