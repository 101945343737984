<template>
    <div >
        <h2 class="bg_fff padding15">用户信息</h2>
        <div class="disflex_between_center bg_fff">
            <fieldset  class="padding15 border_eee width40 baseBorder">
                <legend class="color_cdcdcd">公众号用户信息</legend>
                <div class="userMsg">
                    <el-card :body-style="{ padding: '0px', display: 'flex' }">
                        <img :src="allData.pusherDetail.headimgurl" style="width: 80px;height: 80px;" >
                        <div style="padding: 14px;">
                            <h4>用户昵称： {{allData.pusherDetail.nickname}}</h4>
                            <div class="bottom clearfix">
                                <span>用户ID： {{allData.pusherDetail.id}}</span>
                                <p>地区： {{allData.pusherDetail.country}}-{{allData.pusherDetail.province}}-{{allData.pusherDetail.city}}</p>
                                <p>加入时间：{{allData.pusherDetail.add_time}}</p>
                            </div>
                        </div>
                    </el-card>
                </div>
            </fieldset>
            <fieldset  class="padding15 border_eee width40 baseBorder">
                <legend class="color_cdcdcd">小程序用户</legend>
                <div class="userMsg">
                    <el-card :body-style="{ padding: '0px', display: 'flex' }">
                        <img :src="allData.miniProgramUserData.avatar" style="width: 80px;height: 80px;">
                        <div style="padding: 14px;">
                            <h4>用户昵称： {{allData.miniProgramUserData.nickname}}</h4>
                            <div class="bottom clearfix">
                                <span>用户ID： {{allData.miniProgramUserData.id}}</span>
                                <span>电话： {{allData.miniProgramUserData.pure_mobile}}</span>
                                <p>注册ip：{{allData.miniProgramUserData.register_ip}}</p>
                                <p>注册时间：{{allData.miniProgramUserData.register_time}}</p>
                                <p>最后登录时间：{{allData.miniProgramUserData.last_login_time}}</p>
                            </div>
                        </div>
                    </el-card>
                </div>
            </fieldset>
        </div>
        <div class="padding15 bg_fff">
            <fieldset  class="padding15 border_eee width40 baseBorder">
                <legend class="color_cdcdcd">小组情况</legend>
                <div class="userMsg">
                    <el-card :body-style="{ padding: '0px', display: 'flex' }">
                        <img :src="allData.grouperDetail.group_avatar" style="width: 80px;height: 80px;">
                        <div class="bottom clearfix">
                            <h4>小组名称： {{allData.grouperDetail.group_name}}</h4>
                            <p>组长名称： {{allData.grouperDetail.belong_user_name}}</p>
                            <p>微信号： {{allData.grouperDetail.wechat}}</p>
                            <p>手机号：{{allData.grouperDetail.mobile}}</p>
                            <p>更新时间：{{allData.grouperDetail.update_time}}</p>
                            <p>小组状态：{{allData.grouperDetail.is_permit_str}}</p>
                        </div>
                    </el-card>
                </div>
            </fieldset>
        </div>
        <h2 class="bg_fff padding15">商城订单情况</h2>
        <el-table :data="allData.miniProgramUserOrderCount"
                  v-loading="!allData.miniProgramUserOrderCount" style="width: 100%">
            <el-table-column label="金额" prop="actual_price" width="150"></el-table-column>
            <el-table-column label="收件人" prop="consignee" width="120"></el-table-column>
            <el-table-column label="收件电话" prop="mobile" width="120"></el-table-column>
            <el-table-column label="订单状态" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.order_status }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                allData: {
                    pusherDetail: {},
                    miniProgramUserData: {},
                    grouperDetail: {},
                    miniProgramUserOrderCount:  []
                },
            }
        },
        components: {},
        watch: {},
        created() {
            this.getPaperList(0, 1, 10)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
        },
        mounted() {

        },
        methods: {
            getPaperList(is_limit, page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/serviceAccount/getDetail',
                    method: 'post',
                    data: {
                        service_id: this.$route.query.service_id
                    }
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.allData = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style scoped>
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .pointer {
        cursor: pointer;
    }
</style>

