<template>
    <div>
            <div class="edit-area">
                <vue-html5-editor :content="content" :height="800" :z-index="9999" show-module-name="true" :padding="30"  @change="updateData"></vue-html5-editor>
                <!-- <ueditor v-model="uploadData.content" v-if="showMavon"></ueditor> -->
            </div>

            <div style="position: fixed;bottom: 0;width: 100%;display: flex;justify-content: space-between;padding: 15px;box-sizing: border-box;z-index: 9999;">
                <el-button class="editor-btn" type="default" >存为草稿</el-button>
                <el-button class="editor-btn" type="primary" >编辑完成</el-button>
            </div>
        <!-- <uploadTool></uploadTool> -->


    </div>
</template>
<script>
    // import { mavonEditor } from 'mavon-editor'
    // import uploadTool from '../uploadTool'
    // import 'mavon-editor/dist/css/index.css'
    // import ueditor from '../common/editor'
    // import upload from './Upload'
    // import wx from '../../wx'
    // import VueHtml5Editor from 'vue-html5-editor'
    import bus from '../common/bus'

    export default {
        data: function () {
            return {
                content: ''
            }
        },
        components: {
            // =======================改编辑器================
            // mavonEditor,
            // uploadTool,
            // ueditor
        },
        watch: {

        },
        created() {
           let script = document.createElement('script')
           script.src= 'https://file.chezidao.com/root/jssdk/wx.js'
           let body = document.getElementsByTagName('body')[0]
           body.appendChild(script)
        },
        mounted() {
            // //console.log(wx)
            //console.log(this.$route)
            this.$test_token = this.$route.query.token
            localStorage.setItem('Auth_token', this.$route.query.token)
            // this.$refs.editor.enableFullScreen()

        },
        methods: {
            updateData (data) {
                let data2 = data.replace(/<img src=/g, '<img style="width: 100%;" src=');
                this.content = data2
            }

        }
    }
</script>
<style scoped>
.editor-btn{
    width: 100%;
}
.edit-area{
    padding: 0 20px;
}
</style>
