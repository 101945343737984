<template>
    <div >
        <div>
            <h1>运营指标管理</h1>
            <div style="padding: 30px 15px;box-sizing: border-box;width: 50%">
                <el-form ref="form" :model="Indicators" label-width="150px">
                    <el-form-item label="用户数指标">
                        <el-input v-model="Indicators.target_user_num" placeholder="请填写用户指标"></el-input>
                    </el-form-item>
                    <el-form-item label="订单金额指标">
                        <el-input v-model="Indicators.target_total_price" placeholder="请填写订单金额指标"></el-input>
                    </el-form-item>
                    <el-form-item label="订单量指标">
                        <el-input v-model="Indicators.target_total_num" placeholder="请填写订单量指标"></el-input>
                    </el-form-item>
                    <el-form-item label="指标生效月份">
                        <el-date-picker
                            v-model="Indicators.date"
                            type="month"
                            placeholder="指标生效月份">
                        </el-date-picker>
                    </el-form-item>
                </el-form>
            </div>
            <div class="text-align-right">
                <el-button type="primary" @click="addNewIndicators">新增运营指标</el-button>
            </div>
            <div class="width100 padding15">
                <el-table
                    v-loading="!indicatorsListData"
                    element-loading-text="正在加载今日订单"
                    element-loading-spinner="el-icon-loading"
                    element-loading-background="rgba(0, 0, 0, 0.8)" border :data="indicatorsListData" :show-header="true"  style="width: 100%;font-size:14px;">
                    <el-table-column type="expand">
                    </el-table-column>
                    <el-table-column style="cursor: pointer;" label="指标日期">
                        <template slot-scope="scope">
                            <div class="text-align-center">
                                {{scope.row.year}}年 - {{scope.row.month}}月
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column style="cursor: pointer;" label="用户指标/人">
                        <template slot-scope="scope">
                            <span>{{scope.row.target_user_num}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column style="cursor: pointer;" label="订单金额指标">
                        <template slot-scope="scope">
                            <span class="bold15 red">￥ {{ scope.row.target_total_price }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column style="cursor: pointer;" label="订单数量指标">
                        <template slot-scope="scope">
                            <span class="bold15 red">{{ scope.row.target_total_num }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column style="cursor: pointer;" label="操作">
                        <template slot-scope="scope">
                            <el-button type="success" @click="editIndicators(scope.row)">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "operationalIndicators",
        data () {
            return {
                Indicators: {
                    target_user_num: 0,
                    target_total_price: 0,
                    target_total_num: 0,
                    month: new Date().getMonth() + 1,
                    year: new Date().getFullYear(),
                    date: ''
                },
                indicatorsListData: []
            }
        },
        components: {
        },
        watch: {

        },
        mounted () {
            this.indicatorsList();
        },
        methods: {
            // 编辑运营指标
            editIndicators (row) {
                this.Indicators = row;
                this.Indicators.date = row.year + '-' + row.month;
            },
            // 新增运营指标
            addNewIndicators () {

                if (!this.Indicators.date) {
                    this.$message.error('请选择指标时间');
                    return
                }

                this.Indicators.month = new Date(this.Indicators.date).getMonth() + 1;
                this.Indicators.year = new Date(this.Indicators.date).getFullYear();

                const loading = this.$loading({
                    lock: true,
                    text: '正在生成~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });


                this.Indicators.month = new Date(this.Indicators.date).getMonth() + 1;
                this.Indicators.year = new Date(this.Indicators.date).getFullYear();

                if (!this.Indicators.year) {
                    this.$message.error('请选择指标时间');
                    return
                }

                if (!this.Indicators.month) {
                    this.$message.error('请选择指标时间');
                    return
                }

                this.$http({
                    url: this.$api + '/admin/operationManagement/addNewIndicators',
                    method: 'post',
                    data: this.Indicators
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success(response.data.data);
                        this.indicatorsList();
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                    loading.close()
                }).catch(err => {
                    loading.close()
                })
            },
            // 获取今年的指标情况
            indicatorsList () {
                const loading = this.$loading({
                    lock: true,
                    text: '正在生成~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/operationManagement/indicatorsList',
                    method: 'post',
                    data: this.Indicators
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.indicatorsListData = response.data.data
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                    loading.close()
                }).catch(err => {
                    loading.close()
                })
            }
        }
    }
</script>

<style scoped>

</style>
