import './public-path';
import Vue from 'vue';
import store from './store'
import App from './App';
import routes from './router';
import VueRouter from 'vue-router';
import axios from 'axios';
import _ from 'lodash';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';    // 默认主题
// import '../static/css/theme-green/index.css';       // 浅绿色主题
import qs from 'qs';
import echarts from 'echarts'
import api from './static/api'
import "./assets/icon/iconfont.css"
import   "font-awesome.css"
import hasPermission from './libs/hasPermission'
import VueDND from 'awe-dnd'
// import hack from './utils/hack'

import Print from 'vue-print-nb'
Vue.use(Print); //注册

import PrintDiret from 'vue-print-plugin'

// Vue.use(PrintDiret);
//=======================插件调用==========================
Vue.use(ElementUI, { size: 'small' });
Vue.use(hasPermission);
// vue 拖拽
Vue.use(VueDND);
// Vue.use(hack);
// Vue.use(clipboard);
// 指定请求类型
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Vue.prototype.$http = axios;
Vue.prototype.$_ = _;
// 运行挂上图表插件
Vue.prototype.$echarts = echarts;
// 超时设置
Vue.prototype.$http.defaults.timeout = 500000
// ====================跨域接口设置==========================
if (process.env.NODE_ENV === 'development') {
    Vue.prototype.$api = ''
    // 设置百度编辑器的代理
    window.proxyTypeForVue = Vue.prototype.$api
    console.warn('开发模式：添加跨域设置' + Vue.prototype.$api)
    console.log('%c深圳市猫趴科技有限公司 %c猫趴正品商城后台 %c 开发模式', 'background:#35495e ; padding: 1px;  color: #fff','background:#41b883 ; padding: 1px; border-radius:  3px 0 0 3px;  color: #fff','background:#ff0000 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff');
} else {
    Vue.prototype.$api = ''
    // 设置百度编辑器的代理
    window.proxyTypeForVue = Vue.prototype.$api
    console.info('生产模式')
    console.log('%c深圳市猫趴科技有限公司 %c猫趴正品商城后台 %c 生产模式', 'background:#35495e ; padding: 1px;   color: #fff','background:#41b883 ; padding: 1px; border-radius:  3px 0 0 3px;  color: #fff','background:#ff0000 ; padding: 1px; border-radius: 0 3px 3px 0;  color: #fff');
}

Vue.prototype.$test_token = ''


let router = new VueRouter({
    base: window.__POWERED_BY_QIANKUN__ ? '/manage/' : '/',
    mode: 'history',
    routes,
});


// ===================全局请求等待状态==========================
// var loadinginstace
// axios请求拦截
axios.interceptors.request.use(config => {
    // loadinginstace = ElementUI.Loading.service();

    return config
},error=> {
    // loadinginstace.close()
    ElementUI.Message.error({
        message: '加载失败'
    })
    return Promise.reject(error)
})

// ========================http拦截=============================
axios.interceptors.response.use(data => {
    // loadinginstace.close()

    if (data.data.errno !== 0) {
        ElementUI.Message.error({
            message: data.data.errmsg
        })
        if (data.status === 404) {
            router.push('/')
            ElementUI.Message.error({
                message: '页面不存在！'
            })
        }
        if (data.data.errno === 401) {
            router.push('/login');
            localStorage.setItem('auth_token','');
            setTimeout(function () {
                if (router.history.current.path !== '/login') {
                    ElementUI.MessageBox.alert('请登陆后使用本系统！', {
                        confirmButtonText: '重新登陆',
                        showCancelButton: true,
                        cancelButtonText: '取消',
                        callback: (action, instance, done) => {
                            if (action === 'confirm') {
                                localStorage.setItem('auth_token','');
                                router.push('/login')
                            } else {
                                ElementUI.Message.error({
                                    message: '未登录无权访问本页面！'
                                })
                                try {
                                    done();
                                } catch (e) {
                                    console.log(e)
                                }
                            }
                        }
                    });
                }
            }, 200)
        }
    }
    return data
},error => {
    // if (loadinginstace) loadinginstace.close();
    ElementUI.Message.error({
        message: 'NETWORK ERROR 网络错误！'

    });
    return Promise.reject(error)
})
// ====================原型链扩展QS用于格式化提交参数=========================
Vue.prototype.$qs = qs;
// 使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
    if (to.meta.title) document.title = to.meta.title
    //  每次触发钩子都要检查登陆状况
    const Auth_token = localStorage.getItem('auth_token');
    axios.defaults.headers.common['auth_token'] = Auth_token;
    if(!Auth_token && to.path !== '/login'){
        ElementUI.MessageBox.alert('请登陆后使用本系统！', {
            confirmButtonText: '重新登陆',
            showCancelButton: true,
            cancelButtonText: '取消',
            callback: (action, instance, done) => {
                if (action === 'confirm') {
                    next('/login');
                } else {
                    ElementUI.Message.error({
                        message: '未登录无权限访问本页面！'
                    })

                    try {
                        done();
                    } catch (e) {
                        console.log(e)
                    }
                    setTimeout(function () {
                        window.location.replace('https://www.mxclass.com')
                    },1500)
                }
            }
        });
    }else{
        // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
        if(navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor'){
            Vue.prototype.$alert('不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
                confirmButtonText: '确定'
            });
        }else{
            next();
        }
    }
})
// ===========================公共api列表===================================
// 遍历接口列表，挂到Vue上，实现便捷调用
for (let item in api) {
    Vue.prototype[item] = api[item]
}

// ===========================原型链上增加全局方法===========================
Vue.prototype.formatStrTime = (str,length) => {
    if (str !== undefined && str !== '' && str !== null) {
        return str.substring(0,length)
    } else {
        return ''
    }
}
Vue.prototype.devAlert = () =>{
    ElementUI.Message.info('功能正在开发，敬请期待')
};
Vue.prototype.$getDicByName = () => {
  return null
}
Vue.prototype.$getDay = (day) => {
    var today = new Date();

    var targetday_milliseconds=today.getTime() + 1000*60*60*24*day;
    // 重新设置设置时间
    today.setTime(targetday_milliseconds);

    var tYear = today.getFullYear();
    var tMonth = today.getMonth();
    var tDate = today.getDate();
    tMonth = doHandleMonth(tMonth + 1);
    tDate = doHandleMonth(tDate);
    return tYear+"-"+tMonth+"-"+tDate;
}
function doHandleMonth(month){
    var m = month;
    if(month.toString().length == 1){
        m = "0" + month;
    }
    return m;
}
Vue.prototype.$reload =  () => {
    setTimeout(function(){
        try{
            window.location.reload()
        } catch (e) {
            window.location.reload(true)
        }
    },200)
}
Vue.prototype.EmptyJson = json => {
    for (var item in json) {
        if (typeof json[item] === 'Array') {
            json[item] = []
        } else if (typeof json[item] === 'object') {
            json[item] = {}
        } else {
            json[item] = ''
        }
    }
}
Vue.prototype.emptyJson = json => {
    if (typeof json === 'object') {
        for (var item in json) {
            json[item] = null
        }
    }
}
Vue.prototype.UTCtime = time => {
    let t = time.replace(/-/g,'/')
    let n = new Date(t)
    return n
}
Vue.prototype.Format = (date,fmt) => { //author: meizz
    if (!date) date = new Date()
    var that = date
    var o = {
        "M+": that.getMonth() + 1, //月份
        "d+": that.getDate(), //日
        "H+": that.getHours(), //小时
        "m+": that.getMinutes(), //分
        "s+": that.getSeconds(), //秒
        "q+": Math.floor((that.getMonth() + 3) / 3), //季度
        "S": that.getMilliseconds() //毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (that.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}
// 通用导出方法
// Vue.prototype.$export = (url,data) => {
Vue.prototype.$export = (d) => {
    let data = d.data
    data.Auth_token =localStorage.getItem('auth_token')
    var form = document.createElement('form')
    form.setAttribute('action', d.url)
    form.setAttribute('method', 'post')
    form.setAttribute('target', '_self')
    for (let key in data){
        var htmlInputElement = document.createElement('input');
        htmlInputElement.setAttribute('name', key)
        htmlInputElement.setAttribute('value', data[key])
        htmlInputElement.setAttribute('type', 'hidden')
        console.log(htmlInputElement)
        form.appendChild(htmlInputElement)
    }
    document.getElementById('app').appendChild(form)
    form.submit();
}

Vue.prototype.log = (m) => {
    console.log(m)
}
// 删除oss 上的照片
Vue.prototype.handleRemove = (file, fileList) => {
    const url = file.response.data
    Vue.prototype.$http({
        url: Vue.prototype.$api + '/api/oss/delete',
        method: 'post',
        data: Vue.prototype.$qs.stringify({url: url})
    }).then(res => {
        ElementUI.Message.error({
            message: res.data.errmsg
        })
    }).catch( res => {
        ElementUI.Message.error({
            message: res.data.errmsg
        })
    })
}
// marven编辑器图片上传回调
Vue.prototype.handleMAVON_IMG_UPLOAD = (response,md,pos) => {
    if (response.data.ret === 0) {
        if (response.data.data) {
            md.$img2Url(pos, response.data.data);
        }
    }
}

let instance = null;
function render(props = {}) {
    const { container } = props;
    instance = new Vue({
        router,
        store,
        render: h => h(App),
    }).$mount(container ? container.querySelector('#app') : '#app');
}
// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
    render();
}

export async function bootstrap() {
    console.log('[vue] vue app bootstraped');
}
export async function mount(props) {
    console.log('[vue] props from main framework', props);
    render(props);
}
export async function unmount() {
    instance.$destroy();
    instance.$el.innerHTML = '';
    instance = null;
}
