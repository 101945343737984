<template>
    <!--v-for="question in newDataList"-->
    <div class="" >
        <h3 style="margin-top: 10px;margin-bottom: 10px">{{question.title}}</h3>
        <div class="tag-box-tool" style="color: #8c939d">
            <div style="display: flex">
                <div class="user-avator">
                    <img :src="question.heads" />
                </div>
                <span style="margin-right: 10px;margin-left: 5px">{{question.nickname}}</span>
                <span>{{question.city?question.city.split(" ")[1]:''}}  |  {{question.jobPosition}}</span>
            </div>
            <span>发布于  {{formatStrTime(question.createTime,16)}}</span>
        </div>

        <div  class="answer-contentIntro" v-if="!this.spread">
            <img :src="question.picture" >
            <div>
                {{question.contentIntro?question.contentIntro:''}}...
                <el-button v-if="question.contentIntro"
                    title="展开全文"
                           @click="spreadAll"
                           type="text"
                size="medium">展开全文
                </el-button>
            </div>
        </div>
        <!--<div class="answer-content"  v-for="(item,index) in analyContent(question.content)" :key="index">-->
            <!--<p v-if="item.name == 'p'">{{item.value}}</p>-->
            <!--<img v-if="item.name == 'img'" :src="item.value"/>-->
        <!--</div>-->
        <div v-else v-html="question.content"></div>

        <div class="tag-box-tool">
            <el-button v-if="question.rewardPrice>0" style="color: red">悬赏金额 {{question.rewardPrice}} 元</el-button>
            <label v-else></label>
            <div style="width: 400px" class="tag-box-tool ">
                <el-button style="color: #000000" size="medium" type="" @click="getComment(question.answerId)">{{question.commentNum}} 评论</el-button>
                <span>{{question.praiseNum}} 点赞</span>
                <span>{{question.collectNum}} 收藏</span>
                <span>{{question.shardNum}} 分享</span>

                <el-dropdown v-if="question.showAccept"  @command="handleCommand">
                  <span class="el-dropdown-link ft16">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                    <el-dropdown-menu  slot="dropdown">
                        <el-dropdown-item command="accept">采纳为最佳</el-dropdown-item>
                        <el-dropdown-item command="delete">删除回答</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <!--<div v-show="comment.showForm">-->
        <!--<div></div>-->
        <comment-list style="padding:10px 30px 10px 30px;background:#f0f0f0;" :comment-data="comment"></comment-list>


    </div>
</template>

<script>
    import commentList from './commentList';

    export default {
        data() {
            return {
                comment: {
                    id:'',
                    showForm:false
                },
                page: 1,
                spread: false,
                isAccept: true,
                currentPage: 1,
                total: 0,
                size: 50,
            }
        },
        props: ['question'],
        computed: {},
        components: {
            commentList
        },
        mounted() {

        },
        watch: {
            // 监听父组件传来的数据
            question: function () {
                this.spread = this.question.spread?this.question.spread:false
                this.comment.showForm = false
            },
        },
        methods: {
            getList(title, page, length,id) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/chigua/admin/answer/comment/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        answerId:id,
                        sort: title,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.comment = response.data.data
                        this.comment.id = id
                        this.comment.showFold = true
                        this.comment.showForm  = true
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                    loading.close()
                })
            },
            getComment(id){
                if (this.question.commentNum === 0){
                    this.$message('没有评论')
                }else {
                    this.getList('',1,10,id)
                }

            },
            analyContent(content){
                if (content===null||content===''){
                    return '';
                }
                let contents = content.split('#$-$#');
                let result = [];
                for (let item in contents) {
                    if (contents[item].startsWith('p#$==$#')){
                        let node = {
                            name: 'p',
                            value:contents[item].substring(7)
                        }
                        result.push(node)
                    }else if (contents[item].startsWith('img#$==$#')){
                        let node = {
                            name: 'img',
                            value:contents[item].substring(9)
                        }
                        result.push(node)
                    }
                }
                return result;
            },
            spreadAll() {
                this.spread = true
            },
            handleCommand(command) {
                if (command ==='delete') {
                    this.deleteAnswer(this.question.id,this.question.isAccept)
                }else if (command ==='accept') {
                    this.acceptAnswer(this.question)
                }
            },
            acceptAnswer(question){
                if (question.isAccept) {
                    this.$confirm('该回答已经是最佳回答了，不能重复设置', '警示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '操作取消'
                        });
                    });
                }else {
                //console.log(question)
                this.$confirm('您正在将'+question.nickname+'的回答设为最佳回答，设置成功后不可以撤销并将把会悬赏金额打手对方账户中，确认？', '警示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/answer/update/accept',
                        method: 'post',
                        data: this.$qs.stringify({
                            questionId: question.questionId,
                            id:question.answerId
                        })
                    }).then(response => {
                            if (response.data.errno === 0) {
                                this.$message.success('处理成功！');
                            }else {
                                this.$message.warning(response.data.msg);
                            }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
                }
            },
            deleteAnswer(id,isAccept){
                if (isAccept){
                    this.$confirm('您正在删除最佳回答，该功能被管理员禁用，请联系管理员处理', '警示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '操作取消'
                        });
                    });

                } else {
                    this.$confirm('您正在删除问题，确认？', '警示', {
                        confirmButtonText: '删除',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$http({
                            url: this.$api + '/chigua/admin/answer/delete',
                            method: 'post',
                            data: this.$qs.stringify({id: id})
                        }).then(response => {
                            if (response.status === 200) {
                                if (response.data.errno === 0) {
                                    this.$message.success('删除成功！');
                                    // bus.$emit('reloadPage',true)
                                }else {
                                    this.$message.warning(response.data.msg);
                                }
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '操作取消'
                        });
                    });
                }
            }
        }
    }
</script>
<style scoped>

    .answer-contentIntro > img{
        margin-right:20px;height: 100px;vertical-align: middle;
    }
    .answer-content > img{
        width: 50%;
        height: 50%
    }
    .answer-content {
        display: flex;
        margin: 5px 0 5px 0;
    }
    .answer-contentIntro {
        display: flex;
        margin: 5px 0 5px 0;
    }

    .tag-box > div {
        margin-top: 10px;
    }

    .core-data-box > div {
        padding: 0 10px 0 10px;
        margin-top: 10px;
    }
    .ft16{
        font-size: 16px;
        padding-top: 5px;
    }
    .tag-box-tool > span {
        padding-top: 5px;
    }
    .tag-box-tool {
        display: flex;
        justify-content: space-between;
    }

    .user-avator img {
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        vertical-align: middle;
    }

</style>
