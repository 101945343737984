<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>开发设置</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="padding: 10px">
            <el-button type="primary" @click="addChildMenu()" style="margin: 1em;">添加频道</el-button>

            <el-tree
                class="menu-tree-table"
                :data="menuDataOffLine"
                :props="defaultProps"
                :indent="indent"
                :expand-on-click-node="false"
                accordion
                @node-click=""
            >
                <div class="menu-flex" style="width: 100%" slot-scope="{ node, data }">
                    <!--菜单名-->
                    <div style="width: 8em">
                        <!--<span>{{data.sort_order}}、</span>-->
                        <img style="width: 25px;height: 35px; margin-right: 10px;vertical-align: middle;" :src="data.icon_url" alt="频道图标">
                        <span style="padding-left: 1px">{{ data.name }}({{data.id}})</span>
                    </div>
                    <!--图标-->
                    <div >
                        <p>{{data.url}}</p>
                    </div>

                    <!--序号-->
                    <div >
                        <p>序号：{{data.sort_order}}</p>
                    </div>
                    <div style="">


                        <!--<i title="删除" class="el-icon-delete ft25 red pointer" @click="deleteCategory(data)" v-if="data.is_delete == 0"></i>-->
                        <!--<span v-if="data.is_delete == 1" style="color: #ff0000;font-size: 12px;">已删除</span>-->
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer" style="margin-left: 10px"
                           @click="editMenu(data)"></i>

                        <!-- 上下线 -->
                        <i title="下线" class="el-icon-remove-outline ft25 pointer"
                           @click="downLineCategory(data)"></i>

                        <!--<i :title="data.menuType===1?'添加下级菜单':(data.menuType===2?'添加按钮':'')" v-show="!(data.menuType===3)"-->
                           <!--class="el-icon-circle-plus-outline ft25 pointer" style="margin-left: 10px"-->
                           <!--@click="addChildMenu(data)"></i>-->
                    </div>
                </div>
            </el-tree>
        </div>

        <addChannel></addChannel>
        <editChannel></editChannel>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import editChannel from './editChannel'
    import addChannel from './addChannel'

    export default {
        data: function () {
            return {
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                menuDataOffLine: [],
                showSencondMenu: false,
                paperId: '',
                indent: 0,
                showTable: false,
                tableData: '',
                menuType: [
                    {label: '目录', value: 1},
                    {label: '菜单', value: 2},
                    {label: '按钮', value: 3}
                ],
            }
        },
        components: {
            editChannel, addChannel
        },
        watch: {},
        created() {
            this.getPaperList()
        },
        mounted() {
            bus.$on('updateChannel', msg => {
                if (msg) {
                    this.getPaperList()
                }
            })
        },
        methods: {
            // 频道下线
            downLineCategory (row) {
                this.$confirm('您正在进行【下线频道】操作，该操作会直接改变线上频道', '警示', {
                    confirmButtonText: '下线',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/channel/switch',
                        method: 'post',
                        data: this.$qs.stringify({channelId: row.id, isShow: 0})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno == 0) {
                                this.$message.success('下线成功！')
                                this.getPaperList(this.currentTagId, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            addChildMenu() {
                bus.$emit('addChannel')
            },
            addmenuShow() {
                bus.$emit('addChannel')
            },
            editMenu(row) {
                bus.$emit('editChannel', row)
            },
            deleteCategory(row) {
                this.$confirm('您执行删除频道操作！删除后将会在小程序下线', '警告', {
                    confirmButtenText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(msg => {
                    this.$http({
                        url: this.$api + '/admin/channel/delete',
                        method: 'post',
                        data: this.$qs.stringify({
                            channelId: row.id
                        })
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success('删除【' + row.name + '】频道成功！该菜单将同步在客户端删除！');
                            this.getMenuList()

                        } else {
                            this.$message.error('频道删除失败')
                        }
                    })
                }).catch(msg => {
                    this.$message.info('操作取消')
                })
            },
            getMenuList() {
                this.$http({
                    url: this.$api + '/admin/channel/index',
                    method: 'get'
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.menuDataOffLine = response.data.data
                    } else {
                        this.$message.error('菜单加载失败')
                    }
                })
            },
            getPaperList() {
                // var menuDataOffLine = JSON.parse(localStorage.getItem('menuDataOffLine'))
                // this.menuDataOffLine = menuDataOffLine
                this.getMenuList()
            }
        }
    }
</script>
<style>
    .menu-tree-table .is-expanded {
        background-color: #f0f8f9;
    }

    .menu-tree-table .el-tree-node {
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #f0f0f0;
        /*text-align: center;*/
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .menu-flex {
        display: flex;
        justify-content: space-around;
        line-height: 34px;
        /*margin:auto;*/
    }
</style>
