<template>
    <div>
        <div class="crumbs button-tool">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}条内容）</label>

        </div>
        <div class="disflex_flex_start">
            <div class="search-title">
                <el-input placeholder="搜索内容/标题" v-model="searchTitle" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>

        <el-table
            :data="tableData.data"
            style="width: 100%">
            <el-table-column
                label="用户昵称"
                width="120">
                <template slot-scope="scope">
                    <div class="text-align-center">
                        <img class="width30" :src="scope.row.avatar" alt="">
                        <p>{{ scope.row.nickname }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="问题类型" width="200">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper" style="position: relative;">
                        <img v-if="scope.row.fakePicture" :src="scope.row.fakePicture"
                             style="width: 60px;vertical-align: middle;">
                        <h4>{{ scope.row.type_name }}</h4>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="反馈内容" >
                <template slot-scope="scope">
                    <span
                        style="margin-left: 10px">{{ scope.row.content }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="联系电话"
                width="120">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.mobile }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="邮箱"
                width="130">
                <template slot-scope="scope">
                    <span style="margin-left: 10px;font-size: 10px;">{{ scope.row.email }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="反馈时间"
                width="130">
                <template slot-scope="scope">
                    <span
                        style="margin-left: 10px;font-size: 10px;">{{ scope.row.add_time}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="回复内容">
                <template slot-scope="scope">
                    <span
                        style="margin-left: 10px;font-size: 10px;" v-if="scope.row.response_content">
                        【{{ scope.row.response_user}}】{{ scope.row.response_content}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="回复时间"
                width="130">
                <template slot-scope="scope">
                    <span
                        style="margin-left: 10px;font-size: 10px;">{{ scope.row.response_time}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="100">
                <template slot-scope="scope" >
                    <div style="display: flex;justify-content: space-between;height:35px;align-items: flex-end;">
                        <i title="回复" class="el-icon-position ft25 pointer"
                           @click="showResponseBoxFun(scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <el-dialog :visible.sync="showResoponseBox" custom-class="question-dialog" title="反馈回复" @close="showResoponseBox = false">
            <h3 class="color_eb3021 font-size-15">请注意：</h3>
            <p class="color_eb3021 font-size-13">1、请正面反馈用户的内容，如果你不确定你的反馈内容，请小组讨论后发布。</p>
            <p class="color_eb3021 font-size-13">2、该内容会直接通知用户，代表猫趴的官方观点。</p>
            <p class="color_eb3021 font-size-13">3、收到反馈后，请7天内回复用户，否则将不能再回复用户。</p>

            <div class="padding15 box_sizing">
                <p class="padding15">
                    回复人：
                </p>
                <el-input v-model="responseData.response_user" placeholder="请输入回复人" required>
                </el-input>
                <p class="padding15">
                    回复内容：
                </p>
                <el-input v-model="responseData.response_content" placeholder="请输入回复内容" required>
                </el-input>

                <div class="padding15 text-align-right">
                    <el-button type="primary" @click="submitResponse">
                        回复并通知用户
                    </el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    export default {
        data: function () {
            return {
                tableData: {},
                responseData: {
                    response_user: '',
                    response_content: ''
                },
                responseItem: {},
                searchTitle: '',
                showResoponseBox: false,
            }
        },
        components: {
        },
        watch: {},
        created() {
            this.getFeedbackList(1, 10);
        },
        mounted() {

        },
        methods: {
            // 显示回复框
            showResponseBoxFun (item) {
                this.responseItem = item;
                this.showResoponseBox = !this.showResoponseBox
            },
            // submitResponse 提交回复
            submitResponse () {
                this.$http({
                    url: this.$api + 'admin/feedback/responseUser',
                    method: 'post',
                    data: {
                        response_content: this.responseData.response_content,
                        response_user: this.responseData.response_user,
                        feedback_id: this.responseItem.id,
                        form_id: this.responseItem.form_id
                    }
                }).then(response => {
                    if (response.errno === 0) {
                        this.$message.success(response.data || response.errmsg);
                        this.showResoponseBox = !this.showResoponseBox
                    } else {
                        this.$message.error('发布失败！')
                    }
                })
            },
            handleSearchByTitle() {
                this.getFeedbackList(this.currentPage, this.size, this.searchTitle)
            },
            handleSizeChange(val) {
                this.getFeedbackList(this.currentPage, val, this.searchTitle)
            },
            handleCurrentChange(val) {
                this.getFeedbackList(val, this.size, this.searchTitle)
            },
            getFeedbackList(page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/feedback/list',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page,
                        size: length,
                        search: title || this.searchTitle
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            },
        }
    }
</script>
<style>
    .question-dialog .el-dialog__body {
        padding: 10px 35px 20px 40px !important;
    }

    .question-dialog .el-dialog__header {
        border-bottom: #6d6d9d 1px solid !important;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px !important;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
