<template>
    <div>

        <div class="padding15">
            <span @click="gotoStorageAlert" >
                <el-badge :value="adminMsg.storageAlert" class="item">
                    <el-button type="danger" icon="el-icon-warning" size="small">库存告警</el-button>
                </el-badge>

                <!--<el-badge class="item">-->
                    <!--<el-button type="warn" icon="el-icon-warning" size="small">数据中台</el-button>-->
                <!--</el-badge>-->

            </span>
        </div>
        <el-row :gutter="24" v-if="UA !== 'MOBILE'">
            <el-col :span="10">
                <el-row>
                    <el-col>
                    <el-card shadow="hover" class="mgb20">
                        <div class="user-info">
                            <img :src="userMsg.avatar" class="user-avator" alt="">
                            <div class="user-info-cont">
                                <div class="user-info-name">{{userMsg.username}}</div>
                                <div>{{role}}</div>
                            </div>
                        </div>
                        <div class="user-info-list">上次登录时间：<span style="font-size: 12px">{{userMsg.last_login_time}}</span></div>
                        <div class="user-info-list">上次登录地点：<span>{{userMsg.location || '未知地点'}}</span></div>
                        <el-button type="primary" icon="el-icon-warning" size="small" @click="updateGitConfig">更新GIT配置</el-button>
                        <todo></todo>
                    </el-card>
                </el-col>
                </el-row >
                <el-row style="background-color: #fff;"  shadow="hover" class="mgb20 box_sizing">.
                    <div class="disflex_between_center width100 padding15 box_sizing">
                        <h3 class="box_sizing">商城运营指标</h3>
                        <span class="labelText cursor_pointer" @click="$router.push('/operationalIndicators')">历史数据 > </span>
                    </div>
                    <el-col>
                        <el-row :gutter="24" class="mgb20 box_sizing" >
                            <el-col :span="8" class="padding0 text-align-center">
                                <el-progress type="circle" :stroke-width="10" :percentage="OperationalIndicatorsData.user_num_rate" :status="OperationalIndicatorsData.user_num_rate_status  || '0'"></el-progress>
                                <p class="text-align-center font-size-13">{{OperationalIndicatorsData.month}}月用户指标</p>
                                <p class="text-align-center font-size-10">{{OperationalIndicatorsData.thiMonth_user_num}} / {{OperationalIndicatorsData.target_user_num}}</p>

                            </el-col>
                            <el-col :span="8" class="padding0 text-align-center">
                                <el-progress type="circle" :stroke-width="10" :percentage="OperationalIndicatorsData.order_num_rate" :status="OperationalIndicatorsData.order_money_rate_status  || '0'"></el-progress>
                                <p class="text-align-center font-size-13">{{OperationalIndicatorsData.month}}月订单数指标</p>
                                <p class="text-align-center font-size-10">{{OperationalIndicatorsData.month_order_num}} / {{OperationalIndicatorsData.target_total_num}}</p>

                            </el-col>
                            <el-col :span="8" class="padding0 text-align-center">
                                <el-progress type="circle" show-text :stroke-width="10" :percentage="OperationalIndicatorsData.order_money_rate" :status="OperationalIndicatorsData.order_money_rate_status || '0'"></el-progress>
                                <p class="text-align-center font-size-13">{{OperationalIndicatorsData.month}}月订单金额指标</p>
                                <p class="text-align-center font-size-10">{{OperationalIndicatorsData.month_sell_price}} / {{OperationalIndicatorsData.target_total_price}}</p>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row >
                <el-row style="background-color: #fff;padding: 15px;"  shadow="hover" class="mgb20 box_sizing">
                    <price-charts :charts-data="chartsData" id-attr="priceAnalysis" name="销售金额"></price-charts>
                </el-row>
                <el-row style="background-color: #fff;padding: 15px;"  shadow="hover" class="mgb20 box_sizing">
                    <user-charts :charts-data="userAnalysisData" id-attr="userAnalysis" name="用户总数"></user-charts>
                </el-row>
                <el-row style="background-color: #fff;padding: 15px;"  shadow="hover" class="mgb20 box_sizing">
                    <user-charts :charts-data="everyMothSaleTotalPrice" type="bar" id-attr="everyMothSaleTotalPrice" name="本月销售额"></user-charts>
                </el-row>
            </el-col>
            <el-col :span="14">
                <el-row :gutter="24" class="mgb20">
                    <el-col :span="8" class="padding0">
                        <el-card shadow="hover" :body-style="{padding: '0px',cursor: 'pointer'}" >
                            <div class="grid-content grid-con-1">
                                <i class="el-icon-view grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div>总在售商品数</div>
                                    <div class="grid-num">{{statisticsData.goodsCount}}</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8" class="padding0">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-2">
                                <i class="el-icon-mobile-phone grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div>本月用户数</div>
                                    <div class="grid-num">{{ statisticsData.userNum }}</div>
                                    <!-- <div>新增名片数 {{ loadIndexStatus.card.todayNumber }}</div> -->
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8" class="padding0">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-4">
                                <i class="el-icon-circle-check-outline grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div>本月总成交额</div>
                                    <div class="grid-num">{{statisticsData.payMoneyCount? statisticsData.payMoneyCount:0}}</div>
                                    <!-- <div>新成交额 {{ payMoneyCount }}</div> -->
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row :gutter="24" class="mgb20">
                    <el-col :span="8" class="padding0">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-5">
                                <i class="el-icon-sold-out grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div>本月总付款数</div>
                                    <div class="grid-num">{{statisticsData.PayCount}}</div>
                                    <!-- <div>今日付款数 {{loadIndexStatus.paid.todayNumber}}</div> -->
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8" class="padding0">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-3">
                                <i class="el-icon-goods grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <!--<div class="grid-num">5000</div>-->
                                    <!--<div>BUG</div>-->
                                    <div>本月总未付款数</div>
                                    <div class="grid-num">{{statisticsData.unPayCount}}</div>
                                    <!-- <div>今日未付款数 {{ loadIndexStatus.unPaid.todayNumber }}</div> -->
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8" class="padding0">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                            <div class="grid-content grid-con-3">
                                <i class="el-icon-tickets grid-con-icon"></i>
                                <div class="grid-cont-right">
                                    <div>中通面单/张</div>
                                    <div class="grid-num">{{quota.ZTO.quota_num}}</div>
                                    <div>百世面单/张</div>
                                    <div class="grid-num">{{quota.BEST.quota_num}}</div>
                                </div>
                            </div>
                        </el-card>
                    </el-col>
                </el-row>
                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>今日订单</span>
                        <!-- <el-button style="float: right; padding: 3px 0" type="text">添加</el-button> -->
                    </div>
                    <el-table
                        v-loading="!newOrderData.data"
                        element-loading-text="正在加载今日订单"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)" border :data="newOrderData.data" :show-header="true"  style="width: 100%;font-size:14px;">
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-form label-position="center" inline class="demo-table-expand">
                                    <el-form-item>
                                        <h2>订单号：{{scope.row.order_sn}}</h2>
                                        <el-table :data="scope.row.goodList" style="width: 600px"
                                                  border
                                                  show-summary
                                                  :summary-method="getSummaries"
                                                  sum-text="总计需支付价格">
                                            <el-table-column label="商品名称" width="300" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <img @click="showImagePath = scope2.row.list_pic_url" :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                                        <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="数量"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column label="购买规格"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;font-size: 15px;">￥ {{ scope2.row.goods_specifition_name_value }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="结算单价"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="结算总价" sortable align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="用户信息">
                            <template slot-scope="scope">
                                <div class="text-align-center">
                                    <img @click="showImagePath = scope.row.avatar" :src="scope.row.avatar" alt="" class="width40 cursor_pointer">
                                    <p  >{{ scope.row.nickname }} </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="收件信息">
                            <template slot-scope="scope">
                                <span>{{ scope.row.consignee }}  |  {{ scope.row.mobile }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="订单金额">
                            <template slot-scope="scope">
                                <span class="bold15 red">￥ {{ scope.row.order_price }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="实际支付金额" width="100">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.actual_price }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column style="cursor: pointer;" label="状态">
                            <template slot-scope="scope">
                                <span class="red" v-if="scope.row.order_status === 0">{{ scope.row.order_status_text }} </span>
                                <span class="yellow" v-else-if="scope.row.order_status >0 && scope.row.order_status < 200">{{ scope.row.order_status_text }} </span>
                                <span class="blue" v-else-if="scope.row.order_status === 201">{{ scope.row.order_status_text }} </span>
                                <span v-else>{{ scope.row.order_status_text }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column style="cursor: pointer;" label="订单生成时间">
                            <template slot-scope="scope">
                                <span >{{ scope.row.add_time }} </span>
                            </template>
                        </el-table-column>


                        <el-table-column style="cursor: pointer;color: #ff0000;" label="买家备注">
                            <template slot-scope="scope">
                                <span style="color: #ff0000;">{{ scope.row.remarks }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="$router.push('/orderDetail?id=' + scope.row.id)">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-pagination
                        @size-change="todayOrderSizeChange"
                        @current-change="todayOrderCurrentChange"
                        :current-page="newOrderData.currentPage"
                        :page-sizes="[5,10, 20, 30, 40]"
                        :page-size="newOrderData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="newOrderData.count">
                    </el-pagination>
                </el-card>
                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>待发货订单</span>
                    </div>
                    <el-table
                        v-loading="!UnExpressOrderData.data"
                        element-loading-text="待发货订单加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)" border :data="UnExpressOrderData.data" :show-header="true"  style="width: 100%;font-size:14px;">
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-form label-position="center" inline class="demo-table-expand">
                                    <el-form-item>
                                        <h2>订单号：{{scope.row.order_sn}}</h2>
                                        <el-table :data="scope.row.goodList" style="width: 600px"
                                                  border
                                                  show-summary
                                                  :summary-method="getSummaries"
                                                  sum-text="总计需支付价格">
                                            <el-table-column label="商品名称" width="300" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <img :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                                        <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="数量"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="结算单价"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="结算总价" sortable align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="用户信息">
                            <template slot-scope="scope">
                                <div class="text-align-center">
                                    <img @click="showImagePath = scope.row.avatar" :src="scope.row.avatar" alt="" class="width40 cursor_pointer">
                                    <p  >{{ scope.row.nickname }} </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="收件信息">
                            <template slot-scope="scope">
                                <span>{{ scope.row.consignee }}  |  {{ scope.row.mobile }} |
                                    {{scope.row.ProvinceName + scope.row.CityName + scope.row.DistrictName + scope.row.address}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="订单金额">
                            <template slot-scope="scope">
                                <span class="bold15 red">￥ {{ scope.row.order_price }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="实际支付金额" width="100">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.actual_price }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column style="cursor: pointer;" label="订单生成时间">
                            <template slot-scope="scope">
                                <span >{{ scope.row.add_time }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column style="cursor: pointer;color: #ff0000;" label="买家备注">
                            <template slot-scope="scope">
                                <span style="color: #ff0000;">{{ scope.row.remarks }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="addExpressFUN_AUTO_EXPRESS(scope.row)">生成运单</el-button>
                                <p>---</p>
                                <el-button type="success" @click="addExpressFUN(scope.row)">人工发货</el-button>
                                <p>---</p>
                                <el-button type="info" @click="$router.push('/orderDetail?id=' + scope.row.id)">查看详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>

                    <el-pagination
                        @size-change="UnExpressOrderDataSizeChange"
                        @current-change="UnExpressOrderDataCurrentChange"
                        :current-page="UnExpressOrderData.currentPage"
                        :page-sizes="[5,10, 20, 30, 40]"
                        :page-size="UnExpressOrderData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="UnExpressOrderData.count">
                    </el-pagination>
                </el-card>
            </el-col>
        </el-row>
        <el-row :gutter="24" v-if="UA === 'MOBILE'" >
            <el-col :span="24">
                <el-row :gutter="24" class="mgb20">
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{padding: '0px',cursor: 'pointer'}" >
                                <div class="grid-cont-right UA_MOBILE_1">
                                    <div>总在售商品数</div>
                                    <div class="grid-num grid-num_mobile">{{statisticsData.goodsCount}}</div>
                            </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                                <div class="grid-cont-right UA_MOBILE_2">
                                    <div>新增用户数</div>
                                    <div class="grid-num grid-num_mobile">{{ statisticsData.userNum }}</div>
                                    <!-- <div>新增名片数 {{ loadIndexStatus.card.todayNumber }}</div> -->
                                </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                                <div class="grid-cont-right UA_MOBILE_3">
                                    <div>本月总成交额</div>
                                    <div class="grid-num grid-num_mobile">{{statisticsData.payMoneyCount? statisticsData.payMoneyCount:0}}</div>
                                    <!-- <div>新成交额 {{ payMoneyCount }}</div> -->
                                </div>
                        </el-card>
                    </el-col>
                </el-row>
                <el-row :gutter="24" class="mgb20">
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                                <div class="grid-cont-right UA_MOBILE_4">
                                    <div>本月总付款数</div>
                                    <div class="grid-num grid-num_mobile">{{statisticsData.PayCount}}</div>
                                    <!-- <div>今日付款数 {{loadIndexStatus.paid.todayNumber}}</div> -->
                                </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                                <div class="grid-cont-right UA_MOBILE_5">
                                    <div>本月总未付款数</div>
                                    <div class="grid-num grid-num_mobile">{{statisticsData.unPayCount}}</div>
                                </div>
                        </el-card>
                    </el-col>
                    <el-col :span="8">
                        <el-card shadow="hover" :body-style="{padding: '0px'}">
                                <div class="grid-cont-right UA_MOBILE_6">
                                    <div>中通面单/张</div>
                                    <div class="grid-num grid-num_mobile">{{quota.ZTO.quota_num}}</div>
                                    <div>百世面单/张</div>
                                    <div class="grid-num grid-num_mobile">{{quota.BEST.quota_num}}</div>
                                </div>
                        </el-card>
                    </el-col>
                </el-row>

                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>今日订单 <span class="color_09bbe7">（共{{newOrderData.count || 0}}单）</span></span>
                    </div>
                    <el-table
                        v-loading="!newOrderData.data"
                        element-loading-text="正在加载今日订单"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)" border :data="newOrderData.data" :show-header="true"  style="width: 100%;font-size:14px;">
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-form label-position="center" inline class="demo-table-expand">
                                    <el-form-item>
                                        <h2>订单号：{{scope.row.order_sn}}</h2>
                                        <el-table :data="scope.row.goodList" style="width: 600px"
                                                  border
                                                  show-summary
                                                  :summary-method="getSummaries"
                                                  sum-text="总计需支付价格">
                                            <el-table-column label="商品名称" width="300" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <img class="cursor_pointer" @click="showImagePath = scope2.row.list_pic_url" :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                                        <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="数量"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="购买规格"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;font-size: 15px;">￥ {{ scope2.row.goods_specifition_name_value }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="结算单价"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="结算总价" sortable align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="用户信息">
                            <template slot-scope="scope">
                                <div class="text-align-center">
                                    <img @click="showImagePath = scope.row.avatar" :src="scope.row.avatar" alt="" class="width40 cursor_pointer">
                                    <p  >{{ scope.row.nickname }} </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="收件信息">
                            <template slot-scope="scope">
                                <span>{{ scope.row.consignee }}  |  {{ scope.row.mobile }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="订单金额">
                            <template slot-scope="scope">
                                <span class="bold15 red">￥ {{ scope.row.order_price }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="实际支付金额" width="100">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.actual_price }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column style="cursor: pointer;" label="状态">
                            <template slot-scope="scope">
                                <span class="red" v-if="scope.row.order_status === 0">{{ scope.row.order_status_text }} </span>
                                <span class="yellow" v-else-if="scope.row.order_status >0 && scope.row.order_status < 200">{{ scope.row.order_status_text }} </span>
                                <span class="blue" v-else-if="scope.row.order_status === 201">{{ scope.row.order_status_text }} </span>
                                <span v-else>{{ scope.row.order_status_text }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column style="cursor: pointer;" label="订单生成时间">
                            <template slot-scope="scope">
                                <span >{{ scope.row.add_time }} </span>
                            </template>
                        </el-table-column>


                        <el-table-column style="cursor: pointer;color: #ff0000;" label="买家备注">
                            <template slot-scope="scope">
                                <span style="color: #ff0000;">{{ scope.row.remarks }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="primary" @click="$router.push('/orderDetail?id=' + scope.row.id)">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="todayOrderSizeChange"
                        @current-change="todayOrderCurrentChange"
                        :current-page="newOrderData.currentPage"
                        :page-sizes="[5,10, 20, 30, 40]"
                        :page-size="newOrderData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="newOrderData.count">
                    </el-pagination>
                </el-card>


                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>待发货订单 <span class="color_09bbe7">（共{{UnExpressOrderData.count || 0}}单）</span></span>
                    </div>
                    <el-table
                        v-loading="!UnExpressOrderData.data"
                        element-loading-text="待发货订单加载中"
                        element-loading-spinner="el-icon-loading"
                        element-loading-background="rgba(0, 0, 0, 0.8)" border :data="UnExpressOrderData.data" :show-header="true"  style="width: 100%;font-size:14px;">
                        <el-table-column type="expand">
                            <template slot-scope="scope">
                                <el-form label-position="center" inline class="demo-table-expand">
                                    <el-form-item>
                                        <h2>订单号：{{scope.row.order_sn}}</h2>
                                        <el-table :data="scope.row.goodList" style="width: 600px"
                                                  border
                                                  show-summary
                                                  :summary-method="getSummaries"
                                                  sum-text="总计需支付价格">
                                            <el-table-column label="商品名称" width="300" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <img @click="showImagePath = scope2.row.list_pic_url" :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;" class="cursor_pointer">
                                                        <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="数量"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="结算单价"  width="80" align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="结算总价" sortable align="center">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-form-item>
                                </el-form>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="用户信息">
                            <template slot-scope="scope">
                                <div class="text-align-center">
                                    <img @click="showImagePath = scope.row.avatar" :src="scope.row.avatar" alt="" class="width40 cursor_pointer">
                                    <p  >{{ scope.row.nickname }} </p>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="收件信息">
                            <template slot-scope="scope">
                                    <span>{{ scope.row.consignee }}  |  {{ scope.row.mobile }} |
                                        {{scope.row.ProvinceName + scope.row.CityName + scope.row.DistrictName + scope.row.address}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="订单金额">
                            <template slot-scope="scope">
                                <span class="bold15 red">￥ {{ scope.row.order_price }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column label="实际支付金额" width="100">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.actual_price }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column style="cursor: pointer;" label="订单生成时间">
                            <template slot-scope="scope">
                                <span >{{ scope.row.add_time }} </span>
                            </template>
                        </el-table-column>

                        <el-table-column style="cursor: pointer;color: #ff0000;" label="买家备注">
                            <template slot-scope="scope">
                                <span style="color: #ff0000;">{{ scope.row.remarks }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column style="cursor: pointer;" label="操作" align="center">
                            <template slot-scope="scope">
                                <el-button type="success" @click="addExpressFUN(scope.row)">发货</el-button>
                                <p>---</p>
                                <el-button type="primary" @click="$router.push('/orderDetail?id=' + scope.row.id)">详情</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="UnExpressOrderDataSizeChange"
                        @current-change="UnExpressOrderDataCurrentChange"
                        :current-page="UnExpressOrderData.currentPage"
                        :page-sizes="[5,10, 20, 30, 40]"
                        :page-size="UnExpressOrderData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="UnExpressOrderData.count">
                    </el-pagination>
                </el-card>

                <el-row style="background-color: #fff;"  shadow="hover" class="mgb20">
                    <price-charts :charts-data="chartsData" id-attr="priceAnalysis" name="销售金额"></price-charts>
                </el-row>
                <el-row style="background-color: #fff;"  shadow="hover" class="mgb20">
                    <user-charts :charts-data="userAnalysisData" id-attr="userAnalysis" name="用户总数"></user-charts>
                </el-row>
                <el-row style="background-color: #fff;"  shadow="hover" class="mgb20">
                    <user-charts :charts-data="everyMothSaleTotalPrice" type="bar" id-attr="everyMothSaleTotalPrice" name="本月销售额"></user-charts>
                </el-row>
            </el-col>
        </el-row>
        <preview-image :img-path="showImagePath" @closeImageHub="showImagePath = ''"></preview-image>
        <!--发货组件-->
        <add-express v-if="is_adding_express"></add-express>

        <el-dialog
            title="填写发货信息"
            :visible.sync="addExpress"
            width="90%">
            <h2>1、选择快递信息</h2>

            <el-cascader
                v-model="ShipperCode_originList"
                :options="expressCompany"></el-cascader>
            <h2>2、确认运单信息</h2>
            <div class="disflex_around_start">
                <!--发件人信息-->
                <div class="expressBox">
                    <el-form ref="senderMsg" :model="express">
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">发件信息</legend>
                            <el-form-item :rules="[
                                  { required: true, message: '发件人不能为空'}
                                ]" prop="Sender.Name">
                                <span>发件人：</span>
                                <el-input v-model="express.Sender.Name" placeholder="请选择物流公司" ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: true, message: '发件人手机号不能为空！'}
                                ]" prop="Sender.Mobile">
                                <span>发件人联系方式：</span>
                                <el-input v-model="express.Sender.Mobile" placeholder="手机号码" ></el-input>
                                <el-input v-model="express.Sender.Tel" placeholder="固话" require ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: true, message: '发件省市地址不能为空，省市区间用空格分开'}
                                ]" prop="Sender.FullRegion">
                                <span>发件地址：</span>
                                <el-input v-model="express.Sender.FullRegion" placeholder="请选择发件地址" ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: true, message: '发件人详细地址不能为空'}
                                ]" prop="Sender.Address">
                                <span>发件地址：</span>
                                <el-input v-model="express.Sender.Address" placeholder="详细地址" ></el-input>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
                <!--收件人信息-->
                <div class="expressBox">
                    <el-form ref="receiverMsg"  :model="express">
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">收件信息</legend>
                            <el-form-item prop="Receiver.Name" :rules="[
                                  { required: true, message: '收件人姓名不能为空！'}
                                ]">
                                <span>收件人：</span>
                                <el-input v-model="express.Receiver.Name" placeholder="请填写收件人姓名"></el-input>
                            </el-form-item>
                            <el-form-item prop="Receiver.Mobile"  :rules="[
                                  { required: true, message: '收件人手机号不能为空'}
                                ]">
                                <span>收件人联系方式：</span>
                                <el-input v-model="express.Receiver.Mobile" placeholder="收件人手机号码" ></el-input>
                                <el-input v-model="express.Receiver.Tel" placeholder="收件人固话" require ></el-input>
                            </el-form-item>
                            <el-form-item  prop="Receiver.FullRegion" :rules="[
                                  { required: true, message: '收件人省市区不能为空，省市区间用空格分开'}
                                ]">
                                <span>收件地址：</span>
                                <el-input v-model="express.Receiver.FullRegion" placeholder="请选择发件地址"></el-input>
                            </el-form-item>
                            <el-form-item  prop="Receiver.Address" :rules="[
                                  { required: true, message: '收件人详细地址不能为空！'}
                                ]">
                                <span>收件地址：</span>
                                <el-input v-model="express.Receiver.Address" placeholder="详细地址" ></el-input>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
                <!--其他物流信息-->
                <div class="expressBox">
                    <el-form ref="otherExpress" :model="express">
                        <fieldset class="padding15 border_eee" v-if="express.goodsList">
                            <legend class="color_cdcdcd">其他物流信息</legend>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>快递物件名：</span>
                                <el-input v-model="express.goodsList[0].goods_name" placeholder="请填写快递物件名"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>重量：(千克)</span>
                                <el-input v-model="express.weight" placeholder="包裹重量"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>长：（cm）</span>
                                <el-input v-model="express.space_x" placeholder="包裹长度"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>宽：(cm)</span>
                                <el-input v-model="express.space_y" placeholder="包裹宽度"  ></el-input>
                            </el-form-item>

                            <el-form-item prop="Commodity.GoodsName">
                                <span>宽：(cm)</span>
                                <el-input v-model="express.space_z" placeholder="包裹宽度"  ></el-input>
                            </el-form-item>

                            <el-form-item>
                                <span>买家备注：</span>
                                <textarea class="remarks" name="备注（如果为空，则可以填写快递备注，数据直接同步到快递，打印在快递单上）" id="" cols="20" rows="10" :value="express.Remark"
                                          @input="RemarkChange"></textarea>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
            </div>
            <span slot="footer" class="dialog-footer" @click="addExpress = false">
            <el-button type="primary" @click="sendExpress">获取运单</el-button>
            <el-button type="default">保  存</el-button>
          </span>
        </el-dialog>
        <!--startprint-->
        <el-dialog
            title="预览运单"
            close-on-press-escape
            close-on-click-modal
            :visible.sync="BOOL_EXPRESS_HTML"
            width="65%"
            @close="BOOL_EXPRESS_HTML = false">
            <div class="text-align-right">
                <el-button v-print="'#express_print'"  type="primary">打印运单</el-button>
                <el-button  type="primary" @close="BOOL_EXPRESS_HTML = false">关闭</el-button>
            </div>
            <div class="width50">
                <div id="express_print" style="position: relative; height: 1500px;width: 100%;" v-if="BOOL_EXPRESS_HTML"  v-html="EXPRESS_HTML"></div>
            </div>
        </el-dialog>
        <!--endprint-->
    </div>
</template>

<script>
    import bus from '../common/bus'
    import multiCharts from '../common/multiCharts'
    import addExpress from '../common/addExpress'
    import todo from '../common/todo'
    import previewImage from '../common/previewImage'
    import print from 'print-js'


    export default {
        data() {
            return {
                // 管理员信息
                adminMsg: {
                    storageAlert: 0, // 库存告警数量
                },
                showImagePath: '', // 预览图片
                optiontoChildren: '',
                userMsg: localStorage.getItem('userMsg'),
                loadIndexStatus: {
                    wxUser:{totalNumber: 0,todayNumber: 0},
                    card: {totalNumber: 0,todayNumber: 0},
                    deal: {totalNumber: 0,todayNumber: 0},
                    unPaid: {totalNumber: 0,todayNumber: 0},
                    paid: {totalNumber: 0,todayNumber: 0},
                },
                loadData: [],
                chartsData:[],
                userAnalysisData: [], // 用户分析数据
                UnExpressOrderData: [], // 未发货订单
                everyMothSaleTotalPrice: [], // 个月销售金额
                is_adding_express: false, // 是否显示发货组件
                statisticsData: {
                    unPayCount: 0,
                    goodsCount: 0,
                    PayCount: 0,
                    payMoneyCount: 0,
                    unPayMoneyCount: 0,
                    userNum: 0
                }, // 首页分析数据
                newOrderData: {}, // 获取最新订单
                baseExpress: {}, // 发货对象信息
                showAddExpressDialog: false,
                quota: {
                    ZTO: {

                    },
                    YTO: {

                    },
                    BEST: {}
                }, //中通快递面单
                EXPRESS_HTML: '', // 需要打印的HTML面单
                addExpress: false,
                expressCompany: [
                    {label: '顺丰速运', value: 'SF', children: [
                            {value: 0, label: '标准快递'},
                            {value: 1, label: '顺丰次日'},
                            {value: 2, label: '顺丰次晨'},
                            {value: 3, label: '顺丰标快'},
                            {value: 4, label: '顺丰特惠'}

                        ]},
                    {label: '中通快递', value: 'ZTO', children: [
                            {value: 0, label: '标准快递'}
                        ]},
                    {label: '圆通快递', value: 'YTO', children: [
                            {value: 0, label: '普通快递'},
                            {value: 1, label: '圆准达'},
                        ]}
                ], //物流公司
                ShipperCode_originList: [], //
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                PayTypeOptions: [ // 付款方式
                    {label: '现付', value: 1},
                    {label: '到付', value: 2},
                    {label: '月结', value: 3},
                    {label: '第三方', value: 4}
                ],
                ExpTypeOptions: [
                    // 快递类型
                    {label: '标准快件', value: 1}
                ],
                express: {
                    weight: 1,
                    space_x: 20,
                    space_y: 20,
                    space_z: 20,
                    LogisticCode: '', // 运单号
                    ShipperCode: 'ZTO', //物流公司代码
                    ShipperName: '中通快递', // 物流公司名称
                    shipper_service_type: 0,
                    shipper_service_name: '标准快递',
                    OrderId: '',
                    IsNotice: 1, //默认不通知
                    IsSendMessage: 1, // 默认发送通知
                    PayType: 1, // 默认发件类型为现付
                    ExpType: 1, // 默认快递类型为标准快递
                    Quantity: '', // 数量
                    Remark: '猫趴客户，请微笑服务~', // 备注
                    Sender: {
                        Name: '李美丽',
                        Tel: '',
                        Mobile: '13631156639',
                        Address: '华宁路厦门大学产学研基地14楼1402',
                        ProvinceName: '广东省',
                        CityName: '深圳市',
                        ExpAreaName: '龙华区',
                        FullRegion: '广东省 深圳市 龙华区'
                    },
                    Receiver: {
                        Name: '',
                        Tel: '',
                        Mobile: '',
                        Address: '',
                        ProvinceName: '',
                        CityName: '',
                        ExpAreaName: '',
                        FullRegion: ''
                    },
                    Commodity: {
                        GoodsName: ''
                    }
                    // 快递信息
                },
                showRefundDetailBool: false, // 是否显示退款订单详情
                refundStatus: [], // 退款详情
                OperationalIndicatorsData: {} // 运营指标
            }
        },
        watch: {
            ShipperCode_originList (newv) {
                let that =this;
                this.express.ShipperCode = newv[0];
                this.express.shipper_service_type = newv[1];

                this.expressCompany.map(function (item) {
                    if (that.express.ShipperCode === item.value) {
                        that.express.ShipperName = item.label;
                        item.children.map(function (param) {
                            if (that.express.shipper_service_type == param.value) {
                                that.express.shipper_service_name = param.label;
                            }
                        })
                    }
                })
            }
        },
        components: {
            previewImage: previewImage,
            priceCharts: multiCharts,
            userCharts: multiCharts,
            addExpress: addExpress,
            todo: todo
        },
        computed: {
            role() {
                return this.name === 'admin' ? '超级管理员' : '管理员';
            },
            UA () {
                return this.$store.state.UA || 'WEB'
            },
            BOOL_EXPRESS_HTML: {
                get () {
                    return this.EXPRESS_HTML !== ''
                },
                set () {

                }
            }
        },
        created() {
            this.loadPushData();
            this.loadNewOrder();
            let token = localStorage.getItem('auth_token');
            if (!token) {
                this.$router.push('/login')
            }
        },
        mounted(){
            this.ShipperCode_originList = ['ZTO', 0];

            let userMsg =  localStorage.getItem('userMsg');
            try {
                this.userMsg = JSON.parse(userMsg)
            } catch (e) {
                //console.log(e)
            }
            this.loadOrderAnalysisData();
            this.loadUserAnalysis();
            this.loadUnExpressOrder().then(res => {
                this.loadEveryMothSaleTotal();
            });
            let that = this;
            bus.$on('closeAddExpressDialog', function () {
                that.is_adding_express = false
                that.loadOrderAnalysisData();
            })
            this.getQuota();
            this.getAdminMsg();
            this.getOperationalIndicators()
        },
        methods: {
            // 获取运营指标
            async getOperationalIndicators () {
                const loading = this.$loading({
                    lock: true,
                    text: '正在加载数据....',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                await this.$http.post(this.$api + '/admin/operationManagement/getOperationalIndicators', )
                    .then( res => {
                        this.OperationalIndicatorsData = res.data.data
                        loading.close()
                    }).catch(err => {
                        loading.close()
                    })
            },
            // 更新GIT配置
            async updateGitConfig () {
                await this.$http.post(this.$api + '/admin/system/checkoutUserIp')
                    .then( res => {
                        console.log(res)
                        this.$message.success('更新GIT配置成功！！')
                    }).catch(err => {
                        this.$message.error('更新失败，请联系管理员！')
                        console.log(err)
                    })
            },
            UnExpressOrderDataSizeChange (val) {
                this.loadUnExpressOrder(this.UnExpressOrderData.currentPage, val)
            },
            UnExpressOrderDataCurrentChange (val) {
                this.loadUnExpressOrder(val, this.UnExpressOrderData.pageSize)
            },
            todayOrderSizeChange (val) {
                this.loadNewOrder(this.newOrderData.currentPage, val)
            },
            todayOrderCurrentChange(val) {
                this.loadNewOrder(val, this.newOrderData.pageSize)
            },
            // 备注
            RemarkChange (e) {
                this.express.Remark = e.target.value
            },
            // 发货操作
            sendExpress () {
                let that = this;
                that.$refs['senderMsg'].validate((valid) => {
                    if (!valid) {
                        that.$message.error('请完善发件人必填信息！！');
                        return false;
                    } else {
                        that.$refs['receiverMsg'].validate((valid) => {
                            if (!valid) {
                                that.$message.error('请完善收件人必填信息！！');
                                return false;
                            } else {
                                that.$refs['otherExpress'].validate((valid) => {
                                    if (!valid) {
                                        that.$message.error('请完善其他物流必填信息！！');
                                        return false;
                                    } else {
                                        if (!that.express.ShipperCode || !that.express.ShipperName) {
                                            return that.$message.error('请选择物流公司！');
                                        }
                                        // 提交数据
                                        that.express.Receiver_str = JSON.stringify(that.express.Receiver);
                                        that.express.Sender_str = JSON.stringify(that.express.Sender);
                                        that.express.Commodity_str = JSON.stringify(that.express.Commodity);
                                        const loading = this.$loading({
                                            lock: true,
                                            text: '正在拉取快递单~',
                                            spinner: 'el-icon-loading',
                                            background: 'rgba(183, 183, 183, 0.57)'
                                        });
                                        that.$http.post(that.$api + '/admin/printer/addOrder', that.express)
                                            .then( res => {
                                                if (res.data.errno === 0) {
                                                    loading.close();
                                                    this.$message.success('运单获取成功！');
                                                    /**
                                                     * 每次发货成功后都需要重置快递公司，防止惯性操作用错快递公司
                                                     * **/
                                                    this.express.ShipperCode = this.express.ShipperName = '';
                                                    this.loadUnExpressOrder(this.UnExpressOrderData.currentPage, this.UnExpressOrderData.pageSize);
                                                    this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                                                    // 关闭发货窗口
                                                    this.addExpress = !this.addExpress;
                                                    // 打开打印窗口
                                                    // this.$alert('运单已生成，是否立即打印运单？', '打印提醒', {
                                                    //     confirmButtonText: '打印',
                                                    //     callback: action => {
                                                    //         printJS('express_print', 'html');
                                                    //         that.EXPRESS_HTML = ''
                                                    //     }
                                                    // });
                                                } else {
                                                    loading.close();
                                                    this.$message.error(res.data.errmsg);
                                                }
                                            }).catch (err => {
                                            loading.close();
                                        })
                                    }
                                });
                            }
                        });
                    }
                });
            },
            // 变更快递
            changeExpressCode (val) {
                let that =this;
                this.expressCompany.map(function (item) {
                    if (item.value === val) {
                        that.express.ShipperName = item.label
                    }
                })
            },
            async getDeliveryBill (DATA) {
                const loading = this.$loading({
                    lock: true,
                    text: '运单已存在，拉取运单中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                await this.$http.post(this.$api + '/admin/printer/getOrder',  {
                    order_id: DATA.order_sn,
                    openid: DATA.OPENID,
                    delivery_id: DATA.ShipperCode,
                    waybill_id: DATA.LogisticCode
                })
                    .then( res => {
                        this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                        // 打开打印窗口
                        // setTimeout (function () {
                        //     printJS('express_print', 'html');
                        //     loading.close()
                        // }, 1000)
                    }).catch(err => {
                        loading.close()
                    })
            },
            async addExpressFUN_AUTO_EXPRESS (item) {
                    if (!item.id) {
                        alert('订单ID有误，请重新选择！');
                        return
                    }
                    /**
                     * 订单已发货，则无需再生成运单号
                     * **/
                    if (item.order_status >= 300) {
                        // don't need to add new Delivery
                        return await this.getDeliveryBill(item)
                    }
                    this.express.OrderId = item.id;
                    // this.express.openid = item.OPENID;
                    this.express.ITEM = item;
                    this.express.goodsList = item.goodList;
                    this.express.Receiver.Name = item.consignee;
                    this.express.Receiver.Tel = item.tel;
                    this.express.Receiver.Mobile = item.mobile;
                    this.express.Receiver.ProvinceName = item.ProvinceName;
                    this.express.Receiver.CityName = item.CityName;
                    this.express.Receiver.ExpAreaName = item.DistrictName;
                    this.express.Receiver.Address = item.address;
                    this.express.Receiver.FullRegion = item.ProvinceName + ' ' + item.CityName + ' ' + item.DistrictName;
                    this.express.Remark = this.express.Remark + item.goodList.map(function (item) {
                        return item.goods_specifition_name_value
                    }).join(',');

                    this.addExpress = !this.addExpress
            },
            gotoStorageAlert(e) {
              console.log(e)
              this.$router.push('/storageAlertList')
            },
            getAdminMsg () {
                this.$http.post(this.$api + '/admin/messageHub/getAllAdminMsgCount')
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.adminMsg.storageAlert = res.data.data
                        }
                    })
            },
            // 获取面单
            getQuota () {
                this.$http.post(this.$api + '/admin/printer/getQuota')
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.quota = res.data.data
                        }
                    })
            },
            // 发货
            addExpressFUN (data) {
                this.is_adding_express = true
                bus.$emit('showAddExpressDialog', data);
            },
            // 总计
            getSummaries(param) {
                //console.log(param)
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计（元）';
                        return;
                    } else if (index === 1) {
                        sums[index] = 0;
                        for (let item in data) {
                            sums[index] += data[item].number
                        }
                        return;
                    } else if (index === 2) {
                        sums[index] = '-';
                        return;
                    } else if (index === 3) {
                        sums[index] = 0;
                        for (let item in data) {
                            sums[index] += data[item].retail_price * data[item].number
                        }
                        return;
                    }
                });

                return sums;
            },
            // 加载用户分析数据
            loadUserAnalysis () {
                this.$http({
                    url: this.$api + '/admin/analysis/userStatistics',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno == 0) {
                        let arr = [];
                        arr.push('小程序用户走势');
                        arr.push(['totalNumber','用户总数']);
                        this.userAnalysisData = arr.concat(res.data.data)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            // 加载近三个月数据
            loadOrderAnalysisData () {
                // 六个月前的时间戳
                let start = new Date().getTime() - 3600 * 1000 * 24 * 30 * 6;
                this.$http({
                    url: this.$api + '/admin/analysis/priceStatistics',
                    method: 'post',
                    data: this.$qs.stringify({
                        startTime: start
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        let arr = [];
                        arr.push('近6个月销售情况');
                        arr.push(['TotalPrice','销售金额']);
                        this.chartsData = arr.concat(res.data.data)
                    } else {
                         this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            loadPushData () {
                this.$http({
                    url: this.$api + '/admin/index/statistics',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno == 0) {
                       this.statisticsData = res.data.data
                    } else {
                         this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {
                    this.$message.error(response)
                })
            },
            loadNewOrder (page, size) {
                this.$http({
                    url: this.$api + '/admin/order/todayOrder',
                    method: 'post',
                    data: {
                        page: page || this.newOrderData.currentPage || 1,
                        size: size || this.newOrderData.pageSize || 10
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.newOrderData = res.data.data
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {
                    this.$message.error(response)
                })
            },
            async loadUnExpressOrder (page, size) {
                await this.$http({
                    url: this.$api + '/admin/order/unExpressOrder',
                    method: 'post',
                    data: {
                        page: page || this.UnExpressOrderData.currentPage,
                        size: size || this.UnExpressOrderData.pageSize
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.UnExpressOrderData = res.data.data
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {
                    this.$message.error(response)
                })
            },
            loadEveryMothSaleTotal () {
                this.$http({
                    url: this.$api + '/admin/analysis/everyMothSaleTotalPrice',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno == 0) {
                        let arr = [];
                        arr.push('各个自然月销量');
                        arr.push(['totalMoney','本月销售额']);
                        for (let item in res.data.data[0]) {
                            arr.push({
                                month: item,
                                totalMoney: res.data.data[0][item]
                            })
                        }
                        this.everyMothSaleTotalPrice = arr;
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {
                    this.$message.error(response)
                })
            }
        }
    }

</script>


<style scoped>
    .remarks{
        padding: 10px;
        border: 1px solid #eee;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        width: 100%;
        display: block;
        max-height: 110px;
    }
    .el-row {
        margin-bottom: 20px;
    }

    .grid-content {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .grid-cont-right {
        flex: 1;
        text-align: center;
        font-size: 12px;
        color: #999;
    }

    .grid-num {
        font-size: 30px;
        font-weight: bold;
    }

    .grid-num_mobile {
        font-size: 25px !important;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 50px;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }
    .grid-con-icon_mobile {
        font-size: 15px !important;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }
    .grid-con-1 .grid-con-icon {
        background: rgb(45, 140, 240);
    }

    .grid-con-1 .grid-num, .UA_MOBILE_1 .grid-num_mobile {
        color: rgb(45, 140, 240);
    }

    .grid-con-2 .grid-con-icon {
        background: rgb(100, 213, 114);
    }

    .grid-con-2 .grid-num, .UA_MOBILE_2 .grid-num_mobile {
        color: rgb(45, 140, 240);
    }

    .grid-con-3 .grid-con-icon {
        background: rgb(242, 94, 67);
    }

    .grid-con-3 .grid-num, .UA_MOBILE_3 .grid-num_mobile {
        color: rgb(242, 94, 67);
    }
    .grid-con-4 .grid-con-icon {
        background: #429493;
    }

    .grid-con-4 .grid-num, .UA_MOBILE_4 .grid-num_mobile {
        color: #429493;
    }
    .grid-con-5 .grid-con-icon {
        background: #e08724;
    }

    .grid-con-5 .grid-num, .UA_MOBILE_5 .grid-num_mobile {
        color: #e08724;
    }

    .grid-con-6 .grid-num, .UA_MOBILE_6 .grid-num_mobile {
        color: green;
    }

    .user-info {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 2px solid #ccc;
        margin-bottom: 20px;
    }

    .user-avator {
        max-width: 120px;
        max-height: 120px;
        border-radius: 50%;
    }

    .user-info-cont {
        padding-left: 15px;
        flex: 1;
        font-size: 14px;
        color: #999;
    }

    .user-info-cont div:first-child {
        font-size: 30px;
        color: #222;
    }

    .user-info-list {
        font-size: 14px;
        color: #999;
        line-height: 25px;
    }

    .user-info-list span {
        margin-left: 70px;
    }

    .mgb20 {
        margin-bottom: 20px;
    }

    .todo-item {
        font-size: 14px;
    }

    .todo-item-del {
        text-decoration: line-through;
        color: #999;
    }
    .toduTitle:hover{
        cursor: pointer;
        color: #429493;
    }
</style>
