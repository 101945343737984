<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}个商品）</label>
        </div>
        <div class="button-tool">
            <div>
                <el-button type='primary' @click="$router.push('/goodsList')">新建促销活动(从商品列表新建)</el-button>
                <!--<el-button type='primary' @click="$router.push('/newspaper?ct=2')">新增公众号</el-button>-->
                <!--<el-button type='success' @click="editPaperType_btn">编辑分类</el-button>-->
            </div>

            <div style="display: flex">
                <el-radio-group v-model="contentType" style="margin-right: 20px;margin-top: 10px"
                                @change="handleContentType">
                    <el-radio v-for="item in contentTypes" :label="item.value" :name="item.name" :key="item.value">
                        {{item.name}}
                    </el-radio>
                </el-radio-group>
                <el-input placeholder="请输入商品名查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 500px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>
        <div class="tagSwitch disflexct">
            <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 100%">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table
            :data="tableData.data"

            style="width: 100%">
            <el-table-column label="商品id" width="100" prop="goods_id"></el-table-column>
            <el-table-column
                label="秒杀主图"
                width="150">
                <template slot-scope="scope">
                    <img class="width80px" style="height: 80px;" :src="scope.row.list_pic_url" alt="">
                </template>
            </el-table-column>
            <el-table-column
                label="秒杀标题"
                width="150">
                <template slot-scope="scope">
                    <span >{{ scope.row.title }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="活动标题"
                width="150">
                <template slot-scope="scope">
                    <span >{{ scope.row.promotion_title }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="秒杀规格"
                width="200">
                <template slot-scope="scope">
                    <span >{{ scope.row.sku }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="秒杀价格"
                width="200">
                <template slot-scope="scope">
                    <p >日常售价：￥{{ scope.row.spike_origin_price_format }}</p>
                    <p style="color: #eb3021;font-weight: bold;font-size: 15px;">秒杀价格：￥{{ scope.row.spike_price_format }}</p>
                </template>
            </el-table-column>
            <el-table-column
                label="秒杀时间"
                width="150">
                <template slot-scope="scope">
                    <p >开始时间：{{ scope.row.start_time_format }}</p>
                    <p >结束时间：{{ scope.row.end_time_format }}</p>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px;
     align-items: flex-end;">
                        <!-- 编辑 -->
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                           @click="gotoGoodsDetailEdit(scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    export default {
        name: 'promotionList',
        data: function () {
            return {
                activeName: '全部',
                addPaperType_bool: false,
                // loading: true,
                tableData: [],
                tagSwitchData: [],
                contentType: '',
                contentTypes: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                searchState: '',
                paperStatus: '未上线',
                paperId: '',
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                is_edit: false
            }
        },
        components: {
        },
        watch: {
            '$route' (to, from) {
                if (to.path === '/goodslist') {
                    if (to.query.page) {
                        this.getPaperList(0, to.query.page, 10)
                    }
                }
            }
        },
        created() {
            this.getPaperList(0, this.$route.query.page || 1, 10)
        },
        mounted() {
        },
        methods: {
            gotoGoodsDetailEdit (row) {
                let routeUrl = this.$router.resolve({
                    path: "/goodsedit",
                    query: {id:row.goods_id}
                });
                window.open(routeUrl.href, '_blank');
            },
            // 删除商品活动
            deletePromotion(row, promotion) {
                this.$confirm('您正在进行【删除商品促销活动】操作，确认？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/goodsPromotion/deletePromotion',
                        method: 'post',
                        data: this.$qs.stringify({sell_id: row.sell_id})
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success('删除成功！立即生效！');
                            this.getPaperList(this.currentTagId, this.currentPage, this.size)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            //按标题搜索列表
            handleContentType() {
                this.getPaperList(this.currentTagId, 1, this.tableData.pageSize)
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, 1, this.pageSize)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                // this.$router.push({
                //     path: '/goodslist',
                //     query: {
                //         page: val
                //     }
                // })
                this.getPaperList(this.currentTagId, val, this.tableData.pageSize)
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/spikeGoods/list',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 10,
                        name: this.searchState
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                        this.currentPage = response.data.data.currentPage;
                        this.total = response.data.data.totalPages;
                        this.size = response.data.data.pageSize;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            },
            switchTag(tab) {

                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {

                        this.searchState = ''
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10)
                        this.currentTagId = this.tagSwitchData[i].id
                    }
                }
            },
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
