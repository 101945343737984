<template>
    <div>
        <div class="crumbs" v-if="isNotComponents">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}人）</label>
        </div>

        <div class="text-align-right"  v-if="isNotComponents">
            <el-button type="primary" @click="showAddAdminRole">新增用户角色</el-button>
        </div>

        <div class="width100">
            <el-table :data="adminRoleData_computed"
                      v-loading="!adminRoleData_computed" style="width: 100%" @expand-change="roleExpandChange" border highlight-current-row>
                <el-table-column label="角色头像" width="100">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <img :src="scope.row.avatar" class="width60px" alt="">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="角色名称" width="200">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <h4>{{scope.row.role_name}}</h4>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="地址" >
                    <template slot-scope="scope">
                        <span>{{scope.row.role_value}}</span>
                    </template>
                </el-table-column>

                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <div class="text-align-right">
                            <el-button type="primary" @click="showAddParentAPIDialog = !showAddParentAPIDialog">新增一级Root API</el-button>
                        </div>
                        <el-table :height="tableHeight" :data="render_tableData" v-loading="!render_tableData" style="width: 50%"
                                  @selection-change="handleSelectionChange" border highlight-current-row @expand-change="PermitRowExpand">
                                <el-table-column label="Root API">
                                    <template slot-scope="permit_scope">
                                        <div slot="reference" class="name-wrapper">
                                            <h2>{{permit_scope.row.permission_name}}</h2>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="地址">
                                    <template slot-scope="permit_scope">
                                        <span>{{permit_scope.row.permission_addr}}</span>
                                    </template>
                                </el-table-column>

                                <el-table-column type="expand">
                                    <template slot-scope="permit_scope">
                                        <div class="text-align-right">
                                            <el-button type="danger" @click="cancelAllRolePermit(permit_scope.row, permit_scope.row, scope.row)">取消当前所有授权</el-button>
                                            <el-button type="primary" @click="showAddAPIDialogFun(permit_scope.row)">新增接口</el-button>
                                        </div>
                                        <el-table :data="permit_scope.row.childList" border highlight-current-row >
                                            <el-table-column label="接口名称" width="100">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper" >
                                                        <span :class="scope2.row.is_permit? 'permitActive':'permitCancel'" style="margin-left: 10px">{{ scope2.row.permission_name }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column label="接口地址">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper">
                                                        <span :class="scope2.row.is_permit? 'bold15 permitActive':'permitCancel'" style="margin-left: 10px;">{{scope2.row.parent_addr}}/{{ scope2.row.permission_addr }}</span>
                                                    </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column label="操作">
                                                <template slot-scope="scope2">
                                                    <div slot="reference" class="name-wrapper disflex_around_center">
                                                        <el-button v-if="!scope2.row.is_permit" type="primary" @click="addPermitForAdminRole(scope2.row, permit_scope.row, scope.row)">授权</el-button>
                                                        <el-button v-else type="danger">解除授权</el-button>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </template>
                                </el-table-column>

                            </el-table>
                    </template>
                </el-table-column>

            </el-table>


        </div>

        <el-dialog title="新增一级接口分类"
                   :visible.sync="showAddParentAPIDialog"
                   width="50%">
            <div class="padding15">
                <el-input v-model="addParentAPIDATA.permission_addr" placeholder="接口父地址" >
                    <template slot="prepend">Root API地址</template>
                </el-input>
            </div>
            <div class="padding15">
                <el-input v-model="addParentAPIDATA.permission_name"placeholder="请输入接口名称">
                    <template slot="prepend">接口名称</template>
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary"@click="submitParentAPI">新增Root API</el-button>
                <el-button type="default" @click="showAddParentAPIDialog = !showAddParentAPIDialog">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="新增接口"
                   :visible.sync="showAddAPIDialog"
                   width="50%">
            <div class="padding15">
                <el-input v-model="addAPIdATA.parent_name" placeholder="父接口" disabled>
                    <template slot="prepend">父接口</template>
                </el-input>
            </div>
            <div class="padding15">
                <el-input v-model="addAPIdATA.parent_id" placeholder="接口父id" disabled>
                    <template slot="prepend">接口父id</template>
                </el-input>
            </div>
            <div class="padding15">
                <el-input v-model="addAPIdATA.parent_addr" placeholder="接口父地址" disabled>
                    <template slot="prepend">接口父地址</template>
                </el-input>
            </div>
            <div class="padding15">
                <el-input v-model="addAPIdATA.permission_name"placeholder="请输入接口名称">
                    <template slot="prepend">接口名称</template>
                </el-input>
            </div>
            <div class="padding15">
                <el-input v-model="addAPIdATA.permission_addr" placeholder="请输入接口地址">
                    <template slot="prepend">接口地址</template>
                </el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary"@click="submitAddAPI()">提交</el-button>
                <el-button type="default" @click="showAddAPIDialog = !showAddAPIDialog">关 闭</el-button>
            </span>
        </el-dialog>
        <el-dialog title="新增用户角色"
                   :visible.sync="showAddAdminRoleBool"
                   width="50%">
            <div class="padding15">
                <el-input v-model="addRoleData.role_name"placeholder="请输入角色名称">
                    <template slot="prepend">角色名称</template>
                </el-input>
            </div>
            <div class="padding15">
                <el-input v-model="addRoleData.role_value" placeholder="请输入角色value值">
                    <template slot="prepend">角色value值(必须唯一)</template>
                </el-input>
            </div>
            <h2>请上传角色头像</h2>
            <upload-image v-model="addRoleData.avatar" :PHUrl="addRoleData.avatar" :width="250"></upload-image>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary"@click="addNewRole">新增角色</el-button>
                <el-button type="default" @click="showAddAdminRoleBool = !showAddAdminRoleBool">关 闭</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    import bus from '../../common/bus';
    import util from '../../../utils/util';
    import uploadImage from '../../common/uploadImage';

    export default {
        data: function () {
            return {
                activeName: '全部用户',
                // loading: true,
                tableData: [],
                render_tableData: [],
                paperId: '',
                searchTitle: '',
                tableHeight: 600,
                selectUserList: [],
                showAddAPIDialog: false, // 是否显示新增接口窗口
                showAddParentAPIDialog: false, // 新增一级接口
                showAddAdminRoleBool: false, // 是否显示新增角色操作
                addAPIdATA: {
                    parent_addr: '',
                    permission_addr: '',
                    permission_name: '',
                    parent_id: '',
                    parent_name: ''
                },
                addParentAPIDATA: {
                    parent_addr: '',
                    permission_addr: '',
                    permission_name: '',
                    parent_id: 0,
                    parent_name: ''
                },
                addRoleData: {
                    role_name: '',
                    role_value: '',
                    avatar: ''
                },
                selectRootAPIList: [], // 选择的root api 列表
                adminRoleData: [],
                nowPermitList: [], // 当前正在操作的权限列表
                add_permit_role: {}, // 当前授权的对象
                add_permit_id_list: [], // 当前授权的id
                add_permit_name_list: '' , // 当前授权的权限名称
            }
        },
        components: {
            uploadImage
        },
        props: {
          isNotComponents: {
              default: true,
              type: Boolean
          }
        },
        watch: {
            tableData: {
                handler (newv, oldv) {
                    console.log(newv)
                    this.render_tableData = newv
                },
                deep: true
            }
        },
        computed: {
            adminRoleData_computed () {
                return this.adminRoleData
            }
        },
        created() {
            this.getPaperList(0, 1, 10);
            this.getAdminRole();
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
        },
        mounted() {

        },
        methods: {
            // 权限行展开
            PermitRowExpand (level_1_row) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                const childList = level_1_row.childList;
                // 标记已赋予的权限
                this.nowPermitList.forEach(row => {
                    if (row.parent_addr === level_1_row.permission_addr) {
                        for (let item in childList) {
                            for (let param in row.childList) {
                                if (row.childList[param].permission_addr === childList[item].permission_addr) {
                                    childList[item].is_permit = true
                                }
                            }
                        }
                    }
                })
                for (let param in this.tableData) {
                    if (this.tableData[param].permission_addr === level_1_row.permission_addr) {
                        this.tableData[param].childList = childList
                    }
                }

                loading.close()
            },
            // 行展开
            async roleExpandChange (row, expandedRows) {
                this.nowPermitList = [];
                if (!expandedRows[0]) {
                    return
                }
                console.log(row)
                this.add_permit_role = row;
                this.loadRolePermit(row)
            },
            async loadRolePermit (row) {
                /**
                 * 动态加载该角色权限
                 * **/
                await this.$http({
                    url: this.$api + '/admin/adminManagement/getSingleRolePermit',
                    method: 'post',
                    data: {
                        role_value: row.role_value
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.nowPermitList = response.data.data;
                        for (let item in this.tableData) {
                            for (let param in this.nowPermitList) {
                                if (this.tableData[item].permission_addr === this.nowPermitList[param].parent_addr) {
                                    this.nowPermitList[param].parent_name = this.tableData[item].permission_name;
                                }
                            }
                        }
                    } else {
                        this.$message.error('加载权限失败！')
                    }
                })
            },
            // 取消所有授权
            cancelAllRolePermit (row, permit, adminData) {
                // console.log(row)
                // console.log(permit)
                // console.log(adminData)
            },
            // 添加角色权限
            addPermitForAdminRole (row, permit_scope, adminData) {
                /**
                 * 勾选api实时更新权限
                 * **/
                this.$http({
                    url: this.$api + '/admin/adminManagement/addPermitForAdminRole',
                    method: 'post',
                    data: {
                        role_id: row.id,
                        role_value: adminData.role_value,
                        permit_list: [row.id]
                    }
                }).then(async response => {
                    if (response.data.errno === 0) {
                        this.$message.success(`为角色${adminData.role_name}添加权限：${row.permission_name} 成功！`);
                        await this.loadRolePermit(adminData).then(res => {
                            const childList = permit_scope.childList;
                            // 标记已赋予的权限
                            this.nowPermitList.forEach(row_def => {
                                if (row_def.parent_addr === permit_scope.permission_addr) {
                                    for (let item in childList) {
                                        for (let param in row_def.childList) {
                                            if (row_def.childList[param].permission_addr === childList[item].permission_addr) {
                                                childList[item].is_permit = true
                                            }
                                        }
                                    }
                                }
                            })
                            for (let param in this.tableData) {
                                if (this.tableData[param].permission_addr === permit_scope.permission_addr) {
                                    this.tableData[param].childList = childList
                                }
                            }
                        });
                    } else {
                        this.$message.error('分配权限失败！')
                    }
                })
            },
            handleLevel2ApiChange (val) {
                if (val.length === 0) {
                    this.add_permit_role = {};
                    this.add_permit_id_list = [];
                    this.add_permit_name_list = [];
                    return
                }
                this.add_permit_id_list = val.map(function (item) {
                    return item.id
                });
                let permit_name_list = val.map(function (item) {
                    return item.permission_name
                });
                this.add_permit_name_list = permit_name_list.join(',');
            },
            // 用户勾选/取消选项
            handleSelectionChange (val) {
                console.log(val);
                this.selectRootAPIList = val
            },
            // 增加新角色
            addNewRole () {
                this.$http({
                    url: this.$api + '/admin/adminManagement/addAdminRole',
                    method: 'post',
                    data: this.addRoleData
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('新增角色成功！');
                        this.showAddAdminRoleBool = !this.showAddAdminRoleBool
                        this.getPaperList()
                    } else {
                        this.$message.error('新增角色失败！');
                    }
                })
            },
            // showAddAdminRole
            showAddAdminRole () {
                this.showAddAdminRoleBool = !this.showAddAdminRoleBool
            },
            // 删除接口
            deleteApi (data) {
                this.$alert('您正在删除api接口，删除后将会影响用户相应权限，请确认！', {
                    confirmButtonText: '确认删除',
                    showCancelButton: true,
                    cancelButtonText: '取消',
                    callback: (action, instance, done) => {
                        if (action === 'confirm') {
                            this.$http({
                                url: this.$api + '/admin/adminManagement/deletePermission',
                                method: 'post',
                                data: {
                                    id: data.id
                                }
                            }).then(response => {
                                if (response.data.errno === 0) {
                                    this.$message.success('删除api接口成功！');
                                    this.getPaperList()
                                } else {
                                    this.$message.error('删除失败！');
                                }
                            })
                        } else {
                            this.$message.success('操作取消！');
                        }
                    }
                })
            },
            // 提交
            submitAddAPI (item) {
                if (!this.addAPIdATA.permission_name || !this.addAPIdATA.permission_addr) {
                    this.$message.error('请完善参数后提交！')
                }
                this.$http({
                    url: this.$api + '/admin/adminManagement/addPermission',
                    method: 'post',
                    data: this.addAPIdATA
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('新增权限成功！');
                        this.showAddAPIDialog = !this.showAddAPIDialog;
                        this.getPaperList()
                    }
                })
            },            // 提交
            submitParentAPI (item) {
                if (!this.addAPIdATA.permission_name || !this.addAPIdATA.permission_addr) {
                    this.$message.error('请完善参数后提交！')
                }
                this.$http({
                    url: this.$api + '/admin/adminManagement/addRootPermission',
                    method: 'post',
                    data: this.addParentAPIDATA
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('新增权限成功！');
                        this.showAddParentAPIDialog = !this.showAddParentAPIDialog;
                        this.getAdminRole()
                    }
                })
            },
            // 新增接口
            showAddAPIDialogFun (item) {
                this.showAddAPIDialog = !this.showAddAPIDialog;
                this.addAPIdATA.parent_id = item.id;
                this.addAPIdATA.parent_addr = item.permission_addr;
                this.addAPIdATA.parent_name = item.permission_name;
            },
            handleSearchByTitle() {
                if (this.activeName === '黑名单') {
                    this.getPaperList(1, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            handleSizeChange(val) {

                if (this.activeName === '黑名单') {
                    this.getPaperList(1, this.tableData.currentPage || 1, val, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, val, this.searchTitle)
                }
            },
            handleCurrentChange(val) {
                if (this.activeName === '黑名单') {
                    this.getPaperList(1, val, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, val, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            getPaperList(is_limit, page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });

                this.$http({
                    url: this.$api + '/admin/adminManagement/Level1PermissionList',
                    method: 'post',
                    data: this.$qs.stringify({
                        name: title,
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            },
            getAdminRole() {
                this.$http({
                    url: this.$api + '/admin/adminManagement/adminRoleList',
                    method: 'post'
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.adminRoleData = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                })
            }
        }
    }
</script>
<style scoped>
    .permitActive {
        color: #09bb07 !important;
        font-weight: bold;
    }
    .permitCancel {
        color: #eb3021 !important;
    }
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .pointer {
        cursor: pointer;
    }
</style>

