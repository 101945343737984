<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}个商品）</label>
            <!--<el-breadcrumb separator="/">-->
            <!--<el-breadcrumb-item><i class="el-icon-date"></i>商品管理</el-breadcrumb-item>-->
            <!--<el-breadcrumb-item>商品列表</el-breadcrumb-item>-->
            <!--</el-breadcrumb>-->

        </div>
        <div class="button-tool">
            <div>
                <el-button type='primary' @click="$router.push('/editPromotion')">新增商品促销活动</el-button>
                <!--<el-button type='primary' @click="$router.push('/newspaper?ct=2')">新增公众号</el-button>-->
                <!--<el-button type='success' @click="editPaperType_btn">编辑分类</el-button>-->
            </div>

            <div style="display: flex">
                <el-radio-group v-model="contentType" style="margin-right: 20px;margin-top: 10px"
                                @change="handleContentType">
                    <el-radio v-for="item in contentTypes" :label="item.value" :name="item.name" :key="item.value">
                        {{item.name}}
                    </el-radio>
                </el-radio-group>
                <el-input placeholder="请输入商品名查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 500px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>
        <div class="tagSwitch disflexct">
            <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 100%">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table
            :data="tableData.data"

            style="width: 100%">
            <el-table-column label="商品id" width="80" prop="id"></el-table-column>
            <el-table-column label="商品名称">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                        <p style="margin-left: 10px">{{ scope.row.name }}</p>
                        <div class="border_eee padding15 font-size-13">
                            <h3>参加活动的规格</h3>
                            <div
                                class="positionRE"
                                v-for="(item, promotionIndex) in scope.row.promotionList" :key="item.id"
                                 :style="promotionIndex%2===0? 'background: #d6e9c6':'background: #fcf0e6'">
                                <div
                                    v-for="(promotion) in item.goods_specification"
                                    :key="promotion.goods_id"
                                    class="font-size-15"
                                    style="padding: 5px 8px;"
                                >
                                    <p>{{promotion.keyName}}: {{promotion.valueName}} <span class="color_eb3021 font-size-15 fontWeight">促销价：{{item.sell_coupon_price}}</span></p>
                                    <p class="positionAB cursor_pointer" style="top: 50%;transform: translateY(-50%);right: 10px;">
                                        <span style="color: #eeeeee;">|</span>
                                        <i class="el-icon-close color_eb3021" @click="deletePromotion(item, promotion)"></i>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </el-table-column>
            <!--<el-table-column-->
                <!--label="标签"-->
                <!--width="80">-->
                <!--<template slot-scope="scope">-->
                    <!--<div slot="reference" class="name-wrapper">-->
                        <!--<el-tag size="medium">{{ scope.row.promotion_desc }}</el-tag>-->
                    <!--</div>-->
                <!--</template>-->
            <!--</el-table-column>-->
            <el-table-column
                label="库存"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span style="margin-left: 10px">{{ scope.row.goods_number }} {{scope.row.goods_unit}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="销售量"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span style="margin-left: 10px">{{ scope.row.sell_volume }} {{scope.row.goods_unit}}</span>
                </template>
            </el-table-column>
            <el-table-column label="零售价" width="80" prop="retail_price"></el-table-column>
            <el-table-column label="商品编码" width="80" prop="goods_sn"></el-table-column>
            <el-table-column label="关键字" width="80" prop="keywords"></el-table-column>

            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px;
     align-items: flex-end;">
                        <!-- 编辑 -->
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                           @click="editPaper(scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'
    import clearCacheTool from '../../common/clearCacheTool'

    export default {
        name: 'promotionList',
        data: function () {
            return {
                activeName: '全部',
                addPaperType_bool: false,
                // loading: true,
                tableData: [],
                tagSwitchData: [],
                contentType: '',
                contentTypes: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                searchState: '',
                paperStatus: '未上线',
                paperId: '',
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                is_edit: false
            }
        },
        components: {
            commonlist,
            clearCacheTool
        },
        watch: {
            '$route' (to, from) {
                if (to.path === '/goodslist') {
                    if (to.query.page) {
                        this.getPaperList(0, to.query.page, 10)
                    }
                }
            }
        },
        created() {
            this.getPaperList(0, this.$route.query.page || 1, 10)
            // this.loadPaperType()
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
            this.contentTypes = this.$getDicByName('CONTENT_TYPE');
        },
        mounted() {
            bus.$on('reloadPaper', res => {
                if (res) {
                    this.getPaperList(this.currentTagId, this.currentPage, this.size)
                }
            })
        },
        methods: {
            editPaper (row) {
                this.$router.push(`/editPromotion?goods_id=${row.id}`)
            },
            // 删除商品活动
            deletePromotion(row, promotion) {
                this.$confirm('您正在进行【删除商品促销活动】操作，确认？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/goodsPromotion/deletePromotion',
                        method: 'post',
                        data: this.$qs.stringify({sell_id: row.sell_id})
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success('删除成功！立即生效！');
                            this.getPaperList(this.currentTagId, this.currentPage, this.size)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            //按标题搜索列表
            handleContentType() {
                this.getPaperList(this.currentTagId, 1, this.tableData.pageSize)
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, 1, this.pageSize)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                // this.$router.push({
                //     path: '/goodslist',
                //     query: {
                //         page: val
                //     }
                // })
                this.getPaperList(this.currentTagId, val, this.tableData.pageSize)
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/goodsPromotion/goodsPromotionList',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 10,
                        name: this.searchState
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                        this.currentPage = response.data.data.currentPage;
                        this.total = response.data.data.totalPages;
                        this.size = response.data.data.pageSize;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            },
            switchTag(tab) {

                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {

                        this.searchState = ''
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10)
                        this.currentTagId = this.tagSwitchData[i].id
                    }
                }
            },
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
