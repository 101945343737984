<template>
    <el-dialog :visible.sync="showForm" :title="titleName" :before-close="beforeClose">
        <div class="button-tool">
            <div style="display: flex">
                <el-input style="width: 500px" placeholder="搜索用户名" v-model="searchTitle"
                          @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
            <div>
                <el-button type='primary' @click="exportInvite">导出列表</el-button>
            </div>
        </div>
        <div id="crumbs"></div>
        <el-table :data="tableData" border class="text-centre my-table"
                  header-row-class-name=""
                  :span-method="objectSpanMethod"
                  style="width: 100%">

            <el-table-column label="用户名">
                <template slot-scope="scope">
                    <div>
                        <img :src="scope.row.heads" style="width: 20px;vertical-align: middle;margin-right: 10px">
                        <span>{{ scope.row.applyUserName === null ? 0 : scope.row.applyUserName }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="真实姓名" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.realName === null ? '' : scope.row.realName }}</span>
                </template>
            </el-table-column>

            <el-table-column label="房型" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.roomType === 1 ? '大床房' : scope.row.roomType === 2 ? '标准间' : '未知类型' }}</span>
                </template>
            </el-table-column>

            <el-table-column label="入住时间" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.checkInTime === null ? '' : scope.row.checkInTime }}</span>
                </template>
            </el-table-column>

            <el-table-column label="退房时间" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.checkOutTime === null ? '' : scope.row.checkOutTime }}</span>
                </template>
            </el-table-column>

            <el-table-column label="天数" width="150">
                <template slot-scope="scope">
                    <span>共 {{ scope.row.checkInDays === null ? 0 : scope.row.checkInDays }} 晚</span>
                </template>
            </el-table-column>
        </el-table>
        <span style="float: right;margin: 10px">剩余房间：大床房{{this.bigRoomAvailableNum}}间，标准间{{this.standardRoomAvailableNum}}间</span>
        <div class="paginaion" style="text-align: center;margin-top: 3em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        data: function () {
            return {
                showForm: false,
                titleName: '住宿详情',
                invitationType: [],
                tableData: [],
                activityId: '',
                applyUserName: '',

                pos: 0,
                spanArr: [],

                activeName: '',
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 10,
                status: 0,
                currentTagId: '',

                showCommon: false,
                commonData: '',
                bigRoomAvailableNum: '',
                standardRoomAvailableNum: '',
                tableHeight: 600,
                searchTitle: ''
            }
        },
        props: ['hotelData'],
        watch: {
            hotelData: function (data) {
                this.showForm = true
                this.activityId = data.id

                this.tableData = data.records
                this.currentPage = data.current
                this.total = data.total
                this.size = data.size
                this.getAvailableHotel(this.activityId)
                this.getSpanArr(this.tableData)
            }
        },
        created() {
            this.invitationType = this.$getDicByName('INVITATION_TYPE')
        },
        mounted() {
        },
        methods: {
            getAvailableHotel(activityId) {
                this.$http({
                    url: this.$api + '/chigua/admin/activity/hotel/info/get',
                    method: 'post',
                    data: this.$qs.stringify({
                        activityId: activityId
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.bigRoomAvailableNum = response.data.data.bigRoomAvailableNum
                        this.standardRoomAvailableNum = response.data.data.standardRoomAvailableNum
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                })

            },
            beforeClose(done) {
                this.showForm = false
                done()
            },
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0 || columnIndex === 1) {
                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return {
                        rowspan: _row,
                        colspan: _col
                    }
                }
            },
            getSpanArr(data) {
                this.spanArr = []
                this.pos = 0
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1);
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].applyId === data[i - 1].applyId) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }
            },
            handleSearchByState() {
                this.getList('', this.activityId, this.currentPage, this.size)
            }
            ,
            handleSearchByTitle() {
                this.getList(this.searchTitle, this.activityId, this.currentPage, this.size)

            }
            ,
            handleSizeChange(val) {
                this.getList(this.searchTitle, this.activityId, this.currentPage, val)
            }
            ,
            handleCurrentChange(val) {
                this.getList(this.searchTitle, this.activityId, val, this.size)
            }
            ,
            getList(title, activityId, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/chigua/admin/activity/info/get/accommodation',
                    method: 'post',
                    data: this.$qs.stringify({
                        activityId: activityId,
                        userName: title,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size

                        this.getSpanArr(this.tableData)
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                    loading.close()
                })
            },
            exportInvite() {

                this.$export({
                    url: this.$api + '/chigua/admin/activity/info/export/accommodation',
                    data: {
                        searchUserName: this.searchTitle,
                        activityId: this.activityId
                    }
                });

            }
        }
    }
</script>
<style>


    .my-table.el-table th {
        background: rgba(197, 145, 234, 0.3) !important;
        text-align: center;
    }

    .el-radio + .el-radio {
        margin-left: 10px;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .text-centre {
        text-align: center;
    }

    .pointer {
        cursor: pointer;
    }

    .el-dialog__body {
        padding-top: 0px;
    }
</style>
