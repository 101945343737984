<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}个机器人）</label>
        </div>
        <div class="button-tool">
            <div>
                <el-button type='primary' @click="addRobot">添加机器人</el-button>
            </div>
        </div>
        <el-table
            :data="tableData.data"
            style="width: 100%">
            <el-table-column label="用户ID" width="100" prop="id"></el-table-column>
            <el-table-column label="用户昵称" width="300">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.avatar" style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.nickname }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="已评价数量" width="100" >
                <template slot-scope="scope">
                    <p style="text-align: center">{{ scope.row.evaluateNumber }}</p>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <el-dialog
            title="添加机器人"
            :visible.sync="isShowEvaluateDialog"
            width="50%">
                <el-input placeholder="用户昵称" v-model="userData.nickname" style="margin-bottom: 1em;">
                    <template slot="prepend">用户昵称</template>
                </el-input>
                <el-input placeholder="用户头像" v-model="userData.avatar">
                    <template slot="prepend">用户头像</template>
                </el-input>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="saveRobot" type="primary">添加机器人</el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'
    import clearCacheTool from '../../common/clearCacheTool'

    export default {
        data: function () {
            return {
                tableData: [],
                contentType: '',
                contentTypes: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                searchState: '',
                paperStatus: '未上线',
                isShowEvaluateDialog: false, // 是否显示添加评价窗口
                evaluateGoods: {}, //当前正在评价的商品
                userData: {}
            }
        },
        components: {
            commonlist,
            clearCacheTool
        },
        watch: {},
        created() {
            this.getPaperList(0, 1, 10)
            // this.loadPaperType()
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
        },
        mounted() {
            bus.$on('reloadPaper', res => {
                if (res) {
                    //console.log(res);
                    this.getPaperList(this.currentTagId, this.currentPage, this.size)
                }
            })
        },
        methods: {
            // 保存机器人
            async saveRobot () {
                const loading = this.$loading({
                    lock: true,
                    text: '正在添加~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                await this.$http({
                    url: this.$api + '/admin/user/addRobot',
                    method: 'post',
                    data: this.$qs.stringify(this.userData)
                }).then(response => {
                    if (response.data.data) {
                        this.userData = {}
                        this.$message.success('添加成功！')
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                    loading.close()
                }).catch(err => {
                    loading.close()
                })
            },
            // 添加评价点击
            addRobot (item) {
                this.isShowEvaluateDialog = !this.isShowEvaluateDialog
                // this.evaluateGoods = item
            },
            //按标题搜索列表
            handleContentType() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size)
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size)
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/user/getAllRobot',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 20,
                        name: this.searchState
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
