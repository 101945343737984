<template>
    <div class="previewBox">
        <div :class="isCenter? 'previewBoxContanier sreenCenter':'previewBoxContanier'">
            <div style="width: 100%;display: flex;justify-content: center;align-items: center;padding: 15px;box-sizing: border-box;">
                <div class="camera"></div>
                <div class="sound"></div>
            </div>
            <div class="previewContentBox">
                <div class="block">
                    <el-carousel height="250px">
                        <el-carousel-item v-for="item in data.gallery" :key="item.id">
                            <img :src="item.img_url" alt="">
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <h2>{{data.name}}</h2>
                <p style="color: #666666;font-size: 12px;padding: 15px;box-sizing: border-box">{{data.goods_brief}}</p>
                <div style="text-align: center;position: fixed;top: 40px;left: 15px;">
                    <img class="iphoneStatusBar" src="https://oss.mxclass.com/cathouse_pro/icon/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20181204204728.png" alt="">
                </div>
                <div class="previewContentBox_html" v-html="data.goods_desc"></div>
            </div>
            <div class="homeMenu" @click.stop="changePreviewStatus"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "goodsDetailPreview",
        data () {
            return {
                showPreviewPhone: false, // 显示预览
            }
        },
        props: {
            data: {
                type: Object,
                default: ''
            },
            value: {
                type: Boolean,
                default: false
            },
            isCenter: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            windowWidth() {
                return document.body.clientWidth
            },
            isShowPhonePreview () {
                return this.value
            }
        },
        methods: {
            changePreviewStatus () {
                this.$emit('input', !this.isShowPhonePreview)
            }
        }
    }
</script>

<style scoped>
    .iphoneStatusBar{
        width: 320px;
        margin: 0 auto;
        height: 20px;
        vertical-align: top;
    }
</style>
