
<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ total }}篇）</label>
        </div>
        <div class="button-tool">
            <div>
                <el-button type='primary' @click="$router.push('/newsactivity')">新增活动</el-button>
            </div>

            <div style="display: flex">
                <el-radio-group v-model="status" style="margin-right: 20px;margin-top: 10px"
                                @change="handleSearchByState">
                    <el-radio v-for="item in activityStatus" :label="item.value" :name="item.name" :key="item.value">
                        {{item.name}}
                    </el-radio>
                </el-radio-group>

                <el-input placeholder="请输入标题查询" v-model="searchTitle" @keyup.enter.native="handleSearchByTitle"
                          style="width: 500px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>

        <el-table :data="tableData"  style="width: 100%">
            <el-table-column label="标题">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                        <!--<span style="margin-left: 10px" title="活动简介"-->
                        <!--@click="getActivityDetail(scope.$index, scope.row)">{{ scope.row.title }}</span>-->
                        <el-button style="margin-left: 10px"
                                   title="活动简介"
                                   @click="getActivityDetail(scope.$index, scope.row)"
                                   type="text"
                                   size="small">
                            {{ scope.row.title }}
                        </el-button>

                    </div>
                </template>
            </el-table-column>

            <el-table-column label="活动报名时间" width="200">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span>{{ formatStrTime(scope.row.applyStartTime,10) }}</span>
                    <span style="margin-left: 5px;margin-right: 5px">至</span>
                    <span>{{ formatStrTime(scope.row.applyEndTime,10) }}</span>
                </template>
            </el-table-column>

            <el-table-column label="活动定价（￥）" width="150">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.price }}</span>
                </template>
            </el-table-column>

            <el-table-column label="状态" width="120">
                <template slot-scope="scope">
                    <span :style="scope.row.activityStatus===2?'color: red':''" v-for="item in activityStatus"
                          v-if="scope.row.activityStatus==item.value">{{item.name}}</span>
                </template>
            </el-table-column>

            <el-table-column label="报名人数" width="80">
                <template slot-scope="scope">
                    <!--<span style="margin-left: 10px"-->
                    <!--title="报名详情" @click="getApplyDetail(scope.$index, scope.row)">{{ scope.row.applyNum === null? 0:scope.row.applyNum }}</span>-->

                    <el-button style="margin-left: 10px"
                               title="报名详情"
                               @click="getApplyDetail(scope.$index, scope.row)"
                               type="text"
                               size="small">
                        {{ scope.row.applyNum === null? 0:scope.row.applyNum }}
                    </el-button>

                </template>
            </el-table-column>

            <el-table-column label="创建日期" width="150">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.createTime === null? 0:scope.row.createTime }}</span>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="160px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:25px; align-items: flex-end;">
                        <i title="编辑活动" class="el-icon-czd-bianji ft25 pointer"
                           @click="editPaper(scope.$index, scope.row)"></i>
                        <i title="住宿信息" class="el-icon-czd-shouye ft25 pointer"
                           @click="getHotel(scope.$index, scope.row)"></i>
                        <i title="邀请详情" class="el-icon-czd-huodong ft25 pointer"
                           @click="inviteDetail(scope.$index, scope.row)"></i>
                        <el-popover class="ft25" placement="right" trigger="click" @show="showErWeiMa(scope.row.id)">
                            <a :download="scope.row.title" :href="qrUrl">
                                <div>
                                    <el-button>下载二维码</el-button>
                                </div>
                                <img style="width: 200px;height: 200px" :src="qrUrl"/>
                            </a>
                            <i slot="reference" title="二维码" class="el-icon-czd-erweima ft25 pointer"
                               @click=""></i>
                        </el-popover>
                        <i title="删除" class="el-icon-delete ft25 red pointer"
                           @click="deletePaper(scope.$index, scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>

        <!--活动简介-->
        <el-dialog :visible.sync="showActivityDetail" width="50%">
            <el-row :gutter="20">
                <el-col :span="6" style="padding-right: 10px">
                    <div class="headimg">
                        <img width="100%" :src="activityDetail.coverPicture? activityDetail.coverPicture : ''">
                    </div>
                </el-col>
                <el-col :span="16">
                    <el-row :gutter="20">
                        <div class="papername"><span class="a7Text">活动标题：</span>{{ activityDetail.title?
                            activityDetail.title : ''}}
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <div class="papername"><span class="a7Text">活动时间：</span>
                            {{ activityDetail.startTime?activityDetail.startTime.substring(0,16) : '' }}
                            <label>至</label>
                            {{ activityDetail.endTime?activityDetail.endTime.substring(0,16) : ''
                            }}（共{{this.activityDetail.diffTime}}天）
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <div class="papername"><span class="a7Text">报名时间：</span>
                            {{ activityDetail.applyStartTime?activityDetail.applyStartTime.substring(0,16) : '' }}
                            <label>至</label>
                            {{ activityDetail.applyEndTime?activityDetail.applyEndTime.substring(0,16) : '' }}
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <div class="paperauthor">
                            <span class="a7Text">活动状态：</span>
                            <span :style="activityDetail.status===2?'color: red':''" v-for="item in activityStatus"
                                  v-if="activityDetail.status==item.value">{{item.name}}</span>
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <div class="papertime">
                            <span class="a7Text">活动地点： </span>
                            {{ activityDetail.activityAddress?activityDetail.activityAddress : ''}}
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <div class="papertime">
                            <span class="a7Text">活动定价： </span>
                            {{ activityDetail.price?activityDetail.price : ''}}
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <div class="papertime">
                            <span class="a7Text">邀请奖励：</span>
                            <span v-if="activityDetail.awardPrice&&activityDetail.awardPrice!==0">
                            成功邀请用户奖励{{ activityDetail.awardPrice?activityDetail.awardPrice : ''}}元/人
                            </span>
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <div class="papertime">
                            <span class="a7Text">优惠设置：</span>
                            <span v-if="activityDetail.groupNum&&activityDetail.groupNum!==0">
                            用户团购{{activityDetail.groupNum}}张或者以上，则每张自动减免{{activityDetail.reducePrice}}元
                            </span>
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <div class="paperauthor"><span class="a7Text">报名人数：</span>{{ activityDetail.applyNum?
                            activityDetail.applyNum : 0 }}
                        </div>
                    </el-row>

                    <el-row :gutter="20">
                        <el-col :span="3" style="padding: 0px"><span class="a7Text">活动简介：</span></el-col>
                        <el-col :span="20" style="padding: 0px">{{activityDetail.remark?activityDetail.remark : '' }}
                        </el-col>
                    </el-row>

                </el-col>

            </el-row>


        </el-dialog>
        <invite :inviteData="inviteData"></invite>
        <hotel :hotelData="hotelData"></hotel>
    </div>
</template>


<script>
    import bus from '../../common/bus';
    import invite from './invite';
    import hotel from './hotel';

    export default {
        data: function () {
            return {
                activityStatus: [],
                activeName: '',
                qrUrl: '',
                tableData: [],
                activityDetail: [],
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 10,
                status: '',
                currentTagId: '',
                activityId: '',
                tableHeight: 600,
                searchTitle: '',
                inviteData: {},
                hotelData: {},
                showActivityDetail: false,

            }
        },
        components: {
            hotel,
            invite
        },
        watch: {},
        created() {
            // 加载活动列表
            this.getPaperList(this.searchTitle, 0, 1, this.size)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.7
            this.activityStatus = this.$getDicByName('ACTIVITY_STATUS')
        },

        mounted() {
            bus.$on('reloadActivity', res => {
                if (res) {
                    this.getPaperList(this.searchTitle, this.currentTagId, this.currentPage, this.size)
                }
            })
        },
        methods: {
            showErWeiMa(activityId) {
                this.$http({
                    url: this.$api + '/chigua/admin/activity/info/get/qr',
                    method: 'post',
                    data: this.$qs.stringify({
                        activityId: activityId,
                        page: 'pages/index/attachIn'
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.qrUrl = response.data.data
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                })

            },
            inviteDetail(index, row) {
                this.getInviteList(row.id)

                // bus.$emit('inviteChange', row)
            },
            getInviteList(activityId) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/chigua/admin/activity/invite/list/detail',
                    method: 'post',
                    data: this.$qs.stringify({
                        activityId: activityId
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.inviteData = response.data.data
                        this.inviteData.showForm = true
                        this.inviteData.id = activityId
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                    loading.close()
                })
            },
            /**
             * 获取住宿信息
             */
            getHotel(index, row) {
                this.getHotelList(row.id)
            },
            getHotelList(activityId) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/chigua/admin/activity/info/get/accommodation',
                    method: 'post',
                    data: this.$qs.stringify({
                        activityId: activityId
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.hotelData = response.data.data
                        this.hotelData.id = activityId
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                    loading.close()
                })
            },
            editPaper(index, row) {
                this.activityId = row.id
                bus.$emit('activityChange', this.activityId)
                this.$router.push('/activityedit?activityId=' + row.id)
            },
            getActivityDetail(index, row) {
                this.$http({
                    url: this.$api + '/chigua/admin/activity/info/get/summary',
                    method: 'post',
                    data: this.$qs.stringify({
                        activityId: row.id
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.activityDetail = response.data.data
                        let s1 = new Date(this.activityDetail.startTime.substring(0, 10))
                        let s2 = new Date(this.activityDetail.endTime.substring(0, 10))
                        let days = s2.getTime() - s1.getTime();
                        this.activityDetail.diffTime = parseInt(days / (1000 * 60 * 60 * 24)) + 1;
                        this.showActivityDetail = true
                    } else {
                        this.$message.error('菜单加载失败！')
                    }
                    // loading.close()
                })


            },
            getApplyDetail(index, row) {
                this.activityId = row.id
                bus.$emit('activityApplyChange', this.activityId)
                this.$router.push('/activity_apply_detail?activityId=' + row.id)
            },
            // 删除活动
            deletePaper(index, row) {
                this.$confirm('您正在进行【删除活动】操作，确认？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/activity/info/delete',
                        method: 'post',
                        data: this.$qs.stringify({id: row.id})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('活动已删除！')
                                this.getPaperList(this.searchTitle, this.currentTagId, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            handleSearchByState() {
                this.getPaperList('', this.currentTagId, this.currentPage, this.size)
            },
            handleSearchByTitle() {
                this.getPaperList(this.searchTitle, this.currentTagId, this.currentPage, this.size)
            },
            handleSizeChange(val) {
                this.getPaperList(this.searchTitle, this.currentTagId, this.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.searchTitle, this.currentTagId, val, this.size)
            },
            getPaperList(title = '', type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/chigua/admin/activity/info/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        title: title,
                        status: this.status,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size
                    } else {
                        this.$message.error('菜单加载失败！')
                    }
                    loading.close()
                })
            }
            ,
        }
    }
</script>
<style>
    .radio-top {
        margin-top: 10px;
        margin-right: 40px;
    }

    .el-radio + .el-radio {
        margin-left: 10px;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .disflexft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .el-row {
        margin-bottom: 5px;

    &
    :last-child {
        margin-bottom: 0;
    }

    }
    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }
</style>
