<template>
    <div>
        <div class="crumbs" id="formId">
            <label><span>{{ $route.meta.title }}</span>（共{{ total }}人）</label>
        </div>
        <div class="button-tool">
            <div style="display: flex;justify-content: space-between">
                <el-input placeholder="搜索用户名、真实姓名、手机号码" v-model="searchTitle"
                          @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
                <el-select v-model="status" placeholder="发票状态" @change="handleSearchByState" class="apply-margin10">
                    <el-option
                        v-for="item in invoiceStatus"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="signStatus" placeholder="签到状态" @change="handleBySignStatus" class="apply-margin10">
                    <el-option
                        v-for="item in signStatusList"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="isPay" placeholder="支付状态" @change="handleIsPay" class="apply-margin10">
                    <el-option label="未支付" value="0"></el-option>
                    <el-option label="已支付" value="1"></el-option>
                </el-select>
            </div>
            <div class="apply-button-tool">
                <el-upload
                    class="apply-margin10"
                    :action="$api+'chigua/admin/activity/apply/import'"
                    :headers="{ Auth_token }"
                    :show-file-list="false"
                    :data="{activityId:this.activityId}"
                    :on-success="importSuccess"
                >
                    <el-button type="success" plain>导入名单</el-button>
                </el-upload>
                <el-button type='success' plain @click="exportTemplate">导入模板下载</el-button>
                <el-button type='primary' plain @click="getInvoice">发票信息</el-button>
                <el-button type='primary' plain @click="exportApply">导出列表</el-button>
            </div>
        </div>

        <el-table :data="tableData" border class="text-centre my-table"
                  :span-method="objectSpanMethod"
                  style="width: 100%">

            <el-table-column label="报名用户" width="160">
                <template slot-scope="scope">
                    <div>
                        <span>{{ scope.row.applyUserName === null ? 0 : scope.row.applyUserName }}</span>
                    </div>
                    <div>
                        <span>共{{ scope.row.applyNum === null ? 0 : scope.row.applyNum }}人</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="真实姓名" width="100" prop="realName">
            </el-table-column>

            <el-table-column label="是否签到" width="100">
                <template slot-scope="scope">
                    <i v-if="scope.row.signStatus===1" class="el-icon-check" style="color: #23e202;font-size: 24px"></i>
                    <i v-if="scope.row.signStatus===0" class="el-icon-minus" style="color: #919a9d;font-size: 24px"></i>
                </template>
            </el-table-column>

            <el-table-column label="手机号" width="100" prop="mobile">
            </el-table-column>

            <el-table-column label="微信" width="120" prop="weixin">
            </el-table-column>

            <el-table-column label="邮箱" width="200" prop="email">

            </el-table-column>
            <el-table-column label="公司" width="250" prop="company">
            </el-table-column>

            <el-table-column label="职位" width="100" prop="jobPosition">
            </el-table-column>

            <el-table-column label="备注说明" prop="remark">
            </el-table-column>

            <el-table-column label="支付金额（元）" width="120" prop="payPrice">
            </el-table-column>

            <el-table-column label="报名时间" width="120">
                <template slot-scope="scope">
                    <span>{{ formatStrTime(scope.row.payTime, 16) }}</span>
                </template>
            </el-table-column>

            <el-table-column label="发票信息" width="150">
                <template slot-scope="scope">
                    <div class="text-centre">
                        <el-button
                            title="发票信息"
                            v-if="scope.row.invoiceId"
                            @click="applyDetail(scope.row)"
                            type="text"
                            size="small">
                            发票详情
                        </el-button>
                    </div>
                    <div>
                        <span v-if="scope.row.isOpen ===0">未开发票</span>
                        <span v-if="scope.row.isOpen ===1">已开发票</span>
                        <span v-if="scope.row.isOpen ===null">不需要发票</span>
                    </div>
                </template>
            </el-table-column>

        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="total>100?[20,50,100,200]:[10, 20, 30, 40]"
                :page-size="size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <!--// 邀请详情弹窗-->
        <el-dialog :title="invoiceTitle" :visible.sync="showInvoice" width="30%" :show-close=false
                   :before-close="closeInvoice">
            <el-button :disabled="invoiceData.isOpen===1" type="primary" plain
                       style="position: absolute;top: 20px;right:20px;" @click="openInvoice(invoiceData.id)">
                {{invoiceData.isOpen === 1 ? '已开发票' : '未开发票'}}
            </el-button>
            <table class="invoiceTable">
                <tr>
                    <td>发票类型</td>
                    <td v-for="item in invoiceType" v-if="invoiceData.invoiceType ==item.value">{{item.name}}</td>
                </tr>
                <tr>
                    <td>抬头类型</td>
                    <td v-for="item in invoiceRiseType" v-if="invoiceData.titleType ==item.value">{{item.name}}</td>
                </tr>
                <tr>
                    <td>发票抬头</td>
                    <td>{{invoiceData.title}}</td>
                </tr>
                <tr>
                    <td>纳税人识别号</td>
                    <td>{{invoiceData.taxpayerNo}}</td>
                </tr>
                <tr>
                    <td>地址和电话</td>
                    <td>{{invoiceData.addressAndPhone}}</td>
                </tr>
                <tr>
                    <td>开户行和账号</td>
                    <td>{{invoiceData.openAccount}}</td>
                </tr>
                <tr>
                    <td>收件人</td>
                    <td>{{invoiceData.recipients}}</td>
                </tr>
                <tr>
                    <td>联系电话</td>
                    <td>{{invoiceData.mobile}}</td>
                </tr>
                <tr>
                    <td>收件地址</td>
                    <td>{{invoiceData.addressee}}</td>
                </tr>
            </table>
        </el-dialog>
        <!--导入结果弹窗-->
        <el-dialog :visible.sync='showImportResult' title="数据导入情况分析">
            <table border="1" style="text-align: center;font-size: 20px;width: 100%">
                <thead style="background-color: #919a9d">
                <th>总条数</th>
                <th>成功数</th>
                <th>失败数</th>
                </thead>
                <tr>
                    <td>{{importResultData.sum}}</td>
                    <td>{{importResultData.success}}</td>
                    <td>{{importResultData.fail}}</td>
                </tr>
                <tr v-if="importResultData.messages.length>0" style="color: red">
                    <td colspan="3">导入失败原因如下</td>
                </tr>
                <tr v-else style="color: #23e202">
                    <td colspan="3">数据全部导入成功</td>
                </tr>
                <tr v-for="item in importResultData.messages">
                    <td colspan="3">{{item}}</td>
                </tr>
            </table>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                invoiceData: [],
                importResultData: {
                    messages: [],
                    sum: '',
                    fail: '',
                    success: ''
                },
                showInvoice: false,
                showImportResult: false,
                invoiceTitle: '发票信息',
                currentUser: '李东',
                Auth_token: '',

                signStatus: '',
                signStatusList: [],
                invoiceStatus: [],
                invoiceRiseType: [],
                invoiceType: [],
                tableData: [],
                activityId: '',
                applyUserName: '',
                pos: 0,
                spanArr: [],

                activeName: '',
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 10,
                status: '',
                currentTagId: '',
                showCommon: false,
                isPay: '1',
                commonData: '',
                tableHeight: 600,
                searchTitle: ''
            }
        },
        components: {},
        watch: {},
        created() {
            // 加载活动列表
            this.activityId = this.$route.query.activityId
            // this.getList(this.searchTitle, 1, this.size)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.7
            this.invoiceStatus = this.$getDicByName('INVOICE_STATUS')
            this.signStatusList = this.$getDicByName('SIGN_STATUS')
            this.invoiceRiseType = this.$getDicByName('INVOICE_RISE_TYPE')
            this.invoiceType = this.$getDicByName('INVOICE_TYPE')
            this.Auth_token = localStorage.getItem('auth_token')
        },
        mounted() {
            bus.$on('activityApplyChange', msg => {
                this.activityId = msg
                //console.log(this.activityId)
                this.getList(this.searchTitle, 1, this.size)
            })
            const id = this.$route.query.activityId
            if (id) {
                // //console.log(id)
                this.activityId = id
                this.getList(this.searchTitle, 1, this.size)
            }
        },
        methods: {
            importSuccess(res) {
                if (res.ret === 0) {
                    this.showImportResult = true
                    this.importResultData = res.data
                    this.getList(this.searchTitle, this.currentPage, this.size)
                } else {
                    this.$notify.error({
                        title: '错误',
                        message: res.msg
                    })
                }

            },
            closeInvoice(done) {
                this.getList('', this.currentPage, this.size)
                done()
            },
            openInvoice(id) {
                this.$http({
                    url: this.$api + '/chigua/admin/activity/invoice/update',
                    method: 'post',
                    data: this.$qs.stringify({
                        invoiceId: id,
                        invoiceStatus: 1
                    })
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.$message.success('更新成功！')
                        this.invoiceData.isOpen = 1
                    } else {
                        this.$message.error('数据加载失败！')
                    }

                })

            },
            applyDetail(row) {
                this.$http({
                    url: this.$api + '/chigua/admin/activity/invoice/get',
                    method: 'post',
                    data: this.$qs.stringify({
                        invoiceId: row.invoiceId,
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.invoiceData = response.data.data
                        this.showInvoice = true
                        this.invoiceTitle = '用户' + row.applyUserName + '的发票信息'
                    } else {
                        this.$message.error('数据加载失败！')
                    }

                })

            },
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0 || columnIndex === 11 || columnIndex === 9 || columnIndex === 10) {
                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return {
                        rowspan: _row,
                        colspan: _col
                    }
                }
            },
            getSpanArr(data) {
                this.spanArr = []
                this.pos = 0
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1);
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].id === data[i - 1].id) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }
            },
            handleIsPay() {
                this.getList(this.searchTitle, this.currentPage, this.size)
            },
            handleSearchByState() {
                this.getList(this.searchTitle, this.currentPage, this.size)
            },
            handleBySignStatus() {
                this.getList(this.searchTitle, this.currentPage, this.size)
            },
            handleSearchByTitle() {
                this.getList(this.searchTitle, 1, this.size)
            },
            handleSizeChange(val) {
                this.getList(this.searchTitle, this.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getList(this.searchTitle, val, this.size)
            },
            getList(title, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/chigua/admin/activity/apply/detail/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: this.activityId,
                        invoiceStatus: this.status,
                        signStatus: this.signStatus,
                        isPay: this.isPay,
                        searchCondition: title,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size

                        this.getSpanArr(this.tableData)
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                    loading.close()
                })
            },
            //邀请详情
            getInvoice() {
                bus.$emit('getInvoice', this.activityId)
                this.$router.push('/invoicelist?activityId=' + this.activityId)
            },
            //导出模板
            exportTemplate() {
                this.$export({
                    url: this.$api + '/chigua/admin/activity/apply/export',
                    data: {}
                });
            },
            //导出列表
            exportApply() {
                this.$export({
                    url: this.$api + '/chigua/admin/activity/apply/detail/export',
                    data: {
                        id: this.activityId,
                        isPay: this.isPay,
                        searchCondition: this.searchTitle,
                        invoiceStatus: this.status
                    }
                });

                // document.getElementById().appendChild(form)
                // form.submit();
            },
        }
    }
</script>
<style>
    .apply-button-tool {
        display: flex;
        justify-content: space-around;
        max-height: 32px;
    }

    .apply-button-tool .el-upload--text {
        width: auto;
        height: auto;
        border: 1px;
    }
</style>
<style scoped>

    .apply-margin10 {
        margin: 0 10px
    }

    .my-table.el-table th {
        background: rgba(197, 145, 234, 0.3) !important;
        text-align: center;
    }

    .el-radio + .el-radio {
        margin-left: 10px;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .text-centre {
        text-align: center;
    }

    .pointer {
        cursor: pointer;
    }

    .invoiceTable td {
        border: solid #add9c0;
        border-width: 0px 0px 1px 0px;
        padding: 10px 5px;
        margin-left: 10px;
    }

    .invoiceTable table {
        border: solid #add9c0;
        border-width: 1px 0px 0px 1px;
    }
</style>
