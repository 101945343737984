<template>
    <div id="app" style="width: 100%;height: 100%" :style="{minHeight: minHeight}">
        <router-view></router-view>
    </div>
</template>
<script>
export default {
  data: function () {
    return {}
  },
  computed: {
    minHeight () {
      return (document.body.clientHeight - 50) + 'px'
    }
  },
  created() {

  },
  mounted() {

  }
}
</script>
<style>
    @import "/static/css/main.css";
    @import "/static/css/color-dark.css";      /*深色主题*/
    /*  @import "../static/css/theme-green/color-green.css";  浅绿色主题*/
    page {
      width: 100%;
      height: 100%;
      min-height: 800px
    }
</style>
