<template>
    <div>
        <el-table :data="commentData" class="my-table" :show-header="false">
            <el-table-column>
                <template slot-scope="scope">
                    <div class="answer-disflex">
                        <div class="comment-img color_09bbe7 disflex_around_center" >
                            <div class="disflex_flex_start width50 text-align-left" v-if="scope.row.to_uid !== scope.row.from_uid">
                                <span class="cursor_pointer" @click.stop="replyThisComment(scope.row.from_name, scope.row.from_uid, scope.row)">
                                    <img :src="scope.row.from_avatar" style="width: 25px;height:25px;vertical-align: middle;">
                                    {{scope.row.from_name}}
                                </span>
                                <span style="color: #666;" > 回复 </span>
                                <span class="cursor_pointer"  @click.stop="replyThisComment(scope.row.to_name, scope.row.to_uid, scope.row)">
                                    <img  :src="scope.row.to_avatar" style="width: 25px;height:25px;vertical-align: middle;">
                                    {{scope.row.to_name}}
                                </span>
                            </div>
                            <div class="disflex_flex_start width50 text-align-left" v-else
                                 @click.stop="replyThisComment(scope.row.from_name, scope.row.from_uid, scope.row)">
                                <img class="cursor_pointer" :src="scope.row.from_avatar" style="width: 25px;height:25px;vertical-align: middle;">
                                <span class="cursor_pointer">{{scope.row.from_name}}:</span>
                            </div>
                            <div class="width50 text-align-right">
                                <i class="el-icon-czd-z-like"></i>
                                <span>{{scope.row.praise_num}}</span>
                            </div>
                        </div>
                        <div class="comment-content">
                            <div style="color: #000;font-size: 15px;padding: 10px 0;box-sizing: border-box">
                                <span >{{scope.row.content}}</span>
                            </div>
                            <div class="comment-content-foot">
                                <div>
                                    <!--<el-button style="color: #8c939d" size="medium" type="text" icon="el-icon-czd-huo" @click = 'setGreat(scope.row)'>{{scope.row.isGreat ===1?'取消精选':'设为精选'}}</el-button>-->
                                    <el-button style="color: #8c939d" size="medium" type="text" icon="el-icon-delete" @click="deleteReply(scope.row)">删除</el-button>
                                    <el-button style="color: #8c939d;margin-right: 10px" size="medium" type="text" icon="el-icon-czd-kulou" @click="addUserToBlackList(scope.row)">加入黑名单</el-button>
                                    <span>{{scope.row.add_time}}</span>
                                </div>

                            </div>

                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <!--回复用户-->
        <el-dialog  :visible.sync="isShowReplyBoxInList" custom-class="comment-dialog" :show-close="true"
                    @close="isShowReplyBoxInList = false;replyItemData = {}">
            <h2><span>{{ submitReplyData.from_name }}</span>
                回复 <span>{{submitReplyData.to_name}}</span>:</h2>
            <br>
            <el-form ref="form" label-width="80px">
                <el-form-item label="回复内容">
                    <el-input v-model="submitReplyData.content"></el-input>
                </el-form-item>
                <div class="disflex_flex_end">
                    <el-button type="default">重置</el-button>
                    <el-button type="success" @click="doSubmitReplyData">回复</el-button>
                </div>
            </el-form>
            <h2>请选择一个机器人回复：</h2>
            <span v-for="item in RobotList.data" :key="item.id" @click.stop="setThisUserForReply(item)">
                    <el-badge style="margin: 5px;" >
                        <el-button class="share-button" >
                            <img class="width25px" :src="item.avatar" alt="">
                            {{item.nickname}}
                        </el-button>
                    </el-badge>
                </span>

            <div class="paginaion" style="text-align: center;margin-top: 1em">
                <el-pagination
                    @size-change="handleSizeChangeRobotList"
                    @current-change="handleCurrentChangeRobotList"
                    :current-page="RobotList.currentPage"
                    :page-size="RobotList.pageSize"
                    layout="total,prev, pager, next"
                    :total="RobotList.count">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus'
    export default {
        data: function () {
            return {
                isShowReplyBoxInList: false, // 是否显示回复窗口
                replyItemData: {}, // 当前正在恢复的人
                submitReplyData: {
                    to_uid: '',
                    to_name: '',
                    from_name: '',
                    from_uid: 0,
                    content: ''
                },
                RobotList: {}
            }
        },
        props: ['commentData'],
        watch: {

        },
        created() {
        },
        mounted() {
        },
        methods: {
            // 提交回复
            doSubmitReplyData () {
                this.$http({
                    url: this.$api + '/admin/community/doReplyComment',
                    method: 'post',
                    data: this.submitReplyData
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('回复成功！')
                        this.isShowReplyBoxInList = false
                        bus.$emit('reloadCommentList')
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            handleSizeChangeRobotList (val) {
                this.getRobotList(this.RobotList.currentPage, val, this.sort)
            },
            handleCurrentChangeRobotList (val) {
                this.getRobotList( val, this.RobotList.pageSize, this.sort)
            },
            // 选择用户
            setThisUserForReply (item) {
                this.submitReplyData.from_name = item.nickname;
                this.submitReplyData.from_uid=item.id;
            },
            // 回复这条评论
            replyThisComment (name, id, item) {
                this.isShowReplyBoxInList = true;
                this.submitReplyData.to_name = name;
                this.submitReplyData.to_uid = id;
                this.submitReplyData.comment_id = item.comment_id;
                this.replyItemData = item
                if (!this.RobotList.count) {
                    this.getRobotList(1, 20);
                }
            },
            async getRobotList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '正在加载机器人列表',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                await this.$http({
                    url: this.$api + '/admin/user/getAllRobot',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 20,
                    })
                }).then(response => {
                    if (response.data.data) {
                        this.RobotList = response.data.data;
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
                loading.close()
            },
            // 删除回复
            deleteReply (item) {
                this.$confirm('你正在删除该用户的回复，确认删除？（删除后不可恢复）', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/community/deleteReply',
                        method: 'post',
                        data: {
                            id: item.id
                        }
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success('删除成功')
                        } else {
                            this.$message.error('删除失败！')
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            // 加入黑名单
            addUserToBlackList (item) {
                this.$confirm('您正在把【' + item.from_name + '】加入黑名单！请确认您的操作。', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/user/addUserToBlackList',
                        method: 'post',
                        data: {
                            // 只把当前发布者加入黑名单
                            id: item.from_uid,
                            pub_content: item.content + '【发布时间】' + item.add_time,
                            reason: '管理员拉黑，原因请核对pub_content字段，查询用户发布的违规消息'
                        }
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success('用户已加入黑名单，不能再访问社区系统')
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            }
        }
    }
</script>
