<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span></label>
        </div>
        <div class="button-tool">
            <div style="display: flex">
                <el-input placeholder="请输入关键字查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 200px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
                <el-button type="primary" @click="showAddKeywordDialog = true">新增热搜词</el-button>
            </div>
        </div>
        <el-table
            :data="userSearchKeyword"
            style="width: 100%">
            <el-table-column
                label="搜索关键字">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span style="margin-left: 10px">{{ scope.row.keyword }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="是否设为热搜"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <el-switch
                        v-model="scope.row.is_hot"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="热词"
                        inactive-text="否"
                        @change="changeHot($event, scope.row.id)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                label="是否设为默认"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <el-switch
                        v-model="scope.row.is_default"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="默认"
                        inactive-text="否"
                        @change="changeDefault($event, scope.row.id)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column
                label="序号"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span style="margin-left: 10px">{{ scope.row.sort_order }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <el-button type="danger" @click="deleteHotKeyword(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog title="设置热搜词" :visible.sync="showAddKeywordDialog" @closed="showAddKeywordDialog = false;loadUserSearchKeyword();" width="80%">
            <search-keyword></search-keyword>
        </el-dialog>
    </div>
</template>

<script>
    import searchKeyword from '../analyse/searchKeyword'

    export default {
        data: function () {
            return {
                searchState: '',
                userSearchKeyword: [],
                showAddKeywordDialog: false
            }
        },
        components: {
            searchKeyword
        },
        watch: {

        },
        created() {

        },
        mounted() {
          this.loadUserSearchKeyword();
        },
        methods: {
            changeHot (is_hot, id) {
                this.$http({
                    url: this.$api + '/admin/adminManagement/updateKeyword',
                    method: 'post',
                    data: {
                        id: id,
                        is_hot: is_hot? 1:0
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success('更新成功！')
                        this.loadUserSearchKeyword();
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            changeDefault (is_default, id) {
                this.$http({
                    url: this.$api + '/admin/adminManagement/updateKeyword',
                    method: 'post',
                    data: {
                        id: id,
                        is_default: is_default? 1:0
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success('更新成功！')
                        this.loadUserSearchKeyword();
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            deleteHotKeyword (id) {
                this.$http({
                    url: this.$api + '/admin/adminManagement/deleteHotKeyword',
                    method: 'post',
                    data: {
                        id: id
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success('删除成功！')
                        this.loadUserSearchKeyword();
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.loadUserSearchKeyword()
            },
            // 加载近三个月数据
            loadUserSearchKeyword (search_day) {
                this.$http({
                    url: this.$api + '/admin/adminManagement/getHotKeywordList',
                    method: 'post',
                    data: {
                        keyword: this.searchState
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        res.data.data = res.data.data.map(function (item) {
                            item.is_hot = !!item.is_hot;
                            item.is_default = !!item.is_default;
                            return item
                        })
                        this.userSearchKeyword = res.data.data;
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
