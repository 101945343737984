<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 求瓜得瓜</el-breadcrumb-item>
                <el-breadcrumb-item>写回答</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="container">
            <question-data :question="question"></question-data>
            <div class="edit-area">
                <ueditor v-model="answer.content"></ueditor>
            </div>
            <div class="">
                <!--<el-button class="editor-btn" type="default" @click="">取消</el-button>-->
                <el-button class="editor-btn" type="primary" @click="submit">提交回答</el-button>
            </div>
        </div>
    </div>

</template>

<script>
    import bus from '../../common/bus';
    import ueditor from '../../common/editor'
    import QuestionData from './QuestionData.vue';

    export default {
        data: function () {
            return {
                question: {},
                answer: {
                    questionId: '',
                    content: '',
                    contentIntro: '',
                    picture: ''
                },
            }
        },
        components: {QuestionData, ueditor},
        watch: {},
        created() {

        },
        mounted() {
            bus.$on('addAnswer', msg => {
                this.answer.questionId = msg
                this.getQuestion(msg)
            })
            const id = this.$route.query.questionId
            if (id) {
                this.answer.questionId = id
                this.getQuestion(id)
            }
        },
        methods: {
            getImgUrl(str) {
                let result = ''
                let imgReg = /<img.*?(?:>|\/>)/gi;
                let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
                let arr = str.match(imgReg);
                for (let i = 0; i < arr.length; i++) {
                    if (i === 3) return result;
                    let src = arr[i].match(srcReg);
                    if (src[1]) {
                        if (i === 0) {
                            result += src[1]
                        } else {
                            result += ',' + src[1]
                        }
                    }
                }
                return result;
            },
            transText(str) {
                return str.replace(/(\n)/g, "").replace(/(\t)/g, "").replace(/(\r)/g, "").replace(/<\/?[^>]*>/g, "").replace(/\s*/g, "").substr(0, 150);
            },
            //提交回答
            submit() {
                this.$message.warning("功能正在开发中，敬请期待")

                this.answer.contentIntro = this.transText(this.answer.content)
                this.answer.picture = this.getImgUrl(this.answer.content)

                const loading = this.$loading({
                    lock: true,
                    text: '小咪正在努力提交...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/chigua/admin/answer/add',
                    method: 'post',
                    data: this.$qs.stringify(this.answer)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('回答成功')
                        loading.close()
                        this.$router.push('/questionList')
                    } else {
                        this.$message(response.data.msg)
                    }

                })

            },
            getQuestion(id) {
                this.$http({
                    url: this.$api + '/chigua/admin/question/get',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: id,
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.question = response.data.data
                        this.question.hideMore = true
                    } else {
                        this.$message.error('数据加载失败！')
                    }

                })

            }
        }
    }

</script>

<style scoped>
    .edit-area {
        margin-top: 25px;
        padding-top: 25px;
    }
</style>
