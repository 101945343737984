export default {
    /*
        上传api,挂载到Vue原型链中，实现快速修改、访问、发布
    */

    //上传商品主图, node 已修改
    'UPLOAD_HEADER_IMG': '/admin/upload/goodsimage',
    //上传商品主图, node 已修改
    'UPLOAD_EXPRESS_ADDRESS_BY_EXCEL': '/admin/upload/UPLOAD_EXPRESS_ADDRESS_BY_EXCEL',
    //上传图片
    'UPLOAD_IMG': '/chigua/admin/upload/picture',
    //删除文件
    'DELETE_FILE': '/chigua/admin/upload/delete',
    //上传文件
    'UPLOAD_FILE': '/chigua/admin/upload/file',
    // 上传音频
    'UPLOAD_AUDIO': '/chigua/admin/upload/article/audio',
    // 上传课程（视频 + 音频）
    'COURSE_UPLOAD_VIDEO_N_AUDIO': '/chigua/admin/upload/course',
    // 上传视频
    'VIDEO_UPLOAD_VIDEO_ONLY': '/chigua/admin/upload/video',
    /*
      ** nomal require api **
    */

}
