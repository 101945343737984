<template>
    <div>
        <div class="crumbs" style="width: 100%;">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }} 单）</label>
        </div>


        <div class="width80 padding15 shadow">
            <div class="disflex_flex_start">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 5, maxRows: 10}"
                    placeholder="每行一条内容，使用英文逗号或者中文逗号分割"
                    v-model="address_row">
                </el-input>
                <el-upload :action="$api + UPLOAD_EXPRESS_ADDRESS_BY_EXCEL" :headers="{ Auth_token }" list-type="text" :http-request="importExcel" :show-file-list="false" >
                    <el-button size="small"  type="primary">导入excel文件</el-button>
                </el-upload>
            </div>
            <div class="padding15 text-align-right  width100" >
                <el-button type="default" >检查地址是否正确</el-button>
                <el-cascader
                    v-model="ShipperCode_originList"
                    :options="expressCompany"></el-cascader>
                <el-button type="danger"  @click="generateExpressCode">批量生成运单</el-button>
                <el-button type="primary"  @click="fillExpress">自动填充运单商品信息</el-button>
                <el-button type="info"  @click="exportPDDTemplate">下载拼多多发货模板</el-button>
                <el-button type="primary"  @click="is_Hide_express_list = !is_Hide_express_list">{{is_Hide_express_list? '隐藏':'显示'}}面单</el-button>
            </div>
        </div>


        <div class="width100" v-show="is_Hide_express_list">
            <div v-for="item in pi_liang_print" class="width25" style="transform: scale(0.8)">
                <div class="text-align-right">
                    <!--<el-button @click="editExpressPaper" type="info">编辑运单</el-button>-->
                    <el-button v-print="'#' + item.id"  type="primary">打印运单</el-button>
                </div>
                <div class="width100">
                    <div :ref="item.id" :id="item.id" style="position: relative; height: 1500px;width: 100%;"   v-html="item.print_html"></div>
                </div>
            </div>
        </div>

        <div class="button-tool">
            <div class="search-title" >
                <el-button type="primary" @click="printBySelectList">批量打印选中运单</el-button>
                <el-input placeholder="请输入订单号" v-model="orderSnSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-input placeholder="请输入用户名" v-model="userNameSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-input placeholder="请输入电话号码" v-model="userPhoneSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-button type="default" @click="handleSearchByTitle">搜索</el-button>
                <el-button type="primary" @click="addExpress = !addExpress">新增手打单</el-button>
            </div>
        </div>

        <el-table
            :data="tableData.data"
            v-loading="!tableData.data"
            style="width: 100%"
            @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="收件人信息">
                <template slot-scope="scope">
                    <span>{{scope.row.receiver_name}},{{scope.row.receiver_mobile}}，{{ scope.row.receiver_province +' '+ scope.row.receiver_city +' '+ scope.row.receiver_area +' '+ scope.row.receiver_address }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下单时间" width="150" prop="add_time"></el-table-column>
            <el-table-column label="物流公司" width="150" prop="delivery_id"></el-table-column>
            <el-table-column label="物流单号" width="150" prop="waybill_id"></el-table-column>
            <el-table-column label="订单id" width="150" prop="self20190606204656884892"></el-table-column>
            <!-- 操作 -->
            <el-table-column
                label="操作" >
                <template slot-scope="scope">
                    <el-button type="success" @click="addExpressFun(scope.row)">
                        预览运单
                    </el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[5,10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>

        <div v-if="addExpress">
            <el-dialog
                title="订单物流信息（手打单）"
                :visible.sync="addExpress"
                width="90%" @close="smartOrderData = {}">
                <el-table
                    :data="[smartOrderData]"
                    v-loading="!smartOrderData.id"
                    v-if="smartOrderData.id"
                    style="width: 100%">
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <el-form label-position="left" inline class="demo-table-expand">
                                <el-form-item>
                                    <h1>订单号：{{ scope.row.order_sn }}</h1>
                                    <el-table :data="scope.row.goodsList" style="width: 800px"
                                              border
                                              show-summary
                                              :summary-method="getSummaries"
                                              sum-text="总计需支付价格">
                                        <el-table-column label="商品名称">
                                            <template slot-scope="scope2">
                                                <div slot="reference" class="name-wrapper">
                                                    <img :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                                    <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="数量">
                                            <template slot-scope="scope2">
                                                <div slot="reference" class="name-wrapper">
                                                    <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="购买规格">
                                            <template slot-scope="scope2">
                                                <div slot="reference" class="name-wrapper">
                                                    <span class="bold15" style="margin-left: 10px;color: #eb3021;font-size: 15px;">{{ scope2.row.goods_specifition_name_value }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="结算单价">
                                            <template slot-scope="scope2">
                                                <div slot="reference" class="name-wrapper">
                                                    <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                        <el-table-column label="结算总价" sortable>
                                            <template slot-scope="scope2">
                                                <div slot="reference" class="name-wrapper">
                                                    <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                                </div>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-form-item>
                            </el-form>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="销售备注" width="80">
                        <template slot-scope="scope">
                            <i v-if="!scope.row.order_remarks_id" class="el-icon-circle-plus-outline cursor_pointer" style="font-size: 18px;color: #eb3021;" @click="addOrderRemarks(scope.row)"></i>
                            <span class="red cursor_pointer" style="font-weight: bold" @click="addOrderRemarks(scope.row)" v-else>{{scope.row.seller_remarks}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label="客户"
                        :width="$store.state.UA !== 'MOBILE'? '100':'60'">
                        <template slot-scope="scope">
                            <div slot="reference" class="name-wrapper">
                                <div><img width="50px" :src="scope.row.AVATAR" alt=""></div>
                                <p class="color_09bbe7">{{ scope.row.NICKNAME }}</p>
                            </div>

                        </template>
                    </el-table-column>
                    <el-table-column label="物流地址">
                        <template slot-scope="scope">
                            <span>{{scope.row.consignee}}，{{scope.row.mobile}}，{{ scope.row.ProvinceName +' '+ scope.row.CityName +' '+ scope.row.DistrictName +' '+ scope.row.address }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单金额" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.order_price }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="实际支付金额" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.actual_price }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="下单时间" width="150" prop="add_time"></el-table-column>
                    <el-table-column label="买家备注" width="150" >
                        <template slot-scope="scope">
                            <span style="color: #ff0000;font-size: 15px;">{{ scope.row.remarks }}</span>

                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态" width="100" prop="">
                        <template slot-scope="scope">
                            <span v-if="scope.row.order_status_text==='已收货'" style="color: #333">{{ scope.row.order_status_text }}</span>
                            <span v-else-if="scope.row.order_status_text==='已发货'" style="color: #09bb07">{{ scope.row.order_status_text }}</span>
                            <span v-else-if="scope.row.order_status_text==='已付款'" style="color: #ff0000;font-weight: bold;">{{ scope.row.order_status_text }}</span>
                            <span v-else style="color: #eb3021;font-weight: bold;font-size: 20px;">{{ scope.row.order_status_text }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="物流信息"
                        width="150">
                        <template slot-scope="scope">
                            <i class="el-icon-time"></i>
                            <!--<span style="margin-left: 10px" :style="scope.row.order_status <= 201? 'color: #ff0000;':''">-->
                            <!---->
                            <!--{{getOrderStatusTxt(scope.row.order_status)}}-->
                            <!--&lt;!&ndash;{{ scope.row.order_status > 201? '已发货':'未发货' }}&ndash;&gt;-->

                            <!--</span>-->

                            <span :style="scope.row.LogisticCode ? '' :'color: #ff0000;'">{{scope.row.LogisticCode ? "已发货" : "未发货"}}</span>

                        </template>
                    </el-table-column>
                    <!-- 操作 -->
                    <el-table-column
                        label="发货状态" >
                        <template slot-scope="scope">
                            <el-button type="success" v-if="scope.row.order_status >= 300"  >
                                <img class="icon_20" v-if="scope.row.ShipperCode === 'SF'" src="https://mini-goods.oss-cn-shenzhen.aliyuncs.com/cathouse_pro/images/sf.png" alt="">
                                <img class="icon_20" v-if="scope.row.ShipperCode === 'ZTO'" src="https://mini-goods.oss-cn-shenzhen.aliyuncs.com/cathouse_pro/images/zto.jpg" alt="">
                                已存在运单号
                            </el-button>
                        </template>
                    </el-table-column>
                    <!-- 其他操作 -->
                    <el-table-column
                        label="订单详情">
                        <template slot-scope="scope">
                            <el-button type="info" plain @click="$router.push('./orderDetail?id=' + scope.row.id)">订单详情</el-button>
                        </template>
                    </el-table-column>

                </el-table>
                <h2>1、选择快递信息</h2>
                <!--<el-select @change="changeExpressCode" v-model="express.ShipperCode" placeholder="请选择快递公司" style="padding: 20px 0">-->
                <!--<el-option-->
                <!--v-for="item in expressCompany"-->
                <!--:key="item.value"-->
                <!--:label="item.label"-->
                <!--:value="item.value">-->
                <!--</el-option>-->
                <!--</el-select>-->
                <el-cascader
                    v-model="ShipperCode_originList"
                    :options="expressCompany"></el-cascader>
                <h2>2、确认运单信息</h2>
                <div class="disflex_around_start">
                    <!--发件人信息-->
                    <div class="expressBox">
                        <el-form ref="senderMsg" :model="express">
                            <fieldset class="padding15 border_eee">
                                <legend class="color_cdcdcd">发件信息</legend>
                                <el-form-item :rules="[
                                  { required: true, message: '发件人不能为空'}
                                ]" prop="Sender.Name">
                                    <span>发件人：</span>
                                    <el-input v-model="express.Sender.Name" placeholder="请选择物流公司" ></el-input>
                                </el-form-item>
                                <el-form-item :rules="[
                                  { required: true, message: '发件人手机号不能为空！'}
                                ]" prop="Sender.Mobile">
                                    <span>发件人联系方式：</span>
                                    <el-input v-model="express.Sender.Mobile" placeholder="手机号码" ></el-input>
                                </el-form-item>
                                <el-form-item :rules="[
                                  { required: true, message: '发件省市地址不能为空，省市区间用空格分开'}
                                ]" prop="Sender.FullRegion">
                                    <span>发件地址：</span>
                                    <el-input v-model="express.Sender.FullRegion" placeholder="请选择发件地址" ></el-input>
                                </el-form-item>
                                <el-form-item :rules="[
                                  { required: true, message: '发件人详细地址不能为空'}
                                ]" prop="Sender.Address">
                                    <span>发件地址：</span>
                                    <el-input v-model="express.Sender.Address" placeholder="详细地址" ></el-input>
                                </el-form-item>
                            </fieldset>
                        </el-form>
                    </div>
                    <!--收件人信息-->
                    <div class="expressBox">
                        <el-form ref="receiverMsg"  :model="express">
                            <fieldset class="padding15 border_eee">
                                <legend class="color_cdcdcd">收件信息</legend>

                                <el-form-item>
                                    <span>智能地址识别：</span>
                                    <el-input v-model="SmartAddress" placeholder="请粘贴淘宝发货人信息"></el-input>
                                    <span>智能地址单号（内部订单）：</span>
                                    <el-input v-model="smartOrderSn" placeholder="填写运单编号，智能识别订单"></el-input>
                                </el-form-item>

                                <el-form-item prop="Receiver.Name" :rules="[
                                  { required: true, message: '收件人姓名不能为空！'}
                                ]">
                                    <span>收件人：</span>
                                    <el-input v-model="express.Receiver.Name" placeholder="请填写收件人姓名"></el-input>
                                </el-form-item>
                                <el-form-item prop="Receiver.Mobile"  :rules="[
                                  { required: true, message: '收件人手机号不能为空'}
                                ]">
                                    <span>收件人联系方式：</span>
                                    <el-input v-model="express.Receiver.Mobile" placeholder="收件人手机号码" ></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <span>收件省市区：</span>
                                    <el-cascader size="medium" style="width: 300px;"
                                                 v-model="express.selectCity"
                                                 :options="cityData_computed"
                                                 :props="{ expandTrigger: 'click' }"
                                                 @change="cityChange"
                                    ></el-cascader>
                                </el-form-item>
                                <el-form-item  prop="Receiver.Address" :rules="[
                                  { required: true, message: '收件人详细地址不能为空！'}
                                ]">
                                    <span>详细街道地址：</span>
                                    <el-input v-model="express.Receiver.Address" placeholder="详细地址" ></el-input>
                                </el-form-item>
                            </fieldset>
                        </el-form>
                    </div>
                    <!--其他物流信息-->
                    <div class="expressBox">
                        <el-form ref="otherExpress" :model="express">
                            <fieldset class="padding15 border_eee" >
                                <legend class="color_cdcdcd">其他物流信息</legend>
                                <el-form-item :rules="[
                                  { required: true, message: '商品名称不能为空！'}
                                ]">
                                    <span>快递物件名称：</span>
                                    <el-input v-model="express.Commodity.GoodsName" placeholder="请输入快递物件名称!"  ></el-input>
                                </el-form-item>

                                <el-form-item >
                                    <el-button style="color: #09bb07;cursor: pointer;" type="default" @click="isShowMorExpressExtend = !isShowMorExpressExtend">
                                        {{isShowMorExpressExtend? '收起设置':'更多高级设置'}}
                                        <i class="el-icon-arrow-up" v-if="isShowMorExpressExtend"></i>
                                        <i class="el-icon-arrow-down" v-else></i>
                                    </el-button>
                                </el-form-item>
                                <el-form-item v-if="isShowMorExpressExtend">
                                    <span>重量：(千克)</span>
                                    <el-input v-model="express.weight" placeholder="包裹重量"  ></el-input>
                                </el-form-item>
                                <el-form-item v-if="isShowMorExpressExtend">
                                    <span>长：（cm）</span>
                                    <el-input v-model="express.space_x" placeholder="包裹长度"  ></el-input>
                                </el-form-item>
                                <el-form-item v-if="isShowMorExpressExtend">
                                    <span>宽：(cm)</span>
                                    <el-input v-model="express.space_y" placeholder="包裹宽度"  ></el-input>
                                </el-form-item>
                                <el-form-item  v-if="isShowMorExpressExtend">
                                    <span>宽：(cm)</span>
                                    <el-input v-model="express.space_z" placeholder="包裹宽度"  ></el-input>
                                </el-form-item>

                                <el-form-item>
                                    <span>买家备注：</span>
                                    <textarea class="remarks" name="备注（如果为空，则可以填写快递备注，数据直接同步到快递，打印在快递单上）" id="" cols="20" rows="10" :value="express.Remark"
                                              @input="RemarkChange"></textarea>
                                </el-form-item>
                            </fieldset>
                        </el-form>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="sendExpress">获取运单</el-button>
            <el-button type="default">保  存</el-button>
          </span>
            </el-dialog>
        </div>
        <!--startprint-->
        <el-dialog
            title="预览运单"
            :visible.sync="BOOL_EXPRESS_HTML" @close="closePreTransportOrder"
            width="65%">
            <div class="text-align-right">
                <el-button @click="editExpressPaper" type="info">编辑运单</el-button>
                <el-button v-print="'#express_print'"  type="primary">打印运单</el-button>
            </div>
            <div class="width100">
                <div id="express_print" style="position: relative; height: 1500px;width: 100%;" v-if="BOOL_EXPRESS_HTML"  v-html="EXPRESS_HTML"></div>
            </div>

            <div class="text-align-right">
                <el-button @click="editExpressPaper" type="info">编辑运单</el-button>
                <el-button v-print="'#express_print'"  type="primary">打印运单</el-button>
            </div>
        </el-dialog>
        <!--endprint-->

        <div v-for="item in print_paper_list" :key="item.waybill_id" style="display: none">
            <!--<el-dialog-->
                <!--title="订单物流信息（手打单）"-->
                <!--:visible.sync="item.waybill_id"-->
                <!--width="90%"  class="width100" style="display: none !important;">-->
                    <div class="width100" :ref="item.waybill_id" :id="item.waybill_id" v-html="item.html"></div>
            <!--</el-dialog>-->
        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import cityData from '../../common/cityData';
    import print from 'print-js'
    import CO from 'co'
    import XLSX from 'xlsx'
    import xmldom from 'xmldom';

    export default {
        data: function () {
            return {
                print_paper_list: [],
                print_by_select_list_origin_data: [], // 手动选择的需要批量打印的运单
                is_Hide_express_list: false,
                Auth_token: '',
                EXPRESS_SHEET: {},
                xlsxJson: {},
                fileList: [],
                dataForm: {},
                address_row: '', // 地址数据
                pi_liang_print: [], // 批量打印的数据
                smartOrderSn: '', // 智能识别订单，订单编号，order_sn
                smartOrderData: {}, // 识别到的订单
                isShowMorExpressExtend: false, // 是否显示更多高级设置
                expressCompany: [
                ], //物流公司 //物流公司
                ShipperCode_originList: [], //
                activeName: '全部',
                showWXpayDetail: false,  //是否展示微信支付系统详情
                weixinPayDetail: {user_info:{}},  //查询出来的微信支付详情
                addExpress: false,
                tableData: [],
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                SmartAddress: '', // 智能地址识别
                size: 0,
                currentTagId: '',
                paperStatus: '未上线',
                paperId: '',
                userNameSearchStr: '', // 姓名查询
                userPhoneSearchStr: '', // 电话查询
                orderSnSearchStr: '', // 单号查询
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                PayTypeOptions: [ // 付款方式
                    {label: '现付', value: 1},
                    {label: '到付', value: 2},
                    {label: '月结', value: 3},
                    {label: '第三方', value: 4}
                ],
                ExpTypeOptions: [
                    // 快递类型
                    {label: '标准快件', value: 1}
                ],
                express: {
                    weight: 1,
                    space_x: 20,
                    space_y: 20,
                    space_z: 20,
                    LogisticCode: '', // 运单号
                    ShipperCode: 'ZTO', //物流公司代码
                    ShipperName: '中通快递', // 物流公司名称
                    shipper_service_type: 0,
                    shipper_service_name: '标准快递',
                    OPENID: '', // 用户的openid
                    OrderId: '',
                    IsNotice: 1, //默认不通知
                    IsSendMessage: 1, // 默认发送通知
                    PayType: 1, // 默认发件类型为现付
                    ExpType: 1, // 默认快递类型为标准快递
                    Quantity: '', // 数量
                    Remark: '猫趴用户，请微笑服务~', // 备注
                    Sender: {
                        Name: '李美丽',
                        Tel: '0755-32867416',
                        Mobile: '13828510994',
                        Address: '华宁路厦门大学产学研基地14楼1402',
                        ProvinceName: '广东省',
                        CityName: '深圳市',
                        ExpAreaName: '龙华区',
                        FullRegion: '广东省 深圳市 龙华区'
                    },
                    Receiver: {
                        Name: '',
                        Tel: '',
                        Mobile: '',
                        Address: '',
                        ProvinceName: '',
                        CityName: '',
                        ExpAreaName: '',
                        FullRegion: ''
                    },
                    Commodity: {
                        GoodsName: '猫咪用品'
                    },
                    // 选择的省市区
                    selectCity: []
                },
                showRefundDetailBool: false, // 是否显示退款订单详情
                refundStatus: [], // 退款详情
                EXPRESS_HTML: '', // 后端返回的快递HTML
                cityData_computed: []
            }
        },
        watch: {
            ShipperCode_originList (newv) {
                let that =this;
                this.express.ShipperCode = newv[0];
                this.express.shipper_service_type = newv[1];

                this.expressCompany.map(function (item) {
                    if (that.express.ShipperCode === item.value) {
                        that.express.ShipperName = item.label;
                        item.children.map(function (param) {
                            if (that.express.shipper_service_type == param.value) {
                                that.express.shipper_service_name = param.label;
                            }
                        })
                    }
                })
            },
            async smartOrderSn (newv) {
                if (newv) {
                    await this.$http.post(this.$api + '/admin/onlineShopHelper/getOrderByOrderSn',  {
                        order_sn: newv
                    })
                        .then( res => {
                            if (res.data.errno === 0) {
                                this.smartOrderData = res.data.data;
                                this.express.Receiver.Name =  this.smartOrderData.consignee;
                                this.express.Receiver.Mobile =  this.smartOrderData.mobile;
                                this.express.Receiver.Address =  this.smartOrderData.address;
                                this.express.OPENID =  this.smartOrderData.OPENID;

                                this.express.Receiver.ProvinceName = this.smartOrderData.ProvinceName;
                                this.express.Receiver.CityName = this.smartOrderData.CityName;
                                this.express.Receiver.ExpAreaName = this.smartOrderData.DistrictName;

                                this.express.Commodity.GoodsName = this.smartOrderData.goodsList.map(function (item) {
                                    return item.goods_name
                                })
                                    .join('---\n');
                                this.express.Remark = this.smartOrderData.remarks;

                                let select_address_name_list = [this.express.Receiver.ProvinceName, this.express.Receiver.CityName, this.express.Receiver.ExpAreaName];
                                let select_address_id_list = new Array(3);

                                for (let item in this.cityData_computed) {
                                    if (this.cityData_computed[item].label === select_address_name_list[0]) {
                                        select_address_id_list[0] = this.cityData_computed[item].value;
                                        console.log(select_address_id_list[0])
                                        console.log(this.cityData_computed[item].children)
                                        for (let level_2 in this.cityData_computed[item].children) {
                                            if (this.cityData_computed[item].children[level_2].label === select_address_name_list[1]) {
                                                console.log(select_address_id_list[1])
                                                select_address_id_list[1] = this.cityData_computed[item].children[level_2].value;
                                                for (let level_3 in this.cityData_computed[item].children[level_2].children) {
                                                    if (this.cityData_computed[item].children[level_2].children[level_3].label === select_address_name_list[2]) {
                                                        console.log(select_address_id_list[2])
                                                        select_address_id_list[2] = this.cityData_computed[item].children[level_2].children[level_3].value;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                }
                                console.log(select_address_id_list)
                                this.express.selectCity = select_address_id_list;
                                this.express.Receiver.FullRegion = `${this.express.Receiver.ProvinceName} ${this.express.Receiver.CityName} ${this.express.Receiver.ExpAreaName}`;
                                this.$message.success('识别成功！请注意核对订单信息！')
                            }
                        })
                }
            },
            SmartAddress (newv) {
                let that = this;
                if (newv) {
                    console.log(newv);
                    if (typeof newv === 'string') {
                        let address = newv.split('，');
                        if (address.constructor === Array && address.length > 1) {
                            address.map(function (item) {
                                if (item.length > 10 && Number(item) && Number(item)  > 0) {
                                    that.express.Receiver.Mobile = item
                                }
                            });
                            that.express.Receiver.Name = address[0];

                            let detailAddress = address[2].split(' ');

                            that.express.Receiver.ProvinceName = detailAddress[0];
                            that.express.Receiver.CityName = detailAddress[1];
                            that.express.Receiver.ExpAreaName = detailAddress[2];
                            let select_address_name_list = [detailAddress[0], detailAddress[1], detailAddress[2]];
                            let select_address_id_list = new Array(3);

                            console.log(select_address_name_list)
                            for (let item in this.cityData_computed) {
                                if (this.cityData_computed[item].label === select_address_name_list[0]) {
                                    select_address_id_list[0] = this.cityData_computed[item].value;
                                    console.log(select_address_id_list[0])
                                    console.log(this.cityData_computed[item].children)
                                    for (let level_2 in this.cityData_computed[item].children) {
                                        if (this.cityData_computed[item].children[level_2].label === select_address_name_list[1]) {
                                            console.log(select_address_id_list[1])
                                            select_address_id_list[1] = this.cityData_computed[item].children[level_2].value;
                                            for (let level_3 in this.cityData_computed[item].children[level_2].children) {
                                                if (this.cityData_computed[item].children[level_2].children[level_3].label === select_address_name_list[2]) {
                                                    console.log(select_address_id_list[2])
                                                    select_address_id_list[2] = this.cityData_computed[item].children[level_2].children[level_3].value;
                                                }
                                            }
                                        }
                                    }
                                }

                            }

                            console.log(select_address_id_list)


                            that.express.selectCity = select_address_id_list;
                            that.express.Receiver.FullRegion = `${detailAddress[0]} ${detailAddress[1]} ${detailAddress[2]}`


                            detailAddress = detailAddress.filter(function (item, index) {
                                return index > 2
                            });

                            that.express.Receiver.Address = detailAddress.join(' ')
                        }
                    }
                }
            }
        },
        computed: {
            BOOL_EXPRESS_HTML: {
                get () {
                    return this.EXPRESS_HTML !== ''
                },
                set () {

                }
            }
        },
        created() {
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
            this.getPaperList(0, 1, 10);
            this.cityData_computed = cityData.default;
            console.log(this.cityData_computed)
        },
        async mounted() {
            this.Auth_token = localStorage.getItem('auth_token');

            await this.getExpressType();
            // 更新列表数据
            bus.$on('reloadOrderList', reload => {
                if (reload) {
                    this.getPaperList(this.currentTagId, 1, 10)
                }
            })
        },
        methods: {
            // 手动批量打印操作
            async printBySelectList () {
                // print_by_select_list_origin_data
                this.print_paper_list = [];
                let that = this;
                await CO(function*(){
                        for (let item in that.print_by_select_list_origin_data) {
                            yield that.doPrintBySelectList({
                                order_id: that.print_by_select_list_origin_data[item].order_sn,
                                open_id: that.print_by_select_list_origin_data[item].open_id,
                                delivery_id: that.print_by_select_list_origin_data[item].delivery_id,
                                waybill_id: that.print_by_select_list_origin_data[item].waybill_id,
                                goods_name: that.print_by_select_list_origin_data[item].goods_name || null,
                                goods_spec: that.print_by_select_list_origin_data[item].goods_spec || null,
                                goods_number: that.print_by_select_list_origin_data[item].goods_number || null,
                                goods_remarks: that.print_by_select_list_origin_data[item].goods_remarks || null,
                            })
                        }
                    });


                that.$nextTick(function () {
                    for (let item in that.print_by_select_list_origin_data) {
                        if (that.print_by_select_list_origin_data[item].waybill_id) {
                            that.$print(that.$refs[that.print_by_select_list_origin_data[item].waybill_id][0])
                        }
                    }
                })
            },
            async doPrintBySelectList (DATA) {
                const loading = this.$loading({
                    lock: true,
                    text: '运单已存在，拉取运单中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                let that = this;
                await this.$http.post(this.$api + '/admin/printer/getOrder',  {
                    order_id: DATA.order_sn,
                    openid: DATA.openid,
                    delivery_id: DATA.delivery_id,
                    waybill_id: DATA.waybill_id
                })
                    .then( res => {
                        loading.close()
                        // this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                        let EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.block_8_r img{width: 80% !important;margin-top: 0 !important;}.block_8_r{width: 30% !important;}.block_8_l {width: 70% !important;}.block_8_l_t{width: 100% !important;}.address__sender {font-size: 23px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                        let parseHTML = new DOMParser().parseFromString(EXPRESS_HTML,'text/html');

                        /**
                         * 打单之前，先替换打单的内容，为商品规格详情后再打印
                         * **/
                        let block_9 = parseHTML.getElementsByClassName('block_9');
                        let block_7 = parseHTML.getElementsByClassName('block_7');
                        block_7[0].parentNode.removeChild(block_7[0]);
                        let firstObj = block_9.firstChild;
                        let block_9_content = document.createElement('div');
                        block_9_content.setAttribute('class', 'block_9_content');
                        block_9[0].insertBefore(block_9_content, firstObj);
                        let remarksEle = parseHTML.getElementsByClassName('block_9_content');

                        let address_row = that.address_row.split('\n');
                        let fit_address = [];

                        if (!DATA.goods_name) {
                            address_row.map(function (address) {
                                address = address.split('，');
                                if (address[6] === DATA.order_sn || address[6] === DATA.order_id) {
                                    if (remarksEle && remarksEle.length > 0) {
                                        fit_address = address;
                                        remarksEle[0].innerHTML = '\n<p style="font-size: 22px !important;">' + address[3] + `【规格：${address[4]} 】【 ${address[5]}件】</p>`
                                    }
                                }
                            });

                            // 添加备注
                            if (fit_address && fit_address[7]) {
                                remarksEle[0].innerHTML += '【备注】：' + fit_address[7];
                            }
                        } else {
                            remarksEle[0].innerHTML = '\n<p style="font-size: 22px !important;">' + DATA.goods_name + `【规格：${DATA.goods_spec} 】【 ${DATA.goods_number}件】</p>`
                            remarksEle[0].innerHTML += '【备注】：' + DATA.goods_remarks;
                        }


                        // 更改样式，容纳更多商品
                        remarksEle[0].innerHTML += ` <style>
                            .block_8 {height: 180px;}
                            .block block_8 {height: 180px;}
                            .block_8_l {height: 180px;}
                            .block_8_l_t {height: 90px;}
                            .block_8_l_b {height: 90px;}
                            .block_top  {height: 860px;}
                            .block_6 {height: 140px;}
                        </style>`;

                        that.print_paper_list.push({
                            html: parseHTML.children[0].innerHTML,
                            waybill_id: DATA.waybill_id
                        })

                    }).catch(err => {
                        loading.close()
                    })
                loading? loading.close():''
            },
            // 手动批量打印
            handleSelectionChange (selectList) {
                this.print_by_select_list_origin_data = selectList;
            },
            // 下载拼多多发货模板
            exportPDDTemplate () {
                let address_row = this.address_row.split('\n');

                if (address_row.length <= 0) {
                    return this.$message.error('请提供订单数据!')
                }
                // 解析订单编号数据
                let order_sn_list = address_row.map(function (address) {
                    address = address.split('，');
                    if (!address) {
                        return -1
                    }
                    return address[6]? address[6] : -1;
                });

                if (order_sn_list.length <= 0) {
                    return this.$message.error('订单数据格式化不正确，请检查数据格式是否符合要求!')
                }
                let url = this.$api + '/admin/datacenter/exportPDDExpressTemplate';
                let token = localStorage.getItem('auth_token');
                let filename = `${new Date().getFullYear()}${new Date().getMonth()+1}${new Date().getDay()}${new Date().getHours()}${new Date().getMinutes()}${new Date().getSeconds()}.xlsx`;

                if (!token) {
                    this.$message.error('请重新登陆后操作！');
                    this.$router.push('/login');
                }

                const loading = this.$loading({
                    lock: true,
                    text: '正在下载~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                try {
                    order_sn_list = order_sn_list.join(',');
                    this.$http.post(url, {
                        order_sn_list: order_sn_list
                    }, {
                        responseType: 'blob'
                    }).then(res => {
                        if (res.status === 200) {
                            const BLOB = res.data; // Blob 对象表示一个不可变、原始数据的类文件对象（File 接口都是基于Blob）
                            const fileReader = new FileReader(); // FileReader 对象允许Web应用程序异步读取存储在用户计算机上的文件的内容
                            fileReader.readAsDataURL(BLOB); // 开始读取指定的Blob中的内容。一旦完成，result属性中将包含一个data: URL格式的Base64字符串以表示所读取文件的内容
                            fileReader.onload = (event) => { // 处理load事件。该事件在读取操作完成时触发
                                // 新建个下载的a标签，完成后移除。
                                let a = document.createElement('a');
                                a.download = filename;
                                a.href = event.target.result;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                                loading.close();
                            }
                        } else {
                            this.$message.error(res.statusText)
                        }
                    }).catch(err => {
                        this.$message.error(err.message)
                    });
                } catch (e) {
                    this.$message.error(e)
                    console.log(e)
                }
                loading.close();
            },
            // 批量生成运单号
            generateExpressCode () {
                const loading = this.$loading({
                    lock: true,
                    text: '正在执行批量生成运单任务，请稍后~~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                let that = this;
                if (!this.express.ShipperCode || !this.express.ShipperName) {
                    return this.$message.error('请选择物流公司！');
                }
                this.$http({
                    url: this.$api + '/admin/printer/generateExpressCode',
                    method: 'post',
                    data: {
                        address_data: this.address_row,
                        ShipperCode:this.express.ShipperCode,
                        ShipperName: this.express.ShipperName
                    }
                }).then(response => {
                    if (response.status === 200) {
                        if (response.data.errno == 0) {
                            this.$message.success('批量操作成功！');
                            console.log(response.data.data);
                            response.data.data.successHTML = response.data.data.successHTML.map(function (item, index) {
                               item.id = 'express_print_' + index;
                               return item
                            });
                            this.pi_liang_print = response.data.data.successHTML;
                            setTimeout(function () {
                               that.fillExpress();
                                loading.close();
                            }, 200);
                            this.getPaperList();

                        } else {
                            this.$message.error('生成失败！请重试或检查地址或联系技术解决！')
                        }
                    } else {
                        loading.close();
                    }
                })
            },
            // 填充运单
            fillExpress () {
                let that = this;
                let address_row = that.address_row.split('\n');
                console.log(this.pi_liang_print)
                this.pi_liang_print.map(function (item, index) {
                    address_row.map(function (address) {
                        address = address.split('，');
                        console.log(address[6] === item.order_id)
                        if (address[6] === item.order_id) {
                            document.getElementById(item.id).getElementsByClassName('block_7')[0].innerHTML = `----【${address[3]}】
                            ----【${address[4]}】
                            ----【${address[5]}件】
                            `;
                            document.getElementById(item.id).getElementsByClassName('block_7')[0].setAttribute('style', 'font-size: 25px;');
                            setTimeout(function () {
                                console.log(that.$refs[item.id][0])
                                that.$print(that.$refs[item.id][0])
                            }, 200)
                        }
                    });
                });
            },
            async importExcel (content) {
                const file = content.file;
                // let file = file.files[0] // 使用传统的input方法需要加上这一步
                const types = file.name.split('.')[1];
                const fileType = ['xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'].some(item => item === types);
                if (!fileType) {
                    this.$message('格式错误！请重新选择');
                    return
                }
                await this.file2Xce(file).then(tabJson => {
                    console.log(tabJson)
                    if (tabJson && tabJson.length > 0) {
                        this.xlsxJson = tabJson;
                        this.fileList = this.xlsxJson[0].sheet;
                        let name = '收货人';
                        let goodsName = '商品';
                        let sku = '商品规格';
                        let mobile = '手机';
                        let province = '省';
                        let city = '市';
                        let area = '区';
                        let street = '街道';
                        let order_sn = '订单号';
                        let number = '商品数量(件)';
                        let remarks = '商家备注';
                        let user_remarks = '商家备注';
                        let allPlace = ''; // 集成地址
                        this.fileList.forEach((item, index, arr) => {
                            try {
                                let remarks = '---';
                                if (item[remarks] && item[remarks].length > 0) {
                                    remarks = item[remarks].replace(/，/g, '')
                                }
                                if (item[user_remarks] && item[user_remarks].length > 0) {
                                    remarks += '&&' + item[user_remarks].replace(/，/g, '')
                                }

                                if (index < this.fileList.length - 1) {
                                    allPlace += `${item[name].replace(/，/g, '')}，${item[mobile]}，${item[province]} ${item[city]} ${item[area]} ${item[street].replace(/，/g, '')}，${item[goodsName].replace(/，/g, '')}，${item[sku].replace(/，/g, '')}，${item[number]}，${item[order_sn].replace(/，/g, '')}，${remarks}\n`
                                } else {
                                    allPlace += `${item[name].replace(/，/g, '')}，${item[mobile]}，${item[province]} ${item[city]} ${item[area]} ${item[street].replace(/，/g, '')}，${item[goodsName].replace(/，/g, '')}，${item[sku].replace(/，/g, '')}，${item[number]}，${item[order_sn].replace(/，/g, '')}，${remarks}`
                                }
                            } catch (e) {
                                console.log(e)
                            }
                        });
                        console.log(allPlace);
                        this.address_row = allPlace;
                    }
                })
            },
            async file2Xce (file) {
                return new Promise(function (resolve, reject) {
                    const reader = new FileReader()
                    reader.onload = function (e) {
                        const data = e.target.result
                        this.EXPRESS_SHEET = XLSX.read(data, {
                            type: 'binary'
                        })
                        const result = []
                        this.EXPRESS_SHEET.SheetNames.forEach((sheetName) => {
                            result.push({
                                sheetName: sheetName,
                                sheet: XLSX.utils.sheet_to_json(this.EXPRESS_SHEET.Sheets[sheetName])
                            })
                        })
                        resolve(result)
                    }
                    // reader.readAsBinaryString(file.raw)
                    reader.readAsBinaryString(file) // 传统input方法
                })
            },
            // 比纳基页面
            editExpressPaper () {
                document.getElementById('express_print').setAttribute('contenteditable', true);
            },
            // 获取可用的打单类型
            async getExpressType () {
                let that = this;
                this.$http({
                    url: this.$api + '/admin/printer/expressType',
                    method: 'post',
                    data: this.editOrderNotice
                }).then(response => {
                    if (response.status === 200) {
                        if (response.data.errno == 0) {
                            this.expressCompany = response.data.data;
                            this.expressCompany.map(function (item) {
                                console.log(item)
                                if (item.isDefault && item.children && item.children.constructor === Array) {
                                    console.log(item.children)
                                    item.children.map(function (param) {
                                        console.log(param)
                                        if (param.isDefault) {
                                            // 修改默认的快递方式
                                            that.ShipperCode_originList = [item.value, param.value];
                                        }
                                    })
                                }
                            });
                        }
                    }
                })
            },
            closePreTransportOrder(){
                this.EXPRESS_HTML = '';
            },
            // 选择城市
            cityChange (data) {
                console.log(data);
                if (data.length < 3) {
                    this.$message.error('请完善省市区地址后提交！')
                }
                for (let item in this.cityData_computed) {
                    if (this.cityData_computed[item].value === data[0]) {
                        this.express.Receiver.ProvinceName = this.cityData_computed[item].label;
                        for (let level_2 in this.cityData_computed[item].children) {
                            if (this.cityData_computed[item].children[level_2].value === data[1]) {
                                this.express.Receiver.CityName = this.cityData_computed[item].children[level_2].label;
                                for (let level_3 in this.cityData_computed[item].children[level_2].children) {
                                    if (this.cityData_computed[item].children[level_2].children[level_3].value === data[2]) {
                                        this.express.Receiver.ExpAreaName = this.cityData_computed[item].children[level_2].children[level_3].label;
                                    }
                                }
                            }
                        }
                    }
                }

                this.express.Receiver.FullRegion = `${this.express.Receiver.ProvinceName} ${this.express.Receiver.CityName} ${this.express.Receiver.ExpAreaName}`
            },

            // 变更快递
            changeExpressCode (val) {
                let that =this;
                this.expressCompany.map(function (item) {
                    if (item.value === val) {
                        that.express.ShipperName = item.label
                    }
                })
            },
            // 发货操作
            sendExpress () {
                let that = this;
                that.$refs['senderMsg'].validate((valid) => {
                    if (!valid) {
                        that.$message.error('请完善发件人必填信息！！');
                        return false;
                    } else {
                        that.$refs['receiverMsg'].validate((valid) => {
                            if (!valid) {
                                that.$message.error('请完善收件人必填信息！！');
                                return false;
                            } else {
                                that.$refs['otherExpress'].validate((valid) => {
                                    if (!valid) {
                                        that.$message.error('请完善其他物流必填信息！！');
                                        return false;
                                    } else {
                                        if (!that.express.ShipperCode || !that.express.ShipperName) {
                                            return that.$message.error('请选择物流公司！');
                                        }
                                        // 提交数据
                                        that.express.Receiver_str = JSON.stringify(that.express.Receiver);
                                        that.express.Sender_str = JSON.stringify(that.express.Sender);
                                        that.express.Commodity_str = JSON.stringify(that.express.Commodity);
                                        const loading = this.$loading({
                                            lock: true,
                                            text: '正在拉取快递单~',
                                            spinner: 'el-icon-loading',
                                            background: 'rgba(183, 183, 183, 0.57)'
                                        });
                                        that.$http.post(that.$api + '/admin/onlineShopHelper/addExpressByManager', that.express)
                                            .then( res => {
                                                if (res.data.errno === 0) {
                                                    loading.close();
                                                    this.$message.success('运单获取成功！');
                                                    /**
                                                     * 每次发货成功后都需要重置快递公司，防止惯性操作用错快递公司
                                                     * **/
                                                    // this.express.ShipperCode = this.express.ShipperName = '';
                                                    this.getPaperList('',this.tableData.currentPage, this.tableData.pageSize);
                                                    /**
                                                     * 中通快递
                                                     * **/
                                                    // this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                                                    /**
                                                     * 百世快递
                                                     * **/
                                                    // this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.block_8_r img{width: 100% !important;margin-top: 0 !important;}.block_8_r{width: 30% !important;}.block_8_l {width: 70% !important;}.block_8_l_t{width: 100% !important;}.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                                                    // this.EXPRESS_HTML = res.data.data.print_html; // 关闭发货窗口
                                                    this.addExpress = !this.addExpress;
                                                    // 打开打印窗口
                                                    // this.$alert('运单已生成，是否立即打印运单？', '打印提醒', {
                                                    //     confirmButtonText: '打印',
                                                    //     callback: action => {
                                                    //         printJS('express_print', 'html');
                                                    //         that.EXPRESS_HTML = ''
                                                    //     }
                                                    // });
                                                } else {
                                                    loading.close();
                                                    this.$message.error(res.data.errmsg);
                                                }
                                            }).catch (err => {
                                            loading.close();
                                        })
                                    }
                                });
                            }
                        });
                    }
                });
            },
            async getDeliveryBill (DATA) {
                const loading = this.$loading({
                    lock: true,
                    text: '运单已存在，拉取运单中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                let that = this;
                await this.$http.post(this.$api + '/admin/printer/getOrder',  {
                    order_id: DATA.order_sn,
                    openid: DATA.openid,
                    delivery_id: DATA.delivery_id,
                    waybill_id: DATA.waybill_id
                })
                    .then( res => {
                        loading.close()
                        // this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                        this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.block_8_r img{width: 80% !important;margin-top: 0 !important;}.block_8_r{width: 30% !important;}.block_8_l {width: 70% !important;}.block_8_l_t{width: 100% !important;}.address__sender {font-size: 23px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                        if (DATA.delivery_id === 'BEST') {
                            setTimeout(function () {
                                try {
                                    /**
                                     * 打单之前，先替换打单的内容，为商品规格详情后再打印
                                     * **/
                                    let block_9 = document.getElementsByClassName('block_9');
                                    let block_7 = document.getElementsByClassName('block_7');
                                    block_7[0].parentNode.removeChild(block_7[0]);
                                    let firstObj = block_9.firstChild;
                                    let block_9_content = document.createElement('div');
                                    block_9_content.setAttribute('class', 'block_9_content');
                                    block_9[0].insertBefore(block_9_content, firstObj);
                                    let remarksEle = document.getElementsByClassName('block_9_content');

                                    let address_row = that.address_row.split('\n');
                                    let fit_address = [];
                                    address_row.map(function (address) {
                                        address = address.split('，');
                                        if (address[6] === DATA.order_sn || address[6] === DATA.order_id) {
                                            if (remarksEle && remarksEle.length > 0) {
                                                fit_address = address;
                                                remarksEle[0].innerHTML = '\n<p style="font-size: 22px !important;">' + address[3] + `【规格：${address[4]} 】【 ${address[5]}件】</p>`
                                            }
                                        }
                                    });

                                    // 添加备注
                                    if (fit_address && fit_address[7]) {
                                        remarksEle[0].innerHTML += '【备注】：' + fit_address[7];
                                    }

                                    // 更改样式，容纳更多商品
                                    remarksEle[0].innerHTML += ` <style>
                                .block_8 {height: 180px;}
                                .block block_8 {height: 180px;}
                                .block_8_l {height: 180px;}
                                .block_8_l_t {height: 90px;}
                                .block_8_l_b {height: 90px;}
                                .block_top  {height: 860px;}
                                .block_6 {height: 140px;}
                            </style>`
                                    loading? loading.close():''
                                } catch (e) {
                                    console.log(e)
                                }
                            }, 500)
                        }
                    }).catch(err => {
                        loading.close()
                    })
            },
            async addExpressFun (item) {
                if (!item.id) {
                    alert('订单ID有误，请重新选择！');
                    return
                }

                await this.getDeliveryBill(item)
            },
            // 备注
            RemarkChange (e) {
                this.express.Remark = e.target.value
            },

            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, this.tableData.pageSize)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.tableData.pageSize)
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/onlineShopHelper/expressList',
                    method: 'post',
                    data: this.$qs.stringify({
                        name: this.userNameSearchStr,
                        mobile: this.userPhoneSearchStr,
                        orderSn: this.orderSnSearchStr,
                        page: page? page:1,
                        size: length? length:10
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                        loading.close()
                    }
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    /*.boxShdow {*/
    /*-webkit-box-shadow: inset hoff voff blur #000;*/
    /*-moz-box-shadow: inset hoff voff blur #000;*/
    /*box-shadow: inset hoff voff blur #000;*/
    /*}*/
    /*#express_print .address__sender {*/
    /*font-size: 26px !important;*/
    /*}*/
    .single_weixin_info{
        margin: 10px 0;
        &.user_info{
            display: flex;
            align-items: center;
            .portrait{
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }
        }
    }

    .remarks{
        padding: 10px;
        border: 1px solid #eee;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        width: 100%;
        display: block;
        max-height: 110px;
    }
    .expressBox{
        width: 25%;
    }
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style >
