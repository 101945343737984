<template>
    <div class="sidebar">
        <el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" background-color="#324157"
                 text-color="#bfcbd9" active-text-color="#20a0ff" unique-opened router :loading="loading" @select="hideCollape">
            <div class="sidebar-logo">
                <img width="50px;" src="../../assets/maobuli.png"/>
                <span v-show="!collapse">猫趴正品</span>
            </div>
            <template v-for="item in menu">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.id">
                        <template slot="title">
                            <i :class="collapse?item.icon +' ft25':item.icon"></i>
                            <span slot="title">{{ item.title }}</span>
                        </template>
                        <!--color: #00a0e9-->
                        <el-menu-item style="padding-left: 50px;" v-for="(subItem,i) in item.subs" :key="i"
                                      :index="subItem.index">
                            {{ subItem.title }}
                        </el-menu-item>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="collapse?item.icon +' ft25':item.icon"></i>
                        <span slot="title">{{ item.title }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
    import bus from '../common/bus';

    export default {
        data() {
            return {
                loading: true,
                menu: []
            }
        },
        computed: {
            onRoutes() {
                return this.$route.path;
            },
            collapse () {
                return this.$store.state.collapse
            }
        },
        created() {
            // 通过 Event Bus 进行组件间通信，来折叠侧边栏
            let that = this;
            bus.$on('collapse', msg => {
                that.$store.dispatch('changeCollapse', msg? msg:true)
            })
            this.loadSidebarMenu()
        },
        mounted() {
            bus.$on('updateMenu', msg => {
                if (msg) {
                    // alert(555)
                    this.loadSidebarMenu()
                }
            })
        },
        methods: {
            hideCollape (index) {
                // if (this.$store.state.UA === 'MOBILE') {
                //     this.$store.dispatch('changeCollapse', false)
                // }
            },
            loadSidebarMenu() {
                var that = this
                this.menuLoad = true
                const role = localStorage.getItem('auth_token');
                this.$http({
                    url: this.$api + '/admin/menu/index',
                    method: 'post'
                }).then(response => {
                    this.loading = false
                    var resdata = response.data.data
                    const menuData = JSON.stringify(response.data.data)
                    localStorage.setItem('menuDataOffLine', menuData);
                    if (resdata) {
                        var item = []
                        // 一级菜单
                        for (var first in resdata) {
                            var middlecase = {}
                            middlecase.icon = resdata[first].icon
                            // middlecase.id = resdata[first].id
                            middlecase.title = resdata[first].name
                            middlecase.icon = resdata[first].icon
                            if (resdata[first].url != '') {
                                middlecase.index = resdata[first].url
                            } else {
                                // 防止出现同时折叠，如果都是用404会同时折叠
                                middlecase.index = resdata[first].name
                            }
                            // 二级菜单
                            if (resdata[first].children) {
                                if (resdata[first].children.length !== 0) {
                                    var subs = []
                                    for (var second in resdata[first].children) {
                                        var subschild = {}
                                        subschild.index = resdata[first].children[second].url
                                        subschild.title = resdata[first].children[second].name
                                        subs.push(subschild)
                                    }
                                    middlecase.subs = subs
                                }
                            }
                            item.push(middlecase)
                        }
                        that.menu = item
                    }

                })
            }
        }
    }
</script>

<style scoped>
    .sidebar {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        overflow-y: scroll;
    }

    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 200px;
    }

    .sidebar > ul {
        height: 100%;
    }

    .sidebar-logo {
        height: 50px;
        background: #324457;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    .sidebar-logo > span {
        color: #00bcd4;
        font-size: 20px;
        margin-left: 10px;
    }

    .sidebar-logo > img {
        width: 35px;
        vertical-align: middle;
    }

    .ft25 {
        font-size: 25px;
    }
</style>
