<template>

    <div>
        <div class="container"   v-if="orderExpress.id">
            <div class="orderDetailBox disflex_around_start baseBorder" style="justify-content: space-between !important;">
                <fieldset  class="padding15 border_eee width40 baseBorder">
                    <legend class="color_cdcdcd">购买者信息</legend>
                    <div class="userMsg">
                        <el-card :body-style="{ padding: '0px', display: 'flex' }">
                            <img :src="orderDetail.avatar" class="image">
                            <div style="padding: 14px;">
                                <h4>用户昵称： {{orderDetail.nickname}}</h4>
                                <div class="bottom clearfix">
                                    <span>用户ID： {{orderDetail.user_id}}</span>
                                    <p>收件人：{{orderDetail.consignee}}</p>
                                    <p>收件电话： {{orderDetail.mobile}}</p>
                                    <p>收件地址：{{orderDetail.fullAddress + orderDetail.address}}</p>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </fieldset>


                <fieldset  class="padding15 border_eee width40 baseBorder">
                    <legend class="color_cdcdcd">发件人信息</legend>
                    <div class="userMsg">
                        <h4 class="userMsg_p">发件人：{{orderExpress.Sender.Name}}</h4>
                        <p class="userMsg_p">发件人电话： <span class="red">{{orderExpress.Sender.Mobile}}</span></p>
                        <p class="userMsg_p">发件人固话： {{orderExpress.Sender.Tel}}</p>
                        <p class="userMsg_p">发件人地址：<span class="red">{{orderExpress.Sender.FullRegion + orderExpress.Sender.Address}}</span></p>
                    </div>
                </fieldset>
                <fieldset  class="padding15 border_eee width25 baseBorder">
                    <legend class="color_cdcdcd">购买商品详情</legend>
                    <el-table :data="orderDetail.goodList" style="width: 400 " >
                        <el-table-column label="商品名称"  align="center" width="270">
                            <template slot-scope="scope2">
                                <div slot="reference" class="name-wrapper">
                                    <img :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                    <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="数量"   align="center" width="50">
                            <template slot-scope="scope2">
                                <div slot="reference" class="name-wrapper">
                                    <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="结算总价" sortable align="center" width="80">
                            <template slot-scope="scope2">
                                <div slot="reference" class="name-wrapper">
                                    <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </fieldset>
            </div>
            <div class="orderDetailBox disflex_around_start">
                <fieldset  class="padding15 border_eee width30 baseBorder">
                    <legend class="color_cdcdcd">物流信息</legend>
                    <div class="userMsg">
                        <h4 class="userMsg_p">订单物流记录ID：{{orderExpress.id}}</h4>
                        <h4 class="userMsg_p">所属订单ID：{{orderExpress.OrderId}}</h4>
                        <h4 class="userMsg_p">物流公司名称：<span class="red">{{orderExpress.ShipperName}}</span></h4>
                        <h4 class="userMsg_p">物流公司代号：<span class="red">{{orderExpress.ShipperCode}}</span></h4>
                        <h4 class="userMsg_p">运单号码（快递单号）： <span class="red">{{orderExpress.LogisticCode}}</span></h4>
                        <h4 class="userMsg_p">发货时间： <span class="red">{{orderExpress.add_time}}</span></h4>
                        <p class="userMsg_p">数量： {{orderExpress.Quantity}}</p>
                        <p class="userMsg_p">支付方式： {{orderExpress.PayType}}</p>
                        <p class="userMsg_p">快件类型： {{orderExpress.ExpType}}</p>
                        <p class="userMsg_p">是否发送通知： {{orderExpress.IsNotice}}</p>
                        <p class="userMsg_p">是否发送消息： {{orderExpress.IsSendMessage}}</p>
                        <p class="userMsg_p">内件名称： {{orderExpress.Commodity.GoodsName}}</p>
                        <p class="userMsg_p">备注： {{orderExpress.Remark}}</p></div>
                </fieldset>

                <fieldset  class="padding15 border_eee width25 baseBorder">
                    <legend class="color_cdcdcd">收件信息</legend>
                    <div class="userMsg">
                        <h4 class="userMsg_p">收件人：{{orderExpress.Receiver.Name}}</h4>
                        <h4 class="userMsg_p">收件人ID：{{orderExpress.UserId}}</h4>
                        <p class="userMsg_p">收件人电话： {{orderExpress.Receiver.Mobile}}</p>
                        <p class="userMsg_p">收件人固话： {{orderExpress.Receiver.Tel}}</p>
                        <p class="userMsg_p">收件地址：{{orderExpress.Receiver.FullRegion + orderExpress.Receiver.Address}}</p>
                        <p class="userMsg_p">买家备注（卖家留言、运单备注）： {{orderExpress.Remark}}</p>
                    </div>
                </fieldset>

                <fieldset  class="padding15 border_eee width25 baseBorder">
                    <legend class="color_cdcdcd">订单详情</legend>
                    <div class="userMsg">
                        <h4 class="userMsg_p">商品售价：<span class="red">￥ {{orderDetail.actual_price}} 元</span></h4>
                        <h4 class="userMsg_p">支付金额：<span class="red">￥ {{orderDetail.goods_price}} 元</span></h4>
                        <h4 class="userMsg_p">订单金额：<span class="red">￥ {{orderDetail.order_price}} 元</span></h4>
                        <h4 class="userMsg_p">买家备注：<span class="red font-size-15">{{orderDetail.remarks}}</span></h4>
                        <p class="userMsg_p">订单创建时间： {{orderDetail.add_time}}</p>
                        <p class="userMsg_p">付款时间： {{orderDetail.pay_time}}</p>
                        <p class="userMsg_p">订单编号： {{orderDetail.order_sn}}</p>
                        <p class="userMsg_p">订单状态： {{orderDetail.order_status_text}}</p>
                        <p class="userMsg_p">支付状态：<span :style="orderDetail.pay_status!==2? 'color: #ff0000;':'color: #09bb07;'">{{orderDetail.pay_status===2? '已支付':'订单支付状态未知'}}</span></p>
                        <p class="userMsg_p">发货状态：<span :style="orderDetail.shipping_status < 1? 'color: #ff0000;':'color: #09bb07;'">{{orderDetail.shipping_status >= 1? '已发货':'未发货'}}</span></p>
                    </div>
                </fieldset>
            </div>
            <div id="orderExpressUpdate" >
                <el-row type="flex" class="row-bg baseBorder" v-if="orderExpressUpdate.Traces" style="padding: 2em 1em;box-sizing: border-box;margin: 1em;">
                    <el-col :span="24">
                        <h2 v-if="orderExpressUpdate.Reason">{{orderExpressUpdate.Reason}}</h2>
                        <el-steps :active="this.orderExpressUpdate.Traces.length -1" finish-status="success">
                            <el-step :title="item.AcceptTime" :description="item.AcceptStation" v-for="item in orderExpressUpdate.Traces" :key="item.AcceptTime"></el-step>
                        </el-steps>
                    </el-col>
                </el-row>
            </div>

            <!--修改物流信息弹窗-->
            <el-dialog title="修改物流信息" :visible.sync="editExpressIsShow">

                <el-form :model="editExpressForm" :rules="editExpressRules" ref="editExpressForm">


                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="addTypeDialogData.isShow = false">取 消</el-button>
                    <el-button type="primary" @click="editExpressSubmit">确 定</el-button>
                </div>

            </el-dialog>

            <el-dialog title="修改收件人信息" :visible.sync="editReceiverMessage">

                <el-form :model="editExpressForm" :rules="editExpressRules" ref="editExpressForm">
                    <!--物流公司名称-->
                    <el-form-item label="物流公司名称：" prop="ShipperName">
                        <el-input v-model="editExpressForm.ShipperName" autocomplete="off"></el-input>
                    </el-form-item>

                    <!--物流公司编码-->
                    <el-form-item label="物流公司编码：" prop="ShipperCode">
                        <el-input v-model="editExpressForm.ShipperCode" autocomplete="off"></el-input>
                    </el-form-item>

                    <!--快递单号-->
                    <el-form-item label="新的快递单号：" prop="LogisticCode">
                        <el-input v-model="editExpressForm.LogisticCode" autocomplete="off"></el-input>
                    </el-form-item>

                </el-form>

                <div slot="footer" class="dialog-footer">
                    <el-button @click="addTypeDialogData.isShow = false">取 消</el-button>
                    <el-button type="primary" @click="editExpressSubmit">确 定</el-button>
                </div>

            </el-dialog>


            <div style="text-align: right">
                <el-button type="primary" @click="$router.back()">返 回</el-button>
                <el-button type="primary" @click="editExpressIsShow=true">修改物流信息</el-button>
                <el-button type="primary" @click="editReceiverMessage=true">修改收件人信息</el-button>
                <el-button type="primary" @click="getOrderExpressUpdate">查物流【快递鸟】</el-button>
                <el-button type="success" @click="getOrderExpressUpdateWX">查物流【微信】</el-button>
            </div>
        </div>
        <div class="container" v-else>
            <div class="orderDetailBox disflex_around_start baseBorder">
                <fieldset  class="padding15 border_eee width40 baseBorder">
                    <legend class="color_cdcdcd">购买者信息</legend>
                    <div class="userMsg">
                        <el-card :body-style="{ padding: '0px', display: 'flex' }">
                            <img :src="orderDetail.avatar" class="image">
                            <div style="padding: 14px;">
                                <h4>用户昵称： {{orderDetail.nickname}}</h4>
                                <div class="bottom clearfix">
                                    <span>用户ID： {{orderDetail.user_id}}</span>
                                    <p>收件人：{{orderDetail.consignee}}</p>
                                    <p>收件电话： {{orderDetail.mobile}}</p>
                                    <p>收件地址：{{orderDetail.fullAddress + orderDetail.address}}</p>
                                </div>
                            </div>
                        </el-card>
                    </div>
                    <!--购买的商品信息-->
                    <fieldset  class="padding15 border_eee width25 baseBorder">
                        <legend class="color_cdcdcd">商品详情</legend>
                        <el-table :data="orderDetail.goodList" style="width: 600px " >
                            <el-table-column label="商品名称" width="300" align="center">
                                <template slot-scope="scope2">
                                    <div slot="reference" class="name-wrapper">
                                        <img :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                        <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="商品规格"  width="150" align="center">
                                <template slot-scope="scope2">
                                    <div slot="reference" class="name-wrapper">
                                        <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.goods_specifition_name_value }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="数量"  width="80" align="center">
                                <template slot-scope="scope2">
                                    <div slot="reference" class="name-wrapper">
                                        <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="结算单价"  width="80" align="center">
                                <template slot-scope="scope2">
                                    <div slot="reference" class="name-wrapper">
                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="结算总价" sortable align="center">
                                <template slot-scope="scope2">
                                    <div slot="reference" class="name-wrapper">
                                        <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </fieldset>
                </fieldset>

                <fieldset  class="padding15 border_eee width25 baseBorder">
                    <legend class="color_cdcdcd">订单详情</legend>
                    <div class="userMsg">
                        <h4 class="userMsg_p">商品售价：<span class="black">￥ {{orderDetail.goods_price}} 元</span></h4>
                        <h4 class="userMsg_p">实际支付金额：<span class="red">￥ {{orderDetail.actual_price}} 元</span></h4>
                        <h4 class="userMsg_p">订单金额：<span class="black">￥ {{orderDetail.order_price}} 元</span></h4>
                        <h4 class="userMsg_p">买家备注：<span class="red font-size-15">{{orderDetail.remarks}}</span></h4>
                        <p class="userMsg_p">订单创建时间： {{orderDetail.add_time}}</p>
                        <p class="userMsg_p">付款时间： {{orderDetail.pay_time}}</p>
                        <p class="userMsg_p">订单编号： {{orderDetail.order_sn}}</p>
                        <p class="userMsg_p">订单状态： {{orderDetail.order_status_text}}</p>
                        <p class="userMsg_p">支付状态：<span :style="orderDetail.pay_status!==2? 'color: #ff0000;':'color: #09bb07;'">{{orderDetail.pay_status===2? '已支付':'订单支付状态未知'}}</span></p>
                        <p class="userMsg_p">发货状态：<span :style="orderDetail.shipping_status < 1? 'color: #ff0000;':'color: #09bb07;'">{{orderDetail.shipping_status >= 1? '已发货':'未发货'}}</span></p>
                    </div>
                </fieldset>
            </div>
            <div style="text-align: right">
                <el-button type="primary" @click="$router.back()">返 回</el-button>
            </div>
        </div>
    </div>


</template>

<script>
    import util from '../../../utils/util'
    export default {
        name: "orderDetail",
        data () {
            return {
                editReceiverMessage: false, // 修改收件人信息
                orderDetail: {},
                orderExpress: {},
                orderExpressUpdate: {},
                orderId: 0, // 订单id
                editExpressForm: {  //修改物流信息的表单
                    ShipperName: '',
                    ShipperCode: '',
                    LogisticCode : ''
                },
                editExpressIsShow: false,  //是否展示修改物流信息的对话框
                editExpressRules: {
                    ShipperName: [{required: true, message: "请输入物流公司名称", trigger: 'blur'}],
                    ShipperCode: [{required: true, message: "请输入物流公司编码", trigger: 'blur'}],
                    LogisticCode: [{required: true, message: "请输入快递单号", trigger: 'blur'}]
                }
            }
        },
        created () {

        },
        async mounted () {
            this.orderDetail = {};
            this.orderExpress = {};
            this.orderId = this.$route.query.id;
            // // 获取订单数据
            // this.getOrderExpress(this.orderId)

            this.getOrderDetail(this.orderId);
            this.getRegionList()

        },
        methods: {
            // 获取地区信息
            getRegionList () {
                this.$http.get(this.$api + '/admin/region/regionList').then(res => {
                    if (res.data.errno === 0) {
                        console.log(res)
                    }
                })
            },
            //修改物流信息提交
            editExpressSubmit() {
                this.$refs['editExpressForm'].validate((valid) => {
                    if (valid) {
                        this.editExpressIsShow = false;
                        this.$http.post(this.$api + 'admin/order/updateOrderExpress', {
                            OrderId : this.orderId,
                            ShipperName : this.editExpressForm.ShipperName,
                            ShipperCode : this.editExpressForm.ShipperCode,
                            LogisticCode : this.editExpressForm.LogisticCode,
                        }).then(res => {
                            if (res.data.errno === 0) {
                                this.$message.success('修改物流成功！');
                                setTimeout(function(){
                                    location.reload();
                                },1500)
                            }
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getOrderExpressUpdateWX () {
                this.$http.post(
                    this.$api + 'admin/order/getOrderExpressUpdateByWX',
                    {
                        order_id: this.orderDetail.id,
                        OPENID: this.orderDetail.OPENID,
                        LogisticCode: this.orderExpress.LogisticCode,
                        ShipperCode: this.orderExpress.ShipperCode
                    }
                ).then(res => {
                    console.log(res.data);
                    if (res.data.errno === 0) {
                        this.orderExpressUpdate = res.data.data;
                        this.orderExpressUpdate.Traces =  util.sortByTime(this.orderExpressUpdate.Traces, 'AcceptTime', 'ASC');
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                })
            },
            // 获取订单的物流信息
            getOrderExpressUpdate () {
                this.$http.post(
                    this.$api + 'admin/order/getOrderExpressUpdate',
                    {
                        order_id: this.orderDetail.id,
                        LogisticCode: this.orderExpress.LogisticCode,
                        ShipperCode: this.orderExpress.ShipperCode
                    }
                ).then(res => {
                    if (res.data.errno === 0) {
                        this.orderExpressUpdate = res.data.data;
                        this.orderExpressUpdate.Traces =  util.sortByTime(this.orderExpressUpdate.Traces, 'AcceptTime', 'ASC');
                        this.$message.success('用户端物流信息已同步更新');
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                })
            },
            // 获取订单详情
            async getOrderDetail (orderId) {
                await this.$http.post(
                    this.$api + '/admin/order/getOrderDetail',
                    {
                        id: orderId
                    }
                ).then(res => {
                    if (res.data.errno === 0) {
                        this.orderDetail = res.data.data
                        if (this.orderDetail.order_status !== 201 && this.orderDetail.order_status !== 0) {
                            this.getOrderExpress(this.orderId)
                        }
                    }
                })
            },
            getOrderExpress (orderId) {
                this.$http.post(
                    this.$api + '/admin/order/getOrderExpress',
                    {
                        id: orderId
                    }
                ).then(res => {
                    if (res.data.errno === 0) {
                        this.orderExpress = res.data.data;

                        this.editExpressForm.ShipperName = this.orderExpress.ShipperName;
                        this.editExpressForm.ShipperCode = this.orderExpress.ShipperCode;

                        switch (this.orderExpress.PayType) {
                            case 1:
                                this.orderExpress.PayType = '现结';
                                break;
                            case 2:
                                this.orderExpress.PayType = '到付';
                                break;
                            case 3:
                                this.orderExpress.PayType = '月结';
                                break;
                            case 4:
                                this.orderExpress.PayType = '第三方支付';
                                break;
                            default:
                                this.orderExpress.PayType = '未知结算方式';
                                break;
                        }
                        switch (this.orderExpress.ExpType) {
                            case 1:
                                this.orderExpress.ExpType = '标准快递';
                                break;
                            default:
                                this.orderExpress.ExpType = '未知快件方式';
                                break;
                        }
                        switch (this.orderExpress.IsNotice) {
                            case 1:
                                this.orderExpress.IsNotice = '是';
                                break;
                            case 0:
                                this.orderExpress.IsNotice = '否';
                                break;
                            default:
                                this.orderExpress.IsNotice = '未知通知状态';
                                break;
                        }
                        switch (this.orderExpress.IsSendMessage) {
                            case 1:
                                this.orderExpress.IsSendMessage = '是';
                                break;
                            case 0:
                                this.orderExpress.IsSendMessage = '否';
                                break;
                            default:
                                this.orderExpress.IsSendMessage = '未知消息推送状态';
                                break;
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .orderDetailBox{
        padding-top: 15px;
    }
    .orderDetailBox .userMsg{
        color: #000;
        font-size: 14px;
    }
    .orderDetailBox .userMsg_p{
        padding: .5em 5px;
    }
</style>
