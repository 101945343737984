<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}个商品）</label>
        </div>
        <div class="button-tool">
            <div>
                <el-button type='primary' @click="$router.push('/addBargain')">新增砍价</el-button>
                <!--<el-button type='primary' @click="$router.push('/newspaper?ct=2')">新增公众号</el-button>-->
                <!--<el-button type='success' @click="editPaperType_btn">编辑分类</el-button>-->
            </div>
            <div style="display: flex">
                <el-radio-group v-model="contentType" style="margin-right: 20px;margin-top: 10px"
                                @change="handleContentType">
                    <el-radio v-for="item in contentTypes" :label="item.value" :name="item.name" :key="item.value">
                        {{item.name}}
                    </el-radio>
                </el-radio-group>
                <el-input placeholder="请输入商品名查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 500px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>
        <!--<div class="tagSwitch disflexct">-->
            <!--<el-tabs v-model="activeName" @tab-click="switchTag" style="width: 100%">-->
                <!--<el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"-->
                             <!--:name="item.name"></el-tab-pane>-->
            <!--</el-tabs>-->
        <!--</div>-->
        <el-table
            :data="tableData.data"

            style="width: 100%">
            <el-table-column label="砍价id" width="80" prop="id"></el-table-column>
            <el-table-column label="砍价商品">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.primary_pic_url" style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.name }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="可砍金额"
                width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px;color: #eb3021;font-size: 18px;">￥{{ scope.row.cut_price }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="砍价人数"
                width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.cut_user_num }} 人</span>
                </template>
            </el-table-column>
            <el-table-column label="零售价(原价)" width="80" prop="retail_price"></el-table-column>
            <el-table-column label="开始时间">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.start_time }} </span>
                </template>
            </el-table-column>
            <el-table-column label="活动时长">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.bargain_time_length }}小时 ({{scope.row.bargain_time_length / 24}}天) </span>
                </template>
            </el-table-column>
            <el-table-column label="预计结束时间">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.end_time }} </span>
                </template>
            </el-table-column>

            <el-table-column
                label="状态">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span v-if="scope.row.is_start"
                          :style="scope.row.is_finish===1? 'font-size: 18px !important;color: #ff0000;':'font-size: 18px !important;color: #09bb07;'">
                        {{ scope.row.is_finish===1? '已结束':'进行中' }} </span>
                    <span style="font-size: 18px !important;" v-else>未开始 </span>
                </template>
            </el-table-column>

            <el-table-column
                label="参与砍价的人数"
                width="80">
                <template slot-scope="scope">
                    <p class="font-size-15 fontWeight">{{ scope.row.sell_volume }} </p>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="80px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px;
     align-items: flex-end;">
                        <!-- 编辑 -->
                        <!--<i title="编辑" class="el-icon-edit-outline ft25 pointer"-->
                           <!--@click="editPaper(scope.$index, scope.row)"></i>-->
                        <i title="下线" class="el-icon-circle-plus-outline ft25 pointer"
                           @click="operatePaper(scope.$index, scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>

    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'
    import clearCacheTool from '../../common/clearCacheTool'

    export default {
        data: function () {
            return {
                activeName: '全部',
                addPaperType_bool: false,
                // loading: true,
                tableData: [],
                tagSwitchData: [],
                contentType: '',
                contentTypes: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                searchState: '',
                paperStatus: '未上线',
                paperId: '',
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                is_edit: false
            }
        },
        components: {
            commonlist,
            clearCacheTool
        },
        watch: {},
        created() {
            this.getPaperList(1, 10)
        },
        mounted() {

        },
        methods: {
            // 编辑商品类型
            editPaperType_btn() {
                this.addPaperType_bool = true
            },
            editPaperType_btn_inside(item) {
                for (var i in item) {
                    this.editPaperData[i] = item[i]
                }
                this.is_edit = true
                this.editPaperStr = '保存编辑'
                this.oprationType = 'edit'
            },
            // 跳转到编辑页面，巴士负责传递id
            editPaper(index, row) {
                // var loading = this.$loading({fullscreen: true})
                this.paperId = row.id
                // bus.$emit('paperChange', this.paperId)
                //  把商品类型带上
                this.$router.push('/goodsedit?id=' + row.id)
                // loading.close()
            },
            // 删除商品
            deletePaper(index, row) {
                this.$confirm('您正在进行【删除商品】操作，确认？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/article/delete',
                        method: 'post',
                        data: this.$qs.stringify({id: row.id})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('商品已删除！')
                                this.getPaperList( this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            operatePaper(index, row) {
                this.$confirm('您正在进行【上线砍价】操作，该操作会直接改变商品发布状态', '警示', {
                    confirmButtonText: '上线砍价',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/bargain/updateBargain',
                        method: 'post',
                        data:  this.$qs.stringify({bargain_id: row.id, is_online: 1})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno == 0) {
                                this.$message.success('上线成功！！')
                                this.getPaperList(this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });

            },

            //按标题搜索列表
            handleContentType() {
                this.getPaperList( 1, this.tableData.pageSize)
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList( 1, this.pageSize)
            },
            handleSizeChange(val) {
                this.getPaperList( this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList( val, this.tableData.pageSize)
            },
            getPaperList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/bargain/bargainList',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page || 1,
                        size: length || 10,
                        is_online: 0,
                        name: this.searchState
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            },
            switchTag(tab) {

                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {

                        this.searchState = ''
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10)
                        this.currentTagId = this.tagSwitchData[i].id
                    }
                }
            },
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
