<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>商品属性设置</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="padding: 10px">
            <div style="padding: 10px;background: #fff;">
                <el-cascader-panel
                    v-model="selectCategoryList"
                    :options="menuDataOffLine" :props="{
                    label: 'name',
                    value: 'id'
                }">

                </el-cascader-panel>
                <h3 class="padding15">属性参数

                </h3>

                <div>
                    <el-card style="display: inline-block;margin: 5px;vertical-align: top" class="width20" :body-style="{ padding: '15px' }" v-for="item in attributeList" :key="item.id">
                       <p class="text-align-right color_eb3021 cursor_pointer">
                           <i class="el-icon-delete-solid"
                              @click="deleteWholeAttr(item)"
                           ></i>
                       </p>
                        <div>
                            <h3 class="padding15">{{item.label}}</h3>
                            <div class="padding15 disflex_between_center" style="border-bottom: 1px solid #eee;cursor: pointer"
                                 v-for="attr in item.children" :key="attr.value">
                                <p>{{attr.label}}</p>
                                <i class="el-icon-remove color_eb3021 cursor_pointer" @click="deleteAttr(attr)"></i>
                            </div>
                            <el-input placeholder="请输入属性值" v-model="item.addValue" v-if="item.isAdding" @keyup.enter.native="saveAttr(item)">
                                <el-button type="success" slot="append" @click="saveAttr(item)" >
                                    保存
                                </el-button>
                            </el-input>
                            <p class="padding15 color_09bbe7 text-align-center cursor_pointer"
                               v-if="!item.isAdding" @click="addAttr(item)" >添加属性值 <i class="el-icon-circle-plus "></i></p>
                        </div>
                    </el-card>

                    <el-card style="display: inline-block;margin: 5px;vertical-align: top" class="width20" :body-style="{ padding: '15px' }">
                        <div>
                            <div class="padding15" style="border-bottom: 1px solid #eee;">
                                <el-input v-model="addAttributeData.attribute_key" style="font-weight: bold;" placeholder="请输入属性名称">
                                    <template slot="prepend">属性名 <span class="color_eb3021">*</span></template>
                                </el-input>
                            </div>

                            <div class="padding15" style="border-bottom: 1px solid #eee;">
                                <el-input v-model="addAttributeData.attribute_value" style="font-weight: bold;" placeholder="请输入属性值" @keyup.enter.native="saveNewAttrData">
                                    <template slot="prepend">属性值<span class="color_eb3021">*</span></template>
                                </el-input>
                            </div>
                            <p class="padding15 color_09bbe7 text-align-center cursor_pointer"
                               @click="saveNewAttrData" >新增属性 <i class="el-icon-s-promotion "></i></p>
                        </div>
                    </el-card>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                menuDataOffLine: [],
                attributeList: [],
                selectCategoryList: [],
                addAttributeData: {
                    attribute_key: '',
                    attribute_value: '',
                    isAdding: false
                }
            }
        },
        components: {
        },
        watch: {
            selectCategoryList () {
                if (this.selectCategoryList[1]) {
                    this.getAttributeList(this.selectCategoryList[1])
                }
            }
        },
        created() {
            this.getMenuList()
        },
        mounted() {

        },
        methods: {
            // 删除整个属性块
            deleteWholeAttr (item) {
                let attr_value_list = item.children.map(function (param) {
                   return param.value
                });
                this.$confirm('该操作将删除当前商品分类下的属性及该属性的所有值？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/attribute/deleteWholeAttr',
                        method: 'post',
                        data: {
                            attribute_id: item.id,
                            attribute_value: attr_value_list
                        }
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno == 0) {
                                this.$message.success('属性已删除！');
                                // 重新拉取数据
                                this.getAttributeList(this.selectCategoryList[1])
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            // 保存新建的属性
            saveNewAttrData () {
                if (!this.addAttributeData.attribute_key || !this.addAttributeData.attribute_value) {
                    this.$message.error('请完善必填数据后保存！')
                    return
                }
                this.addAttributeData.category_id = this.selectCategoryList[1];
                this.$http.post(this.$api + '/admin/attribute/addAttr', this.addAttributeData).then(attribute => {
                    if (attribute.data.errno === 0) {
                        this.$message.success('新增属性成功！');
                        // 清空数据
                        this.addAttributeData.attribute_key = '';
                        this.addAttributeData.attribute_value = '';
                        delete this.addAttributeData.category_id;
                        // 重新拉取数据
                        this.getAttributeList(this.selectCategoryList[1])
                    } else {
                        this.$message.error(attribute.data.errMsg)
                    }
                })
            },
            // 删除属性值
            deleteAttr (attr) {
                this.$http.post(this.$api + '/admin/attribute/deleteAttr_value', {
                    id: attr.value
                }).then(attribute => {
                    if (attribute.data.errno === 0) {
                        this.$message.success('删除属性值成功！')
                        this.getAttributeList(this.selectCategoryList[1])
                    } else {
                        this.$message.error(attribute.data.errMsg)
                    }
                })
            },
            // 保存新增的attr
            saveAttr (item) {
                if (!item.addValue) {
                    return this.$message.error('请输入参数值后保存！！')
                }
                // 保存属性值
                this.$http.post(this.$api + '/admin/attribute/saveAttr_value', {
                    attribute_id: item.id,
                    attribute_value: item.addValue,
                    sort: item.children.length +1
                }).then(attribute => {
                    console.log(attribute)
                    if (attribute.data.errno === 0) {
                        this.$message.success('添加属性值成功！')
                        this.getAttributeList(this.selectCategoryList[1])
                    } else {
                        this.$message.error(attribute.data.errMsg)
                    }
                })
            },
            // 新增属性
            addAttr (item) {
                this.attributeList[this.attributeList.indexOf(item)].isAdding = true;
            },
            // 获取对应的分类的属性参数
            getAttributeList (category_id) {
                this.$http.post(this.$api + '/admin/goods/goodsAttributes', {
                    category_id: category_id
                }).then(attribute => {
                    if (attribute.data.errno === 0) {
                        attribute.data.data = attribute.data.data.map(function (item) {
                            item.isAdding = false;
                            item.addValue = '';
                            return item
                        })
                        this.attributeList = attribute.data.data
                    }
                })
            },
            // 获取所有分类列表
            getMenuList() {
                this.$http({
                    url: this.$api + '/admin/category/all',
                    method: 'get'
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.menuDataOffLine = response.data.data

                        if (this.attributeList.length <= 0) {
                            this.selectCategoryList.push(response.data.data[0].id, response.data.data[0].children[0].id);
                            this.getAttributeList(response.data.data[0].children[0].id)
                        }
                    } else {
                        this.$message.error('菜单加载失败')
                    }
                })
            }
        }
    }
</script>
<style>
    .menu-tree-table .is-expanded {
        background-color: #f0f8f9;
    }

    .menu-tree-table .el-tree-node {
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #f0f0f0;
        /*text-align: center;*/
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .menu-flex {
        display: flex;
        justify-content: space-around;
        line-height: 34px;
        /*margin:auto;*/
    }
</style>
