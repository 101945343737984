<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>开发设置</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="padding: 10px">
            <el-button type="primary" @click="addmenuShow('ruleForm')" style="margin-top: 1em;">添加顶级菜单</el-button>
            <el-tree
                class="menu-tree-table"
                :data="menuDataOffLine"
                :props="defaultProps"
                :indent="indent"
                :expand-on-click-node="false"
                accordion
                @node-click=""
            >
                <div class="menu-flex" style="width: 100%" slot-scope="{ node, data }">
                    <!--菜单名-->
                    <div style="width: 8em">
                        <span style="padding-left: 1px">{{ data.name }}</span>
                    </div>
                    <!--图标-->
                    <div >
                        <span><i :class="data.icon" class="ft25"></i></span>
                    </div>
                    <!--菜单类型-->
                    <!--<div style="width: 100px">-->
                        <!--<span v-show="(data.menuType===2)">{{ data.keepAlive===1?'true':'false'}}</span>-->
                    <!--</div>-->
                    <!--菜单排序-->
                    <div>
                        <el-input style="width: 200px;" v-model="data.sort">
                            <template slot="prepend">排序</template>
                        </el-input>
                    </div>
                    <!--菜单状态-->
                    <div >
                        <!--<el-button :type="data.isShow === 1? 'primary':'info'" @click="showMenu(data)">{{ data.isShow===-->
                        <!--1? '显示':'隐藏' }}-->
                        <!--</el-button>-->
                        <el-radio-group v-model="data.isShow" size="mini" v-show="!(data.menuType===3)">
                            <el-radio-button label=0 @change.native="showMenu(data)">隐藏</el-radio-button>
                            <el-radio-button label=1 @change.native="showMenu(data)">显示</el-radio-button>
                        </el-radio-group>
                    </div>
                    <!--<div style="width: 100px">-->
                        <!--<span v-for="item in menuType">{{ data.menuType===item.value?item.label:''}}</span>-->
                    <!--</div>-->
                    <div style="">
                        <i title="删除" class="el-icon-delete ft25 red pointer" @click="deleteMenu(data)" v-if="data.is_deleted == 0"></i>
                        <span v-if="data.is_deleted == 1" style="color: #ff0000;font-size: 12px;">已删除</span>
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer" style="margin-left: 10px"
                           @click="editMenu(data)"></i>
                        <i :title="data.menuType===1?'添加下级菜单':(data.menuType===2?'添加按钮':'')" v-show="!(data.menuType===3)"
                           class="el-icon-circle-plus-outline ft25 pointer" style="margin-left: 10px"
                           @click="addChildMenu(data)"></i>
                    </div>
                </div>
            </el-tree>
        </div>

        <editmenu></editmenu>
        <addmenu></addmenu>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import listtable from './listtable'
    import editmenu from './editMenu'
    import addmenu from './addMenu'

    export default {
        data: function () {
            return {
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                menuDataOffLine: [],
                showSencondMenu: false,
                paperId: '',
                indent: 0,
                showTable: false,
                tableData: '',
                menuType: [
                    {label: '目录', value: 1},
                    {label: '菜单', value: 2},
                    {label: '按钮', value: 3}
                ],
            }
        },
        components: {
            listtable, editmenu, addmenu
        },
        watch: {},
        created() {
            this.getPaperList()
        },
        mounted() {
            bus.$on('updateMenu', msg => {
                if (msg) {
                    this.getPaperList()
                }
            })
        },
        methods: {
            showMenu(d) {
            //     //console.log(d)
            //     let show = d.isShow === 1 ? '0' : '1'
                this.$http({
                    url: this.$api + '/admin/menu/update',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: d.id,
                        is_show: d.isShow
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('菜单更新成功！')
                        // this.getMenuList()
                    }
                })
            },
            addChildMenu(data) {
                let d = {
                    parentId: data.id,
                    menuType: data.menuType + 1,
                    icon: data.menuType === 2 ? 'el-icon-czd-quanxian' : '',
                }
                bus.$emit('addmenu', d)
            },
            addmenuShow() {
                let data = {
                    parentId: 0,
                    menuType: 1,
                    icon: 'el-icon-czd-shouye',
                }
                bus.$emit('addmenu', data)
            },
            editMenu(row) {
                bus.$emit('editShow', row)
            },
            deleteMenu(row) {
                this.$confirm('您执行菜单删除操作！', '警告', {
                    confirmButtenText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(msg => {
                    this.$http({
                        url: this.$api + '/admin/menu/delete',
                        method: 'post',
                        data: this.$qs.stringify({
                            menuId: row.id
                        })
                    }).then(response => {
                        if (response.data.errno === 0) {
                            // this.getMenuList()
                            this.$message.success('删除【' + row.name + '】菜单成功')
                            // 同步更新菜单内容
                            bus.$emit('updateMenu', msg => {
                                if (msg) {
                                    this.getPaperList()
                                }
                            })
                        } else {
                            this.$message.error('菜单删除失败')
                        }
                    })
                }).catch(msg => {
                    this.$message.info('操作取消')
                })
            },
            getMenuList() {
                this.$http({
                    url: this.$api + '/admin/menu/all',
                    method: 'get'
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.menuDataOffLine = response.data.data
                    } else {
                        this.$message.error('菜单加载失败')
                    }
                })
            },
            getPaperList() {
                // var menuDataOffLine = JSON.parse(localStorage.getItem('menuDataOffLine'))
                // this.menuDataOffLine = menuDataOffLine
                this.getMenuList()
            }
        }
    }
</script>
<style>
    .menu-tree-table .is-expanded {
        background-color: #f0f8f9;
    }

    .menu-tree-table .el-tree-node {
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #f0f0f0;
        /*text-align: center;*/
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .menu-flex {
        display: flex;
        justify-content: space-around;
        line-height: 34px;
        /*margin:auto;*/
    }
</style>
