<template>
    <div v-if="adsEditData_com.showForm">
        <el-dialog :visible.sync="showFormfuck" width="95%" title="编辑轮播图" :before-close="closeDialog">
            <div class="disflex_flex_start">
                <el-form class="width40" :model="adsEditData_com"  ref="adsEditData_com" label-width="100px" >
                    <el-form-item label="广告封面" required class="width40" >
                        <upload-image v-model="adsEditData_com.image_url" :PHUrl="adsEditData_com.image_url" :width="250"></upload-image>
                    </el-form-item>
                    <el-form-item label="标题" required>
                        <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入广告标题"
                            v-model="adsEditData_com.name">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="简介" required>
                        <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入广告简介"
                            v-model="adsEditData_com.content">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="广告类型" required>
                        <el-select v-model="adsEditData_com.media_type">
                            <el-option  v-for="item in media_type_arr" :key="item.value" :label="item.name" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <!--<el-form-item label="结束时间" required>-->
                    <!--<el-date-picker-->
                    <!--v-model="adsEditData_com.end_time"-->
                    <!--type="datetime"-->
                    <!--placeholder="选择结束时间"-->
                    <!--:default-value="new Date()">-->
                    <!--</el-date-picker>-->
                    <!--</el-form-item>-->
                    <el-form-item label="立即上线" required>
                        <el-switch
                            v-model="adsEditData_com.enabled"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="【广告地址！】" required>
                        <el-input
                            placeholder="请选择商品后确认广告地址"
                            v-model="adsEditData_com.link">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="内容ID" required>
                        <el-input
                            placeholder="内容的ID"
                            v-model="adsEditData_com.jump_id">
                        </el-input>
                    </el-form-item>

                </el-form>
                <div class="width50" style="margin-left: 10%;border: 1px solid #eee;">

                    <el-input placeholder="请输入商品名查询" v-model="searchStr" @keyup.enter.native="getPaperList(1, 15)"
                              style="width: 500px">
                        <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="getPaperList(1, 15)"></i>
                    </el-input>
                    <el-table
                        ref="singleTable"
                        :data="tableData.data"
                        highlight-current-row
                        @current-change="handelCurrentSelect"
                        style="width: 100%;cursor: pointer;">
                        <el-table-column
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column
                            property="date"
                            label="商品标题">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <!--<el-table-column-->
                            <!--property="name"-->
                            <!--label="商品简介"-->
                            <!--width="120">-->
                            <!--<template slot-scope="scope">-->
                                <!--<span style="margin-left: 10px">{{ scope.row.goods_brief.substring(0,20) }}</span>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <el-table-column
                            property="name"
                            label="商品价格"
                            width="120">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px"> ￥ {{ scope.row.retail_price }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="paginaion" style="text-align: center;margin-top: 1em">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="tableData.currentPage"
                            :page-size="tableData.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="tableData.count">
                        </el-pagination>
                    </div>
                </div>
            </div>


            <div style="text-align: right;">
                <el-button type="success" round @click="submitAds">发布</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import bus from '../../common/bus';
    import uploadImage from '../../common/uploadImage';


    export default {
        data() {
            return {
                showFormfuck: true,
                media_type_arr:[
                    {name: '商品', value: 1},
                    {name: '分类', value: 2}
                ],
                tableData: {},
                searchStr: ''
            }
        },
        components: {
            uploadImage
        },
        props: {
            'adsEditData':{
                type: Object,
                default: function () {
                    return {
                        image_url: '',
                        showForm: true
                    }
                }
            }
        },
        computed: {
            adsEditData_com: {
                get: function () {
                    let data = this.adsEditData;
                    data.enabled===0? data.enabled = false:data.enabled = true;
                    // 如果没有设置时间，则设置时间默认为七天
                    data.end_time? data.end_time:data.end_time = this.$getDay(7);
                    return data
                },
                set: function (newValue) {
                    this.adsEditData
                }
            }
        },
        watch: {


        },
        methods: {
            //按标题搜索列表
            // handleContentType() {
            //     this.getPaperList(this.currentTagId, this.currentPage, this.size)
            // },
            // //按标题搜索列表
            // handleSearchByTitle() {
            //     this.getPaperList(this.currentTagId, this.currentPage, this.size)
            // },
            handleSizeChange(val) {
                this.getPaperList(this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(val, this.tableData.pageSize)
            },
            handelCurrentSelect (val) {
                this.adsEditData_com.name = val.name;
                this.adsEditData_com.content = val.goods_brief;
                this.adsEditData_com.image_url = val.primary_pic_url;
                this.adsEditData_com.link = '/pages/goods/goods?id=' + val.id;
                this.adsEditData_com.jump_id = val.id;
            },
            submitAds () {
                if (!this.adsEditData_com.link) {
                    this.$message.error('广告地址不能为空！');
                    return
                }
                this.$http({
                    url: this.$api + '/admin/ads/update',
                    method: 'post',
                    data: this.$qs.stringify(this.adsEditData_com)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('编辑成功！前台广告已更新，注意！！！该操作在更新缓存后生效！')
                        this.adsEditData_com.showForm = false
                    }
                })
            },
            closeDialog (done) {
                done()
            },
            listCategory() {
                this.$http({
                    url: this.$api + '/admin/category/parent',
                    method: 'post'
                }).then(response => {
                    if (response.data != null) {
                        this.category = response.data.data
                    }
                })
            },
            getPaperList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/goods/index',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        name: this.searchStr,
                        size: length ? length : 15
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                }).catch(e => {
                    loading.close()
                })
            },
        },
        created() {

        },
        mounted() {
            let that = this
            bus.$on('showEditDialog', function () {
                that.showFormfuck=true
            });
            this.listCategory();
            this.getPaperList();

        }
    }
</script>
<style>
    .uploadToolBox {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
</style>
