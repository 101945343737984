<template>
    <section class="main">
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span></label>
            <!--<el-breadcrumb separator="/">-->
            <!--<el-breadcrumb-item><i class="el-icon-rank"></i>心选商品管理</el-breadcrumb-item>-->
            <!--</el-breadcrumb>-->
        </div>

        <div class="container">
            <div class="drag-box">
                <div class="drag-box-item">
                    <div class="dragitem tabletd">
                        <div style="width: 15%">轮播图</div>
                        <div style="width: 15%">折扣</div>
                        <div style="width: 35%">心选商品标题</div>
                        <div style="width: 20%">操作</div>
                    </div>
                    <draggable v-model="heartChoiceData" @remove="removeHandle" :options="dragOptions">
                        <transition-group tag="div" id="todo" class="item-ul">
                            <div v-for="(item,index2) in heartChoiceData" class="drag-list" :key="item.primary_pic_url">
                                <!-- <img :src="item.picture">/ -->
                                <el-tooltip class="item" effect="dark" :content="'跳转 id：' + item.jump_id" placement="top">
                                    <div class="dragitem tabletd">
                                        <div class="dragimg tabletd" style="width: 15%">
                                            <img :src="item.primary_pic_url" style="width:80px;height: 60px">
                                        </div>
                                        <div class="dragimg tabletd" style="width: 15%">
                                           {{item.discount}}
                                        </div>
                                        <div style="display: block;max-width: 35%;min-width: 35%" title="心选标题">
                                            <el-button @click="jumpToDetail(item)" type="text">{{item.name}}</el-button>
                                        </div>
                                        <div title="操作" class='tabletd' style="width: 20%;font-size: 20px;">
                                            <i class="el-icon-edit-outline ft25 pointer" title="编辑"
                                               @click="editPaper(item,item.id)"></i>
                                            <i @click="offlineREPO(item.id)" class="el-icon-remove-outline pointer"
                                               title="下线"></i>
                                            <i class="el-icon-delete ft25 red pointer" title="删除"
                                               @click="deleteBana(item.id)"></i>
                                        </div>
                                    </div>
                                </el-tooltip>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
            </div>
        </div>

        <el-button type="success" @click="changeBanaSort" style="margin-top: 20px;" round>更改轮播顺序</el-button>
        <el-button type="primary" round @click="addNewAds">新增心选商品</el-button>
        <!-- 新增心选商品组件 -->

        <adsadd :adsadd="adsaddStatus"></adsadd>

        <editAds :adsEditData="adsEditData" @reloadHeartChoicemanage="loadHeartChoice" @reloadHeartChoiceDraft="reloadHeartChoiceDraft"></editAds>
    </section>
</template>

<script>
    import draggable from 'vuedraggable'
    import adsadd from './newHeartChoice'
    import editAds from './editHeartChoice'
    import bus from '../../common/bus'

    export default {
        data() {
            return {
                dragOptions: {
                    animation: 120,
                    scroll: true,
                    group: 'sortlist',
                    ghostClass: 'white'
                },
                heartChoiceData: [],
                advertType: [],
                adsaddStatus: true,
                adsEditData: {}
            }
        },
        watch: {
        },
        components: {
            draggable, adsadd, editAds
        },
        created() {
            this.loadHeartChoice()
            // 尝试调用本地字典
            this.advertType = this.$getDicByName('ADVERT_TYPE')
        },
        mounted() {
            bus.$on('reloadHeartChoice', msg => {
                this.loadHeartChoice()
            })
        },
        methods: {
            // 跳转到详情页
            jumpToDetail (item) {
                this.$router.push('goodsedit?id=' + item.jump_id);
            },
            reloadHeartChoiceDraft() {
                this.loadHeartChoice()
            },
            // 跳转到编辑页面，巴士负责传递id
            editPaper(row, id) {
                this.adsEditData = row;
                this.adsEditData.showForm = true;
                bus.$emit('showEditDialog')
            },
            offlineREPO(id) {
                this.$confirm(
                    '您正在执行下线轮播心选商品操作，下线后首页将不会显示该条轮播心选商品', '提示', {
                        confirmButtonText: '下线',
                        cancelButtonText: '取消'
                    }
                ).then(() => {
                    this.$http({
                        url: this.$api + '/admin/goods/updateHeartChoice',
                        method: 'post',
                        data: this.$qs.stringify({
                            is_on_sale: 0,
                            id: id
                        })
                    }).then(res => {
                        if (res.data.errno === 0) {
                            // //console.log(res.data)
                            this.$message.success('下线成功！')
                            this.loadHeartChoice()
                            let reload = true
                            bus.$emit('reloadHeartChoiceDraft', reload)
                        } else {
                            this.$message.error(res.data.errmsg)
                        }
                    }).catch(res => {
                        this.$message.error('下线失败')
                    })
                })
            },
            addNewAds() {
                this.adsEditData.showForm = true;
                bus.$emit('showAddDialog', this.adsEditData)
            },
            loadHeartChoice() {
                this.$http({
                    url: this.$api + '/admin/goods/heartChoice',
                    method: 'post',
                }).then(res => {
                    if (res.data !== null) {
                        this.heartChoiceData = res.data.data
                    } else {
                        this.$message.error('列表加载失败')
                    }
                }).catch(res => {

                })
            },
            deleteBana(val) {
                this.$confirm(
                    '您正在执行删除轮播心选商品操作，删除后首页将不会显示该条轮播心选商品', '提示', {
                        confirmButtonText: '删除',
                        cancelButtonText: '取消'
                    }
                ).then(() => {
                    this.$http({
                        url: this.$api + '/admin/goods/deleteHeartChoice',
                        method: 'post',
                        data: {id: val}
                    }).then(res => {
                        this.$message.success('删除成功');
                        this.loadHeartChoice()
                        //
                    }).catch(res => {
                        this.$message.info('操作取消！');
                    })
                })
            },
            changeBanaSort() {
                // uploadSort = uploadSort.join(',')
                this.$message.info('正在开发中！')
                return
                this.$http({
                    url: this.$api + '/admin/ads/changeSort',
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: this.heartChoiceData
                }).then(res => {
                    //
                    if (res.data.errno === 0) {
                        this.$message.success('修改轮播顺序成功！')
                        this.loadHeartChoice()
                    }
                }).catch(res => {
                    this.$message.error('更新失败')
                })

            },
            removeHandle(event) {
                //
                // this.$message.success(`从 ${event.from.id} 移动到 ${event.to.id} `);
            }
        }
    }

</script>

<style scoped>

    .ghost-style {
        display: block;
        color: transparent;
        border-style: dashed
    }

    .drag-box-item {
        width: 100%;
        max-width: 100%;
    }

    .item-ul {
        height: 100%;
    }

    .dragitem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        padding: 5px;
        border-bottom: 0.5px solid #e1e4e8;
    }

    .tabletd {
        text-align: center;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
