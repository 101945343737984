<template>
    <!-- 备用dash -->
    <div :id="idAttr" style="width: 100%;height: 400px;"></div>
</template>
<script>
    export default {
        name: '',
        data() {
            return {
                chartsSource: [],
                title: '',
                chartsLegend: [],
            }
        },
        props: {
            chartsData: {
                type: Array,
                require:true
            },
            idAttr: {
                type: String,
                default: 'main'
            },
            name: {
                type: String,
                default: '数值'
            },
            type: {
                type: String,
                default: 'line'
            }
        },
        watch: {
            chartsData: function (data) {
                this.chartsSource = [];
                // 表头
                this.title = data[0];
                let length = data[1].length;
                // 表格数据线名称
                this.chartsLegend=data[1].slice(1);
                let localData=data.slice(1);
                for (let i in localData) {
                    let s = []
                    //先取时间-X轴数据
                    for (var key in localData[i]) {
                        if (key.endsWith('Time')){
                            s.push(localData[i][key]);
                            break
                        }
                    }
                    //取Y轴数据
                    for (var key in localData[i]) {
                        if (!key.endsWith('Time')){
                            s.push(localData[i][key])
                        }
                    }
                    this.chartsSource.push(s)
                }
                this.$nextTick(function () {
                    this.drawPie(this.idAttr)
                })
            }
        },
        mounted() {
        },
        methods: {
            drawPie(id) {
                this.charts = this.$echarts.init(document.getElementById(id));
                // 指定图表配置项和数据
                this.charts.setOption({
                    // 图表表头
                    title: {
                        text: this.title
                    },
                    // 曲线/直方图名称
                    legend: {
                        data:this.chartsLegend
                    },
                    tooltip: {
                        trigger: 'axis',
                        position: function (pt) {
                            return [pt[0], '10%'];
                        }
                    },
                    // 工具栏
                    toolbox: {
                        show: true,
                        feature: {
                            mark: {show: true},
                            dataView: {show: true, readOnly: false},
                            magicType: {show: true, type: ['line', 'bar']},
                            restore: {show: true},
                            saveAsImage: {show: true}
                        }
                    },
                    calculable: true,
                    dataset: {
                        // 提供一份数据。
                        source: this.chartsSource
                    },
                    // 横坐标
                    // 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
                    xAxis:
                        {
                            type: 'category'
                        }
                    ,
                    // 声明一个 Y 轴，数值轴。
                    yAxis: {},
                    // 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
                    // 例如：
                    // series: [{
                    //     name: '销量', // 图表数据命名
                    //     type: 'bar', // 图表类型
                    //     data: [5, 20, 36, 10, 10, 20] // y轴数据
                    // }]
                    series: [{
                            name: this.name, // 图表数据命名
                            type: this.type, // 图表类型
                        }]

                })
                // window.addEventListener("resize", this.chart.resize);
            }
        },
        //调用
        mounted() {
            // 在watch 中调用charts
        }
    }
</script>
<style scoped>
    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }
</style>
