<template>
    <div >
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}人）</label>
        </div>
        <div class="button-tool">
            <div class="search-title">
                <el-input placeholder="请输入用户名查询" v-model="searchTitle" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>
            <el-table :data="tableData.data"
                  v-loading="!tableData.data" style="width: 100%"
                  @selection-change="handleSelectionChange">
            <el-table-column label="头像" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.group_avatar" style="width: 40px;vertical-align: middle;">
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="用户id" prop="belong_user_id" width="80"></el-table-column>
            <el-table-column label="真实姓名" prop="real_name" width="150"></el-table-column>
            <el-table-column label="微信号" prop="last_login_ip" width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.wechat}}</span>
                </template>
            </el-table-column>
            <el-table-column label="手机号" prop="mobile" width="120"></el-table-column>

            <el-table-column label="提现状态" width="100">
                <template slot-scope="scope">
                    <span class="color_09bb07 font-size-15 fontWeight">{{scope.row.apply_status_str}}</span>
                </template>
            </el-table-column>

            <el-table-column label="提现金额" width="150">
                <template slot-scope="scope">
                    <span class="color_eb3021 fontWeight font-size-15">￥ {{ scope.row.commission_cash }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请时间" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.apply_time }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div class="disflex_between_center">
                        <el-button type="primary" @click="selectWithdrawal = scope.row;showJudgeBox = true;">
                            {{ selectWithdrawal.apply_status === 1? '审核':'详情' }}
                        </el-button>

                        <el-button type="danger"  v-if="scope.row.apply_status === 3" @click="selectWithdrawal = scope.row;showTransferBox = true;">
                            发起转账
                        </el-button>
                    </div>
                </template>
            </el-table-column>

        </el-table>

        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <el-dialog :visible.sync="showJudgeBox" custom-class="question-dialog" title="提现申请审核" @close="showJudgeBox = false">
            <el-form  ref="ruleForm" label-width="150px" class="form_con">
                <!--基本信息-->
                <div class="width100 left">
                    <el-form-item label="用户真实姓名">
                        <p>{{selectWithdrawal.real_name}}</p>
                        <p v-if="!selectWithdrawal.real_name" class="color_eb3021">该小组长未提供真实姓名！请驳回重审！</p>
                    </el-form-item>
                    <el-form-item label="提现金额" prop="update_time">
                        <span class="color_eb3021 fontWeight font-size-15">￥ {{ selectWithdrawal.commission_cash }}</span>
                    </el-form-item>
                </div>
            </el-form>
            <p class="padding15 color_eb3021 font-size-15 fontWeight" v-if="selectWithdrawal.is_not_valid_take">该提现存在未完结订单，请驳回提现，防止因为串联刷单、返佣诈骗！</p>
            <el-alert title="提现对应的订单情况" type="info" :closable="false" class="coupon_title"></el-alert>
            <el-table :data="selectWithdrawal.apply_orders" :row-class-name="tableRowClassName">
                <el-table-column label="订单id" prop="order_id" width="150"></el-table-column>
                <el-table-column label="订单状态" prop="order.order_status" width="150"></el-table-column>
                <el-table-column label="订单金额" width="150">
                    <template slot-scope="scope">
                        <p>支付金额：{{scope.row.order.actual_price}}</p>
                        <p>订单总价：{{scope.row.order.order_price}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="返佣商品" width="150">
                    <template slot-scope="scope">
                        <img :src="scope.row.goods.primary_pic_url" alt="" class="width60px">
                        <p class="color_666">{{scope.row.goods.name}}</p>
                    </template>
                </el-table-column>
                <el-table-column label="下单时间" prop="order.add_time" width="120"></el-table-column>
            </el-table>
            <p class="padding15 color_eb3021 font-size-15 fontWeight" v-if="selectWithdrawal.is_not_valid_take">该提现存在未完结订单，请驳回提现，防止因为串联刷单、返佣诈骗！</p>
            <div class="text-align-right" v-if="selectWithdrawal.apply_status === 1">
                <div style="display: inline-block;width: 250px;">
                    <div class="padding15">
                        <el-alert title="审核备注（驳回理由）" type="info" :closable="false" class="coupon_title"></el-alert>
                        <el-input class="width100 "  type="textarea" v-model="FAIL_REASON" placeholder="驳回申请必填驳回理由，审核通过选填！" ></el-input>
                    </div>
                    <el-button type="primary"  @click="updateJudgeStatus('TRANSFER')">
                        审核通过
                    </el-button>

                    <el-button type="danger" @click="updateJudgeStatus('FAIL')">
                        驳回提现
                    </el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 转帐的窗口 -->
        <el-dialog :visible.sync="showTransferBox" custom-class="question-dialog" title="发起转账" @close="showTransferBox = false">
            <el-alert title="当前审核状态" type="info" :closable="false" class="coupon_title"></el-alert>
            <el-form  ref="ruleForm" label-width="150px" class="form_con">
                <!--基本信息-->
                <div class="width100 left">
                    <el-form-item label="审核进度">
                        <span class="color_09bb07 font-size-15 fontWeight">{{selectWithdrawal.apply_status_str}}</span>
                    </el-form-item>
                </div>
            </el-form>
            <p class="padding15 color_eb3021 font-size-15 fontWeight" v-if="selectWithdrawal.is_not_valid_take">该提现存在未完结订单，请驳回提现，防止因为串联刷单、返佣诈骗！</p>
            <el-alert title="提现详情" type="info" :closable="false" class="coupon_title"></el-alert>
            <div class="width100 disflex_around_center">
                <div class="width50 box_sizing">
                    <el-form  ref="ruleForm" label-width="150px" class="form_con">
                        <!--基本信息-->
                        <div class="width100 left">
                            <el-form-item label="真实姓名">
                                <span class="color_09bb07 font-size-15 fontWeight">{{selectWithdrawal.real_name}}</span>
                            </el-form-item>
                            <el-form-item label="联系方式">
                                <span>{{selectWithdrawal.mobile + ' / ' +  selectWithdrawal.wechat}}</span>
                            </el-form-item>
                            <el-form-item label="小组名">
                                <span>{{selectWithdrawal.group_name}}</span>
                            </el-form-item>
                            <el-form-item label="头像">
                                <img :src="selectWithdrawal.group_avatar" alt="">
                            </el-form-item>
                            <el-form-item label="返佣模板">
                                <div class="width100 cursor_pointer"  @click="$router.push('/commissionTemplateDetail?template_id=' + selectWithdrawal.template_id)">
                                    <p class="color_09bb07"> {{selectWithdrawal.template_id}}</p>
                                </div>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
                <div class="width50 box_sizing">
                    <el-form  ref="ruleForm" label-width="150px" class="form_con">
                        <!--基本信息-->
                        <div class="width100 left">
                            <el-form-item label="申请状态">
                                <span>{{selectWithdrawal.apply_status_str}}</span>
                            </el-form-item>
                            <el-form-item label="申请时间">
                                <span>{{selectWithdrawal.apply_time}}</span>
                            </el-form-item>
                            <el-form-item label="审核更新时间">
                                <span>{{selectWithdrawal.judge_time}}</span>
                            </el-form-item>
                            <el-form-item label="审核运营id">
                                <span>{{selectWithdrawal.judge_user}}</span>
                            </el-form-item>

                            <el-form-item label="审核备注">
                                <span class="color_eb3021 font-size-15 fontWeight">{{selectWithdrawal.judge_remarks}}</span>
                            </el-form-item>

                            <el-form-item label="转帐金额: ￥">
                                <el-input class="color_eb3021 font-size-15 fontWeight" v-model="selectWithdrawal.commission_cash"></el-input>
                            </el-form-item>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="text-align-right" v-if="selectWithdrawal.apply_status === 3">
                <div style="display: inline-block;width: 250px;">
                    <el-button type="danger"  @click="payToUser">
                        开始转帐
                    </el-button>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import bus from '../../common/bus';
    import md5 from 'js-md5'
    export default {
        data: function () {
            return {
                tableData: {},
                showJudgeBox: false, // 审核窗口
                showTransferBox: false, // 发起转账窗口
                selectWithdrawal: {},
                FAIL_REASON: '',
                searchTitle: ''
            }
        },
        components: {},
        watch: {
            selectWithdrawal (newv) {
                let is_not_valid_take = false;
                for (let item in newv.apply_orders) {
                    console.log(newv.apply_orders[item].order.order_status)
                    if (newv.apply_orders[item].order.order_status <= 201 || newv.apply_orders[item].order.order_status > 303) {
                        is_not_valid_take = true;
                        break;
                    }
                }
                this.selectWithdrawal.is_not_valid_take = is_not_valid_take;
            }
        },
        created() {
            this.getPaperList(1, 10)
        },
        mounted() {

        },
        methods: {
            // 开始转帐给用户
            async payToUser () {
                let that = this;
                const h = this.$createElement;
                this.$msgbox({
                    title: '转账确认',
                    message: h('p', null, [
                        h('span', null, '请再次确认转帐： '),
                        h('p', {style: 'padding: 5px'}, ''),
                        h('span', {style: 'font-size: 15px;font_weight: bold;'}, '收款人： '),
                        h('span', { style: 'color: teal' }, `${that.selectWithdrawal.real_name}`),
                        h('p', {style: 'padding: 5px'}, ''),
                        h('span', {style: 'font-size: 15px;font_weight: bold;'}, `转帐金额： `),
                        h('span', {style: 'font-size: 15px;font_weight: bold;color: #eb3021;'}, `￥${that.selectWithdrawal.commission_cash}`),
                        h('p', { style: 'color: teal' }, '真实姓名有误将不能到账！请确认！')
                    ]),
                    showCancelButton: true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消'
                }).then(action => {
                    if (action === 'confirm') {
                        that.$prompt('请输入转账备注！（必填，用于后期跟踪账单）', '提示', {
                            confirmButtonText: '确认',
                        }).then(pay_desc => {
                            that.$prompt('请输入转账操作密码', '提示', {
                                confirmButtonText: '开始转帐',
                                cancelButtonText: '取消转帐',
                            }).then(postPaySecret => {
                                let postData = {
                                    postPaySecret: md5(postPaySecret.value), // 操作密码
                                    re_user_name: that.selectWithdrawal.real_name, // 转帐真实姓名
                                    service_id: this.selectWithdrawal.service_id, // 公众号用户id
                                    amount:  this.selectWithdrawal.commission_cash, // 转帐金额
                                    desc: pay_desc.value, // 转账备注
                                    withdrawal_id: that.selectWithdrawal.id, // 提现的id
                                    REQUIRE_TYPE: 'TEAM_USER_WITHDRAWAL_TRANSFER' // 转帐的类型
                                };
                                console.log(postData)
                                // 开始提交微信转帐
                                this.$http({
                                    url: this.$api + '/admin/serviceAccountPay/payToWechatServiceAccountUser',
                                    method: 'post',
                                    data: postData
                                }).then(response => {
                                    console.log(response)
                                    if (response.data.errno === 0) {
                                        this.$message.success(response.data.data.msg);
                                        this.showTransferBox = false;
                                        this.getPaperList(this.tableData.currentPage, this.tableData.pageSize)
                                    } else {
                                        this.$message.error(response.data.errmsg)
                                    }
                                })
                            }).catch((e) => {
                                that.$message({
                                    type: 'info',
                                    message: e
                                });
                            });
                        }).catch((e) => {
                            that.$message({
                                type: 'info',
                                message: e
                            });
                        });
                    }
                });
            },
            // 审核提现申请
            async updateJudgeStatus (confirm_status) {
                // 驳回申请必须提供理由
                if (confirm_status === 'FAIL' && !this.FAIL_REASON) {
                    this.$message.error('驳回申请请提供理由，该理由将会直接告知用户！请谨慎措辞！');
                    return
                }
                this.$http({
                    url: this.$api + '/admin/serviceAccount/confirmGroupCashWithdrawal',
                    method: 'post',
                    data: {
                        service_id: this.selectWithdrawal.service_id,
                        judge_remarks: this.FAIL_REASON,
                        withdrawal_id: this.selectWithdrawal.id,
                        confirm_status: confirm_status
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('更新审核成功！');
                        this.getPaperList(this.tableData.currentPage, this.tableData.pageSize)
                    } else {
                        this.$message.error(response.data.msg)
                    }
                })

                this.FAIL_REASON = '';
                this.showJudgeBox = false
            },
            // 警示信息显示红色底
            tableRowClassName({row}) {
                if (row.order.order_status <= 201 || row.order.order_status > 303) {
                    return 'warning-table-row';
                }
                return '';
            },
            handleSelectionChange (val) {
                this.selectUserList = val
            },
            handleSearchByTitle() {
                this.getPaperList(this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
            },
            handleSizeChange(val) {
                this.getPaperList(this.tableData.currentPage || 1, val, this.searchTitle)
            },
            handleCurrentChange(val) {
                this.getPaperList(val, this.tableData.pageSize || 10, this.searchTitle)
            },
            getPaperList(page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/withdrawal/list',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page || 1,
                        size: length || 10,
                        name: title || '',
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style >

    .warning-table-row {
        background: #fbe8e2 !important;
    }
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .pointer {
        cursor: pointer;
    }
</style>

