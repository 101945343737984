<template>
    <div v-if="adsEditData.showForm">

        <el-dialog :visible.sync="showFormfuck" width="80%" title="新增轮播图" :before-close="closeDialog">
            <div class="disflex_flex_start">
                <el-form :model="adsEditData"  ref="adsEditData" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="封面" required>
                        <upload-image v-model="adsEditData.activity_img" ></upload-image>
                    </el-form-item>
                    <el-form-item label="商品名称" required>
                        <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入商品名"
                            v-model="adsEditData.goods_name">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="简介" required>
                        <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入简介"
                            v-model="adsEditData.goods_bref">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="原价">
                       <p>{{adsEditData.origin_price}}</p>
                    </el-form-item>

                    <el-form-item label="折扣" required>
                        <p>{{adsEditData.goods_discount}}</p>
                        <p>折后价：{{adsEditData.goods_discount * adsEditData.origin_price}}</p>
                    </el-form-item>
                    <el-form-item label="年货价" required>
                        <el-input
                            placeholder="请输入年货价"
                            v-model="adsEditData.selling_price">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="goods_sn" required>
                        <el-input
                            placeholder="分类id"
                            v-model="adsEditData.goods_sn">
                        </el-input>
                    </el-form-item>

                    <el-form-item label="分类id" required>
                        <el-input
                            placeholder="分类id"
                            v-model="adsEditData.category_id">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="排序" required>
                        <el-input
                            placeholder="请输入排序"
                            v-model="adsEditData.sort">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="内容ID" required>
                        <el-input
                            placeholder="内容的ID"
                            v-model="adsEditData.jump_id">
                        </el-input>
                     </el-form-item>


                </el-form>
                <div  class="width50" style="margin-left: 10%;border: 1px solid #eee;">

                    <el-input placeholder="请输入商品名查询" v-model="searchStr" @keyup.enter.native="getPaperList(1, 15)"
                              style="width: 500px">
                        <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="getPaperList(1, 15)"></i>
                    </el-input>
                    <el-table
                        ref="singleTable"
                        :data="tableData.data"
                        highlight-current-row
                        @current-change="handelCurrentSelect"
                        style="width: 100%;cursor: pointer;">
                        <el-table-column
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column
                            property="date"
                            label="商品标题"
                            width="">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <!--<el-table-column-->
                            <!--property="name"-->
                            <!--label="商品简介"-->
                            <!--width="120">-->
                            <!--<template slot-scope="scope">-->
                                <!--<span style="margin-left: 10px">{{ scope.row.goods_brief }}</span>-->
                            <!--</template>-->
                        <!--</el-table-column>-->
                        <el-table-column
                            property="name"
                            label="商品价格"
                            width="120">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px"> ￥ {{ scope.row.retail_price }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="paginaion" style="text-align: center;margin-top: 1em">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="tableData.currentPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="tableData.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="tableData.count">
                        </el-pagination>
                    </div>
                </div>

                <div style="text-align: right;">
                    <el-button type="success" round @click="submitAds">发布</el-button>
                </div>

            </div>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import uploadImage from '../../common/uploadImage';


    export default {
        data() {
            return {
                showFormfuck: true,
                media_type_arr:[
                    {name: '商品', value: 1},
                    {name: '分类', value: 2}
                ],
                tableData: {},
                searchStr: '',
                adsEditData: {
                    showForm: true,
                    sort: 1,
                    type: 'NEW YEAR',
                    activity_img: '',
                    category_id: '',
                    content: '',
                    goods_bref: '',
                    goods_discount: 1,
                    goods_id: '',
                    goods_name: '',
                    goods_sn: '',
                    jump_id: '',
                    link: '',
                    origin_price: 0,
                    selling_price: 0
                }
            }
        },
        components: {
            uploadImage
        },
        computed: {

        },
        watch: {


        },
        created() {

        },
        mounted() {
            let that = this
            bus.$on('showAddDialog', function (data) {
                that.showFormfuck=true
                that.adsEditData.showForm = true
            });
            this.listCategory();
            this.getPaperList();
        },
        methods: {
            //按标题搜索列表
            // handleContentType() {
            //     this.getPaperList(this.currentTagId, this.currentPage, this.size)
            // },
            // //按标题搜索列表
            // handleSearchByTitle() {
            //     this.getPaperList(this.currentTagId, this.currentPage, this.size)
            // },
            handleSizeChange(val) {
                this.getPaperList(this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(val, this.tableData.pageSize)
            },
            handelCurrentSelect (val) {
                console.log(val)
                this.adsEditData.goods_name = val.name;
                this.adsEditData.goods_bref = val.goods_brief;
                this.adsEditData.category_id = val.category_id;
                this.adsEditData.goods_sn = val.goods_sn;
                this.adsEditData.goods_id = val.id; // 商品id
                this.adsEditData.content = val.goods_brief;
                this.adsEditData.activity_img = val.list_pic_url; // 主图
                this.adsEditData.type = 'NEW YEAR'; // 主图
                this.adsEditData.origin_price = val.retail_price; // 主图
                this.adsEditData.selling_price = val.retail_price; // 主图
                this.adsEditData.jump_id = val.id;
                this.adsEditData.is_delete = 0;
                this.adsEditData.is_online = 1;
                this.adsEditData.goods_discount = val.discount || 1;
                this.adsEditData.link = '/pages/goods/goods?id=' + val.id;

                console.log(this.adsEditData)
            },
            submitAds () {
                this.$http({
                    url: this.$api + '/admin/goods/saveActivity',
                    method: 'post',
                    data: this.$qs.stringify(this.adsEditData)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('新增年货成功！注意！！！该操作在更新缓存后生效！');
                        this.adsEditData.showForm = false
                        bus.$emit('reloadAdsList')
                    }
                })
            },
            closeDialog (done) {
                done()
            },
            listCategory() {
                this.$http({
                    url: this.$api + '/admin/category/parent',
                    method: 'post'
                }).then(response => {
                    if (response.data != null) {
                        this.category = response.data.data
                    }
                })
            },
            getPaperList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/goods/index',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 15,
                        name: this.searchStr
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                }).catch(e => {
                    loading.close()
                })
            },
        },

    }
</script>
<style>
    .uploadToolBox {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
</style>
