<template>
    <div>
        <el-dialog :visible.sync="showCommon" width="80%" :before-close="beforeDialogClose">
            <!-- :title="'文章评论(共'+commons.total+'条)'" -->
            <div class="disflexft">
                <div class="headimg">
                    <img :src="commonData.rows? commonData.rows.picture : ''" width="100px">
                </div>

                <div class="headmsg" style="margin-left: 20px">
                    <div class="papername"><span class="a7Text">标题：</span>{{ commonData.rows? commonData.rows.title : ''
                        }}
                    </div>
                    <div class="paperauthor"><span class="a7Text">作者：</span>{{ commonData.rows? commonData.rows.author :
                        '' }}
                    </div>
                    <div class="papertime"><span class="a7Text">发布时间：</span>{{ commonData.rows?
                        commonData.rows.releaseTime : ''}}
                    </div>
                </div>
            </div>
            <div class="disflexbt" style="width: 100%;">
                <div class="sortType">
                    <span class="a7Text">评论排序:</span>
                    <el-button plain @click="newsCommon" :type="buttonActivefornews">最新评论</el-button>
                    <el-button plain @click="mostTips" :type="buttonActivefortips">最多点赞</el-button>
                </div>
                <div class="operateComents">
                    <el-button class="editor-btn" type="danger" @click="deleteCommom">删除评论</el-button>
                    <el-button class="editor-btn" type="info" @click="addUserToBlackList">加入黑名单</el-button>

                </div>
            </div>
            <el-table :data="commonData.records" style="width: 100%" ref="multiSelect"
                      @selection-change="handleSelectionChange">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column label="头像" width="50">
                    <template slot-scope="conments">
                        <div slot="reference" class="name-wrapper">
                            <img :src="conments.row.heads" style="width: 40px;vertical-align: middle;">
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="用户名" width="150">
                    <template slot-scope="conments">
                        <div slot="reference" class="name-wrapper">
                            <el-tag size="medium">{{ conments.row.nickName }}</el-tag>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="评论内容">
                    <template slot-scope="conments">
                        <div slot="reference" class="name-wrapper">
                            <span size="medium">{{ conments.row.content }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="点赞数" width="100">
                    <template slot-scope="conments">
                        <div slot="reference" class="name-wrapper">
                            <span size="medium">{{ conments.row.praiseNum }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="发布时间" width="150">
                    <template slot-scope="conments">
                        <div slot="reference" class="name-wrapper">
                            <span size="medium">{{ formatStrTime(conments.row.createTime,16) }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="paginaion" style="text-align: center;margin-top: 1em">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import vHead from './Header.vue';
    import vSidebar from './Sidebar.vue';
    import vTags from './Tags.vue';
    // import bus from '../common/bus';
    export default {
        data() {
            return {
                selectList: [],
                idlist: '',
                userIdList: [],
                id: '',
                page: 1,
                row: {},
                collapse: false,
                showCommon: false,
                currentPage: 1,
                total: 0,
                size: 50,
                sort: 0,
                showCommonType: '',
                loadurl: '',
                commonData: [],
                buttonActivefornews: "success",
                buttonActivefortips: "default"
            }
        },
        props: ['commons'],
        computed: {},
        mounted() {

        },
        watch: {
            selectList: {
                handler(newValue) {
                    for (var item in this.selectList) {
                        if (this.selectList[item].userId) {
                            this.userIdList.push(this.selectList[item].userId)
                        }
                        if (item == this.selectList.length - 1) {
                            this.idlist = this.idlist + this.selectList[item].id
                            // this.userIdlist = this.userIdlist  + this.selectList[item].userId
                        } else {
                            this.idlist = this.idlist + this.selectList[item].id + ','
                        }
                    }
                },
                deep: true
            },
            // 监听父组件传来的数据
            commons: function () {
                this.showCommon = this.commons.showCommon
                this.id = this.commons.rows.id
                this.page = this.commons.pages
                this.total = this.commons.total
                this.size = this.commons.size
                this.currentPage = this.commons.current
                this.showCommonType = this.commons.showCommonType
                this.commonData = this.commons
                this.row = this.commons.rows
            },
            showCommonType: function () {
                // 文章类型
                if (this.showCommonType === 'paper') {
                    this.loadurl = this.$api + '/chigua/admin/article/comment/page'
                    this.deleteUrl = this.$api + '/chigua/admin/article/comment/delete'
                    // this.addBlackUrl = this.$api + '/chigua/admin/article/comment/update'
                } else if (this.showCommonType === 'video') {
                    // 视频类型
                    this.loadurl = this.$api + '/chigua/admin/video/comment/page'
                    this.deleteUrl = this.$api + '/chigua/admin/video/comment/delete'
                    // this.addBlackUrl = this.$api + '/chigua/admin/video/comment/update'
                } else if (this.showCommonType === 'course') {
                    // 课程类型
                    this.loadurl = this.$api + '/chigua/admin/course/comment/page'
                    this.deleteUrl = this.$api + '/chigua/admin/course/comment/delete'
                    // this.addBlackUrl = this.$api + '/chigua/admin/course/comment/update'
                } else {
                    this.$message.error('未识别的评论类型')
                }
            }
        },
        methods: {
            addUserToBlackList() {
                if (this.userIdList.length == 0) {
                    this.$message.error('请至少选择一条进行操作！')
                    return
                }
                Array.prototype.distinct = function () {
                    var arr = this,
                        result = [],
                        i,
                        j,
                        len = arr.length;
                    for (i = 0; i < len; i++) {
                        for (j = i + 1; j < len; j++) {
                            if (arr[i] === arr[j]) {
                                j = ++i;
                            }
                        }
                        result.push(arr[i]);
                    }
                    return result;
                }

                let userIds = this.userIdList.distinct()
                this.$http({
                    // url: this.addBlackUrl,
                    url: this.$api + '/chigua/admin/wx/user/blacklist/join',
                    method: 'post',
                    data: this.$qs.stringify({ids: userIds.join(',')})
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.$message.success(response.data.msg)
                        // 清空list
                        this.idlist = ''
                        this.userIdList = []
                        // 重载列表
                        this.loadCommonList(this.id, this.current, this.size, this.sort)
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    // bus.$emit('loadConments',this.commonData)
                }).catch(response => {
                    this.$message.error(e.data.msg)
                    //console.log(response)
                })
            },
            deleteCommom() {
                if (this.idlist == '') {
                    this.$message.error('请至少选择一条进行操作！')
                    return
                }
                this.$http({
                    url: this.deleteUrl,
                    method: 'post',
                    data: this.$qs.stringify({ids: this.idlist})
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.$message.success(response.data.msg)
                        // 清空list
                        this.idlist = ''
                        // 重载列表
                        this.loadCommonList(this.id, this.current, this.size, this.sort)
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    // bus.$emit('loadConments',this.commonData)
                }).catch(response => {
                    this.$message.error(e.data.msg)
                    //console.log(response)
                })
            },
            beforeDialogClose(done) {
                this.idlist = ''
                done()
            },
            handleSelectionChange(val) {
                this.selectList = val

            },
            newsCommon() {
                this.sort = 0
                this.loadCommonList(this.id, 1, this.size, this.sort)
                // 切换选中样式
                this.buttonActivefornews = 'success'
                this.buttonActivefortips = 'default'
            },
            mostTips() {
                this.sort = 1
                this.loadCommonList(this.id, 1, this.size, this.sort)
                // 切换选中样式
                this.buttonActivefornews = 'default'
                this.buttonActivefortips = 'success'
            },
            // 处理数据长度
            handleSizeChange(val) {
                this.loadCommonList(this.id, this.current, val, this.sort)
            },
            // 处理当前页面位置
            handleCurrentChange(val) {
                this.loadCommonList(this.id, val, this.size, this.sort)
            },
            // 组件内加载评论列表，并更新翻页状态
            loadCommonList(id, page, length, sort) {
                let loadParam = {
                    // 提交两个ID参数名，因为课程接口需要foreignId，所以此处直接提交
                    foreignId: id,
                    articleId: id,
                    page: page,
                    limit: length,
                    sort: sort
                }
                this.$http({
                    url: this.loadurl,
                    method: 'post',
                    data: this.$qs.stringify(loadParam)
                }).then(response => {
                    this.commonData = response.data.data
                    this.commonData.showCommon = true
                    // 父组件传进来的上级元素
                    this.commonData.rows = this.row
                    // this.showCommon = true
                    this.page = this.commonData.pages
                    this.total = this.commonData.total
                    this.size = this.commonData.size
                    this.currentPage = this.commonData.current
                    // bus.$emit('loadConments',this.commonData)
                }).catch(response => {
                    this.$message.error('加载失败')
                    //console.log(response)
                })
            }
        }
    }
</script>
<style>
    .a7Text {
        color: #a7a7a7;
    }

    .disflexbt {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .disflexft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
</style>>
