<template>
    <div>
        <div class="crumbs" style="width: 100%;">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }} 单）</label>
        </div>
        <div class="button-tool">
            <div class="search-title" :style="$store.state.UA !== 'MOBILE'? '':'width: 100% !important;'">
                <el-input placeholder="请输入订单号" v-model="orderSnSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-input placeholder="请输入用户名" v-model="userNameSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-input placeholder="微信昵称" v-model="nicknameSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-input placeholder="请输入电话号码" v-model="userPhoneSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-button type="default" @click="handleSearchByTitle">搜索</el-button>

                <el-checkbox style="margin-left: 20px;" v-model="is_auto_send_sms_notice">自动发送短信通知</el-checkbox>
            </div>
        </div>

        <div class="tagSwitch">
            <el-tabs v-model="activeName" @tab-click="switchTag">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table
            :data="tableData.data"
            v-loading="!tableData.data"
            :row-class-name="tableRowClassName"
            style="width: 100%">

            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item>

                            <div class="disflex_flex_start">
                                <div>
                                    <h1>订单号：{{ scope.row.order_sn }}</h1>
                                    <p class="color_09bb07">订单生成平台：{{ scope.row.pay_way === 'app'? 'APP生成订单':scope.row.pay_way === 'mini'? "微信小程序生成订单":'未知渠道生成订单' }}</p>
                                    <p class="color_09bb07">支付渠道：{{ scope.row.pay_name }}</p>
                                    <p class="color_09bb07">支付ID：{{ scope.row.pay_id }}</p>
                                </div>
                                <div class="width100">
                                    <el-form size="samll">
                                        <fieldset class="padding15 border_eee margin15">
                                            <legend class="color_cdcdcd">多件快递信息</legend>
                                            <el-form-item style="display: block;">
                                                <span>是否为多件快递：</span>
                                                <el-switch
                                                    v-model="scope.row.ship_type"
                                                    active-color="#13ce66"
                                                    inactive-color="#ff4949">
                                                </el-switch>
                                            </el-form-item>
                                            <el-form-item style="display: block;">
                                                <div>
                                                    <span>多件快递备注（买家可见备注）：</span>
                                                    <el-input v-model="scope.row.ship_remarks" placeholder="请输入展示给买家的备注信息" ></el-input>
                                                    <el-button type="primary" @click="setOrderMultipleExpressStatus(scope.row)">保存</el-button>
                                                </div>
                                            </el-form-item>
                                        </fieldset>
                                    </el-form>
                                </div>
                                <div class="width100">
                                    <el-form size="samll">
                                        <fieldset class="padding15 border_eee margin15">
                                            <legend class="color_cdcdcd">多件快递单号</legend>
                                            <el-form-item>
                                                <el-button type="primary" @click="addOtherExp(scope.row)">新增一个单号</el-button>
                                            </el-form-item>
                                            <el-form-item v-for="(item, index) in scope.row.other_express" :key="index">
                                                <span>快递{{index + 1}}：</span>
                                                <el-input v-model="item.logisticCode" placeholder="快递单号" ></el-input>
                                                <el-input v-model="item.shipperCode" placeholder="快递公司代码" ></el-input>
                                                <el-input v-model="item.shipperName" placeholder="快递公司名称" ></el-input>
                                                <el-button type="primary" @click="addOrUpdateShipperCode(item)">保存</el-button>
                                                <el-button type="danger" @click="deleteOtherExpress(item)">删除</el-button>
                                            </el-form-item>
                                        </fieldset>
                                    </el-form>
                                </div>
                            </div>
                            <el-table :data="scope.row.goodList" style="width: 800px"
                                      border
                                      show-summary
                                      :summary-method="getSummaries"
                                      sum-text="总计需支付价格">
                                <el-table-column label="商品名称">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <img :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                            <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="购买规格">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #eb3021;font-size: 15px;">{{ scope2.row.goods_specifition_name_value }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结算单价">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结算总价" sortable>
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column
                label="销售备注" width="80">
                <template slot-scope="scope">
                    <i v-if="!scope.row.order_remarks_id" class="el-icon-circle-plus-outline cursor_pointer" style="font-size: 18px;color: #eb3021;" @click="addOrderRemarks(scope.row)"></i>
                    <span class="red cursor_pointer" style="font-weight: bold" @click="addOrderRemarks(scope.row)" v-else>{{scope.row.seller_remarks}}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="客户"
                :width="$store.state.UA !== 'MOBILE'? '100':'60'">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <div><img width="50px" :src="scope.row.AVATAR" alt=""></div>
                        <p class="color_09bbe7">{{ scope.row.NICKNAME }}</p>
                    </div>

                </template>
            </el-table-column>
            <el-table-column label="物流地址">
                <template slot-scope="scope">
                    <span>{{scope.row.consignee}}，{{scope.row.mobile}}，{{ scope.row.ProvinceName +' '+ scope.row.CityName +' '+ scope.row.DistrictName +' '+ scope.row.address }}</span>
                </template>
            </el-table-column>
            <el-table-column label="订单金额" width="100">
                <template slot-scope="scope">
                    <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.order_price }}</span>
                </template>
            </el-table-column>
            <el-table-column label="实际支付金额" width="100">
                <template slot-scope="scope">
                    <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.actual_price }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下单时间&快递" width="150">
                <template slot-scope="scope">
                  <div class="width100 text-align-center">
                      <p v-if="scope.row.ship_type" class="multipleExpress text-align-center">{{scope.row.ship_type? '多件快递':''}}</p>
                      <p style="margin-left: 10px;color: #5cadff;">{{ scope.row.add_time }}</p>
                  </div>
                </template>
            </el-table-column>
            <el-table-column label="买家备注" width="150" >
                <template slot-scope="scope">
                    <span style="color: #ff0000;font-size: 15px;">{{ scope.row.remarks }}</span>

                </template>
            </el-table-column>
            <el-table-column label="订单状态" width="100" prop="">
                <template slot-scope="scope">
                    <span v-if="scope.row.order_status_text==='已收货'" style="color: #333">{{ scope.row.order_status_text }}</span>
                    <span v-else-if="scope.row.order_status_text==='已发货'" style="color: #09bb07">{{ scope.row.order_status_text }}</span>
                    <span v-else-if="scope.row.order_status_text==='已付款'" style="color: #ff0000;font-weight: bold;">{{ scope.row.order_status_text }}</span>
                    <span v-else style="color: #eb3021;font-weight: bold;font-size: 20px;">{{ scope.row.order_status_text }}</span>
                    <span v-if="scope.row.pay_way === 'app'" style="display: inline-block;padding: 3px 8px;color: #ffffff;background-color: #1094fa;border-radius: 15px">APP订单</span>
                    <span v-else-if="scope.row.pay_way === 'mini'" style="display: inline-block;padding: 3px 8px;color: #ffffff;background-color: #09bb07;border-radius: 15px">小程序订单</span>
                    <span v-else style="display: inline-block;padding: 3px 8px;color: #ff0000;background-color: #eeeeee;border-radius: 15px">未知渠道</span>
                </template>
            </el-table-column>
            <el-table-column
                label="物流信息"
                width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <!--<span style="margin-left: 10px" :style="scope.row.order_status <= 201? 'color: #ff0000;':''">-->
                        <!---->
                        <!--{{getOrderStatusTxt(scope.row.order_status)}}-->
                        <!--&lt;!&ndash;{{ scope.row.order_status > 201? '已发货':'未发货' }}&ndash;&gt;-->

                    <!--</span>-->

                    <span :style="scope.row.LogisticCode ? '' :'color: #ff0000;'">{{scope.row.LogisticCode ? "已发货" : "未发货"}}</span>

                </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
                label="发货状态" >
                <template slot-scope="scope">
                    <el-button type="success" v-if="scope.row.order_status >= 300"  @click="addExpressFun(scope.row)">
                        <img class="icon_20" v-if="scope.row.ShipperCode === 'SF'" src="https://mini-goods.oss-cn-shenzhen.aliyuncs.com/cathouse_pro/images/sf.png" alt="">
                        <img class="icon_20" v-if="scope.row.ShipperCode === 'ZTO'" src="https://mini-goods.oss-cn-shenzhen.aliyuncs.com/cathouse_pro/images/zto.jpg" alt="">
                        预览运单
                    </el-button>
                    <div v-else>
                        <el-button type="primary"  @click="addExpressFun(scope.row)">生成运单</el-button>
                        <el-button type="success" @click="addExpressFUN_rengongfahuo(scope.row)">人工发货</el-button>
                    </div>
                    <!--<el-button type="default" v-if="scope.row.order_status > 201 && scope.row.order_status < 400">已发货</el-button>-->
                    <!--<el-button type="default" v-if="scope.row.order_status > 201 && scope.row.order_status < 400">生成运单号码 </el-button>-->
                    <el-button type="default" v-if="scope.row.order_status >= 400" @click="showRefundDetail(scope.row)">退款详情</el-button>
                </template>
            </el-table-column>
            <!-- 操作 -->
            <!--<el-table-column-->
                <!--label="订阅物流信息" v-if="$store.state.UA !== 'MOBILE'">-->
                <!--<template slot-scope="scope">-->
                    <!--&lt;!&ndash;&ndash;&gt;-->
                    <!--<el-button v-if="scope.row.order_status > 201 && scope.row.shipping_status < 1 && scope.row.order_status < 400" type="danger" @click="reSubscribeExpress(scope.row)">物流订阅失败</el-button>-->
                    <!--<el-button v-if="scope.row.order_status >= 300 && scope.row.shipping_status >= 1" type="success" @click="reSubscribeExpress(scope.row)">物流订阅成功</el-button>-->
                <!--</template>-->
            <!--</el-table-column>-->

            <!-- 其他操作 -->
            <el-table-column
                label="订单详情">
                <template slot-scope="scope">
                    <!--<el-button type="success"  @click="startRefund(scope.row)">退款</el-button>-->
                    <el-button type="info" plain @click="gotoNewPage('./orderDetail', {id: scope.row.id})">订单详情</el-button>
                    <el-button type="info" plain @click="checkWeixinOrder(scope.row.order_sn)">微信支付详情</el-button>
                    <el-button type="info" plain @click="sendOutExpressNotice(scope.row)" v-if="scope.row.LogisticCode">发货通知</el-button>
                    <el-button type="primary" plain @click="isShowEditOrderNotice = !isShowEditOrderNotice;editOrderNotice = Object.assign({}, editOrderNotice, scope.row)">通知用户</el-button>
                    <el-button type="danger" plain @click="refundThisOrder(scope.row)">退款</el-button>
                </template>
            </el-table-column>

        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[5,10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <!--查询微信支付系统详情-->
        <el-dialog
            title="查询微信支付系统详情"
            :visible.sync="showWXpayDetail"
            width="50%">

            <el-card class="box-card">
                <div class="single_weixin_info user_info">
                    <img class="portrait" :src="weixinPayDetail.user_info.avatar" alt="">
                    用户昵称：{{weixinPayDetail.user_info.nickname}}
                </div>

                <div class="single_weixin_info">查询结果信息：{{weixinPayDetail.message}}</div>
                <div class="single_weixin_info">支付总金额：{{weixinPayDetail.total_fee/100}}元</div>
                <div class="single_weixin_info">支付现金金额：{{weixinPayDetail.cash_fee/100}}元</div>
                <div v-if="weixinPayDetail.alertMessage" class="single_weixin_info price">提醒信息：{{weixinPayDetail.alertMessage}}</div>
            </el-card>


            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>

        <!--合并订单-->
        <el-dialog
            title="合并运单商品，并打印"
            :visible.sync="isShowMergeOrderGoods"
            ref="multipleTable222222"
            tooltip-effect="dark"
            width="90%"
            id="mergeOrder"
            @close="isShowMergeOrderGoods = false"
        >
            <el-table
                :data="MergeOrderGoods.data"
                v-loading="!MergeOrderGoods.data"
                style="width: 100%">

                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-form label-position="left" inline class="demo-table-expand">
                            <el-form-item>
                                <h1>订单号：{{ scope.row.order_sn }}</h1>
                                <el-table :data="scope.row.goodList" style="width: 800px"
                                          border
                                          show-summary
                                          :summary-method="getSummaries"
                                          sum-text="总计需支付价格">
                                    <el-table-column label="商品名称">
                                        <template slot-scope="scope2">
                                            <div slot="reference" class="name-wrapper">
                                                <img :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                                <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="数量">
                                        <template slot-scope="scope2">
                                            <div slot="reference" class="name-wrapper">
                                                <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="购买规格">
                                        <template slot-scope="scope2">
                                            <div slot="reference" class="name-wrapper">
                                                <span class="bold15" style="margin-left: 10px;color: #eb3021;font-size: 15px;">{{ scope2.row.goods_specifition_name_value }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="结算单价">
                                        <template slot-scope="scope2">
                                            <div slot="reference" class="name-wrapper">
                                                <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="结算总价" sortable>
                                        <template slot-scope="scope2">
                                            <div slot="reference" class="name-wrapper">
                                                <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column
                    label="销售备注" width="80">
                    <template slot-scope="scope">
                        <i v-if="!scope.row.order_remarks_id" class="el-icon-circle-plus-outline cursor_pointer" style="font-size: 18px;color: #eb3021;" @click="addOrderRemarks(scope.row)"></i>
                        <span class="red cursor_pointer" style="font-weight: bold" @click="addOrderRemarks(scope.row)" v-else>{{scope.row.seller_remarks}}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    label="客户"
                    :width="$store.state.UA !== 'MOBILE'? '100':'60'">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <p class="color_09bbe7">{{ scope.row.consignee }}</p>
                        </div>

                    </template>
                </el-table-column>
                <el-table-column label="物流地址" width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.mobile}}—{{ scope.row.ProvinceName +' '+ scope.row.CityName +' '+ scope.row.DistrictName +' '+ scope.row.address }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订单金额" width="100">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.order_price }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="实际支付金额" width="100">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.actual_price }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="下单时间" width="150" prop="add_time"></el-table-column>
                <el-table-column label="买家备注" width="150" >
                    <template slot-scope="scope">
                        <span style="color: #ff0000;font-size: 15px;">{{ scope.row.remarks }}</span>

                    </template>
                </el-table-column>
                <el-table-column label="订单状态" width="100" prop="">
                    <template slot-scope="scope">
                        <span v-if="scope.row.order_status_text==='已收货'" style="color: #333">{{ scope.row.order_status_text }}</span>
                        <span v-else-if="scope.row.order_status_text==='已发货'" style="color: #09bb07">{{ scope.row.order_status_text }}</span>
                        <span v-else-if="scope.row.order_status_text==='已付款'" style="color: #ff0000;font-weight: bold;">{{ scope.row.order_status_text }}</span>
                        <span v-else style="color: #eb3021;font-weight: bold;font-size: 20px;">{{ scope.row.order_status_text }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="物流信息"
                    width="150">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <!--<span style="margin-left: 10px" :style="scope.row.order_status <= 201? 'color: #ff0000;':''">-->
                        <!---->
                        <!--{{getOrderStatusTxt(scope.row.order_status)}}-->
                        <!--&lt;!&ndash;{{ scope.row.order_status > 201? '已发货':'未发货' }}&ndash;&gt;-->

                        <!--</span>-->

                        <span :style="scope.row.LogisticCode ? '' :'color: #ff0000;'">{{scope.row.LogisticCode ? "已发货" : "未发货"}}</span>

                    </template>
                </el-table-column>
                <!-- 其他操作 -->
                <el-table-column
                    label="订单详情">
                    <template slot-scope="scope">
                        <!--<el-button type="success"  @click="startRefund(scope.row)">退款</el-button>-->
                        <el-button type="info" plain @click="$router.push('./orderDetail?id=' + scope.row.id)">订单详情</el-button>
                        <el-button type="info" plain @click="checkWeixinOrder(scope.row.order_sn)">支付详情</el-button>
                    </template>
                </el-table-column>

                <el-table-column
                    label="合并运单"
                    width="100">
                    <template slot-scope="scope">
                        <el-checkbox @change="MergeOrderCheckedChange" v-model="scope.row.is_checked_to_merge">合并运单</el-checkbox>
                    </template>
                </el-table-column>

            </el-table>
            <div class="padding15 text-align-right">
                <!--<el-button v-print="'#express_print'"  type="primary">打印运单</el-button>-->
                <p class="color_eb3021 font-size-10">合并订单并同步发货后，所有被合并的订单都会自动发货（同步）</p>

                <el-button @click="editExpressPaper" type="info">编辑运单</el-button>
                <el-button  type="primary" @click="mergeOrderNsendExpress">合并运单并同步发货</el-button>
            </div>
        </el-dialog>

        <!--发起退款-->
        <el-dialog
            title="主动发起退款"
            :visible.sync="isSendingRefund"
            ref="multipleTable333"
            tooltip-effect="dark"
            width="40%"
            id="isSendingRefund"
            @close="CLOSE_REFUND_DIALOG"
        >
            <el-form :model="refundData"  ref="adsEditData_com" label-width="100px" class="demo-ruleForm">
                <el-form-item label="退库金额" required>
                    <el-input
                        type="number"
                        placeholder="退款金额"
                        v-model="refundData.total">
                    </el-input>
                </el-form-item>
                <el-form-item label="退款原因" required>
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入退款原因"
                        v-model="refundData.reason">
                    </el-input>
                </el-form-item>
                <el-form-item label="退款备注" required>
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入退款备注"
                        v-model="refundData.remarks">
                    </el-input>
                </el-form-item>
                <el-form-item label="退款类型" required>
                    <el-select v-model="refundData.type">
                        <el-option  key="moneyOnly" label="仅退款" value="moneyOnly"></el-option>
                        <el-option  key="goodsNMoney" label="退货退款" value="goodsNMoney"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="上传退款凭证" required>
                    <upload-image v-model="refundData.evidence" ></upload-image>
                </el-form-item>
            </el-form>

            <div class="text-align-right">
                <el-button  type="primary" @click="generateRefundOrder">生成退款单</el-button>
            </div>
        </el-dialog>


        <el-dialog
            title="填写发货信息"
            :visible.sync="addExpress"
            width="90%">
            <h2>1、选择快递信息</h2>
            <!--<el-select @change="changeExpressCode" v-model="express.ShipperCode" placeholder="请选择快递公司" style="padding: 20px 0">-->
                <!--<el-option-->
                   <!--v-for="item in expressCompany"-->
                   <!--:key="item.value"-->
                   <!--:label="item.label"-->
                   <!--:value="item.value">-->
               <!--</el-option>-->
            <!--</el-select>-->
            <el-cascader
                v-model="ShipperCode_originList"
                :options="expressCompany"></el-cascader>
            <h2>2、确认运单信息</h2>
            <div class="disflex_around_start">
                <!--发件人信息-->
                <div class="expressBox">
                    <el-form ref="senderMsg" :model="express">
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">发件信息</legend>
                            <el-form-item :rules="[
                                  { required: true, message: '发件人不能为空'}
                                ]" prop="Sender.Name">
                                <span>发件人：</span>
                                <el-input v-model="express.Sender.Name" placeholder="请选择物流公司" ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: false, message: '发件人手机号 不能为空！'}
                                ]" prop="Sender.Mobile">
                                <span>发件人联系方式：</span>
                                <el-input v-model="express.Sender.Mobile" placeholder="手机号码" ></el-input>
                                <el-input v-model="express.Sender.Tel" placeholder="固话"  ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: true, message: '发件省市地址不能为空，省市区间用空格分开'}
                                ]" prop="Sender.FullRegion">
                                <span>发件地址：</span>
                                <el-input v-model="express.Sender.FullRegion" placeholder="请选择发件地址" ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: true, message: '发件人详细地址不能为空'}
                                ]" prop="Sender.Address">
                                <span>发件地址：</span>
                                <el-input v-model="express.Sender.Address" placeholder="详细地址" ></el-input>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
                <!--收件人信息-->
                <div class="expressBox">
                    <el-form ref="receiverMsg"  :model="express">
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">收件信息</legend>
                            <el-form-item prop="Receiver.Name" :rules="[
                                  { required: true, message: '收件人姓名不能为空！'}
                                ]">
                                <span>收件人：</span>
                                <el-input v-model="express.Receiver.Name" placeholder="请填写收件人姓名"></el-input>
                            </el-form-item>
                            <el-form-item prop="Receiver.Mobile"  :rules="[
                                  { required: true, message: '收件人手机号不能为空'}
                                ]">
                                <span>收件人联系方式：</span>
                                <el-input v-model="express.Receiver.Mobile" placeholder="收件人手机号码" ></el-input>
                                <el-input v-model="express.Receiver.Tel" placeholder="收件人固话" require ></el-input>
                            </el-form-item>
                            <el-form-item  prop="Receiver.FullRegion" :rules="[
                                  { required: true, message: '收件人省市区不能为空，省市区间用空格分开'}
                                ]">
                                <span>收件地址：</span>
                                <el-input v-model="express.Receiver.FullRegion" placeholder="请选择发件地址"></el-input>
                            </el-form-item>
                            <el-form-item  prop="Receiver.Address" :rules="[
                                  { required: true, message: '收件人详细地址不能为空！'}
                                ]">
                                <span>收件地址：</span>
                                <el-input v-model="express.Receiver.Address" placeholder="详细地址" ></el-input>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
                <!--其他物流信息-->
                <div class="expressBox">
                    <el-form ref="otherExpress" :model="express">
                        <fieldset class="padding15 border_eee" v-if="express.goodsList">
                            <legend class="color_cdcdcd">其他物流信息</legend>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>快递物件名：</span>
                                <el-input v-model="express.goodsList[0].goods_name" placeholder="请填写快递物件名"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>重量：(千克)</span>
                                <el-input v-model="express.weight" placeholder="包裹重量"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>长：（cm）</span>
                                <el-input v-model="express.space_x" placeholder="包裹长度"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>宽：(cm)</span>
                                <el-input v-model="express.space_y" placeholder="包裹宽度"  ></el-input>
                            </el-form-item>

                            <el-form-item prop="Commodity.GoodsName">
                                <span>宽：(cm)</span>
                                <el-input v-model="express.space_z" placeholder="包裹宽度"  ></el-input>
                            </el-form-item>

                            <el-form-item>
                                <span>买家备注：</span>
                                <textarea class="remarks" name="备注（如果为空，则可以填写快递备注，数据直接同步到快递，打印在快递单上）" id="" cols="20" rows="10" :value="express.Remark"
                                          @input="RemarkChange"></textarea>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="sendExpress">获取运单</el-button>
            <el-button type="default">保  存</el-button>
          </span>
        </el-dialog>
        <!--订单详情-->
        <el-dialog
            title="订单-退货退款-详情"
            :visible.sync="showRefundDetailBool"
            width="90%">
            <div class="disflex_around_start">
                <!--客户信息-->
                <div class="expressBox">
                    <el-form ref="express" v-if="refundStatus.refund">
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">用户详情</legend>
                            <el-form-item>
                                <span>用户昵称: </span>
                                <span>{{refundStatus.refund.nickname}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>收件人名: </span>
                                <span>{{refundStatus.order.consignee}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>收货电话：</span>
                                <span>{{refundStatus.order.mobile}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>收货地址：</span>
                                <span>{{refundStatus.order.ProvinceName +
                                    refundStatus.order.DistrictName +
                                    refundStatus.order.CityName +
                                    refundStatus.order.address}}
                                </span>
                            </el-form-item>
                            <el-form-item>
                                <span>注册时间：</span>
                                <span>{{refundStatus.refund.register_time}}
                                </span>
                            </el-form-item>
                            <el-form-item>
                                <span>最后登陆时间：</span>
                                <span>{{refundStatus.refund.last_login_time}}
                                </span>
                            </el-form-item>

                        </fieldset>
                    </el-form>
                </div>

                <!--退款详情-->
                <div class="expressBox" v-if="refundStatus.refund">
                    <el-form >
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">退款详情</legend>
                            <el-form-item>
                                <span>退款详情: </span>
                                <span>{{refundStatus.refund.type}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>退款原因：</span>
                                <span>{{refundStatus.refund.reason}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>退款说明：</span>
                                <span>{{refundStatus.refund.remarks}}</span>
                            </el-form-item>

                            <el-form-item>
                                <span>退款金额：</span>
                                <span class="red">￥ {{refundStatus.refund.total}} 元</span>
                            </el-form-item>

                            <el-form-item>
                                <span>发起时间：</span>
                                <span class="red">{{refundStatus.refund.add_time}}</span>
                            </el-form-item>

                            <el-form-item>
                                <span>用户上传凭证：</span>
                                <div class="disflex_warp">
                                    <a :href="item" target="_blank" v-for="(item, index) in refundStatus.refund.evidence" :key="index">
                                        <img class="width50 border_eee padding15 box_sizing cursor_pointer"
                                             :src="item" alt="" >
                                    </a>
                                </div>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>

                <!--商城订单信息-->
                <div class="expressBox">
                    <fieldset class="padding15 border_eee">
                        <legend class="color_cdcdcd">该用户下单历史</legend>
                        <el-form>
                            <el-form-item v-if="refundStatus.baseMsg">
                                <h2 class="color_09bb07">商城下单情况：</h2>
                                <div v-for="item in refundStatus.baseMsg" :key="item.order_status">
                                    <h3 class="yellow">{{item.order_status_text}}</h3>
                                    <div class="disflex_around_center">
                                        <p>{{item.order_status_text}}订单总数：{{item.countNum}}</p>
                                        <p>金额总计：{{item.totalPrice}}</p>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </fieldset>
                </div>
            </div>
            <div class="disflex_around_start">
            </div>
            <span slot="footer" class="dialog-footer">
            <!--<el-button type="primary" v-if="refundStatus.order && refundStatus.order.order_status === 401" @click="agreeThisRefund(refundStatus)">审核通过该退款</el-button>-->
            <el-button type="default" @click="showRefundDetailBool = !showRefundDetailBool">关 闭</el-button>
          </span>
        </el-dialog>
        <!--startprint-->
        <!--endprint-->

        <el-dialog
            title="预览运单"
            v-loading="mergeLoading"
            :visible.sync="BOOL_EXPRESS_HTML" @close="closePreTransportOrder"
            width="65%">

            <div class="text-align-right">
                <el-button @click="editExpressPaper" type="info">编辑运单</el-button>
                <el-button v-print="'#express_print'"  type="primary">打印运单</el-button>
            </div>
            <div class="width100">
                <div id="express_print" style="position: relative; height: 1500px;width: 100%;" v-if="BOOL_EXPRESS_HTML"  v-html="EXPRESS_HTML"></div>
            </div>

            <div class="text-align-right">
                <el-button @click="editExpressPaper" type="info">编辑运单</el-button>
                <el-button v-print="'#express_print'"  type="primary">打印运单</el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="合并运单成功！"
            :visible.sync="showPrintExpressButton" @close="showPrintExpressButton = false"
            width="30%">
            <div class="text-align-right">
                <el-button @click="editExpressPaper" type="info">编辑运单</el-button>
                <el-button v-print="'#express_print'" type="primary">打印运单</el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="添加订单销售备注"
            :visible.sync="addOrderRemarksBool"
            width="50%">
                    <el-form>
                        <el-form-item>
                            <span>是否紧急订单: </span>
                            <el-switch
                                v-model="orderRemarksBaseData.is_emergency"
                                active-text="紧急"
                                active-color="#eb3021"
                                inactive-color="#eee">
                            </el-switch>
                        </el-form-item>
                        <el-form-item>
                           <span>销售备注: </span>
                           <el-input  v-model="orderRemarksBaseData.seller_remarks"></el-input>
                        </el-form-item>
                    </el-form>

                    <div class="width100 text-align-right">
                        <el-button type="primary" @click="switchEmergency">更新订单发货备注</el-button>
                        <el-button type="default" @click="addOrderRemarksBool = !addOrderRemarksBool">取 消</el-button>
                    </div>
        </el-dialog>
        <!--和用户沟通，提醒用户-->
        <el-dialog
            title="通知用户"
            :visible.sync="isShowEditOrderNotice"
            width="50%">
            <el-form>
                <el-form-item>
                    <span>自定义订单状态: </span>
                    <el-input  v-model="editOrderNotice.order_status_text"></el-input>
                </el-form-item>
                <el-form-item>
                    <p class="font-size-10 color_eb3021 padding15">请不要发送营销信息，否则会被封号！</p>
                    <span>内容: </span>
                    <el-input  v-model="editOrderNotice.remarks"></el-input>
                </el-form-item>
            </el-form>
            <div class="width100 text-align-right">
                <el-button type="primary" @click="sendNotice">发送通知</el-button>
            </div>
        </el-dialog>


        <!--人工发货组件-->
        <add-express v-model="is_adding_express" :express-data="adding_rengong_express"></add-express>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import print from 'print-js'
    import  util from '../../../utils/util'
    import { Loading } from 'element-ui';
    import uploadImage from '../../common/uploadImage';
    import addExpress from '../../common/addExpress'

    export default {
        data: function () {
            return {
                is_adding_express: false, // 人工发货组建
                adding_rengong_express: {}, // 人工发货信息
                is_auto_send_sms_notice: true, // 是否自动发送短信
                isShowEditOrderNotice: false,
                editOrderNotice: {
                    remarks: '',
                    order_status_text: '',
                    sendNoticeOrder: {}
                },
                refundData: {
                    total: 0, // 退款总金额
                    reason: '', // 退款原因
                    type: 'moneyOnly', // 退款类型
                    evidence: '' ,// 退款凭证
                    remarks: '', // 备注
                }, // 退款详情
                isSendingRefund: false, // 主动发起退款
                showPrintExpressButton: false, // 显示合并订单后的发货按钮
                mergeLoading: false, // 订单打印时的loading
                beMergeOrderPrintData: {}, // 合并订单使用的打印data
                isShowMergeOrderGoods: false, // 是否显示合并订单
                MergeOrderGoods: {}, // 需要合并订单的数据
                expressCompany: [

                ], //物流公司
                ShipperCode_originList: [], //
                activeName: '全部',
                showWXpayDetail: false,  //是否展示微信支付系统详情
                weixinPayDetail: {user_info:{}},  //查询出来的微信支付详情
                addExpress: false,
                tableData: [],
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                paperStatus: '未上线',
                paperId: '',
                userNameSearchStr: '', // 姓名查询
                userPhoneSearchStr: '', // 电话查询
                nicknameSearchStr: '', // 昵称查询
                orderSnSearchStr: '', // 单号查询
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                PayTypeOptions: [ // 付款方式
                    {label: '现付', value: 1},
                    {label: '到付', value: 2},
                    {label: '月结', value: 3},
                    {label: '第三方', value: 4}
                ],
                ExpTypeOptions: [
                    // 快递类型
                    {label: '标准快件', value: 1}
                ],
                express: {
                    weight: 1,
                    space_x: 20,
                    space_y: 20,
                    space_z: 20,
                    LogisticCode: '', // 运单号
                    ShipperCode: 'ZTO', //物流公司代码
                    ShipperName: '中通快递', // 物流公司名称
                    shipper_service_type: 0,
                    shipper_service_name: '标准快递',
                    OrderId: '',
                    IsNotice: 1, //默认不通知
                    IsSendMessage: 1, // 默认发送通知
                    PayType: 1, // 默认发件类型为现付
                    ExpType: 1, // 默认快递类型为标准快递
                    Quantity: '', // 数量
                    Remark: '猫趴客户，请微笑服务~', // 备注
                    Sender: {
                        Name: '李美丽',
                        Tel: '0755-32867416',
                        Mobile: '',
                        Address: '华宁路厦门大学产学研基地14楼1002',
                        ProvinceName: '广东省',
                        CityName: '深圳市',
                        ExpAreaName: '龙华区',
                        FullRegion: '广东省 深圳市 龙华区'
                    },
                    Receiver: {
                        Name: '',
                        Tel: '',
                        Mobile: '',
                        Address: '',
                        ProvinceName: '',
                        CityName: '',
                        ExpAreaName: '',
                        FullRegion: ''
                    },
                    Commodity: {
                        GoodsName: ''
                    }
                    // 快递信息
                },
                showRefundDetailBool: false, // 是否显示退款订单详情
                refundStatus: [], // 退款详情
                EXPRESS_HTML: '', // 后端返回的快递HTML
                orderRemarksBaseData: {}, // 添加订单备注的基础订单数据
                addOrderRemarksBool: false, // 是否显示添加订单备注的弹窗
            }
        },
        watch: {
            ShipperCode_originList (newv) {
                let that =this;
                this.express.ShipperCode = newv[0];
                this.express.shipper_service_type = newv[1];

                this.expressCompany.map(function (item) {
                    if (that.express.ShipperCode === item.value) {
                        that.express.ShipperName = item.label;
                        item.children.map(function (param) {
                            if (that.express.shipper_service_type == param.value) {
                                that.express.shipper_service_name = param.label;
                            }
                        })
                    }
                })
            }
        },
        computed: {
            BOOL_EXPRESS_HTML: {
                get () {
                    return this.EXPRESS_HTML !== ''
                },
                set () {

                }
            },
        },
        components: {
            uploadImage: uploadImage,
            addExpress: addExpress
        },
        created() {
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
            this.getPaperList( 1, 10)
        },
        async mounted() {
            await this.getExpressType();
            // this.ShipperCode_originList = [this.express.ShipperCode, this.express.shipper_service_type]
            // 更新列表数据
            // 更新列表数据
            let that = this;
            bus.$on('reloadOrderList', reload => {
                if (reload) {
                    this.getPaperList( 1, 10)
                }
            })
            bus.$on('closeAddExpressDialog', function () {
                that.is_adding_express = false;
                that.getPaperList(that.tableData.currentPage || 1, that.tableData.pageSize || 10)
            })
        },
        methods: {
            gotoNewPage(path, data) {
                let routeUrl = this.$router.resolve({
                    path: path,
                    query: data
                });
                window.open(routeUrl.href, '_blank');
            },
            // 删除一条多件快递的运单信息
            deleteOtherExpress (express) {

                if (!express.shipperCode) {
                    this.$message.error('该单号为空，无需删除！');
                    return false
                }

                this.$http.post(this.$api + '/admin/order/deleteOtherExpress', {
                    shipperCode: express.shipperCode,
                    order_id: express.order_id
                })
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.$message.success(res.data.data || res.data.errmsg);
                            this.getPaperList( this.tableData.currentPage, this.tableData.pageSize)

                        } else {
                            this.$message.fail(res.data.errmsg)
                        }
                    })
            },
            // 新增或者更新快递单号
            addOrUpdateShipperCode (express) {
                if (!express.order_id || !express.shipperCode) {
                    this.$message.error('参数不完整，请填写三项必填参数！');
                    return false
                }

                if (!express.order_id) {
                    this.$message.error('请刷新页面后重试！频繁出现此问题请联系技术人员！');
                    return false
                }
                this.$http.post(this.$api + '/admin/order/addOrUpdateShipperCode', {
                    shipperCode: express.shipperCode,
                    shipperName: express.shipperName,
                    logisticCode: express.logisticCode,
                    order_id: express.order_id
                })
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.$message.success(res.data.data || res.data.errmsg);
                            this.getPaperList( this.tableData.currentPage, this.tableData.pageSize)
                        } else {
                            this.$message.fail(res.data.errmsg)
                        }
                    })
            },
            // deleteShipCode
            // 设置多件快递状态
            setOrderMultipleExpressStatus (order) {
                this.$http.post(this.$api + '/admin/order/setOrderMultipleExpressStatus', {
                    ship_type: order.ship_type ? 1:0,
                    ship_remarks: order.ship_remarks,
                    order_id: order.id
                })
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.$message.success(res.data.data || res.data.errmsg);
                        } else {
                            this.$message.fail(res.data.errmsg)
                        }
                    })
            },
            // 新增快递信息，静态
            addOtherExp (order) {
                let that = this;
                this.tableData.data.map(function (item, index) {
                    if (item.id === order.id) {
                        that.tableData.data[index].other_express.push(
                            {
                                order_id: item.id,
                                shipperCode: '',
                                shipperName: '',
                                logisticCode: ''
                            }
                        )
                    }
                });
            },
            // 比纳基页面
            editExpressPaper () {
                document.getElementById('express_print').setAttribute('contenteditable', true);
            },
            // 获取可用的打单类型
            async getExpressType () {
                let that = this;
                this.$http({
                    url: this.$api + '/admin/printer/expressType',
                    method: 'post',
                    data: this.editOrderNotice
                }).then(response => {
                    if (response.status === 200) {
                        if (response.data.errno == 0) {
                            this.expressCompany = response.data.data;
                            this.expressCompany.map(function (item) {
                                console.log(item)
                                if (item.isDefault && item.children && item.children.constructor === Array) {
                                    console.log(item.children)
                                    item.children.map(function (param) {
                                        console.log(param)
                                        if (param.isDefault) {
                                            // 修改默认的快递方式
                                            that.ShipperCode_originList = [item.value, param.value];
                                        }
                                    })
                                }
                            });
                        }
                    }
                })
            },
            // 发送发货通知
            sendOutExpressNotice (order) {
                if (this.is_auto_send_sms_notice) {
                    this.$notify({
                        title: '提示',
                        message: '启用自动发送短信通知。。。',
                        duration: 1500
                    });
                }

                if (!order.LogisticCode) {
                    this.$message.error('该订单不存在运单号码！请发货后重试！');
                    return
                }

                if (!order.mobile) {
                    this.$message.error('收件人号码有误！');
                    return
                }

                this.$http({
                    url: this.$api + '/admin/sms/sendOutExpressNotice',
                    method: 'post',
                    data: {
                        PhoneNumbers: order.mobile,
                        TemplateParam: {
                            nickname: (order.consignee || order.NICKNAME) + order.ShipperName? `(${order.ShipperName})`:'',
                            orderSn: '***' + order.order_sn.slice(order.order_sn.length -4,order.order_sn),
                            logisticCode: order.LogisticCode
                        }
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.$notify({
                            title: '提示',
                            message: '自动发送短信通知成功。。。',
                            duration: 1500
                        });
                        if (response.data.errno == 0) {
                            this.$message.success('发送短信成功！');
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    }
                })
            },
            // 发送通知
            sendNotice () {
                this.$http({
                    url: this.$api + '/admin/adminManagement/sendOrderEditMessage',
                    method: 'post',
                    data: this.editOrderNotice
                }).then(response => {
                    if (response.status === 200) {
                        if (response.data.errno == 0) {
                            this.$message.success('发送客户通知成功！');
                            this.isShowEditOrderNotice = false;
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    }
                })
            },
            CLOSE_REFUND_DIALOG () {
                this.isSendingRefund = false;
                this.refundData.total = 0;
                this.refundData.orderId = '';
                this.refundData.evidence = '';
                this.refundData.remarks = '';
                this.refundData.reason = '';
                this.refundData.type = '';
            },
            // 生成退款单
            generateRefundOrder () {
                this.$confirm('您正在主动发起退款，请提前和用户沟通达成一致。', '警示', {
                    confirmButtonText: '退款',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/order/refundUserOrder',
                        method: 'post',
                        data: this.$qs.stringify(this.refundData)
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno == 0) {
                                this.$message.success('发起退款成功！');
                                this.getPaperList(this.tableData.currentPage, this.tableData.pageSize)
                                this.isSendingRefund = false;
                                this.refundData.total = 0;
                                this.refundData.orderId = '';
                                this.refundData.evidence = '';
                                this.refundData.remarks = '';
                                this.refundData.reason = '';
                                this.refundData.type = '';
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'error',
                        message: '发起退款失败！请重新发起！'
                    });
                });
            },
            // 主动发起退款
            refundThisOrder (order) {
                this.refundData = Object.assign({}, this.refundData, order);
                this.refundData.total = order.actual_price;
                this.refundData.orderId = order.id;
                console.log(this.refundData)
                this.isSendingRefund = true
            },
            // 合并订单后，发货
            mergeOrderNsendExpress () {
                // 合并订单
                let that = this;
                this.MergeOrderGoods.data.map(function (item) {
                    if (item.is_checked_to_merge) {
                        if (!that.beMergeOrderPrintData.childrOrder) {
                            that.beMergeOrderPrintData.childrOrder = [];
                        }
                        that.beMergeOrderPrintData.goodList = [...that.beMergeOrderPrintData.goodList, ...item.goodList];
                        if (that.beMergeOrderPrintData.childrOrder.indexOf(item) === -1) {
                            that.beMergeOrderPrintData.childrOrder.push(item)
                        }
                    }
                });

                if (that.beMergeOrderPrintData.childrOrder && that.beMergeOrderPrintData.childrOrder.length > 0) {
                    this.editPrintContent(this.beMergeOrderPrintData, false);
                    // // 同步发货
                    this.$http.post(that.$api + '/admin/order/mergeOrderNSendExpress', {
                        motherOrderId: that.beMergeOrderPrintData.id,
                        childOrderIds: that.beMergeOrderPrintData.childrOrder.map(function (item) {
                            return item.id
                        }),
                    })
                        .then( res => {
                            console.log(res)
                            if (res.data.errno === 0) {
                                this.$message.success(res.data.data || res.data.errmsg);
                            } else {
                                this.$message.fail('合并订单失败！')
                            }
                        })
                }

                // 需要更新列表
                this.getPaperList(this.tableData.currentPage, this.tableData.pageSize);

                // 关闭一些弹窗
                this.showPrintExpressButton = true;
                // 关闭合并订单弹窗
                this.isShowMergeOrderGoods = false;
            },
            // 合并订单选项变更
            MergeOrderCheckedChange () {

            },
            //关闭预览订单弹窗
            closePreTransportOrder(){
                console.log('关闭预览订单弹窗');
                this.EXPRESS_HTML = '';
            },
            //签收状态
            getOrderStatusTxt(code){
                return util.getOrderStatusTxt(code);
            },
            // 发起退款
            async startRefund (row) {
                this.$confirm(`您正在给用户【${row.consignee}】退款￥${row.actual_price}元，请确认！！`, '警示', {
                    confirmButtonText: '确认退款',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    /**
                     * 发起退款
                     * **/
                    this.$http.post(this.$api + 'admin/refund/startRefund', {
                        orderId: row.id,
                        total: row.actual_price
                    }).then(res => {
                        if (res.data.errno === 0) {
                            this.getPaperList( this.tableData.currentPage, this.tableData.pageSize)
                        } else {
                            this.$message.error('退款失败!')
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });

            },
            // 紧急订单显示红色底
            tableRowClassName({row}) {
                if (row.is_emergency > 0) {
                    return 'warning-table-row';
                }
                return '';
            },
            // 设置订单备注
            addOrderRemarks (item) {
                this.addOrderRemarksBool = !this.addOrderRemarksBool;
                if (!item.is_emergency) {
                    item.is_emergency = false
                } else {
                    item.is_emergency = true
                }
                this.orderRemarksBaseData = item
            },
            // 设置为紧急
            async switchEmergency () {
               /**
                * 更新订单备注信息
                * **/
               await this.$http.post(this.$api + 'admin/order/updateOrderSellingRemarks', this.orderRemarksBaseData).then(res => {
                    if (res.data.errno === 0) {
                        this.addOrderRemarksBool = !this.addOrderRemarksBool;
                        this.$message.success('更新成功');
                        this.getPaperList( this.tableData.currentPage, this.tableData.pageSize)
                    } else {
                        this.$message.error('更新失败')
                    }
                })

            },
            //查询微信支付系统详情
            async checkWeixinOrder(order_sn){
                const loading = this.$loading({
                    lock: true,
                    text: '查询中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                await this.$http.post(this.$api + 'admin/order/queryWeixinOrder', {
                    order_sn: order_sn,
                }).then(res => {
                    if (res.data.errno === 0) {
                        this.weixinPayDetail = res.data.data;
                        this.showWXpayDetail = true;
                    }
                    loading.close();
                })
            },
            // 变更快递
            changeExpressCode (val) {
                let that =this;
              this.expressCompany.map(function (item) {
                  if (item.value === val) {
                      that.express.ShipperName = item.label
                  }
              })
            },
            // 同意该笔订单退款
            async agreeThisRefund (refundStatus) {
                if (refundStatus.order.order_status >= 402) {
                    this.$message.error('该订单已审核，无需重复审核！');
                    return
                }
                const loading = this.$loading({
                    lock: true,
                    text: '审核中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                await this.$http.post(this.$api + '/admin/refund/check', {orderId: refundStatus.refund.order_id})
                    .then(res => {
                        if (res.data.errno === 0) {
                            this.$message.success('审核通过，请通知财务在商户后台退款！');
                            this.showRefundDetailBool = !this.showRefundDetailBool;
                        } else {
                            this.$message.error(res.data.errmsg)
                        }
                        loading.close();
                    })
            },
            // 查看退款订单详情
            async showRefundDetail (item) {
                const loading = this.$loading({
                    lock: true,
                    text: '加载退款信息中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                await this.$http.post(this.$api + '/admin/refund/detail', {orderId: item.id})
                    .then(res => {
                        if (res.data.errno === 0) {
                            this.showRefundDetailBool = !this.showRefundDetailBool;
                            this.refundStatus = res.data.data;
                            this.refundStatus.order = item
                        }
                        loading.close();
                    })
            },
            // 计算合计
            getSummaries(param) {
                //console.log(param)
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计（元）';
                        return;
                    } else if (index === 1) {
                        sums[index] = 0;
                        for (let item in data) {
                            sums[index] += data[item].number
                        }
                        return;
                    } else if (index === 2) {
                        sums[index] = '-';
                        return;
                    } else if (index === 3) {
                        sums[index] = 0;
                        for (let item in data) {
                            sums[index] += data[item].retail_price * data[item].number
                        }
                        return;
                    }
                });

                return sums;
            },
            // 重新订阅物流信息
            reSubscribeExpress (item) {
                this.$confirm('此操作将重新向物流系统重新订阅该订单物流信息，请确认重新订阅？', '警示', {
                    confirmButtonText: '重新订阅',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$api + '/admin/order/reSubscribeExpress', {
                        id: item.id
                    })
                        .then( res => {
                            if (res.data.errno === 0) {
                                this.$message.success('物流信息重新订阅成功！！');
                                this.getPaperList(1, 10)
                            } else if (res.data.errno === 4004) {
                                let that = this;
                                setTimeout(function () {
                                    that.$message.error('该订单不存在物流信息，请重新提交发货信息！');
                                }, 1000);
                                this.addExpressFun(item)
                            }
                        })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            // 发货操作
            sendExpress () {
                let that = this;
                that.$refs['senderMsg'].validate((valid) => {
                    if (!valid) {
                        that.$message.error('请完善发件人必填信息！！');
                        return false;
                    } else {
                        that.$refs['receiverMsg'].validate((valid) => {
                            if (!valid) {
                                that.$message.error('请完善收件人必填信息！！');
                                return false;
                            } else {
                                that.$refs['otherExpress'].validate((valid) => {
                                    if (!valid) {
                                        that.$message.error('请完善其他物流必填信息！！');
                                        return false;
                                    } else {
                                        if (!that.express.ShipperCode || !that.express.ShipperName) {
                                            return that.$message.error('请选择物流公司！');
                                        }
                                        // 提交数据
                                        that.express.Receiver_str = JSON.stringify(that.express.Receiver);
                                        that.express.Sender_str = JSON.stringify(that.express.Sender);
                                        that.express.Commodity_str = JSON.stringify(that.express.Commodity);
                                        const loading = this.$loading({
                                            lock: true,
                                            text: '正在拉取快递单~',
                                            spinner: 'el-icon-loading',
                                            background: 'rgba(183, 183, 183, 0.57)'
                                        });
                                        that.$http.post(that.$api + '/admin/printer/addOrder', that.express)
                                            .then( res => {
                                                if (res.data.errno === 0) {
                                                    this.$message.success('运单获取成功！');
                                                    /**
                                                     * 每次发货成功后都需要重置快递公司，防止惯性操作用错快递公司
                                                     * **/
                                                    // this.express.ShipperCode = this.express.ShipperName = '';
                                                    this.getPaperList(this.tableData.currentPage, this.tableData.pageSize);
                                                    // this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                                                    this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.block_8_r img{width: 80% !important;margin-top: 0 !important;}.block_8_r{width: 30% !important;}.block_8_l {width: 70% !important;}.block_8_l_t{width: 100% !important;}.address__sender {font-size: 23px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                                                    // this.EXPRESS_HTML = res.data.data.print_html;

                                                    // 关闭发货窗口
                                                    this.addExpress = !this.addExpress;
                                                    // 打开打印窗口
                                                    // this.$alert('运单已生成，是否立即打印运单？', '打印提醒', {
                                                    //     confirmButtonText: '打印',
                                                    //     callback: action => {
                                                    //
                                                    //         printJS('express_print', 'html');
                                                    //         that.EXPRESS_HTML = ''
                                                    //     }
                                                    // });

                                                    /**
                                                     * 自动发送短信通知
                                                     * **/
                                                    if (that.is_auto_send_sms_notice) {
                                                        that.sendOutExpressNotice({
                                                            mobile: that.express.Receiver.Mobile,
                                                            ShipperName: that.express.ShipperName? `(${that.express.ShipperName})`:'',
                                                            consignee: that.express.Receiver.Name ,
                                                            order_sn: '***' + res.data.data.order_id.slice(res.data.data.order_id.length -4,res.data.data.order_id.length),
                                                            LogisticCode: res.data.data.waybill_id
                                                        });
                                                    }

                                                    // 订单后处理
                                                    that.afterOrderHandler(that.express, loading);
                                                } else {
                                                    loading.close();
                                                    this.$message.error(res.data.errmsg);
                                                }
                                            }).catch (err => {
                                                console.log(err)
                                                loading.close();
                                            })
                                    }
                                });
                            }
                        });
                    }
                });
            },
            afterOrderHandler (DATA, loading) {
                // 显示加载
                this.mergeLoading = true;
                let that = this;
                // 查询是否存在合并订单
                this.$http.post(this.$api + '/admin/order/getUserUnExpressOrder', {
                    user_id: DATA.user_id
                })
                    .then( res => {
                        if (res.data.errno === 0) {
                            if (res.data.data && res.data.data.count > 0) {
                                res.data.data.data = res.data.data.data.map(function (orderItem) {
                                   orderItem.is_checked_to_merge = false;
                                   return orderItem;
                                });
                                this.MergeOrderGoods = res.data.data;
                                that.$confirm('该用户有多个订单可以合并，是否合并打印？', '订单合并提示', {
                                    confirmButtonText: '合并打印',
                                    cancelButtonText: '不合并',
                                    type: 'warning'
                                }).then(() => {
                                    // 合并订单
                                    that.isShowMergeOrderGoods = true;
                                    // 新的打印信息
                                    that.beMergeOrderPrintData = DATA;
                                }).catch(() => {
                                    // 不合并订单
                                    that.editPrintContent(DATA, loading)
                                });
                            } else {
                                this.mergeLoading = false;
                                that.editPrintContent(DATA, loading)
                            }
                        }
                    });

                loading.close();
            },
            // 修改打印信息
            editPrintContent (DATA, loading) {
                // 短期之内中通订单需要这么处理
                if (DATA.ShipperCode === 'ZTO' ) {
                    setTimeout(function () {
                        /**
                         * 打单之前，先替换打单的内容，为商品规格详情后再打印
                         * **/
                        let remarksEle = document.getElementsByClassName('block_9_content');
                        if (remarksEle && remarksEle.length > 0) {
                            remarksEle[0].innerHTML = DATA.goodList.map(function (goods_item_detail) {
                                return '\n<p style="font-size: 18px !important;">' + goods_item_detail.goods_name + `【规格：${goods_item_detail.goods_specifition_name_value} X ${goods_item_detail.number}件】</p>`
                            }).join('\n')
                        }

                        console.log(DATA)
                        // 添加备注
                        if (DATA.Remark) {
                            remarksEle[0].innerHTML += '【备注】：' + DATA.Remark;
                        }

                        if (DATA.remarks) {
                            remarksEle[0].innerHTML += '【备注】：' + DATA.remarks;
                        }
                        // 更改样式，容纳更多商品
                        remarksEle[0].innerHTML += ` <style>
                                .block_8 {height: 180px;}
                                .block block_8 {height: 180px;}
                                .block_8_l {height: 180px;}
                                .block_8_l_t {height: 90px;}
                                .block_8_l_b {height: 90px;}
                                .block_top  {height: 860px;}
                                .block_6 {height: 140px;}
                            </style>`
                        loading? loading.close():''
                    }, 500)
                } else if (DATA.ShipperCode === 'BEST') {
                    setTimeout(function () {
                        try {
                            /**
                             * 打单之前，先替换打单的内容，为商品规格详情后再打印
                             * **/
                            let block_9 = document.getElementsByClassName('block_9');
                            let block_7 = document.getElementsByClassName('block_7');
                            block_7[0].parentNode.removeChild(block_7[0]);
                            let firstObj = block_9.firstChild;
                            let block_9_content = document.createElement('div');
                            block_9_content.setAttribute('class', 'block_9_content');
                            block_9[0].insertBefore(block_9_content, firstObj);
                            let remarksEle = document.getElementsByClassName('block_9_content');
                            if (remarksEle && remarksEle.length > 0) {
                                remarksEle[0].innerHTML = DATA.goodList.map(function (goods_item_detail) {
                                    return '\n<p style="font-size: 22px !important;">' + goods_item_detail.goods_name + `【规格：${goods_item_detail.goods_specifition_name_value} X ${goods_item_detail.number}件】</p>`
                                }).join('\n')
                            }

                            // 添加备注
                            if (DATA.Remark) {
                                remarksEle[0].innerHTML += '【备注】：' + DATA.Remark;
                            }

                            if (DATA.remarks) {
                                remarksEle[0].innerHTML += '【备注】：' + DATA.remarks;
                            }
                            // 更改样式，容纳更多商品
                            remarksEle[0].innerHTML += ` <style>
                                .block_8 {height: 180px;}
                                .block block_8 {height: 180px;}
                                .block_8_l {height: 180px;}
                                .block_8_l_t {height: 90px;}
                                .block_8_l_b {height: 90px;}
                                .block_top  {height: 860px;}
                                .block_6 {height: 140px;}
                            </style>`
                            loading? loading.close():''
                        } catch (e) {
                            console.log(e)
                        }
                    }, 500)
                }
                loading? loading.close():''
                this.mergeLoading = false
            },
            async getDeliveryBill (DATA) {
                const loading = this.$loading({
                    lock: true,
                    text: '运单已存在，拉取运单中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                await this.$http.post(this.$api + '/admin/printer/getOrder',  {
                    order_id: DATA.order_sn,
                    openid: DATA.OPENID,
                    delivery_id: DATA.ShipperCode,
                    waybill_id: DATA.LogisticCode
                })
                    .then( res => {
                        this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.block_8 {height: auto !important;}.block_8_l_t {height: 130px !important;}.block_8_r img{width: 80% !important;margin-top: 0 !important;}.block_8_r{width: 30% !important;}.block_8_l {width: 70% !important;}.block_8_l_t{width: 100% !important;}.address__sender {font-size: 23px !important;}.block_9 {height: auto !important;}.container{padding: 0 !important;height: auto !important;}');

                        // this.EXPRESS_HTML = res.data.data.print_html;
                        // // 打开打印窗口
                        // setTimeout (function () {
                        //     printJS('express_print', 'html');
                        //     loading.close()
                        // }, 1000)
                        // 短期内运单订单需要这么处理
                        this.afterOrderHandler(DATA, loading)
                    }).catch(err => {
                        loading.close()
                })
            },
            // 人工发货
            addExpressFUN_rengongfahuo (data) {
                this.is_adding_express = true;
                this.adding_rengong_express = data
            },
           async addExpressFun (item) {
                if (!item.id) {
                    alert('订单ID有误，请重新选择！');
                    return
                }

                /**
                 * 订单已发货，则无需再生成运单号
                 * **/

                this.express.OrderId = item.id;
               this.express.id = item.id; // 兼容预览运单打印
                // this.express.openid = item.OPENID;
                this.express.ITEM = item;
               this.express.goodsList = item.goodList;
               // 为了兼容之前写错的goodList
               this.express.goodList = item.goodList;
                this.express.Receiver.Name = item.consignee;
                this.express.Receiver.Tel = item.tel;
                this.express.Receiver.Mobile = item.mobile;
                this.express.Receiver.ProvinceName = item.ProvinceName;
                this.express.Receiver.CityName = item.CityName;
                this.express.Receiver.ExpAreaName = item.DistrictName;
                this.express.Receiver.Address = item.address;
                this.express.Receiver.FullRegion = item.ProvinceName + ' ' + item.CityName + ' ' + item.DistrictName;
               this.express.Remark = item.remarks;
               this.express.user_id = item.user_id;

               if (item.order_status >= 300) {
                   // don't need to add new Delivery
                   return await this.getDeliveryBill(item)
               }

               this.addExpress = !this.addExpress

           },
            // 备注
            RemarkChange (e) {
              this.express.Remark = e.target.value
            },

            handleSearchByTitle() {
                this.getPaperList( 1, 10)
            },
            handleSizeChange(val) {
                this.getPaperList( this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList( val, this.tableData.pageSize)
            },
            getPaperList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/order/paydone',
                    method: 'post',
                    data: this.$qs.stringify({
                        name: this.userNameSearchStr,
                        nickname: this.nicknameSearchStr,
                        mobile: this.userPhoneSearchStr,
                        orderSn: this.orderSnSearchStr,
                        page: page? page:1,
                        size: length? length:10
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        response.data.data.data = response.data.data.data.map(function (item) {
                            Number(item.ship_type) === 1? item.ship_type = true:item.ship_type = false;
                            return item
                        });
                        this.tableData = response.data.data;
                        this.currentPage = response.data.data.currentPage;
                        this.total = response.data.data.totalPages;
                        this.size = response.data.data.pageSize;
                        loading.close()
                    }
                })
            },
            switchTag(tab) {
                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {
                        this.userNameSearchStr = "";
                        this.getPaperList(1, 10);
                        this.currentTagId = this.tagSwitchData[i].id
                    }
                }
            },
        }
    }
</script>
<style lang="scss" >
    .multipleExpress {
        width: 6em;
        padding: 2px 8px;
        box-sizing: border-box;
        border-radius: 50px;
        border: 1px solid #ff0000;
        color: #ff0000;
    }
    .warning-table-row {
        background: #fbe8e2 !important;
    }
    /*.boxShdow {*/
        /*-webkit-box-shadow: inset hoff voff blur #000;*/
        /*-moz-box-shadow: inset hoff voff blur #000;*/
        /*box-shadow: inset hoff voff blur #000;*/
    /*}*/
    /*#express_print .address__sender {*/
        /*font-size: 26px !important;*/
    /*}*/
    .single_weixin_info{
        margin: 10px 0;
        &.user_info{
            display: flex;
            align-items: center;
            .portrait{
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }
        }
    }

    .remarks{
        padding: 10px;
        border: 1px solid #eee;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        width: 100%;
        display: block;
        max-height: 110px;
    }
    .expressBox{
        width: 25%;
    }
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style >
