<template>
    <div class="wrapper">
        <!--<v-head></v-head>-->
        <v-sidebar></v-sidebar>
        <div class="content-box" :class="{'content-collapse':collapse}">
            <v-head></v-head>
            <v-tags></v-tags>
            <div class="content" :class="{'content-collapse':collapse}">
                <transition name="move" mode="out-in">
                    <keep-alive>
                        <!-- <router-view v-if="$route.meta.keepAlive"/></router-view> -->
                        <router-view v-if="$route.meta.keepAlive"></router-view>
                    </keep-alive>
                </transition>
                <!-- 不缓存路由中设置了meta属性为keepAlive为false组件 -->
                <router-view v-if="!$route.meta.keepAlive"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
    import vHead from './Header.vue';
    import vSidebar from './Sidebar.vue';
    import vTags from './Tags.vue';
    import bus from '../common/bus';

    export default {
        data() {
            return {
                isRouterAlive: true
            }
        },
        components: {
            vHead, vSidebar, vTags
        },
        computed: {
            collapse () {
                return this.$store.state.collapse
            }
        },
        created() {
            this.$getDicByName('ACTIVITY_TYPE', true)
        },
        mounted(){

        },
        method: {
            reload() {
                this.isRouterAlive = false
                this.$nextTick(() => (this.isRouterAlive = true))
            }
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .red {
        color: #ff0000;
    }
    .wrapper {
      width: 100%;
    }
</style>
