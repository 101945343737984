<template>
    <section class="todoapp">
        <header class="header">
            <input class="new-todo" autofocus autocomplete="off" placeholder="有什么需要记录的工作?" v-model="newTodo" @keyup.enter="addTodo">
        </header>
        <section class="main" v-show="todos.length">
            <input id="toggle-all" class="toggle-all" type="checkbox" v-model="allDone">
            <label for="toggle-all">Mark all as complete</label>
            <ul class="todo-list">
                <li class="todo" v-for="todo in filteredTodos" :key="todo.id" :class="{completed: todo.completed, editing: todo == editedTodo}">
                    <div class="view">
                        <input class="toggle" type="checkbox" v-model="todo.completed" @click="updateTodo({id: todo.id, completed: todo.completed? false:true})">
                        <label @dblclick="editTodo(todo)">{{todo.title}}</label>
                        <button class="destroy" @click="removeTodo(todo)"></button>
                    </div>
                    <input class="edit" type="text" v-model="todo.title" v-todo-focus="todo == editedTodo" @blur="doneEdit(todo)" @keyup.enter="doneEdit(todo)" @keyup.esc="cancelEdit(todo)">
                </li>
            </ul>
        </section>
        <footer class="footer" v-show="todos.length">
				<span class="todo-count">
					<strong > {{todos.length}}</strong> 个待办任务
				</span>
            <ul class="filters">
                <li><a href="#/all" :class="{selected: visibility == 'all'}">All</a></li>
                <li><a href="#/active" :class="{selected: visibility == 'active'}">Active</a></li>
                <li><a href="#/completed" :class="{selected: visibility == 'completed'}">Completed</a></li>
            </ul>
            <button class="clear-completed" @click="removeCompleted" v-show="todos.length > remaining">
                Clear completed
            </button>
        </footer>
    </section>
</template>

<script>
    let filters = {
        all: function (todos) {
            return todos;
        },
        active: function (todos) {
            return todos.filter(function (todo) {
                return !todo.completed;
            });
        },
        completed: function (todos) {
            return todos.filter(function (todo) {
                return todo.completed;
            });
        }
    };
    export default {
        name: "todo",
        // the root element that will be compiled
        // app initial state
        data () {
            return  {
                todos: [],
                newTodo: '',
                editedTodo: null,
                visibility: 'all',
            }
        },
        // watch todos change for localStorage persistence
        watch: {
            todos: {
                deep: true,
                handler: function (todo) {
                    console.log(todo)
                }
            }
        },
        // computed properties
        // http://vuejs.org/guide/computed.html
        computed: {
            filteredTodos: function () {
                return filters[this.visibility](this.todos);
            },
            remaining: function () {
                return filters.active(this.todos).length;
            },
            allDone: {
                get: function () {
                    return this.remaining === 0;
                },
                set: function (value) {
                    this.todos.forEach(function (todo) {
                        todo.completed = value;
                    });
                }
            }
        },
        // methods that implement data logic.
        // note there's no DOM manipulation here at all.
        created () {
          this.getTodoList();
        },
        methods: {
            // 获取代办列表
            getTodoList () {
                this.$http.post(this.$api + '/admin/todo/getTodo',  {})
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.todos = res.data.data
                        }
                    })
            },
            // 新增代办列表
            addNewTodo (data) {
                this.$http.post(this.$api + '/admin/todo/addTodo',  data)
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.todos = res.data.data
                        }
                    })
            },
            // 更新代办列表
            updateTodo (data) {
                this.$http.post(this.$api + '/admin/todo/updateTodo',  data)
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.todos = res.data.data
                        }
                    })
            },
            // 删除代办列表
            deleteTodo (id) {
                this.$http.post(this.$api + '/admin/todo/deleteTodo',  {
                    todo_id: id
                })
                    .then( res => {
                        if (res.data.errno === 0) {
                            this.todos = res.data.data
                        }
                    })
            },
            pluralize: function (word, count) {
                return word + (count === 1 ? '' : 's');
            },

            addTodo: function () {
                var value = this.newTodo && this.newTodo.trim();
                if (!value) {
                    return;
                }

                let todo = { id: this.todos.length + 1,
                    title: value,
                    completed: false,
                    content: ''
                };

                this.todos.push(todo);
                this.newTodo = '';
                this.addNewTodo(todo)
            },

            removeTodo: function (todo) {
                var index = this.todos.indexOf(todo);
                this.todos.splice(index, 1);
                console.log(todo)
                this.deleteTodo(todo.id);
            },

            editTodo: function (todo) {
                this.beforeEditCache = todo.title;
                this.editedTodo = todo;
            },

            doneEdit: function (todo) {
                if (!this.editedTodo) {
                    return;
                }
                this.editedTodo = null;
                todo.title = todo.title.trim();
                if (!todo.title) {
                    this.removeTodo(todo);
                    this.deleteTodo(todo.id);
                } else {

                    console.log(todo)
                    this.updateTodo({
                        id: todo.id,
                        title: todo.title,
                        content: todo.content,
                    })
                }
            },

            cancelEdit: function (todo) {
                this.editedTodo = null;
                todo.title = this.beforeEditCache;
            },

            removeCompleted: function () {
                this.todos = filters.active(this.todos);
            }
        },
        // a custom directive to wait for the DOM to be updated
        // before focusing on the input field.
        // http://vuejs.org/guide/custom-directive.html
        directives: {
            'todo-focus': function (el, binding) {
                if (binding.value) {
                    el.focus();
                }
            }
        }
    }
</script>

<style scoped>
    @import '../../static/todo-base.css';
    @import '../../static/todo-index.css';
    [v-cloak] { display: none; }
</style>
