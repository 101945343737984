<template>
  <div>
    <div class="crumbs">
      <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}条记录）</label>
    </div>
    <div class="button-tool">
      <div style="display: flex">
        <el-input placeholder="请输入宠物名查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                  style="width: 500px">
          <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
        </el-input>
      </div>
    </div>
    <el-table
        :data="tableData.data"

        style="width: 100%">
      <el-table-column label="id" width="80" prop="id"></el-table-column>
      <el-table-column label="宠物昵称">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper disflex_warp" >
            <img :src="scope.row.img" @click="showImagePath = scope.row.img" style="width: 40px;vertical-align: middle;">
            <div class="text-align-left margin-left-1em" >
              <p >{{ scope.row.name }}</p>
              <el-tag size="small" v-if="scope.row.type">{{ scope.row.type }}</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="宠物主">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper disflex_warp" >
            <img :src="scope.row.avatar" @click="showImagePath = scope.row.avatar" style="width: 40px;vertical-align: middle;">
            <div class="text-align-left margin-left-1em" >
              <p >{{ scope.row.nickname }}</p>
              <el-tag size="small" v-if="scope.row.mobile">{{ scope.row.mobile }}</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="体重/千克"
          width="80">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.weight }}kg
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="生日">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.birthday }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="性别"
          width="80">
        <template slot-scope="scope">
          <div slot="reference" class="name-wrapper">
            {{ scope.row.sex }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
          label="到家时间">
        <template slot-scope="scope">
          <!--<i class="el-icon-time"></i>-->
          <span style="margin-left: 10px">{{ scope.row.home_time }}</span>
        </template>
      </el-table-column>
      <el-table-column
          label="档案新增时间">
        <template slot-scope="scope">
          <!--<i class="el-icon-time"></i>-->
          <span style="margin-left: 10px">{{ scope.row.add_time }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="paginaion" style="text-align: center;margin-top: 1em">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="tableData.currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="tableData.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.count">
      </el-pagination>
    </div>
    <preview-image @closeImageHub="showImagePath = ''" v-if="showImagePath" :img-path="showImagePath"></preview-image>
  </div>
</template>

<script>
import bus from '../../common/bus';
import previewImage from '../../common/previewImage';

export default {
  data: function () {
    return {
      tableData: [],
      contentType: '',
      contentTypes: [],
      currentPage: 1,
      total: 0,
      size: 0,
      currentTagId: '',
      searchState: '',
      showImagePath: ''
    }
  },
  components: {
    previewImage
  },
  watch: {
    '$route' (to, from) {
      if (to.path === '/goodslist') {
        if (to.query.page) {
          this.getPaperList(0, to.query.page, 10)
        }
      }
    }
  },
  created() {
    this.getPaperList(0, this.$route.query.page || 1, 10)
    this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
  },
  mounted() {
  },
  methods: {
    //按标题搜索列表
    handleContentType() {
      this.getPaperList(this.currentTagId, 1, this.tableData.pageSize)
    },
    //按标题搜索列表
    handleSearchByTitle() {
      this.getPaperList(this.currentTagId, 1, this.pageSize)
    },
    handleSizeChange(val) {
      this.getPaperList(this.currentTagId, this.tableData.currentPage, val)
    },
    handleCurrentChange(val) {
      this.$router.push({
        path: '/petsList',
        query: {
          page: val
        }
      })
      this.getPaperList(this.currentTagId, val, this.tableData.pageSize)
    },
    getPaperList(type, page, length) {
      const loading = this.$loading({
        lock: true,
        text: '已经很努力加载啦~',
        spinner: 'el-icon-loading',
        background: 'rgba(183, 183, 183, 0.57)'
      });
      if (page === undefined | page === null) {
        page = 1
      } else if (length === undefined | length === null) {
        length = this.size
      }
      this.$http({
        url: this.$api + '/admin/pets/allPets',
        method: 'post',
        data: this.$qs.stringify({
          page: page ? page : 1,
          size: length ? length : 10,
          name: this.searchState
        })
      }).then(response => {
        if (response.data.data != null) {
          this.tableData = response.data.data;
          this.currentPage = response.data.data.currentPage;
          this.total = response.data.data.totalPages;
          this.size = response.data.data.pageSize;
        } else {
          this.$message.error(response.data.msg)
        }
        loading.close()
      })
    }
  }
}
</script>
<style>
.ft25 {
  font-size: 25px;
}

.ft12 {
  font-size: 12px;
}

.red {
  color: red;
}

.pointer {
  cursor: pointer;
}
</style>
<style scoped>
/*.crumbs {*/
/*display: flex;*/
/*justify-content: space-between;*/
/*}*/
.tagSwitch {
  width: 100%;
  height: auto;
}

</style>
