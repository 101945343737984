<template>
    <div>

        <el-dialog title="添加食品" :close-on-click-modal="false" :visible.sync="addFoodDialogData.isShow">

                <!--填写食品内容-->
                <el-form style="width: 80%" label-width="120px" :rules="pushRules" :model="pushData" ref="pushData">

                    <el-form-item label="食品名称" prop="name">
                        <el-input v-model="pushData.name"></el-input>
                    </el-form-item>

                    <el-form-item label="食品展示图" prop="wap_banner_url">
                        <upload-image v-if="addFoodDialogData.isShow"  title="食品展示图【必填】" v-model="pushData.wap_banner_url" style="margin-right: 50px">
                            <div style="text-align: center;">食物的展示图</div>
                        </upload-image>
                    </el-form-item>

                    <el-form-item label="食品类型">
                        <el-select v-model="addFoodDialogData.parent_id" placeholder="请选择">
                            <el-option
                                v-for="item in $store.state.getFoodsList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="食品序号：" prop="sort_order">
                        <el-input v-model="pushData.sort_order" autocomplete="off"></el-input>
                    </el-form-item>

                    <el-form-item label="是否能吃">
                        <el-radio-group v-model="pushData.eat_type">
                            <el-radio border :label="1"><span style="color: red;">禁吃</span></el-radio>
                            <el-radio border :label="2"><span style="color: #ff6602;">慎吃</span></el-radio>
                            <el-radio border :label="3"><span style="color: #1AAD19;">可吃</span></el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="能吃类型解释" prop="front_desc">
                        <el-input type="textarea" v-model="pushData.front_desc" placeholder="为什么不能吃，为什么能吃，怎么慎吃法"></el-input>
                    </el-form-item>

                    <el-form-item label="是否立即上线：">
                        <el-switch
                            v-model="pushData.is_show" active-color="#13ce66" inactive-color="#ccc">
                        </el-switch>
                    </el-form-item>

                    <el-button type="primary" @click="submitForm">保存食品</el-button>
                </el-form>

        </el-dialog>


    </div>
</template>

<script>
    /*添加食物*/
    import bus from '../../../common/bus'
    import uploadImage from '../../../common/uploadImage'

    export default {
        name: "addFood",
        components: {
            uploadImage
        },
        props: {
            addFoodDialogData: {
                type: Object,
                default: {
                    isShow: false,
                    parent_id: null,
                }
            }
        },
        data(){
            return {

                pushData: {
                    wap_banner_url: null,  //食品展示图
                    name: null,  //食品名称
                    front_desc: null,  //食品描述
                    eat_type: 1,  //食品是否可以吃，1标识能吃、2标识慎吃、3标识禁吃
                    sort_order: 0,  //食品序号
                    // parent_id: null,  //食品分类
                    is_show: true  //是否立即上线
                },
                pushRules: {
                    name: [{required: true, message: "请输入食品名称", trigger: "blur"}],
                    wap_banner_url: [{required: true, message: "请上传视频展示图", trigger: "blur"}],
                    sort_order: [{required: true, message: "请输入食物序号", trigger: "blur"}],
                    front_desc: [{required: true, message: "请输入食品描述", trigger: "blur"}],
                    // parent_id: [{required: true, message: "请选择食品分类", trigger: "blur"}],
                },
            }
        },
        methods: {
            //添加食品弹窗确定
            submitForm() {
                this.$refs['pushData'].validate((valid) => {
                    if (valid) {
                        // this.addTypeSubmit();
                        this.addFoodSubmit();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //添加食提交
            addFoodSubmit(){
                this.addFoodDialogData.isShow = false
                this.$http.post(this.$api + 'admin/foodsCategory/add', {
                    name : this.pushData.name,
                    parent_id : this.addFoodDialogData.parent_id,
                    sort_order: this.pushData.sort_order,
                    eat_type: this.pushData.eat_type,
                    wap_banner_url: this.pushData.wap_banner_url,
                    front_desc: this.pushData.front_desc,
                    is_show: this.pushData.is_show ? 1 : 0
                }).then(res => {
                    // console.log('退款res',res);
                    if (res.data.errno === 0) {
                        this.pushData.sort_order = 0;
                        this.pushData.name = '';
                        this.addFoodDialogData.parent_id = null;
                        this.pushData.eat_type = 1,
                        this.pushData.wap_banner_url = null,
                        this.pushData.front_desc = null,
                        this.pushData.is_show = true,
                        this.$message.success('添加食品成功！');
                        this.$store.dispatch('getFoodsList');
                    }
                })
            }
        },
        mounted(){
            console.log('addFoodDialogData',this.addFoodDialogData);
        }
    }
</script>

<style lang="scss" scoped>
    .mike_container{
        background-color: #fff;
    }
</style>
