<template>
    <div >
        <div class="padding15 bg_fff ">
            <el-alert title="小组情况" type="info" :closable="false" class="coupon_title"></el-alert>
            <div class="width100 disflex_flex_start padding15">
                <img :src="allData.grouperDetail.group_avatar" alt="" class="width150px">
                <el-form  ref="ruleForm" label-width="150px" class="form_con">
                    <!--基本信息-->
                    <div class="width100 left">
                        <el-form-item label="小组名称" >
                            <p>{{allData.grouperDetail.group_name}}</p>
                        </el-form-item>
                        <el-form-item label="组长名称">
                            <p>{{allData.grouperDetail.belong_user_name}}</p>
                        </el-form-item>
                        <el-form-item label="微信号">
                            <p>{{allData.grouperDetail.wechat}}</p>
                        </el-form-item>
                    </div>
                </el-form>
                <el-form  ref="ruleForm" label-width="150px" class="form_con">
                    <!--基本信息-->
                    <div class="width100 left">
                        <el-form-item label="手机号">
                            <p>{{allData.grouperDetail.mobile}}</p>
                        </el-form-item>
                        <el-form-item label="更新时间" prop="update_time">
                            <p>{{allData.grouperDetail.update_time}}</p>
                        </el-form-item>
                        <el-form-item label="小组状态" prop="name">
                            <span class="color_eb3021">{{allData.grouperDetail.is_permit_str}}</span>
                        </el-form-item>
                    </div>
                </el-form>
                <div class="padding15 disflex_flex_start">
                    <el-card shadow="hover" :body-style="{padding: '8px',cursor: 'pointer'}" >
                        <div class="grid-content grid-con-1">
                            <i class="el-icon-view grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div>总收益</div>
                                <div class="grid-num">￥{{allData.comprehensiveData.grouper_cash}}</div>
                            </div>
                        </div>
                    </el-card>
                    <el-card shadow="hover" :body-style="{padding: '8px',cursor: 'pointer',marginLeft: '1em'}" >
                        <div class="grid-content grid-con-2">
                            <i class="el-icon-tickets grid-con-icon"></i>
                            <div class="grid-cont-right">
                                <div>今日收益</div>
                                <div class="grid-num">￥{{allData.comprehensiveData.today_grouper_cash}}</div>
                            </div>
                        </div>
                    </el-card>
                </div>
            </div>
        </div>

        <div class="padding15 bg_fff">
            <el-alert title="小组组员以及贡献" type="info" :closable="false" class="coupon_title"></el-alert>
            <el-table :data="allData.comprehensiveData.menberData">
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-table
                            :data="scope.row.contribute_goods"
                            style="width: 100%">
                            <el-table-column align="center" width="200" label="单件返佣" prop="commission"></el-table-column>
                            <el-table-column align="center" width="200" label="购买数量" prop="goods_number"></el-table-column>
                            <el-table-column align="center" width="200" label="合计返佣" >
                                <template slot-scope="goodsItem">
                                    <p>{{goodsItem.row.commission * goodsItem.row.goods_number}}</p>
                                </template>
                            </el-table-column>

                            <el-table-column align="center" width="150"  label="商品" >
                                <template slot-scope="goodsItem">
                                    <img :src="goodsItem.row.primary_pic_url" alt="" class="width60px">
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="200" label="名称" prop="name"></el-table-column>
                            <el-table-column align="center" width="200" label="商品售价" prop="retail_price"></el-table-column>
                            <el-table-column align="center" width="200" label="折扣" prop="discount"></el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column label="用户头像" width="150">
                    <template slot-scope="scope">
                        <img :src="scope.row.avatar" alt="" class="width60px">
                    </template>
                </el-table-column>
                <el-table-column label="用户" prop="nickname" ></el-table-column>
                <el-table-column label="总贡献" prop="user_total_contribution" width="120"></el-table-column>
                <el-table-column label="加入小组时间" prop="join_time" ></el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                allData: {
                    grouperDetail: {},
                    miniProgramUserData:{},
                    miniProgramUserOrderCount: [],
                    pusherDetail: {},
                    comprehensiveData: {}
                },
            }
        },
        components: {},
        watch: {},
        created() {
            this.getPaperList(0, 1, 10)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
        },
        mounted() {

        },
        methods: {
            getPaperList(is_limit, page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/serviceAccount/getDetail',
                    method: 'post',
                    data: {
                        service_id: this.$route.query.service_id
                    }
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.allData = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style scoped>
    .grid-content {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .grid-cont-right {
        flex: 1;
        text-align: center;
        font-size: 12px;
        color: #999;
    }

    .grid-num {
        font-size: 30px;
        font-weight: bold;
    }

    .grid-num_mobile {
        font-size: 25px !important;
        font-weight: bold;
    }

    .grid-con-icon {
        font-size: 50px;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }
    .grid-con-icon_mobile {
        font-size: 15px !important;
        width: 100px;
        height: 100px;
        text-align: center;
        line-height: 100px;
        color: #fff;
    }
    .grid-con-1 .grid-con-icon {
        background: rgb(45, 140, 240);
    }

    .grid-con-1 .grid-num, .UA_MOBILE_1 .grid-num_mobile {
        color: rgb(45, 140, 240);
    }

    .grid-con-2 .grid-con-icon {
        background: rgb(100, 213, 114);
    }

    .grid-con-2 .grid-num, .UA_MOBILE_2 .grid-num_mobile {
        color: rgb(45, 140, 240);
    }

    .grid-con-3 .grid-con-icon {
        background: rgb(242, 94, 67);
    }

    .grid-con-3 .grid-num, .UA_MOBILE_3 .grid-num_mobile {
        color: rgb(242, 94, 67);
    }
    .grid-con-4 .grid-con-icon {
        background: #429493;
    }

    .grid-con-4 .grid-num, .UA_MOBILE_4 .grid-num_mobile {
        color: #429493;
    }
    .grid-con-5 .grid-con-icon {
        background: #e08724;
    }

    .grid-con-5 .grid-num, .UA_MOBILE_5 .grid-num_mobile {
        color: #e08724;
    }

    .grid-con-6 .grid-num, .UA_MOBILE_6 .grid-num_mobile {
        color: green;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .pointer {
        cursor: pointer;
    }
</style>

