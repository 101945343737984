import { render, staticRenderFns } from "./topicAdd.vue?vue&type=template&id=e20ce964&scoped=true&"
import script from "./topicAdd.vue?vue&type=script&lang=js&"
export * from "./topicAdd.vue?vue&type=script&lang=js&"
import style0 from "./topicAdd.vue?vue&type=style&index=0&lang=css&"
import style1 from "./topicAdd.vue?vue&type=style&index=1&id=e20ce964&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e20ce964",
  null
  
)

export default component.exports