<template>
    <div>
        <el-button style="position: absolute;top: 10px;right: 10px" type="primary" @click="saveWatermark()">保存</el-button>
        <div style="display: flex;margin-bottom: 20px;font-size: 18px">
            <!--<el-input v-model="watermark.demo"></el-input>-->
            <i class="el-icon-czd-jiantouarrow484" style="font-size: 32px"></i>
            <el-input-number :step="10" v-model="watermark.x"></el-input-number>
            <i class="el-icon-czd-jiantou2" style="font-size: 32px"></i>
            <el-input-number :step="10" v-model="watermark.y"></el-input-number>
            <el-input style="width: 400px" v-model="watermark.demo"></el-input>
            <span>字体大小：</span>
            <el-input-number :step="5" v-model="watermark.size"></el-input-number>
            <span>字体颜色：</span>
            <el-color-picker v-model="watermark.color"></el-color-picker>
        </div>
        <div style="position: relative;">
                    <span draggable="true" style="position: absolute;"
                          :style="'font-size:' +  watermark.size + 'px; top: '+watermark.y + 'px;left:'+watermark.x + 'px;color:'+watermark.color"
                    >{{watermark.demo}}</span>
            <img :src="backgroundUrl">
        </div>
    </div>

</template>

<script>
    import bus from '../../common/bus'

    export default {
        data() {
            return {
                backgroundUrl: '',
                watermark: {
                    x: 60,
                    y: 60,
                    size: 32,
                    color: '#000000',
                    demo: '深圳市猫趴科技有限公司'
                },
            };
        },
        props: ['watermarkData'],
        watch: {
            watermarkData: function () {
                this.initUrl()
            }
        },
        created() {
            this.initUrl()
        },
        methods: {
            saveWatermark() {
                this.watermarkData.url = this.backgroundUrl + '?x=' + this.watermark.x + '&y=' + this.watermark.y + '&color=' + this.watermark.color + '&size=' + this.watermark.size
                bus.$emit('watermarkUrlChange', this.watermarkData)
            },
            initUrl() {
                let split = this.watermarkData.url.split('?');
                if (split.length > 1) {
                    this.backgroundUrl = split[0]
                    let param = split[1].split('&')
                    for (let p in param) {
                        let s = param[p].split('=');
                        this.watermark[s[0]] = s[1]
                    }
                } else {
                    this.backgroundUrl = this.watermarkData.url
                }
            }
        }
    }
</script>

<style scoped>

</style>
