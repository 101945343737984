<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 统计分析</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container user-analyse">
            <el-card class="box-card user-analyse-keyword">
                <div slot="header" class="clearfix">
                    <span>昨日关键数据</span>
                </div>
                <div class="my-box-card">
                        <table v-for="o in keyData" :key="o.id" >
                            <tr><td colspan="3" class="ft20">{{o.name}}</td></tr>
                            <tr><td colspan="3" class="ft30">{{o.number}}</td></tr>
                            <tr>
                                <td>日</td>
                                <td>{{Math.abs(o.dayPercent)}}%</td>
                                <td><i :class="o.dayPercent<0?'el-icon-czd-jiantou1-copy-copy red':(o.dayPercent===0?'el-icon-minus':'el-icon-czd-jiantou1 green')"></i></td>
                            </tr>
                            <tr>
                                <td>周</td>
                                <td>{{Math.abs(o.weekPercent)}}%</td>
                                <td><i :class="o.weekPercent<0?'el-icon-czd-jiantou1-copy-copy red':(o.weekPercent===0?'el-icon-minus':'el-icon-czd-jiantou1 green')"></i></td>
                            </tr>
                            <tr>
                                <td>月</td>
                                <td>{{Math.abs(o.monthPercent)}}%</td>
                                <td><i :class="o.monthPercent<0?'el-icon-czd-jiantou1-copy-copy red':(o.monthPercent===0?'el-icon-minus':'el-icon-czd-jiantou1 green')"></i></td>
                            </tr>
                        </table>
                    </div>
            </el-card>

            <div class="user-analyse-button">
                <el-radio-group v-model="buttonType" @change="loadUserNumberLineCharts(buttonType)">
                    <el-radio-button  v-for="t in buttonTypes" :key="t.value" :label="t.value">{{t.name}}</el-radio-button>
                </el-radio-group>
            </div>

            <el-card class="user-analyse-charts">
                <div slot="header">
                    <my-date-picker @dateChange="chartsDateChange"></my-date-picker>
                </div>
                <!--<charts :optiontoChildren="optiontoChildren"></charts>-->
                <multi-charts :charts-data.sync="chartsData" ></multi-charts>
            </el-card>
            <el-card class="user-analyse-table">
                <div slot="header" style="display: flex;justify-content: space-between">
                    <my-date-picker @dateChange="tableDateChange"></my-date-picker>
                    <el-button style="margin-right: 30px" type="text" @click="exportUserAnalyse">导出列表</el-button>
                </div>
                <el-table
                    @sort-change="handleSortTable"
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column
                        prop="foundTime"
                        label="时间"
                        sortable="'custom'"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="todayNumber"
                        label="新用户数"
                        sortable="'custom'"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="totalNumber"
                        sortable="'custom'"
                        label="总用户数">
                    </el-table-column>
                </el-table>
                <div class="paginaion" style="text-align: center;margin-top: 1em">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="currentPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="tableData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.count">
                    </el-pagination>
                </div>

            </el-card>

        </div>
    </div>
</template>

<script>
    import multiCharts from '../../common/multiCharts'
    import charts from '../../common/charts'
    import myDatePicker from '../../common/myDatePicker'

    export default {
        components: {
            multiCharts,
            myDatePicker
        },
        data: () => ({
            currentPage: 1,
            total: 0,
            size: 10,
            sortName: '',
            sortType: '',
            chartsData: [],
            chartsTitle:'新增用户数',
            tableData: [],
            keyData: [],

            buttonType: 1,
            buttonTypes: [
                {name: '新增用户数', value: 1},
                {name: '总用户数', value: 2}
            ],
            selectTime: '',
            tableStartTime: '',
            tableEndTime: '',
            queryData: {
                startTime: '',
                endTime: '',
                type: '1'
            },
        }),

        created() {

        },
        mounted() {
            this.loadKeyData()
        },
        methods: {
            //导出用户分析列表
            exportUserAnalyse() {
                this.$export({
                    url: this.$api+'/chigua/admin/analyze/wx-user/export',
                    data:{
                        startTime: this.tableStartTime,
                        endTime: this.tableEndTime,
                        colName: this.sortName,
                        order: this.sortType
                    }
                })
            },
            tableDateChange(data) {
                this.tableStartTime = data[0]
                this.tableEndTime = data[1]
                this.loadTableData()
            },
            //组件自动触发事件
            chartsDateChange(data) {
                this.queryData.startTime = data[0]
                this.queryData.endTime = data[1]
                this.loadUserNumberLineCharts()
            },
            handleSortTable(data) {
                //console.log(data.column, data.prop, data.order)
                if ('descending' === data.order) {
                    this.sortType = 'DESC'
                } else if ('ascending' === data.order) {
                    this.sortType = 'ASC'
                } else {
                    this.sortType = ''
                }
                this.sortName = data.prop
                this.loadTableData()
            },
            handleSizeChange(val) {
                this.size = val
                this.loadTableData()
            },
            handleCurrentChange(val) {
                this.currentPage = val
                this.loadTableData()
            },
            loadTableData() {
                this.getTableData(this.currentPage, this.size, this.tableStartTime, this.tableEndTime, this.sortName, this.sortType)
            },
            getTableData(page, size, startTime, endTime, colName, order) {
                this.$http({
                    url: this.$api + '/chigua/admin/analyze/wx-user/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page,
                        limit: size,
                        startTime: startTime,
                        endTime: endTime,
                        colName: colName,
                        order: order
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.tableData = res.data.data.records
                        this.currentPage = res.data.data.current
                        this.total = res.data.data.total
                        this.size = res.data.data.size
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            loadKeyData() {
                this.$http({
                    url: this.$api + '/chigua/admin/analyze/wx-user/yesterday',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.keyData = res.data.data
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            // 加载近三个月数据
            loadUserNumberLineCharts(type) {
                if (type) this.queryData.type = type
                for (let t in this.buttonTypes) {
                    if (this.buttonTypes[t].value === type) {
                        this.chartsTitle = this.buttonTypes[t].name
                    }
                }
                this.$http({
                    url: this.$api + '/chigua/admin/analyze/wx-user/graph',
                    method: 'post',
                    data: this.$qs.stringify(this.queryData)
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.chartsData = res.data.data
                        this.chartsData.unshift(['product','用户数'])
                        this.chartsData.unshift(this.chartsTitle)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
        }
    }
</script>

<style>
    .user-analyse .el-card__header {
        padding: 1px;
        background-color: #f0f0f0;
    }

</style>
<style scoped>
    .clearfix {
        margin: 5px;
    }

    .ft30{
        font-size: 30px;
    }
    .ft20{
        font-size: 20px;
    }
    .red {
        color: red;
    }

    .green {
        color: green;
    }

    .user-analyse-keyword {
        margin-bottom: 30px;
        /*display: flex;*/
        /*flex-direction: row;*/
        /*;*/
    }

    .my-box-card table{
        width: 150px;
    }
    .my-box-card {
        text-align: center;
        display: flex;
        /*width: 500px;*/
        justify-content: space-around;
    }

    .user-analyse-button {
        margin-bottom: 30px;
    }

    .user-analyse-charts {
        margin-bottom: 30px;
    }

    .user-analyse-table {
        margin-bottom: 30px;
    }

</style>
