<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>开发设置</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div style="padding: 10px">
            <el-button type="primary" @click="addmenuShow('L1')" style="margin-top: 1em;">添加一级目录</el-button>
            <el-tree
                class="menu-tree-table"
                :data="menuDataOffLine"
                :props="defaultProps"
                :indent="indent"
                :expand-on-click-node="false"
                accordion
                @node-click=""
            >
                <div class="menu-flex" style="width: 100%" slot-scope="{ node, data }">
                    <!--菜单名-->
                    <div style="width: 8em">
                        <img v-if="data.level === 'L1'" style="width: 25px;height: 35px; margin-right: 10px;vertical-align: middle;" :src="data.icon_url" alt="分类图标">
                        <span style="padding-left: 1px">{{ data.name }}({{data.id}})</span>
                    </div>
                    <!--图标-->
                    <div >
                        <img style="height: 35px; margin-right: 10px;vertical-align: middle;" :src="data.wap_banner_url" alt="分类展示背景图">
                    </div>
                    <!--菜单类型-->
                    <!--<div style="width: 100px">-->
                    <!--<span v-show="(data.menuType===2)">{{ data.keepAlive===1?'true':'false'}}</span>-->
                    <!--</div>-->
                    <!--菜单排序-->
                    <div style="font-size: 12px;color: #999;">
                        <!--<el-input style="width: 200px;" v-model="">-->
                        <!--<template slot="prepend">排序</template>-->
                        <!--</el-input>-->
                        序号： {{data.sort_order}}
                    </div>
                    <!--菜单状态-->
                    <!--<div >-->
                    <!--&lt;!&ndash;<el-button :type="data.isShow === 1? 'primary':'info'" @click="showMenu(data)">{{ data.isShow===&ndash;&gt;-->
                    <!--&lt;!&ndash;1? '显示':'隐藏' }}&ndash;&gt;-->
                    <!--&lt;!&ndash;</el-button>&ndash;&gt;-->
                    <!--<el-radio-group v-model="data.is_show" size="mini" v-show="!(data.menuType===3)">-->
                    <!--<el-radio-button label=0 @change.native="showMenu(data)">隐藏</el-radio-button>-->
                    <!--<el-radio-button label=1 @change.native="showMenu(data)">显示</el-radio-button>-->
                    <!--</el-radio-group>-->
                    <!--</div>-->
                    <!--<div style="width: 100px">-->
                    <!--<span v-for="item in menuType">{{ data.menuType===item.value?item.label:''}}</span>-->
                    <!--</div>-->
                    <div style="">
                        <i title="删除" class="el-icon-delete ft25 red pointer" @click="deleteCategory(data)" v-if="data.is_delete == 0"></i>
                        <span v-if="data.is_delete == 1" style="color: #ff0000;font-size: 12px;">已删除</span>

                        <i title="编辑" class="el-icon-edit-outline ft25 pointer" style="margin-left: 10px"
                           @click="editMenu(data)"></i>


                        <i title="上线" class="el-icon-sort ft25 pointer"
                           @click="upLineCategory(data)"></i>

                        <i title="添加子分类" class="el-icon-circle-plus-outline ft25 pointer" style="margin-left: 10px"
                           @click="addChildMenu(data)"></i>
                    </div>
                </div>
            </el-tree>
        </div>

        <addCategory></addCategory>
        <editCategory></editCategory>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import editCategory from '../../common/editCategory'
    import addCategory from '../../common/addCategory'

    export default {
        data: function () {
            return {
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                menuDataOffLine: [],
                showSencondMenu: false,
                paperId: '',
                indent: 0,
                showTable: false,
                tableData: '',
                menuType: [
                    {label: '目录', value: 1},
                    {label: '菜单', value: 2},
                    {label: '按钮', value: 3}
                ],
            }
        },
        components: {
            editCategory, addCategory
        },
        watch: {},
        created() {
            this.getPaperList()
        },
        mounted() {
            bus.$on('updateCategory', msg => {
                if (msg) {
                    this.getPaperList()
                }
            })
        },
        methods: {
            showMenu(d) {
                //     //console.log(d)
                //     let show = d.isShow === 1 ? '0' : '1'
                this.$http({
                    url: this.$api + '/admin/menu/update',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: d.id,
                        is_show: d.isShow
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('菜单更新成功！')
                        // this.getMenuList()
                    }
                })
            },
            addChildMenu(data) {
                let d = {
                    parent_id: data.id,
                    level: 'L2',
                    icon: data.menuType === 2 ? 'el-icon-czd-quanxian' : '',
                }
                bus.$emit('addCategory', d)
            },
            addmenuShow() {
                let data = {
                    parent_id: 0,
                    level: 'L1',
                }
                bus.$emit('addCategory', data)
            },
            editMenu(row) {
                bus.$emit('editCategory', row)
            },
            upLineCategory (row) {
                this.$confirm('您正在进行【上线分类】操作，该操作会直接在客户端显示该分类', '警示', {
                    confirmButtonText: '上线',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/category/switch',
                        method: 'post',
                        data: this.$qs.stringify({categoryId: row.id, isShow: 1})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno == 0) {
                                this.$message.success('上线成功！')
                                this.getPaperList(this.currentTagId, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            deleteCategory(row) {
                this.$confirm('您执行删除分类操作！删除后将会在小程序下线', '警告', {
                    confirmButtenText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(msg => {
                    this.$http({
                        url: this.$api + '/admin/category/delete',
                        method: 'post',
                        data: this.$qs.stringify({
                            categoryId: row.id
                        })
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success('删除【' + row.name + '】分类成功！该菜单将同步在客户端删除！');
                            this.getMenuList()

                        } else {
                            this.$message.error('分类删除失败')
                        }
                    })
                }).catch(msg => {
                    this.$message.info('操作取消')
                })
            },
            getMenuList() {
                this.$http({
                    url: this.$api + '/admin/category/draft',
                    method: 'get'
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.menuDataOffLine = response.data.data
                    } else {
                        this.$message.error('菜单加载失败')
                    }
                })
            },
            getPaperList() {
                // var menuDataOffLine = JSON.parse(localStorage.getItem('menuDataOffLine'))
                // this.menuDataOffLine = menuDataOffLine
                this.getMenuList()
            }
        }
    }
</script>
<style>
    .menu-tree-table .is-expanded {
        background-color: #f0f8f9;
    }

    .menu-tree-table .el-tree-node {
        padding: 10px 0 10px 0;
        border-bottom: 1px solid #f0f0f0;
        /*text-align: center;*/
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .menu-flex {
        display: flex;
        justify-content: space-around;
        line-height: 34px;
        /*margin:auto;*/
    }
</style>
