<template>
    <div >
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}人）</label>
        </div>
        <div class="button-tool">
            <div class="search-title">
                <el-input placeholder="请输入用户名查询" v-model="searchTitle" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
                <el-input placeholder="请输入最近登录ip查询" v-model="search_last_login_ip" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
                <el-input placeholder="请输入openid查询" v-model="search_openid" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
            <div>
                <!--<el-button type='primary' disabled>添加标签</el-button>-->
                <!--<el-button type='success' @click="addMultiUserToBlackList" :disabled="activeName === '黑名单'">加入黑名单</el-button>-->
            </div>
        </div>

        <div class="tagSwitch">
            <el-tabs v-model="activeName" @tab-click="switchTag">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>

        <el-table :data="tableData.data"
                  v-loading="!tableData.data" style="width: 100%"
                  @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55"></el-table-column>

            <el-table-column label="头像" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.group_avatar" style="width: 40px;vertical-align: middle;">
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="公号用户id" prop="belong_user_id" width="80"></el-table-column>
            <el-table-column label="小组名" prop="group_name" width="150"></el-table-column>
            <el-table-column label="用户昵称" prop="belong_user_name" width="150"></el-table-column>
            <el-table-column label="微信号" prop="last_login_ip" width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.wechat}}</span>
                </template>
            </el-table-column>
            <el-table-column label="手机号" prop="mobile" width="120"></el-table-column>

            <el-table-column label="审核状态" width="100">
                <template slot-scope="scope">
                    <span>{{scope.row.is_permit_str}}</span>
                </template>
            </el-table-column>

            <el-table-column label="注册日期" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.add_time }}</span>
                </template>
            </el-table-column>
            <el-table-column label="更新时间" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.update_time }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div class="disflex_between_center">
                        <!-- 编辑 -->
                        <el-button type="primary" @click="$router.push('/managerTeamDetail?service_id=' + scope.row.belong_user_id)">
                            小组综合信息
                        </el-button>
                    </div>
                </template>
            </el-table-column>

        </el-table>

        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                activeName: '全部用户',
                // loading: true,
                tableData: {},
                labelNameList: [],
                search_last_login_ip: '', // 最后登录ip
                search_openid: '' , // 通过openid搜索用户
                tagSwitchData: [
                    {
                        name: '全部用户', value: 0,
                    },
                    {
                        name: '黑名单', value: 1,
                    }
                ],
                currentPage: 1,
                total: 0,
                size: 10,
                currentTagId: '',
                paperStatus: '未上线',
                paperId: '',
                searchTitle: '',
                tableHeight: 600,
                selectUserList: []
            }
        },
        components: {},
        watch: {},
        created() {
            this.getPaperList(0, 1, 10)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
        },
        mounted() {

        },
        methods: {
            switchTag (e) {
                this.activeName = e.label;
                if (e.label === '黑名单' || e.index === 1) {
                    this.getPaperList(1, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            handleSelectionChange (val) {
                this.selectUserList = val
            },
            // 批量加入黑名单
            addMultiUserToBlackList () {
                let allName = '';
                let userList = this.selectUserList.map(function (item) {
                    allName += (item.nickname + '--');
                    return {
                        id: item.id,
                        is_limit: 1
                    }
                })
                this.$confirm('您正在批量把【' + userList.length + '】人加入黑名单！名单：' + allName + ', 正在批量加入黑名单，请谨慎操作。', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/user/addMultiUserToBlackList',
                        method: 'post',
                        data: {
                            user_list: userList
                        }
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success(response.data.data)
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            handleSearchByTitle() {
                if (this.activeName === '黑名单') {
                    this.getPaperList(1, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            handleSizeChange(val) {

                if (this.activeName === '黑名单') {
                    this.getPaperList(1, this.tableData.currentPage || 1, val, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, val, this.searchTitle)
                }
            },
            handleCurrentChange(val) {
                if (this.activeName === '黑名单') {
                    this.getPaperList(1, val, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, val, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            getPaperList(is_limit, page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/serviceAccount/teamList',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page,
                        size: length,
                        name: title,
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style scoped>
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .pointer {
        cursor: pointer;
    }
</style>

