<template>
    <div>
       <send-template></send-template>
    </div>
</template>

<script>
    import sendTemplate from '../../common/sendSms';
    export default {
        data: function () {
            return {
            }
        },
        components: {
            sendTemplate
        },
        watch: {

        },
        created() {
        },
        mounted() {

        },
        methods: {

        }
    }
</script>
<style scoped>

</style>

