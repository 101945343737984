<template>
  <!-- 备用dash -->
  <div id="main" style="width: 100%;height: 400px;"></div>
</template>
<script>
    export default {
        name: '',
        data () {
            return {
                charts: '',
                this_data:''
            }
        },
        props: ['optiontoChildren'],
        watch: {
          optiontoChildren: function () {
            this.this_data = this.$qs.parse(this.optiontoChildren)
            this.$nextTick(function() {
                this.drawPie('main')
            })
          }
        },
        mounted () {
        },
        methods:{
            drawPie(id){
               this.charts = this.$echarts.init(document.getElementById(id),null, {renderer: 'canvas'})
               let that = this
               this.charts.setOption({
                  title : {
                      text: that.this_data.title,
                      // subtext: '纯属虚构'
                  },
                  tooltip : {
                      trigger: 'axis'
                  },
                  // 头部显示的图线名称
                  legend: {
                      data: that.this_data.legend_data
                  },
                  // 工具栏显示
                  toolbox: {
                      show : true,
                      feature : {
                          mark : {show: true},
                          dataView : {show: true, readOnly: false},
                          magicType : {show: true, type: ['line', 'bar']},
                          restore : {show: true},
                          saveAsImage : {show: true}
                      }
                  },
                  calculable : true,
                  // 横坐标
                  xAxis : [
                      {
                          type : 'category',
                         boundaryGap : false,
                          data : that.this_data.options? that.this_data.options.foundTime:''
                      }
                  ],
                  // 纵坐标
                  yAxis : [
                      {
                          type : 'value',
                          axisLabel : {
                              formatter: '{value}'
                          }
                      }
                  ],
                   dataZoom: [
                       {   // 这个dataZoom组件，默认控制x轴。
                           type: 'slider', // 这个 dataZoom 组件是 slider 型 dataZoom 组件
                           start: 0,      // 左边在 10% 的位置。
                           end: 100         // 右边在 60% 的位置。
                       }
                   ],
                  //  填充数据
                  series : [
                      {
                          name:that.this_data.series_name,
                          type:'line',
                          data:that.this_data.options? that.this_data.options.number:'',
                      },
                  ]
              })
               // window.addEventListener("resize", this.chart.resize);
            }
        },
      //调用
        mounted(){
          // 在watch 中调用charts
        }
    }
</script>
<style scoped>
    * {
        margin: 0;
        padding: 0;
        list-style: none;
    }
</style>
