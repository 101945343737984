<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>商品管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">


            <div class="padding15">
                <el-switch
                    v-model="is_public"
                    active-text="公众号文章"
                    inactive-text="PGC文章">
                </el-switch>
            </div>
            <div  v-if="!is_public">
                <el-form style="width: 100%" label-width="120px" :model="pushData" ref="pushData">
                    <el-form-item label="专题推广标题*">
                        <el-input v-model="pushData.title"></el-input>
                    </el-form-item>
                    <el-form-item label="二级标题*">
                        <el-input v-model="pushData.subtitle"></el-input>
                    </el-form-item>
                    <el-form-item label="专题分类">

                        <el-select v-model="pushData.topic_category_id" >

                        </el-select>

                    </el-form-item>

                    <div class="display-flex" style="margin: 0 0 20px 60px">

                        <div>
                            <span>专题LOGO：</span>
                            <upload-image title="分类的小logo*" v-model="pushData.avatar" style="margin-right: 50px">
                                <div style="text-align: center;">4：3</div>
                            </upload-image>
                        </div>
                        <div>
                            <span>专题主图：</span>
                            <upload-image title="专题显示主图【比填】*" v-model="pushData.scene_pic_url" style="margin-right: 50px">
                                <div style="text-align: center;">4：3</div>
                            </upload-image>
                        </div>

                        <!--<div>-->
                        <!--<span>专题列表图【详情轮播图】：</span>-->
                        <!--<upload-image title="专题显示主图【比填】" v-model="pushData.item_pic_url" style="margin-right: 50px">-->
                        <!--<div style="text-align: center;">4：3</div>-->
                        <!--</upload-image>-->
                        <!--</div>-->
                    </div>

                    <el-form-item label="*立即上线？">
                        <el-switch on-text="" off-text="" v-model="pushData.is_show"></el-switch>
                    </el-form-item>
                    <el-form-item label="排序*">
                        <el-input-number v-model="pushData.sort_order" :min="1" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="设置阅读量">
                        <el-input-number v-model="pushData.read_count" :min="0"></el-input-number>
                    </el-form-item>
                    <el-form-item label="推荐价格* ￥">
                        <el-input v-model="pushData.price_info"></el-input>
                    </el-form-item>

                </el-form>

                <h1 style="padding: 1em;text-align: center;">专题详情</h1>
                <ueditor  style="line-height: 20px;" v-model="pushData.content"></ueditor>
            </div>

            <div v-if="is_public">
                <el-form style="width: 50%" label-width="120px"  :model="pushData" ref="pushData">
                    <el-form-item label="标题*">
                        <el-input v-model="pushData.title"></el-input>
                    </el-form-item>
                    <el-form-item label="二级标题*">
                        <el-input v-model="pushData.subtitle"></el-input>
                    </el-form-item>
                    <el-form-item label="专题主图*">
                        <upload-image title="专题显示主图【比填】*" v-model="pushData.scene_pic_url" style="margin-right: 50px">
                            <div style="text-align: center;">4：3</div>
                        </upload-image>
                    </el-form-item>
                    <el-form-item label="*立即上线？">
                        <el-switch on-text="" off-text="" v-model="pushData.is_show"></el-switch>
                    </el-form-item>

                    <el-form-item label="公众号文章链接*">
                        <el-input v-model="pushData.content" placeholder="请输入公众号文章链接"></el-input>
                    </el-form-item>
                </el-form>
            </div>

        </div>
        <div class="operationBtn">
            <el-button class="editor-btn" type="primary" @click="submitPaper">提交</el-button>
            <!--<el-button class="editor-btn" type="default" @click="saveAsDraft">存为草稿</el-button>-->
        </div>


    </div>
</template>

<script>
    import ueditor from '../../common/editor'
    import bus from '../../common/bus'
    import uploadImage from '../../common/uploadImage'

    export default {
        data: function () {
            return {
                is_public: false, // 是否食pgc的文章，而不是转载公众号
                currentCategory: '', //选择胡当前专题一级分类
                currentCategoryArr: [], //选择胡当前专题子分类数组
                column: '',
                columnList: [],
                tagsList: [],
                addTagsList: [],
                addTagsForm: false,
                templateDialogVisible: false,
                title: '新增文章',
                templateSearchName: '',
                fileList: '',
                category: [],
                templateData: [],
                recommendChecked: 0, // 推荐类型
                brandOptions: [], // 品牌集合
                pushData: {
                },
                fileList_arr: [], // 提交的专题图数组
                isThisBrandNotDeleted: false, // 判断该专题是否被删除
            }
        },
        watch: {
            $route: {
                handler (newv) {
                    if (newv.query.id) {
                        this.loadGoodsDetail(newv.query.id).then(res => {
                            // this.getParentId()
                        })
                    } else {
                        this.$message.error('null id 专题不存在！')
                    }
                },
                immediate: true
            },
            currentCategory (newv) {
                // this.getCurrentCategory(newv)
            },
            recommendChecked:{
                handler (newv){
                    newv = Number(newv)
                    if (newv === 0) {
                        this.pushData.is_hot = 0
                        this.pushData.is_new = 0
                    } else if (newv === 1) {
                        this.pushData.is_hot = 0
                        this.pushData.is_new = 1
                    } else if (newv === 2) {
                        this.pushData.is_hot = 1
                        this.pushData.is_new = 0
                    }
                },
                immediate: true
            }
        },
        methods: {
            // 改变品牌
            changeBrand () {
              this.isThisBrandNotDeleted = true
            },
            // 获取专题详情
            async loadGoodsDetail (id) {
                await this.$http({
                    url: this.$api + "/admin/topic/get",
                    data: this.$qs.stringify({ topicId: id }),
                    method: 'post'
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.pushData = response.data.data
                        for (let item in response.data.data) {
                            for (let attr in this.pushData) {
                                if (item == attr) {
                                    this.pushData[attr] = response.data.data[item]
                                }
                            }
                        }
                        for (let item in this.brandOptions) {
                            if (this.brandOptions[item].id == this.pushData.brand_id) {
                                this.isThisBrandNotDeleted = true;
                                break
                            }
                        }

                        if (this.pushData.type === 1) {
                            this.is_public = true
                        } else {
                            this.is_public = false
                        }
                        // 判断是否直接上线
                        if (this.pushData.is_on_sale == 0) {
                            this.pushData.is_on_sale = true
                        } else {
                            this.pushData.is_on_sale = false
                        }

                        this.fileList_arr =  response.data.data.gallery?  response.data.data.gallery.split(','):[]
                    }
                });
            },
            // 获取分类父id
            getParentId () {
                this.$http({
                    url: this.$api + "/admin/category/getParent",
                    data: this.$qs.stringify({ categoryId: this.pushData.category_id }),
                    method: 'post'
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.pushData.parent_id = this.currentCategory =  response.data.data.parent_id
                    }
                });
            },
            // 上传轮播图
            handleImageSuccess (res) {
                if (res.errno == 0) {
                    this.fileList_arr.push(res.data)
                }
            },
            // 删除专题属性
            handleRemoveAttribute (index) {
                if(index>=0&&index<this.pushData.attribute.length)
                {
                    this.pushData.attribute.splice(index, 1);
                }
            },
            // 新增一条专题属性
            handleAddAttribute () {
                let obj = {"name":"", "value":""};
                this.pushData.attribute.push(obj);
            },
            // 获取品牌
            getBrand() {
                this.$http({
                    url: this.$api + "/admin/brand/index",
                    data: this.$qs.stringify({ params: { size: 500 } }),
                    method: 'post'
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.brandOptions = this.brandOptions.concat(response.data.data.data);
                    }
                });
            },

            handleClose(tag) {
                this.addTagsList.splice(this.addTagsList.indexOf(tag), 1);
            },
            submitPaper() {
                const loading = this.$loading({
                    lock: true,
                    text: '豆豆正在打扰爸爸敲代码...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // 判断是否需要直接设置成新品或者是是否上线销售，
                this.pushData.is_on_sale? this.pushData.is_on_sale = 1:this.pushData.is_on_sale = 0;
                this.pushData.list_pic_url = this.pushData.primary_pic_url
                // 专题图册，即专题的轮播图。
                this.pushData.goodsGallery = this.fileList_arr.join(',');
                // 提交所有参数
                this.$http({
                    url: this.$api + '/admin/topic/update',
                    method: 'post',
                    data: this.$qs.stringify(this.pushData)
                }).then(response => {
                    loading.close()
                    if (response.data.errno === 0) {
                        this.$notify({
                            title: '成功',
                            message: '更新专题成功!',
                            type: 'success'
                        });
                        this.$router.push('/topiclist');
                        let reload = true;
                        bus.$emit('reloadPaper', reload)
                    }
                })
                loading.close()
            },
            listCategory() {
                this.$http({
                    url: this.$api + '/admin/category/parent',
                    method: 'post'
                }).then(response => {
                    if (response.data != null) {
                        this.category = response.data.data
                    }
                })
            },
            getCurrentCategory(id) {
                this.$http({
                    url: this.$api + '/admin/category/current',
                    method: 'post',
                    data: this.$qs.stringify({categoryId: id})
                }).then(response => {
                    if (response.data != null) {
                        this.currentCategoryArr = response.data.data
                    }
                })
            },
        },
        created() {
            this.Auth_token = localStorage.getItem('auth_token')
            // this.listCategory()
            // this.getBrand()
        },
        mounted() {
            let ct = this.$route.query.ct
            this.uploadData.contentType = ct
        },
        components: {
            ueditor, uploadImage
        },
    }
</script>
<style>

    .el-upload-list{
        display: none !important;
    }
</style>
<style scoped>
    .operationBtn{
        text-align: right;
        padding: 20px;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .audio-div {
        margin: 20px 0 20px 0;
    }

    .article-edit span {
        width: 100px;
        margin-left: 20px;
    }

    .display-flex {
        display: flex;
    }
</style>
