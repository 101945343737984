<template>
    <div>
        <div class="crumbs">
            <label>
                <span>{{ $route.meta.title }}</span>（共{{ total }}篇）</label>
            <el-input style="width:260px;" placeholder="请输入标题或作者查询" v-model="searchState"
                      @keyup.enter.native="handleSearchByTitle">
                <i class="el-icon-search el-input__icon pointer " style="height:34px;" slot="suffix"
                   @click="handleSearchByTitle"></i>
            </el-input>
        </div>
        <div class="tagSwitch disflexct">
            <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 100%">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="(item.id).toString()"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table :data="tableData" style="width: 100%" :header-cell-style="{background:'#F5F7F8'}">
            <el-table-column label="标题">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.title }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="作者" width="120">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        {{ scope.row.author}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="所属栏目" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        {{ scope.row.tag }}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="发布时间" width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ formatStrTime(scope.row.releaseTime?scope.row.releaseTime:scope.row.createTime,16) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="违规或下线理由" width="300">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        {{ scope.row.offlineReason }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="100px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px; align-items: flex-end;">
                        <!-- 上下线 -->
                        <span class="ft12 operateEvent pointer" title="上线" @click="operateVideo( scope.row)"
                              style="height: 24px" :name="scope.row.status">
              <img src="https://file.chezidao.com/root/static/icon/online.png" style="width: 20px">
            </span>
                        <!-- 删除 -->
                        <i title="删除" class="el-icon-delete ft25 red pointer" @click="deleteVideo(scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>
    </div>
</template>

<script>
    import bus from "../../common/bus";
    import commonlist from "../../common/userCommon";
    import {Loading} from "element-ui";

    export default {
        data: function () {
            return {
                searchState: "",
                tableData: [],
                tagSwitchData: [],
                activeName: "1",
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: "",
                paperId: "",
                showCommon: false,
                commonData: "",
                searchStr: "",
                timeDiff: "创建时间",
                showCommentButtonText: false
            };
        },
        components: {
            commonlist
        },
        watch: {},
        created() {
            this.tagSwitchData = [
                {id: 1, name: "文章"},
                {id: 2, name: "视频"},
                {id: 3, name: "专栏"}
            ];
            this.getPaperList(1, "", 1, 10);
        },
        methods: {
            // 删除视频
            deleteVideo(row) {
                this.$confirm(
                    "您正在进行【删除专栏】操作，该操作不可恢复，确认？",
                    "警示",
                    {
                        confirmButtonText: "删除",
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$http({
                            url: this.$api + "/api/column/info/delete",
                            method: "post",
                            data: this.$qs.stringify({ids: row.id})
                        }).then(response => {
                            if (response.status === 200) {
                                if (response.data.errno === 0) {
                                    this.$message.success("专栏已删除！");
                                    this.getPaperList();
                                }
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作取消"
                        });
                    });
            },
            operateVideo(row) {
                // //console.log(row);

                let url = "",
                    tips = "操作成功",
                    alertText = "";
                if (this.activeName == 1) {
                    url = this.$api + "/chigua/admin/article/onLine/batch";
                }
                if (this.activeName == 2) {
                    url = this.$api + "/chigua/admin/video/onLine/batch";
                }
                if (this.activeName == 3) {
                    url = this.$api + "/api/column/info/online";
                }
                tips = "上线成功！";
                alertText = "上线";
                this.$confirm(
                    "您正在进行【" + alertText + "】操作，该操作会直接改变专栏发布状态",
                    "警示",
                    {
                        confirmButtonText: alertText,
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$http({
                            url: url,
                            method: "post",
                            data: this.$qs.stringify({ids: row.id})
                        }).then(response => {
                            if (response.data.errno === 0) {
                                this.$message.success(tips);
                                this.getPaperList(
                                    this.activeName,
                                    this.searchState,
                                    this.currentPage,
                                    this.size
                                );
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作取消"
                        });
                    });
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList(
                    this.activeName,
                    this.searchState,
                    this.currentPage,
                    this.size
                );
            },
            handleSizeChange(val) {
                this.getPaperList(
                    this.activeName,
                    this.searchState,
                    this.currentPage,
                    val
                );
            },
            handleCurrentChange(val) {
                this.getPaperList(this.activeName, this.searchState, val, this.size);
            },
            getPaperList(type, title, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: "已经很努力加载啦~",
                    spinner: "el-icon-loading",
                    background: "rgba(183, 183, 183, 0.57)"
                });
                // //console.log(type);

                if ((page === undefined) | (page === null)) {
                    page = 1;
                } else if ((length === undefined) | (length === null)) {
                    length = this.size;
                }
                let url = "";
                if (type == 1) {
                    url = this.$api + "/chigua/admin/article/drafts/users";
                }
                if (type == 2) {
                    url = this.$api + "/chigua/admin/video/drafts";
                }
                if (type == 3) {
                    url = this.$api + "/api/column/info/drafts/column";
                }
                this.$http({
                    url: url,
                    method: "post",
                    data: this.$qs.stringify({
                        status: 3,
                        queryName: this.searchState,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data.records;
                        this.currentPage = response.data.data.current;
                        this.total = response.data.data.total;
                        this.size = response.data.data.size;
                        loading.close();
                    }
                });
            },
            switchTag(tab) {
                // //console.log(tab);
                for (var i in this.tagSwitchData) {
                    if (tab.name == this.tagSwitchData[i].id) {
                        this.getPaperList(
                            this.tagSwitchData[i].id,
                            this.searchState,
                            1,
                            this.size
                        );
                    }
                }
            }
        }
    };
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .disflexct {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>
