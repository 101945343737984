<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 开发设置</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-table :data="ditDetailList" style="width: 100%"  >

            <el-table-column label="昵称" >
              <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium" >{{ scope.row.nickname }}</span>
                  </div>
              </template>
            </el-table-column>
            <el-table-column label="用户名" prop="company">
              <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium" >{{ scope.row.username }}</span>
                  </div>
              </template>
            </el-table-column>
            <el-table-column label="用户 id" >
              <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium" >{{ scope.row.id }}</span>
                  </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <div style="width: 100%;display: flex;justify-content: space-between;">
                    <span @click="editDitValue(scope.row)" class="el-icon-edit-outline ft25"></span>
                    <span @click="deleteDit(scope.row.id)" class="el-icon-delete ft25 red"></span>
                </div>
              </template>
            </el-table-column>
        </el-table>
          <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageContent.current"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="tableData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageContent.total">
            </el-pagination>
          </div>
        <br />
         <el-button type="primary" @click="addNewBlockWord">新增用户</el-button>
         <!-- 新增屏蔽词 -->
           <el-dialog
              title="新增用户"
              :visible.sync="showaddDialog"
              width="30%">
              <el-form>
                <el-form-item label="昵称">
                  <el-input  v-model="addNewWordData.nickname" placeholder="请输入昵称（>3字符）"></el-input>
              </el-form-item>

                <el-form-item label="账号">
                  <el-input  v-model="addNewWordData.username" placeholder="请输入登陆账号（>3字符）"></el-input>
              </el-form-item>
                <el-form-item label="密码">
                  <el-input type="password" v-model="addNewWordData.password" placeholder="请输入密码"></el-input>
              </el-form-item>
                <el-form-item label="二次验证">
                  <el-input  type="password"  v-model="addNewWordData.repsw" placeholder="请重新输入相同密码" @blur="validate"></el-input>
              </el-form-item>
                <el-form-item label="权限类型">
                  <el-input v-model="addNewWordData.per" placeholder="请输入权限类型"></el-input>
              </el-form-item>
                <el-form-item label="用户描述">
                  <el-input v-model="addNewWordData.describe" placeholder="请输入用户描述"></el-input>
              </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="showaddDialog = false">取 消</el-button>
                <el-button type="primary" @click="saveAddDitValue()">新  增</el-button>
              </span>
            </el-dialog>
            <!-- 修改屏蔽词 -->
          <el-dialog
              title="修改用户密码"
              :visible.sync="showEditDialog"
              width="30%">
              <el-form>
                <el-form-item label="key">
                  <el-input disabled v-model="editData.nickname"></el-input>
              </el-form-item>
                <el-form-item label="值">
                  <el-input disabled v-model="editData.username" placeholder=""></el-input>
              </el-form-item>
                <el-form-item label="密码">
                  <el-input type="password" v-model="editData.password" placeholder="请输入密码"></el-input>
              </el-form-item>
                <el-form-item label="二次验证">
                  <el-input  type="password"  v-model="editData.repsw" placeholder="请重新输入相同密码" @blur="validate2"></el-input>
              </el-form-item>
                <el-form-item label="用户 id">
                  <el-input disabled v-model="editData.id" placeholder="请输入系统设置描述"></el-input>
              </el-form-item>
              </el-form>
              <span slot="footer" class="dialog-footer">
                <el-button @click="showEditDialog = false">取 消</el-button>
                <el-button type="primary" @click="saveEditDitValue(editData.id)">修  改</el-button>
              </span>
            </el-dialog>
    </div>
</template>

<script>
    export default {
        data: function(){
            return {
                ditDetailList: [],
                showEditDialog: false,
                showaddDialog: false,
                pageContent: {
                  current: 1,
                  pages: 1,
                  size: 10,
                  total: 0,
                },
                editData: {
                  username: '',
                  password: '',
                  nickname: '',
                  describe:'',
                  id: ''
                },
                addNewWordData: {
                  username: '',
                  password: '',
                  repsw: '',
                  id: '',
                  nickname: '',
                  describe:'',
                  per: ''
                }
            }
        },
        watch:{
            addNewWordData: {
                handler (newvalue) {
                    if (this.addNewWordData.psw !== this.addNewWordData.repsw) {

                    }
                },
                deep: true
            }
        },
        created () {
            this.loadBlockWord()
        },
        methods: {
            // 编辑验证
            validate2 () {
                if (this.editData.password == '') {
                        this.$message.error('密码不能为空!')
                        return false
                } else {
                    if (this.editData.password !== this.editData.repsw) {
                        this.$message.error('两次输入密码不相同!')
                        return false
                    } else {
                        if (this.editData.username == '' ) {
                            this.$message.error('请输入正确的用户名，大于3字符!')
                            return false
                        } else {
                            return true
                        }
                    }
                }
            },
            // 新增验证
            validate () {
                if (this.addNewWordData.password == '') {
                        this.$message.error('密码不能为空!')
                        return false
                } else {
                    if (this.addNewWordData.password !== this.addNewWordData.repsw) {
                        this.$message.error('两次输入密码不相同!')
                        return false
                    } else {
                        if (this.addNewWordData.username == '' || this.addNewWordData.username.length < 3) {
                            this.$message.error('请输入正确的用户名，大于3字符!')
                            return false
                        } else {
                            return true
                        }
                    }
                }
            },
            handleCurrentChange (val) {
              this.loadBlockWord(val,this.pageContent.size)
            },
            handleSizeChange (val) {
              this.loadBlockWord(this.pageContent.pages,val)
            },
            saveAddDitValue () {
               if (!this.validate()) return
                this.$http({
                    url: this.$api + '/chigua/admin/user/add',
                    method: 'post',
                    data: this.$qs.stringify(this.addNewWordData)
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        // this.loadBlockWord(this.pageContent.pages,this.pageContent.size)
                        // 隐藏窗口
                        this.showaddDialog = false
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            addNewBlockWord () {
              this.showaddDialog = true

            },
            saveEditDitValue (row) {
              if (!this.validate2()) return
                this.$http({
                    url: this.$api + '/chigua/admin/user/update/password',
                    method: 'post',
                    data: this.$qs.stringify(this.editData)
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.loadBlockWord(this.pageContent.pages,this.pageContent.size)
                        // 隐藏窗口
                        this.showEditDialog = false
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            deleteDit (id) {
                this.$http({
                    url: this.$api + '/chigua/admin/user/delete',
                    method: 'post',
                    data: this.$qs.stringify({
                      id: id
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.loadBlockWord(this.pageContent.pages,this.pageContent.size)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            editDitValue (row) {
                this.editData = row
                this.showEditDialog = true
            },
            loadBlockWord (page,limit) {
                this.$http({
                    // url: this.$api + '/chigua/sys/config/page',
                    url: this.$api + '/chigua/admin/user/page',
                    method: 'post',
                    data: this.$qs.stringify({
                      page: page? page:1,
                      limit: limit? limit:10
                    })
                }).then(res => {

                    if (res.data.errno == 0) {
                        this.ditDetailList = res.data.data.records
                        for (var item in res.data.data) {
                          this.pageContent[item] = Number(res.data.data[item])
                        }
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            }
        }
    }
</script>
