<template>
    <div class="my-data-picker">
        <el-select v-model="selectTime" placeholder="请选择时间">
            <el-option
                v-for="item in selectedArr"
                :key="item.name"
                :label="item.name"
                :value="item.value">
            </el-option>
        </el-select>

        <el-date-picker
            v-model="pickerTime"
            :type="type?type:'daterange'"
            range-separator="至"
            unlink-panels
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="defaultTime"
            :clear-icon="''"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>

    </div>
</template>

<script>
    export default {
        data: () => ({
            value6: '',
            buttonType: 1,
            // 选择时间数组
            selectedArr: [
                {name: '最近7天', value: 1},
                {name: '最近15天', value: 2},
                {name: '最近30天', value: 3},
            ],
            pickerTime: [],
            selectTime: '',
            defaultTime:['00:00:00', '23:59:59'],
            queryData: {
                startTime: '',
                endTime: '',
                type: '1'
            },
        }),
        props:['type'],
        mounted(){
            this.selectTime = 3
        },
        watch: {
            selectTime: function (newvalue) {
                let startTime;
                if (newvalue === 1) {
                    startTime = this.Format(new Date(new Date().getTime() - 3600 * 1000 * 24 * 7), 'yyyy-MM-dd 00:00:00')
                } else if (newvalue === 2) {
                    startTime = this.Format(new Date(new Date().getTime() - 3600 * 1000 * 24 * 15), 'yyyy-MM-dd 00:00:00')
                } else if (newvalue === 3) {
                    startTime = this.Format(new Date(new Date().getTime() - 3600 * 1000 * 24 * 30), 'yyyy-MM-dd 00:00:00')
                }
                this.pickerTime = []
                this.pickerTime.push(startTime)
                this.pickerTime.push(this.Format('', 'yyyy-MM-dd 23:59:59'))
            },
            pickerTime: {
                handler: function (newvalue) {
                    this.$emit('dateChange',newvalue)
                },
                deep: true
            }
        }

    }
</script>
<style>
    .my-data-picker .el-input{
        width: 100px;
    }
</style>
<style scoped>

</style>
