<template>
    <div>
        <div class="crumbs">
            <label>
                <span>新建专栏</span>
            </label>
        </div>
        <div class="top">
            <div style="margin-top:20px;">
                <span style="font-size: 14px; margin-right: 8px;">专栏标题</span>
                <el-input v-model="columnName" style="width:50%; margin-right: 20%;"></el-input>
            </div>
            <div style="margin-top:20px;">
                <span style="font-size: 14px;">关联作者</span>
                <el-input style="width:55%;" v-model="authorName"></el-input>
                <el-button type="primary" @click="changeauthor" style="height:32px;">选择</el-button>
            </div>
            <div style="height:80px;width:160px;">
                <span style="font-size: 14px;margin-top: 30px;margin-right:15px; display:block;float:left;">专栏封面</span>
                <el-upload class="avatar-uploader" :action="$api + UPLOAD_HEADER_IMG" :headers="{ Auth_token }"
                           :show-file-list="false" :on-success="handleAvatarSuccess"
                           :before-upload="beforeAvatarUpload">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </div>
        </div>
        <el-dialog :visible.sync="changeAuthor" width="50%">
            <span style="font-size: 16px;color:#000; ">请输入需要关联的用户</span>
            <el-input placeholder="搜索用户" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                      style="width:30%;margin-left:40%;">
                <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
            </el-input>
            <el-table ref="multipleTable" :data="changeUseList" height="500" style="width: 100%">
                <el-table-column label="用户">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <img :src="scope.row.authorHead" style="width:45px;  border-radius: 50%;">
                            <span style="margin-left: 10px;">{{ scope.row.authorName }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="职务">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <span style="margin-left: 10px">{{ scope.row.authorJobPosition }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="选择">
                    <template slot-scope="scope">
                        <el-button type="success" @click="getPaperData(scope.row)" icon="el-icon-check"
                                   circle></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="paginaion" style="display: inline-block; margin-left:20%;margin-top: 2em">
                <el-pagination @size-change="handleSizeChanges" @current-change="handleCurrentChanges"
                               :current-page="currentPages" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize"
                               layout="total, sizes, prev, pager, next, jumper" :total="totals">
                </el-pagination>
            </div>
        </el-dialog>
        <div class="tagSwitch disflexct" v-show="authorId !=''">
            <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 70%">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="(item.id).toString()"></el-tab-pane>
            </el-tabs>
            <el-input style="width:25%;float:right;margin-top:-35px;" placeholder="请输入标题查询" v-model="searchStates"
                      @keyup.enter.native="handleSearchByTitles">
                <i class="el-icon-search el-input__icon pointer " slot="suffix" @click="handleSearchByTitles"></i>
            </el-input>
        </div>
        <el-table :data="tableData" @selection-change="getSelectionChange"
                  style="width: 100%; margin-top:10px;">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="标题">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.title }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="标签" width="120">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <el-tag size="medium">{{ scope.row.tag }}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="作者" width="120">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <span style="margin-left: 10px">{{scope.row.author }}</span>
                    </div>

                </template>
            </el-table-column>
            <el-table-column label="评论数" width="80">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.commentNum === null? 0:scope.row.commentNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="点赞数" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.praiseNum === null? 0:scope.row.praiseNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收藏数" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.praiseNum === null? 0:scope.row.praiseNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="分享" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.forwardNum === null? 0:scope.row.forwardNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="发布时间" width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ formatStrTime(scope.row.releaseTime,16) }}</span>
                </template>
            </el-table-column>
        </el-table>
        <el-button style="margin-left:40%;margin-top: 1em">取消</el-button>
        <el-button type="primary" @click="addColumn">创建专栏</el-button>
        <div class="paginaion" style="display: inline-block; margin-left:10%;margin-top: 1em">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="tableData.count">
            </el-pagination>
        </div>
        <!-- <div>
          {{multipleSelectionAll.length}}
        </div> -->
        <commonlist :commons="commonData"></commonlist>
    </div>
</template>

<script>
    import bus from "../../common/bus";
    import commonlist from "../../common/userCommon";
    import uploadImage from "../../common/uploadImage";
    // import userPhoto from '@/assets/nofind.png'

    export default {
        data: function () {
            return {
                columnName: "",
                multipleSelectionAll: [],
                getSelection: [],
                authorName: "",
                authorId: "",
                imageUrl: "",
                columnId: "",
                changeAuthor: false,
                activeName: "全部",
                changeUseList: [],
                addPaperType_bool: false,
                // loading: true,
                tableData: [],
                tagData: [],
                tagSwitchData: [
                    {
                        id: "1",
                        name: "文章"
                    },
                    {
                        id: "2",
                        name: "视频"
                    }
                ],
                contentType: "",
                contentTypes: [],
                currentPage: 1,
                currentPages: 1,
                total: 0,
                totals: 0,
                size: 0,
                sizes: 0,
                currentTagId: "",
                searchState: "",
                searchStates: "",
                paperStatus: "未上线",
                paperId: "",
                tagId: " ",
                showCommon: false,
                commonData: "",
                addPaperData: {
                    name: ""
                },
                addtagname: {
                    name: ""
                },
                addtag: false,

                editPaperData: {
                    name: ""
                },
                paperTypeData: [],
                editPaperStr: "新  增",
                oprationType: "add",
                tableHeight: 600,
                is_edit: false,
                uppaper: false,
                offTagList: {
                    ids: ""
                },
                offLineData: {
                    ids: "",
                    status: "",
                    form: {
                        desc: "",
                        type: []
                    }
                }
            };
        },
        components: {
            commonlist,
            uploadImage
        },
        watch: {},
        created() {
            this.Auth_token = localStorage.getItem("Auth_token");
            // this.getPaperList(0, 1, 10);
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653;
            this.contentTypes = this.$getDicByName("CONTENT_TYPE");
        },
        mounted() {
            bus.$on("reloadPaper", res => {
                if (res) {
                    this.getPaperList(
                        this.row,
                        this.currentTagId,
                        this.currentPage,
                        this.size
                    );
                    this.loadUseList(this.currentTagId, this.currentPages, this.sizes);
                }
            });
        },
        methods: {
            // 创建专栏
            addColumn() {
                // //console.log();
                if (this.imageUrl == "" || this.columnName == "" || this.authorId == "") {
                    this.$message.error("请输入专栏名或封面图");
                } else {
                    this.$http({
                        url: this.$api + "/api/column/info/add",
                        method: "post",
                        data: this.$qs.stringify({
                            picture: this.imageUrl,
                            name: this.columnName,
                            authorId: this.authorId
                        })
                    }).then(response => {
                        this.columnId = response.data.data;
                        if (response.data.errno == 0) {
                            // //console.log(this.getSelection);

                            let contentType = this.getSelection[0].type;
                            let contentId = [];
                            for (let index in this.getSelection) {
                                contentId.push(this.getSelection[index].id);
                            }
                            let contentsId = contentId.join(",");
                            // //console.log(contentsId);

                            if (contentType == 1) {
                                this.$http({
                                    url: this.$api + "/api/column/article/add",
                                    method: "post",
                                    data: this.$qs.stringify({
                                        columnId: this.columnId,
                                        articleIds: contentsId
                                    })
                                }).then(res => {
                                    // //console.log(res);
                                    this.$message.success(response.data.msg);
                                });
                            } else {
                                this.$http({
                                    url: this.$api + "/api/column/video/add",
                                    method: "post",
                                    data: this.$qs.stringify({
                                        columnId: this.columnId,
                                        videoIds: contentsId
                                    })
                                }).then(res => {
                                    // //console.log(res);
                                    this.$message.success(response.data.msg);
                                });
                            }
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    });
                }
            },
            //   上传封面
            handleAvatarSuccess(res) {
                if (res.ret === 0) {
                    this.imageUrl = res.data;
                }
            },
            beforeAvatarUpload(file) {
                const isLt2M = file.size / 1024 / 1024 < 2;

                if (!isLt2M) {
                    this.$message.error("上传头像图片大小不能超过 2MB!");
                }
                return isLt2M;
            },
            // 监听表格选择
            getSelectionChange(val) {
                this.getSelection = val;
            },
            // 关联用户类型
            loadUseList(pages, lengths) {
                if ((pages === undefined) | (pages === null)) {
                    pages = 1;
                } else if ((lengths === undefined) | (lengths === null)) {
                    lengths = this.sizes;
                }
                this.$http({
                    url: this.$api + "/api/column/info/list/authors",
                    method: "post",
                    data: this.$qs.stringify({
                        query: this.searchState,
                        page: pages,
                        limit: lengths
                    })
                }).then(response => {
                    this.changeUseList = response.data.data.records;
                    this.currentPages = response.data.data.current;
                    this.totals = response.data.data.total;
                    this.sizes = response.data.data.size;
                    // //console.log(this.sizes);
                });
            },
            //选择关联作者弹出弹窗
            changeauthor() {
                this.loadUseList(1, 10);
                this.changeAuthor = true;
            },
            // 保存按钮
            submitTag() {
                let tagList = [];
                for (let param in this.tagData) {
                    if (this.tagData[param].hasTag == 1) {
                        tagList.push(this.tagData[param].id);
                    }
                }
                let tags = tagList.join(",");
                // //console.log(tags);
                // //console.log(this.offTagList.ids);

                this.$http({
                    url: this.$api + "/api/tags/article/add",
                    method: "post",
                    data: this.$qs.stringify({
                        articleId: this.offTagList.ids,
                        tags: tags
                    })
                })
                    .then(res => {
                        // //console.log(res);
                        this.addtag = false;
                        this.getPaperList();
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 切换tag状态
            // @item 对象数据
            changeTag(item) {
                for (let param in this.tagData) {
                    if (item.id == this.tagData[param].id) {
                        this.tagData[param].hasTag == 0
                            ? (this.tagData[param].hasTag = 1)
                            : (this.tagData[param].hasTag = 0);
                        break;
                    }
                }
            },

            //按标题搜索列表
            handleContentType() {
                this.getPaperList();
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.loadUseList();
            },
            handleSearchByTitles() {
                this.getPaperList();
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.currentPage, val);
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size);
            },

            handleSizeChanges(val) {
                this.loadUseList(this.currentPage, val);
            },
            handleCurrentChanges(val) {
                this.loadUseList(val, this.sizes);
            },
            getPaperData(row) {
                this.authorId = row.authorId;
                this.authorName = row.authorName;
                this.getPaperList();
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: "已经很努力加载啦~",
                    spinner: "el-icon-loading",
                    background: "rgba(183, 183, 183, 0.57)"
                });
                if ((page === undefined) | (page === null)) {
                    page = 1;
                } else if ((length === undefined) | (length === null)) {
                    length = this.size;
                }

                this.$http({
                    url: this.$api + "/api/column/info/list/author/article",
                    method: "post",
                    data: this.$qs.stringify({
                        authorId: this.authorId,
                        contentType: type,
                        query: this.searchStates,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        for (var item in response.data.data.records) {
                            if (response.data.data.records[item].status === 1) {
                                response.data.data.records[item].paperStatus = "可上线";
                                // response.data.data.records[item].color = '#e3e3e3'
                            } else if (response.data.data.records[item].status === 2) {
                                response.data.data.records[item].paperStatus = "已上线";
                                // response.data.data.records[item].color = '#49293'
                            } else if (response.data.data.records[item].status === 3) {
                                response.data.data.records[item].paperStatus = "已下线";
                                // response.data.data.records[item].color = '#ff0000'
                            } else {
                                response.data.data.records[item].paperStatus = "未知状态";
                            }
                        }
                        // 页面详细信息
                        this.tableData = response.data.data.records;
                        this.currentPage = response.data.data.current;
                        this.total = response.data.data.total;
                        this.size = response.data.data.size;
                        this.changeAuthor = false;
                        // this.$refs.multipleTable.clearSelection();
                    } else {
                        this.$message.error(response.data.msg);
                    }
                    loading.close();
                });
            },
            switchTag(tab) {
                // //console.log(tab);

                for (var i in this.tagSwitchData) {
                    if (tab.name == this.tagSwitchData[i].id) {
                        this.searchStates = "";
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10);
                        this.currentTagId = this.tagSwitchData[i].id;
                    }
                }
            }
        }
    };
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .top {
        display: flex;
        justify-content: space-between;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style>
    .avatar-uploader {
        display: inline-block;
    }

    .avatar-uploader .el-upload {
        display: inline-block;
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 78px;
        height: 78px;
    }

    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }

    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 78px;
        height: 78px;
        line-height: 78px;
        text-align: center;
    }

    .avatar {
        width: 78px;
        height: 78px;
        display: block;
    }

    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }
</style>
