<template>
    <div>
        <div class="crumbs" style="width: 100%;">
            <label><span>{{ $route.meta.title }}</span>（共{{ total }}篇）</label>
            <!--<el-breadcrumb separator="/" style="width: 100%">-->
            <!--<el-breadcrumb-item><i class="el-icon-date"></i>视频管理</el-breadcrumb-item>-->
            <!--<el-breadcrumb-item>视频列表</el-breadcrumb-item>-->
            <!--</el-breadcrumb>-->
            <!--<el-button type='success' @click="$router.push('/newsvideo')">添加视频</el-button>-->
            <!--<el-button type='default' @click="editPaperType_btn">编辑视频分类</el-button>-->
        </div>
        <div class="button-tool">
            <!--<div>-->
                <!--<el-button type='primary' @click="$router.push('/newsvideo')">添加视频</el-button>-->
                <!--<el-button type='success' @click="editPaperType_btn">编辑视频分类</el-button>-->
            <!--</div>-->
            <div class="search-title">
                <el-input placeholder="请输入标题查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>

        <div class="tagSwitch">
            <el-tabs v-model="activeName" @tab-click="switchTag">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table
            :data="tableData.data"
            v-loading="!tableData.data"
            style="width: 100%">

            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item>
                            <el-table :data="scope.row.goodList" style="width: 800px"
                                      border
                                      show-summary
                                      :summary-method="getSummaries"
                                      sum-text="总计需支付价格">
                                <el-table-column label="商品名称">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <img :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                            <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结算单价">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结算总价" sortable>
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column
                label="订单号"
                width="250"
            >
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <!--<img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">-->
                        <span style="margin-left: 10px">{{ scope.row.order_sn }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="客户"
                width="120">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <el-tag size="medium">{{ scope.row.consignee }}</el-tag>
                    </div>

                </template>
            </el-table-column>
            <el-table-column
                label="物流信息"
                width="100">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span :style="scope.row.LogisticCode ? '' :'color: #ff0000;'">{{scope.row.LogisticCode ? "已发货" : "未发货"}}</span>
                </template>
            </el-table-column>
            <el-table-column label="下单时间" width="80" prop="add_time"></el-table-column>
            <el-table-column label="物流地址" width="200" prop="address"></el-table-column>
            <el-table-column label="订单金额" width="100">
                <template slot-scope="scope">
                    <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.order_price }}</span>
                </template>
            </el-table-column>

            <el-table-column label="买家备注" width="200" >
                <template slot-scope="scope">
                    <span style="color: #ff0000;font-size: 15px;">{{ scope.row.remarks }}</span>

                </template>
            </el-table-column>
            <el-table-column label="付款状态" width="100" prop="">
                <template slot-scope="scope">
                    <span style="margin-left: 10px;color:#ff0000;" v-if="scope.row.order_status_text == '未付款'">{{ scope.row.order_status_text }}</span>
                    <span style="margin-left: 10px;color:#409EFF;" v-if="scope.row.order_status_text == '已付款'">{{ scope.row.order_status_text }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-around;height:35px;
     align-items: flex-end;">
                        <el-button type="primary" @click="$router.push('/orderDetail?id=' + scope.row.id)">详情</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>

        <el-dialog
            title="修改视频类型"
            :visible.sync="addPaperType_bool"
            width="30%">
            <el-table :data="tagSwitchData" style="width: 100%">
                <el-table-column label="类型名称">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <span style="margin-left: 10px">{{ scope.row.name }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80px">
                    <template slot-scope="scope">
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                           @click="editPaperType_btn_inside(scope.row)"></i>
                        <i title="删除" class="el-icon-delete ft25 red pointer" @click="deletePaperType(scope.row)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <el-form v-if="is_edit">
                <el-form-item style="display: flex;justify-content: space-between;margin-top: 30px;width: 100%;">
                    <el-input v-model="editPaperData.name" placeholder="编辑视频类型" require style="width:60%"></el-input>
                    <el-button type="primary" @click="submit_edit_paper_type()" style="width: 30%">保存编辑</el-button>
                </el-form-item>
            </el-form>

            <el-form>
                <el-form-item style="display: flex;justify-content: space-between;margin-top: 30px;width: 100%;">
                    <el-input v-model="addPaperData.name" placeholder="新增视频类型" require style="width:60%"></el-input>
                    <el-button type="primary" @click="submit_add_paper_type()" style="width: 30%">新 增</el-button>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
            <el-button @click="addPaperType_bool = false">完  成</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'
    import {Loading} from 'element-ui'

    export default {
        data: function () {
            return {
                activeName: '全部',
                addPaperType_bool: false,
                tableData: [],
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                paperStatus: '未上线',
                paperId: '',
                searchState: '',
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                is_edit: false
            }
        },
        components: {
            commonlist
        },
        watch: {},
        created() {
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
            this.getPaperList(0, 1, 10)
            // this.$http({
            //     url: this.$api + '/chigua/admin/video/category/list',
            //     method: 'post'
            // }).then(response => {
            //     if (response.data.data != null) {
            //         this.tagSwitchData = response.data.data
            //         // 添加“全部”分类按钮
            //         let addAll = {}
            //         addAll.id = 0
            //         addAll.name = '全部'
            //         addAll.isDeleted = false
            //         this.tagSwitchData.unshift(addAll)
            //     }
            // })
        },
        mounted() {
            // 更新列表数据
            bus.$on('reloadVideo', reload => {
                if (reload) {
                    this.getPaperList(this.currentTagId, this.currentPage, this.size)
                }
            })
        },
        methods: {
            // 合计
            getSummaries(param) {
                //console.log(param)
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计（元）';
                        return;
                    } else if (index === 1) {
                        sums[index] = 0;
                        for (let item in data) {
                            sums[index] += data[item].number
                        }
                        return;
                    } else if (index === 2) {
                        sums[index] = '-';
                        return;
                    } else if (index === 3) {
                        sums[index] = 0;
                        for (let item in data) {
                            sums[index] += data[item].retail_price * data[item].number
                        }
                        return;
                    }
                });

                return sums;
            },
            // 删除视频类型
            deletePaperType(item) {
                this.$http({
                    url: this.$api + '/chigua/admin/video/category/delete',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: item.id
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.loadPaperType()
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 新增视频类型
            submit_add_paper_type() {
                let url = '/chigua/admin/video/category/add'
                this.$http({
                    url: this.$api + url,
                    method: 'post',
                    data: this.$qs.stringify({
                        id: this.addPaperData.id,
                        name: this.addPaperData.name,
                        sort: this.addPaperData.sort ? this.addPaperData.sort : 99
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.addPaperData.name = ''
                        this.loadPaperType()
                        this.oprationType = 'add'
                        this.editPaperStr = '新  增'
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 新增视频类型
            submit_edit_paper_type() {
                let url = '/chigua/admin/video/category/update'
                this.$http({
                    url: this.$api + url,
                    method: 'post',
                    data: this.$qs.stringify({
                        id: this.editPaperData.id,
                        name: this.editPaperData.name,
                        sort: this.editPaperData.sort ? this.editPaperData.sort : 99
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.editPaperData.name = ''
                        this.loadPaperType()
                        this.oprationType = 'add'
                        this.editPaperStr = '新  增'
                        this.is_edit = false
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 编辑视频类型
            editPaperType_btn() {
                this.addPaperType_bool = true
            },
            editPaperType_btn_inside(item) {
                for (var i in item) {
                    this.editPaperData[i] = item[i]
                }
                this.is_edit = true
                this.editPaperStr = '保存编辑'
                this.oprationType = 'edit'
            },
            // 加载视频类型
            loadPaperType() {
                this.$http({
                    url: this.$api + '/chigua/admin/video/category/list',
                    method: 'post'
                }).then(response => {
                    if (response.data.data != null) {
                        this.tagSwitchData = response.data.data
                        // 添加“全部”分类按钮
                        let addAll = {}
                        addAll.id = 0
                        addAll.name = '全部'
                        addAll.isDeleted = false
                        this.tagSwitchData.unshift(addAll)
                    }
                })
            },
            // 加载评论列表
            loadCommonList(row) {
                // var loadinginstace = this.$showLoading()
                var loading = Loading.service({fullscreen: true})
                this.$http({
                    url: this.$api + '/chigua/admin/video/comment/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        articleId: row.id,
                        sort: 0, // 最新评论
                        limit: 10,
                        page: 1
                    })
                }).then(response => {
                    loading.close()
                    if (response.data.errno === 0) {
                        this.commonData = response.data.data
                        this.commonData.showCommon = true
                        this.commonData.showCommonType = 'video'
                        this.commonData.rows = row
                        this.showCommon = true
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    // bus.$emit('loadConments',this.commonData)
                }).catch(response => {
                    //console.log(response)
                })
            },
            // 跳转到编辑页面，巴士负责传递id
            editVideo(row) {
                this.paperId = row.id
                bus.$emit('videoChange', row.id)
                this.$router.push('/videoedit?videoid=' + row.id)
            },
            // 删除视频
            deleteVideo(row) {
                this.$confirm('您正在进行【删除视频】操作，确认？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/video/delete',
                        method: 'post',
                        data: this.$qs.stringify({id: row.id})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('视频已删除！')
                                this.getPaperList(this.currentTagId, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            operateVideo(row) {
                let url = '', tips = "操作成功", alertText = ''
                if (row.status === 1 || row.status === 3) {
                    //  未上线、已经下线的视频上线
                    url = this.$api + '/chigua/admin/video/onLine'
                    tips = "上线成功！"
                    alertText = '上线'
                } else if (row.status === 2) {
                    // 上线的视频下线
                    url = this.$api + '/chigua/admin/video/downLine'
                    tips = '下线成功！'
                    alertText = '下线'
                    // row.color = '#ff0000'
                } else {
                    return
                }
                this.$confirm('您正在进行【' + alertText + '】操作，该操作会直接改变视频发布状态', '警示', {
                    confirmButtonText: alertText,
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: url,
                        method: 'post',
                        data: this.$qs.stringify({id: row.id})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success(tips)
                                this.getPaperList(this.currentTagId, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });

            },
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size)
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/order/index',
                    method: 'post',
                    data: this.$qs.stringify({
                        queryType: type,
                        title: this.searchState,
                        page: page? page:1,
                        size: length? length:10
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data
                        this.currentPage = response.data.data.currentPage
                        this.total = response.data.data.totalPages
                        this.size = response.data.data.pageSize
                        loading.close()
                    }
                })
            },
            switchTag(tab) {
                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {
                        this.searchState = ""
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10)
                        this.currentTagId = this.tagSwitchData[i].id
                    }
                }
            },
        }
    }
</script>
<style>
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
.tagSwitch {
    width: 100%;
    height: auto;
}

</style >
