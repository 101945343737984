var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-dialog',{attrs:{"title":"填写发货信息","visible":_vm.isShowDialog,"width":"90%"},on:{"update:visible":function($event){_vm.isShowDialog=$event},"close":_vm.closeBox}},[_c('div',{staticClass:"maskLayer",on:{"click":_vm.closeBox}}),_c('div',{staticClass:"dialogBox"},[_c('h3',{staticClass:"padding15"},[_vm._v("填写发货信息")]),_c('div',{staticClass:"disflex_around_start"},[_c('div',{staticClass:"expressBox"},[_c('el-form',{ref:"express",attrs:{"model":_vm.express}},[_c('fieldset',{staticClass:"padding15 border_eee"},[_c('legend',{staticClass:"color_cdcdcd"},[_vm._v("物流信息")]),_c('el-form-item',{attrs:{"rules":[
                          { required: true, message: '运单号码不能为空'}
                        ],"prop":"LogisticCode"}},[_c('span',[_vm._v("运单号码: ")]),_c('el-input',{attrs:{"placeholder":"请填写运单号码(快递单号)"},model:{value:(_vm.recogniseLogisticCode),callback:function ($$v) {_vm.recogniseLogisticCode=$$v},expression:"recogniseLogisticCode"}})],1),_c('el-form-item',{attrs:{"rules":[
                          { required: false, message: '物流公司名称不能为空'}
                        ],"prop":"ShipperName"}},[_c('span',[_vm._v("物流公司：")]),_c('el-select',{attrs:{"placeholder":"请选择快递公司"},model:{value:(_vm.express.ShipperCode),callback:function ($$v) {_vm.$set(_vm.express, "ShipperCode", $$v)},expression:"express.ShipperCode"}},_vm._l((_vm.recogniseExpressList),function(item){return _c('el-option',{key:item.ShipperCode,attrs:{"label":item.ShipperName,"value":item.ShipperCode}})}),1)],1),_c('el-form-item',{attrs:{"rules":[
                          { required: true, message: '物流公司名称不能为空'}
                        ],"prop":"ShipperCode"}},[_c('span',[_vm._v("物流公司代号：")]),_c('el-input',{attrs:{"placeholder":"请填写物流公司代号"},model:{value:(_vm.express.ShipperCode),callback:function ($$v) {_vm.$set(_vm.express, "ShipperCode", $$v)},expression:"express.ShipperCode"}})],1),_c('el-form-item',[_c('span',[_vm._v("付款方式：")]),_c('el-select',{attrs:{"placeholder":"请选择付款方式"},model:{value:(_vm.express.PayType),callback:function ($$v) {_vm.$set(_vm.express, "PayType", $$v)},expression:"express.PayType"}},_vm._l((_vm.PayTypeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',[_c('span',[_vm._v("快递类型：")]),_c('el-select',{attrs:{"placeholder":"请选择快递类型"},model:{value:(_vm.express.ExpType),callback:function ($$v) {_vm.$set(_vm.express, "ExpType", $$v)},expression:"express.ExpType"}},_vm._l((_vm.ExpTypeOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',[_c('span',[_vm._v("物品数量：")]),_c('el-input',{attrs:{"placeholder":"物品数量","require":""},model:{value:(_vm.express.Quantity),callback:function ($$v) {_vm.$set(_vm.express, "Quantity", $$v)},expression:"express.Quantity"}})],1)],1)])],1),_c('div',{staticClass:"expressBox"},[_c('el-form',{ref:"senderMsg",attrs:{"model":_vm.express}},[_c('fieldset',{staticClass:"padding15 border_eee"},[_c('legend',{staticClass:"color_cdcdcd"},[_vm._v("发件信息")]),_c('el-form-item',{attrs:{"rules":[
                          { required: true, message: '发件人不能为空'}
                        ],"prop":"Sender.Name"}},[_c('span',[_vm._v("发件人：")]),_c('el-input',{attrs:{"placeholder":"请选择物流公司"},model:{value:(_vm.express.Sender.Name),callback:function ($$v) {_vm.$set(_vm.express.Sender, "Name", $$v)},expression:"express.Sender.Name"}})],1),_c('el-form-item',{attrs:{"rules":[
                          { required: true, message: '发件人手机号不能为空！'}
                        ],"prop":"Sender.Mobile"}},[_c('span',[_vm._v("发件人联系方式：")]),_c('el-input',{attrs:{"placeholder":"手机号码"},model:{value:(_vm.express.Sender.Mobile),callback:function ($$v) {_vm.$set(_vm.express.Sender, "Mobile", $$v)},expression:"express.Sender.Mobile"}}),_c('el-input',{attrs:{"placeholder":"固话","require":""},model:{value:(_vm.express.Sender.Tel),callback:function ($$v) {_vm.$set(_vm.express.Sender, "Tel", $$v)},expression:"express.Sender.Tel"}})],1),_c('el-form-item',{attrs:{"rules":[
                          { required: true, message: '发件省市地址不能为空，省市区间用空格分开'}
                        ],"prop":"Sender.FullRegion"}},[_c('span',[_vm._v("发件地址：")]),_c('el-input',{attrs:{"placeholder":"请选择发件地址"},model:{value:(_vm.express.Sender.FullRegion),callback:function ($$v) {_vm.$set(_vm.express.Sender, "FullRegion", $$v)},expression:"express.Sender.FullRegion"}})],1),_c('el-form-item',{attrs:{"rules":[
                          { required: true, message: '发件人详细地址不能为空'}
                        ],"prop":"Sender.Address"}},[_c('span',[_vm._v("发件地址：")]),_c('el-input',{attrs:{"placeholder":"详细地址"},model:{value:(_vm.express.Sender.Address),callback:function ($$v) {_vm.$set(_vm.express.Sender, "Address", $$v)},expression:"express.Sender.Address"}})],1)],1)])],1),_c('div',{staticClass:"expressBox"},[_c('el-form',{ref:"receiverMsg",attrs:{"model":_vm.express}},[_c('fieldset',{staticClass:"padding15 border_eee"},[_c('legend',{staticClass:"color_cdcdcd"},[_vm._v("收件信息")]),_c('el-form-item',{attrs:{"prop":"Receiver.Name","rules":[
                          { required: true, message: '收件人姓名不能为空！'}
                        ]}},[_c('span',[_vm._v("收件人：")]),_c('el-input',{attrs:{"placeholder":"请填写收件人姓名"},model:{value:(_vm.express.Receiver.Name),callback:function ($$v) {_vm.$set(_vm.express.Receiver, "Name", $$v)},expression:"express.Receiver.Name"}})],1),_c('el-form-item',{attrs:{"prop":"Receiver.Mobile","rules":[
                          { required: true, message: '收件人手机号不能为空'}
                        ]}},[_c('span',[_vm._v("收件人联系方式：")]),_c('el-input',{attrs:{"placeholder":"收件人手机号码"},model:{value:(_vm.express.Receiver.Mobile),callback:function ($$v) {_vm.$set(_vm.express.Receiver, "Mobile", $$v)},expression:"express.Receiver.Mobile"}}),_c('el-input',{attrs:{"placeholder":"收件人固话","require":""},model:{value:(_vm.express.Receiver.Tel),callback:function ($$v) {_vm.$set(_vm.express.Receiver, "Tel", $$v)},expression:"express.Receiver.Tel"}})],1),_c('el-form-item',{attrs:{"prop":"Receiver.FullRegion","rules":[
                          { required: true, message: '收件人省市区不能为空，省市区间用空格分开'}
                        ]}},[_c('span',[_vm._v("收件地址：")]),_c('el-input',{attrs:{"placeholder":"请选择发件地址"},model:{value:(_vm.express.Receiver.FullRegion),callback:function ($$v) {_vm.$set(_vm.express.Receiver, "FullRegion", $$v)},expression:"express.Receiver.FullRegion"}})],1),_c('el-form-item',{attrs:{"prop":"Receiver.Address","rules":[
                          { required: true, message: '收件人详细地址不能为空！'}
                        ]}},[_c('span',[_vm._v("收件地址：")]),_c('el-input',{attrs:{"placeholder":"详细地址"},model:{value:(_vm.express.Receiver.Address),callback:function ($$v) {_vm.$set(_vm.express.Receiver, "Address", $$v)},expression:"express.Receiver.Address"}})],1)],1)])],1),_c('div',{staticClass:"expressBox"},[_c('el-form',{ref:"otherExpress",attrs:{"model":_vm.express}},[_c('fieldset',{staticClass:"padding15 border_eee"},[_c('legend',{staticClass:"color_cdcdcd"},[_vm._v("其他物流信息")]),_c('el-form-item',{attrs:{"prop":"Commodity.GoodsName","rules":[
                          { required: true, message: '快递物件名不能为空！快递要求必须填写物件名！'}
                        ]}},[_c('span',[_vm._v("快递物件名：")]),_c('el-input',{attrs:{"placeholder":"请填写快递物件名"},model:{value:(_vm.express.Commodity.GoodsName),callback:function ($$v) {_vm.$set(_vm.express.Commodity, "GoodsName", $$v)},expression:"express.Commodity.GoodsName"}})],1),_c('el-form-item',[_c('span',[_vm._v("发送信息：")]),_c('el-select',{attrs:{"placeholder":"是否发送信息"},model:{value:(_vm.express.IsSendMessage),callback:function ($$v) {_vm.$set(_vm.express, "IsSendMessage", $$v)},expression:"express.IsSendMessage"}},[_c('el-option',{attrs:{"label":"是","value":1}})],1)],1),_c('el-form-item',[_c('span',[_vm._v("接受通知：")]),_c('el-select',{attrs:{"placeholder":"是否接受通知"},model:{value:(_vm.express.IsNotice),callback:function ($$v) {_vm.$set(_vm.express, "IsNotice", $$v)},expression:"express.IsNotice"}},[_c('el-option',{attrs:{"label":"是","value":1}})],1)],1),_c('el-form-item',[_c('span',[_vm._v("备注：")]),_c('textarea',{staticClass:"remarks",attrs:{"name":"备注","id":"","cols":"30","rows":"10"},domProps:{"value":_vm.express.Remark},on:{"input":_vm.RemarkChange}})])],1)])],1)]),_c('div',{staticClass:"dialog-footer padding15",staticStyle:{"text-align":"right"},attrs:{"slot":"footer"},slot:"footer"},[_c('el-checkbox',{staticStyle:{"margin-left":"20px"},model:{value:(_vm.is_auto_send_sms_notice),callback:function ($$v) {_vm.is_auto_send_sms_notice=$$v},expression:"is_auto_send_sms_notice"}},[_vm._v("自动发送短信通知")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.sendExpress}},[_vm._v("发 货")]),_c('el-button',{attrs:{"type":"default"},on:{"click":_vm.closeBox}},[_vm._v("关 闭")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }