<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>视频管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="edit-title">
                <div style="display: flex;justify-content: space-around;align-items: flex-start;">
                    <div class="imgbox">
                        <upload-image v-model="uploadData.picture"></upload-image>
                    </div>
                    <div class="videobox">
                        <video id="video" class="uploadVideoShowBack" v-if="uploadData.video" :src="uploadData.video"
                               controls="controls" style="width:360px;max-height: 240px !important;"></video>
                        <div>
                            <span>视频不能修改，请重新添加视频</span>
                            <el-radio style="margin-left: 10px" v-model="uploadData.video" :label="uploadData.url">原画</el-radio>
                            <el-radio v-model="uploadData.video" :label="uploadData.mediaUrl">高清</el-radio>
                        </div>
                    </div>
                </div>
            </div>
            <el-form label-width="80px" style="margin-top: 20px;width: 90%">
                <el-form-item label="视频标题">
                    <el-input type="text" placeholder="请输入视频标题" maxlength="40" clearable
                              style="width: 100%;" size="small"
                              v-model="uploadData.title"></el-input>
                </el-form-item>
                <el-form-item label="作者">
                    <el-input placeholder="作者" maxlength='10' clearable style="width: 80%"
                              v-model="uploadData.author"></el-input>
                </el-form-item>
                <el-form-item label="视频分类">
                    <el-select v-model="uploadData.categoryId" placeholder="视频分类" style="width: 20%;">
                        <el-option
                            v-for="item in category"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="视频简介">
                    <el-input
                        type="textarea"
                        :rows="10"
                        placeholder="请输入视频简介"
                        v-model="uploadData.intro" max="250" maxlength="250">
                    </el-input>
                </el-form-item>
            </el-form>


            <el-button class="editor-btn" type="primary" @click="submitPaper">提交修改</el-button>
        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus'
    import uploadImage from '../../common/uploadImage'

    export default {
        data: function () {
            return {
                imgStatus: 'display:none',
                loadingIMG: false,
                loadingVIDEO: false,
                videoStatus: 'display:none',
                autoImgUpload: true,
                autoVideoUpload: true,
                content: '',
                intro: '',
                configs: {},
                category: [],
                sort: '',
                uploadData: {
                    title: '',
                    author: '',
                    intro: '',
                    tag: '',
                    sort: '',
                    picture: '',
                    categoryId: '',
                    video: '',
                    ossId: '',
                    timeLength: '',
                    url: '',
                    mediaUrl: '',
                    fileSize: '',
                    id: ''
                },
                uploadConfig: {
                    title: '上传视频封面',
                    uploadUrl: ''
                },
                Auth_token: '',
                videoId: null,
                paperHeadImg: '',
                paperHeadVideo: '',
                upload_tool_img_status: 'display:block',
                upload_tool_video_status: 'display:block'
            }
        },
        components: {
            // mavonEditor
            uploadImage
        },
        computed: {},
        watch: {
        },
        created() {
            this.Auth_token = localStorage.getItem('auth_token')
            // 获取视频分类
            this.$http({
                url: this.$api + '/chigua/admin/video/category/list',
                method: 'post'
            }).then(response => {
                if (response.data != null) {
                    this.category = response.data.data
                }
            })
        },
        mounted() {
            // 从兄弟组件传过来的巴士消息
            // bus.$on('videoChange', msg => {
            //     this.videoId = this.uploadData.ossId = msg
            //     this.getVideo(this.videoId)
            // })
            // 路由id
            const videoId = this.$route.query.videoid
            // this.videoId = videoId
            // 获取视频
            if (videoId !== 'undefined') {
                this.getVideo(videoId)
            }
        },
        methods: {
            getVideo(videoId) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪正在努力加载...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                var that = this
                if (videoId) {
                    if (videoId !== null && videoId !== '') {
                        this.$http({
                            url: this.$api + '/chigua/admin/video/get',
                            method: 'post',
                            data: this.$qs.stringify({id: videoId})
                        }).then(response => {
                            if (response.data.data === null) {
                                this.$message.error('数据不存在')
                                return
                            }
                            if (response.data.errno === 0) {
                                var resdata = response.data.data
                                if (response.data.data !== null) {
                                    if (response.data.data.picture.indexOf('http') > -1) {
                                        that.paperHeadImg = response.data.data.picture
                                        that.uploadData.video = response.data.data.url
                                        // //console.log(that.uploadData.video)
                                    } else {
                                        that.paperHeadImg = ''
                                    }
                                    // this.uploadData = resdata
                                }
                                // that.uploadData = response.data.data
                                for (var item in response.data.data) {
                                    for (var data in this.uploadData) {
                                        if (item === data) {
                                            // //console.log(item + '=======' + data)
                                            this.uploadData[data] = response.data.data[item]
                                        }
                                    }
                                }
                                if (response.data.data.url) {
                                    that.uploadData.video = response.data.data.url
                                }
                            } else if (response.data.errno === 7001) {
                                this.$message.error(response.data.msg)
                            } else {
                                this.$message.error(response.data.msg)
                            }
                        })
                    }
                }
                loading.close()
            },
            // 提交视频
            submitPaper() {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪正在努力提交...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                //  判断能否编辑
                //  判断能否编辑
                var video = document.getElementById('video')
                this.uploadData.timeLength = video.duration
                if (typeof this.uploadData.timeLength === 'number' && isNaN(this.uploadData.timeLength)) {
                    loading.close()
                    this.$message.error('请等待视频加载完成！')
                    return
                }
                if (this.uploadData.ossId === '' && this.$route.query.videoId === '') {
                    this.$message.error('保存失败，您还没有选择要编辑的视频！')
                    loading.close()
                    return;
                } else if (this.uploadData.title.length < 3) {
                    this.$message.error('视频标题好像写的不太对哦~')
                    loading.close()
                    return;
                } else if (this.uploadData.categoryId === '') {
                    this.$message.error('请选择视频分类')
                    loading.close()
                    return;
                }
                this.$http({
                    url: this.$api + '/chigua/admin/video/update',
                    method: 'post',
                    data: this.$qs.stringify(this.uploadData)
                }).then(response => {
                    if (response.data.errno === 0) {
                        loading.close()
                        this.$message.success('编辑视频成功！');
                        let reload = true
                        bus.$emit('reloadVideo', reload)
                        this.$router.push('/videolist')
                        this.emptyJson(this.uploadData)
                        let boo = false
                        this.hideImgUpload(boo)
                        this.hideVideoUpload(boo)
                    } else {
                        this.$message.error(response.data.msg)
                    }
                })
                loading.close()
            },
        }
    }
</script>
<style scoped>
    .editor-btn {
        margin-top: 20px;
    }

    #title-input:-ms-input-placeholder, #title-input:-moz-placeholder {
        font-size: 30px;
        text-align: left;
    }


</style>
<style>
</style>
