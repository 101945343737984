<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}个商品）</label>

        </div>
        <div class="button-tool">
            <div style="display: flex;justify-content: flex-start !important;">
                <el-radio-group v-model="contentType" style="margin-right: 20px;margin-top: 10px"
                                @change="handleContentType">
                    <el-radio v-for="item in contentTypes" :label="item.value" :name="item.name" :key="item.value">
                        {{item.name}}
                    </el-radio>
                </el-radio-group>
                <el-input placeholder="请输入商品名查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 500px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>
        <div class="tagSwitch disflexct">
            <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 100%">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table
            :data="tableData.data"

            style="width: 100%">
            <el-table-column label="商品id" :width="$store.state.UA !== 'MOBILE'? '100':'60'" prop="id"></el-table-column>
            <el-table-column label="商品名称" :width="$store.state.UA !== 'MOBILE'? '400':'150'">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <a :href="scope.row.list_pic_url" target="_blank"><img :src="scope.row.list_pic_url" style="width: 40px;vertical-align: middle;"></a>
                        <span style="margin-left: 10px">{{ scope.row.name }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="库存"
                :width="$store.state.UA !== 'MOBILE'? '100':'60'">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span style="margin-left: 10px">{{ scope.row.goods_number }} {{scope.row.goods_unit}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="销售量"
                :width="$store.state.UA !== 'MOBILE'? '100':'60'">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span style="margin-left: 10px">{{ scope.row.sell_volume }} {{scope.row.goods_unit}}</span>
                </template>
            </el-table-column>
            <el-table-column label="零售价" :width="$store.state.UA !== 'MOBILE'? '100':'60'" prop="retail_price"></el-table-column>
            <!--设置折扣，可以重新制定零售价-->
            <el-table-column label="新零售价" :width="$store.state.UA !== 'MOBILE'? '100':'60'" >
                <template slot-scope="scope">
                    <div >
                        <el-input size="medium " placeholder="新零售价" v-model="scope.row.retail_price_new" >
                        </el-input>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="折扣设置" width="300" >
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px;align-items: flex-end;">
                        <!-- 编辑 -->
                        <el-input suffix-icon="el-icon-warning" step="0.01" size="medium " placeholder="在[0.1 , 1]之间" v-model="scope.row.discount" class="" type="number" min="0.1" max="1">
                            <el-button slot="append" type="warning" @click="saveDiscount(scope.row)">
                                保存折扣
                            </el-button>
                        </el-input>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>

        <el-dialog
            title="修改商品类型"
            :visible.sync="addPaperType_bool"
            width="30%">
            <el-table :data="tagSwitchData" style="width: 100%">
                <el-table-column label="类型名称">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <span style="margin-left: 10px">{{ scope.row.name }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="80px">
                    <template slot-scope="scope">
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                           @click="editPaperType_btn_inside(scope.row)"></i>
                        <i title="删除" class="el-icon-delete ft25 red pointer" @click="deletePaperType(scope.row)"></i>
                    </template>
                </el-table-column>
            </el-table>
            <el-form v-if="is_edit">
                <el-form-item style="display: flex;justify-content: space-between;margin-top: 30px;width: 100%;">
                    <el-input v-model="editPaperData.name" placeholder="编辑商品类型" require style="width:60%"></el-input>
                    <el-button type="primary" @click="submit_edit_paper_type()" style="width: 30%">保存编辑</el-button>
                </el-form-item>
            </el-form>

            <el-form>
                <el-form-item style="display: flex;justify-content: space-between;margin-top: 30px;width: 100%;">
                    <el-input v-model="addPaperData.name" placeholder="新增商品类型" require style="width:60%"></el-input>
                    <el-button type="primary" @click="submit_add_paper_type()" style="width: 30%">新 增</el-button>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
            <el-button @click="addPaperType_bool = false">完  成</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'
    import clearCacheTool from '../../common/clearCacheTool'

    export default {
        data: function () {
            return {
                activeName: '全部',
                addPaperType_bool: false,
                // loading: true,
                tableData: [],
                tagSwitchData: [],
                contentType: '',
                contentTypes: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                searchState: '',
                paperStatus: '未上线',
                paperId: '',
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                is_edit: false
            }
        },
        components: {
            commonlist,
            clearCacheTool
        },
        watch: {},
        created() {
            this.getPaperList(0, 1, 10)
            // this.loadPaperType()
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
            this.contentTypes = this.$getDicByName('CONTENT_TYPE');
        },
        mounted() {

            bus.$on('reloadPaper', res => {
                if (res) {
                    //console.log(res);

                    this.getPaperList(this.currentTagId, this.currentPage, this.size)
                }
            })
        },
        methods: {
            // 设置折扣
            saveDiscount (goods) {
                if (!goods.discount) {
                    this.$message.error('请先设置折扣后保存！')
                    return
                }
                if (goods.discount < 0.5 || goods.discount > 1) {
                    this.$message.error('折扣信息设置不合法，请修改！')
                    return
                }
                this.$confirm('你正在设置该商品折扣，是否继续？更新商品：【' + goods.name + '】，该操作设置折扣为【' + goods.discount * 10 + '折】，设置价格为：【 ' + goods.retail_price_new + '】。请确认！', '警示', {
                    confirmButtonText: '确认设置折扣',
                    cancelButtonText: '取消操作',
                    type: 'warning'
                }).then(() => {
                    // 提交修改
                    this.$http({
                        url: this.$api + '/admin/goods/addDiscount',
                        method: 'post',
                        data: this.$qs.stringify({
                            goods_id: goods.id,
                            discount: goods.discount,
                            retail_price: goods.retail_price_new
                        })
                    }).then(res => {
                        if (res.data.errno == 0) {
                            this.$message.success('设置成功！');
                            this.getPaperList('',this.tableData.currentPage, this.tableData.pageSize)
                        } else {
                            this.$message.error(res.data.errmsg)
                        }
                    }).catch(res => {
                        this.$message.error('设置失败，请联系技术')
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消折扣设置'
                    });
                });
            },
            // 设置为热门
            setToHot (param, ishot) {
                this.$http({
                    url: this.$api + '/admin/goods/sethot',
                    method: 'post',
                    data: this.$qs.stringify({
                        goodsId: param.id,
                        isHot: ishot
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success('设置成功！');
                        this.getPaperList(this.currentTagId, this.currentPage, this.size)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 设置最新
            setToNew (param, isnew) {

                this.$http({
                    url: this.$api + '/admin/goods/setnew',
                    method: 'post',
                    data: this.$qs.stringify({
                        goodsId: param.id,
                        isNew: isnew
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success('设置成功！');
                        this.getPaperList(this.currentTagId, this.currentPage, this.size)
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 删除商品类型
            deletePaperType(item) {
                this.$http({
                    url: this.$api + '/chigua/admin/article/category/delete',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: item.id
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.loadPaperType()
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 新增商品类型
            submit_add_paper_type() {
                let url = '/chigua/admin/article/category/add'
                this.$http({
                    url: this.$api + url,
                    method: 'post',
                    data: this.$qs.stringify({
                        id: this.addPaperData.id,
                        name: this.addPaperData.name,
                        sort: this.addPaperData.sort ? this.addPaperData.sort : 99
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.addPaperData.name = ''
                        this.loadPaperType()
                        this.oprationType = 'add'
                        this.editPaperStr = '新  增'
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 新增商品类型
            submit_edit_paper_type() {
                let url = '/chigua/admin/article/category/update'
                this.$http({
                    url: this.$api + url,
                    method: 'post',
                    data: this.$qs.stringify({
                        id: this.editPaperData.id,
                        name: this.editPaperData.name,
                        sort: this.editPaperData.sort ? this.editPaperData.sort : 99
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success(res.data.errmsg)
                        this.editPaperData.name = ''
                        this.loadPaperType()
                        this.oprationType = 'add'
                        this.editPaperStr = '新  增'
                        this.is_edit = false
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 编辑商品类型
            editPaperType_btn() {
                this.addPaperType_bool = true
            },
            editPaperType_btn_inside(item) {
                for (var i in item) {
                    this.editPaperData[i] = item[i]
                }
                this.is_edit = true
                this.editPaperStr = '保存编辑'
                this.oprationType = 'edit'
            },
            // 跳转到编辑页面，巴士负责传递id
            editPaper(index, row) {
                // var loading = this.$loading({fullscreen: true})
                this.paperId = row.id
                // bus.$emit('paperChange', this.paperId)
                //  把商品类型带上
                this.$router.push('/goodsedit?id=' + row.id)
                // loading.close()
            },
            // 删除商品
            deletePaper(index, row) {
                this.$confirm('您正在进行【删除商品】操作，确认？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/article/delete',
                        method: 'post',
                        data: this.$qs.stringify({id: row.id})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('商品已删除！')
                                this.getPaperList(this.currentTagId, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            operatePaper(index, row) {

                this.$confirm('您正在进行【下线商品】操作，该操作会直接改变商品发布状态', '警示', {
                    confirmButtonText: '下线',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/goods/switch',
                        method: 'post',
                        data: this.$qs.stringify({goodsId: row.id, isOnLine: 0})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno == 0) {
                                this.$message.success('下线成功！')
                                this.getPaperList(this.currentTagId, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });

            },

            //按标题搜索列表
            handleContentType() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size)
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size)
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/goods/index',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 20,
                        name: this.searchState
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        for (var item in response.data.data.data) {
                            response.data.data.data[item].retail_price_new = response.data.data.data[item].retail_price
                        }
                        this.tableData = response.data.data;
                        this.currentPage = response.data.data.currentPage;
                        this.total = response.data.data.totalPages;
                        this.size = response.data.data.pageSize;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            },
            switchTag(tab) {

                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {

                        this.searchState = ''
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10)
                        this.currentTagId = this.tagSwitchData[i].id
                    }
                }
            },
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
