<template>
  <div style="width: 100%;height: 100%">
      <iframe style="width: 30%;height: 100%" src="https://file.chezidao.com/#/goddess" frameborder="0"></iframe>
  </div>

</template>

<script>
    export default {
        name: "iframe"
    }
</script>

<style scoped>

</style>
