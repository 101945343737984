<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ total }}篇）</label>
            <!--<el-breadcrumb separator="/">-->
            <!--<el-breadcrumb-item><i class="el-icon-date"></i>课程管理</el-breadcrumb-item>-->
            <!--<el-breadcrumb-item>草稿箱</el-breadcrumb-item>-->
            <!--</el-breadcrumb>-->
        </div>

        <div class="tagSwitch disflexct">
            <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 70%">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>

            <el-input class="search-title" placeholder="请输入标题查询" v-model="searchState"
                      @keyup.enter.native="handleIconClick">
                <i class="el-icon-search el-input__icon pointer" slot="prefix" @click="handleIconClick"></i>
            </el-input>

        </div>
        <el-table
            :data="tableData"
            style="width: 100%">
            <el-table-column
                label="标题"
            >
                <template slot-scope="scope">
                    <!-- <el-popover trigger="hover" placement="top"> -->
                    <!-- <p>姓名: {{ scope.row.name }}</p> -->
                    <!-- <p>住址: {{ scope.row.address }}</p> -->
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.title }}</span>
                    </div>
                    <!-- </el-popover> -->
                </template>
            </el-table-column>
            <el-table-column label="作者" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <el-tag size="medium">{{ scope.row.author }}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :label="timeDiff" width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ formatStrTime(scope.row.releaseTime?scope.row.releaseTime:scope.row.createTime,16) }}</span>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px; align-items: flex-end;">
                        <!-- 编辑 -->
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer" @click="editCourse(scope.row)"></i>
                        <!-- 评论 -->
                        <!-- 无评论 -->
                        <div v-if="showCommentButtonText">
                            <div class="pointer" v-if="scope.row.commentNum <= 0" @click="$message.info('暂无评论')"
                                 style="height: 24px">
                                <img src="https://file.chezidao.com/root/static/icon/comments.png" style="width: 20px">
                            </div>
                            <!-- 有评论 -->
                            <div v-if="scope.row.commentNum > 0" @click="loadCommonList(scope.row)"
                                 style="height: 24px">
                                <el-badge :value="scope.row.commentNum" :max="99" class="item pointer">
                                    <img src="https://file.chezidao.com/root/static/icon/comments.png" style="width: 20px">
                                </el-badge>
                            </div>
                        </div>
                        <!-- 上下线 -->
                        <span class="ft12 operateEvent pointer" title="上线" @click="operatePaper(scope.row)" style="height: 24px"
                              :name="scope.row.status">
                            <!--{{ scope.row.paperStatus }}-->
                            <img src="https://file.chezidao.com/root/static/icon/online.png"
                                 style="width: 20px">
                        </span>
                        <!-- 删除 -->
                        <i title="删除" class="el-icon-delete ft25 red pointer" @click="deletePaper(scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'

    export default {
        data: function () {
            return {
                searchState: '',
                searchData: [],
                activeName: '未上线',
                tableData: [],
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: 1,
                paperStatus: '未上线',
                paperId: '',
                showCommon: false,
                commonData: '',
                status: '',
                timeDiff: '创建时间',
                showCommentButtonText: false
            }
        },
        watch: {
            currentTagId: function () {
                if (this.currentTagId === 1) {
                    this.showCommentButtonText = false
                } else if (this.currentTagId === 3) {
                    this.showCommentButtonText = true
                }
            }
        },
        components: {
            commonlist
        },
        created() {
            this.getPaperList(1, '', 1, 10)
            this.tagSwitchData = [{id: 1, name: "未上线"}, {id: 3, name: "已下线"}]
        },
        methods: {
            // 草稿搜索
            loadConmentsSearch(id, page, length) {
                this.getPaperList(this.currentTagId, this.searchState, 1, this.size)
            },
            //  搜索按钮点击事件
            handleIconClick(ev) {
                this.loadConmentsSearch(this.currentTagId, 1, 20)
            },
            // 加载评论列表
            loadCommonList(row) {
                let url = this.$api + '/chigua/admin/course/comment/page'
                this.$http({
                    url: url,
                    method: 'post',
                    data: this.$qs.stringify({
                        foreignId: row.id,
                        sort: 0, // 最新评论
                        limit: 10,
                        page: 1
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.commonData = response.data.data
                        this.commonData.showCommon = true
                        this.commonData.showCommonType = 'paper'
                        this.commonData.rows = row
                        this.showCommon = true
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    // bus.$emit('loadConments',this.commonData)
                }).catch(response => {
                    //console.log(response)
                })
            },
            // 跳转到编辑页面，巴士负责传递id
            editCourse(row) {
                this.paperId = row.id
                bus.$emit('paperChange', this.paperId)
                this.$router.push('/editcourse?courseId=' + row.id)
            },
            // 删除课程
            deletePaper(row) {
                this.$confirm('您正在进行【删除课程】操作，该操作不可恢复，确认？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/course/delete',
                        method: 'post',
                        data: this.$qs.stringify({id: row.id})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('课程已删除！')
                                this.getPaperList(this.currentTagId, this.searchState, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            operatePaper(row) {
                let url = '', tips = "操作成功", alertText = ''
                if (row.status === 1 || row.status === 3) {
                    //  未上线、已经下线的课程上线
                    url = this.$api + '/chigua/admin/course/onLine'
                    tips = "上线成功！"
                    alertText = '上线'
                } else if (row.status === 2) {
                    // 上线的课程下线
                    url = this.$api + '/chigua/admin/course/downLine'
                    tips = '下线成功！'
                    alertText = '下线'
                    // row.color = '#ff0000'
                } else {
                    return
                }
                this.$confirm('您正在进行【' + alertText + '】操作，该操作会直接改变课程发布状态', '警示', {
                    confirmButtonText: alertText,
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: url,
                        method: 'post',
                        data: this.$qs.stringify({id: row.id})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success(tips)
                                this.getPaperList(this.currentTagId, this.searchState, this.currentPage, this.size)
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });

            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.searchState, this.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, this.searchState, val, this.size)
            },
            getPaperList(type, title, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                loading.close()
                this.$http({
                    url: this.$api + '/chigua/admin/course/drafts',
                    method: 'post',
                    data: this.$qs.stringify({
                        status: type,
                        title: title,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        for (var item in response.data.data.records) {
                            if (response.data.data.records[item].status === 1) {
                                response.data.data.records[item].paperStatus = '可上线'
                                // response.data.data.records[item].color = '#e3e3e3'
                            } else if (response.data.data.records[item].status === 2) {
                                response.data.data.records[item].paperStatus = '已上线'
                                // response.data.data.records[item].color = '#49293'
                            } else if (response.data.data.records[item].status === 3) {
                                response.data.data.records[item].paperStatus = '已下线'
                                // response.data.data.records[item].color = '#ff0000'

                            } else {
                                response.data.data.records[item].paperStatus = '未知状态'
                            }
                        }
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size
                        loading.close()
                    }
                })
            },
            switchTag(tab) {
                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {
                        this.getPaperList(this.tagSwitchData[i].id, this.searchState, 1, 10)
                        //console.log(this.currentTagId)
                        this.currentTagId = this.tagSwitchData[i].id
                        //console.log(this.currentTagId)
                        if (this.currentTagId === 1) {
                            this.timeDiff = '创建时间'
                        } else {
                            this.timeDiff = '发布时间'
                        }
                    }
                }
            },
        },
        mounted() {
            // //console.log(this.currentTagId)
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .disflexct {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .my-autocomplete {

    li {
        line-height: normal;
        padding: 7px;

    .name {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .addr {
        font-size: 12px;
        color: #b4b4b4;
    }

    .highlighted .addr {
        color: #ddd;
    }

    }
    }
</style>
