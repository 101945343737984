<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>商品管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">

            <div style="margin-bottom: 20px">
                <el-button type="primary" @click="submitPaper">保存商品</el-button>
                <el-button type="primary" @click="getTemperData">获取格式化数据</el-button>
            </div>

            <el-form style="width: 90%" label-width="120px" :rules="pushRules" :model="pushData" ref="pushData">
                <el-form-item label="商品推广标题">
                    <el-input v-model="pushData.name"></el-input>
                </el-form-item>
                <div class="display-flex" style="margin: 0 0 20px 60px">
                    <span >商品主图：</span>
                    <upload-image  title="商品显示主图【比填】" v-model="pushData.primary_pic_url" style="margin-right: 50px">
                        <div style="text-align: center;">4：3</div>
                    </upload-image>

                    <span>商品列表图【详情轮播图】：</span>
                    <el-upload
                        class="upload-demo"
                        v-loading="uploadLoading"
                        :action="$api + UPLOAD_HEADER_IMG"
                        :headers="{ Auth_token }"
                        :file-list="fileList_arr"
                        :on-remove="handleRemove"
                        list-type="picture-card"
                        :before-upload="beforeUpload"
                        :on-success="handleImageSuccess"
                        :on-error="handleError">
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                    </el-upload>
                </div>

                <!--<el-form-item label="所属品牌" prop="brand_id">-->
                <!--<el-select v-model="pushData.brand_id" placeholder="请选择品牌">-->
                <!--<el-option v-for="item in brandOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>-->
                <!--</el-select>-->
                <!--</el-form-item>-->
                <el-form-item label="商品简介">
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入商品简介"
                        v-model="pushData.goods_brief">
                    </el-input>
                </el-form-item>

                <!--<el-form-item label="商品属性" name="attribute" prop="attribute">-->
                <!--<div v-for="(item, curIndex) in pushData.attribute" :key="item.hashCode">-->
                <!--<el-input style="width:200px;margin-right:5px;margin-bottom:5px;" placeholder="请输入属性名称，如：颜色"-->
                <!--v-model="item.name"></el-input>-->
                <!--<el-input style="width:200px" placeholder="请输入属性值，如：白色" v-model="item.value"></el-input>-->
                <!--<el-button size="small" type="danger" @click="handleRemoveAttribute(curIndex)">删除</el-button>-->
                <!--</div>-->

                <!--<el-button size="small" type="primary" @click="handleAddAttribute()">新增</el-button>-->
                <!--</el-form-item>-->


                <el-form-item label="商品分类">
                    <el-select v-model="currentCategory">
                        <el-option v-for="item in category" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                    <el-select v-model="pushData.category_id" v-if="currentCategoryArr" @change="chooseThisCategory">
                        <el-option v-for="item in currentCategoryArr" :key="item.id" :label="item.name"
                                   :value="item.id"></el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="商品SKU">
                    <div v-for="(goods, $index) in goodsList" :key="$index" class="width40" style="float: left; margin-right: 15px;">
                        <el-card   :body-style="{ padding: '15px' }" >
                            <div class="disflex_around_center">
                                <div class="width40">
                                    <sku-img-upload  title="sku主图" list-type="picture-card" v-model="goods.header_img" style="margin-right: 50px" >
                                        <div style="text-align: center;">4：3</div>
                                    </sku-img-upload>
                                    <div class="disflex_flex_start align-items padding15">
                                        <p>首页展示：</p>
                                        <el-switch
                                            v-model="goods.IS_PAGE_SHOW"
                                            active-color="#13ce66"
                                            inactive-color="#ff4949"
                                            @change="changePageShow($event, goods)">
                                        </el-switch>
                                    </div>
                                </div>
                                <div class="width60">
                                    <el-input v-model="goods.goods_number"  placeholder="请输入商品库存" class="mgb20">
                                        <template slot="prepend"><i class="el-icon-s-order"></i></template>
                                    </el-input>
                                    <el-input v-model="goods.selling_price"  placeholder="请设置销售价格" class="mgb20">
                                        <template slot="prepend">￥</template>
                                    </el-input>

                                    <el-input v-model="goods.discount"  placeholder="请设置当前sku折扣价" class="mgb20">
                                        <template slot="prepend">折扣</template>
                                    </el-input>

                                    <div v-for="(attribute, attrIndex) in goods.attributeList" :key="attrIndex" class="disflex_flex_start mgb20">
                                        <el-cascader
                                            :options="attributeData"
                                            v-model="attribute.attribute_value"
                                            @change="attributeDataChange($event, goods)" @visible-change="attrVisibleChange"></el-cascader>
                                        <i class="el-icon-circle-plus" style="font-size: 30px;color: #09bbe7;cursor: pointer;" @click="up_attrIndexLength(goods, attribute)" v-if="attrIndex === goods.attributeList.length - 1"></i>
                                        <i class="el-icon-remove" style="font-size: 30px;color: #eb3021;cursor: pointer" @click="cut_attrIndexLength(goods, attribute)" v-if="attrIndex !== goods.attributeList.length - 1"></i>
                                    </div>
                                    <el-input v-model="goods.remarks"  placeholder="请设置销售价格" >
                                        <template slot="prepend">备注</template>
                                    </el-input>
                                </div>
                            </div>
                        </el-card>
                    </div>
                    <p class="color_09bbe7 font-size-13 cursor_pointer" @click="addGoodsSku">新增sku <i class="el-icon-circle-plus"></i></p>
                </el-form-item>

                <el-form-item label="零售价格">
                    <el-input v-model="pushData.retail_price"></el-input>
                    <p class="color_eb3021_important font-size-10 padding15 margin-left-1em">当前价格可自行设置或者选择根据上面的sku自动填充【首页展示】的价格</p>
                </el-form-item>

                <el-form-item label="库存数">
                    <el-input v-model="pushData.goods_number"></el-input>
                    <p class="color_eb3021_important font-size-10 padding15 margin-left-1em">当前库存可自行设置或者选择根据上面的sku自动填充【首页展示】的库存</p>
                </el-form-item>

                <el-form-item label="折扣（两位小数）">
                    <el-input-number v-model="pushData.discount" :precision="2" :step="0.1" :max="1"></el-input-number>
                    <p class="color_eb3021_important font-size-10 padding15 margin-left-1em">当前折扣可自行设置或者选择根据上面的sku自动填充【首页展示】的折扣，两位小数，例如：0.50 标识5折，0.85表示85折</p>
                </el-form-item>


                <el-form-item label="推荐类型" >
                    <el-radio-group v-model="recommendChecked">
                        <el-radio-button label="0" :key="1">无</el-radio-button>
                        <el-radio-button label="1" :key="2">新品</el-radio-button>
                        <el-radio-button label="2" :key="3">热卖</el-radio-button>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="直接上架">
                    <el-switch on-text="" off-text="" v-model="pushData.is_on_sale"></el-switch>
                </el-form-item>

                <el-form-item label="排序">
                    <el-input-number v-model="pushData.sort_order" :min="1" :max="1000"></el-input-number>
                </el-form-item>

                <el-form-item label="搜索关键字" >
                    <el-input v-model="pushData.keywords"></el-input>
                </el-form-item>

                <el-form-item label="活动备注（价格备注）" >
                    <el-input v-model="pushData.price_desc" placeholder="如该商品存在买 * 送 * 活动，请备注买送详情以及发货方式, 长度为32字符以内！" ></el-input>
                </el-form-item>
                <el-form-item label="推广标签" >
                    <el-input v-model="pushData.promotion_tag"></el-input>
                </el-form-item>
                <el-form-item label="推广详情" >
                    <el-input
                        type="textarea"
                        :rows="3"
                        placeholder="请输入推广详情"
                        v-model="pushData.promotion_desc">
                    </el-input>
                </el-form-item>
            </el-form>

            <el-dialog title="添加标签" :visible.sync="addTagsForm" width="30%" >
                <el-checkbox-group v-model="addTagsList" size="small">
                    <el-checkbox border style="margin-left: 10px" v-for="tags in tagsList" :label="tags" :key="tags.id">
                        {{tags.name}}
                    </el-checkbox>
                </el-checkbox-group>
            </el-dialog>

            <!--格式化数据用-->
            <el-dialog title="请粘贴需要格式化的商品数据" :visible.sync="fotmatTemperDataBool" width="50%">
                <textarea v-model="formatTemperData" name="" id=""
                          style="width: 100%;border-radius: 10px;min-height: 150px;"></textarea>
                <div style="text-align: right;padding: 10px;box-sizing: border-box">
                    <el-button class="editor-btn" type="primary" @click="fillData">填充</el-button>
                </div>
            </el-dialog>

            <h1 style="padding: 1em;text-align: left;">商品详情 (
                <span @click="showPreviewPhone = !showPreviewPhone" style="color: #429493;font-size: 16px;cursor:pointer;">点此预览详情</span>
                )</h1>

            <div class="editBox_preview" @click="showPreviewPhone = !showPreviewPhone">
                <ueditor class="editBox" v-model="pushData.goods_desc"></ueditor>
                <phone-preview :html="pushData.goods_desc" v-model="showPreviewPhone"></phone-preview>
            </div>
        </div>
        <div class="operationBtn">
            <el-button class="editor-btn" type="primary" @click="submitPaper">提交</el-button>
            <!--<el-button class="editor-btn" type="default" @click="saveAsDraft">存为草稿</el-button>-->
        </div>


    </div>
</template>

<script>
    import ueditor from '../../common/editor'
    import bus from '../../common/bus'
    import uploadImage from '../../common/uploadImage'
    import phonePreview from '../../common/phonePreview'

    export default {
        data: function () {
            return {
                currentCategory: '', //选择胡当前商品一级分类
                currentCategoryArr: [], //选择胡当前商品子分类数组
                fotmatTemperDataBool: false, // 格式化窗口
                formatTemperData: '', // 用于格式化的字符串数据
                column: '',
                columnList: [],
                tagsList: [],
                addTagsList: [],
                addTagsForm: false,
                templateDialogVisible: false,
                uploadLoading: false, // 上传组件状态
                title: '新增商品',
                templateSearchName: '',
                fileList: '',
                category: [],
                templateData: [],
                recommendChecked: 0, // 推荐类型
                uploadData: {
                    title: '',
                    author: '',
                    content: '',
                    tag: '',
                    sort: '',
                    picture: '',
                    sharePicture: '',
                    categoryId: 0,
                    contentType: '',
                    selected: '',
                },
                pushRules: {
                    name: [{required: true, message: "请输入商品名称", trigger: "blur"}],
                    goods_number: [{required: true, message: "请输入商品名称", trigger: "blur"}],
                    goods_brief: [{required: true, message: "请输入商品简介", trigger: "blur"}],
                    goods_desc: [{required: true, message: "请完善商品详情", trigger: "blur"}],
                    retail_price: [{required: true, message: "请明确零售价格", trigger: "blur"}],
                    brand_id: [{required: true, message: "请选择商品品牌", trigger: "blur"}],
                    list_pic_url: [{required: true, message: "请上传商品图片", trigger: "blur"}],
                    primary_pic_url: [{required: true, message: "请上传商品主图", trigger: "blur"}],
                    attribute: [{type: 'array', required: true, message: "请添加商品属性", trigger: "blur"}],
                },
                brandOptions: [], // 品牌集合
                pushData: {
                    category_id: '', // 商品分类
                    name: '', // 商品名
                    brand_id: '', // 品牌名称
                    goods_number: '', // 商品库存数
                    keywords: '', // 关键字
                    goods_brief: '', // 商品简介
                    goods_desc: '', // 商品详情
                    is_on_sale: 0, // 默认在售
                    // special_type: 1, // 默认为普通商品，非试用装
                    // is_special_goods: false, // 是否为试用装
                    add_time: '', // 添加时间
                    sort_order: 0, // 排序
                    attribute: [], // 0
                    counter_price: 0, // 专柜价格
                    extra_price: 0, // 附加价格
                    goods_unit: '件', // 商品单位
                    primary_pic_url: '', // 商品主图
                    list_pic_url: '', //商品列表图
                    retail_price: '', // 零售价格
                    sell_volume: '', // 销量
                    primary_product_id: '', // 主sku　product_id
                    unit_price: 0, // 单位价格，单价
                    promotion_desc: '', // 推广详情
                    promotion_tag: '', // 推广tag
                    app_exclusive_price: '', // 专柜价
                    is_app_exclusive: '', // 是否app 专属
                    is_hot: 0, // 是否热卖
                    is_new: 0, // 是否新品首发
                    price_desc: '', // 活动备注
                    discount: 1, // 商品的折扣

                },
                fileList_arr: [], // 提交的商品图数组
                showPreviewPhone: false,
                attributeData: [], // 商品的属性sku
                attrIndexLength: 1, // 商品的sku属性个数
                selectSKUData: {}, // 选择的sku数据参数
                goodsList: [
                    {
                        header_img: '',
                        goods_number: '', // 商品单sku库存
                        selling_price: '', // 商品单sku售价
                        discount: 1, // 商品的折扣价
                        IS_PAGE_SHOW: false, // 是否首页显示
                        remarks: '', // sku备注
                        attributeLength: 1,
                        onlyId: Math.random().toString(36).substr(3,length) + Date.now().toString(36), // 生成随机数的id
                        attributeList: [
                            {
                                random_id: Math.random().toString(36).substr(3,length) + Date.now().toString(36),
                                attribute_value: []
                            }
                        ]
                    }
                ], // 商品的数组
            }
        },
        components: {
            ueditor,
            uploadImage,
            'phone-preview': phonePreview,
            'sku-img-upload': uploadImage
        },
        computed: {
            windowWidth() {
                return document.body.clientWidth
            }
        },
        watch: {
            // 每次修改一级分类都要加载二级分类
            currentCategory(newv) {
                this.getCurrentCategory(newv)
            },
            recommendChecked: {
                handler(newv) {
                    newv = Number(newv)
                    if (newv === 0) {
                        this.pushData.is_hot = 0;
                        this.pushData.is_new = 0
                    } else if (newv === 1) {
                        this.pushData.is_hot = 0;
                        this.pushData.is_new = 1
                    } else if (newv === 2) {
                        this.pushData.is_hot = 1;
                        this.pushData.is_new = 0
                    }
                },
                immediate: true
            }
        },
        methods: {
            // 变更首页展示
            changePageShow (bool, item) {
                if (bool) {
                    this.goodsList = this.goodsList.map(function (param) {
                        if (item.onlyId === param.onlyId) {
                            param.IS_PAGE_SHOW = true
                        } else {
                            param.IS_PAGE_SHOW = false
                        }
                        return param;
                    });
                    this.pushData.retail_price = item.selling_price;
                    this.pushData.goods_number = item.goods_number;
                    this.pushData.discount = item.discount;
                }
            },
            // 新增单品
            addGoodsSku () {
                this.goodsList.push(
                    {
                        header_img: '',
                        goods_number: '', // 商品单sku库存
                        selling_price: '', // 商品单sku售价
                        discount: 1, // 商品折扣价
                        IS_PAGE_SHOW: false, // 是否首页显示
                        remarks: '', // sku备注
                        attributeLength: 1,
                        onlyId: Math.random().toString(36).substr(3,length) + Date.now().toString(36), // 生成随机数的id
                        attributeList: [
                            {
                                random_id: Math.random().toString(36).substr(3,length) + Date.now().toString(36),
                                attribute_value: []
                            }
                        ]
                    }
                )
            },
            // 新增属性
            up_attrIndexLength (goods) {
                for (let index = 0; index < this.goodsList.length; index++) {
                    if (this.goodsList[index].onlyId === goods.onlyId) {
                        this.goodsList[index].attributeList.push({
                            random_id: Math.random().toString(36).substr(3,length) + Date.now().toString(36),
                            attribute_value: []
                        })
                    }
                }
            },
            // 减少属性
            cut_attrIndexLength (goods, attribute) {
                for (let index = 0; index < this.goodsList.length; index++) {
                    if (this.goodsList[index].onlyId === goods.onlyId) {
                        for (let item = 0; item < this.goodsList[index].attributeList.length ; item ++) {
                            if (attribute.random_id === this.goodsList[index].attributeList[item].random_id) {
                                this.goodsList[index].attributeList.splice(item, 1)
                            }
                        }
                    }
                }
            },
            // 检查是否先选择了商品属性
            attrVisibleChange (visable) {
                if (visable) {
                    if (this.attributeData.length === 0) {
                        this.$message.error('未选择商品分类 或 该分类属性下不存在商品sku属性值！')
                    }
                }
            },
            // 为商品选择SKU
            attributeDataChange (data) {
                console.log(data)
            },
            // 商品选择分类触发
            chooseThisCategory (category_id) {
                if (!category_id) {
                    this.$message.error('请重新选择商品分类！');
                    return
                }
                this.$http.post(this.$api + '/admin/goods/goodsAttributes', {
                    category_id: category_id
                }).then(res => {
                    if (res.data.errno === 0) {
                        this.attributeData = res.data.data
                    }
                })
            },
            handleError() {
                this.uploadLoading = false
            },
            // 上船前
            beforeUpload(file) {
                this.uploadLoading = true
            },
            // 填充获取的格式化数据
            fillData() {
                let that = this;
                if (!this.formatTemperData) {
                    this.$message.error('数据格式不正确！');
                    return
                }
                let Json = JSON.parse(this.formatTemperData);
                this.pushData.name = Json.title;
                this.pushData.retail_price = Json.price;
                this.pushData.goods_brief = Json.title;
                const loading = this.$loading({
                    lock: true,
                    text: '正在格式化商品详情',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$http.post(this.$api + '/admin/download/get', {list: Json.description}).then(res => {
                    //console.log(res)
                    if (res.data.errno === 0) {
                        setTimeout(function () {
                            let p = '';
                            for (let item in res.data.data) {
                                p += '<p style="text-align: center;"><img src="' + res.data.data[item] + '"/></p>';
                            }
                            that.pushData.goods_desc = p;
                            loading.close();
                        }, 2500)
                    }
                })

            },
            // 格式化商品图片
            getTemperData() {
                this.fotmatTemperDataBool = !this.fotmatTemperDataBool
            },
            // 删除上传的商品图片
            handleRemove(ele) {
                for (let item in this.fileList_arr) {
                    if (this.fileList_arr[item].uid === ele.uid) {
                        this.fileList_arr.splice(item, 1)
                    }
                }
                this.$http({
                    url: this.$api + 'admin/file/deleteSingleFile',
                    method: 'post',
                    data: this.$qs.stringify({filename: ele.url}),
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('删除成功');
                    } else {
                        this.$message(response.data.msg)
                    }
                })
            },
            // 上传轮播图
            handleImageSuccess(res) {
                if (res.errno == 0) {
                    let name;
                    let resArr = res.data.split('/');
                    if (resArr.length > 0) {
                        name = resArr[resArr.length - 1]
                    }
                    this.fileList_arr.push({
                        name: name,
                        id: 0,
                        url: res.data
                    })
                }
                this.uploadLoading = false
            },
            // 删除商品属性
            handleRemoveAttribute(index) {
                if (index >= 0 && index < this.pushData.attribute.length) {
                    this.pushData.attribute.splice(index, 1);
                }
            },
            // 新增一条商品属性
            handleAddAttribute() {
                let obj = {"name": "", "value": ""};
                this.pushData.attribute.push(obj);
            },
            // 获取品牌
            getBrand() {
                this.$http({
                    url: this.$api + "/admin/brand/index",
                    data: this.$qs.stringify({params: {size: 500}}),
                    method: 'post'
                }).then(response => {
                    if (response.data.errno == 0) {
                        this.brandOptions = this.brandOptions.concat(response.data.data.data);
                    }
                });
            },

            handleClose(tag) {
                this.addTagsList.splice(this.addTagsList.indexOf(tag), 1);
            },
            deleteTemplate(id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/template/info/delete',
                        method: 'post',
                        data: this.$qs.stringify({
                            id: id
                        })
                    }).then(res => {
                        if (res.data.errno === 0) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                            this.getTemplate()
                        } else {
                            this.$message.error(res.data.errmsg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });

            },
            selectTemplate(id) {
                this.$http({
                    url: this.$api + '/admin/template/info/get',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: id
                    })
                }).then(res => {
                    if (res.data.errno === 0) {
                        let text = res.data.data.templateContent;
                        this.uploadData = eval('(' + text + ')');
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                })
            },
            submitPaper() {
                let that = this;
                if (!this.pushData.name || !this.pushData.category_id) {
                    this.$message.error('请完善商品参数后提交！！');
                    return
                }
                // 判断sku是否完善
                let is_not_finish = false;
                for (let item in this.goodsList) {
                    if (!this.goodsList[item].selling_price) {
                        this.$message.error(`请完善第${Number(item) + 1}个商品的价格！！`);
                        is_not_finish = true
                        break
                    }

                    if (!this.goodsList[item].goods_number) {
                        this.$message.error(`请完善第${Number(item) + 1}个商品的库存数据！！`);
                        is_not_finish = true
                        break
                    }

                    for (let index in this.goodsList[item].attributeList) {
                        if (this.goodsList[item].attributeList[index].attribute_value.length <= 0) {
                            is_not_finish = true
                            this.$message.error(`请完善第${Number(item) + 1}个商品的第${Number(index) + 1}个sku参数！！`);
                            break
                        }


                        if (this.goodsList[item].attributeList[index].attribute_value.length < 2) {
                            is_not_finish = true
                            this.$message.error(`请完善第${Number(item) + 1}个商品的第${Number(index) + 1}个sku参数的第${attribute_value_index}个数据！！`);
                            break
                        }
                    }
                }

                if (is_not_finish) {
                    return
                }

                const loading = this.$loading({
                    lock: true,
                    text: '正在提交...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // 判断是否需要直接设置成新品或者是是否上线销售，
                this.pushData.is_on_sale ? this.pushData.is_on_sale = 1 : this.pushData.is_on_sale = 0;
                // 标识为普通商品，默认值
                this.pushData.special_type = 2;
                this.pushData.list_pic_url = this.pushData.primary_pic_url;
                // 商品图册，即商品的轮播图。
                this.pushData.goodsGallery = this.fileList_arr;

                // 添加sku数据
                let SKU = this.goodsList;
                let goods_attribute_key_list = [];
                let Goods_Attribute_List = [];
                for (let item in SKU) {
                    for (let param in SKU[item].attributeList) {
                        SKU[item].attributeList[param].attribute_key = [];
                        that.attributeData.map(function (attr) {
                            if (attr.id === SKU[item].attributeList[param].attribute_value[0]) {
                                SKU[item].attributeList[param].attribute_key.push(attr.label);
                                // 保存在 商品表 数据的key
                                if (goods_attribute_key_list.indexOf(attr.id) === -1) {
                                    goods_attribute_key_list.push(attr.id);
                                    // 生成第一条attr列表数据
                                    Goods_Attribute_List.push({
                                        id: attr.id,
                                        name: attr.label,
                                        value_list: []
                                    })
                                }
                            }

                            attr.children.map(function (attr_value) {
                                if (attr_value.value === SKU[item].attributeList[param].attribute_value[1]) {
                                    SKU[item].attributeList[param].attribute_key.push(attr_value.label)
                                }
                            })
                        })
                    }
                }

                // 把所有相同属性的值组成一个list, 保存在商品数据中
                for (let item in SKU) {
                    for (let param in SKU[item].attributeList) {
                        Goods_Attribute_List = Goods_Attribute_List.map(function (goods_attr_item) {
                            if (goods_attr_item.id === SKU[item].attributeList[param].attribute_value[0]) {
                                that.attributeData.map(function (attr) {
                                    attr.children.map(function (attr_value) {
                                        if (attr_value.value === SKU[item].attributeList[param].attribute_value[1]) {
                                            if (JSON.stringify(goods_attr_item.value_list).indexOf(
                                                JSON.stringify({
                                                    name: attr_value.label,
                                                    id: SKU[item].attributeList[param].attribute_value[1]
                                                })) === -1) {
                                                goods_attr_item.value_list.push(
                                                    {
                                                        name: attr_value.label,
                                                        id: SKU[item].attributeList[param].attribute_value[1]
                                                    })
                                            }
                                        }
                                    })
                                })
                            }
                            return goods_attr_item
                        })
                    }
                }


                this.pushData.SKU = SKU;
                this.pushData.Goods_Attribute_List = Goods_Attribute_List;
                // 提交所有参数
                this.$http({
                    url: this.$api + '/admin/goods/add',
                    method: 'post',
                    data: this.pushData
                }).then(response => {
                    loading.close()
                    if (response.data.errno === 0) {
                        this.$notify({
                            title: '成功',
                            message: '添加商品成功!',
                            type: 'success'
                        });
                        this.$router.push('/specialGoodsList');
                        let reload = true;
                        bus.$emit('reloadPaper', reload)
                    }
                })


                loading.close()




            },
            listCategory() {
                this.$http({
                    url: this.$api + '/admin/category/parent',
                    method: 'post'
                }).then(response => {
                    if (response.data != null) {
                        this.category = response.data.data
                    }
                })
            },
            getCurrentCategory(id) {
                this.$http({
                    url: this.$api + '/admin/category/current',
                    method: 'post',
                    data: this.$qs.stringify({categoryId: id})
                }).then(response => {
                    if (response.data != null) {
                        this.currentCategoryArr = response.data.data
                    }
                })
            },
        },
        created() {
            this.Auth_token = localStorage.getItem('auth_token')
            this.listCategory()
            this.getBrand()
        },
        mounted() {
            let ct = this.$route.query.ct
            this.uploadData.contentType = ct
        }
    }
</script>
<style>

</style>
<style>
    .operationBtn {
        text-align: right;
        padding: 20px;
    }

    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .audio-div {
        margin: 20px 0 20px 0;
    }

    .article-edit span {
        width: 100px;
        margin-left: 20px;
    }

    .display-flex {
        display: flex;
    }

    .alignCenter {
        text-align: center;
    }

</style>
