<template>
    <div class="question-content">
        <div class="title"><span>关键数据情况</span></div>
        <div class="core-data">
            <div v-for="item in keyDataList" style="width: 23%">
                <div class="white core-data-box">
                    <div class="ft20 solid-red">{{item.totalNumberName}}</div>
                    <div class="ft25">{{item.totalNumber}}</div>
                    <div class="ft16 hui">{{item.yesterdayNumberName}}：{{item.yesterdayNumber}}</div>
                </div>
            </div>
        </div>
        <div  class="tagSwitch white border-radius3">
            <el-tabs v-model="tagsType" @tab-click="handleTabClick">
                <el-tab-pane name="hot">
                    <span slot="label" style="padding-left: 40px">热门推荐</span>
                    <div class="white border-radius3" v-for="item in hotDataList" >
                        <question-answer-data :question = item class="tag-box"></question-answer-data>
                    </div>
                    <div class="paginaion" style="text-align: center;margin-bottom: 2em">
                        <el-pagination
                            @size-change="handleHotSizeChange"
                            @current-change="handleHotPageChange"
                            :current-page="hotcurrentPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="tableData.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="hottotal">
                        </el-pagination>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="最新发布" name="new">
                    <span slot="label">最新发布</span>
                    <div class="white border-radius3" v-for="item in newDataList">
                        <question-data :question = item class="tag-box"></question-data>
                    </div>
                    <div class="paginaion" style="text-align: center;margin-bottom: 2em">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handlePageChange"
                            :current-page="currentPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="tableData.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="tableData.count">
                        </el-pagination>
                    </div>

                </el-tab-pane>
            </el-tabs>
        </div>

    </div>
</template>

<script>
    import QuestionAnswerData from './QuestionAnswerData.vue';
    import QuestionData from './QuestionData.vue';
    import bus from '../../common/bus';
    export default {
        data: function () {
            return {
                keyDataList: [],
                hotDataList: [],
                newDataList: [],
                tagsType: 'new',
                currentPage: 1,
                total: 0,
                size: 10,
                hotcurrentPage: 1,
                hottotal: 0,
                hotsize: 10,
            }
        },
        components: {QuestionData,QuestionAnswerData},
        created() {
            this.getKeyData();
            this.getHotData(this.currentPage, this.size);
            this.getNewData(this.currentPage, this.size);
        },
        mounted() {
            bus.$on('reloadPage', res => {
                if (res) {
                    this.getNewData(this.currentPage, this.size)
                }
            })
        },
        methods: {
            handlePageChange(val) {
                this.getNewData(val, this.size)
            },
            handleSizeChange(val) {
                this.getNewData(this.currentPage, val)
            },
            getNewData(page, size) {
                this.$http({
                    url: this.$api + '/chigua/admin/question/list/new',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page, limit: size
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.newDataList = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            handleHotPageChange(val) {
                this.getHotData(val, this.hotsize)
            },
            handleHotSizeChange(val) {
                this.getHotData(this.hotcurrentPage, val)
            },
            getHotData(page, size) {
                this.$http({
                    url: this.$api + '/chigua/admin/question/list/hot',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page, limit: size
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.hotDataList = response.data.data.records
                        this.hotcurrentPage = response.data.data.current
                        this.hottotal = response.data.data.total
                        this.hotsize = response.data.data.size
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            getKeyData() {
                this.$http({
                    url: this.$api + '/chigua/admin/question/situation',
                    method: 'get'
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.keyDataList = response.data.data
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    // //console.warn(res)
                })
            },
            handleTabClick(tab, event) {
                // //console.log(tab, event);
            }
        }
    }

</script>

<style scoped>

    .question-content {
        padding: 10px 100px 20px 20px;
        /*width: 900px;*/
        margin: auto;
    }

    .tag-box > div {
        margin-top: 10px;
    }

    .tag-box {
        margin-bottom: 20px;
        padding: 20px 50px 25px 30px;
    }

    .tagSwitch {
        margin:30px 0px 30px 0px;
        height: 30px;
        padding: 0px 5px 5px 0px;

    }

    .hui {
        color: #8c939d;
    }

    .ft16 {
        font-size: 16px;
    }

    .ft20 {
        font-size: 20px;
    }

    .ft25 {
        font-size: 25px;
    }

    .core-data-box > div {
        padding: 0 10px 0 10px;
        margin-top: 10px;
    }

    .solid-red {
        border-left: 5px solid;
        border-left-color: red;
    }

    .core-data-box {
        height: 120px;
        padding: 20px;
        border-radius:30px;
    }

    .border-radius3{
        border-radius:10px;
    }
    .core-data {

        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .white {
        background-color: #fff;
    }

    .title {
        margin-bottom: 10px;
    }

</style>
