<template>
    <div>
        <div class="crumbs button-tool">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}条内容）</label>

        </div>
        <div class="disflex_flex_start">
            <div>
                <el-button v-permission="'add'" type='primary' @click="$router.push('/addquestion')">新增图文内容</el-button>
                <el-button v-permission="'add'" type='primary' @click="$router.push('/addVideoQuestion')">新增视频发布</el-button>
            </div>
            <div class="search-title">
                <el-input placeholder="搜索内容/标题" v-model="searchTitle" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>

        <el-table
            :data="tableData.data"
            style="width: 100%">
            <el-table-column
                label="提问者"
                width="120">
                <template slot-scope="scope">
                    <div class="text-align-center">
                        <img class="width30" :src="scope.row.avatar" alt="">
                        <p>{{ scope.row.nickname }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="提问" width="200">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper" style="position: relative;">
                        <img v-if="scope.row.fakePicture" :src="scope.row.fakePicture"
                             style="width: 60px;vertical-align: middle;">
                        <h4>{{ scope.row.title }}</h4>
                        <p>{{ scope.row.content.substring(0, 5) + '...' }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="浏览数"
                width="100" v-if="$store.state.UA !== 'MOBILE'">
                <template slot-scope="scope">
                    <span
                        style="margin-left: 10px">{{ scope.row.browse_num === null ? 0 : scope.row.browse_num }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="回答数"
                width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.reply_num === null ? 0 : scope.row.comment_num }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="分享数"
                width="80"  v-if="$store.state.UA !== 'MOBILE'">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.forward_num === null ? 0 : scope.row.forward_num }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="点赞数"
                width="100"  v-if="$store.state.UA !== 'MOBILE'">
                <template slot-scope="scope">
                    <span
                        style="margin-left: 10px">{{ scope.row.praise_num === 0 ? 0 : scope.row.praise_num}}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="发布时间"
                width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ formatStrTime(scope.row.add_time, 16) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="审核状态"
                width="150">
                <template slot-scope="scope">
                    <span class="red" v-if="scope.row.judge_type === 2" style="font-size: 16px !important;font-weight: bold;">{{ scope.row.judge_type_cn }}</span>
                    <span  v-else-if="scope.row.judge_type === 1" style="font-size: 16px !important;font-weight: bold;color: #09bb07;">{{ scope.row.judge_type_cn }}</span>
                    <span  v-else style="font-size: 16px !important;font-weight: bold;">{{ scope.row.judge_type_cn }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px;align-items: flex-end;">
                        <i title="详情" class="el-icon-tickets ft25 pointer"
                           @click="getShareDetail(scope.row)"></i>
                        <!-- 删除 -->
                        <i title="删除" class="el-icon-delete ft25 red pointer"
                           @click="deleteQuestion(scope.row.id)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>

        <el-dialog :visible.sync="shareDetailBool" custom-class="question-dialog" title="分享详情" @close="shareDetail = {}">
            <question-data :question="shareDetail"></question-data>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import QuestionData from './QuestionData.vue';

    export default {
        data: function () {
            return {
                activeName: '全部',
                // loading: true,
                tableData: {},
                searchTitle: '',
                shareDetail: {},
            }
        },
        components: {
            QuestionData
        },
        computed: {
            shareDetailBool: {
                get: function () {
                    return this.shareDetail.id? true:false
                },
                set: function () {
                }
            }
        },
        watch: {},
        created() {
            this.getQuestionList(1, 10);
        },
        mounted() {
            let that = this;
            bus.$on('reloadPage', res => {
                if (res) {
                    that.getQuestionList(that.tableData.currentPage, that.tableData.pageSize)
                }
            })
            // 关闭详情窗口，并刷新页面
            bus.$on('closeQuestionBox', res => {
                if (res) {
                    that.shareDetail.id = null;
                    that.getQuestionList(that.tableData.currentPage, that.tableData.pageSize)
                }
            })
        },
        methods: {
            // 分享详情
            getShareDetail (item) {
                this.$http({
                    url: this.$api + '/admin/community/shareDetail',
                    method: 'post',
                    data: {id: item.id}
                }).then(response => {
                    if (response.data.errno === 0) {
                        if (response.data.data) {
                            if (response.data.data.picture) {
                                response.data.data.picture = response.data.data.picture.split(',')
                            }
                        }
                       this.shareDetail = response.data.data
                    }
                })
            },
            // 删除课程
            deleteQuestion(id) {
                this.$confirm('您正在删除用户发布的内容，确认删除？', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/community/deleteShare',
                        method: 'post',
                        data: this.$qs.stringify({id: id})
                    }).then(response => {
                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success('删除成功！');
                                this.getQuestionList(this.tableData.currentPage, this.tableData.pageSize)
                            } else {
                                this.$message.warning(response.data.msg);
                            }
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });

            },
            handleSearchByTitle() {
                this.getQuestionList(this.currentPage, this.size, this.searchTitle)
            },
            handleSizeChange(val) {
                this.getQuestionList(this.currentPage, val, this.searchTitle)
            },
            handleCurrentChange(val) {
                this.getQuestionList(val, this.size, this.searchTitle)
            },
            getQuestionList(page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/community/list',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page,
                        size: length,
                        search: title || this.searchTitle
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                        this.tableData.data = this.tableData.data.map(function (item) {
                            if (item.judge_type === 1) {
                                item.judge_type_cn = '审核通过'
                            } else if (item.judge_type === 2) {
                                item.judge_type_cn = '驳回审核'
                            } else {
                                item.judge_type_cn = '未审核'
                            }
                            return item
                        })
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            },
            switchTag(tab) {
                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {
                        this.getQuestionList(this.tagSwitchData[i].value, 1, 10);
                        this.currentTagId = this.tagSwitchData[i].value
                    }
                }
            },
        }
    }
</script>
<style>
    .question-dialog .el-dialog__body {
        padding: 10px 35px 20px 40px !important;
    }

    .question-dialog .el-dialog__header {
        border-bottom: #6d6d9d 1px solid !important;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px !important;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped><
.tagSwitch {
    width: 100%;
    height: auto;
}

/
style >
