<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 活动管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>


        <el-card class="activity-card">
            <div slot="header" class="activity-card-header">
                <span class="activity-title"><b>活动设置</b></span>
            </div>
            <el-form :model="activityForm" :rules="rules" ref="activityForm"
                     label-position="left">
                <el-form-item label="活动名称" prop="title">
                    <el-input v-model="activityForm.title" placeholder="汽车金融行业交流会" style="width: 70%"></el-input>
                </el-form-item>

                <el-form-item label="活动时间" required>
                    <el-date-picker
                        v-model="activityForm.startTime"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="开始时间">
                    </el-date-picker>
                    <span style="margin-right: 10px;margin-left: 10px">至</span>
                    <el-date-picker
                        v-model="activityForm.endTime"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="报名时间" required>
                    <el-date-picker
                        v-model="activityForm.applyStartTime"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="yyyy-MM-dd HH:mm"
                        placeholder="开始时间">
                    </el-date-picker>
                    <span style="margin-right: 10px;margin-left: 10px">至</span>
                    <el-date-picker
                        v-model="activityForm.applyEndTime"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        type="datetime"
                        placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="活动地点" required prop="activityAddress">
                    <el-input v-model="activityForm.activityAddress" style="width: 30%" placeholder="20个字以内,仅用于短信发送"></el-input>
                </el-form-item>


                <el-form-item label="活动规模（人数）" required>
                    <el-input v-model.number="activityForm.scale" class="btn-number"></el-input>
                    <span class="tips">若人数到达刚提前结束报名</span>
                </el-form-item>

                <el-form-item label="活动定价（元）" required prop="price">
                    <el-input v-model.number="activityForm.price" class="btn-number"></el-input>
                </el-form-item>

                <el-form-item label="活动备注">
                    <el-input style="width: 70%" type="textarea" v-model="activityForm.remark"></el-input>
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="activity-card">
            <div slot="header" class="activity-card-header">
                <span class="activity-title"><b>优惠设置</b></span>
                <el-switch v-model="isDiscount"></el-switch>
                <span class="tips">只能选择一种优惠规则</span>
            </div>
            <div v-if="isDiscount">
                <el-radio>规则1：</el-radio>
                用户团购
                <el-input v-model="activityForm.groupNum"
                          class="btn-number"></el-input>
                张或以上，则每张减免
                <el-input v-model="activityForm.reducePrice"
                          class="btn-number"></el-input>
                元
            </div>
        </el-card>

        <el-card class="activity-card">
            <div slot="header" class="activity-card-header">
                <span class="activity-title"><b>邀请有奖</b></span>
                <el-switch v-model="isInvite"></el-switch>
            </div>

            <div v-if="isInvite">
                <label>邀请用户奖励</label>
                <el-input v-model.number="activityForm.awardPrice" class="btn-number"></el-input>
                <label>元</label>
            </div>
        </el-card>

        <el-card class="activity-card">
            <div slot="header" class="activity-card-header">
                <span class="activity-title"><b>酒店设置</b></span>
                <el-button style="float: right;margin-right: 50px" type="success" @click="saveHotel"
                           :disabled="!activityForm.id"
                           v-if="activityForm.id">保存酒店信息
                </el-button>
            </div>
            <el-form :model="hotelForm" :rules="hotelRules" ref="hotelForm" label-position="left">

                <el-form-item label="酒店名称" required>
                    <el-input v-model="hotelForm.hotelName" style="width: 70%"></el-input>
                </el-form-item>

                <el-form-item label="酒店地址" required>
                    <el-input v-model="hotelForm.address" style="width: 30%"></el-input>
                </el-form-item>


                <el-form-item label="初始房间数（间）" required>
                    大床房
                    <el-input v-model.number="hotelForm.bigRoomNum" class="btn-number"></el-input>
                    标准间
                    <el-input v-model.number="hotelForm.standardRoomNum" class="btn-number"></el-input>
                </el-form-item>

                <el-form-item label="协议价（元）" required>
                    <el-input v-model.number="hotelForm.price" class="btn-number"></el-input>
                    /晚
                </el-form-item>
            </el-form>
        </el-card>

        <el-card class="activity-card">
            <div slot="header" class="activity-card-header">
                <span class="activity-title"><b>活动相关物料</b></span>
            </div>

            <div class="display-flex">
                <div style="width: 50%">
                    <div class="display-flex">
                        <span class="upload-header">封面图</span>
                        <upload-image v-model="activityForm.coverPicture" :action="$api+this.UPLOAD_IMG">
                            <div style="text-align: center;">750*350px</div>
                        </upload-image>
                    </div>

                    <div class="display-flex">
                        <div>
                            <div class="upload-header">邀请函图</div>
                            <el-button v-if="activityForm.invitationPicture" type="primary"
                                       @click="addInviteWatermark(activityForm.invitationPicture,'invite')">
                                添加水印
                            </el-button>
                        </div>
                        <!--<span class="upload-header"></span>-->
                        <upload-image v-model="activityForm.invitationPicture"
                                      :action="$api+this.UPLOAD_IMG">
                            <div style="text-align: center;">690*1228px</div>
                        </upload-image>
                    </div>

                    <div class="display-flex">
                        <span class="upload-header">分享图(支付前)</span>
                        <upload-image v-model="activityForm.shardPicture" :action="$api+this.UPLOAD_IMG">
                            <div style="text-align: center;">610*1134px</div>
                        </upload-image>
                    </div>
                    <div class="display-flex">
                        <div>
                            <div class="upload-header">分享图(支付后)</div>
                            <el-button v-if="activityForm.shardPicture2" type="primary"
                                       @click="addWatermark(activityForm.shardPicture2,'share')">添加水印
                            </el-button>
                        </div>
                        <upload-image v-model="activityForm.shardPicture2" :action="$api+this.UPLOAD_IMG">
                            <div style="text-align: center;">610*1134px</div>
                        </upload-image>
                    </div>

                </div>
                <div style="width: 50%">
                    <div class="display-flex">
                        <span class="upload-header">内容长图</span>
                        <upload-image v-model="activityForm.contentPicture" :action="$api+this.UPLOAD_IMG">
                            <div style="text-align: center;">750px*不限高度</div>
                        </upload-image>
                    </div>
                </div>
            </div>
        </el-card>
        <el-button type="primary" @click="addActivity" :disabled="activityForm.id!==''">立即创建</el-button>
        <el-button type="success" @click="updateActivity" :disabled="!activityForm.id">保存修改</el-button>
        <el-button @click="clearActivity">清空</el-button>

        <el-dialog title="添加水印" :visible.sync="dialogWatermarkShow" width="50%" :show-close="false">
            <watermark :watermarkData="waterData"></watermark>
        </el-dialog>
        <el-dialog title="添加水印" :visible.sync="inviteWatermarkShow" width="80%" :show-close="false">
            <watermark :watermarkData="waterData"></watermark>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus'
    import uploadImage from '../../common/uploadImage'
    import watermark from './watermark'

    export default {
        components: {uploadImage, watermark},
        data() {
            return {
                Auth_token: '',
                loading: false,
                dialogWatermarkShow: false,
                inviteWatermarkShow: false,
                waterData: {
                    url: '',
                    type: ''
                },
                watermark: {
                    x: 60,
                    y: 60,
                    size: 32,
                    color: '#000000',
                    demo: '深圳市猫趴科技有限公司'
                },
                hotelForm: {
                    id: '',
                    activityId: '',
                    hotelName: '',
                    address: '',
                    price: '',
                    standardRoomNum: '',
                    bigRoomNum: '',
                },
                activityForm: {
                    id: '',
                    title: '',
                    startTime: '',
                    applyStartTime: '',
                    endTime: '',
                    applyEndTime: '',
                    coverPicture: '',
                    invitationPicture: '',
                    shardPicture: '',
                    shardPicture2: '',
                    contentPicture: '',
                    isDiscount: false,
                    groupNum: '',
                    awardPrice: '',
                    reducePrice: '',
                    price: '',
                    scale: '',
                    remark: ''
                },
                isDiscount: false,
                isInvite: false,
                hotelRules: {},
                rules: {
                    title: [
                        {required: true, message: '请输入活动名称', trigger: 'blur'},
                        {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
                    ],
                    activityAddress: [
                        {required: true, message: '请输入活动地址', trigger: 'blur'},
                        {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
                    ],
                    price: [
                        {required: true, message: '请输入活动定价', trigger: 'blur'},
                        {type: 'number', message: '请输入数字'}
                    ],
                    awardPrice: [
                        {required: true, message: '请输入邀请奖励金', trigger: 'blur'},
                        {type: 'number', message: '请输入数字'}
                    ],
                    groupNum: [
                        {type: 'number', message: '请输入数字'}
                    ],
                    reducePrice: [{type: 'number', message: '请输入数字'}],
                    selectTime: [
                        {type: 'date', required: true, message: '请选择活动时间', trigger: 'blur'}
                    ],
                    selectApplyTime: [
                        {type: 'date', required: true, message: '请选择活动报名时间', trigger: 'blur'}
                    ],
                }
            };
        },
        watch: {},
        created() {
            this.Auth_token = localStorage.getItem('auth_token')
        },
        mounted() {
            const id = this.$route.query.activityId
            // 获取视频
            if (id) {
                this.getActivity(id)
            }
            bus.$on('watermarkUrlChange', d => {
                debugger
                this.dialogWatermarkShow = false
                if (d.type === 'share') {
                    this.activityForm.shardPicture2 = d.url
                }
                if (d.type === 'invite') {
                    this.activityForm.invitationPicture = d.url
                }
            })
        },
        methods: {
            addWatermark(url, type) {
                this.dialogWatermarkShow = true
                this.waterData.url = url
                this.waterData.type = type
            },
            addInviteWatermark(url, type) {
                this.inviteWatermarkShow = true
                this.waterData.url = url
                this.waterData.type = type
            },
            getHotel(id) {
                this.$http({
                    url: this.$api + '/chigua/admin/activity/hotel/info/get/detail',
                    method: 'post',
                    data: this.$qs.stringify({activityId: id})
                }).then(response => {
                    if (response.data.errno === 0) {
                        if (response.data.data) {
                            this.hotelForm = response.data.data
                        }
                    }
                })

            },
            getActivity(id) {
                this.$http({
                    url: this.$api + '/chigua/admin/activity/info/get/detail',
                    method: 'post',
                    data: this.$qs.stringify({activityId: id})
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.activityForm = response.data.data

                        if (response.data.data.groupNum > 0) {
                            this.isDiscount = true
                        } else {
                            this.isDiscount = false
                        }
                        if (response.data.data.awardPrice > 0) {
                            this.isInvite = true
                        } else {
                            this.isInvite = false
                        }
                        this.hotelForm.activityId = response.data.data.id;
                        this.getHotel(this.hotelForm.activityId)
                    }
                })
            },
            clearActivity() {
                for (let item in this.activityForm) {
                    this.activityForm[item] = ''
                }
                this.isInvite = false
                this.activityForm.isDiscount = this.isDiscount = false
                for (let item in this.hotelForm) {
                    this.hotelForm[item] = ''
                }
            },
            updateActivity() {
                this.submitForm('activityForm')
            },
            addActivity() {
                this.submitForm('activityForm')
            },
            saveHotel() {
                this.$http({
                    url: this.$api + '/chigua/admin/activity/hotel/info/save',
                    method: 'post',
                    data: this.$qs.stringify(this.hotelForm)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$notify({
                            title: '成功',
                            message: '保存酒店信息成功！',
                            type: 'success'
                        });
                        this.hotelForm.id = response.data.data
                    } else {
                        this.$message.error(response.data.msg)
                    }
                }).catch(error => {
                    this.$message.error('提交失败')
                })

            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.activityForm.isDiscount = this.isDiscount
                        if (!this.isDiscount) {
                            this.activityForm.groupNum = ""
                            this.activityForm.reducePrice = ""
                        }
                        if (!this.isInvite) {
                            this.activityForm.awardPrice = ""
                        }
                        this.$http({
                            url: this.$api + '/chigua/admin/activity/info/save',
                            method: 'post',
                            data: this.$qs.stringify(this.activityForm)
                        }).then(response => {
                            if (response.data.errno === 0) {
                                this.activityForm.id = this.hotelForm.activityId = response.data.data
                                this.saveHotel()
                                this.$notify({
                                    title: '成功',
                                    message: '保存活动成功！',
                                    type: 'success'
                                });
                            }
                        }).catch(error => {
                            this.$message.error('提交失败')
                        })
                    } else {
                        //console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },

        }
    }
</script>

<style scoped>
    .activity-card {
        margin: 20px 30px 20px 0;
    }

    .activity-card-header {

    }

    .activity-card-header .activity-title {
        font-size: 18px;
        margin-right: 10px;
    }

    .el-date-editor.el-input, .el-date-editor.el-input__inner {
        width: 170px;
    }

    .tips {
        color: #8c939d;
        font-size: 14px;
    }

    .upload-header {
        width: 130px;
        font-size: 16px;
        font-weight: bold;
    }

    .uploadToolBox {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    .btn-number {
        width: 80px;
    }

    .display-flex {
        display: flex;
        margin-bottom: 20px;
    }
</style>
<style>
    .activity-card .el-card__header {
        padding: 10px 20px;
    }
</style>
