<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 用户发布</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="container">
            <div class="answer-herder">
                <div class="answer-herder-title width30">
                    <div>
                        提问标题：<span>{{shareDetail.title}}</span>
                    </div>
                    <div>提问者：<span>{{shareDetail.nickname}}</span></div>
                    <div>发布时间：<span>{{formatStrTime(shareDetail.add_time,16)}}</span></div>
                </div>
                <div class="answer-herder-number width30">
                    <div>关注人数：<span>{{shareDetail.praise_num}}</span></div>
                    <div>评论数：<span>{{shareDetail.comment_num}}</span></div>
                    <div >
                        内容：<span style="color: #666;font-size: 12px;width: 50%;">{{shareDetail.content}}</span>
                    </div>
                </div>

                <div class="width30">
                    <h4>图片：</h4>
                    <img :src="item" v-for="item in shareDetail.picture" @click="showImagePath = item" style="width: 120px;">
                </div>
            </div>

            <div class="answer-table">
                <div class="answer-table-sort">
               <div class="text-align-right">
                   <el-button type="primary" @click="commentThisShare">添加评论</el-button>
                   <el-button style="color: #eb3021;margin-right: 10px" size="medium" type="text" icon="el-icon-czd-kulou" @click="addUserToBlackList(shareDetail)">加入黑名单</el-button>
               </div>
                </div>
                    <el-table class="my-table" :data="tableData.data" style="width: 100%">
                        <el-table-column
                            width="180"
                            label="回答用户">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper" style="position: relative;">
                                    <img v-if="scope.row.avatar" :src="scope.row.avatar" style="width: 40px;height:40px;vertical-align: middle;">
                                    <span style="margin-left: 10px">{{ scope.row.nickname }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="回答概览">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <span>{{ scope.row.content }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="发布时间"
                            width="150">
                            <template slot-scope="scope">
                                <i class="el-icon-time"></i>
                                <span style="margin-left: 10px">{{ formatStrTime(scope.row.add_time, 16) }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column
                            label="点赞数"
                            width="80" v-if="$store.state.UA !== 'MOBILE'">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.praise_num === null ? 0 : scope.row.praise_num }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="回复数"
                            width="80" v-if="$store.state.UA !== 'MOBILE'">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.reply_num === null ? 0 : scope.row.reply_num }}</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" width="150px">
                            <template slot-scope="scope">
                                <div style="display: flex;justify-content: space-between;height:35px;align-items: flex-end;">
                                    <!-- 有评论 -->
                                    <div @click="showReplyList(scope.row)" style="height: 24px">
                                        <el-badge v-if="scope.row.reply_num > 0" :value="scope.row.reply_num" :max="99" class="item pointer">
                                            <i title="查看评论" class="el-icon-czd-xiaoxi2 ft25 pointer"></i>
                                        </el-badge>
                                    </div>
                                    <!-- 有回答 -->
                                    <i title="回复" class="el-icon-edit-outline ft25 pointer"
                                       @click.sync="replyThisComment(scope.row)"></i>
                                    <!-- 删除 -->
                                    <i title="删除" class="el-icon-delete ft25 red pointer"
                                       @click="deleteComment(scope.row)"></i>
                                    <i title="加入黑名单" class="el-icon-czd-kulou  ft25 red pointer"  @click="addUserToBlackList(scope.row)"></i>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="paginaion" style="text-align: center;margin-top: 1em">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="tableData.currentPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="tableData.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="tableData.count">
                        </el-pagination>
                    </div>
                </div>
            <!--评论组件-->
            <el-dialog :visible.sync="showCommentList" custom-class="comment-dialog"
                       :show-close="true" @close="showCommentList = false;replyList = []">
                <comment-list :comment-data="replyList"></comment-list>
            </el-dialog>

            <!--回复用户-->
            <el-dialog  :visible.sync="isShowReplyBox" custom-class="comment-dialog" :show-close="true"
                        @close="isShowReplyBox = false;replyItemData = {}">
                <h2><span class="cursor_pointer">{{ submitReplyData.from_name }}</span>
                    回复 <span class="cursor_pointer">{{replyItemData.nickname}}</span>:</h2>
                <br>
                <el-form ref="form" label-width="80px">
                    <el-form-item label="回复内容">
                        <el-input v-model="submitReplyData.content"></el-input>
                    </el-form-item>
                    <div class="disflex_flex_end">
                        <el-button type="default">重置</el-button>
                        <el-button type="success" @click="doSubmitReplyData">回复</el-button>
                    </div>
                </el-form>
                <h2>请选择一个机器人回复：</h2>
                <span v-for="item in RobotList.data" :key="item.id" @click.stop="setThisUserForReply(item)">
                    <el-badge style="margin: 5px;" >
                        <el-button class="share-button" >
                            <img class="width25px" :src="item.avatar" alt="">
                            {{item.nickname}}
                        </el-button>
                    </el-badge>
                </span>

                <div class="paginaion" style="text-align: center;margin-top: 1em">
                    <el-pagination
                        @size-change="handleSizeChangeRobotList"
                        @current-change="handleCurrentChangeRobotList"
                        :current-page="RobotList.currentPage"
                        :page-size="RobotList.pageSize"
                        layout="total,prev, pager, next"
                        :total="RobotList.count">
                    </el-pagination>
                </div>
            </el-dialog>
            <!--评论用户-->
            <el-dialog  :visible.sync="showAddCommentDialog" custom-class="comment-dialog" :show-close="true"
                        @close="showAddCommentDialog = false;">
                <h2><span>{{ submitCommentData.nickname }}</span> 评论 :</h2>
                <br>
                <el-form ref="form" label-width="80px">
                    <el-form-item label="评论内容">
                        <el-input type="textarea" v-model="submitCommentData.content"></el-input>
                    </el-form-item>
                    <div class="disflex_flex_end">
                        <el-button type="default">重置</el-button>
                        <el-button type="success" @click="doSubmitCommentData">添加评论</el-button>
                    </div>
                </el-form>
                <h2>请选择一个机器人评论：</h2>
                <span v-for="item in RobotList.data" :key="item.id" @click.stop="setThisUserForComment(item)">
                    <el-badge style="margin: 5px;" >
                        <el-button class="share-button" >
                            <img class="width25px" :src="item.avatar" alt="">
                            {{item.nickname}}
                        </el-button>
                    </el-badge>
                </span>

                <div class="paginaion" style="text-align: center;margin-top: 1em">
                    <el-pagination
                        @size-change="handleSizeChangeRobotList"
                        @current-change="handleCurrentChangeRobotList"
                        :current-page="RobotList.currentPage"
                        :page-size="RobotList.pageSize"
                        layout="total,prev, pager, next"
                        :total="RobotList.count">
                    </el-pagination>
                </div>
            </el-dialog>
        </div>
        <preview-image :img-path="showImagePath"></preview-image>
    </div>

</template>

<script>
    import bus from '../../common/bus';
    import commentList from './commentList';
    import previewImage from '../../common/previewImage';

    export default {
        data: function () {
            return {
                showImagePath: '', // 需要预览的图片
                isShowReplyBox: false, // 是否开启回复窗口
                replyItemData: {}, // 正在回复的对象json
                submitReplyData: {}, // 需要提交的回复内容
                submitCommentData: {
                    from_name: '',
                    content: '',
                    user_id: '',
                    nickname: '',
                    share_id: ''
                }, // 需要提交的评论内容
                RobotList: {}, // 机器人列表
                showAddCommentDialog: false, // 显示添加评论窗口
                acceptPic:'https://file.chezidao.com/root/answer.png',
                sort:'createTime DESC',
                questionId:'',
                answerId:0,
                replyList:[],
                currentPage:1,
                size:10,
                total:1,
                showCommentList:false,
                showAnswer:false,
                questionPic:'',
                question:[],
                shareDetail:[],
                tableData:[]
            }
        },
        components: {commentList,
            previewImage: previewImage,
        },
        watch: {},
        created() {
        },
        mounted() {
            const id = this.$route.query.questionId
            if (id) {
                this.questionId = id
                this.getShareDetail(this.questionId)
            }
            let that = this;
            bus.$on('reloadCommentList', function () {
                that.showCommentList = false;
                that.getList(that.tableData.currentPage, that.tableData.pageSize)
            })
        },
        methods: {
            // 删除
            deleteComment (item) {
                this.$http({
                    url: this.$api + '/admin/community/deleteComment',
                    method: 'post',
                    data: {
                        id: item.id
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success(response.data.data)
                        const id = this.$route.query.questionId
                        if (id) {
                            this.questionId = id
                            this.getShareDetail(this.questionId)
                        }
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            // 加入黑名单
            addUserToBlackList (item) {
                this.$confirm('您正在把【' + item.nickname + '】加入黑名单！被拉黑的内容为：'+ (item.title || '') + (item.content || '') +'【发布时间】' + item.add_time +',请确认您的操作。', '警示', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/admin/user/addUserToBlackList',
                        method: 'post',
                        data: {
                            // 只把当前发布者加入黑名单
                            id: item.create_by || item.user_id,
                            pub_content: (item.title || '') + (item.content || '') +'【发布时间】' + item.add_time,
                            reason: '用户在评论中回复违规消息，管理员审核不通过！'
                        }
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success(response.data.data)
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            handleSizeChangeRobotList (val) {
                this.getRobotList(this.RobotList.currentPage, val, this.sort)
            },
            handleCurrentChangeRobotList (val) {
                this.getRobotList( val, this.RobotList.pageSize, this.sort)
            },
            // 执行回复用户
            doSubmitCommentData () {
                this.$http({
                    url: this.$api + '/admin/community/saveRobotComment',
                    method: 'post',
                    data: this.submitCommentData
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('评论添加成功！')
                        this.showAddCommentDialog = !this.showAddCommentDialog;
                        this.getList(this.tableData.currentPage, this.tableData.pageSize)
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            // 执行回复用户
            doSubmitReplyData () {
                this.$http({
                    url: this.$api + '/admin/community/doReplyComment',
                    method: 'post',
                    data: this.submitReplyData
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('回复成功！')
                        this.isShowReplyBox = false
                        this.getList(this.tableData.currentPage, this.tableData.pageSize)

                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            // 选择用户
            setThisUserForReply (item) {
                this.isShowReplyBox = false
                this.submitReplyData.from_name = item.nickname;
                this.submitReplyData.from_uid=item.id;
                this.isShowReplyBox = true
            },
            // 选择用户
            setThisUserForComment (item) {
                this.submitCommentData.nickname = item.nickname;
                this.submitCommentData.user_id=item.id;
            },
            commentThisShare () {
              if (!this.RobotList.count) {
                  this.getRobotList(1, 20);
              }
              this.showAddCommentDialog = !this.showAddCommentDialog;
              this.submitCommentData.share_id = this.shareDetail.id
            },
            // 回复这条评论
            replyThisComment (item) {
                this.replyItemData = item;
                this.submitReplyData.to_name = item.nickname;
                this.submitReplyData.to_uid = item.user_id;
                this.submitReplyData.comment_id = item.id;
                this.isShowReplyBox = !this.isShowReplyBox;
                if (!this.RobotList.count) {
                    this.getRobotList(1, 20);
                }
            },
            async getRobotList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                await this.$http({
                    url: this.$api + '/admin/user/getAllRobot',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 20,
                    })
                }).then(response => {
                    if (response.data.data) {
                        this.RobotList = response.data.data;
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
                loading.close()
            },
            // 显示回复
            showReplyList (item) {
                this.showCommentList = !this.showCommentList;
                this.replyList = item.replyList
            },
            // 分享详情
            getShareDetail (id) {
                this.$http({
                    url: this.$api + '/admin/community/shareDetail',
                    method: 'post',
                    data: {id: id}
                }).then(response => {
                    if (response.data.errno === 0) {
                        if (response.data.data) {
                            if (response.data.data.picture) {
                                response.data.data.picture = response.data.data.picture.split(',')
                            }
                        }
                        this.shareDetail = response.data.data
                        this.getList(this.tableData.currentPage || 1, this.tableData.pageSize || 10)
                    }
                })
            },
            handleSearchBySort(val) {
                this.getList(this.tableData.currentPage, this.tableData.pageSize, this.sort)
            },
            handleSizeChange(val) {
                this.getList(this.tableData.currentPage, val, this.sort)
            },
            handleCurrentChange(val) {
                this.getList( val, this.tableData.pageSize, this.sort)
            },
            async getList(page, length, sort) {
                const loading = this.$loading({
                    lock: true,
                    text: '正在拉取列表~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                await this.$http({
                    url: this.$api + '/admin/community/commentList',
                    method: 'post',
                    data: this.$qs.stringify({
                        id:this.shareDetail.id,
                        page: page,
                        size: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error('加载数据失败啦！')
                    }
                    loading.close()
                })
            },
        }
    }

</script>

<style>
 .pointer{
     cursor: pointer;
 }
 .answer-herder{
     display: flex;
     justify-content: flex-start;
 }
 .answer-herder > div{
     margin-left: 30px;
 }
 .answer-herder > div>div{
     margin-top: 20px;
 }
.answer-herder-number{
    padding-left: 30px;
    border-left: #8c939d 1px solid;
}
 .comment-dialog  .el-dialog__header{
     padding: 0;
 }
 .comment-dialog .el-dialog__body{
     padding-top: 20px;
 }
 .answer-dialog  .el-dialog__header{
     padding: 0;
 }
 .answer-dialog .el-dialog__body{
     padding-top: 20px;
 }

 .answer-table{
     margin-top: 25px;
 }
.answer-table-sort{
    margin-left: 30px;
    margin-bottom: 10px;
}
 .answer-table th {
     background-color: rgba(197, 145, 234, 0.3) !important;
 }
</style>
