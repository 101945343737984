/*
	mutations 中的方法是不分组件的 , 假如你在 dialog_stroe.js 文件中的定义了
	switch_dialog 方法 , 在其他文件中的一个 switch_dialog 方法 , 那么
	$store.commit('switch_dialog') 会执行所有的 switch_dialog 方法。
*/
export default {
    state:{//state
        show:false,
        dialogData: []
    },
    /*
    $store.getters.not_show 的值是不能直接修改的 , 需要对应的 state 发生变化才能修改。
    */
    getters:{ // 相当于vue中的计算conputed方法
        // not_show(state){//这里的state对应着上面这个state
        //     return !state.show;
        // }
    },
    mutations:{
        // switch_dialog(state,data){//这里的state对应着上面这个state
        //     state.show = data
        //     //你还可以在这里执行其他的操作改变state
        // },
        // updateDialogData(state){
        //     state.dialogData = ''
        // }
    },
    actions:{
        switch_dialog(context,data){//这里的context和我们使用的$store拥有相同的对象和方法
            context.commit('switch_dialog',data);
            //你还可以在这里触发其他的mutations方法
        },
        updateDialogData (context,data) {
            context.commit('updateDialogData',data);
            console.log(data)
        }
    }
}