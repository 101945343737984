<template>
    <div>
        <div class="crumbs" id="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>名片管理</el-breadcrumb-item>
                <!-- <el-breadcrumb-item>菜单管理</el-breadcrumb-item> -->
            </el-breadcrumb>
        </div>
        <!-- <iframe src="https://vzan.com/live/tvchat-711295?shareuid=85524861&v=636632063337820220&from=singlemessage&isappinstalled=0&stamp=636632064091926103&shareuserId=85524861" style="width: 500px;height: 500px"></iframe> -->
        <div class="cardHeadOperate">
            <div class="search" style="display: flex;justify-content: space-between;align-items: center;">
                <el-input placeholder="请输入搜索内容"
                          v-model="searchStr">
                </el-input>
                <i slot="suffix" class="el-input__icon el-icon-search timeSearchButton" @click="handleSearchClick"></i>
            </div>
            <div class="timepicker">
                <el-date-picker
                    id="timepicker" v-model="searchTime" align="right" type="date" placeholder="选择日期"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions1">
                </el-date-picker>
                <i slot="suffix" class="el-input__icon el-icon-search timeSearchButton" @click="handleTimeClick"></i>
            </div>
            <div class="operatebutton">
                <el-button type="success" @click="exportCard" round>导出列表</el-button>
                <el-button type="danger" @click="deleteCard" round>删除名片</el-button>
            </div>
        </div>
        <el-table
            :data="cardList"
            style="width: 100%" ref="multiSelect" @selection-change="handleSelectionChange">
            <el-table-column type="expand">
                <template slot-scope="props">
                    <el-form class="demo-table-expand">
                        <el-form-item label=" ">
                            <img :src="props.row.cardHead" style="width: 80px;height:80px;border-radius: 40px;">
                        </el-form-item>
                        <el-form-item label="姓名：">
                            <span>{{ props.row.realName }}</span>
                        </el-form-item>
                        <el-form-item label="公司：">
                            <span>{{ props.row.company }}</span>
                        </el-form-item>
                        <el-form-item label="所在地区：">
                            <span>{{ props.row.city }}</span>
                        </el-form-item>
                        <el-form-item label="职位：">
                            <span>{{ props.row.jobPosition }}</span>
                        </el-form-item>
                        <el-form-item label="手机号">
                            <span>{{ props.row.mobile }}</span>
                        </el-form-item>
                        <el-form-item label="电子邮箱">
                            <span>{{ props.row.email }}</span>
                        </el-form-item>
                        <el-form-item label="微信：">
                            <span>{{ props.row.weixin }}</span>
                        </el-form-item>
                        <el-form-item label="模板编号：">
                            <span>{{ props.row.templateId }}</span>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                label="真实姓名"
                prop="realName">
            </el-table-column>

            <el-table-column
                label="公司"
                prop="company">
            </el-table-column>

            <el-table-column
                label="所在地区"
                prop="city">
            </el-table-column>

            <el-table-column
                label="职位"
                prop="jobPosition">
            </el-table-column>

            <el-table-column
                label="创建者"
                prop="createName">
            </el-table-column>

            <el-table-column
                label="创建时间"
                prop="createTime">
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
    </div>
</template>


<script>

    // import bus from '../common/bus';
    export default {
        data : function () {
            return {
                total : 0, page : 1, size : 20, currentPage : 1, // 选择数组
                selectList : [], // 数据列表
                cardList : [], searchStr : "", searchTime : "", searchStatus : false, pickerOptions1 : {
                    disabledDate ( time ) {
                        return time.getTime () > Date.now ()
                    }, shortcuts : [ {
                        text : "今天", onClick ( picker ) {
                            picker.$emit ( "pick", new Date () )
                        }
                    }, {
                        text : "昨天", onClick ( picker ) {
                            const date = new Date ()
                            date.setTime ( date.getTime () - 3600 * 1000 * 24 )
                            picker.$emit ( "pick", date )
                        }
                    }, {
                        text : "一周前", onClick ( picker ) {
                            const date = new Date ()
                            date.setTime ( date.getTime () - 3600 * 1000 * 24 * 7 )
                            picker.$emit ( "pick", date )
                        }
                    } ]
                }
            }
        }, components : {}, watch : {
            selectList : function () {
            }
        }, created () {
            this.getCardList ()
        }, mounted () {
        }, methods : {
            handleSizeChange ( val ) {
                this.getCardList ( this.searchStr, this.searchTime, this.page, val )
            }, handleCurrentChange ( val ) {
                this.getCardList ( this.searchStr, this.searchTime, val, this.size )
            }, handleTimeClick () {
                this.getCardList ( this.searchStr, this.searchTime, 1, 20 )
            }, handleSearchClick () {
                this.getCardList ( this.searchStr, this.searchTime, 1, 20 )
            }, searchStrFun () {
            }, exportCard () {
                var idlist = []
                for ( var item in this.selectList ) {
                    if ( item == this.selectList.length - 1 ) {
                        idlist = idlist + this.selectList[ item ].id
                    } else {
                        idlist = idlist + this.selectList[ item ].id + ","
                    }
                }
                this.$export ( {
                    url : this.$api + "/chigua/admin/card/export", data : {
                        query : this.searchStr, createTime : this.searchTime, ids : idlist
                    }
                } )
            }, deleteCard () {
                this.$confirm ( "此操作将永久删除该文件, 是否继续?", "提示", {
                    confirmButtonText : "确定", cancelButtonText : "取消", type : "warning"
                } )
                .then ( () => {
                    let idlist = ""
                    for ( var item in this.selectList ) {
                        if ( item == this.selectList.length - 1 ) {
                            idlist = idlist + this.selectList[ item ].id
                        } else {
                            idlist = idlist + this.selectList[ item ].id + ","
                        }
                    }
                    this.$http ( {
                        url : this.$api + "/chigua/admin/card/delete",
                        method : "post",
                        data : this.$qs.stringify ( { ids : idlist } )
                    } )
                    .then ( response => {
                        if ( response.status === 200 ) {
                            if ( response.data.errno === 0 ) {
                                this.$message.success ( "删除名片成功！" )
                                this.getCardList ()
                            }
                        }
                    } )
                    .catch ( error => {
                        this.$message.error ( "删除名片失败！" )
                    } )
                } )
                .catch ( () => {
                    this.$message ( {
                        type : "info", message : "已取消删除"
                    } )
                } )
            }, handleSelectionChange ( val ) {
                this.selectList = val
            }, getCardList ( query = "", ct = "", page = 1, limit = 10 ) {
                this.$http ( {
                    url : this.$api + "/chigua/admin/card/page", method : "post", data : this.$qs.stringify ( {
                        query : query, createTime : ct, page : page, limit : limit
                    } )
                } )
                .then ( response => {
                    if ( response.status === 200 ) {
                        if ( response.data.errno === 0 ) {
                            this.cardList = response.data.data.records
                            this.currrent = response.data.data.current
                            this.size = response.data.data.size
                            this.total = response.data.data.total
                            this.searchStatus = true
                        }
                    }
                } )
                .catch ( error => {
                    this.$message.error ( "加载名片失败！" )
                } )
            }
        }
    }
</script>
<style>
    .demo-table-expand label {
        color: #99a9bf;
        width: 90px;
    }

    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
        display: block;
        float: left;
    }

    .demo-table-expand {
        padding: 20px 50px;
        box-sizing: border-box;
        font-size: 0;
    }

    .cardHeadOperate {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .operatebutton button {
        font-size: 12px;
    }

    .timeSearchButton {
        color: #252f42;
        cursor: pointer;
    }
</style>
<style scoped>
</style>
