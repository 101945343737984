<template>
    <div>
        <!--尾缀添加-->
        <meta name="referrer" content="never">
        <el-table
            :data="tableData.data"
            style="width: 100%"
            :row-class-name="tableRowClassName"
            :stripe="true">
            <el-table-column
                prop="nickname"
                label="用户">
            </el-table-column>
            <el-table-column
                prop="last_login_time"
                label="最后登录时间">
            </el-table-column>
            <el-table-column
                label="消息总条数">
                <template slot-scope="scope">
                    {{scope.row.allCount}}
                </template>
            </el-table-column>
            <el-table-column
                label="未读消息条数">
                <template slot-scope="scope">
                    <el-badge :value="scope.row.unReadCount" :max="10" class="item">
                    </el-badge>
                </template>
            </el-table-column>
            <el-table-column
                label="最新消息">
                <template slot-scope="scope" v-if="scope.row.lastMsg.msg_type !== 'event' && scope.row.lastMsg.msg_type !== 'chat'">
                    <p v-if="scope.row.lastMsg.msg_type === 'text'">{{scope.row.lastMsg.content}}</p>
                    <img class="item_image" v-if="scope.row.lastMsg.msg_type === 'image'" :src="scope.row.lastMsg.pic_url" alt="">
                    <p class="add_time_right">{{scope.row.lastMsg.add_time}}</p>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                tableData: {}
            }
        },
        mounted () {
            this.getUnRead()
        },
        methods: {
            getUnRead () {
              this.$http.post(this.$api + '/admin/customService/index', {
                  page: 1,
                  size: 20
              })
                  .then(res => {
                      if (res.data.errno === 0) {
                          this.tableData = res.data.data
                      }
                  })
            },
            tableRowClassName({row}) {
                if (row.unReadCount > 5) {
                    return 'warning-row';
                }
                return '';
            }
        },
    }
</script>
<style>
    .item_image{
        width: 50px;
        height: 50px;
    }
    .add_time_right{
        text-align: right;
    }
    .el-table__row {
        cursor: pointer;
    }
    .el-table .warning-row {
        background: oldlace;
    }
    .el-table .success-row {
        background: #f0f9eb;
    }
</style>
