<template>
    <div class="mike_con">

        <el-form :model="ruleForm"  ref="ruleForm" label-width="150px" class="form_con">

            <!--活动信息-->
            <div class="mike_part left">
                <el-alert title="活动信息" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--优惠券名称-->
                <el-form-item label="促销标题" prop="name">
                    <el-input v-model="ruleForm.sell_title" placeholder="促销大标题，显示在促销左上方/促销原价上方"></el-input>
                </el-form-item>
                <!--优惠券名称-->
                <el-form-item label="促销副标题" prop="name">
                    <el-input v-model="ruleForm.sell_second_title" placeholder="促销副标题，显示在促销右上方"></el-input>
                </el-form-item>

                <!--促销备注-->
                <el-form-item label="促销备注" prop="name_desc">
                    <el-input v-model="ruleForm.sell_desc" placeholder="备用字段，促销的备注，显示在促销的最下方"></el-input>
                </el-form-item>



                <el-form-item label="促销前缀信息" prop="sell_coupon_price_desc">
                    <el-input v-model="ruleForm.sell_coupon_price_desc" placeholder="促销前缀信息，显示在促销价格前面"></el-input>
                </el-form-item>

            </div>

            <div class="mike_part left">
                <el-alert title="促销外观设置" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--促销整体外观-->

                <el-form-item label="促销整体外观" prop="preferential_cash">
                    <el-input v-model="ruleForm.sell_box_style" placeholder="促销整体外观"></el-input>
                </el-form-item>

                <!--促销的背景外观-->
                <el-form-item label="促销的背景外观" prop="limit_pay_num">
                    <el-input v-model="ruleForm.sell_bg_style" placeholder="促销的背景外观"></el-input>
                </el-form-item>
            </div>


            <!--时间选项-->
            <div class="mike_part left">
                <el-alert title="促销时间" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--生效日期-过期日期-->
                <el-form-item label="促销开始时间" prop="publish_date">
                    <el-date-picker
                        v-model="ruleForm.sell_start_time_DATE"
                        range-separator="至"
                        start-placeholder="促销开始时间"
                        end-placeholder="促销结束日期"
                        align="right">
                    </el-date-picker>
                </el-form-item>
            </div>
            <div class="width100">
                <div class=" width100">
                    <div class="padding15 width100">
                        <el-alert title="选择适用的商品" type="info" :closable="false" class="coupon_title"></el-alert>
                        <el-input placeholder="请输入商品名查询" v-model="searchStr" @keyup.enter.native="getPaperList(1, 15)"
                                  style="width: 500px">
                            <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="getPaperList(1, 15)"></i>
                        </el-input>
                        <el-table
                            stripe
                            ref="addGoodsPromotion"
                            :data="tableData.data"
                            highlight-current-row
                            @selection-change="handleSelectionChange"
                            style="width: 100%;cursor: pointer;">
                            <el-table-column type="selection" width="55"></el-table-column>
                            <el-table-column
                                property="date"
                                label="商品标题"
                                width="">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                property="name"
                                label="日常售价">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px"> {{scope.row.retail_price}}（原价） x {{scope.row.discount}} (折扣)=
                                        <span class="font-size-15 fontWeight color_eb3021">￥ {{(scope.row.retail_price * scope.row.discount).toFixed(2) }}</span>
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="paginaion" style="text-align: center;margin-top: 1em">
                            <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="tableData.currentPage"
                                :page-sizes="[10, 20, 30, 40]"
                                :page-size="tableData.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="tableData.count">
                            </el-pagination>
                        </div>
                    </div>
                    <div class="padding15 width100">
                        <el-alert title="已选择的商品" type="info" :closable="false" class="coupon_title"></el-alert>
                        <el-table
                            ref="selectedGoodsPromotion"
                            :data="selectTableData"
                            highlight-current-row
                            style="width: 100%;cursor: pointer;" stripe default-expand-all
                            :row-key="getRowKeys" :expand-row-keys="expandRowKeys">
                            <el-table-column type="expand">
                                <template slot-scope="scope">
                                    <el-form  label-position="left" inline class="is-always-shadow demo-table-expand spec_box" v-for="sku in scope.row.spec_list" :key="sku.id">

                                        <el-alert  type="info" :closable="false" class="coupon_title disflex_between_center" >
                                            <div>
                                                {{sku.goods_specification[0].keyName}}: {{sku.goods_specification[0].valueName}}
                                            </div>
                                           <div>
                                               <el-switch
                                                   v-model="sku.is_choose"
                                                   @change="checkBoxChange($event, scope.row, sku)"
                                                   active-color="#13ce66"
                                                   inactive-color="#ff4949"
                                               >
                                               </el-switch>
                                           </div>
                                        </el-alert>

                                        <el-form-item label="促销原价" prop="sell_origin_price">
                                            <el-input :value="sku.sell_origin_price" @input="promotionInputChange($event, 'sell_origin_price', scope.row, sku)" placeholder="促销原始售价"></el-input>
                                        </el-form-item>

                                        <el-form-item label="日常售价（折后）">
                                            <span style="margin-left: 10px"> {{sku.selling_price}}（原价） x {{sku.discount}} (折扣)=
                                                <span class="font-size-15 fontWeight color_eb3021">￥ {{(sku.selling_price * sku.discount).toFixed(2) }}</span>
                                            </span>
                                        </el-form-item>

                                        <el-form-item label="促销后的价格" prop="sell_coupon_price">
                                            <el-input :value="sku.sell_coupon_price" @input="promotionInputChange($event, 'sell_coupon_price',scope.row, sku)" placeholder="促销后的价格（比日常售价低）"></el-input>
                                        </el-form-item>
                                    </el-form>
                                </template>
                            </el-table-column>
                            <el-table-column
                                property="date"
                                label="商品标题"
                                width="">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                property="name"
                                label="日常售价"
                                width="120">
                                <template slot-scope="scope">
                                    <span style="margin-left: 10px"> {{scope.row.retail_price}}（原价） x {{scope.row.discount}} (折扣)=
                                        <span class="font-size-15 fontWeight color_eb3021">￥ {{(scope.row.retail_price * scope.row.discount).toFixed(2) }}</span>
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                property="name"
                                label="删除"
                                width="120">
                                <template slot-scope="scope">
                                <el-button type="danger" @click="deleteSelection(scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>

        </el-form>

        <div class="padding15 text-align-right">
            <el-button type="primary" @click="submitPromotion">立即创建</el-button>
        </div>
    </div>
</template>

<script>
    import util from '@/utils/util'
    import _ from 'lodash'
    export default {
        name: "addPromotion",
        data() {
            return {
                expandRowKeys: [], // 默认展开的行id
                tableData: {},
                selectTableData: [],
                searchStr: '',
                ruleForm: {
                    'sell_desc': '',
                    'sell_title': '',
                    'sell_second_title': '',
                    'sell_start_time_DATE': '',
                    'sell_origin_price': '', // 原始售价，可以是折后价
                    'sell_retail_price': '', // 日常售价
                    'sell_coupon_price': '', // 真正的促销价格
                    'sell_coupon_price_desc': '',
                    'sell_bg_style':"background-image: url('https://oss.mxclass.com/cathouse_pro/shuang11/promotionBG.jpg');background-position: center;background-size: cover;color: #ffffff;",
                    'sell_box_style': 'color: #ffffff;',
                    'goods_id': '',
                    'sell_add_time': parseInt(new Date().getTime()/1000)
                },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                selectGoodsList: []
            }
        },
        mounted () {
            this.getPaperList();
        },
        watch: {
        },
        computed: {
        },
        methods: {
            promotionInputChange (value, key, row, sku) {
                this.selectTableData = this.selectTableData.map(function (goods) {
                    if (goods.id === row.id) {
                        goods.spec_list = goods.spec_list.map(function (origin_sku) {
                            if (origin_sku.id === sku.id) {
                                origin_sku[key] = value;
                            }
                            return origin_sku;
                        });
                    }
                    return goods
                });

                let arr = this.expandRowKeys.push(row.id);
                this.expandRowKeys = Array.from(new Set(arr))
            },
            getRowKeys (row) {
                return row.id
            },
            checkBoxChange (event, row, sku) {
                this.selectTableData = this.selectTableData.map(function (goods) {
                   if (goods.id === row.id) {
                       goods.spec_list = goods.spec_list.map(function (origin_sku) {
                           if (origin_sku.id === sku.id) {
                                origin_sku.is_choose = event;
                           }
                           return origin_sku;
                       });
                   }
                    return goods
                });
                let arr = this.expandRowKeys.push(row.id);
                this.expandRowKeys = Array.from(new Set(arr))
            },
            deleteSelection (row) {
                this.selectTableData = _.pull(this.selectTableData, row);
                this.$refs.addGoodsPromotion.toggleRowSelection(row);
            },
            // 点击选择商品
            handleSelectionChange (val) {
                const that = this;
                // val = val.map(function (goods) {
                //     // goods.sell_origin_price = goods.retail_price;
                //     // goods.sell_retail_price = goods.retail_price;
                //     // goods.sell_coupon_price = (goods.retail_price * goods.discount).toFixed(2);
                //     return goods
                // });
                that.selectTableData = Array.from(new Set(that.selectTableData.concat(val)))
            },
            // 获取商品数组
            getPaperList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/goods/index',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 15,
                        name: this.searchStr
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;

                        this.tableData.data = this.tableData.data.map(function (goods) {
                            if (goods.spec_list && goods.spec_list.length > 0) {
                                // 格式化产品sku数组
                                goods.spec_list = goods.spec_list.map(function (sku) {
                                    if (sku) {
                                        sku.is_choose = false;
                                        sku.sell_origin_price = sku.selling_price;
                                        sku.sell_coupon_price = (sku.selling_price * sku.discount).toFixed(2);
                                        try {
                                            sku.goods_specification = JSON.parse(sku.goods_specification)
                                        } catch (e) {
                                            console.warn('商品sku格式化失败！')
                                        }
                                    }
                                    return sku;
                                });
                            }
                            return goods;
                        });
                        let that = this;
                        setTimeout(function () {
                            that.selectTableData.map(function (goods) {
                                that.$refs.addGoodsPromotion.toggleRowSelection(goods);
                            });
                        }, 100)
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                }).catch(e => {
                    loading.close()
                })
            },
            //
            submitPromotion() {
                let that = this;
                let pushGoodsList = [];
                this.selectTableData.map(function (goods) {
                   goods.spec_list = goods.spec_list.filter(function (sku) {
                      return sku.is_choose
                   }).map(function (sku) {
                       let newGoods = {
                           goods_id: goods.id,
                           goods_spec_id: sku.id,
                           sell_coupon_price: sku.sell_coupon_price,
                           sell_origin_price: sku.sell_origin_price,
                           sell_retail_price: sku.selling_price
                       };
                       pushGoodsList.push(newGoods);
                       return sku
                   });
                });

                // console.log(pushGoodsList)

                let submitGoods = pushGoodsList.map(function (goods) {
                    let goodsParam = {};
                    goodsParam.sell_start_time = parseInt(new Date(that.ruleForm.sell_start_time_DATE) / 1000);
                    goodsParam.goods_id = goods.goods_id;
                    goodsParam.goods_spec_id = goods.goods_spec_id;
                    goodsParam.sell_coupon_price = goods.sell_coupon_price;
                    goodsParam.sell_origin_price = goods.sell_origin_price;
                    goodsParam.sell_retail_price = goods.sell_retail_price;

                    goodsParam.sell_desc = that.ruleForm.sell_desc;
                    goodsParam.sell_title = that.ruleForm.sell_title;
                    goodsParam.sell_second_title = that.ruleForm.sell_second_title;
                    goodsParam.sell_bg_style = that.ruleForm.sell_bg_style;
                    goodsParam.sell_coupon_price_desc = that.ruleForm.sell_coupon_price_desc;
                    goodsParam.sell_box_style = that.ruleForm.sell_box_style;
                    goodsParam.sell_add_time = that.ruleForm.sell_add_time;
                    return goodsParam
                });

                // console.log(submitGoods);
                // return

                this.$http({
                    url: this.$api + '/admin/goodsPromotion/autoSetGoodsPromotion',
                    method: 'post',
                    data: submitGoods
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('批量添加优惠券成功！');
                    }
                })
            },
            handleSizeChange(val) {
                this.getPaperList(this.tableData.currentPage || 1, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(val, this.tableData.pageSize || 10);
            },
        }
    }
</script>

<style lang="scss">
    .couponGoodsTransfer .el-transfer-panel {
        width: 40%;
    }
    .coupon_title {
        margin-bottom: 20px;
    }

    .form_con {
        display: flex;
        flex-wrap: wrap;
    }

    .mike_part {
        width: 100%;
        &.left {
            margin-right: 100px;
        }
    }
    .spec_box {
        width: 30%; display: inline-block;border: 1px solid #eeeeee;padding: 5px;margin: 5px;
    }
</style>
