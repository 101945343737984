<template>
    <div>
        <div class="crumbs button-tool">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}个测试）</label>

        </div>
        <div class="button-tool">
            <div class="search-title">
                <el-input placeholder="搜索用户" v-model="searchTitle" @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>

        <el-table
            :data="tableData.data"
            style="width: 100%">

            <el-table-column
                label="用户" width="180">
                <template slot-scope="scope">
                    <div class="text-align-center">
                        <img @click="showImagePath = scope.row.avatar" class="width60px" :src="scope.row.avatar" alt="">
                        <h4>{{ scope.row.nickname }}</h4>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                label="宠物类型"
                width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.pets_type }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="准确度"
                width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.accuracy }}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="测试结果">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper" style="position: relative;">
                        <img @click="showImagePath = scope.row.test_img" v-if="scope.row.test_img" :src="scope.row.test_img" style="width: 60px;vertical-align: middle;">
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <preview-image v-if="showImagePath" :img-path="showImagePath"></preview-image>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import previewImage from '../../common/previewImage';

    export default {
        data: function () {
            return {
                tableData: [],
                searchTitle: '',
                showImagePath: ''
            }
        },
        components: {
            previewImage: previewImage
        },
        watch: {},
        created() {
            this.getQuestionList(1, 10);
        },
        mounted() {
            let that = this;
            bus.$on('closeImageHub', function (data) {
                that.showImagePath = ''
            })
        },
        methods: {
            handleSearchByTitle() {
                this.getQuestionList(this.currentPage, this.size, this.searchTitle)
            },
            handleSizeChange(val) {
                this.getQuestionList(this.currentPage, val, this.searchTitle)
            },
            handleCurrentChange(val) {
                this.getQuestionList(val, this.size, this.searchTitle)
            },
            getQuestionList(page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/userPublish/getAiTestList',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page,
                        size: length,
                        search: title
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style>
    .question-dialog .el-dialog__body {
        padding: 10px 35px 20px 40px !important;
    }

    .question-dialog .el-dialog__header {
        border-bottom: #6d6d9d 1px solid !important;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px !important;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped><
.tagSwitch {
    width: 100%;
    height: auto;
}

/
style >
