<template>
    <div>
        <i class="el-icon-setting guajieTool" @click="ToolClick" v-show="showToolBtn" @mousedown="move"></i>
        <div v-show="showTool" id="uploadTool" @mousedown="move" show-close=true>
            <i class="el-icon-close closeTool" @click="ToolClick"></i> <!--绑定按下事件-->
            <el-upload class="upload-demo"
            :action="$api + UPLOAD_FILE"
            :on-preview="handlePreview"
            :on-remove="handleRemove"multiple
            :limit="10"
            :on-exceed="handleExceed"
            :file-list="fileList"
            :headers="{ Auth_token }"
            :on-success="uploadSuccess"
            :before-upload="before_upload">
                <el-button size="big" style="width: 100%;" type="default">点此上传<i style="color: #a1a1a1;icon-size: 12px;">点击链接复制</i></el-button>
                  <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div> -->
            </el-upload>
        </div>

        <!-- <div style="text-align: center;position: relative;top: 50%;">功能正在开发，敬请期待</div> -->
    </div>
</template>

<script>
    import Clipboard from 'clipboard';
    export default {
        data: function(){
            return {
                positionX:0,
                showTool: false,
                showToolBtn: true,
                positionY:0,
                fileList: [],
                Auth_token:''
            }
        },
        mounted() {
            this.Auth_token = localStorage.getItem('auth_token')
            var clipboard = new Clipboard('a.el-upload-list__item-name');
            var _this = this
            clipboard.on('success', function (e) {
                _this.$message.success('复制成功')
            });
            clipboard.on('error', function (e) {
                _this.$message.error('复制失败')
            });
        },
        methods: {
            before_upload (file) {
                var type,element,uploadParamType
                if (file != null) {
                    uploadParamType = file.name.split('.') //分析链接
                    type = uploadParamType[uploadParamType.length-1].toLowerCase() // 获取上传文件格式
                }
                var it_can_continue = false
                // 上传之前判断上传文件类型是否符合要求
                switch(type){
                    case 'png':case "jpg":case 'jpeg':case "gif":
                        it_can_continue = true
                        break
                    case 'mp4':case "rm":case "rmvb":case "avi":case "mpg":case "mpeg":case "3gp":case "mkv":case "flv":case "ram":
                        it_can_continue = true
                        break
                    case 'mp3':
                        it_can_continue = true
                        break
                    default:
                        this.$message.error('仅支持上传文件格式：图片（png / jpg / jpeg / gif）、视频（mp4 / rm / rmvb / avi / mpg等）、 音频（mp3）')
                        it_can_continue = false
                        break
                }
                return it_can_continue
            },
            ToolClick () {
                this.showTool = !this.showTool
                this.showToolBtn = !this.showToolBtn
            },
            // 设置组件的复制状态
            uploadSuccess (data) {
                var list = document.querySelectorAll('.el-upload-list__item-name')
                var type,element,uploadParamType
                if(!data) return
                if (data.data != null) {
                    uploadParamType = data.data.split('.') //分析链接
                    type = uploadParamType[uploadParamType.length-1].toLowerCase() // 获取上传文件格式
                }
                var endtag = '"/>'
                switch(type){
                    case 'png':case "jpg":case 'jpeg':case "gif":
                        element = '<img src="'
                        break
                    case 'mp4':case "rm":case "rmvb":case "avi":case "mpg":case "mpeg":case "3gp":case "mkv":case "flv":case "ram":
                        element = '<video src="'
                        break
                    case 'mp3':
                        element = '<audio src="'
                        break
                    default:
                        element = '您上传的文件不符合发布要求！请上传jpg/png/jpeg/gif格式的图片、MP4/flv/rmvb/avi等格式的视频、MP3格式的音频。'
                        endtag = ''
                        break
                }
                for (var i = 0; i < list.length; i++) {
                    list[i].setAttribute('data-clipboard-action','copy')
                    var copytext = element + data.data + endtag
                    list[i].setAttribute('data-clipboard-text',copytext)
                }
            },
            handleRemove(file, fileList) {
                if(!file.response) return
                const url = file.response.data
                this.$http({
                    url: this.$api + '/api/oss/delete',
                    method: 'post',
                    data: this.$qs.stringify({url: url})
                }).then(res => {
                    this.$message.success('删除成功')
                }).catch()
            },
            handlePreview(file) {
                         ;
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 10 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            move(e){
                let odiv = e.target; //获取目标元素 //算出鼠标相对元素的位置
                let disX = e.clientX - odiv.offsetLeft;
                let disY = e.clientY - odiv.offsetTop;
                document.onmousemove = (e)=>{ //鼠标按下并移动的事件 //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let left = e.clientX - disX;
                    let top = e.clientY - disY; //绑定元素位置到positionX和positionY上面
                    this.positionX = top;
                    this.positionY = left; //移动当前元素
                    odiv.style.left = left + 'px';
                    odiv.style.top = top + 'px';
                };
                document.onmouseup = (e) => {
                     document.onmousemove = null;
                     document.onmouseup = null;
                 };
            },
        }
    }
</script>
<style>
    .guajieTool{
    }
    .closeTool{
        position: absolute;
        /* right: 36px; */
        top: 3px;
        right: 5px;
        /* float: right; */
        display: inline;
        width: 1em;
        cursor: pointer;
    }
    .upload-demo .el-upload--text{
        display: inline !important;
        text-align: center;
        cursor: pointer;
        outline: none;
    }
    #uploadTool{
        border-radius: 10px;
        border: 0.5px solid rgb(221, 221, 221);
        cursor: move;
        padding: 20px;
        position: absolute; /*定位*/
        top: 50%;
        right: 0;
        width: 200px;
        min-height: 200px;
        background-image: url('../../assets/tool.png');
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 99999;
    }
    .guajieTool{
        cursor: pointer;
        position: absolute;
        top: 60%;
        right: 70px;
        background: transparent;
        z-index: 99999;
        font-size: 50px;
        max-width: 50px !important;
        max-height: 50px !important;
    }
    @-webkit-keyframes rotation{
        from {-webkit-transform: rotate(0deg);}
        to {-webkit-transform: rotate(360deg);}
    }

    .guajieTool{
        -webkit-transform: rotate(360deg);
        animation: rotation 3s linear infinite;
        -moz-animation: rotation 3s linear infinite;
        -webkit-animation: rotation 3s linear infinite;
        -o-animation: rotation 3s linear infinite;
    }
</style>
