<template>
    <div class="disflex_around_center">
        <div>
            <h1>生成小程序码</h1>
            <div style="padding: 30px 15px;box-sizing: border-box">
                <el-form ref="form" :model="codeParam" label-width="150px">
                    <el-form-item label="页面地址">
                        <el-input v-model="codeParam.page" placeholder="例如首页：pages/index/index"></el-input>
                        <div>
                        <span class="color_eb3021">常用页面路径参考：
                            <span class="color_09bbe7 cursor_pointer" v-if="!is_show_example" @click="is_show_example = !is_show_example">展开</span>
                            <span class="color_09bb07 cursor_pointer" v-if="is_show_example" @click="is_show_example = !is_show_example">隐藏</span></span>
                            <p v-if="is_show_example" class="color_666"
                               v-for="(item, index) in example"
                               :key="index"
                               @click="codeParam.page = item.path"
                            >{{item.name}}: {{item.path}} <span class="color_09bbe7 cursor_pointer" @click="codeParam.page = item.path"> 选择</span>
                            </p>
                        </div>
                    </el-form-item>
                    <el-form-item label="携带参数" name="attribute" prop="attribute">
                        <div v-for="(item, curIndex) in codeParam.attribute" :key="curIndex">
                            <el-input style="width:200px;margin-right:5px;margin-bottom:5px;" placeholder="请输入参数名"
                                      v-model="item.key"></el-input>
                            <el-input style="width:200px" placeholder="请输入参数值" v-model="item.value"></el-input>
                            <el-button size="small" type="danger" @click="handleRemoveAttribute(curIndex)">删除</el-button>
                        </div>
                        <p class="color_eb3021">1、参数的key请尽量简写，例如name 可以简写n,参数名不能重复！</p>
                        <p class="color_eb3021">2、请务必添加必填参数，防止小程序码因为缺失参数而无法使用</p>
                        <p class="color_eb3021">3、商品详情页、专题文章详情页请务必携带商品或者文章的id</p>
                        <p class="color_eb3021">商品详情页示例：id（参数名） = 10000066（参数值）</p>
                        <el-button size="small" type="primary" @click="handleAddAttribute()">新增参数</el-button>
                    </el-form-item>
                    <el-form-item label="是否透明底色">
                        <el-switch v-model="codeParam.is_hyaline"></el-switch>
                    </el-form-item>
                </el-form>
            </div>
            <div class="text-align-right">
                <el-button type="primary" @click="generateCode">生成二维码</el-button>
            </div>
            <div class="padding15" v-if="result">
                <h1 >生成的二维码：</h1>
                <img :src="result" alt="小程序二维码">
            </div>
        </div>
        <goods-list v-if="showGoodsList"></goods-list>
    </div>

</template>

<script>
    import goodsList from '../goods/shortGoodsList'
    export default {
        name: "generateQrCode",
        data () {
            return {
                codeParam: {
                    page: '', // 生成小程序码的页面
                    is_hyaline: false, // 是否生成透明二维码
                    attribute: []
                },
                showGoodsList: false, // 是否显示商品的列表
                result: '', // 生成的二维码地址
                example: [
                    {name: '首页', path: 'pages/index/index'},
                    {name: '吸猫区', path: 'pages/topic/topic'},
                    {name: 'AI测猫', path: 'pages/activity/distinguishCats'},
                    {name: '首页的分类页面', path: 'pages/category/category'},
                    {name: '某条专题的地址', path: 'pages/topic/topicDetail'},
                    {name: '登陆页面', path: 'pages/login/login'},
                    {name: '试用装页面', path: 'pages/activity/index'},
                    {name: '商品的详情页', path: 'pages/goods/goods'},
                ],
                is_show_example: false
            }
        },
        components: {
            goodsList: goodsList
        },
        watch: {
            codeParam: {
                handler (newv) {
                    if (newv.page === 'pages/goods/goods') {
                        this.showGoodsList = true
                    } else {
                        this.showGoodsList = false
                    }
                },
                deep: true
            }
        },
        mounted () {

        },
        methods: {
            // 删除属性
            handleRemoveAttribute(index) {
                if (index >= 0 && index < this.codeParam.attribute.length) {
                    this.codeParam.attribute.splice(index, 1);
                }
            },
            // 新增一条属性
            handleAddAttribute() {
                let obj = {"key": "", "value": ""};
                this.codeParam.attribute.push(obj);
            },
            // 生成小程序二维码
            generateCode () {
                if (!this.codeParam) {
                    return this.$message.error('请输入正确的页面地址');
                }
                if (this.codeParam.page.indexOf('pages/') === -1) {
                    return this.$message.error('请输入正确的页面地址');
                }
                if (this.codeParam.page.length <= 10) {
                    return this.$message.error('地址长度有误，请重新输入！');
                }
                this.result = '';
                const loading = this.$loading({
                    lock: true,
                    text: '正在生成~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/operationManagement/generateCode',
                    method: 'post',
                    data: this.codeParam
                }).then(response => {
                    // this.loading = false
                    if (response.data.errno === 0) {
                        this.$message.success('添加评价成功！');
                        this.result = response.data.data
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                    loading.close()
                }).catch(err => {
                    loading.close()
                })
            }
        }
    }
</script>

<style scoped>

</style>
