<template>
    <el-dialog title="设置秒杀" :visible.sync="openStatus" width="70%" @close="closeDialog">
        <p class="font-size-13 color_eb3021 padding15">1、秒杀商品保存后即刻生效!</p>
        <p class="font-size-13 color_eb3021 padding15">2、多SKU商品只能设置一个秒杀，且多SKU商品必须在商品标题中追加sku名称</p>
        <div class="disflex_flex_start">
            <el-form style="margin-right: 15px;width: 40%;" label-width="120px" ref="spike">
                <el-form-item label="商品主图">
                    <upload-image title="商品显示主图【必填】" list-type="picture-card" v-model="spike.list_pic_url" style="margin-right: 50px">
                        <div style="text-align: center;">4：3</div>
                    </upload-image>
                </el-form-item>
                <el-form-item label="秒杀标题">
                    <el-input v-model="spike.title"
                              maxlength="6"
                              show-word-limit placeholder="秒杀标题"></el-input>
                    <p class="labelText">限制6字内</p>
                </el-form-item>
                <el-form-item label="商品标题">
                    <el-input v-model="spike.goods_name" type="textarea" placeholder="商品标题"></el-input>
                    <p class="labelText">如果是多规格商品，请必须在标题中增加sku名称</p>
                </el-form-item>
                <el-form-item label="参加秒杀的规格">
                    <span v-for="(item, index) in sku" :key="index" style="display: inline-block;">
                        <span   @click="setGoodsName(item)"
                           :style="spike.sku === (`【${item.skuData.list.join(',')}】`)? 'background: #eb3021;':'background: #00a0e9;'"
                           style="display: inline-block;background-color: #00a0e9;color: #ffffff;border-radius: 50px;padding: 1px 10px;margin-top: 5px;cursor: pointer;font-size: 12px;">
                            <span >{{item.skuData.list.join(',')}}</span>/
                        </span>
                    </span>
                    <p class="labelText">SKU会追加在商品标题后</p>
                </el-form-item>
                <el-form-item label="活动标题">
                    <el-input v-model="spike.promotion_title"
                              maxlength="10"
                              show-word-limit placeholder="活动标题"></el-input>
                    <p class="labelText">限制10字内</p>
                </el-form-item>
                <el-form-item label="日常零售价">
                    <el-input v-model="spike.spike_origin_price"  type="number" placeholder="日常零售价"></el-input>
                </el-form-item>
                <el-form-item label="参加秒杀价格">
                    <el-input v-model="spike.spike_price"  type="number" placeholder="参加秒杀价格"></el-input>
                    <p class="labelText">秒杀价格不得低于日常零售价</p>
                </el-form-item>
                <el-form-item label="秒杀库存">
                    <el-input v-model="spike.storage" type="number" placeholder="秒杀库存"></el-input>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker
                        v-model="spike.start_time"
                        type="datetime"
                        placeholder="选择开始日期时间"
                        align="right"
                        value-format="timestamp"
                        format="yyyy-MM-dd HH:mm"
                        @change="changeStartTime"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                        v-model="spike.end_time"
                        type="datetime"
                        value-format="timestamp"
                        format="yyyy-MM-dd HH:mm"
                        @change="changeEndTime"
                        placeholder="选择结束日期时间"
                        align="right"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div class="width50" >
               <div class="padding15">
                   <span>商品详情</span>
                   <el-button type="primary" @click="showPreviewPhone = !showPreviewPhone">
                       {{showPreviewPhone? '关闭预览': '预览详情'}}
                   </el-button>
               </div>
                <div style="min-height: 600px;height: 100vh;overflow: scroll;min-width: 375px;">
                    <ueditor class="editBox" v-model="spike.desc"></ueditor>
                </div>
            </div>
        </div>
        <div slot="footer">
            <el-button type="info" @click="closeDialog">取消</el-button>
            <el-button type="danger" @click="deleteSpike" v-if="spike.id">删除</el-button>
            <el-button type="primary" @click="saveSpike">生成秒杀</el-button>
        </div>
        <phone-preview style="z-index: 9999;" :html="spike.desc" v-model="showPreviewPhone"></phone-preview>
    </el-dialog>
</template>

<script>
    import uploadImage from '../../common/uploadImage'
    import ueditor from '../../common/editor'
    import phonePreview from '../../common/phonePreview'

    export default {
        name: "spikeGoods",
        components: {
            uploadImage,ueditor,phonePreview
        },
        props: {
            goods: {
                type: Object,
                default() {
                    return {}
                }
            },
            goodsId: {
              type: Number,
              default: 0
            },
            isOpen: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            openStatus: {
                get() {
                    return this.isOpen
                },
                set(){}
            },
            sku() {
                if (this.goods.SKU && this.goods.SKU.length > 0) {
                    return this.goods.SKU.map(item => {
                        item.GS = JSON.parse(item.goods_specification);
                        item.skuData = {
                            list: [],
                            price: item.selling_price * item.discount
                        };
                        const attr = item.GS.map(param => {
                            return `${param.keyName}: ${param.valueName}`
                        });
                        item.skuData.list = attr;
                        return item
                    })

                } else {
                    return [
                        {
                            skuData: {
                                list: [],
                                price: 9999
                            }
                        }
                    ]
                }
            }
        },
        mounted() {
            this.spike.goods_id = this.goods.id;
            this.spike.storage = this.goods.goods_number;
            this.spike.category_id = this.goods.category_id;
            this.spike.desc = this.goods.spikeData.id? this.goods.spikeData.desc:this.goods.goods_desc;
            this.spike.title = this.goods.spikeData.id? this.goods.spikeData.title:'';
            this.spike.sku = this.goods.spikeData.id? this.goods.spikeData.sku:'';

            this.spike.goods_name = this.goods.spikeData.id? this.goods.spikeData.goods_name:this.goods.name;

            if (this.goods.spikeData.id && this.goods.spikeData.list_pic_url && this.goods.spikeData.list_pic_url.indexOf('?x-oss-process=style') !== -1) {
                this.spike.list_pic_url =  this.goods.spikeData.list_pic_url.split('?x-oss-process=style')[0]
            } else {
                this.spike.list_pic_url =  this.goods.list_pic_url
            }
            this.spike.spike_origin_price = this.goods.spikeData.id? (this.goods.spikeData.spike_origin_price/100).toFixed(2):this.goods.retail_price;
            this.spike.spike_price = this.goods.spikeData.id? (this.goods.spikeData.spike_price/100).toFixed(2):this.goods.retail_price;
            this.spike.promotion_title = this.goods.spikeData.id? this.goods.spikeData.promotion_title:'';
            this.spike.id = this.goods.spikeData.id? this.goods.spikeData.id:'';
        },
        data() {
            return {
                showPreviewPhone: false, // 是否显示预览
                pickerOptions: {
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    },{
                        text: '明天',
                        onClick(picker) {
                            picker.$emit('pick', new Date().getTime() + 3600 * 1000 * 24);
                        }
                    },
                        {
                            text: '2天后',
                            onClick(picker) {
                                picker.$emit('pick', new Date().getTime() + 3600 * 1000 * 24 * 2);
                            }
                        },{
                            text: '3天后',
                            onClick(picker) {
                                picker.$emit('pick', new Date().getTime() + 3600 * 1000 * 24 * 3);
                            }
                        },{
                            text: '5天后',
                            onClick(picker) {
                                picker.$emit('pick', new Date().getTime() + 3600 * 1000 * 24 * 5);
                            }
                        },{
                            text: '7天后',
                            onClick(picker) {
                                picker.$emit('pick', new Date().getTime() + 3600 * 1000 * 24 * 7);
                            }
                        },{
                            text: '10天后',
                            onClick(picker) {
                                picker.$emit('pick', new Date().getTime() + 3600 * 1000 * 24 * 10);
                            }
                        },{
                            text: '15天后',
                            onClick(picker) {
                                picker.$emit('pick', new Date().getTime() + 3600 * 1000 * 24 * 15);
                            }
                        },{
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }]
                },
                subscribe: {},
                spike: {
                    id: '', // 表中的id
                    sku: '', // 商品名称中新增的规则参数
                    title: '',  // 秒杀名称
                    goods_name: '', // 商品标题
                    promotion_title: '', // 活动标题
                    list_pic_url: '', // 商品主图
                    desc: '', // 商品详情
                    spike_price: 0 , // 商品秒杀价格， 单位：分
                    spike_origin_price: 0, // 商品秒杀的原价，单位：分
                    goods_id: 0,
                    start_time: new Date().getTime(),
                    end_time: new Date().getTime() + 3600 * 1000 * 24,
                    storage: 0,
                    category_id: 0
                }
            }
        },
        methods: {
            async deleteSpike() {
                await this.$http.post(this.$api + '/admin/spikeGoods/deleteSpike', {
                    id: this.spike.id
                })
                    .then(res => {
                        if (res.data.errno === 0) {
                            this.$message.success('删除是成功!!');
                            this.$emit('close')
                            this.$emit('refresh')
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
            },
            setGoodsName (item) {
                this.spike.sku = `【${item.skuData.list.join(',')}】`
                this.spike.spike_origin_price = item.skuData.price
            },
            changeStartTime() {
                if (this.spike.end_time && this.spike.start_time > this.spike.end_time) {
                    this.spike.start_time = null
                    this.$message.error('开始时间不能大于结束时间')
                    return
                }

                // 秒杀间隔时间必须大于等于12小时
                if (this.spike.end_time) {
                    const timeCount = this.spike.end_time - this.spike.start_time
                    if (timeCount < 3600 * 1000 * 12) {
                        this.$message.error('请注意！！！设置的秒杀时间小于12小时')
                    }

                    if (timeCount < 60 * 1000) {
                        this.spike.start_time = null
                        this.$message.error('请注意！！！设置的秒杀时间小于60秒')
                    }
                }
            },
            changeEndTime () {
                if (this.spike.start_time && this.spike.start_time > this.spike.end_time) {
                    this.spike.end_time = null
                    this.$message.error('结束时间不能大于开始时间')
                    return
                }

                // 秒杀间隔时间必须大于等于12小时
                if (this.spike.start_time) {
                    const timeCount = this.spike.end_time - this.spike.start_time
                    if (timeCount < 3600 * 1000 * 12) {
                        this.$message.error('请注意！！！设置的秒杀时间小于12小时')
                    }

                    if (timeCount < 60 * 1000) {
                        this.spike.end_time = null
                        this.$message.error('请注意！！！设置的秒杀时间小于60秒')
                    }
                }
            },
            closeDialog() {
              this.$emit('close')
            },
            async saveSpike() {
                const goodsName = this.spike.goods_name + this.spike.sku;
                const spike = this.$_.cloneDeep(this.spike);
                spike.goods_name = goodsName;
                await this.$http.post(this.$api + '/admin/spikeGoods/saveSpike', {
                    spike: spike
                })
                    .then(res => {
                        if (res.data.errno === 0) {
                            this.$message.success('设置秒杀成功!');
                            this.$emit('close')
                            this.$emit('refresh')
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
            }
        }
    }
</script>

<style scoped>

</style>
