<template>
    <div>
        <div class="crumbs" style="width: 100%;">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }} 单）</label>
        </div>
        <div class="button-tool">
            <div class="search-title" :style="$store.state.UA !== 'MOBILE'? '':'width: 100% !important;'">
                <el-input placeholder="请输入订单号" v-model="orderSnSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-input placeholder="请输入用户名" v-model="userNameSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-input placeholder="请输入电话号码" v-model="userPhoneSearchStr" @keyup.enter.native="handleSearchByTitle">
                </el-input>
                <el-button type="default" @click="handleSearchByTitle">搜索</el-button>
            </div>
        </div>

        <div class="tagSwitch">
            <el-tabs v-model="activeName" @tab-click="switchTag">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table
            :data="tableData.data"
            v-loading="!tableData.data"
            :row-class-name="tableRowClassName"
            style="width: 100%">

            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item>
                            <h1>订单号：{{ scope.row.order_sn }}</h1>
                            <p class="color_09bb07">订单生成平台：{{ scope.row.pay_way === 'app'? 'APP生成订单':scope.row.pay_way === 'mini'? "微信小程序生成订单":'未知渠道生成订单' }}</p>
                            <p class="color_09bb07">支付渠道：{{ scope.row.pay_name }}</p>
                            <p class="color_09bb07">支付ID：{{ scope.row.pay_id }}</p>
                            <el-table :data="scope.row.goodList" style="width: 800px"
                                      border
                                      show-summary
                                      :summary-method="getSummaries"
                                      sum-text="总计需支付价格">
                                <el-table-column label="商品名称">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <img :src="scope2.row.list_pic_url" style="width: 40px;vertical-align: middle;">
                                            <span style="margin-left: 10px">{{ scope2.row.goods_name }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="数量">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #09bb07;">{{ scope2.row.number }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结算单价">
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #eb3021;">￥ {{ scope2.row.retail_price }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结算总价" sortable>
                                    <template slot-scope="scope2">
                                        <div slot="reference" class="name-wrapper">
                                            <span class="bold15" style="margin-left: 10px;color: #eb3021;" >￥ {{ scope2.row.retail_price * scope2.row.number }}</span>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>

            <el-table-column
                label="销售备注" width="80">
                <template slot-scope="scope">
                    <i v-if="!scope.row.order_remarks_id" class="el-icon-circle-plus-outline cursor_pointer" style="font-size: 18px;color: #eb3021;" @click="addOrderRemarks(scope.row)"></i>
                    <span class="red cursor_pointer" style="font-weight: bold" @click="addOrderRemarks(scope.row)" v-else>{{scope.row.seller_remarks}}</span>
                </template>
            </el-table-column>

            <el-table-column
                label="客户"
                :width="$store.state.UA !== 'MOBILE'? '100':'60'">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <div><img width="50px" :src="scope.row.AVATAR" alt=""></div>
                        <p class="color_09bbe7">{{ scope.row.NICKNAME }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="物流地址">
                <template slot-scope="scope">
                    <span>{{scope.row.consignee}}，{{scope.row.mobile}}，{{ scope.row.ProvinceName +' '+ scope.row.CityName +' '+ scope.row.DistrictName +' '+ scope.row.address }}</span>
                </template>
            </el-table-column>
            <el-table-column label="订单金额" width="100">
                <template slot-scope="scope">
                    <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.order_price }}</span>
                    <p v-if="scope.row.commission_order_id > 0" class="positionAB color_eb3021 fontWeight" style="font-size: 30px;top: 10px;left: 0;opacity: 0.3;">返佣订单</p>
                </template>
            </el-table-column>
            <el-table-column label="实际支付金额" width="100">
                <template slot-scope="scope">
                    <span style="margin-left: 10px;color: #5cadff;">￥ {{ scope.row.actual_price }}</span>
                </template>
            </el-table-column>
            <el-table-column label="下单时间" width="150" prop="add_time"></el-table-column>
            <el-table-column label="买家备注" width="150">
                <template slot-scope="scope">
                        <span style="color: #ff0000;font-size: 15px;">{{ scope.row.remarks }}</span>
                </template>
            </el-table-column>

            <el-table-column label="退款类型" width="100" prop="">
                <template slot-scope="scope">
                    <span v-if="scope.row.REFUND_TYPE==='moneyOnly'" style="color: #333;font-size: 16px;">仅退款</span>
                    <span v-else-if="scope.row.REFUND_TYPE==='goodsNMoney'" style="color: #333;font-size: 16px;">退货退款</span>
                    <span v-else style="color: #333;font-size: 16px;">不存在退货信息</span>
                </template>
            </el-table-column>

            <el-table-column label="订单状态" width="100" prop="">
                <template slot-scope="scope">
                    <span v-if="scope.row.order_status_text==='已收货'" style="color: #333">{{ scope.row.order_status_text }}</span>
                    <span v-else-if="scope.row.order_status_text==='已发货'" style="color: #09bb07">{{ scope.row.order_status_text }}</span>
                    <span v-else-if="scope.row.order_status_text==='已付款'" style="color: #ff0000;font-weight: bold;">{{ scope.row.order_status_text }}</span>
                    <span v-else style="color: #eb3021;font-weight: bold;font-size: 20px;">{{ scope.row.order_status_text }}</span>
                    <span v-if="scope.row.pay_way === 'app'" style="display: inline-block;padding: 3px 8px;color: #ffffff;background-color: #1094fa;border-radius: 15px">APP订单</span>
                    <span v-else-if="scope.row.pay_way === 'mini'" style="display: inline-block;padding: 3px 8px;color: #ffffff;background-color: #09bb07;border-radius: 15px">小程序订单</span>
                    <span v-else style="display: inline-block;padding: 3px 8px;color: #ff0000;background-color: #eeeeee;border-radius: 15px">未知渠道</span>
                </template>
            </el-table-column>
            <el-table-column
                label="物流信息"
                width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span :style="scope.row.LogisticCode ? 'color: #ff0000;' :''">{{scope.row.LogisticCode ? `${scope.row.ShipperName}(${scope.row.LogisticCode})` : "未发货"}}</span>
                </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
                label="发货状态" width="150px">
                <template slot-scope="scope">
                    <el-button type="success" v-if="scope.row.order_status >= 300" disabled>
                        <img class="icon_20" v-if="scope.row.ShipperCode === 'SF'" src="https://mini-goods.oss-cn-shenzhen.aliyuncs.com/cathouse_pro/images/sf.png" alt="">
                        <img class="icon_20" v-if="scope.row.ShipperCode === 'ZTO'" src="https://mini-goods.oss-cn-shenzhen.aliyuncs.com/cathouse_pro/images/zto.jpg" alt="">
                        预览运单
                    </el-button>
                    <el-button type="primary" v-else disabled>生成运单</el-button>
                    <p v-if="scope.row.commission_order_id > 0" class="positionAB color_eb3021 fontWeight" style="font-size: 30px;top: 10px;left: 0;opacity: 0.3;">返佣订单</p>
                </template>
            </el-table-column>
            <!-- 操作 -->
            <!--<el-table-column-->
                <!--label="订阅物流信息" v-if="$store.state.UA !== 'MOBILE'">-->
                <!--<template slot-scope="scope">-->
                    <!--&lt;!&ndash;&ndash;&gt;-->
                    <!--<el-button v-if="scope.row.order_status > 201 && scope.row.shipping_status < 1 && scope.row.order_status < 400" type="danger" @click="reSubscribeExpress(scope.row)">物流订阅失败</el-button>-->
                    <!--<el-button v-if="scope.row.order_status >= 300 && scope.row.shipping_status >= 1" type="success" @click="reSubscribeExpress(scope.row)">物流订阅成功</el-button>-->
                <!--</template>-->
            <!--</el-table-column>-->

            <!-- 其他操作 -->
            <el-table-column
                label="操作按钮" width="130">
                <template slot-scope="scope">
                    <div style="display: flex;flex-flow: column;flex: .5;">
                        <el-button type="danger" class="bg-purple" v-if="scope.row.order_status===401 && scope.row.pay_id === 'ali'" @click="openRefundForm(scope.row)">发起支付宝退款</el-button>
                        <el-button type="danger" v-if="scope.row.order_status===401 && scope.row.pay_id === 'wxp'" @click="openRefundForm(scope.row)">发起微信退款</el-button>
                        <el-button type="primary" v-if="scope.row.order_status===401"  @click="openOrderOperationRecord(scope.row)">取消退款</el-button>
                        <el-button type="primary" v-else  @click="openOrderOperationRecord(scope.row)">订单操作记录</el-button>
                        <el-button type="info" plain @click="$router.push('./orderDetail?id=' + scope.row.id)">订单详情</el-button>
                        <el-button type="default" v-if="scope.row.order_status >= 400" @click="showRefundDetail(scope.row)">订单退款详情</el-button>
                        <el-button type="default" v-if="scope.row.order_status >= 400 && scope.row.pay_id === 'wxp'" @click="checkWeixinRefundDetail(scope.row.order_sn)">微信退款详情</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[5,10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>

        <!--查询微信支付退款详情-->
        <el-dialog
            title="查询微信支付退款详情"
            :visible.sync="showWXRefundDetail"
            width="50%">

            <el-card class="box-card">
                <div class="single_weixin_info user_info">
                    <img class="portrait" :src="WXRefundDetail.user_info.avatar" alt="">
                    用户昵称：{{WXRefundDetail.user_info.nickname}}
                </div>

                <div class="single_weixin_info">退款方式：{{WXRefundDetail.refund_recv_accout_0}}</div>
                <div class="single_weixin_info">退款到账时间：{{WXRefundDetail.refund_success_time_0}}</div>
                <div class="single_weixin_info">退款状态：{{WXRefundDetail.refund_status_0}}</div>
                <!--<div class="single_weixin_info">支付总金额：{{WXRefundDetail.total_fee/100}}元</div>-->
                <div class="single_weixin_info">该订单退款次数：{{WXRefundDetail.refund_count}}</div>
                <div class="single_weixin_info">退款总金额：{{WXRefundDetail.total_fee/100}}元</div>
            </el-card>


            <span slot="footer" class="dialog-footer"></span>
        </el-dialog>

        <!--查询订单操作记录并提供对订单的历史恢复操作-->
        <el-dialog
            title="订单操作记录"
            :visible.sync="showOrderOperationRecordData"
            width="80%">
            <div class="padding15 text-align-right" v-if="orderOperationRecordData.row.order_status===401">
                <el-popover
                    placement="right"
                    width="400"
                    trigger="click">
                    <el-form>
                        <div class="color_eb3021_important font-size-10">该操作会直接变更订单的当前状态并删除相应的数据，例如：订单状态选择回退到未发货，则会删除订单的申请退款记录以及相关的订单记录</div>
                        <el-form-item label="请选择取消退款后需要回退的订单状态" required>
                            <el-select v-model="chooseOrderRestoreType" placeholder="请选择">
                                <el-option
                                    v-for="item in orderStatusData"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item >
                            <div class="text-align-right">
                                <el-button type="danger" @click="cancelOrderRefund">确认取消退款，并回退订单状态</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                    <el-button slot="reference">取消退款</el-button>
                </el-popover>
            </div>
            <el-table
                :data="orderOperationRecordData.data"
                v-loading="!orderOperationRecordData.data"
                style="width: 100%">
                <el-table-column
                    label="修改人员">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <p class="color_09bbe7">{{ scope.row.edit_user }}</p>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="订单id">
                    <template slot-scope="scope">
                            <p class="color_09bbe7">{{ scope.row.order_id }}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="编辑的项目">
                    <template slot-scope="scope">
                            <p class="color_09bbe7">{{ scope.row.edit_column }}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="新旧值对照">
                    <template slot-scope="scope">
                            <p class="color_09bbe7"><span class="color_09bb07">新值：{{ scope.row.edit_column_new }}</span> / <span>旧值：{{ scope.row.edit_column_old }}</span></p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="请求内容/编辑总体内容">
                    <template slot-scope="scope">
                            <p class="color_09bbe7">{{ scope.row.content }}</p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作时间">
                    <template slot-scope="scope">
                            <p class="color_09bbe7">{{ scope.row.edit_time }}</p>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

        <!--退款发起表单-->
        <el-dialog
            :title="'填写'+orderBaseInfo.pay_id_name+'退款信息'"
            :visible.sync="showRefundForm"
            width="40%">
            <div class="disflex_around_start">
                <div class="expressBox" style="width: 60%;">
                    <h3>订单基本信息：</h3>
                    <div style="margin: 10px 0;">
                        收货人：
                        {{orderBaseInfo.consignee}}
                    </div>
                    <div style="margin: 10px 0;">
                        收货地址：
                        {{orderBaseInfo.mobile}}
                        —{{ orderBaseInfo.ProvinceName +' '+ orderBaseInfo.CityName +' '+ orderBaseInfo.DistrictName +' '+ orderBaseInfo.address }}
                    </div>
                    <div style="margin: 10px 0;">
                        <span style="margin-right: 20px;">订单金额： <span class="price"> ¥{{orderBaseInfo.order_price}}</span></span>
                        实际支付金额： <span class="price"> ¥{{orderBaseInfo.actual_price}}</span>
                    </div>
                    <div style="margin: 10px 0;">
                        下单时间：
                        {{orderBaseInfo.add_time}}
                    </div>
                    <div style="margin: 10px 0;">
                        买家备注：
                        {{orderBaseInfo.remarks}}
                    </div>
                    <div style="margin: 10px 0;">
                        订单状态：
                        {{orderBaseInfo.order_status_text}}
                    </div>

                    <h3 style="margin-top: 20px;">退款表单：</h3>
                    <el-form ref="senderMsg" :model="refundInfo">
                        <!--退款单号-->
                        <el-form-item :rules="[{ required: true, message: '订单单号不能为空'}]" prop="order_sn">
                            <span>订单号：</span>
                            <el-input v-model="refundInfo.order_sn" placeholder="请填写订单单号" ></el-input>
                        </el-form-item>
                        <!--退款金额-->
                        <el-form-item :rules="[
                                  { required: true, message: '退款金额不能为空！'},
                                ]" prop="refund_fee">
                            <div style="margin-right: 20px;">
                                订单未优惠前金额： <span class="price"> ¥{{orderBaseInfo.order_price}}</span>
                            </div>

                            <div>
                                实际支付金额： <span class="price" style="font-size: 18px;font-weight: bold;"> ¥{{orderBaseInfo.actual_price}}</span>
                            </div>

                            <div>
                                用户申请退款金额： <span class="price"> ¥{{orderBaseInfo.REFUND_TOTAL}}</span>
                            </div>
                            <span>退款金额：</span>
                            <el-input type="number" max="10" v-model="refundInfo.refund_fee" placeholder="退款金额" require ></el-input>
                        </el-form-item>
                        <!--退款原因-->
                        <el-form-item :rules="[{ required: true, message: '退款原因不能为空'}]" prop="refund_desc">
                            <span>退款原因：</span>
                            <p>用户退款原因：<span class="color_eb3021">{{orderBaseInfo.REFUND_REASON}}</span></p>
                            <p>用户退款备注：<span class="color_eb3021">{{orderBaseInfo.REFUND_REMARKS}}</span></p>
                            <el-input v-model="refundInfo.refund_desc" type="textarea" placeholder="请填写退款原因" ></el-input>
                        </el-form-item>
                        <!--操作码-->
                        <el-form-item :rules="[
                                  { required: true, message: '操作码不能为空！'}
                                ]" prop="validate_code">
                            <span>操作码：</span>
                            <el-input show-password v-model="refundInfo.validate_code" placeholder="操作码" require ></el-input>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="refundSubmit(orderBaseInfo.pay_id)">
            <img v-if="orderBaseInfo.pay_id === 'wxp'" class="width25px " src="https://oss.mxclass.com/cathouse_pro/%E7%BB%BC%E5%90%88%E7%B4%A0%E6%9D%90/wxpay.png" alt="">
            <img v-if="orderBaseInfo.pay_id === 'ali'"  class="width25px " src="https://oss.mxclass.com/images/%E6%94%AF%E4%BB%98%E5%AE%9D.jpg" alt="">
              确  定</el-button>
            <el-button type="default" @click="showRefundForm=false;">取  消</el-button>
          </span>
        </el-dialog>

        <el-dialog
            title="填写发货信息"
            :visible.sync="addExpress"
            width="90%">
            <h2>1、选择快递信息</h2>
            <el-select @change="changeExpressCode" v-model="express.ShipperCode" placeholder="请选择快递公司" style="padding: 20px 0">
                <el-option
                   v-for="item in expressCompany"
                   :key="item.value"
                   :label="item.label"
                   :value="item.value">
               </el-option>
            </el-select>
            <h2>2、确认运单信息</h2>
            <div class="disflex_around_start">
                <!--发件人信息-->
                <div class="expressBox">
                    <el-form ref="senderMsg" :model="express">
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">发件信息</legend>
                            <el-form-item :rules="[
                                  { required: true, message: '发件人不能为空'}
                                ]" prop="Sender.Name">
                                <span>发件人：</span>
                                <el-input v-model="express.Sender.Name" placeholder="请选择物流公司" ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: true, message: '发件人手机号不能为空！'}
                                ]" prop="Sender.Mobile">
                                <span>发件人联系方式：</span>
                                <el-input v-model="express.Sender.Mobile" placeholder="手机号码" ></el-input>
                                <el-input v-model="express.Sender.Tel" placeholder="固话" require ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: true, message: '发件省市地址不能为空，省市区间用空格分开'}
                                ]" prop="Sender.FullRegion">
                                <span>发件地址：</span>
                                <el-input v-model="express.Sender.FullRegion" placeholder="请选择发件地址" ></el-input>
                            </el-form-item>
                            <el-form-item :rules="[
                                  { required: true, message: '发件人详细地址不能为空'}
                                ]" prop="Sender.Address">
                                <span>发件地址：</span>
                                <el-input v-model="express.Sender.Address" placeholder="详细地址" ></el-input>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
                <!--收件人信息-->
                <div class="expressBox">
                    <el-form ref="receiverMsg"  :model="express">
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">收件信息</legend>
                            <el-form-item prop="Receiver.Name" :rules="[
                                  { required: true, message: '收件人姓名不能为空！'}
                                ]">
                                <span>收件人：</span>
                                <el-input v-model="express.Receiver.Name" placeholder="请填写收件人姓名"></el-input>
                            </el-form-item>
                            <el-form-item prop="Receiver.Mobile"  :rules="[
                                  { required: true, message: '收件人手机号不能为空'}
                                ]">
                                <span>收件人联系方式：</span>
                                <el-input v-model="express.Receiver.Mobile" placeholder="收件人手机号码" ></el-input>
                                <el-input v-model="express.Receiver.Tel" placeholder="收件人固话" require ></el-input>
                            </el-form-item>
                            <el-form-item  prop="Receiver.FullRegion" :rules="[
                                  { required: true, message: '收件人省市区不能为空，省市区间用空格分开'}
                                ]">
                                <span>收件地址：</span>
                                <el-input v-model="express.Receiver.FullRegion" placeholder="请选择发件地址"></el-input>
                            </el-form-item>
                            <el-form-item  prop="Receiver.Address" :rules="[
                                  { required: true, message: '收件人详细地址不能为空！'}
                                ]">
                                <span>收件地址：</span>
                                <el-input v-model="express.Receiver.Address" placeholder="详细地址" ></el-input>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
                <!--其他物流信息-->
                <div class="expressBox">
                    <el-form ref="otherExpress" :model="express">
                        <fieldset class="padding15 border_eee" v-if="express.goodsList">
                            <legend class="color_cdcdcd">其他物流信息</legend>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>快递物件名：</span>
                                <el-input v-model="express.goodsList[0].goods_name" placeholder="请填写快递物件名"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>重量：(千克)</span>
                                <el-input v-model="express.weight" placeholder="包裹重量"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>长：（cm）</span>
                                <el-input v-model="express.space_x" placeholder="包裹长度"  ></el-input>
                            </el-form-item>
                            <el-form-item prop="Commodity.GoodsName">
                                <span>宽：(cm)</span>
                                <el-input v-model="express.space_y" placeholder="包裹宽度"  ></el-input>
                            </el-form-item>

                            <el-form-item prop="Commodity.GoodsName">
                                <span>宽：(cm)</span>
                                <el-input v-model="express.space_z" placeholder="包裹宽度"  ></el-input>
                            </el-form-item>

                            <el-form-item>
                                <span>买家备注：</span>
                                <textarea class="remarks" name="备注（如果为空，则可以填写快递备注，数据直接同步到快递，打印在快递单上）" id="" cols="20" rows="10" :value="express.Remark"
                                          @input="RemarkChange"></textarea>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="sendExpress">获取运单</el-button>
            <el-button type="default">保  存</el-button>
          </span>
        </el-dialog>
        <!--订单详情-->
        <el-dialog
            title="订单-退货退款-详情"
            :visible.sync="showRefundDetailBool"
            width="90%">
            <div class="disflex_around_start">
                <!--客户信息-->
                <div class="expressBox">
                    <el-form ref="express" v-if="refundStatus.refund">
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">用户详情</legend>
                            <el-form-item>
                                <span>用户昵称: </span>
                                <span>{{refundStatus.refund.nickname}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>收件人名: </span>
                                <span>{{refundStatus.order.consignee}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>收货电话：</span>
                                <span>{{refundStatus.order.mobile}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>收货地址：</span>
                                <span>{{refundStatus.order.ProvinceName +
                                    refundStatus.order.DistrictName +
                                    refundStatus.order.CityName +
                                    refundStatus.order.address}}
                                </span>
                            </el-form-item>
                            <el-form-item>
                                <span>注册时间：</span>
                                <span>{{refundStatus.refund.register_time}}
                                </span>
                            </el-form-item>
                            <el-form-item>
                                <span>最后登陆时间：</span>
                                <span>{{refundStatus.refund.last_login_time}}
                                </span>
                            </el-form-item>

                        </fieldset>
                    </el-form>
                </div>

                <!--退款详情-->
                <div class="expressBox" v-if="refundStatus.refund">
                    <el-form >
                        <fieldset class="padding15 border_eee">
                            <legend class="color_cdcdcd">退款详情</legend>
                            <el-form-item>
                                <span>退款详情: </span>
                                <span>{{refundStatus.refund.type}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>退款原因：</span>
                                <span>{{refundStatus.refund.reason}}</span>
                            </el-form-item>
                            <el-form-item>
                                <span>退款说明：</span>
                                <span>{{refundStatus.refund.remarks}}</span>
                            </el-form-item>

                            <el-form-item>
                                <span>退款金额：</span>
                                <span class="red">￥ {{refundStatus.refund.total}} 元</span>
                            </el-form-item>

                            <el-form-item>
                                <span>发起时间：</span>
                                <span class="red">{{refundStatus.refund.add_time}}</span>
                            </el-form-item>

                            <el-form-item>
                                <span>用户上传凭证：</span>
                                <div class="disflex_warp">
                                    <a :href="item" target="_blank" v-for="(item, index) in refundStatus.refund.evidence" :key="index">
                                        <img class="width50 border_eee padding15 box_sizing cursor_pointer"
                                             :src="item" alt="" >
                                    </a>
                                </div>
                            </el-form-item>
                        </fieldset>
                    </el-form>
                </div>

                <!--商城订单信息-->
                <div class="expressBox">
                    <fieldset class="padding15 border_eee">
                        <legend class="color_cdcdcd">该用户下单历史</legend>
                        <el-form>
                            <el-form-item v-if="refundStatus.baseMsg">
                                <h2 class="color_09bb07">商城下单情况：</h2>
                                <div v-for="item in refundStatus.baseMsg" :key="item.order_status">
                                    <h3 class="yellow">{{item.order_status_text}}</h3>
                                    <div class="disflex_around_center">
                                        <p>{{item.order_status_text}}订单总数：{{item.countNum}}</p>
                                        <p>金额总计：{{item.totalPrice}}</p>
                                    </div>
                                </div>
                            </el-form-item>
                        </el-form>
                    </fieldset>
                </div>
            </div>
            <div class="disflex_around_start">
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button type="primary" v-if="refundStatus.order && refundStatus.order.order_status === 401" @click="agreeThisRefund(refundStatus)">审核通过该退款</el-button>
            <el-button type="default" @click="showRefundDetailBool = !showRefundDetailBool">关 闭</el-button>
          </span>
        </el-dialog>
        <!--startprint-->
        <div id="express_print" v-if="BOOL_EXPRESS_HTML"  class="boxShdow" v-html="EXPRESS_HTML"></div>
        <!--endprint-->
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'
    import {Loading} from 'element-ui'
    import print from 'print-js'
    import util from '../../../utils/util'

    export default {
        data: function () {
            return {
                refundInfo:{  //退款填写信息
                    order_sn: null,
                    refund_fee: null,
                    refund_desc: null,
                    validate_code: null,
                },
                orderBaseInfo: {  //退款时展示的信息

                },
                showRefundForm: false,  //是否展示退款表单
                showWXRefundDetail: false,  //是否展示微信退款详情
                WXRefundDetail: {user_info:{}},  //微信退款详情
                expressCompany: [
                    {label: '顺丰速运', value: 'SF'},
                    {label: '中通快递', value: 'ZTO'}
                ], //物流公司
                activeName: '全部',
                addExpress: false,
                tableData: [],
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                paperStatus: '未上线',
                paperId: '',
                userNameSearchStr: '', // 姓名查询
                userPhoneSearchStr: '', // 电话查询
                orderSnSearchStr: '', // 单号查询
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                PayTypeOptions: [ // 付款方式
                    {label: '现付', value: 1},
                    {label: '到付', value: 2},
                    {label: '月结', value: 3},
                    {label: '第三方', value: 4}
                ],
                ExpTypeOptions: [
                    // 快递类型
                    {label: '标准快件', value: 1}
                ],
                express: {
                    weight: 1,
                    space_x: 20,
                    space_y: 20,
                    space_z: 20,
                    LogisticCode: '', // 运单号
                    ShipperCode: '', //物流公司代码
                    ShipperName: '', // 物流公司名称
                    OrderId: '',
                    IsNotice: 1, //默认不通知
                    IsSendMessage: 1, // 默认发送通知
                    PayType: 1, // 默认发件类型为现付
                    ExpType: 1, // 默认快递类型为标准快递
                    Quantity: '', // 数量
                    Remark: '猫趴客户，请微笑服务~', // 备注
                    Sender: {
                        Name: '李美丽',
                        Tel: '',
                        Mobile: '0755-32867416',
                        Address: '华宁路厦门大学产学研基地14楼1402',
                        ProvinceName: '广东省',
                        CityName: '深圳市',
                        ExpAreaName: '龙华区',
                        FullRegion: '广东省 深圳市 龙华区'
                    },
                    Receiver: {
                        Name: '',
                        Tel: '',
                        Mobile: '',
                        Address: '',
                        ProvinceName: '',
                        CityName: '',
                        ExpAreaName: '',
                        FullRegion: ''
                    },
                    Commodity: {
                        GoodsName: ''
                    }
                    // 快递信息
                },
                showRefundDetailBool: false, // 是否显示退款订单详情
                refundStatus: [], // 退款详情
                EXPRESS_HTML: '', // 后端返回的快递HTML
                showOrderOperationRecordData: false, // 显示操作记录
                orderOperationRecordData: {
                    data: [], // 订单的操作记录
                    row: {} // 当前选择的订单
                }, // 订单操作记录
                chooseOrderRestoreType: null, // 选择恢复订单的类型
                orderStatusData: [
                ], // 订单状态码对应的中文参数字典
            }
        },
        components: {
            commonlist
        },
        watch: {},
        computed: {
            BOOL_EXPRESS_HTML () {
                return this.EXPRESS_HTML !== ''
            }
        },
        created() {
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
            this.getPaperList(0, 1, 10)
        },
        mounted() {
            // 更新列表数据
            bus.$on('reloadOrderList', reload => {
                if (reload) {
                    this.getPaperList(this.currentTagId, 1, 10)
                }
            })
        },
        methods: {
            async cancelOrderRefund () {
                if (this.chooseOrderRestoreType === null) {
                    this.$message.error('请选择需要恢复的订单状态！');
                    return
                }

                if (Number(this.chooseOrderRestoreType === 401)) {
                    this.$message.error('该订单已在退款状态！！');
                    return
                }

                let order_status_text = '', that = this;
                this.orderStatusData.map(function (item) {
                    if (item.value === that.chooseOrderRestoreType) {
                        order_status_text = item.label
                    }
                });

                if (!order_status_text) {
                    this.$message.error('选择恢复的状态有误，请刷新后重新选择！');
                    return
                }

                this.$confirm(`您正在取消该订单的退款申请，并恢复订单状态至：【${order_status_text}】，恢复后订单相关信息将不再显示或者直接被删除`, '警示', {
                    confirmButtonText: `确认取消退款并恢复为【${order_status_text}】`,
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$api + '/admin/order/restoreOrderFromRefund', {
                        order_id: this.orderOperationRecordData.row.id,
                        order_status: this.chooseOrderRestoreType
                    }).then(res => {
                        if (res.data.errno === 0) {
                            this.$message.success('恢复成功！')
                            this.showOrderOperationRecordData = false;
                        }
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            // orderStatusData
            async orderStatusDataFun () {
                await this.$http.post(this.$api + '/admin/order/getOrderStatusDictionary', {}).then(res => {
                    if (res.data.errno === 0) {
                        this.orderStatusData = res.data.data;
                    }
                });
            },
            // 查看订单操作记录
            async openOrderOperationRecord (row) {
                const loading = this.$loading({
                    lock: false,
                    text: '加载操作记录中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                await this.$http.post(this.$api + '/admin/order/getOrderOperationProcess', {
                    order_id: row.id
                }).then(res => {
                    if (res.data.errno === 0) {
                        this.orderOperationRecordData.data = res.data.data;
                        this.orderOperationRecordData.row = row;
                        this.showOrderOperationRecordData = true;
                    }
                });
                await this.orderStatusDataFun();
                loading.close();
            },
            // 返佣显示红色底
            tableRowClassName({row}) {
                if (row.commission_order_id > 0) {
                    return 'warning-table-row';
                }
                return '';
            },
            //点击发起退款，展示退款表单
            openRefundForm(order){
              // 区分支付宝退款还是微信退款
              this.showRefundForm = true;
              this.refundInfo.order_sn = order.order_sn;
              this.refundInfo.order_id = order.id;
              // this.refundInfo.refund_fee = order.actual_price;
              // 如果用户提交的金额比支付的金额大，则以实际支付金额为准
              // 否则以用户提交金额为准（防止用户提交高金额）
              if (order.actual_price <= order.REFUND_TOTAL) {
                this.refundInfo.refund_fee = order.REFUND_TOTAL;
              } else {
                this.refundInfo.refund_fee = order.actual_price;
              }
              order.pay_id_name = order.pay_id === 'ali'? '支付宝':'微信'
              this.orderBaseInfo = order;
            },
            //点击确定退款按钮
            async refundSubmit(pay_id){
                const loading = this.$loading({
                    lock: true,
                    text: '正在退款中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // 区分支付宝支付还是微信支付
              if (pay_id === 'ali') {
                  await this.$http.post(this.$api + 'admin/refund/alipayRefund', {
                  order_sn: this.refundInfo.order_sn,
                  orderId: this.refundInfo.order_id,
                  refund_fee: this.refundInfo.refund_fee,
                  refund_desc: this.refundInfo.refund_desc,
                  validate_code: util.base64_encode(this.refundInfo.validate_code),
                }).then(res => {
                  // console.log('退款res',res);
                  if (res.data.errno === 0) {
                    this.$message.success('退款成功！');
                    this.showRefundForm = false;
                    this.getPaperList(0, this.tableData.currentPage, this.tableData.pageSize)
                  }
                  if(res.result_code == 'FAIL'){
                    this.$message.error(res.err_code_des);
                  }
                  loading.close();
                })
              } else {
                await this.$http.post(this.$api + 'admin/order/requireOrderRefund', {
                  order_sn: this.refundInfo.order_sn,
                  refund_fee: this.refundInfo.refund_fee,
                  refund_desc: this.refundInfo.refund_desc,
                  validate_code: util.base64_encode(this.refundInfo.validate_code),
                }).then(res => {
                  // console.log('退款res',res);
                  if (res.data.errno === 0) {
                    this.$message.success('退款成功！');
                    this.showRefundForm = false;
                    this.getPaperList(0, this.tableData.currentPage, this.tableData.pageSize)
                  }
                  if(res.result_code == 'FAIL'){
                    this.$message.error(res.err_code_des);
                  }
                  loading.close();
                })
              }
            },
            //查询微信退款详情
            async checkWeixinRefundDetail (order_sn){
                const loading = this.$loading({
                    lock: true,
                    text: '查询中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                await this.$http.post(this.$api + 'admin/order/queryWeixinRefundOrder', {
                    order_sn: order_sn,
                }).then(res => {
                    if (res.data.errno === 0) {
                        this.WXRefundDetail = res.data.data;
                        this.showWXRefundDetail = true;
                    }
                    loading.close();
                })
            },
            // 变更快递
            changeExpressCode (val) {
                let that =this;
              this.expressCompany.map(function (item) {
                  if (item.value === val) {
                      that.express.ShipperName = item.label
                  }
              })
            },
            // 同意该笔订单退款
            async agreeThisRefund (refundStatus) {
                if (refundStatus.order.order_status >= 401) {
                    this.$message.error('该订单已审核，无需重复审核！');
                    return
                }
                const loading = this.$loading({
                    lock: true,
                    text: '审核中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                await this.$http.post(this.$api + '/admin/refund/check', {orderId: refundStatus.refund.order_id})
                    .then(res => {
                        if (res.data.errno === 0) {
                            this.$message.success('审核通过，请通知财务在商户后台退款！');
                            this.showRefundDetailBool = !this.showRefundDetailBool;
                        } else {
                            this.$message.error(res.data.errmsg)
                        }
                        loading.close();
                    })
            },
            // 查看退款订单详情
            async showRefundDetail (item) {
                const loading = this.$loading({
                    lock: true,
                    text: '加载退款信息中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                await this.$http.post(this.$api + '/admin/refund/detail', {orderId: item.id})
                    .then(res => {
                        if (res.data.errno === 0) {
                            this.showRefundDetailBool = !this.showRefundDetailBool;
                            this.refundStatus = res.data.data;
                            this.refundStatus.order = item
                        }
                        loading.close();
                    })
            },
            // 计算合计
            getSummaries(param) {
                //console.log(param)
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计（元）';
                        return;
                    } else if (index === 1) {
                        sums[index] = 0;
                        for (let item in data) {
                            sums[index] += data[item].number
                        }
                        return;
                    } else if (index === 2) {
                        sums[index] = '-';
                        return;
                    } else if (index === 3) {
                        sums[index] = 0;
                        for (let item in data) {
                            sums[index] += data[item].retail_price * data[item].number
                        }
                        return;
                    }
                });

                return sums;
            },
            // 重新订阅物流信息
            reSubscribeExpress (item) {
                this.$confirm('此操作将重新向物流系统重新订阅该订单物流信息，请确认重新订阅？', '警示', {
                    confirmButtonText: '重新订阅',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$api + '/admin/order/reSubscribeExpress', {
                        id: item.id
                    })
                        .then( res => {
                            if (res.data.errno === 0) {
                                this.$message.success('物流信息重新订阅成功！！');
                                this.getPaperList(0, 1, 10)
                            } else if (res.data.errno === 4004) {
                                let that = this;
                                setTimeout(function () {
                                    that.$message.error('该订单不存在物流信息，请重新提交发货信息！');
                                }, 1000);
                                this.addExpressFun(item)
                            }
                        })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            // 发货操作
            sendExpress () {
                let that = this;
                that.$refs['senderMsg'].validate((valid) => {
                    if (!valid) {
                        that.$message.error('请完善发件人必填信息！！');
                        return false;
                    } else {
                        that.$refs['receiverMsg'].validate((valid) => {
                            if (!valid) {
                                that.$message.error('请完善收件人必填信息！！');
                                return false;
                            } else {
                                that.$refs['otherExpress'].validate((valid) => {
                                    if (!valid) {
                                        that.$message.error('请完善其他物流必填信息！！');
                                        return false;
                                    } else {
                                        if (!that.express.ShipperCode || !that.express.ShipperName) {
                                            return that.$message.error('请选择物流公司！');
                                        }
                                        // 提交数据
                                        that.express.Receiver_str = JSON.stringify(that.express.Receiver);
                                        that.express.Sender_str = JSON.stringify(that.express.Sender);
                                        that.express.Commodity_str = JSON.stringify(that.express.Commodity);
                                        const loading = this.$loading({
                                            lock: true,
                                            text: '正在拉取快递单~',
                                            spinner: 'el-icon-loading',
                                            background: 'rgba(183, 183, 183, 0.57)'
                                        });
                                        that.$http.post(that.$api + '/admin/printer/addOrder', that.express)
                                            .then( res => {
                                                if (res.data.errno === 0) {
                                                    loading.close();
                                                    this.$message.success('运单获取成功！');
                                                    /**
                                                     * 每次发货成功后都需要重置快递公司，防止惯性操作用错快递公司
                                                     * **/
                                                    this.express.ShipperCode = this.express.ShipperName = '';
                                                    this.getPaperList('',this.tableData.currentPage, this.tableData.pageSize);
                                                    this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                                                    // 关闭发货窗口
                                                    this.addExpress = !this.addExpress;
                                                    // 打开打印窗口
                                                    // this.$alert('运单已生成，是否立即打印运单？', '打印提醒', {
                                                    //     confirmButtonText: '打印',
                                                    //     callback: action => {
                                                    //         printJS('express_print', 'html');
                                                    //         that.EXPRESS_HTML = ''
                                                    //     }
                                                    // });
                                                } else {
                                                    loading.close();
                                                    this.$message.error(res.data.errmsg);
                                                }
                                            }).catch (err => {
                                            loading.close();
                                        })
                                    }
                                });
                            }
                        });
                    }
                });
            },
            createExpressHtml (express) {
                let that = this;
                const loading = this.$loading({
                    lock: true,
                    text: '正在拉取快递单~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                that.$http.post(that.$api + '/admin/printer/getOrder', express)
                    .then( res => {
                        // if () {
                        //
                        // }
                    })
                loading.close();
            },
            async getDeliveryBill (DATA) {
                const loading = this.$loading({
                    lock: true,
                    text: '运单已存在，拉取运单中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                console.log(DATA)
                await this.$http.post(this.$api + '/admin/printer/getOrder',  {
                    order_id: DATA.order_sn,
                    openid: DATA.OPENID,
                    delivery_id: DATA.ShipperCode,
                    waybill_id: DATA.LogisticCode
                })
                    .then( res => {
                        this.EXPRESS_HTML = res.data.data.print_html.replace(/<style>/g, '<style>.address__sender {font-size: 26px !important;}.block_9 {height: 165px !important;}.container{padding: 0 !important;height: auto !important;}');
                        // 打开打印窗口
                        // setTimeout (function () {
                        //     printJS('express_print', 'html');
                        //     loading.close()
                        // }, 1000)
                    }).catch(err => {
                        loading.close()
                })
            },
           async addExpressFun (item) {
                if (!item.id) {
                    alert('订单ID有误，请重新选择！');
                    return
                }

                /**
                 * 订单已发货，则无需再生成运单号
                 * **/
                if (item.order_status >= 300) {
                    // don't need to add new Delivery
                    return await this.getDeliveryBill(item)
                }
                this.express.OrderId = item.id;
                // this.express.openid = item.OPENID;
                this.express.ITEM = item;
                this.express.goodsList = item.goodList;
                this.express.Receiver.Name = item.consignee;
                this.express.Receiver.Tel = item.tel;
                this.express.Receiver.Mobile = item.mobile;
                this.express.Receiver.ProvinceName = item.ProvinceName;
                this.express.Receiver.CityName = item.CityName;
                this.express.Receiver.ExpAreaName = item.DistrictName;
                this.express.Receiver.Address = item.address;
                this.express.Receiver.FullRegion = item.ProvinceName + ' ' + item.CityName + ' ' + item.DistrictName;
                this.addExpress = !this.addExpress
            },
            // 备注
            RemarkChange (e) {
              this.express.Remark = e.target.value
            },

            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, this.tableData.pageSize)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.tableData.pageSize)
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/order/refundList',
                    method: 'post',
                    data: this.$qs.stringify({
                        name: this.userNameSearchStr,
                        mobile: this.userPhoneSearchStr,
                        orderSn: this.orderSnSearchStr,
                        page: page? page:1,
                        size: length? length:10
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data
                        this.currentPage = response.data.data.currentPage
                        this.total = response.data.data.totalPages
                        this.size = response.data.data.pageSize
                        loading.close()
                    }
                })
            },
            switchTag(tab) {
                for (var i in this.tagSwitchData) {
                    if (tab.name === this.tagSwitchData[i].name) {
                        this.userNameSearchStr = ""
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10)
                        this.currentTagId = this.tagSwitchData[i].id
                    }
                }
            },
        }
    }
</script>
<style lang="scss" >
    .warning-table-row {
        background: #fbe8e2 !important;
    }
    /*.boxShdow {*/
        /*-webkit-box-shadow: inset hoff voff blur #000;*/
        /*-moz-box-shadow: inset hoff voff blur #000;*/
        /*box-shadow: inset hoff voff blur #000;*/
    /*}*/
    /*#express_print .address__sender {*/
        /*font-size: 26px !important;*/
    /*}*/
    .single_weixin_info{
        margin: 10px 0;
        &.user_info{
            display: flex;
            align-items: center;
            .portrait{
                width: 50px;
                height: 50px;
                margin-right: 20px;
            }
        }
    }
    .remarks{
        padding: 10px;
        border: 1px solid #eee;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        width: 100%;
        display: block;
        max-height: 110px;
    }
    .expressBox{
        width: 25%;
    }
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style >
