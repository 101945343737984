<template>
    <div>
        <div class="crumbs">
            <label>
                <span>{{ $route.meta.title }}</span>
                （共{{ tableData.count }}人）
                <el-button type='success' @click="shareAddPrinter = true" >新增打印员</el-button>
            </label>
        </div>
        <p class="color_eb3021 padding15">请勿随意添加打印员，打印员具有拉取所有订单物流的权限。请注意！</p>
        <el-table :data="printerData"  style="width: 100%"
                  @selection-change="handleSelectionChange">
            <el-table-column label="头像" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.avatar" style="width: 40px;vertical-align: middle;">
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="用户ID" prop="id" width="80"></el-table-column>
            <el-table-column label="用户名" prop="nickname" width="100"></el-table-column>
            <el-table-column label="用户别名" prop="username" width="100"></el-table-column>
            <el-table-column label="微信openId" prop="weixin_openid"></el-table-column>
            <el-table-column label="操作" >
                <template slot-scope="scope">
                    <el-button type='success' @click="deletePrinter(scope.row)" >删除</el-button>
                </template>
            </el-table-column>
        </el-table>


        <el-dialog :visible.sync="shareAddPrinter" custom-class="question-dialog" title="用户列表">
            <div class="grid-content bg-purple">

                <div class="button-tool">
                    <div class="search-title">
                        <el-input placeholder="请输入用户名查询" v-model="searchTitle" @keyup.enter.native="handleSearchByTitle">
                            <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                        </el-input>
                    </div>
                </div>

                <div class="tagSwitch disflex_between_center">
                    <el-tabs v-model="activeName" @tab-click="switchTag">
                        <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                                     :name="item.name"></el-tab-pane>
                    </el-tabs>
                    <el-button type='success' @click="addNewPrinter" >添加打印员</el-button>
                </div>


                <el-table :data="tableData.data" v-loading="!tableData.data" style="width: 100%"
                          @selection-change="handleSelectionChange">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column label="头像" width="100">
                        <template slot-scope="scope">
                            <div slot="reference" class="name-wrapper">
                                <img :src="scope.row.avatar" style="width: 40px;vertical-align: middle;">
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="用户ID" prop="id" width="80"></el-table-column>
                    <el-table-column label="用户名" prop="nickname" width="100"></el-table-column>
                    <el-table-column label="微信openId" prop="weixin_openid"></el-table-column>
                </el-table>

                <div class="paginaion" style="text-align: center;margin-top: 1em">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="tableData.currentPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="tableData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.count">
                    </el-pagination>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                activeName: '全部用户',
                // loading: true,
                tableData: {},
                printerData: [],
                labelNameList: [],
                tagSwitchData: [
                    {
                        name: '全部用户', value: 0,
                    },
                    {
                        name: '黑名单', value: 1,
                    }
                ],
                currentPage: 1,
                total: 0,
                size: 10,
                currentTagId: '',
                paperStatus: '未上线',
                paperId: '',
                searchTitle: '',
                tableHeight: 600,
                selectUserList: [],
                shareAddPrinter: false
            }
        },
        components: {},
        watch: {},
        created() {
            this.getPaperList(0, 1, 10)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653
            this.getPrinterData()
        },
        mounted() {

        },
        methods: {
            // 删除打印员
            deletePrinter (item) {
                this.$confirm(`您正在删除打印员【${item.nickname}】，请确认！`, '警示', {
                    confirmButtonText: '确定删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    const loading = this.$loading({
                        lock: true,
                        text: '正在删除~',
                        spinner: 'el-icon-loading',
                        background: 'rgba(183, 183, 183, 0.57)'
                    });
                    this.$http({
                        url: this.$api + '/admin/printer/deletePrinter',
                        method: 'post',
                        data: {
                            weixin_openid: item.weixin_openid
                        }
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.printerData = response.data.data
                            this.$message.success('更新成功！')
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                        loading.close()
                    }).catch(err => {
                        loading.close()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            // 保存打印员
            addNewPrinter () {
              if (this.selectUserList.length <= 0) {
                  return this.$message.error('请选择用户后执行添加操作！')
              }
                this.$confirm(`您正在授权${this.selectUserList.length}名用户成为打印员？设置成功后该成员可以打印物流订单！`, '警示', {
                    confirmButtonText: '确定授权',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {

                    const loading = this.$loading({
                        lock: true,
                        text: '正在保存~',
                        spinner: 'el-icon-loading',
                        background: 'rgba(183, 183, 183, 0.57)'
                    });
                    this.$http({
                        url: this.$api + '/admin/printer/addPrinter',
                        method: 'post',
                        data: this.selectUserList
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.printerData = response.data.data;
                            this.shareAddPrinter = false
                            this.$message.success('更新成功！')
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                        loading.close()
                    }).catch(err => {
                        loading.close()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            /**
             * 加载打印员信息**/
            getPrinterData () {
                this.$http({
                    url: this.$api + '/admin/printer/getPrinterList',
                    method: 'post'
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.printerData = response.data.data
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            switchTag (e) {
                this.activeName = e.label;
                if (e.label === '黑名单' || e.index === 1) {
                    this.getPaperList(1, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                } else {
                    this.getPaperList(0, this.tableData.currentPage || 1, this.tableData.pageSize || 10, this.searchTitle)
                }
            },
            handleSelectionChange (val) {
                this.selectUserList = val
            },
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, this.size, this.searchTitle)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.currentPage, val, this.searchTitle)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size, this.searchTitle)
            },
            getPaperList(is_limit, page, length, title) {
                const loading = this.$loading({
                    lock: true,
                    text: '小咪已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/user/index',
                    method: 'post',
                    data: this.$qs.stringify({
                        is_limit: is_limit,
                        page: page,
                        size: length,
                        name: title,
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.errno === 0) {
                        this.tableData = response.data.data
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style scoped>
    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .pointer {
        cursor: pointer;
    }
</style>

