<template>
    <div>
        <el-dialog :visible.sync="showaddForm" width="60%" :title="editMenuData.name">
            <!-- :title="'文章评论(共'+commons.total+'条)'" -->
            <el-form :model="editMenuData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="菜单类型" prop="menuType">
                    <el-radio :disabled="editMenuData.menuType!==item.value" v-model="editMenuData.menuType"
                              v-for="item in menuType" :label="item.value" :key="item.value" border>{{item.name}}
                    </el-radio>
                </el-form-item>

                <el-form-item label="菜单名称" prop="name">
                    <el-input v-model="editMenuData.name"></el-input>
                </el-form-item>
                <el-form-item v-if="editMenuData.menuType===1" label="图标">
                    <i :class="editMenuData.icon"></i>
                    <el-input v-model="editMenuData.icon"></el-input>
                </el-form-item>
                <el-form-item label="菜单父ID" prop="parentId">
                    <el-input v-model="editMenuData.parentId" disabled></el-input>
                </el-form-item>
                <el-form-item  label="页面地址URL">
                    <el-input v-model="editMenuData.url" placeholder="请谨慎填写访问路径，填写错误可能导致页面404"></el-input>
                </el-form-item>

                <el-form-item label="排序" prop="sort">
                    <el-input v-model="editMenuData.sort"></el-input>
                </el-form-item>
                <el-form-item v-if="editMenuData.menuType===2"  label="keepAlive">
                    <el-radio v-model="editMenuData.keepAlive" v-for="item in keepAlive" :label="item.value"
                              :key="item.value" border>{{item.name}}
                    </el-radio>
                </el-form-item>
                <el-form-item label="状态" prop="isShow">
                    <el-radio v-model="editMenuData.isShow" v-for="item in isShows" :label="item.value"
                              :key="item.value" border>{{item.name}}
                    </el-radio>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addMenu('ruleForm')">添加菜单</el-button>
                    <el-button @click="resetForm('ruleForm')">重置菜单</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data() {
            return {
                showaddForm: false,
                editMenuData: {
                    name: '',
                    url: '',
                    icon: '',
                    perms: '',
                    menuTypeCN: '',
                    sort: 9,
                    isShow: 1,
                    keepAlive: 0,
                    menuType: 1,
                    parentId: 0
                },
                keepAlive: [
                    {name: 'true', value: 1},
                    {name: 'false', value: 0}
                ],
                isShows: [
                    {name: '显示', value: 1},
                    {name: '隐藏', value: 0}
                ],
                menuType: [
                    {name: '目录', value: 1},
                    {name: '菜单', value: 2},
                    {name: '按钮', value: 3}
                ],
                rules: {
                    name: [
                        {required: true, message: '请输入菜单名称', trigger: 'blur'},
                        {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
                    ],
                    url: [
                        {required: true, message: '请填写菜单地址', trigger: 'change'}
                    ],
                    icon: [
                        {required: true, message: '请添加菜单图标', trigger: 'change'}
                    ],
                    menuType: [
                        {required: true, message: '请选择菜单类型', trigger: 'change'}
                    ],
                    sort: [
                        {required: true, message: '请填写菜单排序', trigger: 'change'}
                    ],
                    isShow: [
                        {required: true, message: '请选择菜单状态', trigger: 'blur'}
                    ],
                    parentId: [
                        {required: true, message: '请填写菜单id', trigger: 'blur'}
                    ]
                }
            }
        },
        watch: {},
        mounted() {
            bus.$on('addmenu', data => {
                this.showaddForm = true
                this.editMenuData.menuType = data.menuType
                this.editMenuData.parentId = data.parentId
                this.editMenuData.icon = data.icon
            })
        },
        methods: {
            addMenu() {
                this.editMenuData['parent_id'] = this.editMenuData.parentId
                this.editMenuData['is_show'] = this.editMenuData.isShow
                this.editMenuData['keep_alive'] = this.editMenuData.keepAlive
                this.$http({
                    url: this.$api + '/admin/menu/add',
                    method: 'post',
                    data: this.$qs.stringify(this.editMenuData)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.showaddForm = false
                        this.$message.success('菜单添加成功!')
                        // 添加菜单成功，更新菜单数据
                        let update = true
                        bus.$emit('updateMenu', update)
                    } else if (response.data.errno === 500) {
                        this.$message.error(response.data.msg)
                    }
                }).catch(error => {
                    //console.log(error)
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // dialogClose(done) {
            //   this.showEditForm = false
            // }
        }
    }
</script>
<style>
</style>>
