<template>
    <div class="padding15 defaultBackground box_sizing baseBorder">
        <div class="disflex_flex_start  defaultBackground">
            <el-form :model="bargainData"  ref="bargainData" label-width="100px" class="demo-ruleForm">
                <div class="padding15">
                    <el-input placeholder="请输入商品标题" v-model="bargainData.goods_name">
                    </el-input>
                </div>
                <el-form-item label="广告封面" required>
                    <upload-image v-model="bargainData.image_url" ></upload-image>
                </el-form-item>

                <div class="padding15 baseBorder " style="margin: 1em">
                    <el-form-item label="商品售价" required>
                        <p style="color: #ff0000;font-size: 18px;">
                            ￥{{bargainData.retail_price}}
                            <span class="bargain_price">砍价后金额：￥{{bargainData.retail_price - bargainData.cut_price}}</span>
                        </p>
                    </el-form-item>
                    <el-form-item label="可砍金额" required>
                        <el-input
                            placeholder="可砍金额应该 <= 商品售价"
                            type="text"
                            v-model="bargainData.cut_price">
                            <i slot="suffix" class="el-input__icon el-icon-s-ticket"></i>
                        </el-input>
                    </el-form-item>
                </div>


                <div class="padding15 baseBorder " style="margin: 1em">

                    <el-form-item label="普通用户砍价人数" required>
                        <el-input
                            placeholder="请输入参与砍价的人数（发起者本人可以砍一刀）"
                            type="number"
                            v-model="bargainData.cut_user_num">
                            <i slot="suffix" class="el-input__icon el-icon-user"></i>
                        </el-input>
                        <p class="red_tips">【普通用户】人均砍价：{{bargainData.cut_price / bargainData.cut_user_num}} 元，未设置新用户砍价时，默认使用普通用户砍价规则</p>
                    </el-form-item>

                    <el-form-item label="新用户砍价人数" required>
                        <el-input
                            placeholder="新用户砍价的人数（发起者本人可以砍一刀）"
                            type="number"
                            v-model="bargainData.cut_new_user_num">
                            <i slot="suffix" class="el-input__icon el-icon-user"></i>
                        </el-input>
                        <p class="red_tips"><span class="color_eb3021_important">【新用户】</span>人均砍价：{{bargainData.cut_price / bargainData.cut_new_user_num}} 元</p>
                    </el-form-item>
                </div>

                <div class="padding15 baseBorder " style="margin: 1em">
                    <!--限制条件-->
                    <el-form-item label="单人可砍次数" required>
                        <el-input
                            placeholder="单个人可发起砍价次数"
                            type="number"
                            v-model="bargainData.limit_cut_times">
                            <i slot="suffix" class="el-input__icon el-icon-user"></i>
                        </el-input>
                        <p class="red_tips">此砍价商品，单人只能发起砍价次数： {{bargainData.limit_cut_times}} 次</p>
                    </el-form-item>

                    <el-form-item label="商品可砍库存" required>
                        <el-input
                            placeholder="商品可砍库存"
                            type="number"
                            v-model="bargainData.limit_storage">
                            <i slot="suffix" class="el-input__icon el-icon-user"></i>
                        </el-input>
                        <p class="red_tips">此砍价商品，总共可发起砍价次数： {{bargainData.limit_storage}} 次</p>
                    </el-form-item>
                </div>

                <div class="padding15 baseBorder " style="margin: 1em">
                    <el-form-item label="持续时间" required>
                        <el-input-number v-model="bargainData.bargain_time_length_hour" @change="handleChange" :min="1" :max="1000" label="以小时为单位"></el-input-number>
                        <span>小时</span>
                    </el-form-item>
                    <el-form-item label="开始时间" required>
                        <el-date-picker
                            v-model="bargainData.start_time_fake"
                            type="datetime"
                            placeholder="选择活动开始时间"
                            align="right">
                        </el-date-picker>
                    </el-form-item>
                </div>

                <el-form-item label="立即上线？" required>
                    <el-switch
                        v-model="bargainData.is_online"
                        active-color="#13ce66"
                        inactive-color="#ff4949">
                    </el-switch>
                </el-form-item>
                <p style="color: #ff0000;font-size: 15px;">
                    备注：<br />
                    1、可砍金额不能低于商品售价；<br />
                    2、砍价的商品不享受折扣优惠；<br />
                    3、砍价价格和人数设置必须符合实际，人均不得低于0.01元；<br />
                    4、可以预设砍价活动，到达对应开始时间后，砍价会自动上线开始<br />
                </p>
            </el-form>
            <div  class="width50" style="margin-left: 10%;border: 1px solid #eee;">
                <el-input placeholder="请输入商品名查询" v-model="searchStr" @keyup.enter.native="getPaperList(1, 15)"
                          style="width: 500px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="getPaperList(1, 15)"></i>
                </el-input>
                <el-table
                    ref="singleTable"
                    :data="tableData.data"
                    highlight-current-row
                    @current-change="handelCurrentSelect"
                    style="width: 100%;cursor: pointer;">
                    <el-table-column
                        type="index"
                        width="50">
                    </el-table-column>
                    <el-table-column
                        property="date"
                        label="商品标题"
                        width="">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <!--<el-table-column-->
                    <!--property="name"-->
                    <!--label="商品简介"-->
                    <!--width="120">-->
                    <!--<template slot-scope="scope">-->
                    <!--<span style="margin-left: 10px">{{ scope.row.goods_brief }}</span>-->
                    <!--</template>-->
                    <!--</el-table-column>-->
                    <el-table-column
                        property="name"
                        label="商品价格"
                        width="120">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px"> ￥ {{ scope.row.retail_price }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        property="name"
                        label="猫趴价（折后价）"
                        width="120">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px"> ￥ {{discountPrice( scope.row)}}</span>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="paginaion" style="text-align: center;margin-top: 1em">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="tableData.currentPage"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="tableData.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.count">
                    </el-pagination>
                </div>
            </div>
        </div>
        <div class="text-align-right">
            <el-button type="success" round @click="submitAds">发布</el-button>
        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import uploadImage from '../../common/uploadImage';


    export default {
        data() {
            return {
                tableData: {},
                searchStr: '',
                bargainData: {
                    id: undefined,
                    goods_name: '',
                    retail_price: 0,
                    cut_price: 1,
                    cut_user_num: 2,
                    cut_new_user_num: 2,
                    bargain_time_length_hour: 24,
                    is_online: false,
                    bargain_time_length: 24 * 60 * 60 * 1000,
                    start_time_fake: new Date(), // 当前时间
                    limit_storage: 1,  //砍价的库存数量
                    limit_cut_times: 1,  //单用户限制砍价次数
                }
            }
        },
        components: {
            uploadImage
        },
        computed: {

        },
        watch: {
            bargainData: {
                handler  (newv, oldv) {
                    if (newv.cut_price === 0 || newv.cut_user_num === 0) {
                        alert('砍价金额和砍价人数均必须大于0');
                    }
                    if (newv.cut_price / newv.cut_user_num < 0.01) {
                        alert('请注意！人均金额不得小于0.01！');
                    }
                    if (newv.retail_price - newv.cut_price <= 0) {
                        alert('请注意！砍价金额不应该小于商品售价，除非你需要设置0元砍价商品');
                    }
                },
                deep: true
            }
        },
        created() {

        },
        mounted() {
            this.getPaperList();
        },
        methods: {
            discountPrice(good){
                if(good.discount){
                    return (good.retail_price*good.discount).toFixed(2);
                }
                else{
                    return good.retail_price;
                }
            },
            handleChange (num) {
                this.bargainData.bargain_time_length_hour = num;
                this.bargainData.bargain_time_length = num * 60 * 60 * 1000;
            },
            handleSizeChange(val) {
                this.getPaperList(this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(val, this.tableData.pageSize)
            },
            handelCurrentSelect (val) {
                this.bargainData.goods_id = val.id;
                this.bargainData.goods_name = val.name;
                this.bargainData.image_url = val.list_pic_url;
                this.bargainData.retail_price = val.retail_price
            },
            submitAds () {
                if (this.bargainData.retail_price - this.bargainData.cut_price < 0) {
                    alert('砍价金额不得小于商品零售价！');
                    return
                }
                if (this.bargainData.cut_price / this.bargainData.cut_user_num < 0.01) {
                    alert('请注意！【普通用户砍价】人均金额不得小于0.01！');
                    return
                }
                if (this.bargainData.cut_price / this.bargainData.cut_new_user_num < 0.01) {
                    alert('请注意！【新用户砍价】人均金额不得小于0.01！');
                    return
                }
                if (this.bargainData.cut_price === 0 || this.bargainData.cut_user_num === 0 || this.bargainData.cut_new_user_num === 0) {
                    alert('砍价金额和砍价人数均必须大于0！');
                    return
                }
                if (this.bargainData.cut_user_num <= 1) {
                    alert('普通用户砍价的人数必须大于等于 2 ！！');
                    return
                }

                if (this.bargainData.cut_new_user_num <= 1) {
                    alert('新用户砍价的人数必须大于等于 2 ！！');
                    return
                }


                if (this.bargainData.limit_storage < 1) {
                    alert('砍价库存必须大于0');
                    return
                }

                if (this.bargainData.limit_cut_times < 1) {
                    alert('单用户可砍次数必须大于0');
                    return
                }


                if (this.bargainData.is_online) {
                    this.bargainData.is_online = 1
                } else {
                    this.bargainData.is_online = 0
                }
                this.bargainData.start_time = parseInt(new Date(this.bargainData.start_time_fake).getTime() / 1000);
                this.$http({
                    url: this.$api + '/admin/bargain/addBargain',
                    method: 'post',
                    data: this.$qs.stringify(this.bargainData)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('新增砍价成功！');
                        this.$confirm('新增砍价成功!', '成功提示', {
                            confirmButtonText: '继续添加砍价',
                            cancelButtonText: '回到砍价列表',
                            type: 'warning'
                        }).then(() => {
                            // 重设当前DATA
                            this.bargainData = {
                                id: undefined,
                                goods_name: '',
                                retail_price: 0,
                                cut_price: 1,
                                cut_user_num: 1,
                                bargain_time_length_hour: 24,
                                is_online: false,
                                bargain_time_length: 24 * 60 * 60 * 1000,
                                start_time_fake: new Date(), // 当前时间
                                limit_cut_times: 1,  //单用户限制砍价次数
                                limit_storage: 1,  //砍价的库存数量
                            }
                        }).catch(() => {
                            this.$router.push('/bargainList')
                        });
                    }
                })
            },
            getPaperList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/goods/index',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 15,
                        name: this.searchStr
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                }).catch(e => {
                    loading.close()
                })
            },
        },

    }
</script>
<style>
    .bargain_price {
        color: #666666;
        font-size: 12px;
    }
    .uploadToolBox {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
</style>
