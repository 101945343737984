<template>
  <div style="width: 100%;height: 100%">
      <iframe style="width: 100%;height: 100%" src="https://kfgl.mxclass.com" frameborder="0"></iframe>
  </div>

</template>

<script>
    export default {
        name: "kefu"
    }
</script>

<style scoped>

</style>
