<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>能不能吃</el-breadcrumb-item>
                <el-breadcrumb-item>食物草稿箱</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-tabs v-model="activeName" @tab-click="tabClick">
            <el-tab-pane label="食品" name="foodList"></el-tab-pane>
            <el-tab-pane label="食品分类" name="typeList"></el-tab-pane>
        </el-tabs>

        <div style="padding: 10px">
            <!--<el-button type="primary" @click="addTypeDialogData.isShow=true" style="margin-top: 1em;">添加食物分类</el-button>-->

            <!--食品分类列表-->
            <el-table v-if="activeName=='typeList'" :data="typeList" style="width: 100%">
                <el-table-column
                    label="食品分类名称"
                    prop="name">
                </el-table-column>
                <el-table-column
                    label="分类序号"
                    prop="sort_order">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <!--<el-button-->
                            <!--size="mini"-->
                            <!--@click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
                        <el-button size="mini" type="default" @click="editType(scope.row.id)">修改食品分类</el-button>
                        <!--<el-button size="mini" type="primary" @click="addFoodDialogData.isShow=true">添加食品</el-button>-->
                        <el-button size="mini" type="success" @click="downLineFood(scope.row)">上线</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!--食品列表-->
            <el-table v-if="activeName=='foodList'" key="id" :data="foodList" style="width: 100%">
                <el-table-column width="200" label="食品名称" prop="name"></el-table-column>
                <el-table-column width="200" label="食品图片" prop="wap_banner_url">
                    <template slot-scope="scope">
                        <img class="food_picture" :src="scope.row.wap_banner_url" alt="">
                    </template>
                </el-table-column>
                <el-table-column width="100" label="能吃类型" prop="wap_banner_url">
                    <template slot-scope="scope">
                        <div class="single_data">
                            <span v-if="scope.row.eat_type==1" style="color: #1AAD19;">能吃</span>
                            <span v-else-if="scope.row.eat_type==2" style="color: #ff6602;">慎吃</span>
                            <span v-else style="color: red;">禁吃</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="100" label="分类序号" prop="sort_order"></el-table-column>
                <el-table-column label="食品描述" prop="front_desc"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <!--<el-button-->
                        <!--size="mini"-->
                        <!--@click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
                        <el-button size="mini" type="default" @click="editFood(scope.row.id)">修改食品分类</el-button>
                        <el-button size="mini" type="success" @click="downLineFood(scope.row)">上线</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!--修改食品分类弹窗-->
        <edit-type-dialog
            :isDraft="true" :editTypeDialogData="editTypeDialogData" @editDraftSuccess="editDraftSuccess">

        </edit-type-dialog>

        <!--修改食品弹窗-->
        <edit-food-dialog :isDraft="true" :editFoodDialogData="editFoodDialogData" @editDraftSuccess="editDraftSuccess"></edit-food-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import addTypeDialog from './children/addTypeDialog'
    import addFoodDialog from './children/addFoodDialog'
    import editFoodDialog from './children/editFoodDialog'
    import editTypeDialog from './children/editTypeDialog'

    export default {
        data: function () {
            return {
                activeName: 'foodList',
                typeList: [],  //分类草稿列表
                foodList: [],  //食品草稿列表
                editTypeDialogData:{
                    isShow: false,
                    type_id: null,
                },
                editFoodDialogData:{
                    isShow: false,
                    food_id: null,
                }
            }
        },
        components: {
            addTypeDialog,
            addFoodDialog,
            editFoodDialog,
            editTypeDialog
        },
        methods: {
            //点击编辑食品分类
            editType(type_id){
                this.editTypeDialogData.isShow = true;
                this.editTypeDialogData.type_id = type_id;
            },
            //点击编辑食品
            editFood(food_id){
                this.editFoodDialogData.isShow = true;
                this.editFoodDialogData.food_id = food_id;
            },
            //上线食品
            downLineFood(food) {
                this.$confirm('此操作上线食品分类或食品《'+food.name+'》, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$api + 'admin/foodsCategory/switch', {
                        foods_category_id: food.id,
                        is_show: 1
                    }).then(res => {
                        if(res.data.errno===0){
                            this.$message.success("食品/分类上线成功");
                            this.typeList = [];
                            this.foodList = [];
                            this.getAllFoodDraft();
                            this.$store.dispatch('getFoodsList');
                        }
                    })
                }).catch(() => {
                });
            },
            //获取所有食品的草稿
            getAllFoodDraft(){
                this.$http.post(this.$api + 'admin/foodsCategory/draft', {

                }).then(res => {
                    if(res.data.errno===0){
                        res.data.data.map((item,index) => {
                            if(item.parent_id){
                                this.foodList.push(item);
                            }else{
                                this.typeList.push(item);
                            }
                        });
                        // this.$message.success("食品/分类下线成功");
                        // this.$store.dispatch('getFoodsList');
                    }
                })
            },
            editDraftSuccess(){
                this.typeList = [];
                this.foodList = [];
                this.getAllFoodDraft();
            },
            tabClick(tab, event){

            }
        },
        created(){

        },
        mounted() {
            this.getAllFoodDraft();
        },
    }
</script>

<style lang="scss" scoped>
    .single_food{
        display: flex;
        align-items: center;
        margin: 2px 0;
        padding: 10px;
        border-radius: 4px;
        &.thead{
            font-weight: bold;
            font-size: 15px;
            color: #000;
            &:hover{
                background-color: #f6faff;
            }
        }
        &:hover{
            background-color: #fff;
        }
        .single_data{
            /*flex: 1;*/
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.front_desc{
                /*flex: 1;*/
                width: 300px;
                justify-content: left;
            }
            .food_picture{
                width: 80px;
                height: 80px;
                border-radius: 4px;
            }
        }
    }

    .pointer{
        cursor: pointer;
    }

    .food_picture{
        width: 80px;
        height: 80px;
        border-radius: 4px;
    }


</style>
