<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i>商品管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="container">
            <div class="padding15">
                <el-switch
                    v-model="is_public"
                    active-text="公众号文章"
                    inactive-text="PGC文章">
                </el-switch>
            </div>
            <div v-if="!is_public">
                <el-form style="width: 50%" label-width="120px"  :model="pushData" ref="pushData">
                    <el-form-item label="标题*">
                        <el-input v-model="pushData.title"></el-input>
                    </el-form-item>
                    <el-form-item label="二级标题*">
                        <el-input v-model="pushData.subtitle"></el-input>
                    </el-form-item>
                    <!--<el-form-item label="分类">-->
                    <!--<el-select v-model="pushData.topic_category_id" >-->
                    <!--</el-select>-->
                    <!--</el-form-item>-->


                    <el-form-item label="专题主图*">
                        <upload-image title="专题显示主图【比填】*" v-model="pushData.scene_pic_url" style="margin-right: 50px">
                            <div style="text-align: center;">4：3</div>
                        </upload-image>
                    </el-form-item>
                    <el-form-item label="*立即上线？">
                        <el-switch on-text="" off-text="" v-model="pushData.is_show"></el-switch>
                    </el-form-item>
                    <el-form-item label="排序*">
                        <el-input-number v-model="pushData.sort_order" :min="1" ></el-input-number>
                    </el-form-item>
                    <el-form-item label="设置阅读量">
                        <el-input-number v-model="pushData.read_count" :min="0"></el-input-number>
                    </el-form-item>
                    <el-form-item label="推荐价格* ￥">
                        <el-input v-model="pushData.price_info"></el-input>
                    </el-form-item>

                </el-form>
                <h1 style="padding: 1em;text-align: center;">专题详情</h1>
                <ueditor  style="line-height: 20px;" v-model="pushData.content"></ueditor>
            </div>

            <div v-if="is_public">
                <el-form style="width: 50%" label-width="120px"  :model="pushData" ref="pushData">
                    <el-form-item label="标题*">
                        <el-input v-model="pushData.title"></el-input>
                    </el-form-item>
                    <el-form-item label="二级标题*">
                        <el-input v-model="pushData.subtitle"></el-input>
                    </el-form-item>
                    <el-form-item label="专题主图*">
                        <upload-image title="专题显示主图【比填】*" v-model="pushData.scene_pic_url" style="margin-right: 50px">
                            <div style="text-align: center;">4：3</div>
                        </upload-image>
                    </el-form-item>
                    <el-form-item label="*立即上线？">
                        <el-switch on-text="" off-text="" v-model="pushData.is_show"></el-switch>
                    </el-form-item>

                    <el-form-item label="公众号文章链接*">
                        <el-input v-model="pushData.content" placeholder="请输入公众号文章链接"></el-input>
                    </el-form-item>
                </el-form>
            </div>

        </div>
        <div class="operationBtn">
            <el-button class="editor-btn" type="primary" @click="submitPaper">提交</el-button>
            <!--<el-button class="editor-btn" type="default" @click="saveAsDraft">存为草稿</el-button>-->
        </div>


    </div>
</template>

<script>
    import ueditor from '../../common/editor'
    import bus from '../../common/bus'
    import uploadImage from '../../common/uploadImage'

    export default {
        data: function () {
            return {
                is_public: true, // 是否食pgc的文章，而不是转载公众号
                currentCategory: '', //选择胡当前专题一级分类
                currentCategoryArr: [], //选择胡当前专题子分类数组
                column: '',
                columnList: [],
                tagsList: [],
                addTagsList: [],
                addTagsForm: false,
                templateDialogVisible: false,
                title: '新增文章',
                templateSearchName: '',
                fileList: '',
                category: [],
                templateData: [],
                recommendChecked: 0, // 推荐类型
                brandOptions: [], // 品牌集合
                pushData: {
                },
                fileList_arr: [], // 提交的专题图数组
                isThisBrandNotDeleted: false, // 判断该专题是否被删除
            }
        },
        watch: {
        },
        methods: {
            // 上传轮播图
            handleImageSuccess (res) {
                if (res.errno == 0) {
                    this.fileList_arr.push(res.data)
                }
            },
            // 删除专题属性
            handleRemoveAttribute (index) {
                if(index>=0&&index<this.pushData.attribute.length)
                {
                    this.pushData.attribute.splice(index, 1);
                }
            },
            // 新增一条专题属性
            handleAddAttribute () {
                let obj = {"name":"", "value":""};
                this.pushData.attribute.push(obj);
            },

            handleClose(tag) {
                this.addTagsList.splice(this.addTagsList.indexOf(tag), 1);
            },
            submitPaper() {
                const loading = this.$loading({
                    lock: true,
                    text: '豆豆正在打扰爸爸敲代码...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                // 判断是否需要直接设置成新品或者是是否上线销售，
                this.pushData.is_show? this.pushData.is_show = 1:this.pushData.is_show = 0;

                if (this.is_public) {
                    this.pushData.type = 1

                } else {
                    this.pushData.type = 2
                }
                // 提交所有参数
                this.$http({
                    url: this.$api + '/admin/topic/add',
                    method: 'post',
                    data: this.$qs.stringify(this.pushData)
                }).then(response => {
                    loading.close()
                    if (response.data.errno === 0) {
                        this.$notify({
                            title: '成功',
                            message: '添加专题成功!',
                            type: 'success'
                        });
                        this.$router.push('/topiclist');
                        let reload = true;
                        bus.$emit('reloadPaper', reload)
                    }
                })
                loading.close()
            },
        },
        created() {
            this.Auth_token = localStorage.getItem('auth_token')
            // this.listCategory()
            // this.getBrand()
        },
        mounted() {
        },
        components: {
            ueditor, uploadImage
        },
    }
</script>
<style>

    .el-upload-list{
        display: none !important;
    }
</style>
<style scoped>
    .operationBtn{
        text-align: right;
        padding: 20px;
    }
    .el-tag + .el-tag {
        margin-left: 10px;
    }

    .audio-div {
        margin: 20px 0 20px 0;
    }

    .article-edit span {
        width: 100px;
        margin-left: 20px;
    }

    .display-flex {
        display: flex;
    }
</style>
