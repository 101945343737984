import Vue from 'vue'
import vuex from 'vuex'
Vue.use(vuex);

// 模块导入
import dialog_store from './modules/dialog_store.js';//引入段落弹窗模块
import login from './modules/login.js';//引入login 模块
import menu from './modules/menu.js';//引入菜单模块

// 模块挂载
export default new vuex.Store({
	// 根节点的状态管理
    state:{//state
        login:false,
        dialogData: [],
        adsdATA: '',
        collapse: false, // 当前菜单是否折叠
        categoryList: [], //分类列表
        getFoodsList: [],  //食品列表
        getDiseaseList: [], // 疾病列表
        UA: 'WEB' // 网页的类型，是web还是移动端
    },
    /*
    $store.getters.not_show 的值是不能直接修改的 , 需要对应的 state 发生变化才能修改。
    */
    getters:{ // 相当于vue中的计算conputed方法
        // not_show(state){//这里的state对应着上面这个state
        //     return !state.login;
        // }
    },
    mutations:{
        loadAdsList (state) {
                Vue.prototype.$http({
                    url: Vue.prototype.$api + '/chigua/admin/port/list',
                    method: 'post',
                }).then(res => {
                    if (res.data !== null) {
                        this.adsdATA = res.data.data
                        console.log(this.adsdATA)
                    } else {
                        this.$message.error('列表加载失败')
                    }
                }).catch(res => {

                })
        },
        checkLogin(state,data){//这里的state对应着上面这个state
            state.show = data
            //你还可以在这里执行其他的操作改变state
        },
        setLoginStatus(state){
            state.dialogData = ''
        },
        getCategoryList (state, response) {
            state.categoryList = response.data.data
        },
        //食物列表
        getFoodsList (state, response) {
            let newRes = response.data.data.map((item,index) => {
                item.childrens = item.children
                delete item.children
                return item;
            });
            state.getFoodsList = newRes;
        },
        //疾病列表
        getDiseaseList (state, response) {
            let newRes = response.data.data.map((item,index) => {
                item.childrens = item.children
                delete item.children
                return item;
            });
            state.getDiseaseList = newRes;
            console.log(state.getDiseaseList)
        },
        changeCollapse (state, collapse) {
            state.collapse = collapse
        },
        changeUA (state, UA) {
            state.UA = UA
        }
    },
    actions:{
        getCategoryList (context, state) {
            Vue.prototype.$http({
                url: Vue.prototype.$api + '/admin/category/all',
                method: 'get'
            }).then(response => {
                if (response.data.errno === 0) {
                    context.commit('getCategoryList', response)
                } else {
                    console.log(response.data.errmsg? response.data.errmsg:response.data.data)
                }
            })
        },
        //食物列表
        getFoodsList (context, state) {
            Vue.prototype.$http({
                url: Vue.prototype.$api + '/admin/foodsCategory/all',
                method: 'post'
            }).then(response => {
                if (response.data.errno === 0) {
                    context.commit('getFoodsList', response);
                } else {
                    console.log(response.data.errmsg? response.data.errmsg:response.data.data)
                }
            })
        },
        //疾病列表
        getDiseaseList (context, state) {
            Vue.prototype.$http({
                url: Vue.prototype.$api + '/admin/disease/all',
                method: 'post'
            }).then(response => {
                if (response.data.errno === 0) {
                    context.commit('getDiseaseList', response);
                } else {
                    console.log(response.data.errmsg? response.data.errmsg:response.data.data)
                }
            })
        },
        changeCollapse (context, collapse) {
            context.commit('changeCollapse', collapse)
        },
        changeUA (context, UA) {
            context.commit('changeUA', UA)
        },
        switch_dialog(context,data){//这里的context和我们使用的$store拥有相同的对象和方法
            context.commit('switch_dialog',data);
            //你还可以在这里触发其他的mutations方法
        },
        updateDialogData (context,data) {
            context.commit('updateDialogData',data);
            console.log(data)
        }
    },
    // 引入根节点下的子模块，子模块中如果还有子模块可在对应的二级模块引入
    modules: {
        dialog: dialog_store,
        login: login,
        menu: menu
    }
})
