<template>
    <div>
        <div class="crumbs" id="invoiceId">
            <label><span>{{ $route.meta.title }}</span>（共{{ total }}条）</label>
        </div>
        <div class="button-tool">
            <div style="display: flex">
                <el-input style="width: 500px" placeholder="搜索用户名、发票抬头" v-model="searchTitle"
                          @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
                <el-radio-group v-model="status" class="radio-top"
                                @change="handleSearchByState">
                    <el-radio v-for="item in invoiceStatus" :label="item.value" :name="item.name" :key="item.value">
                        {{item.name}}
                    </el-radio>
                </el-radio-group>
            </div>
            <div>
                <el-button type='success' @click="exportInvoice">导出发票列表</el-button>
            </div>
        </div>
        <el-table :data="tableData"  border class="text-centre my-table"
                  header-row-class-name=""
                  style="width: 100%">

            <el-table-column label="用户名" width="100">
                <template slot-scope="scope">
                    <div>
                        <span>{{ scope.row.nickname === null ? '-' : scope.row.nickname }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="发票类型" width="80">
                <template slot-scope="scope">
                    <span v-for="item in invoiceType" v-if="item.value==scope.row.invoiceType">{{item.name}}</span>
                </template>
            </el-table-column>

            <el-table-column label="抬头类型" width="80">
                <template slot-scope="scope">
                    <span v-for="item in invoiceRiseType" v-if="item.value==scope.row.titleType">{{item.name}}</span>
                </template>
            </el-table-column>

            <el-table-column label="发票抬头" width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.title === null ? '-' : scope.row.title }}</span>
                </template>
            </el-table-column>

            <el-table-column label="纳税人识别号" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.taxpayerNo === null ? '-' : scope.row.taxpayerNo }}</span>
                </template>
            </el-table-column>
            <el-table-column label="地址和电话" width="250">
                <template slot-scope="scope">
                    <span>{{ scope.row.addressAndPhone === null ? '-' : scope.row.addressAndPhone }}</span>
                </template>
            </el-table-column>

            <el-table-column label="账户和开户行" width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.openAccount === null ? '-' : scope.row.openAccount }}</span>
                </template>
            </el-table-column>

            <el-table-column label="收件人" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.recipients === null ? '-' : scope.row.recipients }}</span>
                </template>
            </el-table-column>

            <el-table-column label="联系电话" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.mobile === null ? '-' : scope.row.mobile }}</span>
                </template>
            </el-table-column>

            <el-table-column label="收件地址">
                <template slot-scope="scope">
                    <span>{{ scope.row.addressee === null ? '-' : scope.row.addressee }}</span>
                </template>
            </el-table-column>

            <el-table-column label="开票金额" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.payPrice ? scope.row.payPrice : '0' }}</span>
                </template>
            </el-table-column>

            <el-table-column label="发票状态" width="80">
                <template slot-scope="scope">
                    <div class="text-centre">
                        <el-button
                            title="设为未开"
                            v-if="scope.row.isOpen"
                            @click="openInvoice(scope.row)"
                            type="text"
                            style="color: #8c939d"
                            size="small">
                            已开
                        </el-button>

                        <el-button
                            title="设为已开"
                            v-else

                            @click="openInvoice(scope.row)"
                            type="text"
                        >
                            未开
                        </el-button>

                    </div>
                </template>
            </el-table-column>

        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                invoiceStatus: [
                    {
                        name: '全部',
                        value: ''
                    },
                    {
                        name: '已开发票',
                        value: 1
                    },
                    {
                        name: '未开发票',
                        value: 0
                    }
                ],
                invoiceRiseType: [],
                invoiceType: [],
                tableData: [],
                activityId: '',
                applyUserName: '',
                pos: 0,
                spanArr: [],

                activeName: '',
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 10,
                status: '',
                commonData: '',
                tableHeight: 600,
                searchTitle: ''
            }
        },
        components: {},
        watch: {},
        created() {
            // 加载活动列表
            this.activityId = this.$route.query.activityId
            // this.getList(this.searchTitle, 1, this.size)
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.7
            this.invoiceRiseType = this.$getDicByName('INVOICE_RISE_TYPE')
            this.invoiceType = this.$getDicByName('INVOICE_TYPE')
        },
        mounted() {
            bus.$on('activityApplyChange', msg => {
                this.activityId = msg
                //console.log(this.activityId)
                this.getList(this.searchTitle, 1, this.size)
            })
            const id = this.$route.query.activityId
            if (id) {
                // //console.log(id)
                this.activityId = id
                this.getList(this.searchTitle, 1, this.size)
            }
        },
        methods: {
            closeInvoice(done) {
                this.getList('', this.currentPage, this.size)
                done()
            },
            openInvoice(row) {
                let title
                let isOpenStatus
                if (row.isOpen === 1) {
                    title = '您正在进行【撤销开发票】操作，确认？'
                    isOpenStatus = 0
                } else {
                    title = '您正在进行【开发票】操作，确认？'
                    isOpenStatus = 1
                }
                this.$confirm(title, '警示', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/activity/invoice/update',
                        method: 'post',
                        data: this.$qs.stringify({
                            invoiceId: row.invoiceId,
                            invoiceStatus: isOpenStatus
                        })
                    }).then(response => {
                        if (response.data.errno == 0) {
                            this.$message.success('更新成功！')
                            this.getList(this.searchTitle, this.currentPage, this.size)
                        } else {
                            this.$message.error('数据加载失败！')
                        }

                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });


            },
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0 || columnIndex === 8 || columnIndex === 9 || columnIndex === 10) {
                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return {
                        rowspan: _row,
                        colspan: _col
                    }
                }
            },
            getSpanArr(data) {
                this.spanArr = []
                this.pos = 0
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1);
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].id === data[i - 1].id) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }
            },
            handleSearchByState() {
                this.getList(this.searchTitle, this.currentPage, this.size)
            },
            handleSearchByTitle() {
                this.getList(this.searchTitle, this.currentPage, this.size)
            },
            handleSizeChange(val) {
                this.getList(this.searchTitle, this.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getList(this.searchTitle, val, this.size)
            },
            getList(title, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/chigua/admin/activity/invoice/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        activityId: this.activityId,
                        isOpen: this.status,
                        query: title,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size

                        this.getSpanArr(this.tableData)
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                    loading.close()
                })
            },

            //导出列表
            exportInvoice() {
                this.$export({
                    url: this.$api + '/chigua/admin/activity/invoice/export',
                    data: {
                        activityId: this.activityId,
                        query: this.searchTitle,
                        isOpen: this.status
                    }
                });
            },

        }
    }
</script>
<style>
    .radio-pay {
        margin-left: 30px;
    }

    .radio-top {
        margin-top: 10px;
        margin-left: 20px;
    }

    .my-table.el-table th {
        background: rgba(197, 145, 234, 0.3) !important;
        text-align: center;
    }

    .el-radio + .el-radio {
        margin-left: 10px;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .text-centre {
        text-align: center;
    }

    .pointer {
        cursor: pointer;
    }

    .el-dialog__body {
        padding-top: 0px;
    }

    .invoiceTable td {
        border: solid #add9c0;
        border-width: 0px 0px 1px 0px;
        padding: 10px 5px;
        margin-left: 10px;
    }

    .invoiceTable table {
        border: solid #add9c0;
        border-width: 1px 0px 0px 1px;
    }
</style>
