<template>
    <div class="upload-image-my">
        <el-upload
            v-if="listType === 'picture-card'"
            :action="action"
            list-type="picture-card"
            v-loading="uploadLoading"
            :headers="{ Auth_token }"
            :file-list="fileList"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-progress="handleProgress"
            :on-success="handleImageSuccess"
            :before-upload="beforeAvatarUpload">
            <img v-if="fileUrl" :src="fileUrl" class="avatar width100" >
            <i class="el-icon-plus" v-else></i>
        </el-upload>

        <el-upload
            v-else
            :action="action"
            list-type="picture"
            :headers="{ Auth_token }"
            :file-list="fileList"
            v-loading="uploadLoading"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-progress="handleProgress"
            :on-success="handleImageSuccess"
            :before-upload="beforeAvatarUpload"
        >
            <slot></slot>
            <img v-if="imageUrl" :src="imageUrl" class="avatar" :style="'width:' + width + 'px'">
            <i v-if="!imageUrl && !PHUrl_COM " class="el-icon-upload avatar-uploader-icon"></i>
            <img v-if="PHUrl_COM && !imageUrl" :src="PHUrl_COM" class="avatar" :style="'width:' + width + 'px'">
            <span v-else></span>
            <span v-if="imageUrl">点击图片添加/更换图片</span>
            <p v-else>{{title}}</p>

            <el-progress v-show="percentage>0&percentage<100" :percentage="percentage"></el-progress>
        </el-upload>

    </div>
</template>

<script>
    import bus from '../common/bus'
    export default {
        data: function () {
            return {
                percentage: 0,
                imageUrl: '',
                Auth_token: '',
                uploadLoading: false, //上传文件loading状态
                fileLength: 0
            }
        },
        methods: {
            handleProgress(event, file, fileList) {
                this.percentage = event.percent
            },
            beforeAvatarUpload(file) {
                this.uploadLoading = true
                // //console.log(file)
                const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isJPG) {
                    this.$message.error('上传头像图片只能是 jpg,png,gif 格式!');
                }
                if (!isLt2M) {
                    this.$message.error('上传图片大小不能超过 2MB!');
                }
                return isJPG && isLt2M;
            },
            handleImageSuccess(res, file) {
                if (res.errno === 0) {
                    this.imageUrl = res.data;
                    this.$notify({
                        title: '成功',
                        message: '图片上传成功！',
                        type: 'success'
                    });
                } else {
                    this.$notify.error({
                        title: '上传失败',
                        message: res.msg
                    });
                }
                // 关闭loading
                this.uploadLoading = false
                this.fileLength += 1
                // this.$emit('fileUrlModel', this.imageUrl)
            },
            handleRemove(file, fileList) {
                this.$http({
                    url: this.$api + 'admin/file/deleteSingleFile',
                    method: 'post',
                    data: this.$qs.stringify({ filename: file.response.data || ''}),
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('删除成功');
                        // 删除后清空
                        this.imageUrl = '';
                        this.fileLength -= 1
                    } else {
                        this.$message(response.data.msg)
                    }
                })

            },
            handlePictureCardPreview(file) {
                this.imageUrl = file.response ? file.response.data : file.url
            },
        },
        created() {
            this.Auth_token = localStorage.getItem('auth_token')
        },
        computed:{
            PHUrl_COM () {
                return this.PHUrl
            }
        },
        props: {
            limitLength: {
              type: Number,
              default: 1
            },
            title: {
              type: String,
              default: '上传图片,支持jpg,png,gif格式'
            },
            fileUrl: {
                type: String,
                default: ''
            },
            PHUrl: {
                type: String,
                default: ''
            },
            action: {
                type: String,
                default: function () {
                    return this.$api + this.UPLOAD_HEADER_IMG
                }
            },
            listType: {
                type: String,
                default: 'picture'
            },
            fileList: {
                type: Array,
                default: function () {
                    return []
                }
            },
            width: {
                default: 320
            }
        },
        model: {
            // 使用model， 这儿2个属性，prop属性说，我要将msg作为该组件被使用时（此处为aa组件被父组件调用）v-model能取到的值，event说，我emit ‘cc’ 的时候，参数的值就是父组件v-model收到的值。
            prop: 'fileUrl',
            event: 'fileUrlModel'
        },
        watch: {
            imageUrl(data) {
                this.$emit('fileUrlModel', this.imageUrl)
                bus.$emit('fileTraceBack', this.imageUrl)
            },
            fileUrl(data) {
                this.imageUrl = this.fileUrl ? this.fileUrl : ''
            }
        }
    }
</script>
<style>
    .upload-image-my .el-upload--text {
        width: auto;
        height: auto;
        background-color: #d1dae5 !important;
    }

    .upload-image-my .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }

    .upload-image-my .el-upload:hover {
        border-color: #409EFF;
    }

    .upload-image-my .avatar-uploader-icon {
        font-size: 50px;
        color: #8c939d;
        width: 320px;
        height: auto;
        line-height: 128px;
        text-align: center;
        margin: 0;
    }

    .upload-image-my .avatar {
        /*width: 320px;*/
        height: auto;
        display: block;
    }
</style>
<style scoped>

</style>
