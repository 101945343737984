<template>
    <div>
        <div class="button-tool">
            <div style="display: flex">
                <el-input placeholder="搜索姓名、公司名、手机号码、微信" v-model="searchState" @keyup.enter.native="handleSearchByTitle" style="width: 450px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
                <el-radio-group v-model="isJoin" style="margin-right: 20px;margin-top: 10px" @change="handleContentType">
                    <el-radio :label="-1">全部</el-radio>
                    <el-radio :label="0">未参加</el-radio>
                    <el-radio :label="1">已参加</el-radio>
                </el-radio-group>
                <el-button type="primary" @click="exportCard">导出列表</el-button>
            </div>
        </div>
        <el-table :data="tableData"  :header-cell-style="{background:'#f5f7f8'}" @selection-change="handleSelectionChange" style="width: 100%">
            <el-table-column label="微信名" >
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.heads" style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.nickname }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="姓名" width="120">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="性别" width="80">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ ['', '男', '女'][scope.row.sex] }}</span>
                </template>
            </el-table-column>
            <el-table-column label="公司"width="300">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.company }}</span>
                </template>
            </el-table-column>
            <el-table-column label="职务" width="150">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.jobPosition }}</span>
                </template>
            </el-table-column>
            <el-table-column label="手机号码" width="200">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.mobile }}</span>
                </template>
            </el-table-column>
            <el-table-column label="是否参加活动" width="120">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.isJoin == 0?"否":"是" }}</span>
                </template>
            </el-table-column>
            <el-table-column label="报名活动" width="200">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.joinActivity }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>
    </div>
</template>

<script>
import bus from "../../common/bus";
import commonlist from "../../common/userCommon";

export default {
  data: function() {
    return {
      activeName: "全部",
      // loading: true,
      tableData: [],
      tagSwitchData: [],
      isJoin: "",
      // 选择数组
      selectList: [],
      contentType: "",
      contentTypes: [],
      currentPage: 1,
      total: 0,
      size: 0,
      currentTagId: "",
      searchState: "",
      paperStatus: "未上线",
      paperId: "",
      showCommon: false,
      commonData: "",
      addPaperData: {
        name: ""
      },
      editPaperData: {
        name: ""
      },
      paperTypeData: [],
      editPaperStr: "新  增",
      oprationType: "add",
      tableHeight: 600,
      is_edit: false
    };
  },
  components: {
    commonlist
  },
  watch: {
    selectList: function() {}
  },
  created() {
    this.getPaperList(0, 1, 10);
    this.tableHeight = `${document.documentElement.clientHeight}` * 0.653;
    this.contentTypes = this.$getDicByName("CONTENT_TYPE");
  },
  mounted() {
    bus.$on("reloadPaper", res => {
      if (res) {
        // //console.log(res);

        this.getPaperList(this.currentTagId, this.currentPage, this.size);
      }
    });
  },
  methods: {
    handleSelectionChange(val) {
      this.selectList = val;
    },
    // 导出列表
    exportCard() {
      var idlist = [];

      for (var item in this.selectList) {
        if (item == this.selectList.length - 1) {
          idlist = idlist + this.selectList[item].id;
        } else {
          idlist = idlist + this.selectList[item].id + ",";
        }
      }
      this.$export({
        url: this.$api + "/api/test/account/export",
        data: {
          query: this.searchState,
          isJoin: this.isJoin,
        }
      });
    },

    //按标题搜索列表
    handleContentType() {
      this.getPaperList(this.currentTagId, this.currentPage, this.size);
    },
    //按标题搜索列表
    handleSearchByTitle() {
      this.getPaperList(this.currentTagId, this.currentPage, this.size);
    },
    handleSizeChange(val) {
      this.getPaperList(this.currentTagId, this.currentPage, val);
    },
    handleCurrentChange(val) {
      this.getPaperList(this.currentTagId, val, this.size);
    },
    getPaperList(type, page, length) {
      const loading = this.$loading({
        lock: true,
        text: "已经很努力加载啦~",
        spinner: "el-icon-loading",
        background: "rgba(183, 183, 183, 0.57)"
      });
      if ((page === undefined) | (page === null)) {
        page = 1;
      } else if ((length === undefined) | (length === null)) {
        length = this.size;
      }
      this.$http({
        url: this.$api + "/api/test/account/page/admin",
        method: "post",
        data: this.$qs.stringify({
          queryType: type,
          isJoin: this.isJoin,
          query: this.searchState,
          page: page,
          limit: length
        })
      }).then(response => {
        // this.loading = false
        if (response.data.data != null) {
          for (var item in response.data.data.records) {
            if (response.data.data.records[item].status === 1) {
              response.data.data.records[item].paperStatus = "可上线";
              // response.data.data.records[item].color = '#e3e3e3'
            } else if (response.data.data.records[item].status === 2) {
              response.data.data.records[item].paperStatus = "已上线";
              // response.data.data.records[item].color = '#49293'
            } else if (response.data.data.records[item].status === 3) {
              response.data.data.records[item].paperStatus = "已下线";
              // response.data.data.records[item].color = '#ff0000'
            } else {
              response.data.data.records[item].paperStatus = "未知状态";
            }
          }
          this.tableData = response.data.data.records;
          this.currentPage = response.data.data.current;
          this.total = response.data.data.total;
          this.size = response.data.data.size;
          // //console.log(this.tableData);
        } else {
          this.$message.error(response.data.msg);
        }
        loading.close();
      });
    },
    switchTag(tab) {
      for (var i in this.tagSwitchData) {
        if (tab.name === this.tagSwitchData[i].name) {
          this.searchState = "";
          this.getPaperList(this.tagSwitchData[i].id, 1, 10);
          this.currentTagId = this.tagSwitchData[i].id;
        }
      }
    }
  }
};
</script>
<style>
.ft25 {
  font-size: 25px;
}

.ft12 {
  font-size: 12px;
}

.red {
  color: red;
}

.pointer {
  cursor: pointer;
}
</style>
<style scoped>
/*.crumbs {*/
/*display: flex;*/
/*justify-content: space-between;*/
/*}*/
.tagSwitch {
  width: 100%;
  height: auto;
}
</style>
