<template>
    <div v-if="imgBool" @click="closePreviewImage">
        <el-dialog :visible.sync="imgBool" :show-close="true" title="预览图片">
            <img @click="closePreviewImage" :src="imgShowPath" style="width: 100%;">
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../common/bus'
    export default {
        name: "previewImage",
        data() {
            return {

            }
        },
        props: {
            imgPath: {
                type: String,
                default: ''
            }
        },
        computed: {
            imgShowPath () {
              return this.imgPath
            },
            imgBool: {
                get: function () {
                    return !!this.imgShowPath
                },
                set: function () {
                }
            }
        },
        methods: {
            closePreviewImage () {
                this.imgBool = false;
                bus.$emit('closeImageHub')
                this.$emit('closeImageHub', true)
            }
        }
    }
</script>

<style scoped>

</style>
