<template>
    <div>
        <div class="crumbs">
            <label>
                <span>文章列表</span>（用户发布）</label>

            <el-button type="primary" @click="editTagType_btn" class="tagButton">标签管理</el-button>
            <el-input class="searchBar" placeholder="请输入标题查询" v-model="searchState"
                      @keyup.enter.native="handleSearchByTitle">
                <i class="el-icon-search el-input__icon pointer " slot="suffix" @click="handleSearchByTitle"></i>
            </el-input>
            <el-dialog title="标签管理" :visible.sync="addPaperType_bool" width="30%">
                <!-- 可拖拽 -->
                <div class="container">
                    <div class="drag-box">
                        <div class="drag-box-item">
                            <div class="dragitem tabletd">
                                <div style="width: 15%">标签名</div>
                                <div style="width: 20%">操作</div>
                            </div>
                            <draggable v-model="allTaglist" @remove="removeHandle" :options="dragOptions">
                                <transition-group tag="div" id="todo" class="item-ul">
                                    <div v-for="(item,index2) in allTaglist" class="drag-list" :key="item.name">
                                        <!-- <img :src="item.picture">/ -->
                                        <div class="dragitem tabletd">
                                            <div class="dragimg tabletd" style="width: 20%">
                                                <span style="margin-left: 10px">{{ item.name }}</span>
                                            </div>
                                            <div title="操作" class='tabletd' style="font-size: 20px;"
                                                 v-if="item.editable == 1">
                                                <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                                                   @click="editTagType_btn_inside(item)"></i>
                                                <i title="删除" class="el-icon-delete ft25 red pointer"
                                                   @click="deletePaperType(item)"></i>
                                                <i class="ft16 pointer"
                                                   :style="item.status == 1?'color:red':'color:#409eff'"
                                                   @click="toggleState(item)">{{ item.status == 1? "禁用":"启用" }}</i>
                                            </div>
                                        </div>
                                    </div>
                                </transition-group>
                            </draggable>
                        </div>
                    </div>
                </div>
                <el-form v-if="is_edit">
                    <el-form-item class="formFooter">
                        <el-input v-model="editPaperData.name" placeholder="编辑标签" require class="footInput"></el-input>
                        <el-button type="primary" @click="submit_edit_paper_type()" class="footAddButton">保存编辑
                        </el-button>
                    </el-form-item>
                </el-form>

                <el-form>
                    <el-form-item class="formFooter">
                        <el-input v-model="addPaperData.name" placeholder="新增标签" require class="footInput"></el-input>
                        <el-button type="primary" @click="submit_add_paper_type()" class="footAddButton">新 增</el-button>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
          <el-button @click="changeBanaSort">完 成</el-button>
        </span>
            </el-dialog>
        </div>
        <div class="tagSwitch disflexct">
            <el-tabs v-model="activeName" @tab-click="switchTag">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="(item.id).toString()"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table :data="tableData" @selection-change="handleSelectionChange" >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="标题">
                <template slot-scope="scope" width="120">
                    <!-- <el-popover trigger="hover" placement="top"> -->
                    <!-- <p>姓名: {{ scope.row.name }}</p> -->
                    <!-- <p>住址: {{ scope.row.address }}</p> -->
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                        <span>{{ scope.row.title }}</span>
                    </div>
                    <!-- </el-popover> -->
                </template>
            </el-table-column>
            <el-table-column label="标签">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <el-tag v-if="scope.row.tag!=null" size="medium">{{ scope.row.tag }}</el-tag>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="作者" width="120">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <span>{{scope.row.author }}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="评论数" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.commentNum === null? 0:scope.row.commentNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="点赞数" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.praiseNum === null? 0:scope.row.praiseNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="收藏数" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.praiseNum === null? 0:scope.row.praiseNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="分享" width="80">
                <template slot-scope="scope">
                    <span>{{ scope.row.forwardNum === null? 0:scope.row.forwardNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="发布时间" width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span>{{ formatStrTime(scope.row.releaseTime,16) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px;
     align-items: flex-end;">
                        <!-- 添加标签 -->
                        <i title="添加标签" class="el-icon-czd-biaoqian1 ft25 pointer" @click="addtagFun(scope.row)"></i>
                        <!-- 编辑 -->
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                           @click="editPaper(scope.$index, scope.row)"></i>

                        <!-- 评论 -->
                        <!-- 无评论 -->
                        <div class="pointer" v-if="scope.row.commentNum <= 0" @click="$message.info('暂无评论')"
                             style="height: 24px">
                            <img src="https://file.chezidao.com/root/static/icon/comments.png" style="width: 20px">
                        </div>
                        <!-- 有评论 -->
                        <div v-if="scope.row.commentNum > 0" @click="loadCommonList(scope.row)" style="height: 24px">
                            <el-badge :value="scope.row.commentNum" :max="99" class="item pointer">
                                <img src="https://file.chezidao.com/root/static/icon/comments.png" style="width: 20px">
                            </el-badge>
                        </div>
                        <i title="下线" class="el-icon-remove-outline ft25 pointer" @click="downLinePaper(scope.row)"></i>

                        <!-- 删除 -->
                        <i title="删除" class="el-icon-delete ft25 red pointer"
                           @click="deletePaper(scope.$index, scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- 批量标签弹出框 -->
        <el-dialog title="批量添加标签" :visible.sync="addtags" width="30%">
            <el-checkbox-group v-model="checkboxGroup">
                <el-checkbox-button v-for="(items, index) in allTaglist"
                                    style="border-radius: 5px; border: 1px solid #dcdfe6; margin-right:25px;margin-top:10px;"
                                    :key="items.id" :label="items">{{items.name}}
                </el-checkbox-button>
            </el-checkbox-group>
            <el-button style="color: red;border-color:red; margin-left:8px;margin-top:20px;" @click="clearButton">清空
            </el-button>
            <el-button style="margin-left:180px;margin-top:20px;" type="success" @click="batchAdd">保存</el-button>
        </el-dialog>
        <!-- 单击标签弹出框 -->
        <el-dialog title="添加标签" :visible.sync="addtag" width="30%">
            <div prop="type" style=" margin-bottom: 30px;">
                <el-button name="type" plain size="medium"
                           :style="item.hasTag==0 ? '':' background-color:#409eff;color:#fff;'" @click="changeTag(item)"
                           style="margin:5px; width:120px; text-align: center;" v-for="(item, index) in tagData"
                           :key="item.id">{{ item.name }}
                </el-button>
            </div>
            <el-button style="color: red;border-color:red; margin-left:8px;" @click="resetForm">清空</el-button>
            <el-button style="margin-left:180px" type="success" @click="submitTag">保存</el-button>
        </el-dialog>
        <el-dialog title="批量下线文章" :visible.sync="uppapers" width="50%">
            <el-form ref="form" :model="uppapersList">
                <span>请选择理由(可多选)</span>
                <el-form-item prop="type">
                    <el-checkbox-group v-model="uppapersList.type" v-for="(item, index) in reasonData" :key="item.id">
                        <el-checkbox style="width:45%; margin-left: 30px;" :label="item.name" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="其他" prop="desc">
                    <el-input type="textarea" size="medium" v-model="uppapersList.desc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="float:right" @click="batchOffline">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="下线文章" :visible.sync="uppaper" width="50%">
            <el-form ref="form" :model="offLineData.form">
                <span>请选择理由(可多选)</span>
                <el-form-item prop="type">
                    <el-checkbox-group v-model="offLineData.form.type" v-for="(item, index) in reasonData"
                                       :key="item.id">
                        <el-checkbox style="width:45%; margin-left: 30px;" :label="item.name" name="type"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="其他" prop="desc">
                    <el-input type="textarea" size="medium" v-model="offLineData.form.desc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="float:right" @click="onSubmit">确认</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-button type="primary" style="margin-left:5%;margin-top: 2em" @click="outtags">批量添加标签</el-button>
        <el-button type="info" @click="outReason">批量下线</el-button>
        <el-button type="danger" @click="batchRemove">批量删除</el-button>
        <div class="paginaion" style="display: inline-block; margin-left:15%;margin-top: 2em">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize"
                           layout="total, sizes, prev, pager, next, jumper" :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>
    </div>
</template>

<script>
    import bus from "../../common/bus";
    import commonlist from "../../common/userCommon";
    import draggable from "vuedraggable";

    export default {
        data: function () {
            return {
                dragOptions: {
                    animation: 120,
                    scroll: true,
                    group: "sortlist",
                    ghostClass: "white"
                },
                checkboxGroup: [],
                multipleSelection: [],
                activeName: "0",
                addPaperType_bool: false,
                // loading: true,
                tableData: [],
                tagData: [],
                tagSwitchData: [],
                contentType: "",
                contentTypes: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: "",
                searchState: "",
                paperStatus: "未上线",
                paperId: "",
                tagId: " ",
                showCommon: false,
                commonData: "",
                addPaperData: {
                    name: ""
                },
                allTaglist: [],
                addtagname: {
                    name: ""
                },
                addtag: false,
                addtags: false,
                editPaperData: {
                    name: ""
                },
                reasonData: [],
                paperTypeData: [],
                editPaperStr: "新  增",
                oprationType: "add",
                tableHeight: 600,
                is_edit: false,
                uppaper: false,
                uppapers: false,
                uppapersList: {
                    desc: "",
                    type: []
                },
                offTagList: {
                    ids: ""
                },
                offLineData: {
                    id: "",
                    status: "",
                    form: {
                        desc: "",
                        type: []
                    }
                }
            };
        },
        components: {
            commonlist,
            draggable
        },
        watch: {},
        created() {
            // this.reasonData = [
            //     {id: 1, name: "标题、图片或内容低俗,具有性暗示"},
            //     {id: 2, name: "色情低俗"},
            //     {id: 3, name: "垃圾广告"},
            //     {id: 4, name: "内容或图片暴力、恐怖、血腥"},
            //     {id: 5, name: "内容不相关"},
            //     {id: 6, name: "人身攻击"},
            //     {id: 7, name: "含政治敏感信息"},
            //     {id: 8, name: "赌博诈骗"},
            //     {id: 9, name: "发布违法违禁信息"}
            // ];
            this.allTag();
            this.getPaperList(0, 1, 10);
            this.loadPaperType();
            this.tableHeight = `${document.documentElement.clientHeight}` * 0.653;
            this.contentTypes = this.$getDicByName("CONTENT_TYPE");
            this.reasonData = this.$getDicByName("OFFLINE_REASON_TYPE");
        },
        mounted() {
            bus.$on("userPaper", res => {
                if (res) {
                    this.getPaperList(this.currentTagId, this.currentPage, this.size);
                }
            });
        },
        methods: {
            // 移动行
            removeHandle(event) {
                //
                // this.$message.success(`从 ${event.from.id} 移动到 ${event.to.id} `);
            },
            // 拖拽标签顺序数据传后台
            changeBanaSort() {
                let idSort = [];

                for (var item in this.allTaglist) {
                    if (this.allTaglist[item] != null) {
                        idSort.push({id: this.allTaglist[item].id, sort: item});
                    }
                }
                // //console.log(idSort);

                this.$http({
                    url: this.$api + "/api/tags/info/sort",
                    method: "post",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: idSort
                })
                    .then(res => {
                        //
                        if (res.data.errno === 0) {
                            this.$message.success("修改顺序成功！");
                        }
                    })
                    .catch(res => {
                        this.$message.error("更新失败");
                    });
                this.loadPaperType();
                // this.addPaperType_bool = false;
            },
            // 批量删除
            batchRemove() {
                let pagerId = [];
                for (let index in this.multipleSelection) {
                    pagerId.push(this.multipleSelection[index].id);
                }
                let pagerIds = pagerId.join(",");
                if (pagerIds == "") {
                    this.$message.error("请选择的批量删除文章");
                } else {
                    this.$http({
                        url: this.$api + "/chigua/admin/article/delete/batch",
                        method: "post",
                        data: this.$qs.stringify({
                            ids: pagerIds
                        })
                    })
                        .then(res => {
                            this.$message.success("批量删除成功");
                            this.getPaperList(this.currentTagId, this.currentPage, this.size);
                        })
                        .catch(res => {
                        });
                }
            },
            // 弹出原因框
            outReason() {
                this.uppapers = true;
            },
            // 批量下线
            batchOffline() {
                let pagerId = [];
                for (let index in this.multipleSelection) {
                    pagerId.push(this.multipleSelection[index].id);
                }
                let pagerIds = pagerId.join(",");
                if (this.uppapersList.type.length == 0) {
                    this.$message.error("请选择要下线的原因");
                } else {
                    this.$http({
                        url: this.$api + "/chigua/admin/article/downLine/batch",
                        method: "post",
                        data: this.$qs.stringify({
                            ids: pagerIds,
                            offlineReason: this.uppapersList.desc + "," + this.uppapersList.type
                        })
                    })
                        .then(response => {
                            // //console.log(this.uppapersList.type);
                            if (response.data.msg == "ids-不能为空") {
                                this.$message.error("请选择要下线的文章");
                            } else {
                                this.$message.success(response.data.msg);
                            }
                            // //console.log(response);
                            // this.$message.success(response.data.msg);
                            this.getPaperList(this.currentTagId, this.currentPage, this.size);
                            // //console.log(this.$refs["form"]);
                            this.uppapers = false;
                            this.$refs["form"].resetFields();
                        })
                        .catch(res => {
                            //console.warn(res);
                            this.$message.success("请选择要下线的文章或下线理由");
                        });
                }
            },
            // 弹出全部标签
            outtags() {
                this.addtags = true;
            },
            clearButton() {
                this.checkboxGroup = [];
            },
            // 批量添加标签
            batchAdd() {
                let pagerId = [];
                for (let index in this.multipleSelection) {
                    pagerId.push(this.multipleSelection[index].id);
                }
                let pagerIds = pagerId.join(",");
                // //console.log(this.checkboxGroup);
                // //console.log(pagerIds);
                let tag = [];
                for (let index in this.checkboxGroup) {
                    tag.push(this.checkboxGroup[index].id);
                }
                let tags = tag.join(",");
                this.$http({
                    url: this.$api + "/api/tags/article/add/batch",
                    method: "post",
                    data: this.$qs.stringify({
                        articleIds: pagerIds,
                        tags: tags
                    })
                })
                    .then(res => {
                        // //console.log(res);
                        this.addtags = false;
                        this.getPaperList();
                        this.checkboxGroup = [];
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 监听批量操作
            handleSelectionChange(val) {
                this.multipleSelection = val;
                // //console.log(this.multipleSelection);
            },
            // 查询所有的标签
            allTag() {
                this.$http({
                    url: this.$api + "/api/tags/info/list/tip",
                    method: "post"
                })
                    .then(res => {
                        this.allTaglist = res.data.data;
                        // //console.log(this.allTaglist);
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 标签禁用启用
            toggleState(row) {
                // //console.log(row);
                let status = row.status == 1 ? "2" : "1";
                // //console.log(status);

                this.$http({
                    url: this.$api + "/api/tags/info/update/status",
                    method: "post",
                    data: this.$qs.stringify({
                        id: row.id,
                        status: status
                    })
                })
                    .then(res => {
                        // //console.log(res);
                        if (res.data.errno == 0) {
                            this.$message.success(res.data.errmsg);
                            this.allTag();
                            this.loadPaperType();
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        // //console.warn(res);
                    });
            },
            // 保存按钮
            submitTag() {
                let tagList = [];
                for (let param in this.tagData) {
                    if (this.tagData[param].hasTag == 1) {
                        tagList.push(this.tagData[param].id);
                    }
                }
                let tags = tagList.join(",");
                // //console.log(tags);
                // //console.log(this.offTagList.ids);

                this.$http({
                    url: this.$api + "/api/tags/article/add",
                    method: "post",
                    data: this.$qs.stringify({
                        articleId: this.offTagList.ids,
                        tags: tags
                    })
                })
                    .then(res => {
                        // //console.log(res);
                        this.addtag = false;
                        this.getPaperList();
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 重置标签
            resetForm() {
                for (let param in this.tagData) {
                    if (this.tagData[param].hasTag == 1) {
                        this.tagData[param].hasTag = 0;
                    }
                }
            },
            // 切换tag状态
            // @item 对象数据
            changeTag(item) {
                for (let param in this.tagData) {
                    if (item.id == this.tagData[param].id) {
                        this.tagData[param].hasTag == 0
                            ? (this.tagData[param].hasTag = 1)
                            : (this.tagData[param].hasTag = 0);
                        break;
                    }
                }
            },
            // 批量切换标签弹窗状态
            changeTags(items) {
                for (let param in this.allTaglist) {
                    if (items.id == this.allTaglist[param].id) {
                        this.allTaglist[param].checked = !this.allTaglist[param].checked;
                        break;
                    }
                }
            },
            // 编辑标签
            // @item: 单条数据
            addtagFun(row) {
                this.addtag = true;
                this.offTagList.ids = row.id;
                this.$http({
                    url: this.$api + "/api/tags/article/list/tags",
                    method: "post",
                    data: this.$qs.stringify({
                        id: row.id
                    })
                })
                    .then(res => {
                        // //console.log(res);
                        this.tagData = res.data.data;
                        // //console.log(this.tagData);
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 下线操作\
            downLinePaper(row) {
                // //console.log(row);

                this.uppaper = true;
                this.offLineData.id = row.id;
                this.offLineData.status = row.status;
            },
            onSubmit() {
                let url = "",
                    tips = "操作成功",
                    alertText = "";
                if (this.offLineData.status === 1 || this.offLineData.status === 3) {
                    //  未上线、已经下线的文章上线
                    url = this.$api + "/chigua/admin/article/onLine";
                    tips = "上线成功！";
                    alertText = "上线";
                } else if (this.offLineData.status === 2) {
                    // 上线的文章下线
                    url = this.$api + "/chigua/admin/article/downLine";
                    tips = "下线成功！";
                    alertText = "下线";
                    // row.color = '#ff0000'
                } else {
                    return;
                }
                if (this.offLineData.form.type.length == 0) {
                    this.$message.error("请选择下线原因");
                } else {
                    this.$http({
                        url: url,
                        method: "post",
                        data: this.$qs.stringify({
                            id: this.offLineData.id,
                            offlineReason:
                            this.offLineData.form.type + "," + this.offLineData.form.desc
                        })
                    }).then(response => {
                        // //console.log(response);

                        if (response.status === 200) {
                            if (response.data.errno === 0) {
                                this.$message.success(tips);
                                this.getPaperList(this.currentTagId, this.currentPage, this.size);
                                // //console.log(this.$refs["form"]);
                                this.uppaper = false;
                                this.$refs["form"].resetFields();
                            }
                        }
                    });
                }
            },
            // 删除标签类型
            deletePaperType(item) {
                this.$http({
                    url: this.$api + "/api/tags/info/delete",
                    method: "post",
                    data: this.$qs.stringify({
                        id: item.id
                    })
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.$message.success(res.data.errmsg);
                            this.allTag();
                            this.loadPaperType();
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 新增标签类型
            submit_add_paper_type() {
                let url = "/api/tags/info/add";
                this.$http({
                    url: this.$api + url,
                    method: "post",
                    data: this.$qs.stringify({
                        id: this.addPaperData.id,
                        name: this.addPaperData.name,
                        sort: this.addPaperData.sort ? this.addPaperData.sort : 99
                    })
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.$message.success(res.data.errmsg);
                            this.addPaperData.name = "";
                            this.oprationType = "add";
                            this.editPaperStr = "新  增";
                            this.allTag();
                            this.loadPaperType();
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 保存编辑标签类型
            submit_edit_paper_type() {
                let url = "/api/tags/info/update";
                this.$http({
                    url: this.$api + url,
                    method: "post",
                    data: this.$qs.stringify({
                        id: this.editPaperData.id,
                        name: this.editPaperData.name,
                        sort: this.editPaperData.sort ? this.editPaperData.sort : 99
                    })
                })
                    .then(res => {
                        if (res.data.errno == 0) {
                            this.$message.success(res.data.errmsg);
                            this.editPaperData.name = "";
                            this.allTag();
                            this.oprationType = "add";
                            this.editPaperStr = "新  增";
                            this.is_edit = false;
                        } else {
                            this.$message.error(res.data.errmsg);
                        }
                    })
                    .catch(res => {
                        //console.warn(res);
                    });
            },
            // 编辑标签类型
            editTagType_btn() {
                this.addPaperType_bool = true;
            },
            editTagType_btn_inside(item) {
                for (var i in item) {
                    this.editPaperData[i] = item[i];
                }
                this.is_edit = true;
                this.editPaperStr = "保存编辑";
                this.oprationType = "edit";
            },

            // 加载标签类型
            loadPaperType() {
                this.$http({
                    url: this.$api + "/api/tags/info/list",
                    method: "post"
                }).then(response => {
                    if (response.data.data != null) {
                        this.tagSwitchData = response.data.data;
                        //  //console.log(this.tagSwitchData);
                    }
                });
            },
            // 加载评论列表
            loadCommonList(row) {
                this.$http({
                    url: this.$api + "/chigua/admin/article/comment/page",
                    method: "post",
                    data: this.$qs.stringify({
                        articleId: row.id,
                        page: 1,
                        limit: 10,
                        sort: 0
                    })
                })
                    .then(response => {
                        this.commonData = response.data.data;
                        this.commonData.showCommon = true;
                        this.commonData.rows = row;
                        this.showCommon = true;
                        this.commonData.showCommonType = "paper";

                        // bus.$emit('loadConments',this.commonData)
                    })
                    .catch(response => {
                        // //console.log(response);
                    });
            },
            // 跳转到编辑页面，巴士负责传递id
            editPaper(index, row) {
                var loading = this.$loading({fullscreen: true});
                this.paperId = row.id;
                // //console.log(row.id);

                bus.$emit("paperChange", this.paperId);
                //  把文章类型带上
                this.$router.push(
                    "/paperedit?paperid=" + row.id + "&ct=" + row.contentType
                );
                loading.close();
            },
            // 删除文章
            deletePaper(index, row) {
                this.$confirm("您正在进行【删除文章】操作，确认？", "警示", {
                    confirmButtonText: "删除",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.$http({
                            url: this.$api + "/chigua/admin/article/delete",
                            method: "post",
                            data: this.$qs.stringify({id: row.id})
                        }).then(response => {
                            if (response.status === 200) {
                                if (response.data.errno === 0) {
                                    this.$message.success("文章已删除！");
                                    this.getPaperList(
                                        this.currentTagId,
                                        this.currentPage,
                                        this.size
                                    );
                                }
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作取消"
                        });
                    });
            },
            // 删除标签
            deleteTag(index, row) {
                this.$confirm("您正在进行【删除标签】操作，确认？", "警示", {
                    confirmButtonText: "删除",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                    .then(() => {
                        this.$http({
                            url: this.$api + "/api/tags/info/delete",
                            method: "post",
                            data: this.$qs.stringify({id: row.id})
                        }).then(response => {
                            // //console.log(response);
                            if (response.data.errno === 0) {
                                this.$message.success("标签已删除！");
                                // 重新调用方法
                                this.getTagList();
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作取消"
                        });
                    });
            },
            operatePaper(index, row) {
                let url = "",
                    tips = "操作成功",
                    alertText = "";
                if (row.status === 1 || row.status === 3) {
                    //  未上线、已经下线的文章上线
                    url = this.$api + "/chigua/admin/article/onLine";
                    tips = "上线成功！";
                    alertText = "上线";
                } else if (row.status === 2) {
                    // 上线的文章下线
                    url = this.$api + "/chigua/admin/article/downLine";
                    tips = "下线成功！";
                    alertText = "下线";
                    // row.color = '#ff0000'
                } else {
                    return;
                }
                this.$confirm(
                    "您正在进行【" + alertText + "】操作，该操作会直接改变文章发布状态",
                    "警示",
                    {
                        confirmButtonText: alertText,
                        cancelButtonText: "取消",
                        type: "warning"
                    }
                )
                    .then(() => {
                        this.$http({
                            url: url,
                            method: "post",
                            data: this.$qs.stringify({id: row.id})
                        }).then(response => {
                            if (response.status === 200) {
                                if (response.data.errno === 0) {
                                    this.$message.success(tips);
                                    this.getPaperList(
                                        this.currentTagId,
                                        this.currentPage,
                                        this.size
                                    );
                                }
                            }
                        });
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "操作取消"
                        });
                    });
            },

            //按标题搜索列表
            handleContentType() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size);
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId, this.currentPage, this.size);
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.currentPage, val);
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size);
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: "已经很努力加载啦~",
                    spinner: "el-icon-loading",
                    background: "rgba(183, 183, 183, 0.57)"
                });
                if ((page === undefined) | (page === null)) {
                    page = 1;
                } else if ((length === undefined) | (length === null)) {
                    length = this.size;
                }

                this.$http({
                    url: this.$api + "/chigua/admin/article/page/tag",
                    method: "post",
                    data: this.$qs.stringify({
                        tagId: type,
                        contentType: this.contentType,
                        title: this.searchState,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        for (var item in response.data.data.records) {
                            if (response.data.data.records[item].status === 1) {
                                response.data.data.records[item].paperStatus = "可上线";
                                // response.data.data.records[item].color = '#e3e3e3'
                            } else if (response.data.data.records[item].status === 2) {
                                response.data.data.records[item].paperStatus = "已上线";
                                // response.data.data.records[item].color = '#49293'
                            } else if (response.data.data.records[item].status === 3) {
                                response.data.data.records[item].paperStatus = "已下线";
                                // response.data.data.records[item].color = '#ff0000'
                            } else {
                                response.data.data.records[item].paperStatus = "未知状态";
                            }
                        }
                        // 页面详细信息
                        this.tableData = response.data.data.records;
                        this.currentPage = response.data.data.current;
                        this.total = response.data.data.total;
                        this.size = response.data.data.size;
                        // //console.log(this.tableData);
                    } else {
                        this.$message.error(response.data.msg);
                    }
                    loading.close();
                });
            },
            switchTag(tab) {
                // //console.log(tab);

                for (var i in this.tagSwitchData) {
                    if (tab.name == this.tagSwitchData[i].id) {
                        this.searchState = "";
                        this.getPaperList(this.tagSwitchData[i].id, 1, 10);
                        this.currentTagId = this.tagSwitchData[i].id;
                    }
                }
            }
        }
    };
</script>
<style scoped>
    .container {
        border: none;
    }

    .ghost-style {
        display: block;
        color: transparent;
        border-style: dashed;
    }

    .drag-box-item {
        width: 100%;
        max-width: 100%;
    }

    .item-ul {
        height: 100%;
    }

    .dragitem {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        padding: 5px;
        border-bottom: 0.5px solid #e1e4e8;
        height: 30px;
    }

    .tagSwitch {
        width: 100%;
        height: auto;
    }

    .tagButton {
        margin-right: -45%;
    }

    .searchBar {
        width: 260px;
    }

    .ft25 {
        font-size: 25px;
    }

    .footAddButton {
        width: 30%;
    }

    .footInput {
        width: 60%;
    }

    .ft12 {
        font-size: 12px;
    }

    .ft16 {
        font-size: 16px;
    }

    .formFooter {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        width: 100%;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
