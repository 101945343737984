<template>

    <div class="clearCacheBox" @mousedown="move">
        <div style="text-align: center">
            <el-select v-model="cacheOptionsItem" multiple placeholder="更新缓存(修改后请更新！)">
                <el-option
                    v-for="item in cacheList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <el-button type="danger" round @click="updateBackendCache">更新</el-button>
            <el-button type="default" round @click="forceUpdateBackendCache">强制更新所有</el-button>
        </div>
    </div>

</template>

<script>
    export default {
        data: function(){
            return {
                positionX:0,
                showTool: false,
                showToolBtn: true,
                positionY:0,
                fileList: [],
                Auth_token:'',
                cacheOptions: [
                    {name: '首页轮播图', value: 'mp_index_banner_cache'},
                    {name: '首页频道', value: 'mp_index_channel_cache'},
                    {name: '系统配置（二维码、微信号、客服电话）', value: 'system_config_cache'},
                    {name: '首页缓存--001', value: 'mp_index_cache_other'},
                    {name: '首页缓存--002', value: 'mp_index_cache_goods'},
                    {name: '销量排行榜', value: 'mp_index_cache_salesRankings'},
                    {name: '今日寄语', value: 'one_today_motto'}
                ], // 缓存类型
                cacheOptionsItem: [],
                cacheList: []
            }
        },
        mounted() {
            this.getRedisCacheList()
        },
        methods: {
            /**
             * 高危接口,
             * 调用此接口将会彻底清除服务器redis缓存，包括已经登陆的所有用户，
             * 本次登录用户将在调用任意的接口后退出登录
             * **/
            forceUpdateBackendCache () {
                this.$http({
                    url: this.$api + '/admin/cache/flushRedisDb',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno === 0) {
                        this.$message.success('强制更新后台缓存成功')
                    } else {
                        this.$message.error('强制更新失败！')
                    }
                })
            },
            /**
             *
             *  加载后台缓存列表，不能频繁调用，占用资源较大
             *
             * **/
            getRedisCacheList () {
                this.$http({
                    url: this.$api + '/admin/cache/getAllKeys',
                    method: 'post'
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.cacheList = res.data.data;
                        this.cacheList = this.cacheList.filter(function (item) {
                            return item.match('_')&&!item.match('user_')
                        })
                        let that = this;
                        this.cacheList = this.cacheList.map(function (item) {
                            let cacheItem = {};
                            cacheItem.label = item;
                            for (let param in that.cacheOptions) {
                                if (item === that.cacheOptions[param].value) {
                                    cacheItem.label = that.cacheOptions[param].name;
                                    break;
                                }
                            }
                            cacheItem.value = item;
                            return cacheItem
                        })
                    } else {
                        this.$message.error('加载redis缓存失败，请不要频繁加载redis缓存列表！')
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            // 更新缓存
            updateBackendCache () {
                if (this.cacheOptionsItem.length == 0) {
                    this.$message.error('请选择需要更新的缓存分类！');
                    return
                }
                this.$http({
                    url: this.$api + '/admin/cache/del',
                    method: 'post',
                    data: this.$qs.stringify({
                        cache_type: this.cacheOptionsItem.join(',')
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success('更新缓存成功！')
                        this.cacheOptionsItem = []
                    } else {
                        this.$message.error('更新缓存失败！')
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            move(e){
                let odiv = e.target; //获取目标元素 //算出鼠标相对元素的位置
                let disX = e.clientX - odiv.offsetLeft;
                let disY = e.clientY - odiv.offsetTop;
                document.onmousemove = (e)=>{ //鼠标按下并移动的事件 //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
                    let left = e.clientX - disX;
                    let top = e.clientY - disY; //绑定元素位置到positionX和positionY上面
                    this.positionX = top;
                    this.positionY = left; //移动当前元素
                    odiv.style.left = left + 'px';
                    odiv.style.top = top + 'px';
                };
                document.onmouseup = (e) => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                };
            },
        }
    }
</script>
<style scoped>
    .clearCacheBox{
        border-radius: 10px;
        cursor: move;
        position: absolute; /*定位*/
        top:0;
        left: 50px;
        /*transform: translateX(50%);*/
        /*width: 80px;*/
        /*background-image: url('../../assets/tool.png');*/
        background-repeat: no-repeat;
        background-size: cover;
        overflow: visible;
    }
    .btnbox{
        text-align: center;
        z-index:  -1;
        width: 100%;
        min-height: 2em;
        background: #ff0000;
        color: #fff;
    }
</style>
