<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>返佣管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-button type="primary" @click="$router.push({path:'/addCommissionTemplate'})">新增返佣模板</el-button>

        <div style="padding: 10px">
            <el-table
                :data="commissionTemplateList"
                style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="scope">
                        <el-table
                            :data="scope.row.goods_list"
                            style="width: 100%">
                            <el-table-column align="center" width="150"  label="商品" >
                                <template slot-scope="goodsItem">
                                    <img :src="goodsItem.row.head_img_url" alt="" class="width60px">
                                </template>
                            </el-table-column>
                            <el-table-column align="center" width="200" label="名称" prop="goods_name"></el-table-column>
                            <el-table-column align="center" width="200" label="商品返佣" prop="return_cash"></el-table-column>
                            <el-table-column align="center" label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="danger" @click="deleteTemplateGoods(scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="text-align-right padding15 bg_fff">
                            <el-button size="mini" type="primary" @click="$router.push(`/editCommissionTemplate?template_id=${scope.row.template_id}`)">新增商品</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center" width="260"  label="模板id" prop="template_id"></el-table-column>
                <el-table-column align="center" width="200" label="模板名称" prop="template_name"></el-table-column>
                <el-table-column align="center" width="150"  label="模板说明" prop="template_desc"></el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <!--<el-button-->
                            <!--size="mini"-->
                            <!--@click="handleEdit(scope.$index, scope.row)">编辑</el-button>-->
                        <el-button size="mini" type="primary" @click="$router.push(`/commissionTemplateDetail?template_id=${scope.row.template_id}`)">模板详情</el-button>
                        <el-button size="mini" type="default" @click="$router.push(`/editCommissionTemplate?template_id=${scope.row.template_id}`)">编辑模板</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import util from '../../../utils/util'

    export default {
        data: function () {
            return {
                commissionTemplateList: [],
            }
        },
        components: {
        },
        methods: {
            // 删除模板商品
            deleteTemplateGoods (ROW) {
                this.$http({
                    url: this.$api + '/admin/serviceTemplate/deleteTemplateGoods',
                    method: 'post',
                    data: {
                        default_goods_id: ROW.goods_id,
                        template_id: ROW.template_id
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('删除模板商品成功！')
                        this.getCommissionTemplateList();
                    }
                })
            },
            //获取优惠券列表
            getCommissionTemplateList(){
                this.$http({
                    url: this.$api + '/admin/serviceTemplate/defaultList',
                    method: 'post',
                }).then(response => {
                    if (response.data.errno === 0) {
                        let commissionTemplateList =  response.data.data;
                        this.commissionTemplateList = commissionTemplateList;
                    }
                })
            },
        },
        created(){

        },
        mounted() {
            this.getCommissionTemplateList();
        },
    }
</script>

<style lang="scss" scoped>
    .single_food{
        display: flex;
        align-items: center;
        margin: 2px 0;
        padding: 10px;
        border-radius: 4px;
        &.thead{
            font-weight: bold;
            font-size: 15px;
            color: #000;
            &:hover{
                background-color: #f6faff;
            }
        }
        &:hover{
            background-color: #fff;
        }
        .single_data{
            /*flex: 1;*/
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.front_desc{
                /*flex: 1;*/
                width: 300px;
                justify-content: left;
            }
            .food_picture{
                width: 80px;
                height: 80px;
                border-radius: 4px;
            }
        }
    }

    .pointer{
        cursor: pointer;
    }

</style>
