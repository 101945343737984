<template>
    <div class="mike_con">

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="form_con">

            <!--基本信息-->
            <div class="mike_part left">
                <el-alert title="基本信息" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--优惠券名称-->
                <el-form-item label="专栏/活动名称" prop="title">
                    <el-input v-model="ruleForm.title" required></el-input>
                </el-form-item>

                <!--优惠券描述-->
                <el-form-item label="专栏描述/活动描述" prop="desc">
                    <el-input v-model="ruleForm.desc" required></el-input>
                </el-form-item>

                <!--满减门槛-->
                <el-form-item label="活动所在位置" prop="position" required>
                    <el-input v-model="ruleForm.position" placeholder="请选择活动所在的位置" required></el-input>
                </el-form-item>

                <!--用户支付订单时限制使用的优惠券数量-->
                <el-form-item label="位置图标" prop="position_icon">
                    <upload-image v-model="ruleForm.position_icon" required></upload-image>
                </el-form-item>

                <!--用户支付订单时限制使用的优惠券数量-->
                <el-form-item label="宣传图" prop="position_banner">
                    <upload-image v-model="ruleForm.position_banner" required></upload-image>
                </el-form-item>

            </div>

            <!--限制条件-->
            <div class="mike_part left">
                <el-alert title="商品列表" type="info" :closable="false" class="coupon_title"></el-alert>
                <!--指定商品可以使用的券，例如go专用券，id字符串，多个分类使用逗号分隔-->
                <el-form-item label="指定商品专用" prop="fit_goods_ids" v-if="!ruleForm.is_full_fit">
                    <el-transfer class="couponGoodsTransfer" v-model="CouponGoods" :data="CouponGoodsData"
                                 :button-texts="['取消', '添加']"
                                 :titles="['备选商品（请搜索）', '已选商品']"
                                 filterable></el-transfer>
                    <div class="baseBorder padding15" v-if="ruleForm.fit_goods_names_data && ruleForm.fit_goods_names_data.length > 0">
                        <p class="fontWeight">请设置商品别名</p>
                        <ul class="padding15">
                            <li class="padding15" v-for="(item, index) in ruleForm.fit_goods_names_data" :key="item.name">
                                <p>{{index + 1}}、{{item.name}}</p>
                                <el-input  v-model="ruleForm.fit_goods_names_data[index].shortName"  placeholder="请设置商品别名">
                                    <template slot="prepend">商品别名：</template>
                                </el-input>
                            </li>
                        </ul>
                    </div>
                    <!--<el-input v-model="ruleForm.fit_goods_ids" :disabled="true" placeholder="例如go九种肉8磅专用券，id字符串，多个分类使用逗号分隔"></el-input>-->
                </el-form-item>
            </div>

            <!--按钮-->
            <el-form-item>
                <el-button type="primary" @click="Submit">立即创建</el-button>
            </el-form-item>
        </el-form>



    </div>
</template>

<script>
    import uploadImage from '../../common/uploadImage';
    export default {
        name: "newClassActivity",
        data() {
            return {
                ruleForm: {
                    position: '',
                    title: '',
                    desc: '',
                    position_icon: '',
                    position_banner: '',
                    content: '',
                    fit_goods_names_data: [], // 带有长短名称的商品数组
                    fit_goods_ids: [],
                    fit_goods_names: []
                },
                rules: {
                    title: [
                        {required: true, message: "请输入活动标题", trigger: 'blur'},
                    ],
                    desc: [
                        {required: true, message: "请输入活动备注", trigger: 'blur'},
                    ],
                    position: [
                        {required: true, message: "请选择该活动所处的位置，例如：channel", trigger: 'blur'},
                    ],
                    position_icon: [
                        {required: true, message: "请上传活动的图标，pisition图标", trigger: 'blur'},
                    ],
                    position_banner: [
                        {required: true, message: "请上传活动图片", trigger: 'blur'},
                    ]
                },
                category: [],
                CouponCategory: [], // 已选择的分类，在watch中监听，组装数据
                CouponCategoryData: [], // 分类数组
                CouponGoods: [], // 已选择的商品，在watch中监听，组装数据
                CouponGoodsData: [], // 商品的所有
            }
        },
        mounted () {
            this.getGoods();
        },
        components: {
            uploadImage
        },
        watch: {
            CouponCategory (newv) {
                let that = this;
                let fit_category_names_data = [];
                if (newv.constructor === Array) {
                    newv.map(function (param) {
                        that.CouponCategoryData.map(function (item) {
                            if (param === item.key) {
                                fit_category_names_data.push({
                                    name: item.label,
                                    id: item.key
                                })
                            }
                        })
                    })
                }
                this.ruleForm.fit_category_ids = newv;
                this.ruleForm.fit_category_names_data = fit_category_names_data
            },
            CouponGoods (newv) {
                let that = this;
                let fit_goods_names_data = [];
                if (newv.constructor === Array) {
                    newv.map(function (param) {
                        that.CouponGoodsData.map(function (item) {
                            if (param === item.key) {
                                fit_goods_names_data.push({
                                    id: item.key,
                                    name: item.label,
                                    shortName: ''
                                })
                            }
                        })
                    })
                }
                this.ruleForm.fit_goods_ids = newv;
                this.ruleForm.fit_goods_names_data = fit_goods_names_data
            }
        },
        methods: {
            resetForm () {
                this.ruleForm = {
                        position: '',
                        title: '',
                        desc: '',
                        position_icon: '',
                        position_banner: '',
                        content: '',
                        fit_goods_names_data: [], // 带有长短名称的商品数组
                        fit_goods_ids: [],
                        fit_goods_names: [],
                }
            },
            getGoods (name) {
                this.$http({
                    url: this.$api + '/admin/goods/getTransferGoods',
                    method: 'post',
                    data: {
                        name: name,
                        page: 1,
                        size: 20
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.CouponGoodsData = response.data.data.data.map(function (item) {
                            return {
                                key: item.id,
                                label: item.name,
                                disabled: false
                            }
                        })
                    } else {
                        this.$message.error('商品数据加载失败！请刷新重试！')
                    }
                })
            },
            Submit() {
                this.$http({
                    url: this.$api + '/admin/goods/saveClassActivity',
                    method: 'post',
                    data: this.ruleForm
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('添加分类精选成功！');
                        this.resetForm()
                    }
                })
            }
        }
    }
</script>

<style lang="scss">
    .couponGoodsTransfer .el-transfer-panel {
        width: 40%;
    }
    .coupon_title {
        margin-bottom: 20px;
    }

    .form_con {
        display: flex;
        flex-wrap: wrap;
    }

    .mike_part {
        width: 100%;
        &.left {
            margin-right: 100px;
        }
    }
</style>
