<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.count }}个商品）</label>
        </div>
        <div class="button-tool">
            <div style="display: flex">
                <el-radio-group v-model="contentType" style="margin-right: 20px;margin-top: 10px"
                                @change="handleContentType">
                    <el-radio v-for="item in contentTypes" :label="item.value" :name="item.name" :key="item.value">
                        {{item.name}}
                    </el-radio>
                </el-radio-group>
                <el-input placeholder="请输入商品名查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 500px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>
        <el-table
            :data="tableData.data"
            style="width: 100%" >
            <el-table-column label="商品id" width="80" prop="id"></el-table-column>
            <el-table-column label="商品名称" width="400">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <a :href="scope.row.list_pic_url" target="_blank"><img :src="scope.row.list_pic_url" style="width: 80px;vertical-align: middle;"></a>
                        <span style="margin-left: 10px">{{ scope.row.name }}</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="销量" width="100" >
                <template slot-scope="scope">
                    <p style="text-align: center">{{ scope.row.sell_volume }}</p>
                </template>
            </el-table-column>
            <el-table-column label="评价数量" width="100" >
                <template slot-scope="scope">
                    <p style="text-align: center">{{ scope.row.evaluateCount }}</p>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <el-button type="primary" @click="addEvaluate(scope.row)">添加评价</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
        <el-dialog
            title="添加商品评价"
            :visible.sync="isShowEvaluateDialog"
            width="80%">
            <div class="disflex_around_start">

                <div class="width50 ">
                    <!--插这里-->
                    <div class="popup_goods_info">
                        <div>{{evaluateGoods.name}}</div>
                        <img style="width: 100px;height: 100px;" :src="evaluateGoods.list_pic_url" alt="">
                    </div>
                    <div class="padding15">
                        <h2>评论内容</h2>
                        <el-input placeholder="评价内容" v-model="evaluateUser.content" type="textarea">
                            <template slot="prepend">评价内容</template>
                        </el-input></div>
                    <div class="padding15">
                        <el-input placeholder="基础点赞数量" v-model="evaluateUser.prise_num">
                            <template slot="prepend">基础点赞数量</template>
                        </el-input></div>
                    <div class="padding15" style="display: flex">
                        <div class="block">
                            <span class="demonstration">评价时间</span>
                            <el-date-picker
                                v-model="evaluateUser.add_time*1000"
                                type="datetime"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </div>
                        <el-button type="primary" @click="getChooseTime(604800000)" style="margin-left: 15px;">最近一周</el-button>
                        <el-button type="primary" @click="getChooseTime(259200000)">最近三天</el-button>
                        <el-button type="primary" @click="getChooseTime(86400000)">最近一天</el-button>
                    </div>
                    <div class="padding15">
                        <div class="block">
                            <span class="demonstration">商品评分</span>
                            <el-rate
                                v-model="evaluateUser.star"
                                :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
                            </el-rate>
                        </div></div>
                    <div class="padding15">
                        <div class="block">
                            <span class="demonstration">服务评分</span>
                            <el-rate
                                v-model="evaluateUser.service_star"
                                :colors="['#99A9BF', '#F7BA2A', '#FF9900']">
                            </el-rate>
                        </div></div>
                    <upload-image  title="评价图片" v-model="evaluateUser.primary_pic_url" >
                        <div style="text-align: center;">4：3</div>
                    </upload-image>
                    <div>
                        <img :src="item" alt="" v-for="item in evaluateUser.gallery" style="width: 100px;">
                    </div>
                </div>
                <div class="width50">
                    <h2>已选择用户：<i class="el-icon-circle-check" size="50" color="#09bbe7"></i></h2>
                    <el-card :body-style="{ padding: '0px' }" shadow="hover">
                        <div class="disflex_flex_start padding15">
                            <img :src="selectUser.avatar" class="image">
                            <div style="padding: 14px;">
                                <div class="bottom clearfix">
                                    <el-button type="text" class="button">{{selectUser.nickname}}</el-button>
                                </div>
                            </div>
                        </div>
                    </el-card>
                    <el-table
                        :data="RobotList.data"
                        ref="RobotList"
                        style="width: 100%;cursor: pointer;" el-table-column highlight-current-row @current-change="chooseUser">
                        <el-table-column label="用户ID" width="100" prop="id"></el-table-column>
                        <el-table-column label="用户昵称" width="300">
                            <template slot-scope="scope">
                                <div slot="reference" class="name-wrapper">
                                    <img :src="scope.row.avatar" style="width: 40px;vertical-align: middle;">
                                    <span style="margin-left: 10px">{{ scope.row.nickname }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="已评价数量" width="100" >
                            <template slot-scope="scope">
                                <p style="text-align: center">{{ scope.row.evaluateNumber }}</p>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="paginaion" style="text-align: center;margin-top: 1em">
                        <el-pagination
                            @size-change="robotListSizeChange"
                            @current-change="robotListCurrentChange"
                            :current-page="RobotList.currentPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="RobotList.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="RobotList.count">
                        </el-pagination>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="saveEvaluate" type="primary">添加评价</el-button>
          </span>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'
    import clearCacheTool from '../../common/clearCacheTool'
    import uploadImage from '../../common/uploadImage'


    export default {
        data: function () {
            return {
                tableData: [],
                contentType: '',
                contentTypes: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                searchState: '',
                paperStatus: '未上线',
                isShowEvaluateDialog: false, // 是否显示添加评价窗口
                evaluateGoods: {

                }, //当前正在评价的商品
                evaluateUser: {
                    prise_num: this.getRandomNum(0, 2),
                    gallery: [],
                    star: 5,
                    service_star: 5,
                    add_time: this.getDefaultTime()
                },
                RobotList: {},
                selectUser: {}
            }
        },
        components: {
            commonlist,
            clearCacheTool,
            uploadImage
        },
        watch: {},
        created() {
            this.getPaperList(0, 1, 10);
            this.getRobotList('', 1 , 10);
        },
        mounted() {
            bus.$on('reloadPaper', res => {
                if (res) {
                    //console.log(res);
                    this.getPaperList(this.currentTagId, this.currentPage, this.size)
                }
            })
            let that = this;
            // 上传组件emit的是上传事件
            bus.$on('fileTraceBack', function (item) {
                that.evaluateUser.gallery.push(item);
            });

            this.evaluateUser.add_time = this.getDefaultTime();
        },
        methods: {
            getDefaultTime() {
                //一个月内
                let startStamp = new Date().getTime() - 2633026687;
                let endStamp = new Date().getTime();
                let randomStamp = startStamp + Math.random() * (endStamp - startStamp);

                let date = new Date(randomStamp);
                let hours = date.getHours();
                if(hours>8){
                    // this.evaluateUser.add_time = randomStamp;
                    return Math.ceil(randomStamp/1000);
                    // this.showTime(randomStamp);
                }else{
                    return this.getDefaultTime();
                }
            },
            //选择最近一周、三天
            getChooseTime(rangeTimeStamp){
                let startStamp = new Date().getTime() - rangeTimeStamp;
                let endStamp = new Date().getTime();
                let randomStamp = startStamp + Math.random() * (endStamp - startStamp);

                let date = new Date(randomStamp);
                let hours = date.getHours();
                if(hours>8){
                    this.evaluateUser.add_time = Math.ceil(randomStamp/1000);
                    // return Math.ceil(randomStamp/1000);
                    // this.showTime(randomStamp);
                }else{
                    return this.getChooseTime(rangeTimeStamp);
                }
                // console.log('this.evaluateUser.add_time',this.evaluateUser.add_time);
            },
            // 选择用户
            chooseUser (event) {
                this.selectUser = event
            },
            // 获取随机数
            getRandomNum (Min,Max) {
                var Range = Max - Min;
                var Rand = Math.random();
                var num = Min + Math.round(Rand * Range); //四舍五入
                return num;
            },
            // 保存评估价
            saveEvaluate () {
                if (!this.selectUser.id) {
                    this.$message.error('请选择用户！')
                    return
                }

                if (!this.evaluateUser.content) {
                    this.$message.error('请填写评价！')
                    return
                }
                // 添加评价图片
                // this.evaluateUser.gallery = this.evaluateUser.gallery;
                // if (this.evaluateUser.add_time < 1500000000) {
                //     this.evaluateUser.add_time = parseInt(this.getDefaultTime()/1000);
                // } else {
                //     this.evaluateUser.add_time = parseInt(this.evaluateUser.add_time/1000);
                // }
                this.evaluateUser.goods_id = this.evaluateGoods.id;
                this.evaluateUser.order_id = 888888; // 默认订单id
                this.evaluateUser.user_id = this.selectUser.id;
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/goods/addRobotEvaluate',
                    method: 'post',
                    data: this.evaluateUser
                }).then(response => {
                    // this.loading = false
                    if (response.data.errno === 0) {
                        // this.evaluateUser.add_time = this.getDefaultTime();
                        this.$message.success('添加评价成功！');
                        // 重置点赞数
                        this.evaluateUser = {
                            prise_num: this.getRandomNum(0, 2),
                            gallery: [],
                            star: 5,
                            service_star: 5,
                            add_time: this.getDefaultTime(),
                        }

                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                    loading.close()
                }).catch(err => {
                    loading.close()
                })
            },
            // 添加评价点击
            addEvaluate (item) {
                this.isShowEvaluateDialog = !this.isShowEvaluateDialog
                this.evaluateGoods = item
            },
            getRobotList(type, page, length) {
                this.$http({
                    url: this.$api + '/admin/user/getAllRobot',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 20,
                        // name: this.searchState
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data) {
                        this.RobotList = response.data.data;
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            robotListSizeChange (size) {
                this.getRobotList('', this.RobotList.currentPage, size);
            },
            robotListCurrentChange (page) {
                this.getRobotList('', page , this.RobotList.pageSize);
            },
            //按标题搜索列表
            handleContentType() {
                this.getPaperList(this.currentTagId, 1, this.tableData.pageSize)
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList(this.currentTagId,1, this.tableData.pageSize)
            },
            handleSizeChange(val) {
                this.getPaperList(this.currentTagId, this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(this.currentTagId, val, this.size)
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/goods/evaluateRobot',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 10,
                        name: this.searchState
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style>
    .padding15 {
        padding: 15px;
    }
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
