<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item><i class="el-icon-date"></i> 课程管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
<!--         <el-steps  :active="stepActive" finish-status="success">
          <el-step title="课程信息"></el-step>
          <el-step title="活动方案"></el-step>
          <el-step title="课程目录"></el-step>
        </el-steps> -->
        <div class="container">
          <h2 style="margin: 20px 0 20px 0;color: #bbb;">① 编辑课程信息</h2>
            <div class="step1" v-show="stepStatus.step1">
                <div class="uploadToolBox">
                    <div class="imgbox" style="text-align: center;">
                        <img class="uploadImgShowBack" style="width:50%;max-height: 200px !important;height: 200px;" :src="courseData.picture" v-if="courseData.picture">
                        <!-- <el-button  class="uploadImgShowBack" >重新上传</el-button> -->
                        <el-upload
                        class="upload_tool"
                        drag
                        :action="$api + UPLOAD_HEADER_IMG"
                        :before-upload="beforeupload" v-loading="loading"
                        :on-preview="handlePreviewImg"
                        :on-success="showFaceImg"
                        :headers="{ Auth_token }"
                         style="margin-bottom: 1em" >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">修改课程封面</div>
                            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，数量<i style="color: #ff0000;font-weight: bold;"> 1 </i>张，重复上传只取最后一张</div>
                        </el-upload>
                    </div>
                    <div class="videobox"  style="text-align: center;width:50%;">
                        <video id="video" class="uploadVideoShowBack" :src="courseData.testUrl" controls="controls" style="width:100%;max-height: 200px !important;height: 200px;" v-if="courseData.testUrl"></video>
                        <el-upload
                        class="upload_tool_video"
                        drag
                        :action="this.$api + COURSE_UPLOAD_VIDEO_N_AUDIO"
                        :before-upload="beforeVIDEOupload" v-loading="loadingVIDEO"
                        :on-preview="handlePreviewVideo"
                        :on-success="showFaceVideo"
                        :headers="{ Auth_token }"
                        accept=""
                        multiple style="margin-bottom: 1em;" >
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">【视频上传】 支持拖拽</em></div>
                            <div class="el-upload__tip" slot="tip" style="text-align: center;">只能上传MP4文件</div>
                        </el-upload>
                    </div>
                </div>
                <div style="display: flex;justify-content: space-between;align-items: flex-start;">
                        <div class="left" style="width: 50%">
                            <el-input placeholder="请输入课程标题" v-model="courseData.title" maxlength="40"></el-input>
                            <el-input placeholder="作者" v-model="courseData.author" style="width: 50%;margin: 5px 0 5px 0" maxlength="10"></el-input>
                            <el-input
                              type="textarea"
                              :autosize="{ minRows: 2, maxRows: 4}"
                              placeholder="请输入课程简介"
                              v-model="courseData.intro" maxlength="250">
                            </el-input>
                        </div>
                        <div class="right" style="width: 50%;text-align: left;padding-left: 50px;">
                            <el-select v-model="courseData.categoryId" placeholder="课程分类"  style="width: 50%">
                                <el-option
                                  v-for="item in tagSwitchData"
                                  :key="item.value"
                                  :label="item.name"
                                  :value="item.value">
                                </el-option>
                            </el-select>
                            <div style="padding: 15px;">
                                <el-switch
                                  v-model="courseData.tagboo"
                                  active-text="视频"
                                  inactive-text="音频">
                                </el-switch>
                            </div>
                            <el-input  type="number" placeholder="课程原价" style="width: 50%" v-model="courseData.price" max="999">
                                <template  slot="prepend">原价￥</template>
                            </el-input>
                            <div style="padding: 15px;">
                                <el-tag>是否置顶</el-tag>
                                <el-switch
                                  v-model="courseData.isTop"
                                  active-color="#13ce66"
                                  inactive-color="#ff4949">
                                </el-switch>
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <div class="container">
          <h2  style="margin: 20px 0 20px 0;color: #bbb;">② 编辑活动</h2>
            <div class="step2" v-show="stepStatus.step2">
                <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item label="活动标题" prop="promotionName">
                                <el-input v-model="courseStep2Data.promotionName" placeholder="请输入活动标题"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <div style="display: flex;justify-content: space-between;">
                        <el-row :gutter="20">
                          <el-col :span="12">
                            <div class="grid-content bg-purple">
                                <el-form-item label="原价" prop="name">
                                    <el-input v-model="courseStep2Data.price"></el-input>
                                </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="10">
                                <div class="grid-content bg-purple">
                                    <el-form-item label="优惠价" prop="name">
                                        <el-input v-model="courseStep2Data.discountPrice"></el-input>
                                    </el-form-item>
                                </div>
                            </el-col>
                            <el-col :span="2">
                                <el-tag>{{ courseStep2Data.discountPrice==courseStep2Data.price?  0:courseStep2Data.discount }}折</el-tag>
                            </el-col>
                            <el-col>
                                <label></label>
                                    <el-form-item label="添加标签" prop="name" style="color:#429493;">
                                        <el-tag
                                          :key="tag"
                                          v-for="tag in dynamicTags"
                                          closable
                                          :disable-transitions="false"
                                          @close="handleClose(tag)">
                                          {{tag}}
                                        </el-tag>
                                        <el-input
                                              class="input-new-tag"
                                              v-if="inputVisible"
                                              v-model="inputValue"
                                              ref="saveTagInput"
                                              size="small"
                                              @keyup.enter.native="handleInputConfirm"
                                              @blur="handleInputConfirm"
                                              style="width: 50%"
                                            ></el-input>
                                            <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
                                    </el-form-item>

                              </el-col>
                          <el-col>
                            <el-form-item label="起始时间" prop="name" style="color:#429493;">
                                    <el-date-picker
                                      v-model="selectTime"
                                      type="datetimerange"
                                      range-separator="至"
                                      start-placeholder="开始日期"
                                      end-placeholder="结束日期">
                                    </el-date-picker>
                            </el-form-item>
                          </el-col>
                        </el-row>
                    </div>
              </el-form>
            </div>
        </div>
        <div class="container">
          <h2  style="margin: 20px 0 20px 0;color: #bbb;">③ 编辑课程目录</h2>
            <div class="step3" v-show="stepStatus.step3">
                <el-table
                    :data="tableData"
                    style="width: 100%">
                    <el-table-column
                      label="序号"
                      width="80">
                      <template slot-scope="scope">
                          <div slot="reference" class="name-wrapper">
                            <el-tag size="medium">{{ scope.row.sort }}</el-tag>
                          </div>

                      </template>
                    </el-table-column>
                    <el-table-column
                      label="标题"
                      width="">
                      <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ scope.row.title }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="上传文件名"
                      width="200">
                      <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.fileName }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="发布时间"
                      width="150">
                      <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ Format(new Date(scope.row.createTime),'yyyy-MM-dd HH:mm:ss') }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="100px" >
                      <template slot-scope="scope" >
                         <div style="display: flex;justify-content: space-between;height:35px;align-items: flex-end;">
                              <i title="编辑" class="el-icon-edit-outline ft25 pointer" @click="editPaper(scope.row)"></i>
                            <i title="删除" class="el-icon-delete ft25 red pointer" @click="deletePaper(scope.row)"></i>
                         </div>
                      </template>
                    </el-table-column>
                  </el-table>

          <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="tableData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.count">
            </el-pagination>
          </div>
                  <!-- 弹窗 -->
                <el-dialog title="" :visible.sync="addCoursedialogFormVisible">
                  <el-form :model="addCourseList">
                    <el-form-item label="课程名称">
                        <el-input v-model="addCourseList.title" auto-complete="off" placeholder="请输入视频课程标题"></el-input>
                        <el-input type="number" v-model="addCourseList.sort" auto-complete="off" style="width: 30%" placeholder="请输入课程数字顺序"></el-input><i class="red" style="font-size: 12px">序号重复按时间排序</i>
                    </el-form-item>
                    <el-form-item label="课程视频真实地址">
                        <div class="videobox" style="width: 100%;text-align: center;">
                            <video id="video_for_upload" class="uploadVideoShowBack" :src="addCourseList.url" controls="controls" style="width:100%;max-height: 200px !important;height: 200px;" v-if="addCourseList.url"></video>
                            <el-upload
                            class="upload_tool_video"
                            drag
                            :action="this.$api + COURSE_UPLOAD_VIDEO_N_AUDIO"
                            :before-upload="beforeCOURSEupload" v-loading="loadingVIDEOforCourse"
                            :on-preview="handlePreviewCOURSE"
                            :on-success="showFaceCOURSE"
                            :headers="{ Auth_token }"
                            :file-list="fileList"
                            accept=""
                            multiple style="margin-bottom: 1em;width: 100%" >
                                <i class="el-icon-upload"></i>
                                <div class="el-upload__text">【课程视频上传】 支持拖拽</em></div>
                                <div class="el-upload__tip" slot="tip" style="text-align: center;">只能上传MP4文件</div>
                            </el-upload>
                    </div>
                    </el-form-item>
                  </el-form>
                  <div slot="footer" class="dialog-footer">
                    <el-button @click="addCoursedialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="addCourseVideo">{{ uploadOReditBtnName }}</el-button>
                  </div>
                </el-dialog>
            </div>
        </div>
        <div style="display: flex;justify-content: space-between;">
            <el-button class="editor-btn" type="primary" @click="addNewCourseClick">新增课程</el-button>
            <div>
               <!--  <el-button class="editor-btn" type="primary" @click="nextStep('last')"  v-if="stepActive >= 2">上一步</el-button>
                <el-button class="editor-btn" type="primary" @click="nextStep('next')"  v-if="stepActive < 3">保存并继续</el-button> -->
                <el-button class="editor-btn" type="primary" @click="submitEditPaper" >保存编辑</el-button>
            </div>
        </div>
        <!-- <uploadTool></uploadTool> -->
    </div>
</template>
<script>
    // import uploadTool from '../page/uploadTool'
    // import upload from './page/Upload'
    import bus from '../../common/bus'
    export default {
        data: function(){
            return {
                addCoursedialogFormVisible: false,
                uploadOReditBtnName: '上传',
                uploadOReditURL: '/chigua/admin/course/inventory/add',
                fileList: [],
                tableData: [], // 目录表单数组
                selectTime: [], //选择时间初始数组
                ruleForm: {},
                dynamicTags: [], // 标签数组
                inputVisible: false, // 标签添加的显示
                loadingVIDEOforCourse: '',
                inputValue: '', // 添加的标签名字
                stepActive: 1, // 绑定目前显示的栏目
                addCourseList:{
                    title: '',
                    fileName: '',
                    timeLength: '',
                    url: '',
                    sort: '',
                    courseId:'',
                    id: ''
                },
                stepStatus: { // 步骤状态
                    step1: true,
                    step2: true,
                    step3: true,
                },
                stepData: [
                    {name: 'step1', value: 1},
                    {name: 'step2', value: 2},
                    {name: 'step3', value: 3}
                ],
                fileList: [],
                loading: false,
                loadingVIDEO: false,
                options: [],
                courseStep2Data: { // 第二部
                    promotionName: '',
                    discountPrice: '', // 优惠价
                    price: '',
                    discount: '', // 折扣
                    discountRate: '', // 折扣
                    startTime:'',
                    endTime: '',
                    courseId: '',
                },
                tagSwitchData: [],
                // tagboo: true,
                courseData: { // 第一步
                    picture: '',
                    tagboo: true, //视频还是音频
                    title: '',
                    author: '',
                    intro: '',
                    categoryId: '',
                    tag: '',
                    testUrl: '',
                    price: '',
                    isTop: true
                },
                time: 1,
                courseId: '',
                currentPage: 1,
                total:0,
                page: 1,
                size: 10,
                searchStr: ''
            }
        },
        components: {
            // uploadTool
        },
        conputed: {

        },
        watch:{
            courseStep2Data: {
                handler (newValue) {
                    newValue.discount = (Math.floor((newValue.discountPrice/newValue.price)*100))/100
                    newValue.discountRate = (Math.floor((newValue.discountPrice/newValue.price)*100))/10
                    this.courseData.price = newValue.price
                },
                deep: true
            },
            selectTime: {
                handler (newValue) {
                    this.courseStep2Data.startTime = this.Format(new Date(newValue[0]),'yyyy-MM-dd HH:mm:ss')
                    this.courseStep2Data.endTime = this.Format(new Date(newValue[1]),'yyyy-MM-dd HH:mm:ss')
                },
                deep: true
            },
            tagboo: () => {
                this.courseData.tag = this.tagboo
            },
            courseData: {
                handler (newValue,oldValue) {
                    this.courseStep2Data.price = newValue.price
                    newValue.isTop == 0? newValue.isTop = false:true
                },
                deep: true
            }
        },
        created () {
            this.courseId = this.addCourseList.courseId = this.courseData.courseId =this.courseStep2Data.courseId = this.$route.query.courseId
            // 如果没有id，回到课程列表
            // if (courseId == '' || courseId == undefined || courseId == 'undefined') {
            //   this.$router.push('/courselist')
            //   return
            // }
            this.tagSwitchData = this.$getDicByName('COURSE_CATEGORY')
            // 把value转化为数字
            for (let item in this.tagSwitchData) {
              this.tagSwitchData[item].value = Number(this.tagSwitchData[item].value)
            }
            this.Auth_token = localStorage.getItem('auth_token')
            this.$http({
                url: this.$api + '/chigua/admin/article/category/list',
                method: 'post'
            }).then(response => {
                if (response.data != null) {
                    this.category = response.data.data
                }
            })
            if (this.courseId) this.loadCourseDataWhenEdit(this.courseId)
            this.loadCourseVideoList(this.courseId,this.searchStr,this.page,this.size)
            this.loadCourseActivityData(this.courseId)
        },
        mounted () {
        },
        methods: {
            addNewCourseClick () {
              this.addCoursedialogFormVisible = true
              this.uploadOReditBtnName = '上传'
              this.uploadOReditURL = '/chigua/admin/course/inventory/add'
            },
            handleSizeChange(val) {
              this.loadCourseVideoList (this.courseId,this.searchStr,this.page,val)
            },
            handleCurrentChange(val) {
              this.loadCourseVideoList (this.courseId,this.searchStr,val,this.size)
            },
            loadCourseActivityData (courseId,promotionName,page,limit) {
                this.$http({
                    url:this.$api + '/chigua/admin/course/promotion/page',
                    method: 'post',
                    data: this.$qs.stringify({
                      courseId: courseId? courseId:this.courseId,
                      promotionName: promotionName? promotionName:'',
                      page: page? page:1,
                      limit: limit? limit:10,
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                      for (let item in response.data.data.records[0]) {
                        for (let par in this.courseStep2Data) {
                          if (item == 'price') continue
                            this.courseStep2Data[item] = response.data.data.records[0][item]
                            // this.courseStep2Data[item] = response.data.data[item]
                        }
                      }
                      this.courseStep2Data.startTime = this.Format(new Date(response.data.data.records[0].startTime),'yyyy-MM-dd HH:mm:ss')
                      this.selectTime = []
                      this.selectTime.push(this.courseStep2Data.startTime)
                      this.courseStep2Data.endTime = this.Format(new Date(response.data.data.records[0].endTime),'yyyy-MM-dd HH:mm:ss')
                      this.selectTime.push(this.courseStep2Data.endTime)

                    } else {
                        this.$message.error(response.data.msg)
                    }
                }).catch(response => {

                    //console.log(response)
                })
            },
            loadCourseDataWhenEdit (id) {
                this.$http({
                    url:this.$api + '/chigua/admin/course/get',
                    method: 'post',
                    data: this.$qs.stringify({id: id})
                }).then(response => {
                    if (response.data.errno === 0) {
                      for (let item in response.data.data) {
                        for (let par in this.courseData) {
                            this.courseData[item] = response.data.data[item]
                            // this.courseStep2Data[item] = response.data.data[item]
                        }
                        for (let i in this.courseStep2Data) {
                          if (item == i) {
                            this.courseStep2Data[i] = response.data.data[item]
                          }
                        }
                      }
                    } else {
                        this.$message.error(response.data.msg)
                    }
                }).catch(response => {

                    //console.log(response)
                })
            },
            addCourseVideo () {
                var video = document.getElementById('video_for_upload')
                this.addCourseList.timeLength = video.duration
                if (this.addCourseList.url == '') {
                  this.$message.error('请上传视频文件或者等待文件上传完毕！')
                  return
                }
                this.$http({
                    url:this.$api + this.uploadOReditURL,
                    method: 'post',
                    data: this.$qs.stringify(this.addCourseList)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success(response.data.msg)
                        this.addCoursedialogFormVisible = false
                        for (let item in this.addCourseList) {
                            if (item == 'courseId') continue
                            if (item == 'id') continue
                            this.addCourseList[item] = ''
                        }
                        // 新增成功后，刷新表单
                        this.loadCourseVideoList(this.courseId,this.searchStr,this.page,this.size)
                        this.fileList = []
                    } else {
                        this.$message.error(response.data.msg)
                    }
                }).catch(response => {
                    // this.$message.error(response)
                    //console.log(response)
                })
            },
            // 步骤一校验
            validStep1 () {
                if (this.courseData.picture == '' || this.courseData.title =='' || this.courseData.testUrl == '') {
                    this.$message.error('请完善课程标题 / 文章封面 / 预览视频')
                    return false
                } else if (this.courseData.author == '' || this.courseData.intro == '') {
                    this.$message.error('请完善作者或课程简介')
                    return false
                } else if (this.courseData.categoryId == '' || this.courseData.price == '') {
                    this.$message.error('请完善课程分类 / 商品原价')
                    return false
                }
                return true
            },
            // 步骤二校验输入的内容
            validStep2 () {
               for (let item in this.courseStep2Data) {
                    if (this.courseStep2Data[item] == '' || this.courseStep2Data[item] == null) {
                        if (this.courseStep2Data.courseId == '') {
                            this.$message.error('课程信息错误，请返回上一步重新创建课程！')
                            return false
                            break
                        }
                        this.$message.error('请完善活动必填信息！')
                        return false
                        break
                    }
               }
               return true
            },
            loadCourseVideoList (id,title='',page,length) {
                this.$http({
                    url:this.$api + '/chigua/admin/course/inventory/page',
                    method: 'post',
                    data: this.$qs.stringify({
                        courseId: id? id:this.courseStep2Data.courseId,
                        title: title,
                        page: page? page:1,
                        limit: length? length:10
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.page = response.data.data.page
                        this.size = response.data.data.size
                        // 从这里开始加载下一步的列表
                    } else {
                        this.$message.error(response.data.msg)
                    }
                }).catch(response => {
                    // this.$message.error(response)
                    //console.log(response)
                })
            },
            // 保存课程活动信息
            saveCourseActivity () {
                this.$http({
                    url:this.$api + '/chigua/admin/course/promotion/add',
                    method: 'post',
                    data: this.$qs.stringify(this.courseStep2Data)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success(response.data.msg)
                        // 从这里开始加载下一步的列表
                        this.loadCourseVideoList(this.courseId,this.searchStr,this.page,this.size)
                    } else {
                        this.$message.error(response.data.msg)
                    }
                }).catch(response => {
                    // this.$message.error(response)
                    //console.log(response)
                })
            },
            //  保存课程信息
            // saveCourseData () {
            //     this.courseData.isTop? this.courseData.isTop=1:this.courseData.isTop=0
            //     for (let i in this.tagSwitchData) {
            //         if (this.tagSwitchData[i].value == this.courseData.categoryId) {
            //             this.courseData.tag = this.tagSwitchData[i].name
            //             break
            //         }
            //     }
            //     this.$http({
            //         url:this.$api + '/chigua/admin/course/add',
            //         method: 'post',
            //         data: this.$qs.stringify(this.courseData)
            //     }).then(response => {
            //         if (response.data.errno === 0) {
            //             this.$message.success(response.data.msg)
            //             this.courseStep2Data.courseId = this.addCourseList.courseId = response.data.data
            //         } else {
            //             this.$message.error(response.data.msg)
            //             // 提交错误，重新返回第一步提交
            //             this.stepActive = 0
            //         }
            //     }).catch(response => {
            //
            //         //console.log(response)
            //     })
            // },
            // 每次点击下一步进行的操作i
            // nextStep (type) {
            //     let thisStep = this.stepActive
            //     if (thisStep == 1) {
            //         // 验证步骤一是否符合要求
            //         if (this.validStep1()) {
            //             // 符合要求提交表达
            //             this.saveCourseData()
            //         } else {
            //             return
            //         }
            //     } else if (thisStep == 2) {
            //         // 验证步骤2是否符合要求
            //         if (!this.validStep2()) return
            //             // 符合要求提交表单
            //         this.saveCourseActivity()
            //     }
            //     if (type == 'next') {
            //         thisStep +=1
            //         if (thisStep > 3) thisStep = 3
            //         for (let i in this.stepData) {
            //             if (this.stepData[i].value == thisStep) {
            //                 for (let item in this.stepStatus) {
            //                     if (item == this.stepData[i].name) {
            //                         this.stepStatus[item] = true
            //                         this.stepActive = thisStep
            //                     } else {
            //                         this.stepStatus[item] = false
            //                     }
            //                 }
            //                 break
            //             }
            //         }
            //     } else if (type == 'last') {
            //         thisStep -=1
            //         if (thisStep < 1) thisStep = 1
            //         for (let i in this.stepData) {
            //             if (thisStep == this.stepData[i].value) {
            //                 for (var item in this.stepStatus) {
            //                     if (item == this.stepData[i].name) {
            //                         this.stepStatus[item] = true
            //                         this.stepActive = thisStep
            //                     } else {
            //                         this.stepStatus[item] = false
            //                     }
            //                 }
            //                 break
            //             }
            //         }

            //     } else {
            //         this.$message.error('')
            //         this.reload()
            //     }
            // },
            deletePaper (row) {
              this.$confirm('您正在删除课程，确认？', '警示', {
                confirmButtonText: '删除',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$http({
                    url:this.$api + '/chigua/admin/course/inventory/delete',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: row.id
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success(response.data.msg)
                        this.loadCourseVideoList(this.courseId,this.searchStr,this.page,this.size)
                    } else {
                        this.$message.error(response.data.msg)
                        // 提交错误，重新返回第一步提交
                        this.stepActive = 0
                    }
                }).catch(response => {
                    // this.$message.error(response)
                    //console.log(response)
                })
                }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '操作取消'
                });
              });

            },
            editPaper (row) {
                this.$http({
                    url:this.$api + '/chigua/admin/course/inventory/get',
                    method: 'post',
                    data: this.$qs.stringify({
                        id: row.id
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        // 设置公共上传参数，和新增课程共享了同一个HTML
                        this.addCoursedialogFormVisible = true
                        this.uploadOReditBtnName = '保存编辑'
                        this.uploadOReditURL = '/chigua/admin/course/inventory/update'

                        this.addCourseList = response.data.data
                        // 不能取代data.只能替换内容
                        for (let item in this.addCourseList) {
                            for(let i in response.data.data) {
                                if (item == i) {
                                    this.addCourseList[i] = response.data.data[i]
                                }
                            }
                        }
                    } else {
                        this.$message.error(response.data.msg)
                        // 提交错误，重新返回第一步提交
                    }
                }).catch(response => {
                    // this.$message.error(response)
                    //console.log(response)
                })
            },
            showInput () {
                this.inputVisible = true;
                this.$nextTick(_ => {
                  this.$refs.saveTagInput.$refs.input.focus();
                });
            },
            handleInputConfirm (value) {
                //console.log(value)
                let inputValue = this.inputValue;
                if (inputValue) {
                  this.dynamicTags.push(inputValue);
                }
                this.inputVisible = false;
                this.inputValue = '';
            },
            handlePreviewVideo () {

            },
            handlePreviewCOURSE () {

            },
            handlePreviewImg () {

            },
            showFaceCOURSE (response) {

                this.loadingVIDEOforCourse = false
                if (response.ret === 0) {
                    this.addCourseList.url = response.data.url
                    this.addCourseList.fileName = response.data.name
                } else if (response.data.errno === 500) {
                    this.$message.error(response.data.msg)
                }
            },
            showFaceVideo (response) {
                this.loadingVIDEO = false
                if (response.ret === 0) {
                    this.courseData.testUrl = response.data.url
                } else if (response.data.errno === 500) {
                    this.$message.error(response.data.msg)
                }
            },
            showFaceImg(res) {

                if (res.ret == 0) {
                    this.courseData.picture = res.data
                }
            },
            beforeCOURSEupload () {

            },
            beforeVIDEOupload () {

            },
            beforeupload () {

            },
            submitEditPaper() {
                this.courseData.isTop? this.courseData.isTop=1:this.courseData.isTop=0
                for (let i in this.tagSwitchData) {
                    if (this.tagSwitchData[i].value == this.courseData.categoryId) {
                        this.courseData.tag = this.tagSwitchData[i].name
                        break
                    }
                }
                var alldone = false
                const loading = this.$loading({
                  lock: true,
                  text: '小咪正在保存课程~',
                  spinner: 'el-icon-loading',
                  background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url:this.$api + '/chigua/admin/course/update',
                    method: 'post',
                    data: this.$qs.stringify(this.courseData)
                }).then(response => {
                    if (response.data.errno === 0) {
                        loading.close()
                        this.$http({
                            url:this.$api + '/chigua/admin/course/promotion/update',
                            method: 'post',
                            data: this.$qs.stringify(this.courseStep2Data)
                        }).then(response => {
                            if (response.data.errno === 0) {
                              this.$message.success('保存成功')
                              alldone = true
                              this.$router.push('/courselist')
                              this.$http({
                                url: this.$api + '/chigua/admin/course/update/talNumAndTime',
                                method: 'post',
                                data: this.$qs.stringify({id: this.courseData.id})
                              }).then(response => {
                                 //console.log('统计课时')
                              })
                            }
                        })
                    } else {
                        this.$message.error(response.data.msg)
                        // 提交错误，重新返回第一步提交
                    }
                }).catch(response => {
                    // this.$message.error(response)
                    //console.log(response)
                })
                loading.close()
            }
        }
    }
</script>
<style scoped>
.container{
  margin: 10px 0 10px 0;
}
.ft25{
    font-size: 25px;
}
.red{
    color: red;
}
    .uploadAudio i{
        margin: 0 !important;
    }
    .uploadAudio{
        padding: 0
    }
    .upload-demo .el-upload--text{
        display: inline !important;
        text-align: center;
        cursor: pointer;
        outline: none;
    }
    .editor-btn{
        margin-top: 20px;
    }
    #title-input:-ms-input-placeholder,#title-input:-moz-placeholder{
        font-size: 30px;
        text-align: left;
    }
    .uploadToolBox{
        display: flex;
    align-items: flex-start;
    justify-content: space-between;
    }
    .uploadAudio{
        margin-bottom: 1em
    }
</style>
