<template>
    <div class="header">
        <!-- 左侧按钮 -->
        <!--  el-icon-czd-zhankai -->
        <div class="collapse-btn" @click="collapseChage">
            <el-tooltip effect="dark" :content="!collapse? '展开':'收起'" placement="bottom">
             <i :class="!collapse ? 'el-icon-czd-zhankai1':'el-icon-czd-takeup'"></i>
            </el-tooltip>
        </div>
        <!--<div class="collapse-btn" @click="refreshChange">-->
            <!--<el-tooltip effect="dark" content="刷新" placement="bottom">-->
                <!--<i class="el-icon-refresh"></i>-->
            <!--</el-tooltip>-->
        <!--</div>-->

        <!-- 右侧按钮 -->
        <div class="header-right-btn" @click.stop="reloadPage">
            <i class="el-icon-loading" ></i>
        </div>

        <div >
        <el-dropdown class="header-right-btn header-btn"  @command="handleCommand">
                <span class="el-dropdown-link">
                    {{username}} <i class="el-icon-caret-bottom"></i>
                </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item divided command="password">修改密码</el-dropdown-item>
                <el-dropdown-item divided command="information">个人信息</el-dropdown-item>
                <el-dropdown-item divided command="loginout">退出登录</el-dropdown-item>
                <el-dropdown-item divided command="loginout" v-if="$store.state.UA === 'MOBILE'">
                    <clearCacheTool style="line-height: 48px;"  ></clearCacheTool>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        </div>
        <div class="header-right-btn user-avator header-btn" @click="handleFullScreen">
            <img src="https://oss.mxclass.com/cathouse_pro/logo/maobuli.png">
        </div>

        <!--清理缓存工具-->
        <clearCacheTool style="line-height: 48px;"  v-if="$store.state.UA !== 'MOBILE'"></clearCacheTool>

        <div class="header-right-btn header-btn" @click="handleFullScreen" v-if="$store.state.UA !== 'MOBILE'">
            <el-tooltip effect="dark" :content="fullscreen?`取消全屏`:`全屏`" placement="bottom">
                <i class="el-icon-czd-quanping1"></i>
            </el-tooltip>
        </div>

        <el-dialog title="修改密码" :visible.sync="updatePswShow" width="30%">
            <el-form :model="ruleForm2" status-icon :rules="rules2" ref="ruleForm2" label-width="100px"
                     class="demo-ruleForm">
                <el-form-item label="旧密码" prop="pass">
                    <el-input type="password" v-model="ruleForm2.oldPass" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="pass">
                    <el-input type="password" v-model="ruleForm2.pass" auto-complete="off"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="checkPass">
                    <el-input type="password" v-model="ruleForm2.checkPass" auto-complete="off"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm2')">提交</el-button>
                    <el-button @click="resetForm('ruleForm2')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    import bus from '../common/bus';
    import clearCacheTool from '../common/clearCacheTool'

    export default {
        data() {
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    if (this.ruleForm2.checkPass !== '') {
                        this.$refs.ruleForm2.validateField('checkPass');
                    }
                    callback();
                }
            };
            var validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.ruleForm2.pass) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                ruleForm2: {
                    oldPass: '',
                    pass: '',
                    checkPass: ''
                },
                rules2: {
                    pass: [
                        {validator: validatePass, trigger: 'blur'}
                    ],
                    checkPass: [
                        {validator: validatePass2, trigger: 'blur'}
                    ]
                },
                fullscreen: false,
                updatePswShow: false,
                name: '未知用户',
                message: 2,
                headerIcon: 'el-icon-czd-zhankai'
            }
        },
        computed: {
            username() {
                let username = localStorage.getItem('guajie_username');
                return username ? username : this.name;
            },
            collapse () {
                return this.$store.state.collapse
            }
            //这里的三点叫做 : 扩展运算符
            // ...mapState({
            //   show:state=>state.dialog.show
            // }),
        },
        components: {
            clearCacheTool
        },
        created() {
            // 测试vuex
            // if (true) {
            //     // 出发store的状态更改，执行mutations里面的操作
            //     this.$store.dispatch('switch_dialog','sadfgasdfasdf')
            //     alert(this.$store.state.dialog.show)
            //     alert(this.$store.getters.not_show)
            // }
        },
        mounted () {
            let that = this;
            if (window.screen.availWidth <= 769) {
                bus.$emit('collapse', that.collapse);
                this.$store.dispatch('changeCollapse', true)
                this.$store.dispatch('changeUA', 'MOBILE')
            }

            window.onresize = () => {
                if (window.screen.availWidth <= 769) {
                    this.$store.dispatch('changeCollapse', true)
                    this.$store.dispatch('changeUA', 'MOBILE')
                } else {
                    this.$store.dispatch('changeUA', 'WEB')
                }
            }
        },
        methods: {
            reloadPage () {
                window.location.reload()
            },
            submitForm(formName) {
                if (this.ruleForm2.pass !== this.ruleForm2.checkPass) {
                    this.$message.error('两次输入的密码不一致，请检查！')
                    return
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$http({
                            url: this.$api + '/admin/auth/updatePass',
                            method: 'post',
                            data: this.$qs.stringify({
                                password: this.ruleForm2.pass,
                                oldPassword: this.ruleForm2.oldPass
                            })
                        }).then(res => {
                            console.log(res)
                            if (res.data.errno == 0) {
                                this.$message.success('更新密码成功！请重新登录！');
                                this.updatePswShow = false
                            } else {
                                this.$message.error(res.data.errmsg)
                            }
                        }).catch(response => {

                        })
                    } else {
                        //console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // 用户名下拉菜单选择事件
            handleCommand(command) {
                if (command == 'loginout') {
                    localStorage.setItem('auth_token', '');
                    localStorage.removeItem('guajie_username')
                    localStorage.removeItem('userMsg')
                    this.$router.push('/login');
                } else if (command == 'password') {
                    this.updatePswShow = true
                } else if (command == 'information') {
                    this.$message('正在开发中....');
                }
            },
            refreshChange() {

                // bus.$emit('refresh');
            },
            // 侧边栏折叠
            collapseChage() {
                this.$store.dispatch('changeCollapse', !this.collapse)
            },
            // 全屏事件
            handleFullScreen() {
                let element = document.documentElement;
                if (this.fullscreen) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitCancelFullScreen) {
                        document.webkitCancelFullScreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (element.requestFullscreen) {
                        element.requestFullscreen();
                    } else if (element.webkitRequestFullScreen) {
                        element.webkitRequestFullScreen();
                    } else if (element.mozRequestFullScreen) {
                        element.mozRequestFullScreen();
                    } else if (element.msRequestFullscreen) {
                        // IE11
                        element.msRequestFullscreen();
                    }
                }
                this.fullscreen = !this.fullscreen;
            }
        }
    }
</script>
<style scoped>
    .header {
        /*position: relative;*/
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        font-size: 22px;
        color: #010101;
        border-bottom: 1px solid #f0f0f0;
    }

    .collapse-btn {
        float: left;
        padding: 0 21px;
        cursor: pointer;
        line-height: 48px;
    }
    .header-right-btn {
        float: right;
        padding: 0 15px;
        cursor: pointer;
        line-height: 48px;
    }

    .header .logo {
        float: left;
        width: 250px;
        line-height: 50px;
    }

    .logo-icon {
        float: left;
        width: 50px;
        margin-top: 15px;
        margin-left: 140px;
    }

    .header-right {
        float: right;
        padding-right: 50px;
    }

    .header-btn:hover{
        background: #f0f0f0;
        border-bottom: 2px solid blue;
    }

    .header-user-con {
        display: flex;
        height: 50px;
        align-items: center;
    }

    .btn-fullscreen {
        transform: rotate(45deg);
        margin-right: 5px;
        font-size: 24px;
    }

    .btn-bell, .btn-fullscreen {
        position: relative;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 15px;
        cursor: pointer;
    }

    .btn-bell-badge {
        position: absolute;
        right: 0;
        top: -2px;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #f56c6c;
        /*color: #fff;*/
    }

    .user-avator {
        height: 48px;
        padding-right: 4px;
        padding-left: 4px;

    }

    .user-avator img {
        margin-top: 8px;
        display: block;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }

    .el-dropdown-link {
        /*color: #fff;*/
        cursor: pointer;
    }

    .el-dropdown-menu__item {
        text-align: center;
    }
</style>
