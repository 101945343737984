<template>
    <div class='login-box width100' style="position: relative">
        <div class="width100">
            <video autoplay="" loop="" muted=""
                   style="margin: auto; position: absolute; z-index: -1;
                    top: 50%; left: 50%; transform: translate(-50%, -50%);
                     visibility: visible; width: 100%;
                      height: 782px;object-fit: fill;">
                <source src="https://oss.mxclass.com/cathouse_pro/bg_video.mp4" type="video/mp4">
            </video>
        </div>
<!--        <div class="ms-title">-->
<!--            <img src="https://file.chezidao.com/root/static/icon/guajie_logo.png" class="guajie-logo">-->
<!--            <span>深圳市猫趴科技有限公司</span>-->
<!--        </div>-->
        <div class="login-wrap" :style="windowHeight">
            <el-card v-show="!isShowSecondCheck" class="ms-login" :body-style="{padding:'30px'}">
                <div slot="header" style="text-align:center;color: #00a0e9;font-size: 25px">
                    <span>用户登录</span>
                </div>
                <el-form status-icon :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
                    <el-form-item prop="userName">
                        <el-input prefix-icon="el-icon-czd-pengyou" type="text" v-model="ruleForm.userName"
                                  placeholder="用户名/手机号"></el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input prefix-icon="el-icon-czd-quanxian" type="password" placeholder="密码"
                                  v-model="ruleForm.password"
                                  @keyup.enter.native="submitForm('ruleForm')"></el-input>
                    </el-form-item>
                    <!--<el-form-item prop="code">-->
                        <!--<div class="disflex_around_center">-->
                            <!--<el-input prefix-icon="el-icon-czd-quanxian" type="text" placeholder="验证码"-->
                                      <!--v-model="ruleForm.code" @keyup.enter.native="submitForm('ruleForm')">-->
                            <!--</el-input>-->
                            <!--<img class="cursor_pointer" @click="changeCode" ref="code2valid" style="width: 250px;" :src="code2validate" alt="">-->
                        <!--</div>-->
                    <!--</el-form-item>-->
                    <el-form-item prop="code">
                        <!-- 验证码 -->
                        <div ref="captcha" id="captcha"></div>
                        <!-- 验证结果 -->
                        <!--<div id="msg"></div>-->
                    </el-form-item>

                    <div class="login-btn">
                        <el-button :disabled="disabledClickLogin" type="primary" @click="sendCheck('ruleForm')"
                                   :loading="loging">校验
                        </el-button>
                    </div>
                </el-form>
            </el-card>
            <div v-show="isShowSecondCheck" class="bg_fff padding15 ms-login text-align-center">
                <div id="wechat_code"></div>
                <div class="disflex_around_center">
                    <el-button class="width40"  type="info" @click="isShowSecondCheck = false">取消 </el-button>
                    <el-button class="width40" :disabled="disabledClickLogin" type="primary" @click="submitForm('ruleForm')"
                               :loading="loging">登录
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import util from '../../utils/util'
    import '../../utils/slider/slider.js'

    export default {
        data: function () {
            var validateUserName = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入用户名'));
                } else if (value.length < 5) {
                    callback(new Error('用户名不少于5位数'));
                } else {
                    callback();
                }
            };
            var validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入密码'));
                } else {
                    callback();
                }
            };
            return {
                isShowSecondCheck: false, // 是否显示二次校验
                disabledClickLogin: true, //
                loging: false,
                ruleForm: {
                    userName: '',
                    password: '',
                    code: ''
                },
                code2validate: this.$api + '/admin/auth/code', // 验证码接口
                // code2md5: '', // 验证码
                rules: {
                    userName: [
                        {validator: validateUserName, required: true, message: '请输入用户名', trigger: 'blur'}
                    ],
                    password: [
                        {validator: validatePass, required: true, message: '请输入密码', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted () {
            this.getCaptcha();
            const token = localStorage.getItem('auth_token');
            if (token && token.length > 0) {
              this.$router.push('/dashboard');
            }
        },
      created () {
        const token = localStorage.getItem('auth_token');
        if (token && token.length > 0) {
          this.$router.push('/dashboard');
        }
      },
      computed : {
            windowHeight () {
              return `min-height: ${document.body.clientHeight}px;position: relative;`
            }
        },
        methods: {
            // 二次校验 显示微信登录弹窗
            sendCheck () {

                if (!this.ruleForm.userName) {
                    this.$message.error('请输入账号');
                    return;
                }

                if (!this.ruleForm.password) {
                    this.$message.error('请输入密码！');
                    return;
                }

                if (this.disabledClickLogin) {
                    this.$message.error('请告诉我你不是机器人，正确移动验证图块');
                    return;
                }

                this.isShowSecondCheck = true;

                !function(a, b, c) {
                    function d(a) {
                        let c = "default";
                        a.self_redirect === !0 ? c = "true": a.self_redirect === !1 && (c = "false");
                        let d = b.createElement("iframe"),
                            e = "https://open.weixin.qq.com/connect/qrconnect?appid=" + a.appid + "&scope=" + a.scope + "&redirect_uri=" + a.redirect_uri + "&state=" + a.state + "&login_type=jssdk&self_redirect=" + c + '&styletype=' + (a.styletype || '') + '&sizetype=' + (a.sizetype || '') + '&bgcolor=' + (a.bgcolor || '') + '&rst=' + (a.rst || '');
                        e += a.style ? "&style=" + a.style: "",
                            e += a.href ? "&href=" + a.href: "",
                            d.src = e,
                            d.frameBorder = "0",
                            d.allowTransparency = "true",
                            d.scrolling = "no",
                            d.width = "300px",
                            d.height = "400px";
                        let f = b.getElementById(a.id);
                        f.innerHTML = "",
                            f.appendChild(d)
                    }
                    a.WxLogin = d
                } (window, document);

                let obj = new WxLogin({
                    self_redirect:true,
                    id:"wechat_code",
                    appid: "wx851713401a607094",
                    scope: "snsapi_login",
                    redirect_uri: "https://shop.catparty.vip/api/open/webLoginAcessControl",
                    // redirect_uri: "https://shop.catparty.vip",
                    state: this.ruleForm.userName,
                    style: "black",
                    href: ""
                });
                console.log(obj)
            },
            getCaptcha() {
                jigsaw.init({
                    el: this.$refs.captcha,
                    onSuccess: this.onSuccess,
                    onFail: this.onFail,
                    onRefresh: this.cleanMsg
                });
            },
            onSuccess() {
                this.disabledClickLogin = false // 允许登录
                this.$notify({
                    title: '校验提示',
                    message: '校验成功！可以登录！',
                    type: 'success'
                });
            },
            onFail() {
                this.$message.error('验证失败！请重新验证！');
            },
            cleanMsg() {
                this.$message.error('验证失败!请重新验证');
            },
            // 更换验证码
            changeCode () {
              this.code2validate = this.$api + '/admin/auth/code?d=' + new Date().getTime();
            },
            // 提交
            submitForm(formName) {
                // if (!this.ruleForm.code) {
                //     return this.$message.error('请输入验证码！');
                // }

                if (this.disabledClickLogin) {
                    this.$message.error('请告诉我你不是机器人，正确移动验证图块');
                    return;
                }

                this.loging = true;
                this.$http({
                    method: 'post',
                  // 增加 / 否则请求资源名错误
                    url: this.$api + '/admin/auth/login',
                    data: this.$qs.stringify({
                        payload: util.base64_encode('0x_' + util.base64_encode(new Date().getTime() + '%0^0&0*' + this.ruleForm.userName + '%0^0&0*' + this.ruleForm.password + '%0^0&0*'  + this.ruleForm.code)),
                        username: util.base64_encode('0x_' + util.base64_encode(this.ruleForm.userName) + '%0^0&0*'),
                        code: this.ruleForm.code
                    })
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('登陆成功');
                        localStorage.setItem('username', response.data.data.userInfo.username);
                        localStorage.setItem('auth_token', response.data.data.token);
                        localStorage.setItem('userMsg', JSON.stringify(response.data.data.userInfo));
                        this.$router.push('/dashboard');
                        // 业务需求，在这里加上业务字典请求，在登陆时就夹在字典
                        this.$getDictionary()
                    } else if (response.data.errno === 1000) {
                        this.$message.error('验证码有误！！！');
                        this.changeCode()
                        this.loging = false
                    } else {
                        this.$message.error(response.data.errmsg);
                        this.loging = false
                    }
                }).catch(e => {
                    //console.log(e)
                    this.loging = false
                })

            },
        }
    }
</script>

<style scoped>
    @import "../../utils/slider/slider.css";
    .companyName {
        font-size: 12px;
        font-family: '微软雅黑';
        font-weight: lighter;
    }

    .fogetpsw {
        text-align: right;
        padding: 5px 0px 5px 0px;
    }

    .fogetpsw a {
        color: #000;
        font-size: 12px;
        text-decoration: none;
    }

    .guajie-logo {
        width: 30px;
        vertical-align: middle;
    }

    .login-box {
        width: 100%;
        height: 100%;
        position: relative;
        /*background: url('https://oss.mxclass.com/share/background2dd78f.png');*/
        background-repeat: repeat-x;
        background-size: cover;
    }

    .login-wrap {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: transparent;
    }

    .ms-title {
        text-align: left;
        font-size: 25px;
        color: #000;
        margin-left: 2em;
        padding: 15px 0;
    }

    .ms-login {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        background: #fff;
        width: 360px;
    }

    .login-btn {
        text-align: center;
    }

    .login-btn button {
        width: 100%;
        height: 40px;
    }

</style>
