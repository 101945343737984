<template>
    <div>
        <el-dialog :visible.sync="showaddForm" width="60%" :title="editMenuData.name">
            <!-- :title="'文章评论(共'+commons.total+'条)'" -->
            <el-form :model="editMenuData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <!--<el-form-item label="分类类型" prop="menuType">-->
                <!--<el-radio :disabled="editMenuData.menuType!==item.value" v-model="editMenuData.menuType"-->
                <!--v-for="item in menuType" :label="item.value" :key="item.value" border>{{item.name}}-->
                <!--</el-radio>-->
                <!--</el-form-item>-->

                <el-form-item label="分类名称" prop="name">
                    <el-input v-model="editMenuData.name"></el-input>
                </el-form-item>
                <el-form-item  label="一级分类图标" v-if="editMenuData.level == 'L1'">
                    <upload-image title="一级分类必填" v-model="editMenuData.icon_url" style="margin-right: 50px">
                        <div style="text-align: center;">一级分类logo必填，二级分类可不填</div>
                    </upload-image>
                </el-form-item>
                <el-form-item  label="分类背景">
                    <upload-image title="分类背景（用于分类头图）和二级分类的logo" v-model="editMenuData.wap_banner_url" style="margin-right: 50px">
                        <div style="text-align: center;">一级分类logo必填，二级分类可不填</div>
                    </upload-image>
                </el-form-item>

                <!--<el-form-item label="分类分级（默认一级，添加子分类）">-->
                <!--<el-radio v-model="editMenuData.level"  label="L1" border>一级分类</el-radio>-->
                <!--<el-radio v-model="editMenuData.level"  label="L2" border>二级分类</el-radio>-->
                <!--</el-form-item>-->

                <el-form-item label="分类父ID" prop="parent_id">
                    <el-input v-model="editMenuData.parent_id" disabled></el-input>
                </el-form-item>



                <el-form-item label="排序">
                    <el-input-number v-model="editMenuData.sort_order" :min="1" :max="1000"></el-input-number>
                </el-form-item>

                <el-form-item label="分类简称" prop="sort">
                    <el-input v-model="editMenuData.front_name"></el-input>
                </el-form-item>

                <el-form-item label="分类简介" prop="sort">
                    <el-input v-model="editMenuData.front_desc"></el-input>
                </el-form-item>


                <el-form-item label="状态" prop="is_show">
                    <el-radio v-model="editMenuData.is_show" v-for="item in isShows" :label="item.value"
                              :key="item.value" border>{{item.name}}
                    </el-radio>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addMenu('ruleForm')">添加分类</el-button>
                    <el-button @click="resetForm('ruleForm')">重置分类</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../common/bus';
    import uploadImage from '../common/uploadImage'

    export default {
        data() {
            return {
                showaddForm: false,
                editMenuData: {
                    name: '',
                    url: '',
                    icon_url: '', // 一级分类logo
                    wap_banner_url: '', // 分类背景Url,二级分类胡logo
                    level: '', // 分类分级
                    sort_order: 1, // 分类排序
                    front_name: '', // 分类简称
                    front_desc: '', // 分类简介
                    perms: '',
                    is_show: 1, // 是否上线该分类
                    keepAlive: 0,
                    parent_id: 0
                },
                isShows: [
                    {name: '显示', value: 1},
                    {name: '隐藏', value: 0}
                ],
                rules: {
                    name: [
                        {required: true, message: '请输入分类名称', trigger: 'blur'},
                        {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
                    ],
                    isShow: [
                        {required: true, message: '请选择分类状态', trigger: 'blur'}
                    ],
                    parent_id: [
                        {required: true, message: '请填写分类id', trigger: 'blur'}
                    ]
                }
            }
        },
        components: {
            uploadImage
        },
        watch: {},
        mounted() {
            bus.$on('editCategory', data => {
                this.showaddForm = true
                this.editMenuData= data
            })
        },
        methods: {
            addMenu() {
                this.$http({
                    url: this.$api + '/admin/category/update',
                    method: 'post',
                    data: this.$qs.stringify(this.editMenuData)
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.showaddForm = false
                        this.$message.success('修改分类添加成功!')
                        let update = true
                        bus.$emit('updateCategory', update)
                    } else if (response.data.errno === 500) {
                        this.$message.error(response.data.msg)
                    }
                }).catch(error => {
                    //console.log(error)
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            // dialogClose(done) {
            //   this.showEditForm = false
            // }
        }
    }
</script>
<style>
</style>>
