<template>
    <div class="tags" v-if="showTags">
        <ul class="tags-ul">
            <li class="tags-li" v-for="(item,index) in tagsList" :class="{'active': isActive(item.path)}" :key="index">
                <router-link :to="item.path" class="tags-li-title">
                    {{item.title}}
                </router-link>
                <span class="tags-li-icon" @click="closeTags(index)"><i class="el-icon-close"></i></span>
            </li>
        </ul>
        <div class="tags-close-box" v-if="$store.state.UA !== 'MOBILE'">
            <el-dropdown @command="handleTags">
                <el-button size="mini" type="primary">
                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu size="small" slot="dropdown">
                    <el-dropdown-item command="other">关闭其他</el-dropdown-item>
                    <el-dropdown-item command="all">关闭所有</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                tagsList: []
            }
        },
        methods: {
            // 更新缓存
            updateBackendCache () {
                this.$http({
                    url: this.$api + '/admin/cache/del',
                    method: 'post',
                    data: this.$qs.stringify({
                        cache_type: 'mp_index_cache',
                    })
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success('缓存更新成功！客户端数据版本为最新！');
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(res => {
                    //console.warn(res)
                })
            },
            isActive(path) {
                return path.split('?')[0] === this.$route.path;
            },
            // 关闭单个标签
            closeTags(index) {
                const delItem = this.tagsList.splice(index, 1)[0];
                const item = this.tagsList[index] ? this.tagsList[index] : this.tagsList[index - 1];
                if (item) {
                    delItem.path.split('?')[0] === this.$route.path && this.$router.push(item.path);
                } else {
                    this.$router.push('/');
                }
            },
            // 关闭全部标签
            closeAll() {
                this.tagsList = [];
                this.$router.push('/');
            },
            // 关闭其他标签
            closeOther() {
                const curItem = this.tagsList.filter(item => {
                    return item.path === this.$route.path;
                })
                this.tagsList = curItem;
            },
            // 设置标签
            setTags(route) {
                if (route.path === '/empty') return
                let index = 0
                const isExist = this.tagsList.some(item => {
                    index++
                    let p = item.path;
                    let length = p.indexOf('?');
                    if (length === -1) {
                        return p === route.path;
                    } else {
                        return p.substring(0, length) === route.path;
                    }
                })
                let path = route.path
                let flag0 = true
                for (let item in route.query) {
                    if (flag0) {
                        path = path + '?' + item + '=' + route.query[item]
                        flag0 = false
                    } else {
                        path = path + '&' + item + '=' + route.query[item]
                    }
                }
                //如果存在则替换原地址
                if (isExist) {
                    if (route.query) {
                        this.tagsList.splice(index - 1, 1, {
                            title: route.meta.title,
                            path: path
                        })
                    }
                } else {
                    this.tagsList.push({
                        title: route.meta.title,
                        path: path
                    })
                }
            },
            handleTags(command) {
                command === 'other' ? this.closeOther() : this.closeAll();
            }
        },
        computed: {
            showTags() {
                return this.tagsList.length > 0;
            }
        },
        watch: {
            $route(newValue, oldValue) {
                this.setTags(newValue);
            }
        },
        created() {
            this.setTags(this.$route);
        }
    }

</script>


<style>
    .tags {
        position: relative;
        height: 30px;
        overflow: hidden;
        background: #FFFFFF;
        padding-right: 120px;
        border-bottom: #00b7ee 1px solid;
    }

    .tags ul {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        /*overflow-x: scroll;*/
    }

    .tags-li {
        float: left;
        margin: 3px 5px 2px 3px;
        border-radius: 3px;
        font-size: 12px;
        overflow: hidden;
        cursor: pointer;
        height: 23px;
        line-height: 23px;
        border: 1px solid #e9eaec;
        background: #fff;
        padding: 0 5px 0 12px;
        vertical-align: middle;
        color: #666;
        -webkit-transition: all .3s ease-in;
        -moz-transition: all .3s ease-in;
        transition: all .3s ease-in;
    }

    .tags-li:not(.active):hover {
        background: #f8f8f8;
    }

    .tags-li.active {
        color: #fff;
    }

    .tags-li-title {
        float: left;
        max-width: 80px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 5px;
        color: #666;
    }

    .tags-li.active .tags-li-title {
        color: #fff;
    }

    .tags-close-box {
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: border-box;
        padding-top: 1px;
        text-align: center;
        width: 110px;
        height: 30px;
        background: #fff;
        box-shadow: -3px 0 15px 3px rgba(0, 0, 0, .1);
        z-index: 10;
    }

</style>
