<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ tableData.length }}个商品）</label>
        </div>
        <div class="button-tool">
            <div>
                <el-button type='primary' @click="$router.push('/goodsList')">新建订阅(从商品列表新建)</el-button>
                <!--<el-button type='primary' @click="$router.push('/newspaper?ct=2')">新增公众号</el-button>-->
                <!--<el-button type='success' @click="editPaperType_btn">编辑分类</el-button>-->
            </div>

            <div style="display: flex">
                <el-radio-group v-model="contentType" style="margin-right: 20px;margin-top: 10px"
                                @change="handleContentType">
                    <el-radio v-for="item in contentTypes" :label="item.value" :name="item.name" :key="item.value">
                        {{item.name}}
                    </el-radio>
                </el-radio-group>
                <el-input placeholder="请输入商品名查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 500px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
        </div>
        <div class="tagSwitch disflexct">
            <el-tabs v-model="activeName" @tab-click="switchTag" style="width: 100%">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id"
                             :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table
            :data="tableData"

            style="width: 100%">
            <el-table-column label="商品id" width="100" prop="goods_id"></el-table-column>
            <el-table-column
                label="订阅主图"
                width="150">
                <template slot-scope="scope">
                    <img class="width80px" style="height: 80px;" :src="scope.row.list_pic_url" alt="">
                </template>
            </el-table-column>

            <el-table-column
                label="商品名称">
                <template slot-scope="scope">
                    <p>{{scope.row.goods_name}}</p>
                </template>
            </el-table-column>

            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-between;height:35px;
     align-items: flex-end;">
                        <!-- 编辑 -->
                        <i title="编辑" class="el-icon-edit-outline ft25 pointer"
                           @click="gotoGoodsDetailEdit(scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    export default {
        name: 'promotionList',
        data: function () {
            return {
                activeName: '全部',
                addPaperType_bool: false,
                // loading: true,
                tableData: [],
                tagSwitchData: [],
                contentType: '',
                contentTypes: [],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: '',
                searchState: '',
                paperStatus: '未上线',
                paperId: '',
                showCommon: false,
                commonData: '',
                addPaperData: {
                    name: ''
                },
                editPaperData: {
                    name: ''
                },
                paperTypeData: [],
                editPaperStr: '新  增',
                oprationType: 'add',
                tableHeight: 600,
                is_edit: false
            }
        },
        components: {
        },
        watch: {
            '$route' (to, from) {
                if (to.path === '/goodslist') {
                    if (to.query.page) {
                        this.getPaperList(0, to.query.page, 10)
                    }
                }
            }
        },
        created() {
            this.getPaperList(0, this.$route.query.page || 1, 10)
        },
        mounted() {
        },
        methods: {
            gotoGoodsDetailEdit (row) {
                let routeUrl = this.$router.resolve({
                    path: "/goodsedit",
                    query: {id:row.goods_id}
                });
                window.open(routeUrl.href, '_blank');
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.getPaperList()
            },
            getPaperList(type, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/admin/goods/subscribeList',
                    method: 'post',
                    data: this.$qs.stringify({
                        name: this.searchState
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
