<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span></label>
        </div>
        <div class="button-tool">
            <div style="display: flex">
                <el-input placeholder="请输入关键字查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle"
                          style="width: 200px">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
            <div class="disflex_flex_start">
                <el-button type="primary" @click="loadUserSearchKeyword(7)">最近7天</el-button>
                <el-button type="primary" @click="loadUserSearchKeyword(30)">最近一个月</el-button>
                <el-button type="primary" @click="loadUserSearchKeyword(90)">最近3个月</el-button>
                <el-button type="primary" @click="loadUserSearchKeyword(180)">最近半年</el-button>
                <el-button type="primary" @click="loadUserSearchKeyword(365)">最近一年</el-button>
            </div>
        </div>
        <el-table
            :data="userSearchKeyword"
            style="width: 100%">
            <el-table-column
                label="搜索关键字">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span style="margin-left: 10px">{{ scope.row.keyword }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="搜索次数"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <span style="margin-left: 10px">{{ scope.row.count }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="搜索分析"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <el-button type="primary" @click="$router.push('/keywordTimeAnalyse?keyword=' + scope.row.keyword)">数据分析</el-button>
                </template>
            </el-table-column>
            <el-table-column
                label="设置"
                width="150">
                <template slot-scope="scope">
                    <!--<i class="el-icon-time"></i>-->
                    <el-button type="danger" @click="addFrontHotKeyword(scope.row.keyword)">设为前台搜索热词</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import commonlist from '../../common/userCommon'
    import clearCacheTool from '../../common/clearCacheTool'

    export default {
        data: function () {
            return {
                searchState: '',
                userSearchKeyword: []
            }
        },
        components: {

        },
        watch: {

        },
        created() {

        },
        mounted() {
          this.loadUserSearchKeyword();
        },
        methods: {
            addFrontHotKeyword (keyword) {
                this.$http({
                    url: this.$api + '/admin/adminManagement/addHotKeyword',
                    method: 'post',
                    data: {
                        keyword: keyword
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.$message.success('添加成功!');
                        this.loadUserSearchKeyword();
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
            //按标题搜索列表
            handleSearchByTitle() {
                this.loadUserSearchKeyword()
            },
            // 加载近三个月数据
            loadUserSearchKeyword (search_day) {
                this.$http({
                    url: this.$api + '/admin/analysis/userSearchKeyword',
                    method: 'post',
                    data: {
                        keyword: this.searchState,
                        search_day: search_day
                    }
                }).then(res => {
                    if (res.data.errno == 0) {
                        this.userSearchKeyword = res.data.data;
                    } else {
                        this.$message.error(res.data.errmsg)
                    }
                }).catch(response => {

                })
            },
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
<style scoped>
    /*.crumbs {*/
    /*display: flex;*/
    /*justify-content: space-between;*/
    /*}*/
    .tagSwitch {
        width: 100%;
        height: auto;
    }

</style>
