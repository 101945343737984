<template>
    <div>
        <el-dialog :visible.sync="showEditForm" width="60%" :title="editMenuData.name" :before-close="dialogClose">
            <el-form :model="editMenuData" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="菜单类型" prop="menuType">
                    <!--:disabled="editMenuData.menuType!==item.value"-->
                    <el-radio v-model="editMenuData.menuType"
                              v-for="item in menuType" :label="item.value" :key="item.value" border>{{item.name}}
                    </el-radio>
                </el-form-item>

                <el-form-item label="菜单名称" prop="name">
                    <el-input v-model="editMenuData.name"></el-input>
                </el-form-item>
                <el-form-item  label="图标">
                    <i :class="editMenuData.icon"></i>
                    <el-input v-model="editMenuData.icon"></el-input>
                </el-form-item>
                <el-form-item label="菜单父ID" prop="parentId">
                    <el-input v-model="editMenuData.parentId" :disabled="editMenuData.menuType===1"></el-input>
                </el-form-item>
                <el-form-item label="页面地址URL">
                    <el-input v-model="editMenuData.url"></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                    <el-input v-model="editMenuData.sort"></el-input>
                </el-form-item>
                <el-form-item v-if="editMenuData.menuType===2"  label="keepAlive">
                    <el-radio v-model="editMenuData.keepAlive" v-for="item in keepAlive" :label="item.value"
                              :key="item.value" border>{{item.name}}
                    </el-radio>
                </el-form-item>
                <el-form-item label="状态" prop="isShow">
                    <el-radio v-model="editMenuData.isShow" v-for="item in isShows" :label="item.value"
                              :key="item.value" border>{{item.name}}
                    </el-radio>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="updateMenu('ruleForm')">更新菜单</el-button>
                    <el-button @click="resetForm('ruleForm')">重置菜单</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data() {
            return {
                showEditForm: false,
                editMenuData: [],
                menuTypeCN: '',
                isShow: '',
                keepAlive: [
                    {name: 'true', value: 1},
                    {name: 'false', value: 0}
                ],
                isShows: [
                    {name: '显示', value: 1},
                    {name: '隐藏', value: 0}
                ],
                menuType: [
                    {name: '目录', value: 1},
                    {name: '菜单', value: 2},
                    {name: '按钮', value: 3}
                ],
                rules: {
                    name: [
                        {required: true, message: '请输入菜单名称', trigger: 'blur'},
                        {min: 2, max: 10, message: '长度在 2 到 10 个字符', trigger: 'blur'}
                    ],
                    url: [
                        {required: true, message: '请填写菜单地址', trigger: 'change'}
                    ],
                    icon: [
                        {required: true, message: '请添加菜单图标', trigger: 'change'}
                    ],
                    pers: [
                        {required: true, message: '请输入权限字段', trigger: 'change'}
                    ],
                    menuTypeCN: [
                        {required: true, message: '请选择菜单类型', trigger: 'change'}
                    ],
                    sort: [
                        {required: true, message: '请填写菜单排序', trigger: 'change'}
                    ],
                    isShow: [
                        {required: true, message: '请选择菜单状态', trigger: 'blur'}
                    ],
                    id: [
                        {required: true, message: '请填写菜单id', trigger: 'blur'}
                    ]
                }
            }
        },
        props: ['tableData'],
        watch: {
            // 监听父组件传来的数据
            tableData: function () {
                // this.showEditForm = this.tableData.showEditForm
                // // //console.log(this.tableData)、
                // this.menuTypeCN = this.tableData.menuTypeCN
                // //console.log()
                // this.isShow = this.tableData.isShow
            }
        },
        created() {
            // this.getPaperList()
        },
        mounted() {
            bus.$on('editShow', msg => {
                this.showEditForm = true
                // //console.log(msg)
                this.editMenuData = msg
                // //console.log(this.editMenuData.menuType)
                this.menuTypeCN = this.editMenuData.menuTypeCN
                this.isShow = this.editMenuData.isShow
                for (var item in this.menuType) {
                    if (this.editMenuData.menuType === this.menuType[item].value) {
                        this.editMenuData.menuTypeCN = this.menuType[item].label
                    }
                }
            })
        },
        methods: {
            updateMenu() {
                //   debugger
                // for (var item in this.menuType) {
                //   if (this.editMenuData.menuTypeCN === this.menuType[item].label) {
                //      this.editMenuData.menuType = this.menuType[item].value
                //   }
                // }
                // this.editMenuData.isShow = this.isShow
                // this.editMenuData.isShow == '显示'? 1:this.editMenuData.isShow
                // this.editMenuData.isShow == '隐藏'? 2:this.editMenuData.isShow
                this.$http({
                    url: this.$api + '/admin/menu/update',
                    method: 'post',
                    data: this.$qs.stringify(this.editMenuData)
                }).then(response => {

                    if (response.data.errno === 0) {
                        this.$message.success('菜单更新成功！')
                        let update = true
                        bus.$emit('updateMenu', update);
                        this.dialogClose()
                    }
                })
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            editPaper() {
            },
            deleteMenu(row) {
                this.$confirm('您执行菜单删除操作，该操作不可恢复！', '警告', {
                    confirmButtenText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(msg => {
                    this.$http({
                        url: this.$api + '/chigua/admin/menu/delete',
                        method: 'post',
                        data: this.$qs.stringify({
                            id: row.id
                        })
                    }).then(response => {
                        if (response.data.errno === 0) {
                            this.$message.success('删除【' + row.name + '】菜单成功')
                            this.getMenuList()
                            // 从数据中删除已经删除的元素
                            // for (var item in this.editMenuData.children) {
                            //   if (this.showTableData.children[item].id === row.id) {
                            //     this.showTableData.children.splice(item,1)
                            //   }
                            // }
                            // this.dialogClose()
                        } else {
                            this.$message.error('菜单删除失败')
                        }
                    })
                }).catch(msg => {
                    this.$message.info('操作取消')
                })
            },
            getMenuList() {
                //   this.$http({
                //     url: this.$api +'/chigua/admin/menu/nav',
                //     method: 'get'
                // }).then(response => {
                //     if (response.data.errno === 0) {
                //       this.menuDataOffLine =  response.data.data
                //       // const menuData = JSON.stringify(this.menuDataOffLine)
                //       // localStorage.setItem('menuDataOffLine',menuData);
                //     } else {
                //       this.$message.error('菜单加载失败')
                //     }
                //   })
            },
            getPaperList() {
                // var menuDataOffLine = JSON.parse(localStorage.getItem('menuDataOffLine'))
                // this.menuDataOffLine = menuDataOffLine
                // this.getMenuList()
            },
            dialogClose(done) {
                // done()
                this.showEditForm = false
            }
        }
    }
</script>
<style>
    .a7Text {
        color: #a7a7a7;
    }

    .disflexbt {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    .disflexft {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
</style>>
