<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>345</label>
        </div>
        <div class="button-tool">
            <div class="disflex_around_center">
                <el-input placeholder="请输入商品名查询" v-model="search_goods_name" @keyup.enter.native="getPaperList(0, 10)">
                </el-input>

                <el-input placeholder="请输入用户昵称名查询" v-model="search_nickname" @keyup.enter.native="getPaperList(0, 10)">
                </el-input>

                <el-button type="success">
                    查询
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="getPaperList(0 , 10)"></i>
                </el-button>

            </div>
        </div>
        <el-table
            :data="tableData.data"
            style="width: 100%">
            <el-table-column type="expand">
                <template slot-scope="scope">
                    <el-form label-position="left" inline class="demo-table-expand">
                        <el-form-item>
                            <p :href="scope.row.primary_pic_url" target="_blank"><img :src="scope.row.primary_pic_url" style="width: 100px;vertical-align: middle;"></p>
                            <h3>商品名：{{ scope.row.name }}</h3>
                        </el-form-item>
                    </el-form>
                </template>
            </el-table-column>
            <el-table-column label="评价id" width="80" prop="id"></el-table-column>
            <el-table-column label="用户信息" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper text-align-center">
                        <span style="margin-left: 10px">{{ scope.row.nickname }}</span>
                        <p :href="scope.row.avatar" target="_blank"><img :src="scope.row.avatar" style="width: 40px;vertical-align: middle;"></p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="评价内容">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <p style="margin-left: 10px">{{ scope.row.content }}</p>
                        <a v-for="item in scope.row.gallery" :key="item" :href="item" target="_blank">
                            <img :src="item" style="width: 40px;vertical-align: middle;margin-left: 5px;">
                        </a>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="评价时间" width="100">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <p style="margin-left: 10px">{{ scope.row.add_time }}</p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="综合评分" width="180">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <p style="margin-left: 10px">
                            <span class="text-align-center">商品评分：</span>
                            <el-rate v-model="scope.row.star" disabled show-score>
                            </el-rate>
                        </p>
                        <p style="margin-left: 10px">
                            <span class="text-align-center">服务评分：</span>
                            <el-rate v-model="scope.row.service_star" disabled show-score></el-rate>
                        </p>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="50">
            <template slot-scope="scope">
                <div style="display: flex;justify-content: space-between;height:35px;align-items: flex-end;">
                    <i title="删除评价" class="el-icon-delete-solid ft25 pointer" style="color: #eb3021;" @click="deleteEvaluate(scope.row)"></i>
                </div>
            </template>
        </el-table-column>

        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="tableData.currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                tableData: [],
                search_goods_name: '',
                search_nickname: ''
            }
        },
        created() {
            this.getPaperList(1, 10)
        },
        mounted() {
        },
        methods: {
            // 删除评价
            deleteEvaluate (DATA) {
                this.$confirm('确定删除此评价吗？评价删除后将不可恢复！', '警示', {
                    confirmButtonText: '删除评价',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.post(this.$api + '/admin/operationManagement/deleteUserEvaluate', {
                        id: DATA.id
                    })
                        .then( res => {
                            if (res.data.errno === 0) {
                                this.$message.success('评价删除成功！！！');
                                this.getPaperList(1, 10)
                            } else {
                                this.$message.error('评价删除失败！');
                            }
                        })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '操作取消'
                    });
                });
            },
            handleSizeChange(val) {
                this.getPaperList(this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(val, this.size)
            },
            getPaperList( page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/operationManagement/userEvaluateList',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 20,
                        nickname: this.search_nickname,
                        goods_name: this.search_goods_name,
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        for (var item in response.data.data.data) {
                            response.data.data.data[item].retail_price_new = response.data.data.data[item].retail_price
                        }
                        this.tableData = response.data.data;
                        this.currentPage = response.data.data.currentPage;
                        this.total = response.data.data.totalPages;
                        this.size = response.data.data.pageSize;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                })
            }
        }
    }
</script>
<style>
    .ft25 {
        font-size: 25px;
    }

    .ft12 {
        font-size: 12px;
    }

    .red {
        color: red;
    }

    .pointer {
        cursor: pointer;
    }
</style>
