<template>
    <div v-if="heartChoiceData.showForm">

        <el-dialog :visible.sync="showFormfuck" width="80%" title="新增心选商品" :before-close="closeDialog">
            <div class="disflex_flex_start">
                <el-form :model="heartChoiceData"  ref="heartChoiceData" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="心选封面" required>
                        <upload-image v-model="heartChoiceData.primary_pic_url" ></upload-image>
                    </el-form-item>
                    <el-form-item label="标题" required>
                        <el-input
                            type="textarea"
                            :rows="3"
                            placeholder="请输入心选标题"
                            v-model="heartChoiceData.name">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="推荐人" required>
                        <el-input
                            type="text"
                            placeholder="请输入推荐人"
                            v-model="heartChoiceData.choice_by">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="立即上线" required>
                        <el-switch
                            v-model="heartChoiceData.is_on_sale"
                            active-color="#13ce66"
                            inactive-color="#ff4949">
                        </el-switch>
                    </el-form-item>
                    <el-form-item label="心选价格" required>
                        <span>{{heartChoiceData.retail_price}}</span>
                    </el-form-item>

                    <el-form-item label="销量" required>
                        <span>{{heartChoiceData.sell_volume}}</span>
                    </el-form-item>
                    <el-form-item label="商品id" required>
                        <span>{{heartChoiceData.goods_id}}</span>
                    </el-form-item>
                    <el-form-item label="【心选地址！】" required>
                        <el-input
                            placeholder="请选择商品后确认心选地址"
                            v-model="heartChoiceData.navigate_url">
                        </el-input>
                    </el-form-item>


                </el-form>
                <div  class="width50" style="margin-left: 10%;border: 1px solid #eee;">

                    <el-input placeholder="请输入商品名查询" v-model="searchStr" @keyup.enter.native="getPaperList(1, 15)"
                              style="width: 500px">
                        <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="getPaperList(1, 15)"></i>
                    </el-input>
                    <el-table
                        ref="singleTable"
                        :data="tableData.data"
                        highlight-current-row
                        @current-change="handelCurrentSelect"
                        style="width: 100%;cursor: pointer;">
                        <el-table-column
                            type="index"
                            width="50">
                        </el-table-column>
                        <el-table-column
                            property="date"
                            label="商品标题"
                            width="">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px">{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                            property="name"
                            label="商品价格"
                            width="120">
                            <template slot-scope="scope">
                                <span style="margin-left: 10px"> ￥ {{ scope.row.retail_price }}</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="paginaion" style="text-align: center;margin-top: 1em">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="tableData.currentPage"
                            :page-sizes="[10, 20, 30, 40]"
                            :page-size="tableData.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="tableData.count">
                        </el-pagination>
                    </div>
                </div>

                <div style="text-align: right;">
                    <el-button type="success" round @click="submitHeartChoice">发布</el-button>
                </div>

            </div>
        </el-dialog>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import uploadImage from '../../common/uploadImage';

    export default {
        data() {
            return {
                showFormfuck: true,
                media_type_arr:[
                    {name: '商品', value: 1},
                    {name: '分类', value: 2}
                ],
                tableData: {},
                searchStr: '',
                heartChoiceData: {
                    primary_pic_url: '',
                    showForm: false,
                    media_type: 1,
                    name: ''
                },
            }
        },
        components: {
            uploadImage
        },
        computed: {
        },
        watch: {

        },
        created() {

        },
        mounted() {
            let that = this
            bus.$on('showAddDialog', function (data) {
                that.showFormfuck=true
                that.heartChoiceData.showForm = true
            });
            this.getPaperList();
        },
        methods: {
            handleSizeChange(val) {
                this.getPaperList(this.tableData.currentPage, val)
            },
            handleCurrentChange(val) {
                this.getPaperList(val, this.tableData.pageSize)
            },
            handelCurrentSelect (val) {
                this.heartChoiceData.name = val.name;
                this.heartChoiceData.goods_id = val.id;
                this.heartChoiceData.sell_volume = val.sell_volume;
                this.heartChoiceData.retail_price = val.retail_price;
                this.heartChoiceData.primary_pic_url = val.list_pic_url;
                this.heartChoiceData.navigate_url = '/pages/goods/goods?id=' + val.id;
            },
            submitHeartChoice () {
                if (!this.heartChoiceData.navigate_url) {
                    this.$message.error('心选地址不能为空！');
                    return
                }
                if (!this.heartChoiceData.choice_by) {
                    this.$message.error('推荐人不能为空！');
                    return
                }
                this.$http({
                    url: this.$api + '/admin/goods/addHeartChoice',
                    method: 'post',
                    data: this.heartChoiceData
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('新增心选成功！注意！！！该操作在更新缓存后生效！');
                        this.heartChoiceData.showForm = false
                        bus.$emit('reloadHeartChoice')
                    }
                })
            },
            closeDialog (done) {
                done()
            },
            getPaperList(page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                this.$http({
                    url: this.$api + '/admin/goods/index',
                    method: 'post',
                    data: this.$qs.stringify({
                        page: page ? page : 1,
                        size: length ? length : 15,
                        name: this.searchStr
                    })
                }).then(response => {
                    // this.loading = false
                    if (response.data.data != null) {
                        this.tableData = response.data.data;
                    } else {
                        this.$message.error(response.data.msg)
                    }
                    loading.close()
                }).catch(e => {
                    loading.close()
                })
            },
        },

    }
</script>
<style>
    .uploadToolBox {
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
</style>
