exports.default = [
    {
        ShipperCode: 'ZTO',
        ShipperName: '中通快递'
    },
    {
        ShipperCode: 'STO',
        ShipperName: '申通快递'
    },
    {
        ShipperCode: 'SF',
        ShipperName: '顺丰快递'
    },
    {
        ShipperCode: 'DBKD',
        ShipperName: '德邦快递'
    },
    {
        ShipperCode: 'YTO',
        ShipperName: '圆通快递'
    },
    {
        ShipperCode: 'YZPY',
        ShipperName: '邮政快递包裹'
    },
    {
        ShipperCode: 'EMS',
        ShipperName: 'EMS'
    },
    {
        ShipperCode: 'JD',
        ShipperName: '京东快递'
    },
    {
        ShipperCode: 'YD',
        ShipperName: '韵达速递'
    },
    {
        ShipperCode: 'HHTT',
        ShipperName: '天天快递'
    },
    {
        ShipperCode: 'UC',
        ShipperName: '优速快递'
    },
    {
        ShipperCode: 'HTKY',
        ShipperName: '百世快递'
    },
    {
        ShipperCode: 'ZJS',
        ShipperName: '宅急送'
    }
]
