<template>
<div>
  <el-dialog :visible.sync="showTable" width="80%" :title="showTableData.name" :before-close="dialogClose">
     <!-- :title="'文章评论(共'+commons.total+'条)'" -->
        <div class="disflexft">
          <el-table
            :data="showTableData.children"
            style="width: 100%">
             <el-table-column
              label="父ID"
              width="80"
              >
              <template slot-scope="scope">
                <!-- <el-popover trigger="hover" placement="top"> -->
                  <!-- <p>姓名: {{ scope.row.name }}</p> -->
                  <!-- <p>住址: {{ scope.row.address }}</p> -->
                  <div slot="reference" class="name-wrapper">
                    <span style="margin-left: 10px">{{ scope.row.id }}</span>
                  </div>
                <!-- </el-popover> -->
              </template>
            </el-table-column>
            <el-table-column
              label="图标"
              width="100">
              <template slot-scope="scope">
                <span style="margin-left: 10px"><i :class="scope.row.icon" class="ft25"></i></span>
              </template>
            </el-table-column>
            <el-table-column
              label="菜单名称"
              width="100">
              <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <span size="medium">{{ scope.row.name }}</span>
                  </div>

              </template>
            </el-table-column>
            <el-table-column
              label="权限字段"
              width="100">
              <template slot-scope="scope">
                <!-- <i class="el-icon-time"></i> -->
                <span style="margin-left: 10px">{{ scope.row.perms }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="访问路径"
              >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.url }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="排序字段"
              width="100">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.sort }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="状态"
              width="80">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.isShow === 1? '显示':'不显示' }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="类型"
              width="80">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.menuTypeCN }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="150px" >
              <template slot-scope="scope" >
                 <div style="display: flex;justify-content: space-around;height:35px;
     align-items: flex-end;">
                    <!-- 删除 -->
                    <i title="删除" class="el-icon-delete ft25 red pointer" @click="deleteMenu(scope.row)"></i>
                    <!-- 编辑 -->
                    <i title="编辑" class="el-icon-edit-outline ft25 pointer" @click="editMenu(scope.row)"></i>
                    <!-- 有二级菜单 -->
                    <span style="display: block;width: 25px;height: 25px;">
                    <!-- <i v-if="scope.row.children.length > 0? true:false" title="展开二级菜单" class="el-icon-arrow-down  ft25 pointer" @click="showSecondTable(scope.row.children)"></i> -->
                    </span>
                 </div>
              </template>
            </el-table-column>

          </el-table>
    </div>
  </el-dialog>
  </div>
</template>

<script>
    import bus from '../../common/bus';
    export default {
        data(){
            return {
                showTable: false,
                showTableData: [],
                menuType: [
                {label: '目录', value: 1},
                {label: '菜单', value: 2},
                {label: '按钮', value: 3}
                ],
            }
        },
        props: ['tableData'],
        watch:{
            // 监听父组件传来的数据
            tableData: function () {
                this.showTable = this.tableData.showTable
            }
        },
        created() {
           this.getPaperList()
        },
        mounted(){
          bus.$on('tableShow',msg => {
            this.showTable = true
            this.showTableData = msg
            for (var item in this.showTableData.children) {
              for (var second in this.menuType) {
                if (this.showTableData.children[item].menuType === this.menuType[second].value) {
                  this.showTableData.children[item].menuTypeCN = this.menuType[second].label
                }
              }
            }
          })
        },
        methods:{
          editMenu (row) {
            bus.$emit('editShow',row)
          },
          editPaper () {
          },
          deleteMenu (row) {
            this.$confirm('您执行菜单删除操作，该操作不可恢复！','警告',{
              confirmButtenText: '删除',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(msg => {
              this.$http({
                url: this.$api +'/chigua/admin/menu/delete',
                method: 'post',
                data: this.$qs.stringify({
                  id: row.id
                })
            }).then(response => {
              if (response.data.errno === 0) {
                this.$message.success('删除【'+row.name+'】菜单成功')
                this.getMenuList ()
                // 触发同步更新菜单内容
                let update = true
                bus.$emit('updateMenu',update)
                // 从数据中删除已经删除的元素
                for (var item in this.showTableData.children) {
                  if (this.showTableData.children[item].id === row.id) {
                    this.showTableData.children.splice(item,1)
                  }
                }
                // this.dialogClose()
              } else {
                this.$message.error('菜单删除失败')
              }
            })
            }).catch(msg => {
              this.$message.info('操作取消')
            })
          },
          getMenuList () {
            this.$http({
              url: this.$api +'/chigua/admin/menu/nav',
              method: 'get'
          }).then(response => {
              if (response.data.errno === 0) {
                this.menuDataOffLine =  response.data.data
                for (var item in this.menuDataOffLine) {
                  for (var second in this.menuType) {
                    if (this.menuDataOffLine[item].menuType === this.menuType[second].value) {
                      this.menuDataOffLine[item].menuType = this.menuType[second].label
                    }
                  }
                }
                // const menuData = JSON.stringify(this.menuDataOffLine)
                // localStorage.setItem('menuDataOffLine',menuData);
              } else {
                this.$message.error('菜单加载失败')
              }
            })
          },
          getPaperList () {
              // var menuDataOffLine = JSON.parse(localStorage.getItem('menuDataOffLine'))
              // this.menuDataOffLine = menuDataOffLine
              this.getMenuList()
          },
          dialogClose(done) {
            done()
          }
        }
    }
</script>
<style>
    .a7Text{
        color: #a7a7a7;
    }
    .disflexbt{
        display: flex;
        justify-content:space-between;
        align-items: baseline;
    }
    .disflexft{
        display: flex;
        justify-content:flex-start;
        align-items: center;
    }
</style>>
