/**
 *  util工具箱
 *
 * **/

function sortByTime(arr, timeString = "time", type = "desc") {
    if (arr.constructor === Array) {
        if (type.toLowerCase() === "desc") {
            arr.sort(function(a, b) {
                return new Date(a[timeString]) > new Date(b[timeString]) ? -1 : 1;
            });
        } else {
            arr.sort(function(a, b) {
                return new Date(a[timeString]) > new Date(b[timeString]) ? 1 : -1;
            });
        }
    }
    return arr;
}
// private method for UTF-8 encoding
let _utf8_encode = function (string) {
    string = string.replace(/\r\n/g,"\n");
    var utftext = "";
    for (var n = 0; n < string.length; n++) {
        var c = string.charCodeAt(n);
        if (c < 128) {
            utftext += String.fromCharCode(c);
        } else if((c > 127) && (c < 2048)) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
        } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
        }

    }
    return utftext;
}
let base64encode = function (input) {
    let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;
    input = _utf8_encode(input);
    while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
}
// 返回 true ／ false
function base64_encode (input) {
    if (Number(input)) {
        input = input.toString()
    }
    let _keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
    var output = "";
    var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
    var i = 0;
    input = _utf8_encode(input);
    while (i < input.length) {
        chr1 = input.charCodeAt(i++);
        chr2 = input.charCodeAt(i++);
        chr3 = input.charCodeAt(i++);
        enc1 = chr1 >> 2;
        enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
        enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
        enc4 = chr3 & 63;
        if (isNaN(chr2)) {
            enc3 = enc4 = 64;
        } else if (isNaN(chr3)) {
            enc4 = 64;
        }
        output = output +
            _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
            _keyStr.charAt(enc3) + _keyStr.charAt(enc4);
    }
    return output;
}

function getOrderStatusTxt(statusCode){
    let orderStatusTxt = "";
    switch (statusCode) {
        case 0:
            orderStatusTxt = "未付款";
            break;
        case 100:
            orderStatusTxt = "已关闭";
            break;
        case 101:
            orderStatusTxt = "已取消";
            break;
        case 201:
            orderStatusTxt = "付款成功（未发货）";
            break;
        case 300:
            orderStatusTxt = "已发货";
            break;
        case 301:
            orderStatusTxt = "已签收";
            break;
        case 302:
            orderStatusTxt = "交易成功";
            break;
        case 303:
            orderStatusTxt = "已评价";
            break;
        case 401:
            orderStatusTxt = "用户申请退款";
            break;
        case 402:
            orderStatusTxt = "退款成功";
            break;
        case 40201:
            orderStatusTxt = "退款处理中";
            break;
        default:
            orderStatusTxt = "未知状态，联系老麦克修改";
            break;
    }
    return orderStatusTxt;
}


/*
* 13位时间戳转换为yy-MM-dd mm:ss
* */
function getDateTimeStr(timeStamp){
    let date = new Date(timeStamp*1);
    let Y = date.getFullYear() + '-';
    let M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
    let D = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate()) + ' ';
    let h = (date.getHours() < 10 ? '0'+(date.getHours()) : date.getHours()) + ':';
    let m = (date.getMinutes() < 10 ? '0'+(date.getMinutes()) : date.getMinutes());
    // let s = date.getSeconds();
    return (Y+M+D+h+m); //
}


const util = {
    sortByTime,
    base64_encode,
    getOrderStatusTxt,
    getDateTimeStr
};


export default util;
