<template>
    <div>
        <div class="crumbs" style="width: 100%;">
            <label>
                <span>{{ $route.meta.title }}列表</span>（共{{ total }}条）</label>
            <el-input style="width:30%" placeholder="请输入用户名或专栏名称查询" v-model="searchState" @keyup.enter.native="handleSearchByTitle">
                <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
            </el-input>
        </div>

        <div class="tagSwitch">
            <el-tabs v-model="activeName" @tab-click="switchTag">
                <el-tab-pane v-for="item in tagSwitchData" :label="item.name" :key="item.id" :name="item.name"></el-tab-pane>
            </el-tabs>
        </div>
        <el-table :data="tableData"  style="width: 100%">
            <el-table-column label="申请用户">
                <template slot-scope="scope">
                    <div slot="reference" class="name-wrapper">
                        <img :src="scope.row.heads" style="width: 40px;vertical-align: middle;">
                        <span style="margin-left: 10px">{{ scope.row.nickname}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="专栏名称" width="150">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.name === null? 0:scope.row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column label="专栏封面" width="150">
                <template slot-scope="scope">
                    <img :src="scope.row.picture" style="width: 40px;vertical-align: middle;">
                </template>
            </el-table-column>
            <el-table-column label="备注" width="150">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.remark === null? 0:scope.row.remark }}</span>
                </template>
            </el-table-column>
            <el-table-column label="申请时间" width="150">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ formatStrTime(scope.row.createTime,16) }}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="activeName=='拒绝'" label="拒绝理由" width="150">
                <template slot-scope="scope">
                    <span style="margin-left: 10px">{{ scope.row.refuseReason === null? 0:scope.row.refuseReason }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="180px">
                <template slot-scope="scope">
                    <div style="display: flex;justify-content: space-around;height:35px;
     align-items: flex-end;">
                        <!-- 批准 -->
                        <i title="批准" v-show="activeName=='未审核'" class="el-icon-circle-check-outline ft25 pointer" @click="approveColumn(scope.row)"></i>
                        <!-- 拒绝申请 -->
                        <i title="拒绝申请" v-show="activeName=='未审核'" class="el-icon-circle-close-outline ft25 pointer" @click="rejectColumn(scope.row)"></i>
                        <!-- 删除 -->
                        <i title="删除" class="el-icon-delete ft25 red pointer" @click="deleteColumn(scope.row)"></i>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="tableData.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="tableData.count">
            </el-pagination>
        </div>
        <commonlist :commons="commonData"></commonlist>
        <el-dialog title="请填写理由" :visible.sync="uppaper" width="50%">
            <el-form ref="form" :model="offLineData.form">
                <el-form-item label="请选择理由" prop="desc">
                    <el-input type="textarea" size="medium" v-model="offLineData.form.desc"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="float:right" @click="onSubmit">发送</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
import bus from "../../common/bus";
import commonlist from "../../common/userCommon";
import { Loading } from "element-ui";

export default {
  data: function() {
    return {
      activeName: "未审核",
      tableData: [],
      tagSwitchData: [],
      currentPage: 1,
      total: 0,
      size: 0,
      refuseReason: "",
      currentTagId: "",
      paperId: "",
      searchState: "",
      showCommon: false,
      commonData: "",
      tableHeight: 600,
      uppaper: false,
      offLineData: {
        id: "",
        form: {
          desc: ""
        }
      }
    };
  },
  components: {
    commonlist
  },
  watch: {},
  created() {
    this.tagSwitchData = [
      { id: 1, name: "未审核" },
      { id: 2, name: "已审核" },
      { id: 3, name: "拒绝" }
    ];
    this.tableHeight = `${document.documentElement.clientHeight}` * 0.653;
    this.getPaperList(1, 1, 10);
  },
  mounted() {
    // 更新列表数据
    bus.$on("reloadVideo", reload => {
      if (reload) {
        this.getPaperList(this.currentTagId, this.currentPage, this.size);
      }
    });
  },
  methods: {
    // 弹出原因框
    rejectColumn(row) {
      this.uppaper = true;
      this.offLineData.id = row.id;
    },
     // 批准
    approveColumn(row) {
      this.$http({
        url: this.$api + "/api/column/info/check",
        method: "post",
        data: this.$qs.stringify({ id: row.id })
      }).then(response => {
        if (response.status === 200) {
          if (response.data.errno === 0) {
            this.$message.success("批准成功！");
            this.getPaperList(this.currentTagId, this.currentPage, this.size);
          }
        }
      });
    },
    //   拒绝
    onSubmit(row) {
      if (this.offLineData.form.desc == "") {
        this.$message.error("请填写拒绝理由");
      } else {
        this.$http({
          url: this.$api + "/api/column/info/refuse",
          method: "post",
          data: this.$qs.stringify({
            id: this.offLineData.id,
            refuseReason: this.offLineData.form.desc
          })
        }).then(response => {
          if (response.status === 200) {
            if (response.data.errno === 0) {
              this.$message.success("已拒绝！");
              this.getPaperList(this.currentTagId, this.currentPage, this.size);
              this.uppaper = false;
            }
          }
        });
      }
    },
    // 删除专栏
    deleteColumn(row) {
      this.$confirm("您正在进行【删除】操作，确认？", "警示", {
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$http({
            url: this.$api + "/api/column/info/delete",
            method: "post",
            data: this.$qs.stringify({ ids: row.id })
          }).then(response => {
            if (response.status === 200) {
              if (response.data.errno === 0) {
                this.$message.success("已删除！");
                this.getPaperList(
                  this.currentTagId,
                  this.currentPage,
                  this.size
                );
              }
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "操作取消"
          });
        });
    },
    handleSearchByTitle() {
      this.getPaperList(this.currentTagId, this.currentPage, this.size);
    },
    handleSizeChange(val) {
      this.getPaperList(this.currentTagId, this.currentPage, val);
    },
    handleCurrentChange(val) {
      this.getPaperList(this.currentTagId, val, this.size);
    },
    getPaperList(type, page, length) {
      const loading = this.$loading({
        lock: true,
        text: "已经很努力加载啦~",
        spinner: "el-icon-loading",
        background: "rgba(183, 183, 183, 0.57)"
      });
      if ((page === undefined) | (page === null)) {
        page = 1;
      } else if ((length === undefined) | (length === null)) {
        length = this.size;
      }
      this.$http({
        url: this.$api + "/api/column/info/page/check",
        method: "post",
        data: this.$qs.stringify({
          checkStatus: type,
          title: this.searchState,
          page: page,
          limit: length
        })
      }).then(response => {
        if (response.data.data != null) {
          for (var item in response.data.data.records) {
            if (response.data.data.records[item].status === 1) {
              response.data.data.records[item].paperStatus = "可上线";
              // response.data.data.records[item].color = '#e3e3e3'
            } else if (response.data.data.records[item].status === 2) {
              response.data.data.records[item].paperStatus = "已上线";
              // response.data.data.records[item].color = '#49293'
            } else if (response.data.data.records[item].status === 3) {
              response.data.data.records[item].paperStatus = "已下线";
              // response.data.data.records[item].color = '#ff0000'
            } else {
              response.data.data.records[item].paperStatus = "未知状态";
            }
          }
          this.tableData = response.data.data.records;
          this.currentPage = response.data.data.current;
          this.total = response.data.data.total;
          this.size = response.data.data.size;
          loading.close();
        }
      });
    },
    switchTag(tab) {
      for (var i in this.tagSwitchData) {
        if (tab.name === this.tagSwitchData[i].name) {
          this.searchState = "";
          this.getPaperList(this.tagSwitchData[i].id, 1, 10);
          this.currentTagId = this.tagSwitchData[i].id;
        }
      }
    }
  }
};
</script>
<style>
.crumbs {
  display: flex;
  justify-content: space-between;
}

.ft25 {
  font-size: 25px;
}

.ft12 {
  font-size: 12px;
}

.red {
  color: red;
}

.pointer {
  cursor: pointer;
}
</style>
<style scoped><
.tagSwitch {
    width: 100%;
    height: auto;
}

/
style >
