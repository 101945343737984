<template>
    <div>
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>返佣管理</el-breadcrumb-item>
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-button type="primary" @click="$router.push({path:'/addCommissionTemplate'})">新增返佣模板</el-button>

        <div style="padding: 10px">
            <el-table
                :data="teamCommissionList.data"
                style="width: 100%">

                <el-table-column label="头像" width="100">
                    <template slot-scope="scope">
                        <div slot="reference" class="name-wrapper">
                            <img :src="scope.row.group_avatar" style="width: 40px;vertical-align: middle;">
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="公号用户id" prop="belong_user_id" width="80"></el-table-column>
                <el-table-column label="真实姓名" prop="belong_user_name" width="150"></el-table-column>
                <el-table-column label="微信号" width="120" >
                    <template slot-scope="scope">
                        <span>{{scope.row.wechat}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="手机号" prop="mobile" width="120"></el-table-column>

                <el-table-column label="审核状态" width="100">
                    <template slot-scope="scope">
                        <span>{{scope.row.is_permit_str}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="使用的模板" >
                    <template slot-scope="scope" >
                        <div v-if="scope.row.template_id" class="underline cursor_pointer color_09bb07" @click="$router.push('/commissionTemplateDetail')">
                            <p >{{scope.row.template_name}}</p>
                            <p>模板id: {{scope.row.template_id}}</p>
                            <p>模板简介: {{scope.row.template_desc}}</p>
                        </div>
                        <div class="color_eb3021" v-else>未配置返佣模板</div>
                    </template>
                </el-table-column>

                <el-table-column label="提交申请时间" width="150">
                    <template slot-scope="scope">
                        <span>{{ scope.row.add_time }}</span>
                    </template>
                </el-table-column>


                <el-table-column label="操作" >
                    <template slot-scope="scope">
                        <div class="disflex_between_center">
                            <el-button type="primary" @click="$router.push(`/setTeamUserCommission?service_id=${scope.row.belong_user_id}&template_id=${scope.row.template_id}`)">
                                配置返佣
                            </el-button>
                            <el-button type="danger" @click="resetTeamUserGoodsList(scope.row.belong_user_id)">
                                清空返佣商品
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import bus from '../../common/bus';
    import util from '../../../utils/util'

    export default {
        data: function () {
            return {
                teamCommissionList: [],
            }
        },
        components: {
        },
        methods: {
            // 重置商品数据
            resetTeamUserGoodsList (service_id) {
                this.$http({
                    url: this.$api + '/admin/teamCommission/resetTeamUserGoodsList',
                    method: 'post',
                    data: {
                        service_id: service_id
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('清空用户模板商品成功，用户将不能再享受返佣！')
                        this.getTeamCommissionList();
                    }
                })
            },
            //获取优惠券列表
            getTeamCommissionList(){
                this.$http({
                    url: this.$api + '/admin/teamCommission/teamCommissionList',
                    method: 'post',
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.teamCommissionList = response.data.data;
                    }
                })
            },
        },
        created(){

        },
        mounted() {
            this.getTeamCommissionList();
        },
    }
</script>

<style lang="scss" scoped>
    .single_food{
        display: flex;
        align-items: center;
        margin: 2px 0;
        padding: 10px;
        border-radius: 4px;
        &.thead{
            font-weight: bold;
            font-size: 15px;
            color: #000;
            &:hover{
                background-color: #f6faff;
            }
        }
        &:hover{
            background-color: #fff;
        }
        .single_data{
            /*flex: 1;*/
            width: 150px;
            display: flex;
            align-items: center;
            justify-content: center;
            &.front_desc{
                /*flex: 1;*/
                width: 300px;
                justify-content: left;
            }
            .food_picture{
                width: 80px;
                height: 80px;
                border-radius: 4px;
            }
        }
    }

    .pointer{
        cursor: pointer;
    }

</style>
