<template>
    <div>
        <div class="crumbs">
            <label><span>{{ $route.meta.title }}</span>（共{{ total }}篇）</label>
            <!--<el-breadcrumb separator="/">-->
                <!--<el-breadcrumb-item><i class="el-icon-date"></i>广告管理</el-breadcrumb-item>-->
                <!--<el-breadcrumb-item>草稿箱</el-breadcrumb-item>-->
            <!--</el-breadcrumb>-->
        </div>
        <!--<div class="tagSwitch search-title" style="margin-bottom: 0.5em;text-align: right">-->
            <!--<el-input placeholder="请输入内容" v-model="searchState">-->
                <!--<i class="el-icon-search el-input__icon pointer" slot="suffix" @click="handleIconClick"></i>-->
            <!--</el-input>-->
        <!--</div>-->
        <el-table
            :data="tableData.data"
            style="width: 100%">
             <el-table-column
              label="标题"
              >
              <template slot-scope="scope">
                <!-- <el-popover trigger="hover" placement="top"> -->
                  <!-- <p>姓名: {{ scope.row.name }}</p> -->
                  <!-- <p>住址: {{ scope.row.address }}</p> -->
                  <div slot="reference" class="name-wrapper">
                    <img :src="scope.row.image_url" style="width: 40px;vertical-align: middle;">
                    <span style="margin-left: 10px">{{ scope.row.name }}</span>
                  </div>
                <!-- </el-popover> -->
              </template>
            </el-table-column>
<!--             <el-table-column
              label="作者"
              width="80">
              <template slot-scope="scope">
                  <div slot="reference" class="name-wrapper">
                    <el-tag size="medium">{{ scope.row.author }}</el-tag>
                  </div>

              </template>
            </el-table-column> -->
            <el-table-column
              :label="timeDiff"
              width="200">
              <template slot-scope="scope">
                <!--<div style="margin-left: 10px"><i class="el-icon-time" v-if="scope.row.startTime"></i>{{ scope.row.startTime }}</div>-->
                <div style="margin-left: 10px"><i class="el-icon-time"  ></i>{{ scope.row.end_time }}</div>
              </template>
            </el-table-column>

            <el-table-column label="操作" width="100px" >
              <template slot-scope="scope" >
                 <div style="display: flex;justify-content: space-around;height:35px;align-items: flex-end;">
                  <!-- 编辑 -->
                  <i title="编辑" class="el-icon-edit-outline ft25 pointer" @click="editPaper(scope.row)"></i>

                  <!-- 评论 -->
                  <!-- 无评论 -->
<!--                   <a class="pointer" v-if="showCommentButtonText">评论</a>
                  <!-- 有评论 -->
              <!--     <div  @click="loadCommonList(scope.row)" v-if="scope.row.commentNum > 0? true:false">
                    <el-badge  :value="scope.row.commentNum" :max="99" class="item pointer">
                    <a class="pointer">评论</a>
                  </el-badge>
                  </div> -->
                  <!-- <el-popover trigger="hover" placement="top">
                    <p @click="handleEdit(scope.$index, scope.row)">{{ scope.row.commentNum}}</p>
                    <div slot="reference" class="name-wrapper">
                      <i class="el-icon-edit ft25"></i>
                    </div>
                </el-popover> -->

                <!-- 上下线 -->
                <i class="ft25 operateEvent pointer el-icon-upload2" title="上线发布" @click="operatePaper(scope.$index, scope.row)" :name="scope.row.status"></i>
                <!-- 删除 -->
                <i title="删除" class="el-icon-delete ft25 red pointer" @click="deleteBana(scope.row.id)"></i>
                 </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="tableData.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableData.count">
            </el-pagination>
          </div>
          <!-- <commonlist :commons="commonData"></commonlist> -->
        <editAds :adsEditData="adsEditData" @reloadDraf="getPaperList(1,'',1,10)"></editAds>

    </div>
</template>

<script>
    // import commonlist from '../common/userCommon'
    import editAds from './editnianhuo'
    import bus from '../../common/bus'
    export default {
        data: function(){
            return {
                searchState: '',
                searchData: [],
                activeName: '未上线',
                tableData: [],
                tagSwitchData:[],
                currentPage: 1,
                total: 0,
                size: 0,
                currentTagId: 1,
                paperStatus: '未上线',
                paperId: '',
                showCommon: false,
                commonData: '',
                timeDiff: '保存时间',
                showCommentButtonText: false,
                adsaddStatus: false,
                adsEditData: {}
            }
        },
        watch: {
            currentTagId: function () {
                if (this.currentTagId === 1) {
                    this.showCommentButtonText = false
                } else if (this.currentTagId === 3)  {
                    this.showCommentButtonText = true
                }
            }
        },
        components: {
          editAds
        },
        created() {
           this.getPaperList(1,'',1,10)
           this.tagSwitchData = [{id: 1,name: "未上线"},{id: 3,name: "已下线"}]
        },
        mounted() {
            bus.$on('reloadAdsDraft',msg => {
              this.getPaperList(1,'',1,10)
            })
        },
        methods: {
            // 跳转到编辑页面，巴士负责传递id
            editPaper(row) {
                this.adsEditData = row;
                this.adsEditData.showForm = true;
                bus.$emit('showEditDialog')
            },
            // 删除广告
            deleteBana(val) {
                this.$confirm(
                    '您正在执行删除轮播广告操作，删除后首页将不会显示该条轮播广告', '提示', {
                        confirmButtonText: '删除',
                        cancelButtonText: '取消'
                    }
                ).then(() => {
                    this.$http({
                        url: this.$api + '/admin/ads/delete',
                        method: 'post',
                        data: this.$qs.stringify({adsId: val})
                    }).then(res => {
                        this.$message.success('删除广告成功');
                        this.getPaperList()
                        //
                    }).catch(res => {
                        this.$message.info('操作取消！');
                    })
                })
            },
            operatePaper(index,row) {
              this.$confirm('您正在进行【上线广告】操作，确认？', '警示', {
                confirmButtonText: '上线',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.$http({
                  url: this.$api + '/admin/ads/switch',
                  method: 'post',
                  data: this.$qs.stringify({
                      adsId: row.id,
                      enabled: 1
                  })
                }).then(response => {
                  if (response.status === 200) {
                    if (response.data.errno === 0) {
                      this.$message.success('广告上线成功！')
                      this.getPaperList (this.currentTagId,this.searchState,this.currentPage,this.size)
                      let reload = true
                      bus.$emit('reloadAdsList',reload)
                    }
                  } else {
                    this.$success.error(response.data.msg)
                  }
                })
                }).catch((e) => {

                this.$message({
                  type: 'info',
                  message: e
                });
              });
            },
            handleSizeChange(val) {
              this.getPaperList (this.currentTagId,this.searchState,this.currentPage,val)
            },
            handleCurrentChange(val) {
              this.getPaperList (this.currentTagId,this.searchState,val,this.size)
            },
            getPaperList (type,title,page,length) {
              const loading = this.$loading({
                lock: true,
                text: '已经很努力加载啦~',
                spinner: 'el-icon-loading',
                background: 'rgba(183, 183, 183, 0.57)'
              });
              if (page === undefined | page === null) {
                page = 1
              } else if (length === undefined | length === null){
                length = this.size
              }
               loading.close()
              this.$http({
                  url: this.$api + '/admin/ads/all',
                  method: 'post',
                  data: this.$qs.stringify({
                    status: type,
                    title: this.searchState,
                    page: page,
                    limit: length
                  })
              }).then(response => {
                  if (response.data.data != null) {

                      this.tableData = response.data.data
                      this.currentPage = response.data.currentPage
                      this.total = response.data.data.totalPages
                      this.size = response.data.data.pageSize
                      loading.close()
                  }
              })
            },
            switchTag(tab) {
              for (var i in this.tagSwitchData) {
                if (tab.name === this.tagSwitchData[i].name) {
                  this.getPaperList (this.tagSwitchData[i].id,this.searchState,1,10)
                  this.currentTagId = this.tagSwitchData[i].id
                  if (this.currentTagId === 1) {
                    this.timeDiff = '保存时间'
                  } else {
                    this.timeDiff = '发布时间'
                  }
                }
              }
            },
        },
        mounted() {
            // //console.log(this.currentTagId)
        }
    }
</script>
<style>
  .ft25{
    font-size: 25px;
  }
  .ft12{
    font-size: 12px;
  }
  .red{
    color: red;
  }
  .pointer{
    cursor: pointer;
  }
</style>
<style scoped>
    .tagSwitch{
        width: 100%;
        height: auto;
    }
    .disflexct{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 40%;
        margin-bottom: 1em
    }
    .my-autocomplete {
      li {
        line-height: normal;
        padding: 7px;

        .name {
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .addr {
          font-size: 12px;
          color: #b4b4b4;
        }

        .highlighted .addr {
          color: #ddd;
        }
      }
    }
</style>
