<template>
    <div class="mike_con">

        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="form_con">

            <!--基本信息-->
            <div class="mike_part left">
                <el-alert title="基本信息" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--优惠券名称-->
                <el-form-item label="优惠券名称" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>

                <!--优惠券描述-->
                <el-form-item label="优惠券描述" prop="name_desc">
                    <el-input v-model="ruleForm.name_desc"></el-input>
                </el-form-item>

                <!--满减门槛-->
                <el-form-item label="满减门槛" prop="limit_amount">
                    <el-input v-model="ruleForm.limit_amount" :disabled="true" placeholder="需要达到多少金额满减"></el-input>
                </el-form-item>

                <!--优惠金额-->
                <el-form-item label="优惠金额" prop="preferential_cash">
                    <el-input v-model="ruleForm.preferential_cash" :disabled="true" placeholder="优惠券金额"></el-input>
                </el-form-item>

                <!--用户支付订单时限制使用的优惠券数量-->
                <el-form-item label="支付时可用券数限制" prop="limit_pay_num">
                    <el-input v-model="ruleForm.limit_pay_num" placeholder="用户支付订单时限制使用的优惠券数量"></el-input>
                </el-form-item>

                <el-form-item label="优惠券发放时间">
                    <div>{{publish_date}}</div>
                </el-form-item>

                <el-form-item label="优惠券有效时间">
                    <div>{{active_date}}</div>
                </el-form-item>
            </div>




            <!--限制条件-->
            <div class="mike_part left">
                <el-alert title="限制条件" type="info" :closable="false" class="coupon_title"></el-alert>

                <!--&lt;!&ndash;总共发放多少张优惠券  默认1000张&ndash;&gt;-->
                <!--<el-form-item label="是否会员专属" prop="limit_publish_num">-->
                    <!--<el-switch v-model="ruleForm.is_belong_vip" active-color="#13ce66" inactive-color="#ccc">-->
                    <!--</el-switch>-->
                <!--</el-form-item>-->

                <!--用户可领取优惠券数量-->
                <el-form-item label="用户可领数量(0为不限次数)" prop="limit_get_num">
                    <el-input v-model="ruleForm.limit_get_num" placeholder="用户可领该优惠券的次数"></el-input>
                </el-form-item>

                <!--总共发放多少张优惠券  默认1000张-->
                <el-form-item label="发放总数" prop="limit_publish_num">
                    <el-input v-model="ruleForm.limit_publish_num" placeholder="总共发放多少张优惠券  默认1000张"></el-input>
                </el-form-item>

                <!--满减的商品数，例如买三件享受满减优惠券-->
                <el-form-item label="订单商品数量达到多少件可用" prop="limit_number">
                    <el-input v-model="ruleForm.limit_number" :disabled="true" placeholder="满减的商品数，例如买三件享受满减优惠券"></el-input>
                </el-form-item>

                <!--指定分类可以使用的券，例如猫粮专用券，id字符串，多个分类使用逗号分隔-->
                <el-form-item label="指定商品类型专用" prop="fit_category_ids">
                    <el-input v-model="ruleForm.fit_category_ids" placeholder="例如猫粮专用券，id字符串，多个分类使用逗号分隔"></el-input>
                </el-form-item>

                <!--指定商品可以使用的券，例如go专用券，id字符串，多个分类使用逗号分隔-->
                <el-form-item label="指定商品专用" prop="fit_goods_ids">
                    <el-input v-model="ruleForm.fit_goods_ids" placeholder="例如go九种肉8磅专用券，id字符串，多个分类使用逗号分隔"></el-input>
                </el-form-item>

                <!--指定分类不可以使用的券，例如猫粮专用券，id字符串，多个分类使用逗号分隔-->
                <!--<el-form-item label="指定商品类型不可用" prop="beside_category_ids">-->
                    <!--<el-input v-model="ruleForm.beside_category_ids" placeholder="例如猫粮不可用，id字符串，多个分类使用逗号分隔"></el-input>-->
                <!--</el-form-item>-->

                <!--指定商品可以使用的券，例如go专用券，id字符串，多个分类使用逗号分隔-->
                <!--<el-form-item label="指定商品不可用" prop="beside_goods_ids ">-->
                    <!--<el-input v-model="ruleForm.beside_goods_ids "-->
                              <!--placeholder="例如go九种肉8磅不可用，id字符串，多个分类使用逗号分隔"></el-input>-->
                <!--</el-form-item>-->

            </div>

            <!--按钮-->
            <el-form-item>
                <el-button type="primary" @click="couponSubmit">保存</el-button>
            </el-form-item>
        </el-form>


    </div>
</template>

<script>
    import util from '../../../utils/util'
    export default {
        name: "addCoupon",
        data() {
            return {
                publish_date: null,
                active_date: null,
                coupon_only_id: null,
                ruleForm: {
                    name: null,  //优惠券名称
                    name_desc: null,  //优惠券描述
                    limit_amount: null, //需要达到多少金额满减
                    preferential_cash: null,  //优惠金额
                    // is_free: 1,  //是否优惠，先默认免费
                    limit_get_num: 1,  //用户可领数量
                    limit_pay_num: 1,  //用户支付订单时限制使用的优惠券数量
                    // is_belong_vip: false,  //是否属于会员才能领取的券
                    limit_number: 1,  //满减的商品数，例如买三件享受满减优惠券
                    limit_publish_num: 1000,  //优惠券发放总数
                    fit_category_ids: null,
                    fit_goods_ids: null,
                    // beside_category_ids: null,
                    // beside_goods_ids: null,
                },
                rules: {
                    name: [
                        {required: true, message: "请输入优惠券名称", trigger: 'blur'},
                    ],
                    name_desc: [
                        {required: true, message: "请输入优惠券描述", trigger: 'blur'},
                    ],
                    limit_amount: [
                        {required: true, message: "请输入优惠券满减门槛", trigger: 'blur'},
                    ],
                    limit_pay_num: [
                        {required: true, message: "请输入用户支付订单时限制使用的优惠券数量", trigger: 'blur'},
                    ],
                    preferential_cash: [
                        {required: true, message: "请输入优惠券金额", trigger: 'blur'},
                    ],
                },
            }
        },
        methods: {
            //获取优惠券详情
            getCouponDetail(){
                this.$http({
                    url: this.$api + '/admin/coupon/get?coupon_only_id='+this.coupon_only_id,
                    method: 'get',
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.ruleForm.name = response.data.data.name;
                        this.ruleForm.name_desc = response.data.data.name_desc;
                        this.ruleForm.limit_amount  = response.data.data.limit_amount ;
                        this.ruleForm.preferential_cash = response.data.data.preferential_cash;
                        // this.ruleForm.is_belong_vip = (response.data.data.is_belong_vip==1) ? true : false;
                        this.ruleForm.limit_get_num = response.data.data.limit_get_num;
                        this.ruleForm.limit_publish_num = response.data.data.limit_publish_num;
                        this.ruleForm.limit_number = response.data.data.limit_number;
                        this.ruleForm.fit_category_ids = response.data.data.fit_category_ids;
                        this.ruleForm.fit_goods_ids = response.data.data.fit_goods_ids;

                        this.publish_date = `${util.getDateTimeStr(response.data.data.publish_start_date)}~
                        ${util.getDateTimeStr(response.data.data.publish_end_date)}`

                        this.active_date = `${util.getDateTimeStr(response.data.data.active_start_date)}~
                        ${util.getDateTimeStr(response.data.data.active_end_date)}`
                    }
                })
            },
            //保存修改的优惠券
            couponSubmit() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        let ajaxParams = {
                            coupon_only_id: this.coupon_only_id,
                            name: this.ruleForm.name,
                            name_desc: this.ruleForm.name_desc,
                            limit_publish_num: this.ruleForm.limit_publish_num,
                            limit_get_num: this.ruleForm.limit_get_num,
                            limit_pay_num: this.ruleForm.limit_pay_num,
                            fit_category_ids: this.ruleForm.fit_category_ids,
                            fit_goods_ids: this.ruleForm.fit_goods_ids,
                            // is_belong_vip: this.ruleForm.is_belong_vip ? 1 : 0,
                            // beside_category_ids: this.ruleForm.beside_category_ids,
                            // beside_goods_ids: this.ruleForm.beside_goods_ids,
                        };

                        this.$http({
                            url: this.$api + '/admin/coupon/update',
                            method: 'post',
                            data: this.$qs.stringify(ajaxParams)
                        }).then(response => {
                            if (response.data.errno === 0) {
                                this.$message.success('修改优惠券成功！')
                                // this.getMenuList()
                            }
                        })


                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        },
        created(){
            this.coupon_only_id = this.$route.query.coupon_only_id;
        },
        mounted(){
            this.getCouponDetail();
        }
    }
</script>

<style lang="scss" scoped>
    .coupon_title {
        margin-bottom: 20px;
    }

    .form_con {
        display: flex;
        flex-wrap: wrap;
    }

    .mike_part {
        width: 600px;
        &.left {
            margin-right: 100px;
        }
    }
</style>
