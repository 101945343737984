<template>
    <!--添加食品分类-->
    <div>
        <el-dialog title="添加疾病分类" :visible.sync="addTypeDialogData.isShow">

            <el-form :model="foodTypeForm" :rules="rules" ref="foodTypeForm">
                <!--添加分类的名称-->
                <el-form-item label="分类名称：" prop="name">
                    <el-input v-model="foodTypeForm.name" autocomplete="off"></el-input>
                </el-form-item>

                <!--添加分类的序号-->
                <el-form-item label="分类序号：" prop="sort_order">
                    <el-input v-model="foodTypeForm.sort_order" autocomplete="off"></el-input>
                </el-form-item>

                <!--添加的分类是否立即上线-->
                <el-form-item label="是否立即上线：">
                    <el-switch
                        v-model="foodTypeForm.is_show" active-color="#13ce66" inactive-color="#ccc">
                    </el-switch>
                </el-form-item>

            </el-form>

            <div slot="footer" class="dialog-footer">
                <el-button @click="addTypeDialogData.isShow = false">取 消</el-button>
                <el-button type="primary" @click="submitForm">确 定</el-button>
            </div>

        </el-dialog>
    </div>

</template>

<script>
    export default {
        name: "addFormDialog",
        props: {
            addTypeDialogData: {
                type: Object,
                default: {
                    isShow: false
                }
            }
        },
        data(){
            return {
                foodTypeForm: {
                    name: '',
                    sort_order: 0,
                    is_show: true,
                },
                rules: {
                    name: [{required: true, message: "请输入食品分类名称", trigger: 'blur'}]
                }
            }
        },
        methods: {
            //添加食品分类弹窗确定
            submitForm() {
                this.$refs['foodTypeForm'].validate((valid) => {
                    if (valid) {
                        this.addTypeDialogData.isShow = false;
                        this.addTypeSubmit();
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },

            //添加食品分类提交
            addTypeSubmit(){
                this.$http.post(this.$api + 'admin/disease/add', {
                    name : this.foodTypeForm.name,
                    parent_id : 0,
                    sort_order: this.foodTypeForm.sort_order,
                    is_show: this.foodTypeForm.is_show ? 1 : 0
                }).then(res => {
                    if (res.data.errno === 0) {
                        this.foodTypeForm.sort_order = 0;
                        this.foodTypeForm.name = '';
                        this.$message.success('添加食品分类成功！');
                        this.$store.dispatch('getDiseaseList');
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
