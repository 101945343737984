<template>
    <div class="mike_con">
        <div class="crumbs">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-form ref="ruleForm" label-width="150px" class="form_con ">

            <!--基本信息-->
            <div class="mike_part left">
                <el-alert title="选择小组长" type="info" :closable="false" class="coupon_title">
                    <el-select v-model="chooseTeamUserId" filterable placeholder="请选择一个小组长">
                        <el-option
                            v-for="item in teamUserList.data"
                            :key="item.belong_user_id"
                            :label="item.belong_user_name"
                            :value="item.belong_user_id">
                        </el-option>
                    </el-select>
                </el-alert>
                <div class="disflex_flex_start align-items" v-if="chooseTeamUser.belong_user_id">
                    <img :src="chooseTeamUser.group_avatar" alt="" class="width100px">
                    <el-form  ref="ruleForm" label-width="150px" class="demo-ruleForm margin-left-1em">
                        <el-form-item label="小组长id：" prop="name">
                            <p>{{chooseTeamUser.belong_user_id}}</p>
                        </el-form-item>
                        <el-form-item label="组长真实姓名：" prop="name">
                            <p>{{chooseTeamUser.belong_user_name}}</p>
                        </el-form-item>
                        <el-form-item label="小组名：" prop="name">
                            <p>{{chooseTeamUser.group_name}}</p>
                        </el-form-item>
                        <el-form-item label="加入时间：" prop="name">
                            <p>{{chooseTeamUser.update_time}}</p>
                        </el-form-item>
                    </el-form>
                    <el-form  ref="ruleForm" label-width="150px" class="demo-ruleForm">

                        <el-form-item label="电话：" prop="name">
                            <p>{{chooseTeamUser.mobile}}</p>
                        </el-form-item>
                        <el-form-item label="微信：" prop="name">
                            <p>{{chooseTeamUser.wechat}}</p>
                        </el-form-item>
                        <el-form-item label="小组状态：" prop="name">
                            <p class="color_eb3021">{{chooseTeamUser.is_permit_str}}</p>
                        </el-form-item>
                    </el-form>
                </div>

            </div>

            <!--限制条件-->
            <div class="mike_part left">
                <el-alert title="选择返佣模板" type="info" :closable="false" class="coupon_title">
                    <el-select v-model="chooseCommissionTemplateId" filterable placeholder="请选择一个返佣模板">
                        <el-option
                            v-for="item in commissionTemplateList"
                            :key="item.template_id"
                            :label="item.template_name"
                            :value="item.template_id">
                        </el-option>
                    </el-select>
                </el-alert>
                <!--指定商品可以使用的券，例如go专用券，id字符串，多个分类使用逗号分隔-->
                    <el-table
                        :data="chooseCommissionTemplate.goods_list"
                        style="width: 100%">
                        <el-table-column align="center" width="150"  label="商品" >
                            <template slot-scope="goodsItem">
                                <img :src="goodsItem.row.head_img_url" alt="" class="width60px">
                            </template>
                        </el-table-column>
                        <el-table-column align="center" width="200" label="名称" prop="goods_name"></el-table-column>
                        <el-table-column align="center" width="200" label="商品返佣金额">
                            <template slot-scope="goodsItem">
                                <!--<el-input v-model="goodsItem.row.return_cash" placeholder="请输入返佣金额">-->
                                    <!--<i class="el-icon-money" slot="prefix" ></i>-->
                                <!--</el-input>-->
                                <p>{{goodsItem.row.return_cash}}</p>
                            </template>
                        </el-table-column>
                    </el-table>
            </div>

            <!--按钮-->
            <div class="text-align-right width100">
                <el-button type="primary" @click="saveTeamUserCommission">保存模板</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "addCommissionTemplate",
        data() {
            return {
                // ============ 分割线 =======
                teamUserList: {},
                commissionTemplateList: [],
                chooseTeamUser: {},
                chooseTeamUserId: null,
                chooseCommissionTemplateId: null,
                chooseCommissionTemplate: {}
            }
        },
        async mounted () {
            await this.getTeamUserList().then(res => {
                if (this.$route.query.service_id) {
                    this.chooseTeamUserId = Number(this.$route.query.service_id)
                }
            });
            await this.getCommissionTemplateList().then(res => {
                if (this.$route.query.template_id) {
                    this.chooseCommissionTemplateId = this.$route.query.template_id
                }
            });
        },
        watch: {
            chooseTeamUserId (newv) {
                let that = this;
                this.teamUserList.data.map(function (item) {
                    if (newv === item.belong_user_id) {
                        that.chooseTeamUser = item
                    }
                })
            },
            chooseCommissionTemplateId (newv) {
                let that = this;
                this.commissionTemplateList.map(function (item) {
                    if (newv === item.template_id) {
                        that.chooseCommissionTemplate = item
                    }
                })
            }
        },
        methods: {
            async saveTeamUserCommission () {
                await this.$http({
                    url: this.$api + '/admin/teamCommission/setCommissionTemplate',
                    method: 'post',
                    data: {
                        template_id: this.chooseCommissionTemplateId,
                        service_id: this.chooseTeamUserId
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.$message.success('为小组长设置模板成功！');
                        // this.$router.push('/')
                    } else {
                        this.$message.error('为小组长设置模板失败！')
                    }
                })
            },
            async getTeamUserList (page, size) {
                await this.$http({
                    url: this.$api + '/admin/serviceAccount/teamList',
                    method: 'post',
                    data: {
                        page: page || 1,
                        size: size || 100
                    }
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.teamUserList = response.data.data;
                    } else {
                        this.$message.error('加载小组长列表失败！')
                    }
                })
            },
            //获取优惠券列表
            async getCommissionTemplateList(){
                await this.$http({
                    url: this.$api + '/admin/serviceTemplate/defaultList',
                    method: 'post',
                }).then(response => {
                    if (response.data.errno === 0) {
                        this.commissionTemplateList = response.data.data;
                    } else {
                        this.$message.error('加载模板列表失败！')
                    }
                })
            },
        }
    }
</script>

<style lang="scss">
    .team p {
        color: #666;
    }
    .couponGoodsTransfer .el-transfer-panel {
        width: 40%;
    }
    .coupon_title {
        margin-bottom: 20px;
    }

    .form_con {
        display: flex;
        flex-wrap: wrap;
    }

    .mike_part {
        width: 100%;
        &.left {
            /*margin-right: 100px;*/
        }
    }
</style>
