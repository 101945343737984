<template>
    <el-dialog :visible.sync="showForm" :title="titleName" :before-close="beforeClose">
        <div class="button-tool">
            <div style="display: flex">
                <el-input style="width: 500px" placeholder="搜索用户名" v-model="searchTitle"
                          @keyup.enter.native="handleSearchByTitle">
                    <i class="el-icon-search el-input__icon pointer " slot="prefix" @click="handleSearchByTitle"></i>
                </el-input>
            </div>
            <div>
                <el-button type='primary' @click="exportInvite">导出列表</el-button>
            </div>
        </div>
        <div id="crumbs"></div>
        <el-table :data="tableData" border class="text-centre my-table"
                  header-row-class-name=""
                  :span-method="objectSpanMethod"
                  style="width: 100%">

            <el-table-column label="用户名">
                <template slot-scope="scope">
                    <div>
                        <span>{{ scope.row.inviterNickname === null ? 0 : scope.row.inviterNickname }}</span>
                    </div>
                    <div>
                        <img :src="scope.row.inviterHead" style="width: 40px;vertical-align: middle;">
                    </div>
                </template>
            </el-table-column>

            <el-table-column label="成功邀请用户" width="100">
                <template slot-scope="scope">
                    <span>{{ scope.row.nickname === null ? 0 : scope.row.nickname }}</span>
                </template>
            </el-table-column>

            <el-table-column label="报名人数" width="150">
                <template slot-scope="scope">
                    <span>{{ scope.row.inviteNum === null ? 0 : scope.row.inviteNum }}</span>
                </template>
            </el-table-column>
            <el-table-column label="奖励金额" width="150">
                <template slot-scope="scope">
                    <span style="color: red">{{ scope.row.awardPrice === null ? 0 : scope.row.awardPrice }}元</span>
                </template>
            </el-table-column>
            <el-table-column label="报名方式" width="100">
                <template slot-scope="scope">
                    <span v-for="item in invitationType" v-if="scope.row.inviteType ==item.value">{{item.name}}</span>
                </template>
            </el-table-column>
            <el-table-column label="报名时间" width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.payTime === null ? 0 : scope.row.payTime }}</span>
                </template>
            </el-table-column>

            <el-table-column label="状态" width="150">
                <template slot-scope="scope">
                    <div>
                        <el-button title="设为已领取" v-if="scope.row.receiveStatus ==0" type="text" size="small"
                                   @click="invitePay(scope.$index, scope.row)">
                            未领取
                        </el-button>
                        <span v-if="scope.row.receiveStatus ==1">已领取</span>
                        <span v-if="scope.row.receiveStatus ==null">未知</span>
                    </div>

                </template>
            </el-table-column>

        </el-table>
        <div class="paginaion" style="text-align: center;margin-top: 1em">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="tableData.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="tableData.count">
            </el-pagination>
        </div>
    </el-dialog>
</template>

<script>
    import bus from '../../common/bus';

    export default {
        data: function () {
            return {
                showForm: false,
                titleName: '邀请详情',
                invitationType: [],
                tableData: [],
                activityId: '',
                applyUserName: '',

                pos: 0,
                spanArr: [],

                activeName: '',
                tagSwitchData: [],
                currentPage: 1,
                total: 0,
                size: 10,
                status: 0,
                currentTagId: '',

                showCommon: false,
                commonData: '',
                tableHeight: 600,
                searchTitle: ''
            }
        },
        props: ['inviteData'],
        watch: {
            inviteData: function (data) {
                this.showForm = data.showForm
                this.activityId = data.id

                this.tableData = data.records
                this.currentPage = data.current
                this.total = data.total
                this.size = data.size

                this.getSpanArr(this.tableData)
            }
        },
        created() {
            this.invitationType = this.$getDicByName('INVITATION_TYPE')
        },
        mounted() {
            // bus.$on('inviteChange', data => {
            //     this.showForm = true
            //     this.activityId = data.id
            //     this.getList(this.searchTitle, data.id, 1, 10)
            // })
        },
        methods: {
            invitePay(index, row) {
                this.$confirm('请确定已经给该用户发了奖励金？', '发放奖励金确认', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http({
                        url: this.$api + '/chigua/admin/activity/invite/update/pay',
                        method: 'post',
                        data: this.$qs.stringify({
                            inviteId: row.inviteId
                        })
                    }).then(response => {
                        if (response.data.errno == 0) {
                            this.getList(this.searchTitle, this.activityId, this.currentPage, this.size)
                            this.$message({
                                type: 'success',
                                message: '发放成功!'
                            });
                        } else {
                            this.$message(response.msg)
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消操作'
                    });
                });


            },
            beforeClose(done) {
                this.showForm = false
                done()
            },
            objectSpanMethod({row, column, rowIndex, columnIndex}) {
                if (columnIndex === 0 || columnIndex === 8) {
                    const _row = this.spanArr[rowIndex];
                    const _col = _row > 0 ? 1 : 0;
                    return {
                        rowspan: _row,
                        colspan: _col
                    }
                }
            },
            getSpanArr(data) {
                this.spanArr = []
                this.pos = 0
                for (var i = 0; i < data.length; i++) {
                    if (i === 0) {
                        this.spanArr.push(1);
                        this.pos = 0
                    } else {
                        // 判断当前元素与上一个元素是否相同
                        if (data[i].inviteUserId === data[i - 1].inviteUserId) {
                            this.spanArr[this.pos] += 1;
                            this.spanArr.push(0);
                        } else {
                            this.spanArr.push(1);
                            this.pos = i;
                        }
                    }
                }
            },
            handleSearchByState() {
                this.getList('', this.activityId, this.currentPage, this.size)
            }
            ,
            handleSearchByTitle() {
                this.getList(this.searchTitle, this.activityId, this.currentPage, this.size)

            }
            ,
            handleSizeChange(val) {
                this.getList(this.searchTitle, this.activityId, this.currentPage, val)
            }
            ,
            handleCurrentChange(val) {
                this.getList(this.searchTitle, this.activityId, val, this.size)
            }
            ,
            getList(title, activityId, page, length) {
                const loading = this.$loading({
                    lock: true,
                    text: '已经很努力加载啦~',
                    spinner: 'el-icon-loading',
                    background: 'rgba(183, 183, 183, 0.57)'
                });
                if (page === undefined | page === null) {
                    page = 1
                } else if (length === undefined | length === null) {
                    length = this.size
                }
                this.$http({
                    url: this.$api + '/chigua/admin/activity/invite/list/detail',
                    method: 'post',
                    data: this.$qs.stringify({
                        activityId: activityId,
                        searchUserName: title,
                        page: page,
                        limit: length
                    })
                }).then(response => {
                    if (response.data.data != null) {
                        this.tableData = response.data.data.records
                        this.currentPage = response.data.data.current
                        this.total = response.data.data.total
                        this.size = response.data.data.size

                        this.getSpanArr(this.tableData)
                    } else {
                        this.$message.error('数据加载失败！')
                    }
                    loading.close()
                })
            },
            exportInvite() {
                this.$export({
                    url: this.$api + '/chigua/admin/activity/invite/export',
                    data: {
                        searchUserName: this.searchTitle,
                        activityId: this.activityId
                    }
                });

            }
        }
    }
</script>
<style>


    .my-table.el-table th {
        background: rgba(197, 145, 234, 0.3) !important;
        text-align: center;
    }

    .el-radio + .el-radio {
        margin-left: 10px;
    }

    .crumbs {
        display: flex;
        justify-content: space-between;
    }

    .text-centre {
        text-align: center;
    }

    .pointer {
        cursor: pointer;
    }

    .el-dialog__body {
        padding-top: 0px;
    }
</style>
